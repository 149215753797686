import React from "react";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";

import {IddView} from ".";
import {ICap, IStateUi} from "..";
import {IconOverdraft, IconTimes} from "../../Icons";

interface IProps {
  closeViewIdd: () => any;
  isOpen: boolean;
  capItem: ICap;
  ui: IStateUi;
}

const IddViewModal: React.FC<IProps> = (props) => (
  <Modal
    isOpen={props.isOpen}
    toggle={props.closeViewIdd}
    backdrop="static"
    keyboard={false}
    className="modal-form"
  >
    <ModalHeader
      tag="div"
      toggle={props.closeViewIdd}
      close={
        <Button size="sm" className="close-modal" onClick={props.closeViewIdd}>
          <IconTimes size={1.25} className="icon-border-2 rounded-circle p-1" />
        </Button>
      }
    >
      <IconOverdraft size={3} className="icon-border-2 rounded-circle p-2" />
      Valutazione Adeguatezza IBIPs
    </ModalHeader>
    <ModalBody>
      {props.capItem && props.capItem.idd && (
        <IddView idd={props.capItem.idd} />
      )}
    </ModalBody>
  </Modal>
);

export default IddViewModal;
