import IRoutes from "../types/IRoutes";
import List from "./List";
import New from "./New";
import View from "./View";

const routes: IRoutes = [
  {
    component: List,
    exact: true,
    name: "proposte-bene-list",
    path: "/proposte-bene",
    permission: "bene.read",
    company: "101",
  },
  {
    component: New,
    exact: true,
    name: "proposte-bene-new",
    path: "/proposte-bene/new",
    permission: "bene.manage",
    company: "101",
  },
  {
    component: View,
    exact: true,
    name: "proposte-bene-view",
    path: "/proposte-bene/:id?",
    permission: "bene.read",
    company: "101",
  },
];

export default routes;
