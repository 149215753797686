import animateScrollTo from "animated-scroll-to";
import React from "react";
import {connect} from "react-redux";
import {Link, RouteComponentProps} from "react-router-dom";
import ReactTable from "react-table";
import "react-table/react-table.css";
import {
  Alert,
  Button,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import {Layout} from "../App";
import {IInvestmentCompany} from "../Caps";
import {actions as companiesActions} from "../Caps/actions/companies";
import {selectCompanies} from "../Caps/selectors";
import {containsFilter} from "../helpers/containsFilter";
import {
  IconCheckmark,
  IconEye,
  IconPlus,
  IconSpinner,
  IconWarning,
} from "../Icons/";
import {IRootState} from "../redux/reducers";
import {actions, IStateNewUser, IStateUi, IUserEntity} from "./";
import {dehydrateUser, hydrateUser, IUser, userRoles} from "./types";
import "./Users.scss";

interface IStateProps {
  users: IUser[];
  ui: IStateUi;
  newUser: IStateNewUser;
  companies: IInvestmentCompany[];
}
interface IDispatchProps {
  delete: (userId: string) => Promise<void>;
  disable: (user: IUserEntity) => void;
  enable: (user: IUserEntity) => void;
  closeEnableUser: () => void;
  openEnableUser: () => void;
  toggleCloseModal: () => void;
  list: () => Promise<void>;
  resetDeleteSuccess: () => void;
  resetNewUserState: () => void;
  loadCompanies: () => Promise<void>;
}

type IProps = IStateProps & IDispatchProps & RouteComponentProps;

interface IState {
  disableModal: {[key: string]: boolean};
  enableModal: {[key: string]: boolean};
}

const mapStateToProps = (state: IRootState) => {
  const result = state.users.list;

  const entities = {
    users: state.users.entities,
  };

  return {
    newUser: state.users.newUser,
    ui: state.users.ui,
    users: hydrateUser({result, entities}),
    companies: selectCompanies(state),
  };
};

const mapDispatchToProps = {
  delete: (userId: string) => actions.delete(userId),
  disable: (user: IUserEntity) => actions.updateStatus({...user, status: "-1"}),
  enable: (user: IUserEntity) => actions.updateStatus({...user, status: "0"}),

  closeEnableUser: () => {
    return actions.closeEnableUser();
  },
  openEnableUser: () => {
    return actions.openEnableUser();
  },
  toggleCloseModal: () => actions.toggleCloseModal(),

  // fromDbResetUiError: () => actions.fromDbResetUiError(),
  list: () => actions.list(),
  resetDeleteSuccess: () => actions.resetDeleteSuccess(),
  resetNewUserState: () => actions.resetNewUserState(),
  loadCompanies: () => {
    return companiesActions.loadCompanies();
  },
  // viewFromDbResetUiError: () => actions.viewFromDbResetUiError()
};

const LoadingComponent = (props: any) =>
  props.loading ? (
    <div className="-loading -active">
      <div className="-loading-inner">
        <IconSpinner className="icon-spin" /> Caricamento utenti in corso...
      </div>
    </div>
  ) : null;

class ListPage extends React.Component<IProps, IState> {
  public static scrollToTop() {
    animateScrollTo(0);
  }

  public state: IState = {
    disableModal: {},
    enableModal: {},
  };

  public componentDidMount() {
    this.props.list().then();

    if (!!this.props.newUser.isSuccess) {
      animateScrollTo(0).then();
    }

    if (this.props.companies.length === 0) {
      this.props.loadCompanies().then();
    }
  }

  public componentWillUnmount() {
    this.props.resetNewUserState();
  }

  public goToNewPage = () => {
    this.props.resetNewUserState();
    this.props.history.push("/user/new");
  };

  public disableModal = (userId: string) => {
    this.setState((prevState) => ({
      disableModal: {
        ...prevState.disableModal,
        [userId]: !prevState.disableModal[userId],
      },
    }));
  };

  public enableModal = (userId: string) => {
    this.setState((prevState) => ({
      enableModal: {
        ...prevState.enableModal,
        [userId]: !prevState.enableModal[userId],
      },
    }));
  };

  public enable = (user: IUser) => {
    const {
      result,
      entities: {users},
    } = dehydrateUser(user);

    this.props.enable(users[result]);
    this.enableModal(user.id);
  };

  public disable = (user: IUser) => {
    const {
      result,
      entities: {users},
    } = dehydrateUser(user);

    this.props.disable(users[result]);
    this.disableModal(user.id);
  };

  public renderTableActions = (user: IUser) => {
    return (
      <React.Fragment>
        {user.status.toString() === "-1" ? (
          <div>
            <Link
              to={{
                pathname: `/users/edit/${user.id}`,
                state: {fromUser: JSON.stringify(user)},
              }}
            >
              <Button size="sm" color="primary">
                <IconEye /> Dettaglio
              </Button>
            </Link>{" "}
            <Button
              size="sm"
              color="success"
              onClick={this.enableModal.bind(this, user.id)}
            >
              <IconCheckmark /> Abilita
            </Button>
            <Modal
              isOpen={this.state.enableModal[user.id]}
              toggle={this.enableModal.bind(this, user.id)}
              backdrop="static"
            >
              <ModalHeader
                tag="h3"
                toggle={this.enableModal.bind(this, user.id)}
              >
                Sei sicuro di voler abilitare l'utente {user.surname}{" "}
                {user.name} ?
              </ModalHeader>
              <ModalBody>
                <Button color="primary" onClick={this.enable.bind({}, user)}>
                  <IconCheckmark /> Abilita
                </Button>{" "}
                <Button
                  color="secondary"
                  onClick={this.enableModal.bind(this, user.id)}
                >
                  Annulla
                </Button>
              </ModalBody>
            </Modal>
          </div>
        ) : (
          <div>
            <Link
              to={{
                pathname: `/users/edit/${user.id}`,
                state: {fromUser: JSON.stringify(user)},
              }}
            >
              <Button size="sm" color="primary">
                <IconEye /> Dettaglio
              </Button>
            </Link>{" "}
            <Button
              size="sm"
              color="warning"
              onClick={this.disableModal.bind(this, user.id)}
            >
              <IconWarning /> Disabilita
            </Button>
            <Modal
              isOpen={this.state.disableModal[user.id]}
              toggle={this.disableModal.bind(this, user.id)}
              backdrop="static"
            >
              <ModalHeader
                tag="h3"
                toggle={this.disableModal.bind(this, user.id)}
              >
                Sei sicuro di voler disabilitare l'utente {user.surname}{" "}
                {user.name} ?
              </ModalHeader>
              <ModalBody>
                <Button color="primary" onClick={this.disable.bind({}, user)}>
                  <IconCheckmark /> Disabilita
                </Button>{" "}
                <Button
                  color="secondary"
                  onClick={this.disableModal.bind(this, user.id)}
                >
                  Annulla
                </Button>
              </ModalBody>
            </Modal>
          </div>
        )}
      </React.Fragment>
    );
  };

  public renderTable() {
    // const companyRoles: string[] = Array.from(
    //   new Set(this.props.users.map((user: IUser) => user.companyRole || ""))
    // );
    // const rolesId: number[] = Array.from(
    //   new Set(this.props.users.map((user: IUser) => user.rolesId || []))
    // );
    return (
      <ReactTable
        LoadingComponent={LoadingComponent}
        data={this.props.users}
        columns={[
          {
            Header: "Username",
            accessor: "usid",
            filterMethod: (filter: any, row: any) =>
              containsFilter(row[filter.id], filter.value),
          },
          {
            Header: "Email",
            accessor: "email",
            filterMethod: (filter: any, row: any) =>
              containsFilter(row[filter.id], filter.value),
          },
          {
            Header: "Nome e cognome",
            accessor: (user: IUser) => `${user.name} ${user.surname}`,
            filterMethod: (filter: any, row: any) =>
              containsFilter(row[filter.id], filter.value),
            id: "nome",
          },
          {
            Header: "Telefono",
            accessor: "businessPhone",
            filterMethod: (filter: any, row: any) =>
              containsFilter(row[filter.id], filter.value),
          },
          // {
          //   Filter: ({filter, onChange}: any) => (
          //     <select
          //       className="border-primary"
          //       onChange={event => onChange(event.target.value)} // tslint:disable-line
          //       style={{width: "100%"}}
          //       value={filter ? filter.value : "all"}
          //     >
          //       <option key="-" value="">
          //         Tutti
          //       </option>
          //       {companyRoles.map(
          //         value =>
          //           value && (
          //             <option key={value} value={value}>
          //               {value}
          //             </option>
          //           )
          //       )}
          //     </select>
          //   ),
          //   Header: "Ruolo company",
          //   accessor: "companyRole"
          // },
          {
            Filter: ({filter, onChange}: any) => (
              <select
                onChange={(event) => onChange(event.target.value)} // tslint:disable-line
                style={{width: "100%"}}
                value={filter ? filter.value : "all"}
              >
                <option key="-" value="">
                  Tutti
                </option>
                <option value={2}>Advisor</option>
                <option value={3}>Supervisore</option>
                <option value={5}>Backoffice</option>
                <option value={6}>Gestione Utenti</option>
              </select>
            ),
            Header: "Ruolo",
            accessor: (user: IUser) => {
              return (
                <div data-value={user.rolesId ?? []}>
                  {user.rolesId &&
                    user.rolesId
                      .filter((value) => value !== 7)
                      .map((value) => <p key={value}>{userRoles[value]}</p>)}
                </div>
              );
            },
            filterMethod: (filter: any, row: any) => {
              return row[filter.id].props["data-value"].includes(
                parseInt(filter.value)
              );
            },
            id: "rolesId",
          },
          {
            Filter: ({filter, onChange}: any) => (
              <select
                onChange={(event) => onChange(event.target.value)} // tslint:disable-line
                style={{width: "100%"}}
                value={filter ? filter.value : "all"}
              >
                <option key="-" value="">
                  Tutti
                </option>
                {this.props.companies.map((company) => (
                  <option key={company.id} value={company.id}>
                    {company.name}
                  </option>
                ))}
              </select>
            ),
            Header: "Compagnie",
            accessor: (user: IUser) => {
              return (
                <div data-value={user.companiesId ?? []}>
                  {user.companiesId &&
                    user.companiesId.map((value) => (
                      <p key={value}>
                        {
                          this.props.companies.find(
                            (company) => company.id === value.toString()
                          )?.name
                        }
                      </p>
                    ))}
                </div>
              );
            },
            filterMethod: (filter: any, row: any) => {
              return row[filter.id].props["data-value"].includes(
                parseInt(filter.value)
              );
            },
            id: "companiesId",
          },
          {
            Filter: ({filter, onChange}: any) => (
              <select
                className="border-primary"
                onChange={(event) => onChange(event.target.value)} // tslint:disable-line
                style={{width: "100%"}}
                value={filter ? filter.value : "all"}
              >
                <option key="-" value="">
                  Tutti
                </option>
                <option key={-1} value={-1}>
                  Disattivato
                </option>
                <option key={0} value={0}>
                  In attesa di attivazione
                </option>
                <option key={1} value={1}>
                  Attivo
                </option>
              </select>
            ),
            Header: "Stato",
            accessor: (user: IUser) => (
              <span data-value={user.status}>
                <span
                  className={
                    "statusDot " +
                    (user.status.toString() === "-1"
                      ? "text-danger"
                      : user.status.toString() === "0"
                      ? "text-warning"
                      : "text-success")
                  }
                >
                  &#x25cf;
                </span>{" "}
                <span>
                  {user.status.toString() === "-1"
                    ? "Disattivato"
                    : user.status.toString() === "0"
                    ? "In attesa di attivazione"
                    : "Attivo"}
                </span>
              </span>
            ),
            filterMethod: (filter: any, row: any) =>
              row[filter.id].props["data-value"] === filter.value,
            id: "stato",
            sortMethod: (first: any, second: any) => {
              const a = parseInt(first.props["data-value"], 10);
              const b = parseInt(second.props["data-value"], 10);
              if (a === b) {
                return 0;
              }
              return a > b ? -1 : 1;
            },
          },
          {
            Header: "Azioni",
            accessor: this.renderTableActions,
            filterable: false,
            id: "azioni",
            width: 205,
          },
        ]}
        defaultPageSize={50}
        minRows={5}
        filterable
        loading={this.props.ui.isListPending && this.props.users.length === 0}
        className="-striped -highlight"
        previousText="Precedente"
        nextText="Prossima"
        noDataText="Nessun utente trovato"
        pageText="Pagina"
        ofText="di"
        rowsText="righe"
      />
    );
  }

  public render() {
    return (
      <Layout isProtected>
        <Container fluid className="auto-margin-4 px-md-5">
          <Alert
            color="info"
            isOpen={!!this.props.ui.isDeleteSuccess}
            toggle={this.props.resetDeleteSuccess}
          >
            Utente eliminato correttamente
          </Alert>
          <div className="d-flex align-items-center">
            <h2>Utenti</h2>
            <Button
              color="light"
              className="ml-auto"
              outline
              onClick={this.goToNewPage}
            >
              <IconPlus /> Nuovo utente
            </Button>
          </div>
          <Alert
            color="success"
            isOpen={!!this.props.newUser.isSuccess}
            toggle={this.props.resetNewUserState}
          >
            Utente inserito correttamente
          </Alert>
        </Container>
        <Container fluid className="auto-margin-4 px-0 px-sm-3 px-md-5">
          <Container fluid className="container-max">
            {this.renderTable()}
          </Container>
        </Container>
      </Layout>
    );
  }
}

const ConnectedPage = connect(mapStateToProps, mapDispatchToProps)(ListPage);

export default ConnectedPage;
