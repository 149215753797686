import Slider from "rc-slider";
import React from "react";
import {Chart} from "react-google-charts";
import {Col, Progress, Row} from "reactstrap";
import {TargetsQuestionsKeys} from "../helpers/domandeObiettivi";
import FormatNumber from "../helpers/FormatNumber";
import {getBSVar} from "../helpers/getBSVar";
import {targetSerie} from "./targetSerie";
import {ITargetFormData} from "./TargetsForm";

interface IProps {
  details: ITargetFormData;
  weights: {[key in TargetsQuestionsKeys]: number[]};
}

const Wishes = (props: IProps) => {
  let counter = 1;
  return (
    <div>
      <Row className="mt-3">
        <Col>
          <p>I vostri desideri:</p>
        </Col>
        <Col className="d-flex justify-content-between">
          <span>1y</span>
          <span>10y</span>
          <span>20y</span>
          <span>30y</span>
          <span>40y</span>
        </Col>
      </Row>
      {props.details.obj1 ? (
        <Row>
          <Col>
            <p>
              {counter++}. {props.details.obj1}
            </p>
          </Col>
          <Col className="d-flex align-items-center overflow-slider">
            <Slider
              className="readonly adjust-margin-top"
              value={props.details.objValue1}
              min={1}
              max={40}
              marks={{[props.details.objValue1]: `${props.details.objValue1}`}}
            />
          </Col>
        </Row>
      ) : null}
      {props.details.obj2 ? (
        <Row>
          <Col>
            <p>
              {counter++}. {props.details.obj2}
            </p>
          </Col>
          <Col className="d-flex align-items-center overflow-slider">
            <Slider
              className="readonly adjust-margin-top"
              value={props.details.objValue2}
              min={1}
              max={40}
              marks={{[props.details.objValue2]: `${props.details.objValue2}`}}
            />
          </Col>
        </Row>
      ) : null}
      {props.details.obj3 ? (
        <Row>
          <Col>
            <p>
              {counter}. {props.details.obj3}
            </p>
          </Col>
          <Col className="d-flex align-items-center overflow-slider">
            <Slider
              className="readonly adjust-margin-top"
              value={props.details.objValue3}
              min={1}
              max={40}
              marks={{[props.details.objValue3]: `${props.details.objValue3}`}}
            />
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

const TargetDetails = (props: IProps) => {
  const chartData = targetSerie(props.details, props.weights);
  return (
    <>
      {!!props.details.obj1 || !!props.details.obj2 || !!props.details.obj3 ? (
        <Wishes {...props} />
      ) : null}
      <div
        className="d-none d-sm-block"
        style={{height: "400px", width: "100%"}}
      >
        <Chart
          chartType="BarChart"
          data={[
            ["Categoria", "Obiettivi"],
            ["Tutelare beni, casa, animali", chartData[0]],
            ["Tenore di vita personale e famigliare", chartData[1]],
            ["Tutelarsi da infortuni, malattia & prevenzione", chartData[2]],
            ["Costruire un capitale per il futuro", chartData[3]],
          ]}
          options={{
            backgroundColor: getBSVar("drawer"),
            chartArea: {
              bottom: 35,
              left: 250,
              right: 20,
              top: 20,
            },
            hAxis: {
              ticks: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            },
            legend: {position: "none"},
            pointSize: 10,
            series: {0: {color: getBSVar("primary")}},
          }}
          width="100%"
          height="400px"
          legend_toggle
        />
      </div>
      <div className="d-block d-sm-none auto-margin-3">
        <div>
          Tutelare beni, casa, animali:
          <br />
          <FormatNumber>{chartData[0]}</FormatNumber>/10
          <Progress value={chartData[0] * 10} />
        </div>
        <div>
          Tenore di vita personale e famigliare:
          <br />
          <FormatNumber>{chartData[1]}</FormatNumber>/10
          <Progress value={chartData[1] * 10} />
        </div>
        <div>
          Tutelarsi da infortuni, malattia & prevenzione:
          <br />
          <FormatNumber>{chartData[2]}</FormatNumber>/10
          <Progress value={chartData[2] * 10} />
        </div>
        <div>
          Costruire un capitale per il futuro:
          <br />
          <FormatNumber>{chartData[3]}</FormatNumber>/10
          <Progress value={chartData[3] * 10} />
        </div>
      </div>
    </>
  );
};

export default TargetDetails;
