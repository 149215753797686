import React from "react";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import RejectForm, {IRejectFormData} from "./RejectForm";

interface IProps {
  isOpen: boolean;
  toggle: () => void;
  isRejectPending?: boolean;
  rejectError?: string;
  reject: (values: IRejectFormData) => void;
}

const RejectModal: React.FC<IProps> = ({
  isOpen,
  isRejectPending,
  toggle,
  rejectError,
  reject,
}) => (
  <Modal centered isOpen={isOpen} toggle={toggle}>
    <ModalHeader toggle={toggle}>Richiesta revisione</ModalHeader>
    <ModalBody>
      <RejectForm
        onSubmit={reject}
        isRejectPending={isRejectPending}
        rejectError={rejectError}
      />
    </ModalBody>
  </Modal>
);

export default RejectModal;
