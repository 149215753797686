import get from "lodash/get";
import React, {useCallback, useEffect, VFC} from "react";
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {Controller, FieldError, UseFormMethods} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {FormFeedback, FormGroup, Label} from "reactstrap";
import {actions, ICity} from "../Caps";
import {getCities} from "../Caps/selectors";
import {getHighlightedText} from "../helpers/getHighlightedText";
import {ucWordsNormalizer} from "../helpers/normalizers";
import {IconInfoCircle, IconSpinner, IconWarning} from "../Icons";
import {IRootState} from "../redux/reducers";

interface RHFAutocompleteCitiesProps {
  disabled?: boolean;
  control?: UseFormMethods["control"];
  errors?: UseFormMethods["errors"];
  formState?: UseFormMethods["formState"];
  id: string;
  label?: string;
  moreName?: string;
  name: string;
  onSelect?: (selected: ICity[]) => void;
  placeholder?: string;
  regionName?: string;
  setValue?: UseFormMethods["setValue"];
  strict?: boolean;
}

export const RHFAutocompleteCitiesController: VFC<RHFAutocompleteCitiesProps> = ({
  control,
  disabled,
  errors,
  formState,
  id,
  label,
  moreName,
  name,
  onSelect,
  placeholder,
  regionName,
  setValue,
  strict,
}) => {
  const error = errors && (get(errors, name) as FieldError | undefined);
  const touched =
    !!formState && (get(formState.touched, name) as boolean | undefined);
  const placeholderReal = placeholder || label;

  const dispatch = useDispatch();

  const cities = useSelector(getCities);
  const isCityListPending = useSelector(
    (state: IRootState) => state.caps.ui.isCitiesListPending
  );

  const onSearch = useCallback(
    (query) => {
      dispatch(actions.loadCities({limit: 10, name: query, exist: strict}));
    },
    [dispatch, strict]
  );

  useEffect(() => {
    const initialValue = control?.getValues(name);
    if (initialValue) {
      dispatch(actions.loadCities({name: initialValue, limit: 1}));
    }
  }, [control, dispatch, name]);

  return (
    <FormGroup>
      {label && <Label for={id}>{label}</Label>}
      <Controller
        name={`${name}IdObject`}
        control={control}
        render={({value}) => (
          <input type="hidden" name={`${name}IdObject`} value={value} />
        )}
      />
      <Controller
        name={`${name}BExist`}
        control={control}
        render={({value}) => (
          <input type="hidden" name={`${name}BExist`} value={value} />
        )}
      />
      <Controller
        name={name}
        control={control}
        render={({value, onChange, onBlur}) => {
          return (
            <AsyncTypeahead
              disabled={disabled}
              emptyLabel={
                <span className="dropdown-item-text text-center">
                  <IconWarning /> Nessun comune trovato per "query"
                </span>
              }
              filterBy={(option, props) => {
                const searchStr = props.text
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "");
                const cityName = option.cNomeComune.toLowerCase();

                return cityName.includes(searchStr) || cityName === "estero";
              }}
              flip
              onBlur={onBlur}
              onChange={(selected) => {
                setValue?.(`${regionName}`, selected[0]?.cSiglaProvincia);
                setValue?.(`${name}IdObject`, selected[0]?.idObject);
                setValue?.(`${name}BExist`, selected[0]?.bExist);
                if (onSelect) {
                  onSelect(selected);
                }
                onChange(selected[0]?.cNomeComune ?? "");
                onBlur();
              }}
              // @ts-ignore
              maxHeight="500px"
              onInputChange={() => {
                setValue?.(`${regionName}`, "");
                setValue?.(`${moreName}`, "");
                setValue?.(`${name}IdObject`, "");
                setValue?.(`${name}BExist`, "");
              }}
              highlightOnlyResult
              id={id}
              defaultInputValue={value}
              inputProps={{
                id,
                autoComplete: "chrome-off",
              }}
              isInvalid={!!error}
              isLoading={!!isCityListPending}
              isValid={touched && !error}
              labelKey={(option) => ucWordsNormalizer(option.cNomeComune)}
              onSearch={onSearch}
              options={cities}
              placeholder={placeholderReal}
              promptText={
                <span className="dropdown-item-text text-center">
                  <IconInfoCircle /> Scrivi per cercare la città
                </span>
              }
              renderMenuItemChildren={(option, props) => {
                return (
                  <span>
                    {getHighlightedText(
                      ucWordsNormalizer(option.cNomeComune),
                      props.text ?? ""
                    )}{" "}
                    ({option.cSiglaProvincia})
                  </span>
                );
              }}
              searchText={
                <span className="dropdown-item-text text-center">
                  <IconSpinner className="icon-spin" /> Ricerca città in
                  corso...
                </span>
              }
            />
          );
        }}
        useCache={false}
      />
      {error && (
        <FormFeedback className="d-block">{error.message}</FormFeedback>
      )}
    </FormGroup>
  );
};
