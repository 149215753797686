import IRoutes from "../types/IRoutes";
import CapsListPage from "./ListPage";
import NewPage from "./NewPage";
import ViewPage from "./ViewPage";

const routes: IRoutes = [
  {
    component: CapsListPage,
    exact: true,
    name: "analisi",
    path: "/analisi",
    permission: "cap.read||cap.contractor-read",
  },
  {
    component: NewPage,
    name: "analisi-new",
    path: "/analisi/new/:capId?",
    permission: "cap.manage",
  },
  {
    component: ViewPage,
    name: "analisi-view",
    path: "/analisi/view/:capId?",
    permission: "cap.read||recommendations.read||cap.contractor-read",
  },
];

export default routes;
