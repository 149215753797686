import React from "react";
import {Link} from "react-router-dom";
import {Alert, Button} from "reactstrap";

import FailAccept from "../shared/FailAccept";
import AcceptReport from "./AcceptReport";

interface IProps {
  acceptBulkError?: string;
  isAcceptBulkFail: boolean;
  report: Array<{[Key: string]: string}>;

  resetFileState: () => any;
}

const SuccessAccept: React.FC<IProps> = (props) => {
  return (
    <React.Fragment>
      <Alert color="success">Creazione Advisor riuscita</Alert>
      <p>Il file caricato conteneva {props.report.length} Advisor.</p>
      <AcceptReport report={props.report} />
      {props.isAcceptBulkFail && props.acceptBulkError && (
        <FailAccept acceptBulkError={props.acceptBulkError} />
      )}
      <Button color="secondary" onClick={props.resetFileState}>
        Carica un altro file
      </Button>{" "}
      <Button tag={Link} to="/users" color="secondary">
        Torna all'elenco utenti
      </Button>
    </React.Fragment>
  );
};

export default SuccessAccept;
