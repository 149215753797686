import React from "react";
import {Route} from "react-router-dom";
import IRoutes from "../types/IRoutes";
import {ICompaniesName, IPermissionName, LoginPage} from "../User";
import NotAuthorizedPage from "../User/NotAuthorizedPage";

const checkAuthorization = (permissions: string[], permission: string) => {
  if (!permissions.includes) {
    console.log("Errore include", {permissions, permission}); // tslint:disable-line
    return false;
  }

  return permission.split("||").reduce((orsAccumulator, ors) => {
    return (
      ors.split("&&").reduce((andsAccumulator, ands) => {
        return permissions.includes(ands) && andsAccumulator;
      }, true) || orsAccumulator
    );
  }, false);
};

const checkCompany = (companies: string[], company: string) => {
  if (!companies.includes) {
    console.log("Errore include", {
      companies,
      company,
    }); // tslint:disable-line
    return false;
  }

  return company.split("||").reduce((orsAccumulator, ors) => {
    return (
      ors.split("&&").reduce((andsAccumulator, ands) => {
        return companies.includes(ands) && andsAccumulator;
      }, true) || orsAccumulator
    );
  }, false);
};

interface IMakeRoutes {
  routes: IRoutes;
  companies: ICompaniesName[];
  permissions: IPermissionName[];
  isLogged: boolean;
}

const makeRoutes = ({routes, companies, permissions, isLogged}: IMakeRoutes) =>
  routes.map(({name, permission, company, ...routeProps}) =>
    (!permission || checkAuthorization(permissions, permission)) &&
    (!company || checkCompany(companies, company)) ? (
      <Route key={name} {...routeProps} />
    ) : isLogged ? (
      <Route key={name} {...routeProps} component={NotAuthorizedPage} />
    ) : (
      <Route key={name} {...routeProps} component={LoginPage} />
    )
  );

export default makeRoutes;
