import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Button, Form, FormGroup} from "reactstrap";
import {InjectedFormProps, reduxForm} from "redux-form";
import {actions as eSignActions} from "../ESign";
import {actions as userActions} from "../User";
import {PDFTypes} from "../ESign/types";
import {focusFirstInvalid} from "../helpers/focusFirstInvalid";
import RenderMultiFieldCheckBox from "../helpers/RenderMultiFieldCheckBox";
import {IconWarning} from "../Icons";
import IconSpinner from "../Icons/IconSpinner";
import {IRootState} from "../redux/reducers";
import {IPrivacySnapshot} from "../Users";
import {IPrivacy, IPrivacyQuestion, IPrivacySections} from "./types";

export interface IPrivacySubscriptionFormData {
  [Key: string]: boolean | undefined;
}

interface IOwnProps {
  className?: string;
  form: string;
  privacy: IPrivacy;
  isUpdating: boolean;
}

type IProps = IOwnProps &
  InjectedFormProps<IPrivacySubscriptionFormData, IOwnProps>;

const validate = (values: IPrivacySubscriptionFormData, props: IOwnProps) => {
  const errors: {[Key: string]: string} = {};
  const privacySections = props.privacy
    ? (JSON.parse(props.privacy.json) as IPrivacySections)
    : undefined;

  if (!privacySections) {
    return errors;
  }

  privacySections.forEach((section) => {
    section.questions.forEach((question) => {
      if (question.required && !values[question.name]) {
        errors[question.name] = "Questo campo è obbligatorio";
      }
    });
  });

  return errors;
};

const PrivacySubscriptionForm: React.FC<IProps> = (props) => {
  const dispatch = useDispatch();
  const user = useSelector((state: IRootState) => state.user);

  const submit = async (values: IPrivacySubscriptionFormData) => {
    if (needsESign) {
      // Chiedo la firma e aggiorno la privacy
      const lastPrivacySubscription = user!.privacySubscription!;
      const lastPrivacySections = JSON.parse(
        lastPrivacySubscription.json
      ) as IPrivacySections;
      const lastPrivacyQuestions = lastPrivacySections.reduce(
        (questions, section) => {
          return questions.concat(section.questions);
        },
        [] as IPrivacyQuestion[]
      );

      const newPrivacySubscription: Partial<IPrivacySnapshot> = {
        version: lastPrivacySubscription.version,
        values: (lastPrivacyQuestions.reduce(
          (obj, question) => ({
            ...obj,
            [question.name]: !!values[question.name],
          }),
          {}
        ) as unknown) as {[Key: string]: boolean},
      };

      const jsonPrivacySubscription = JSON.stringify(newPrivacySubscription);

      await dispatch(
        eSignActions.openModal(PDFTypes.privacySubscription, user.user!.id, {
          jsonPrivacySubscription,
        })
      );
    } else {
      // Aggiorno la privacy
      await dispatch(userActions.privacySubscription(values));
    }
  };

  const privacySections = JSON.parse(props.privacy.json) as IPrivacySections;
  const needsESign = !user.user?.lastPrivacySubscriptionEsignId;

  return (
    <Form onSubmit={props.handleSubmit(submit)} className={props.className}>
      {props.isUpdating && (
        <Alert color="warning">
          <IconWarning /> Attenzione! I consensi forniti in precedenza devono
          essere aggiornati all'ultima versione
        </Alert>
      )}
      <h2 className="mb-4">
        <strong>Consensi per l'uso della piattaforma</strong>
      </h2>
      {privacySections.map((section, index) => {
        return (
          <div className="privacy-section" key={index}>
            <div dangerouslySetInnerHTML={{__html: section.text}} />
            {section.questions.map((question) => (
              <FormGroup key={question.name} className="mt-3 mb-3">
                <RenderMultiFieldCheckBox
                  id={props.form + question.name}
                  name={question.name}
                  type="checkbox"
                  items={[
                    {
                      text: question.text,
                      value: question.name,
                    },
                  ]}
                />
              </FormGroup>
            ))}
          </div>
        );
      })}
      <div className="mt-5">
        <Button
          type="submit"
          color="primary"
          outline
          disabled={props.submitting}
        >
          {props.submitting ? <IconSpinner className="icon-spin" /> : ""}{" "}
          {needsESign
            ? "Accetta e firma il consenso alla sottoscrizione"
            : "Aggiorna il consenso alla sottoscrizione"}
        </Button>
      </div>
    </Form>
  );
};

export default reduxForm<IPrivacySubscriptionFormData, IOwnProps, string>({
  onSubmitFail: focusFirstInvalid,
  validate,
})(PrivacySubscriptionForm);
