import moment from "moment";
import React, {useEffect} from "react";
import {connect, useSelector} from "react-redux";
import {Link, Redirect} from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Collapse,
  Form,
  FormGroup,
  Label,
  UncontrolledCollapse,
} from "reactstrap";
import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import {selectCompanies} from "../Caps/selectors";
import {focusFirstInvalid} from "../helpers/focusFirstInvalid";
import {birthdayFormatter} from "../helpers/formatters";
import {fiscalCodeNormalize, ucWordsNormalizer} from "../helpers/normalizers";
import RenderField from "../helpers/RenderField";
import RenderMultiField from "../helpers/RenderMultiField";
import RenderMultiFieldCheckBox from "../helpers/RenderMultiFieldCheckBox";
import {dateIt, email, fiscalCode, password} from "../helpers/validators";
import {IconSpinner} from "../Icons/";
import {IRootState} from "../redux/reducers";
import {IStateNewUser} from "./reducers";

export interface IAddUserFormData {
  email: string;
  usid: string;
  newpassword: string;
  newpassword2: string;
  // password: string;
  name: string;
  surname: string;
  birthday: moment.Moment;
  birthplace: string;
  fiscalCode: string;
  gender: "m" | "f";
  maritalStatus:
    | "celibe"
    | "coniugato"
    | "convivente"
    | "divorziato"
    | "vedovo";
  companyRole?: string;
  companyUserId?: number;
  ruiCode: string;
  dateRui: moment.Moment;
  address?: string;
  businessPhone?: string;
  city?: string;
  definitiveCollaborationCode?: string;
  id: number;
  lastLogin?: string | null; // Datetime
  personalEmail?: string;
  personalPhone?: string;
  profitCenter?: string;
  referenceSuperiorUserId?: number | null;
  region?: string;
  status: number; // Default = 1
  temporaryCollaborationCode?: string;
  vatNumber?: string;
  zipCode?: string;
  rolesId: boolean[];
  companiesId: boolean[];
  legal?: boolean;
  companyName: string;
  ruiCodeCompany: string;
  dateRuiCompany: string;
  registeredOffice: string;
  website?: string;
  pec?: string;
}

interface IOwnProps {
  onSubmit: any;
  newUser: IStateNewUser;
  loadCompanies: () => Promise<void>;
}

// interface IProps {
interface IProps extends InjectedFormProps<IAddUserFormData, IProps> {
  isDetailFail: boolean;
  detailError: string;
  // onSubmit: any;
  submitText: string;
  genderValue?: "m" | "f";
  agentValue?: true | false;
  legalValue?: boolean;
  statusValue: string;
  // user?: IDetailFormData;
  // viewUser?: IUser;
}

type Keys =
  | "name"
  | "surname"
  | "birthday"
  | "birthplace"
  | "fiscalCode"
  | "gender"
  | "maritalStatus"
  | "email"
  | "relation"
  | "usid"
  | "newpassword"
  | "newpassword2"
  | "companyRole"
  | "companyUserId"
  | "ruiCode"
  | "dateRui"
  | "address"
  | "businessPhone"
  | "city"
  | "definitiveCollaborationCode"
  | "personalEmail"
  | "personalPhone"
  | "profitCenter"
  | "referenceSuperiorUserId"
  | "region"
  | "status"
  | "temporaryCollaborationCode"
  | "vatNumber"
  | "zipCode"
  | "rolesId"
  | "companyName"
  | "ruiCodeCompany"
  | "dateRuiCompany"
  | "registeredOffice"
  | "website"
  | "pec";
type IAddUserFormErrors = {[K in Keys]?: string | string[]};

const validate = (values: IAddUserFormData) => {
  const errors: IAddUserFormErrors = {};

  if (!values.name) {
    errors.name = "Questo campo è obbligatorio";
  }
  if (!values.surname) {
    errors.surname = "Questo campo è obbligatorio";
  }
  if (!values.fiscalCode) {
    errors.fiscalCode = "Questo campo è obbligatorio";
  } else if (values.fiscalCode.length > 16) {
    errors.fiscalCode =
      "Il codice fiscale deve essere lungo al massimo 16 caratteri";
  } else if (!fiscalCode(values.fiscalCode)) {
    errors.fiscalCode = "Il codice fiscale non è nel formato corretto";
  }
  if (!values.ruiCode) {
    errors.ruiCode = "Questo campo è obbligatorio";
  }
  if (!values.dateRui) {
    errors.dateRui = "Questo campo è obbligatorio";
  }
  if (!values.usid) {
    errors.usid = "Questo campo è obbligatorio";
  }
  if (!values.email) {
    errors.email = "Questo campo è obbligatorio";
  } else if (values.email && !email(values.email)) {
    errors.email = "L'email non è nel formato corretto";
  }
  if (!password(values.newpassword)) {
    errors.newpassword =
      "La password non è nel formato corretto. Servono almeno 8 caratteri tra i quali: almeno un numero, almeno una lettera minuscola ed una maiuscola";
  }
  if (values.newpassword && !values.newpassword2) {
    errors.newpassword2 = "Questo campo è obbligatorio";
  }
  if (values.newpassword && values.newpassword !== values.newpassword2) {
    errors.newpassword2 = "Le password inserite non coincidono";
  }

  // console.log(values.rolesId); // tslint:disable-line
  // console.log(!values.rolesId || values.rolesId.filter(value => !!value)); // tslint:disable-line

  if (
    !values.rolesId ||
    values.rolesId.filter((value) => !!value).length === 0
  ) {
    errors.rolesId = [];
    errors.rolesId[2] = "Questo campo è obbligatorio";
    errors.rolesId[3] = "Questo campo è obbligatorio";
    errors.rolesId[5] = "Questo campo è obbligatorio";
    errors.rolesId[6] = "Questo campo è obbligatorio";
  }

  if (values.status === undefined) {
    errors.status = "Questo campo è obbligatorio";
  }

  if (values.status && values.status.toString() === "1") {
    if (!values.newpassword) {
      errors.newpassword = "Questo campo è obbligatorio";
    }
    if (values.newpassword && !values.newpassword2) {
      errors.newpassword2 = "Questo campo è obbligatorio";
    }
    if (values.newpassword && values.newpassword !== values.newpassword2) {
      errors.newpassword2 = "Le password inserite non coincidono";
    }
  }
  if (
    !!values.birthday &&
    (!dateIt(values.birthday as any) ||
      !moment(values.birthday, "DD-MM-YYYY").isValid())
  ) {
    errors.birthday =
      "La data di nascita non è nel formato corretto gg/mm/aaaa";
  } else if (
    !!values.birthday &&
    moment(values.birthday, "DD-MM-YYYY").isAfter(moment())
  ) {
    errors.birthday =
      "La data di nascita inserita è successiva alla data massima " +
      moment().format("DD-MM-YYYY");
  }

  if (
    !!values.dateRui &&
    (!dateIt(values.dateRui as any) ||
      !moment(values.dateRui, "DD-MM-YYYY").isValid())
  ) {
    errors.dateRui =
      "La data di iscrizione del codice RUI non è nel formato corretto gg/mm/aaaa";
  } else if (
    !!values.dateRui &&
    moment(values.dateRui, "DD-MM-YYYY").isAfter(moment())
  ) {
    errors.dateRui =
      "La data di iscrizione del codice RUI inserita è successiva alla data massima " +
      moment().format("DD-MM-YYYY");
  }

  if (values.legal) {
    if (!values.companyName) {
      errors.companyName = "Questo campo è obbligatorio";
    }
    if (!values.ruiCodeCompany) {
      errors.ruiCodeCompany = "Questo campo è obbligatorio";
    }
    if (!values.dateRuiCompany) {
      errors.dateRuiCompany = "Questo campo è obbligatorio";
    } else if (
      !dateIt(values.dateRuiCompany as any) ||
      !moment(values.dateRuiCompany, "DD-MM-YYYY").isValid()
    ) {
      errors.dateRuiCompany = "La data non è nel formato corretto gg/mm/aaaa";
    } else if (
      !!values.dateRuiCompany &&
      moment(values.dateRuiCompany, "DD-MM-YYYY").isAfter(moment())
    ) {
      errors.dateRuiCompany = "La data inserita è nel futuro";
    }
    if (!values.registeredOffice) {
      errors.registeredOffice = "Questo campo è obbligatorio";
    }
    if (!values.pec) {
      errors.pec = "Questo campo è obbligatorio";
    } else if (!!values.pec && !email(values.pec)) {
      errors.pec = "La PEC non è nel formato corretto";
    }
  }

  return errors;
};

const AddUserForm: React.FC<
  IProps & IOwnProps & InjectedFormProps<IAddUserFormData, IProps>
> = (props) => {
  const companies = useSelector(selectCompanies);
  const isLoadCompaniesPending = useSelector(
    (state: IRootState) => state.caps.ui.isLoadCompaniesPending
  );

  useEffect(() => {
    if (companies.length === 0 && !isLoadCompaniesPending) {
      props.loadCompanies().then();
    }
  }, [companies.length, isLoadCompaniesPending, props]);

  return (
    <Form onSubmit={props.handleSubmit} className="auto-margin-4">
      <Card>
        <CardHeader className="bg-primary text-white"> Dati Utente</CardHeader>
        <CardBody>
          <Alert color="info">
            <span>Tutti i dati di questa sezione sono obbligatori</span>
          </Alert>
          <Label>Ruoli*</Label>
          <RenderMultiFieldCheckBox
            id="rolesId"
            name="rolesId"
            type="checkbox"
            items={[
              {value: "rolesId[2]", text: "Advisor"},
              {value: "rolesId[3]", text: "Supervisore"},
              {value: "rolesId[5]", text: "Backoffice"},
              {value: "rolesId[6]", text: "Gestione utenti"},
            ]}
          />
          {props.agentValue === true && (
            <>
              <Label>Compagnie</Label>
              <RenderMultiFieldCheckBox
                id="companiesId"
                name="companiesId"
                type="checkbox"
                items={companies.map((company) => ({
                  value: `companiesId[${company.id}]`,
                  text: company.name,
                }))}
              />
            </>
          )}
          <FormGroup>
            <Label for="name">Nome*</Label>
            <Field
              id="name"
              name="name"
              component={RenderField}
              type="text"
              placeholder="Nome"
              normalize={ucWordsNormalizer}
            />
          </FormGroup>
          <FormGroup>
            <Label for="surname">Cognome*</Label>
            <Field
              id="surname"
              name="surname"
              component={RenderField}
              type="text"
              placeholder="Cognome"
              normalize={ucWordsNormalizer}
            />
          </FormGroup>
          {props.agentValue === true ? (
            <>
              <FormGroup>
                <Label for="fiscalCode">Codice fiscale*</Label>
                <Field
                  id="fiscalCode"
                  name="fiscalCode"
                  component={RenderField}
                  type="text"
                  placeholder="Codice fiscale"
                  normalize={fiscalCodeNormalize}
                />
              </FormGroup>
              <FormGroup>
                <Label for="ruiCode">Codice RUI*</Label>
                <Field
                  id="ruiCode"
                  name="ruiCode"
                  component={RenderField}
                  type="text"
                  placeholder="Codice RUI"
                />
              </FormGroup>
              <FormGroup>
                <Label for="dateRui">Data iscrizione Codice RUI*</Label>
                <Field
                  id="dateRui"
                  name="dateRui"
                  component={RenderField}
                  type="text"
                  placeholder="Data iscrizione Codice RUI (gg/mm/aaaa)"
                  format={birthdayFormatter}
                />
              </FormGroup>
            </>
          ) : null}
          <FormGroup>
            <Label for="usid">Nome utente*</Label>
            <Field
              id="usid"
              name="usid"
              component={RenderField}
              type="text"
              placeholder="Nome utente"
            />
          </FormGroup>
          <FormGroup>
            <Label for="email">Email aziendale*</Label>
            <Field
              name="email"
              type="email"
              id="email"
              component={RenderField}
              placeholder="Email"
            />
          </FormGroup>
          {props.agentValue === true && (
            <>
              <RenderMultiFieldCheckBox
                id="type"
                name="type"
                type="checkbox"
                items={[{value: "legal", text: "Persona giuridica"}]}
              />
              <Collapse isOpen={!!props.legalValue}>
                <Card body className=" pb-0 mb-3">
                  <FormGroup>
                    <Label for="companyName">Denominazione sociale*</Label>
                    <Field
                      name="companyName"
                      type="text"
                      id="companyName"
                      component={RenderField}
                      placeholder="Denominazione sociale"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="ruiCodeCompany">
                      N° Iscrizione RUI della società di intermediazione*
                    </Label>
                    <Field
                      name="ruiCodeCompany"
                      type="text"
                      id="ruiCodeCompany"
                      component={RenderField}
                      placeholder="N° Iscrizione RUI della società di intermediazione"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="dateRuiCompany">
                      Data iscrizione RUI della società di intermediazione*
                    </Label>
                    <Field
                      name="dateRuiCompany"
                      type="text"
                      id="dateRuiCompany"
                      component={RenderField}
                      placeholder="Data iscrizione RUI della società di intermediazione (gg/mm/aaaa)"
                      format={birthdayFormatter}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="registeredOffice">Sede legale*</Label>
                    <Field
                      name="registeredOffice"
                      type="text"
                      id="registeredOffice"
                      component={RenderField}
                      placeholder="Sede legale"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="pec">PEC*</Label>
                    <Field
                      name="pec"
                      type="text"
                      id="pec"
                      component={RenderField}
                      placeholder="PEC"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="website">Sito web</Label>
                    <Field
                      name="website"
                      type="text"
                      id="website"
                      component={RenderField}
                      placeholder="Sito web"
                    />
                  </FormGroup>
                </Card>
              </Collapse>
            </>
          )}
          <FormGroup>
            <Label for="status">Stato utente*</Label>
            <Field
              id="status"
              name="status"
              component={RenderField}
              type="select"
            >
              <option value="0">In attesa di attivazione</option>
              <option value="1">Attivo</option>
              <option value="-1">Accesso disabilitato</option>
            </Field>
          </FormGroup>
          {props.statusValue === "1" ? (
            <>
              <FormGroup>
                <Label for="newpassword">Password*</Label>
                <Field
                  id="newpassword"
                  name="newpassword"
                  component={RenderField}
                  type="password"
                  placeholder="Password"
                />
              </FormGroup>
              <FormGroup>
                <Label for="newpassword2">Ripetere la Password*</Label>
                <Field
                  id="newpassword2"
                  name="newpassword2"
                  component={RenderField}
                  type="password"
                  placeholder="Ripetere la Password"
                />
              </FormGroup>
            </>
          ) : null}
        </CardBody>
      </Card>
      <Card>
        <CardHeader
          className="bg-primary text-white card-header-toggle"
          id="togglerContatti"
        >
          Contatti aggiuntivi
        </CardHeader>
        <UncontrolledCollapse toggler="#togglerContatti">
          <CardBody>
            <FormGroup>
              <Label for="businessPhone">Telefono aziendale</Label>
              <Field
                id="businessPhone"
                name="businessPhone"
                component={RenderField}
                type="text"
                placeholder="Telefono aziendale"
              />
            </FormGroup>
            <FormGroup>
              <Label for="personalPhone">Telefono Personale</Label>
              <Field
                id="personalPhone"
                name="personalPhone"
                component={RenderField}
                type="text"
                placeholder="Telefono Personale"
              />
            </FormGroup>
            <FormGroup>
              <Label for="personalEmail">Email Personale</Label>
              <Field
                id="personalEmail"
                name="personalEmail"
                component={RenderField}
                type="text"
                placeholder="Email Personale"
              />
            </FormGroup>
          </CardBody>
        </UncontrolledCollapse>
      </Card>
      <Card>
        <CardHeader
          className="bg-primary text-white card-header-toggle"
          id="togglerAzienda"
        >
          Dati aziendali
        </CardHeader>
        <UncontrolledCollapse toggler="#togglerAzienda">
          <CardBody>
            <FormGroup>
              <Label for="companyRole">Ruolo aziendale</Label>
              <Field
                id="companyRole"
                name="companyRole"
                component={RenderField}
                type="text"
                placeholder="Ruolo aziendale"
              />
            </FormGroup>
            <FormGroup>
              <Label for="profitCenter">Area geografica di riferimento</Label>
              <Field
                id="profitCenter"
                name="profitCenter"
                component={RenderField}
                type="text"
                placeholder="Area geografica di riferimento"
              />
            </FormGroup>
            <FormGroup>
              <Label for="temporaryCollaborationCode">
                Codice di collaborazione temporaneo
              </Label>
              <Field
                id="temporaryCollaborationCode"
                name="temporaryCollaborationCode"
                component={RenderField}
                type="text"
                placeholder="Codice di collaborazione temporaneo"
              />
            </FormGroup>
            <FormGroup>
              <Label for="definitiveCollaborationCode">
                Codice di collaborazione definitivo
              </Label>
              <Field
                id="definitiveCollaborationCode"
                name="definitiveCollaborationCode"
                component={RenderField}
                type="text"
                placeholder="Codice di collaborazione definitivo"
              />
            </FormGroup>
            <FormGroup>
              <Label for="companyUserId">
                Identificativo univoco aziendale
              </Label>
              <Field
                id="companyUserId"
                name="companyUserId"
                component={RenderField}
                type="text"
                placeholder="Identificativo univoco aziendale"
              />
            </FormGroup>
            <FormGroup>
              <Label for="referenceSuperiorUserId">
                Identificativo univoco del diretto superiore in struttura
              </Label>
              <Field
                id="referenceSuperiorUserId"
                name="referenceSuperiorUserId"
                component={RenderField}
                type="text"
                placeholder="Identificativo univoco del diretto superiore in struttura"
              />
            </FormGroup>
          </CardBody>
        </UncontrolledCollapse>
      </Card>
      <Card>
        <CardHeader
          className="bg-primary text-white card-header-toggle"
          id="togglerPersona"
        >
          {" "}
          Dati personali
        </CardHeader>
        <UncontrolledCollapse toggler="#togglerPersona">
          <CardBody>
            <FormGroup>
              <Label for="address">Indirizzo</Label>
              <Field
                id="address"
                name="address"
                component={RenderField}
                type="text"
                placeholder="Indirizzo"
                normalize={ucWordsNormalizer}
              />
            </FormGroup>
            <FormGroup>
              <Label for="city">Città</Label>
              <Field
                id="city"
                name="city"
                component={RenderField}
                type="text"
                placeholder="Città"
                normalize={ucWordsNormalizer}
              />
            </FormGroup>
            <FormGroup>
              <Label for="zipCode">Codice Postale</Label>
              <Field
                id="zipCode"
                name="zipCode"
                component={RenderField}
                type="text"
                placeholder="Codice Postale"
              />
            </FormGroup>
            <FormGroup>
              <Label for="region">Provincia</Label>
              <Field
                id="region"
                name="region"
                component={RenderField}
                type="text"
                placeholder="Provincia"
                normalize={ucWordsNormalizer}
              />
            </FormGroup>
            <FormGroup>
              <Label for="birthday">Data di nascita</Label>
              <Field
                id="birthday"
                name="birthday"
                component={RenderField}
                type="text"
                placeholder="Data di nascita (gg/mm/aaaa)"
                format={birthdayFormatter}
              />
            </FormGroup>
            <FormGroup>
              <Label for="birthplace">Luogo di nascita</Label>
              <Field
                id="birthplace"
                name="birthplace"
                component={RenderField}
                type="text"
                placeholder="Luogo di nascita"
                normalize={ucWordsNormalizer}
              />
            </FormGroup>
            <Label>Genere</Label>
            <RenderMultiField
              id="gender"
              name="gender"
              type="radio"
              items={[
                {value: "m", text: "Maschio"},
                {value: "f", text: "Femmina"},
              ]}
            />
            <FormGroup>
              <Label for="maritalStatus">Stato civile</Label>
              <Field
                id="maritalStatus"
                name="maritalStatus"
                component={RenderField}
                type="select"
              >
                <option value="">Seleziona</option>
                <option value="celibe">
                  {props.genderValue
                    ? props.genderValue === "m"
                      ? "Celibe"
                      : "Nubile"
                    : "Celibe / Nubile"}
                </option>
                <option value="coniugato">
                  {props.genderValue
                    ? props.genderValue === "m"
                      ? "Sposato"
                      : "Sposata"
                    : "Sposato / a"}
                </option>
                <option value="convivente">Convivente</option>
                <option value="divorziato">
                  {props.genderValue
                    ? props.genderValue === "m"
                      ? "Divorziato"
                      : "Divorziata"
                    : "Divorziato / a"}
                </option>
                <option value="vedovo">
                  {props.genderValue
                    ? props.genderValue === "m"
                      ? "Vedovo"
                      : "Vedova"
                    : "Vedovo / a"}
                </option>
              </Field>
            </FormGroup>
          </CardBody>
        </UncontrolledCollapse>
      </Card>
      {props.newUser.isSuccess ? <Redirect to="/users" /> : null}
      {props.newUser.isFail && (
        <Alert color="danger" className="mt-3 mb-1">
          {props.newUser.error}
          <br />
          Clicca sul seguente link per modificare l'utente già inserito{" "}
          <Link to={`/users/edit/${props.newUser.userId}`}>
            <Button type="button" color="secondary">
              Utente già inserito
            </Button>
          </Link>
        </Alert>
      )}
      {props.isDetailFail ? (
        <Alert color="danger">{props.detailError}</Alert>
      ) : null}
      <div>
        <Button type="submit" color="primary" disabled={props.submitting}>
          {props.submitting ? <IconSpinner className="icon-spin" /> : ""} Crea
          Utente
        </Button>{" "}
        <Link
          to={{
            pathname: `/users`,
          }}
        >
          <Button type="button" color="secondary">
            Annulla
          </Button>
        </Link>
      </div>
    </Form>
  );
};

const AddUserFormConnected = reduxForm<
  IAddUserFormData,
  any,
  string | string[]
>({
  form: "addUserForm",
  onSubmitFail: focusFirstInvalid,
  validate,
})(AddUserForm);

const selector = formValueSelector("addUserForm");

// export default connect((state: IRootState) => {
export default connect((state: IRootState) => {
  const genderValue = selector(state, "gender");
  const agentValue = selector(state, "rolesId[2]");
  const statusValue = selector(state, "status");
  const legalValue = selector(state, "legal");

  const initialValues = {
    status: "0",
  };

  return {
    legalValue,
    agentValue,
    genderValue,
    initialValues,
    statusValue,
  };
})(AddUserFormConnected);
