import React, {useCallback, useEffect, useState} from "react";
import {connect} from "react-redux";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  FormText,
  Label,
} from "reactstrap";
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import {focusFirstInvalid} from "../helpers/focusFirstInvalid";
import {
  emailNormalizer,
  fiscalCodeNormalize,
  ucArubaNormalizer,
} from "../helpers/normalizers";
import RenderField from "../helpers/RenderField";
import {email, fiscalCode} from "../helpers/validators";
import {IconSpinner, IconWarning} from "../Icons";
import {IRootState} from "../redux/reducers";
import {dehydrateUser, IUser, IUserEntity} from "../Users";
import {ICreateContractorFormData} from "./CreateContractorForm";
import ManageContractorConfirmModal from "./ManageContractorConfirmModal";
import {IStateUi} from "./reducers";

export interface IUpdateContractorFormData {
  name: string;
  surname: string;
  fiscalCode: string;
  email: string;
  email2: string;
}

type IUpdateContractorFormErrors = Partial<IUpdateContractorFormData>;

interface IOwnProps {
  contractor: IUser;
  closeUpdateContractorModal: () => void;
  updateContractorSubmit: (
    values: IUpdateContractorFormData,
    contractor: IUserEntity
  ) => Promise<void>;
  useExistingContractorSubmit: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => Promise<void>;
  ui: IStateUi;
}

type IProps = IOwnProps & InjectedFormProps<IUpdateContractorFormData>;

const validate = (values: IUpdateContractorFormData) => {
  const errors: IUpdateContractorFormErrors = {};
  const arubaRegexp = /^[\p{L}\s,.'-]+$/u;

  if (!values.name) {
    errors.name = "Il nome è obbligatorio";
  } else if (!values.name.match(arubaRegexp)) {
    errors.name =
      "Formato nome non corretto, sono ammessi solo lettere, spazi e i simboli . , ' -";
  }

  if (!values.surname) {
    errors.surname = "Il cognome è obbligatorio";
  } else if (!values.surname.match(arubaRegexp)) {
    errors.name =
      "Formato nome non corretto, sono ammessi solo lettere, spazi e i simboli . , ' -";
  }

  if (!values.fiscalCode) {
    errors.fiscalCode = "Il codice fiscale è obbligatorio";
  } else if (values.fiscalCode.length > 16) {
    errors.fiscalCode =
      "Il codice fiscale deve essere lungo al massimo 16 caratteri";
  } else if (!fiscalCode(values.fiscalCode)) {
    errors.fiscalCode = "Il codice fiscale non è nel formato corretto";
  }

  if (!values.email) {
    errors.email = "L'email è obbligatoria";
  } else if (values.email && !email(values.email)) {
    errors.email = "L'email non è nel formato corretto";
  }
  if (!!values.email && values.email !== values.email2) {
    errors.email2 = "Gli indirizzi e-mail non coincidono";
  }

  return errors;
};

const UpdateContractorForm: React.FC<IProps & IOwnProps> = (props) => {
  const [modal, setModal] = useState<{
    isOpen: boolean;
    confirmed: boolean;
  }>({isOpen: false, confirmed: false});

  const closeModal = useCallback(() => {
    console.log("close");
    setModal({isOpen: false, confirmed: false});
  }, []);

  const confirmOrSubmit = useCallback(
    async (values: ICreateContractorFormData) => {
      console.log("modal.confirmed", modal.confirmed);
      if (modal.confirmed) {
        setModal({isOpen: false, confirmed: false});
        const {
          result,
          entities: {users},
        } = dehydrateUser(props.contractor);
        await props.updateContractorSubmit(values, users[result]);
      } else {
        setModal({isOpen: true, confirmed: false});
      }
    },
    [modal.confirmed, props]
  );

  const handleConfirm = () => {
    setModal({isOpen: false, confirmed: true});
  };

  useEffect(() => {
    if (modal.confirmed) {
      props.handleSubmit(confirmOrSubmit)();
    }
  }, [confirmOrSubmit, modal.confirmed, props]);

  return (
    <>
      <Form onSubmit={props.handleSubmit(confirmOrSubmit)}>
        <Card color="primary" outline className=" auto-margin-3">
          <CardHeader className="bg-primary text-white">
            Dati fondamentali per la firma elettronica
          </CardHeader>
          <CardBody>
            <Alert color="warning">
              Attenzione! Nel caso questi dati non fossero inseriti
              correttamente in questo modulo,{" "}
              <strong>
                tutte le firme apposte non saranno valide e andrà ripetuto
                l'intero processo dall'inizio
              </strong>
              .
            </Alert>{" "}
            <FormGroup>
              <Label for="name">
                Nome, esattamente come scritto nel documento d'identità:
              </Label>
              <Field
                id="name"
                name="name"
                component={RenderField}
                type="text"
                placeholder="Nome come riportato nel documento d'identità"
                normalize={ucArubaNormalizer}
              />
            </FormGroup>
            <FormGroup>
              <Label for="surname">
                Cognome, esattamente come scritto nel documento d'identità:
              </Label>
              <Field
                id="surname"
                name="surname"
                type="text"
                component={RenderField}
                placeholder="Cognome come riportato nel documento d'identità"
                normalize={ucArubaNormalizer}
              />
            </FormGroup>
            <FormGroup>
              <Label for="fiscalCode">Codice fiscale</Label>
              <Field
                id="fiscalCode"
                name="fiscalCode"
                component={RenderField}
                type="text"
                placeholder="Codice fiscale"
                normalize={fiscalCodeNormalize}
              />
            </FormGroup>
          </CardBody>
        </Card>
        <FormGroup>
          <Label for="email">E-mail del cliente</Label>
          <Field
            id="email"
            name="email"
            component={RenderField}
            normalize={emailNormalizer}
            type="email"
            placeholder="E-mail"
          />
          <FormText color="muted">
            Inserisci l'indirizzo e-mail usato con maggior frequenza dal
            cliente.
          </FormText>
        </FormGroup>
        <FormGroup>
          <Label for="email2">Inserisci nuovamente l'e-mail del cliente</Label>
          <Field
            id="email2"
            name="email2"
            component={RenderField}
            normalize={emailNormalizer}
            type="email"
            placeholder="Reinserisci e-mail"
          />
        </FormGroup>
        {props.ui.isUpdateContractorFail && props.ui.updateContractorError && (
          <Alert color="danger">{props.ui.updateContractorError}</Alert>
        )}
        {props.ui.isUpdateContractorFail ? (
          <>
            <Alert color="warning">
              <IconWarning /> L'utente che stai cercando di attivare è già
              presente in anagrafica.
            </Alert>
            <Button
              type="button"
              color="warning"
              outline
              onClick={props.useExistingContractorSubmit}
            >
              Utilizza il cliente già presente nel sistema
            </Button>{" "}
          </>
        ) : (
          <>
            <Button
              type="submit"
              color="primary"
              outline
              disabled={props.submitting}
            >
              {props.submitting ? <IconSpinner className="icon-spin" /> : ""}{" "}
              Salva contatti del cliente
            </Button>{" "}
          </>
        )}
        <Button
          type="button"
          color="secondary"
          outline
          onClick={props.closeUpdateContractorModal}
        >
          Annulla
        </Button>
      </Form>
      <ManageContractorConfirmModal
        isOpen={modal.isOpen}
        onConfirm={handleConfirm}
        toggle={closeModal}
      />
    </>
  );
};

const UpdateContractorFormConnected = reduxForm({
  form: "uploadContractorForm",
  onSubmitFail: focusFirstInvalid,
  validate,
})(UpdateContractorForm);

export default connect((state: IRootState, ownProps: IOwnProps) => {
  const initialValues = ownProps.contractor
    ? {
        email: ownProps.contractor.email,
        email2: ownProps.contractor.email,
        fiscalCode: ownProps.contractor.fiscalCode,
        name: ownProps.contractor.name,
        surname: ownProps.contractor.surname,
      }
    : {};

  return {
    initialValues,
  };
})(UpdateContractorFormConnected);
