import React from "react";

import Navbar from "./Navbar";

const Header = ({isProtected}: {isProtected: boolean}) => (
  <div id="app-header">
    <Navbar isProtected={isProtected} />
  </div>
);

export default Header;
