import React from "react";
import {useDispatch} from "react-redux";
import {Alert, Button, Form, FormGroup} from "reactstrap";
import {InjectedFormProps, reduxForm} from "redux-form";
import {focusFirstInvalid} from "../helpers/focusFirstInvalid";
import RenderMultiFieldCheckBox from "../helpers/RenderMultiFieldCheckBox";
import {IconWarning} from "../Icons";
import IconSpinner from "../Icons/IconSpinner";
import {IUser} from "../Users";
import {actions as userActions} from "./actions";
import {IPrivacy, IPrivacySections} from "./types";

export interface IPrivacyFormData {
  [Key: string]: boolean | undefined;
}

interface IOwnProps {
  className?: string;
  form: string;
  isPrivacyFail?: boolean;
  openRequestOTPModal?: (userId: string) => void;
  privacy: IPrivacy;
  privacyError?: string;
  privacySubmit: (values: IPrivacyFormData) => Promise<void>;
  user: IUser | null;
  isUpdating: boolean;
}

type IProps = IOwnProps & InjectedFormProps<IPrivacyFormData, IOwnProps>;

const validate = (values: IPrivacyFormData, props: IOwnProps) => {
  const errors: {[Key: string]: string} = {};
  const privacySections = props.privacy
    ? (JSON.parse(props.privacy.json) as IPrivacySections)
    : undefined;

  if (!privacySections) {
    return errors;
  }

  privacySections.forEach((section) => {
    section.questions.forEach((question) => {
      if (question.required && !values[question.name]) {
        errors[question.name] = "Questo campo è obbligatorio";
      }
    });
  });

  return errors;
};

const PrivacyForm: React.FC<IProps> = (props) => {
  const submit = async (values: IPrivacyFormData) => {
    await props.privacySubmit(values);
    if (props.openRequestOTPModal) {
      props.openRequestOTPModal(props.user!.id);
    }
  };

  const dispatch = useDispatch();
  const logoutClickHandler = () => {
    dispatch(userActions.logout());
  };

  const privacySections = JSON.parse(props.privacy.json) as IPrivacySections;

  return (
    <Form onSubmit={props.handleSubmit(submit)} className={props.className}>
      {props.isUpdating && (
        <Alert color="warning">
          <IconWarning /> Attenzione! I consensi forniti in precedenza devono
          essere aggiornati all'ultima versione
        </Alert>
      )}
      <h2 className="mb-4">
        <strong>Consensi per l'uso della piattaforma</strong>
      </h2>
      {privacySections.map((section, index) => {
        return (
          <div className="privacy-section" key={index}>
            <div dangerouslySetInnerHTML={{__html: section.text}} />
            {section.questions.map((question) => (
              <FormGroup key={question.name} className="mt-3 mb-3">
                <RenderMultiFieldCheckBox
                  id={props.form + question.name}
                  name={question.name}
                  type="checkbox"
                  items={[
                    {
                      text: question.text,
                      value: question.name,
                    },
                  ]}
                />
              </FormGroup>
            ))}
          </div>
        );
      })}
      {props.isPrivacyFail && (
        <Alert color="danger">{props.privacyError}</Alert>
      )}
      <div className="mt-5">
        <Button
          type="submit"
          color="primary"
          outline
          disabled={props.submitting}
        >
          {props.submitting ? <IconSpinner className="icon-spin" /> : ""}{" "}
          {props.openRequestOTPModal
            ? "Accetta e firma il consenso"
            : "Salva il consenso"}
        </Button>
        {props.form === "privacyFormESign" && (
          <Button
            color="link"
            type="button"
            className="ml-2"
            onClick={logoutClickHandler}
          >
            Esci dalla tua area riservata, potrai firmare la privacy al tuo
            prossimo accesso
          </Button>
        )}
      </div>
    </Form>
  );
};

export default reduxForm<IPrivacyFormData, IOwnProps, string>({
  onSubmitFail: focusFirstInvalid,
  validate,
})(PrivacyForm);
