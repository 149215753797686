import IRoutes from "../types/IRoutes";
import AddPage from "./AddUserPage";
import CreateBulkPage from "./bulks/CreatePageContainer";
import DeleteBulkPage from "./bulks/DeletePageContainer";
import UpdateBulkPage from "./bulks/UpdatePageContainer";
import DetailPage from "./DetailPage";
import ListPage from "./ListPage";

import HelpPage from "../App/HelpPage";

const routes: IRoutes = [
  {
    component: AddPage,
    name: "user-new",
    path: "/user/new",
    permission: "users.manage",
  },
  {
    component: ListPage,
    exact: true,
    name: "users",
    path: "/users",
    permission: "users.manage",
  },
  {
    component: HelpPage,
    exact: true,
    name: "help",
    path: "/help",
  },
  {
    component: DetailPage,
    name: "users-edit",
    path: "/users/edit/:userId?",
    permission: "users.manage",
  },
  {
    component: CreateBulkPage,
    name: "users-create-bulk",
    path: "/users/create-bulk",
    permission: "users.manage",
  },
  {
    component: DeleteBulkPage,
    name: "users-delete-bulk",
    path: "/users/delete-bulk",
    permission: "users.manage",
  },
  {
    component: UpdateBulkPage,
    name: "users-update-bulk",
    path: "/users/update-bulk",
    permission: "users.manage",
  },
];

export default routes;
