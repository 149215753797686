import animateScrollTo from "animated-scroll-to";
import React from "react";
import {connect} from "react-redux";
import {RouteComponentProps} from "react-router";
import {Layout} from "../App";
import {actions as eSignActions} from "../ESign";
import {PDFTypes} from "../ESign/types";
import {IRootState} from "../redux/reducers";
import IApiError from "../types/IApiError";
import {actions as userActions, IState as IUserState} from "../User";
import {getUser} from "../User/selectors";
import {IUser} from "../Users";
import {actions as optionsActions} from "./actions/options";
import {
  Actions,
  actions,
  ICap,
  IInvestmentOption,
  IStateUi,
  NewCapModalTypes,
} from "./";
import {getCap, getIsContractor, getOptions} from "./selectors";
import {IUploadIdFormData} from "./UploadIdForm";
import ViewCap from "./ViewCap";

interface IStateProps {
  isContractor: boolean;
  loggedUser: IUser | null;
  ui: IStateUi;
  userUi: IUserState;
  options?: IInvestmentOption[];
  viewCap?: ICap;
}
type IOwnProps = RouteComponentProps<IMatchParams>;

interface IDispatchProps {
  closeModal: (modalType: NewCapModalTypes) => Actions;
  closeValidation: (capId: string, responseValidation: 0 | 1) => Promise<void>;
  openModal: (modalType: NewCapModalTypes) => Actions;
  optionsList: () => Promise<void>;
  openRequestOTPModal: (capId: string) => void;
  openRequestOTPPrivacyModal: (userId: string) => void;
  openRequestOTPIdentificationModal: (capId: string) => void;
  uploadIdFail: (error: IApiError) => void;
  uploadIdSubmit: (values: IUploadIdFormData) => Promise<void>;
  viewFromDb: (capId: string) => Promise<void>;
  privacyOpenModal: () => void;
}

interface IMatchParams {
  capId: string;
}

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => ({
  isContractor: getIsContractor(state, ownProps.match.params.capId),
  loggedUser: getUser(state),
  options: getOptions(state),
  ui: state.caps.ui,
  userUi: state.user,
  viewCap: getCap(state, ownProps.match.params.capId),
});

const mapDispatchToProps = {
  closeModal: (modalType: NewCapModalTypes) => {
    return actions.closeNewCapModal(modalType);
  },
  closeValidation: (capId: string, responseValidation: 0 | 1) => {
    return actions.closeValidation(capId, responseValidation);
  },
  openModal: (modalType: NewCapModalTypes) => {
    return actions.openNewCapModal(modalType);
  },
  openRequestOTPModal: (capId: string) => {
    return eSignActions.openModal(PDFTypes.cap, capId);
  },
  openRequestOTPPrivacyModal: (userId: string) => {
    return eSignActions.openModal(PDFTypes.privacy, userId);
  },
  openRequestOTPIdentificationModal: (capId: string) => {
    return eSignActions.openModal(PDFTypes.identification, capId);
  },
  optionsList: () => {
    return optionsActions.loadOptions();
  },
  privacyOpenModal: () => {
    return userActions.privacyOpenModal();
  },
  uploadIdFail: (error: IApiError) => {
    return userActions.uploadIdFail(error);
  },
  uploadIdSubmit: (values: IUploadIdFormData) => {
    return userActions.uploadId(values);
  },
  viewFromDb: (capId: string) => {
    return actions.viewFromDb(capId);
  },
};

type IProps = IStateProps & IDispatchProps & IOwnProps;

class ViewPage extends React.Component<IProps> {
  public componentDidMount() {
    this.props.viewFromDb(this.props.match.params.capId).then(() => {
      ["close-cap", "subscription-privacy", "identification"].forEach((id) => {
        if (this.props.location.hash === `#${id}`) {
          setTimeout(() => {
            const targetDiv = document.getElementById(id);
            if (targetDiv) {
              const top = targetDiv.offsetTop - 16;
              animateScrollTo(top).then();
            }
          }, 1000);
        }
      });
    });

    // Se l'utente loggato è il contractor del cap che si sta visualizzando e non ha firmato la privacy apro il popup
    // Serve per gestire il caso degli advisor che diventano anche contractor
    // Collegato al didUpdate ⬇
    if (
      this.props.viewCap?.id &&
      this.props.isContractor &&
      !this.props.loggedUser?.lastPrivacyEsignId
    ) {
      this.props.privacyOpenModal();
    }
  }

  public componentDidUpdate(prevProps: Readonly<IProps>) {
    // Se l'utente loggato è il contractor del cap che si sta visualizzando e non ha firmato la privacy apro il popup
    // Serve per gestire il caso degli advisor che diventano anche contractor
    // Collegato al mount ⬆
    if (
      !prevProps.viewCap?.id &&
      this.props.viewCap?.id &&
      this.props.isContractor &&
      !this.props.loggedUser?.lastPrivacyEsignId
    ) {
      this.props.privacyOpenModal();
    }
  }

  public render() {
    return (
      <Layout isProtected>
        <ViewCap {...this.props} />
      </Layout>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewPage);
