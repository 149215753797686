import {ICap} from "../Caps/types";

export const showAttachments3And4 = (cap: ICap) => {
  const startDateString = process.env.REACT_APP_DATE_REMOVE_ALLEGATI_34;
  const closeDateString = cap.dateResponseValidation?.format("YYYY-MM-DD");
  const now = new Date();

  if (!startDateString) {
    return true;
  }
  const startDate = new Date(startDateString);

  if (!closeDateString) {
    return now < startDate;
  }
  const closeDate = new Date(closeDateString);

  return closeDate < startDate;
};
