import moment from "moment";
import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import {IconPencil, IconWarning} from "../Icons";
import {IUser, MaritalStatuses} from "../Users";

interface IProps {
  openUpdateEmail?: () => void;
  contractor: IUser;
}

const getMaritalStatus = (
  gender: "m" | "f",
  maritalStatus: MaritalStatuses
): string => {
  switch (maritalStatus) {
    case "celibe":
      return gender === "m" ? "Celibe" : "Nubile";
    case "coniugato":
      return gender === "m" ? "Sposato" : "Sposata";
    case "convivente":
      return "Convivente";
    case "divorziato":
      return gender === "m" ? "Divorziato" : "Divorziata";
    case "vedovo":
      return gender === "m" ? "Vedovo" : "Vedova";
  }
};

const PeopleTable: React.FC<IProps> = ({contractor, openUpdateEmail}) => (
  <Row key={contractor.id} className="align-items-center">
    <Col sm="6">
      <CardText>
        {contractor.surname} {contractor.name},
        {contractor.gender === "m" ? " nato" : " nata"} il{" "}
        {moment(contractor.birthday).format("LL")} a {contractor.birthplace}.
      </CardText>
      <CardText>
        Sesso: {contractor.gender === "m" ? "Maschio" : "Femmina"}
        <br />
        Stato civile:{" "}
        {getMaritalStatus(contractor.gender!, contractor.maritalStatus!)}
        <br />
        CF: {contractor.fiscalCode}
      </CardText>
    </Col>
    <Col sm="6" className="align-self-center">
      <Card className="text-white bg-primary">
        <CardBody className="">
          <CardTitle>Contatti</CardTitle>
          <CardText>
            E-mail:{" "}
            {contractor.email ? (
              contractor.email
            ) : (
              <span className="text-warning">
                <IconWarning /> dato mancante
              </span>
            )}{" "}
            {openUpdateEmail && (
              <Button
                color="link"
                size="sm"
                className="text-light"
                style={{marginTop: "-4px"}}
                onClick={openUpdateEmail.bind({}, contractor)}
              >
                <IconPencil title="Modifica email del cliente" />
              </Button>
            )}
            <br />
            Telefono:{" "}
            {contractor.personalPhone ? (
              contractor.personalPhone
            ) : (
              <span className="text-warning">
                <IconWarning /> dato mancante
              </span>
            )}
          </CardText>
        </CardBody>
      </Card>
    </Col>
  </Row>
);
export default PeopleTable;
