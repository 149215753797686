import React from "react";
import IconBase from "./IconBase";

const IconArchive = (props: any) => (
  <IconBase {...props}>
    <path d="M2 28c0 1.1.9 2 2 2h24a2 2 0 002-2V10H2v18zm10-13.25c0-.41.34-.75.75-.75h6.5c.41 0 .75.34.75.75v.5c0 .41-.34.75-.75.75h-6.5a.75.75 0 01-.75-.75v-.5zM30 2H2a2 2 0 00-2 2v3a1 1 0 001 1h30a1 1 0 001-1V4a2 2 0 00-2-2z" />
  </IconBase>
);

export default IconArchive;
