import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import {IconDelivery, IconTimes} from "../../Icons";
import {IRootState} from "../../redux/reducers";
import {actions} from "../actions";
import {getCap, getCapsUi} from "../selectors";
import {NewCapModalTypes} from "../types";
import DeliveryForm, {IDeliveryFormData} from "./DeliveryForm";

interface IProps {
  capId: string;
  hasIbips: boolean;
  hasLifeNotIbips: boolean;
  hasLoss: boolean;
  hasOverdrafts: boolean;
  place: string | undefined;
  toggle: () => void;
}

const DeliveryModal = ({
  capId,
  hasIbips,
  hasLifeNotIbips,
  hasLoss,
  hasOverdrafts,
  place,
  toggle,
}: IProps) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(getCapsUi).isModalOpen[NewCapModalTypes.Delivery];
  const handleSubmit = (values: IDeliveryFormData) => {
    dispatch(actions.delivery(values, capId));
  };
  const deliveryValues = useSelector((state: IRootState) =>
    getCap(state, capId)
  )?.delivery?.values;

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
      keyboard={false}
      className="modal-form"
    >
      <ModalHeader
        toggle={toggle}
        close={
          <Button size="sm" className="close-modal" onClick={toggle}>
            <IconTimes
              size={1.25}
              className="icon-border-2 rounded-circle p-1"
            />
          </Button>
        }
      >
        <IconDelivery size={3} className="icon-border-2 rounded-circle p-2" />
        Dichiarazione sostitutiva di avvenuta consegna
      </ModalHeader>
      <ModalBody>
        <p>Selezionare la tipologia di prodotti proposti.</p>
        <DeliveryForm
          hasIbips={hasIbips}
          hasLifeNotIbips={hasLifeNotIbips}
          hasLoss={hasLoss}
          hasOverdrafts={hasOverdrafts}
          initialValues={{...{place}, ...deliveryValues}}
          onCancel={toggle}
          onSubmit={handleSubmit}
        />
      </ModalBody>
    </Modal>
  );
};

export default DeliveryModal;
