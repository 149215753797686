import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {DeepReadonly} from "utility-types";
import {actionTypes as userActionTypes} from "../../User";
import {IBeneProposalParams} from "../types";
import * as commonSelectors from "./common/selectors";
import {create, get, list, resend} from "./common/thunks";

type IState = DeepReadonly<{
  chosenCap?: string;
  lastCreatedId?: string;
  tempCongruenceFilePath?: string;
  eSignId?: string;
  eSignIdError?: string;
  list: string[];
  listLength: number;
  listParams: IBeneProposalParams;
}>;

const beneProposalsInitialState: IState = {
  list: [],
  listLength: 0,
  listParams: {limit: 50, offset: 0},
};

const common = createSlice({
  name: "beneProposals/common",
  initialState: beneProposalsInitialState,
  reducers: {
    choseCap: (state, action: PayloadAction<{capId: string | undefined}>) => {
      state.chosenCap = action.payload.capId;
    },
    congruenceFileCreated: (
      state,
      action: PayloadAction<{
        path: string | undefined;
        eSignId: string | undefined;
      }>
    ) => {
      state.tempCongruenceFilePath = action.payload.path;
      state.eSignId = action.payload.eSignId;
    },
    setEsignIdError: (
      state,
      action: PayloadAction<{message: string | undefined}>
    ) => {
      state.eSignIdError = action.payload.message;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(list.fulfilled, (state, action) => {
        state.list = action.payload.beneProposalsIds;
        state.listLength = action.payload.numBeneProposals;
        state.listParams = action.payload.params;
      })
      .addCase(create.fulfilled, (state, action) => {
        state.lastCreatedId = action.payload.beneProposalsId;
        const win = window.open(
          `${process.env.REACT_APP_BENE_BASE_URL}portal/#!/accessoprodotto?uid=${action.payload.uid}`,
          "_blank",
          "noopener"
        );
        win?.focus();
      })
      .addCase(create.rejected, (state, action) => {
        state.lastCreatedId = action.payload?.beneProposalsId;
      })
      .addCase(create.reset, (state, action) => {
        delete state.lastCreatedId;
      })
      .addCase(resend.fulfilled, (state, action) => {
        const win = window.open(
          `${process.env.REACT_APP_BENE_BASE_URL}portal/#!/accessoprodotto?uid=${action.payload.uid}`,
          "_blank",
          "noopener"
        );
        win?.focus();
      })
      .addCase(userActionTypes.LOGOUT_SUCCESS, () => {
        return beneProposalsInitialState;
      });
  },
});

export const selectors = commonSelectors;

export const actions = {...common.actions, create, get, list, resend};
export const reducer = common.reducer;
