/* tslint:disable object-literal-sort-keys */

import IApiError from "../types/IApiError";

export enum ErrorCodes {
  NO_CAP_ID = 1,
  FILE_TOO_BIG = 2,
  FILE_MULTIPLE = 3,
  FAILED_TO_FETCH = 4,
  OPTIONS_TOTAL_NOT_100 = 5,
  JSON_ID_UNDEFINED = 6,
  JSON_PRIVACY_UNDEFINED = 7,
  GENERIC_SERVER_ERROR = 8,
  BENE_UID_NOT_VALID = 9,
  BENE_NON_BLOCKING_ERROR = 10,
  CAP_NOT_FOUND = 301,
  MISSING_USER_IN_CAP_CREATION = 316,
  WRONG_PROPOSAL_CODE = 811,
  USER_WITH_CF_ALREADY_EXISTS = 922,
  USER_WITH_USID_ALREADY_EXISTS = 923,
  USER_WITH_EMAIL_ALREADY_EXISTS = 924,
  USER_WITH_CAP_WITH_ANOTHER_ADVISOR = 938,
  ID_FILE_TOO_BIG = 925,
  ID_FILE_MULTIPLE = 926,
  ID_FILE_NOT_VALID = 927,
}

const errors: {[key in ErrorCodes]: IApiError} = {
  [ErrorCodes.NO_CAP_ID]: {
    code: ErrorCodes.NO_CAP_ID,
    message: "cap.id non definito",
  },
  [ErrorCodes.FILE_TOO_BIG]: {
    code: ErrorCodes.FILE_TOO_BIG,
    message: "Il file selezionato è troppo grande (dimensione massima 8 MB)",
  },
  [ErrorCodes.FILE_MULTIPLE]: {
    code: ErrorCodes.FILE_MULTIPLE,
    message: "È possibile caricare un solo file alla volta",
  },
  [ErrorCodes.FAILED_TO_FETCH]: {
    code: ErrorCodes.FAILED_TO_FETCH,
    message:
      "Impossibile completare la richiesta. Controlla la tua connessione ad internet e riprova",
  },
  [ErrorCodes.OPTIONS_TOTAL_NOT_100]: {
    code: ErrorCodes.OPTIONS_TOTAL_NOT_100,
    message:
      "La somma delle percentuali delle opzioni selezionate deve essere uguale a 100 %",
  },
  [ErrorCodes.JSON_ID_UNDEFINED]: {
    code: ErrorCodes.JSON_ID_UNDEFINED,
    message: "L'utente non ha caricato ancora nessun documento",
  },
  [ErrorCodes.JSON_PRIVACY_UNDEFINED]: {
    code: ErrorCodes.JSON_PRIVACY_UNDEFINED,
    message: "L'utente non ha ancora accettato la privacy",
  },
  [ErrorCodes.GENERIC_SERVER_ERROR]: {
    code: ErrorCodes.GENERIC_SERVER_ERROR,
    message:
      "Si è verificato un errore imprevisto. Riprovare ad eseguire l'operazione. Se l'errore persiste, è possibile inviare una segnalazione tramite l'apposita sezione",
  },
  [ErrorCodes.BENE_UID_NOT_VALID]: {
    code: ErrorCodes.BENE_UID_NOT_VALID,
    message:
      "La proposta di polizza di Bene Assicurazioni è stata salvata, ma non è stato possibile effettuare l'invio",
  },
  [ErrorCodes.BENE_NON_BLOCKING_ERROR]: {
    code: ErrorCodes.BENE_NON_BLOCKING_ERROR,
    message:
      "Se l'errore riguarda i dati personali del contraente (nome, cognome, genere o nascita) apri l'analisi e correggi i dati cliccando sul link sottostante, altrimenti correggi in questa pagina",
  },
  [ErrorCodes.CAP_NOT_FOUND]: {
    code: ErrorCodes.CAP_NOT_FOUND,
    message: "Analisi non trovata",
  },
  [ErrorCodes.MISSING_USER_IN_CAP_CREATION]: {
    code: ErrorCodes.MISSING_USER_IN_CAP_CREATION,
    message: "Utente obbligatorio",
  },
  [ErrorCodes.WRONG_PROPOSAL_CODE]: {
    code: ErrorCodes.WRONG_PROPOSAL_CODE,
    message: "Il codice inserito non rispetta i criteri desiderati",
  },
  [ErrorCodes.USER_WITH_CF_ALREADY_EXISTS]: {
    code: ErrorCodes.USER_WITH_CF_ALREADY_EXISTS,
    message: "Esiste già un utente con lo stesso codice fiscale",
  },
  [ErrorCodes.USER_WITH_USID_ALREADY_EXISTS]: {
    code: ErrorCodes.USER_WITH_USID_ALREADY_EXISTS,
    message: "Esiste già un utente con lo stesso usid",
  },
  [ErrorCodes.USER_WITH_EMAIL_ALREADY_EXISTS]: {
    code: ErrorCodes.USER_WITH_EMAIL_ALREADY_EXISTS,
    message: "Esiste già un utente con la stessa email",
  },
  [ErrorCodes.USER_WITH_CAP_WITH_ANOTHER_ADVISOR]: {
    code: ErrorCodes.USER_WITH_CAP_WITH_ANOTHER_ADVISOR,
    message: "Utente ha un Analisi più recente con un altro Advisor",
  },
  [ErrorCodes.ID_FILE_TOO_BIG]: {
    code: ErrorCodes.ID_FILE_TOO_BIG,
    message:
      "Il file selezionato per il documento è troppo grande (dimensione massima 16 MB)",
  },
  [ErrorCodes.ID_FILE_MULTIPLE]: {
    code: ErrorCodes.ID_FILE_MULTIPLE,
    message: "È possibile scegliere un solo file per il documento",
  },
  [ErrorCodes.ID_FILE_NOT_VALID]: {
    code: ErrorCodes.ID_FILE_NOT_VALID,
    message: "Il file scelto non è valido",
  },
};

export default errors;
