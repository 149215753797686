import moment from "moment";
import React from "react";
import {Card, CardText, Col, Row} from "reactstrap";
import {IconDanger} from "../Icons";
import {IIdentificationSnapshot} from "../Users";
import {IdTypes} from "./UploadIdForm";

interface IProps {
  details: IIdentificationSnapshot;
  contractorId: string;
}

const apiUrl = `${process.env.REACT_APP_API_BASE_URL}users`;

const IdDetails: React.FC<IProps> = ({contractorId, details}) => {
  const frontUrl = encodeURI(
    `${apiUrl}/${contractorId}/get-id-image?id-file-name=${details.fileIdFrontName}&size=thumbnail`
  );
  const backUrl = encodeURI(
    `${apiUrl}/${contractorId}/get-id-image?id-file-name=${details.fileIdBackName}&size=thumbnail`
  );
  const faceUrl =
    details.fileIdFaceName &&
    encodeURI(
      `${apiUrl}/${contractorId}/get-id-image?id-file-name=${details.fileIdFaceName}&size=thumbnail`
    );
  const fCUrl =
    details.fileIdFCName &&
    encodeURI(
      `${apiUrl}/${contractorId}/get-id-image?id-file-name=${details.fileIdFCName}&size=thumbnail`
    );
  const expired = moment(details.expirationDate, "DD-MM-YYYY").isBefore(
    moment()
  );

  return (
    <div>
      <Row>
        <Col sm={6} lg={faceUrl ? 3 : 6} className="mb-3 mb-sm-0">
          <CardText>
            <strong>Documento:</strong> {IdTypes[details.idType]}
            <br />
            <strong>Numero documento:</strong> {details.idNumber}
            <br />
            <strong>Rilasciato da:</strong> {details.releasedBy}
            <br />
            <strong>In data:</strong> {details.releaseDate}
            <br />
            {expired ? (
              <span className="text-danger">
                <strong>Scadenza:</strong> {details.expirationDate}{" "}
                <IconDanger />
              </span>
            ) : (
              <>
                <strong>Scadenza:</strong> {details.expirationDate}
              </>
            )}
          </CardText>
        </Col>
        <Col sm={6} lg={faceUrl ? 9 : 6}>
          <Row xs={1} sm={2} lg={faceUrl ? (fCUrl ? 4 : 3) : 2}>
            <Col>
              <div className="aspect-ratio aspect-ratio-square mb-3 mb-lg-0">
                <Card body className="id-thumbnail">
                  <img src={frontUrl} alt="Documento inviato fronte" />
                </Card>
              </div>
            </Col>
            <Col>
              <div className="aspect-ratio aspect-ratio-square mb-3 mb-lg-0">
                <Card body className="id-thumbnail">
                  <img src={backUrl} alt="Documento inviato retro" />
                </Card>
              </div>
            </Col>
            {faceUrl && (
              <Col>
                <div className="aspect-ratio aspect-ratio-square">
                  <Card body className="id-thumbnail">
                    <img src={faceUrl} alt="Viso con Documento" />
                  </Card>
                </div>
              </Col>
            )}
            {fCUrl && (
              <Col>
                <div className="aspect-ratio aspect-ratio-square">
                  <Card body className="id-thumbnail">
                    <img
                      src={fCUrl}
                      alt="Fotografia della Tessera Sanitaria o del Codice Fiscale"
                    />
                  </Card>
                </div>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default IdDetails;
