import {faCheck} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import last from "lodash/last";
import moment from "moment";
import React from "react";
import {UseFormMethods} from "react-hook-form";
import InputMask from "react-input-mask";
import {useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {Alert, Button, FormFeedback, FormGroup, Input, Label} from "reactstrap";
import {getCap} from "../../Caps/selectors";
import {isBeneProposalComplianceApr21} from "../../helpers/isComplianceApr21";
import {IconSpinner} from "../../Icons";
import {IRootState} from "../../redux/reducers";
import {IAppThunkActionStates} from "../../types/thunk";
import {getUser} from "../../Users/selectors";
import {selectors} from "../slice";
import {IBeneProposalViewRouteParams} from "../View";
import {ProposalDocuments} from "./AlertDateAndButton/ProposalDocuments";
import {INewBeneProposalFormData} from "./NewBeneProposalForm";

interface IProps {
  disabled: boolean;
  errors: UseFormMethods<INewBeneProposalFormData>["errors"];
  formState: UseFormMethods<INewBeneProposalFormData>["formState"];
  isView: boolean;
  register: UseFormMethods<INewBeneProposalFormData>["register"];
  trigger: UseFormMethods<INewBeneProposalFormData>["trigger"];
}

export const AlertDateAndButton = ({
  disabled,
  errors,
  formState,
  isView,
  register,
  trigger,
}: IProps) => {
  const {id} = useParams<Partial<IBeneProposalViewRouteParams>>();
  const beneProposal = useSelector((state: IRootState) =>
    id ? selectors.selectById(state, id) : undefined
  );
  const beneProposalContractor = useSelector((state: IRootState) =>
    getUser(state, beneProposal?.contractor)
  );
  const cap = useSelector((state: IRootState) =>
    getCap(state, beneProposal?.cap)
  );
  const eSignId = useSelector(selectors.selectESignId);

  const createThunkStates = useSelector<IRootState, IAppThunkActionStates>(
    (state) => selectors.selectThunkActionStates(state, "beneProposals/create")
  );
  const resendThunkStates = useSelector<IRootState, IAppThunkActionStates>(
    (state) => selectors.selectThunkActionStates(state, "beneProposals/resend")
  );

  const lastSuccessful = last(
    beneProposal?.data.filter(
      (beneProposal) => !!beneProposal.outputAccessoProdotto?.uid
    )
  );
  const lastData = last(beneProposal?.data);
  const lastUid = lastData?.outputAccessoProdotto?.uid;

  return (
    <>
      <FormGroup>
        <Label for="dateEffect">Data di effetto della polizza</Label>
        <Input
          tag={InputMask}
          mask="99/99/9999"
          type="text"
          id="dateEffect"
          name="dateEffect"
          placeholder="Data di effetto della polizza (gg/mm/aaaa)"
          disabled={disabled}
          innerRef={register}
          onKeyUp={() => {
            trigger("dateEffect").then();
          }}
          invalid={!!errors?.dateEffect}
          valid={formState.touched.dateEffect && !errors?.dateEffect}
        />
        {errors?.dateEffect && (
          <FormFeedback>{errors.dateEffect.message}</FormFeedback>
        )}
      </FormGroup>
      {beneProposal && !isBeneProposalComplianceApr21(beneProposal) ? (
        <Alert color="warning">
          Prevision Family è stato aggiornato al fine di consegnare al Cliente
          la Dichiarazione di Coerenza, l'Allegato 4 e il set informativo di
          prodotto. <strong>Nel caso vogliate fare una nuova proposta</strong>,
          vi invitiamo a crearne una ex-novo selezionando cliente e prodotto.
        </Alert>
      ) : (
        <>
          <ProposalDocuments
            hasESign={
              !!beneProposal?.declarationConsistencyEsignId || !!eSignId
            }
            isView={isView}
          />
          <Alert color="info">
            Cliccando sul pulsante:
            <ul className="mb-0">
              <li>
                Il cliente riceverà copia della dichiarazione di coerenza,
                l'allegato 4 e il set informativo del prodotto selezionato;
              </li>
              <li>
                invierai tutte le informazioni a Bene e ti si aprirà una nuova
                schermata dove potrai completare i dettagli della polizza e
                disporre i pagamenti firmando tutto con OTP
              </li>
            </ul>
          </Alert>
        </>
      )}
      {resendThunkStates.isSuccess ? (
        <Alert color="success">
          <FontAwesomeIcon icon={faCheck} color="success" className="mr-2" />{" "}
          Proposta re-inviata correttamente a Bene Assicurazioni.
          <br />
          Abbiamo inviato in data{" "}
          {moment(last(beneProposal?.datesSendEmail)?.date).format(
            "LL[ alle ]LT"
          )}{" "}
          all'indirizzo email &lt;{beneProposalContractor?.email}&gt; un
          messaggio contenente i seguenti allegati:
          <ul className="mb-0">
            <li>dichiarazione di coerenza;</li>
            <li>l'allegato 4;</li>
            <li>set informativo relativo alla compagnia selezionata.</li>
          </ul>
        </Alert>
      ) : createThunkStates.isSuccess ? (
        <Alert color="success">
          <FontAwesomeIcon icon={faCheck} color="success" className="mr-2" />{" "}
          Proposta inviata correttamente a Bene Assicurazioni.
          <br />
          Abbiamo inviato in data{" "}
          {moment(last(beneProposal?.datesSendEmail)?.date).format(
            "LL[ alle ]LT"
          )}{" "}
          all'indirizzo email &lt;{beneProposalContractor?.email}&gt; un
          messaggio contenente i seguenti allegati:
          <ul className="mb-0">
            <li>dichiarazione di coerenza;</li>
            <li>l'allegato 4;</li>
            <li>set informativo relativo alla compagnia selezionata.</li>
          </ul>
        </Alert>
      ) : resendThunkStates.isFail ? (
        <Alert color="danger">
          <span className="mr-3">{resendThunkStates.error}</span>
          {cap?.id && lastUid && (
            <>
              <br />
              <Link to={`/analisi/new/${cap.id}`} className="alert-link">
                Correggi analisi
              </Link>
            </>
          )}
        </Alert>
      ) : (
        createThunkStates.isFail && (
          <Alert color="danger">
            <span className="mr-3">{createThunkStates.error}</span>
            {cap?.id && lastUid && (
              <>
                <br />
                <Link to={`/analisi/new/${cap.id}`} className="alert-link">
                  Correggi analisi
                </Link>
              </>
            )}
          </Alert>
        )
      )}
      <div className="text-center">
        {beneProposal ? (
          <>
            <Button
              type="submit"
              color="primary"
              outline
              className="mr-4"
              disabled={
                resendThunkStates.isPending ||
                !isBeneProposalComplianceApr21(beneProposal) ||
                disabled
              }
            >
              {resendThunkStates.isPending && (
                <IconSpinner className="icon-spin mr-2" />
              )}
              {lastSuccessful ? (
                <span>Re-invia a Bene Assicurazioni</span>
              ) : (
                <span>Invia a Bene Assicurazioni</span>
              )}
            </Button>
            {lastSuccessful ? (
              <>
                Ultimo invio:{" "}
                {moment(lastSuccessful.date).format("LL[ alle ]LT")}
              </>
            ) : (
              "Non ancora inviato"
            )}
          </>
        ) : (
          <Button
            type="submit"
            color="primary"
            outline
            disabled={createThunkStates.isPending || disabled}
          >
            {createThunkStates.isPending && (
              <IconSpinner className="icon-spin mr-2" />
            )}
            Salva e invia a Bene Assicurazioni
          </Button>
        )}
      </div>
    </>
  );
};
