import React from "react";
import {Alert, Button} from "reactstrap";
import {IconCopy, IconInfoCircleSolid, IconSpinner} from "../Icons";
import {IAppThunkActionStates} from "../types/thunk";

interface IProps {
  copyDataInCapStates: IAppThunkActionStates;
  copyFunction: () => void;
  isOpen: boolean;
  loadCopyStates: IAppThunkActionStates;
}

const CopyData: React.FC<IProps> = ({
  children,
  copyDataInCapStates,
  copyFunction,
  isOpen,
  loadCopyStates,
}) => (
  <>
    {loadCopyStates.isPending && (
      <div className="text-center">
        <IconSpinner className="mr-3 icon-spin" />
        Ricerca dati precedenti in corso...
      </div>
    )}
    {loadCopyStates.isSuccess && (
      <Alert color="info" isOpen={isOpen} className="d-flex align-items-center">
        <IconInfoCircleSolid className="mr-3" style={{flex: "0 0 16px"}} />
        {children}
        <Button
          color="light"
          outline
          className="ml-3 text-nowrap"
          onClick={copyFunction}
        >
          {copyDataInCapStates.isPending ? (
            <IconSpinner className="icon-spin" />
          ) : (
            <IconCopy />
          )}{" "}
          Copia
        </Button>
      </Alert>
    )}
  </>
);

export default CopyData;
