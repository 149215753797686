import moment from "moment";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Redirect} from "react-router-dom";
import {Card, Container} from "reactstrap";
import {Layout, PageTitle} from "../App";
import {IOverdraftsSectionFormData} from "../Caps/OverdraftSectionForm";
import PrivacySubscription from "../Caps/PrivacySubscription";
import {getCap} from "../Caps/selectors";
import SearchContractor, {IResultItem} from "../Proposals/New/SearchContractor";
import CapIdentification from "../Proposals/shared/CapIdentification";
import ContractorDetails from "../Proposals/shared/ContractorDetails";
import CapDetails from "../Recommendation/CapDetails";
import {IRootState} from "../redux/reducers";
import {getUser} from "../User/selectors";
import {IRCFormData} from "./shared/ChoseProductLevel/Rc";
import NewBeneProposalForm, {
  INewBeneProposalFormData,
} from "./shared/NewBeneProposalForm";
import {prepareJsonProduct} from "./shared/prepareJsonProduct";
import {actions, selectors} from "./slice";

const New = () => {
  const dispatch = useDispatch();

  const loggedUser = useSelector(getUser)!;
  const chosenCap = useSelector((state: IRootState) =>
    getCap(state, selectors.selectChosenCap(state))
  );
  const tempCongruenceFilePath = useSelector(
    selectors.selectTempCongruenceFilePath
  );
  const eSignId = useSelector(selectors.selectESignId);
  const canSeeChosenCap = useSelector(selectors.selectCanSeeChosenCap);
  const lastCreatedId = useSelector(selectors.selectLastCreatedId);

  const handleSelect = (value: string, item: IResultItem) => {
    dispatch(actions.choseCap({capId: item.cap?.id}));
  };

  const houseDisabled =
    (chosenCap?.data.overdrafts?.beniCasaAnimali as IOverdraftsSectionFormData)
      ?.consultantRating === undefined && chosenCap?.delivery?.values?.loss;
  const injuriesDisabled =
    (chosenCap?.data.overdrafts
      ?.infortuniMalattiaPrevenzione as IOverdraftsSectionFormData)
      ?.consultantRating === undefined && chosenCap?.delivery?.values?.loss;

  const userRcData: Partial<IRCFormData> = {
    name: chosenCap?.contractor.name,
    surname: chosenCap?.contractor.surname,
    birthdate: chosenCap?.contractor.birthday?.format("DD/MM/YYYY"),
    birthCity:
      chosenCap?.contractor.birthplaceCSiglaProvincia === "EE"
        ? "Estero"
        : chosenCap?.contractor.birthplace,
    birthNation:
      chosenCap?.contractor.birthplaceCSiglaProvincia === "EE"
        ? undefined
        : "Italia",
    birthCityIdObject:
      chosenCap?.contractor.birthplaceCSiglaProvincia === "EE" ? "0" : "1",
    birthCityBExist:
      chosenCap?.contractor.birthplaceCSiglaProvincia === "EE" ? "0" : "1",
    birthCityMore:
      chosenCap?.contractor.birthplaceCSiglaProvincia === "EE"
        ? chosenCap?.contractor.birthplace
        : "",
    birthRegion: chosenCap?.contractor.birthplaceCSiglaProvincia,
    fiscalCode: chosenCap?.contractor.fiscalCode,
    gender: chosenCap?.contractor.gender,
  };

  const onSubmit = (values: INewBeneProposalFormData) => {
    const dateEffect = moment(values.dateEffect, "DD/MM/YYYY").format(
      "YYYY-MM-DD"
    );

    if (!eSignId) {
      dispatch(
        actions.setEsignIdError({
          message:
            "Prima di procedere occorre firmare il documento di Dichiarazione di coerenza.",
        })
      );
      return false;
    }

    dispatch(actions.setEsignIdError({message: undefined}));
    dispatch(
      actions.create({
        user: loggedUser.id,
        contractor: chosenCap!.contractor.id,
        cap: chosenCap!.id,
        dateEffect,
        fileDichiarazioneCoerenza: tempCongruenceFilePath!,
        eSignId: eSignId,
        jsonProduct: prepareJsonProduct(values),
      })
    );
  };

  useEffect(() => {
    return () => {
      dispatch(actions.choseCap({capId: undefined}));
    };
  }, [dispatch]);

  return (
    <Layout>
      {lastCreatedId && (
        <Redirect
          to={{
            pathname: `/proposte-bene/${lastCreatedId}`,
            state: {disableAutoScrollToTop: true},
          }}
        />
      )}
      <PageTitle>Nuova polizza Bene Assicurazioni</PageTitle>
      {chosenCap ? (
        <Container className="container-max p-md-5 auto-margin-3">
          <ContractorDetails contractor={chosenCap.contractor} />
          <CapIdentification cap={chosenCap} isAdvisor isContractor={false} />
          <PrivacySubscription
            as={Card}
            body
            cap={chosenCap}
            className="auto-margin-3 d-block"
            color="drawer"
            icon
            id="contractor-privacy-subscription"
          />
          <CapDetails cap={chosenCap} canSeeCap={canSeeChosenCap} isAdvisor />
          <NewBeneProposalForm
            onSubmit={onSubmit}
            houseDisabled={houseDisabled}
            injuriesDisabled={injuriesDisabled}
            defaultValues={{rc: userRcData}}
          />
        </Container>
      ) : (
        <SearchContractor
          onSelect={handleSelect}
          searchParams={{overdrafts: "available"}}
        />
      )}
    </Layout>
  );
};

export default New;
