import moment from "moment";
import {individualCompanyTypes} from "./companyTypes";
import {INewBeneProposalFormData} from "./NewBeneProposalForm";

export function prepareJsonProduct(values: INewBeneProposalFormData) {
  const birthdate = values?.rc?.birthdate
    ? moment(values.rc.birthdate, "DD/MM/YYYY").format("YYYY-MM-DD")
    : undefined;

  const birthCity = values?.rc?.birthCityMore || values?.rc?.birthCity;

  const fiscalCode = values?.rc?.fiscalCode || values?.rc?.companyFiscalCode;

  const companyType = values?.rc?.legalForm
    ? individualCompanyTypes.includes(values.rc.legalForm)
      ? "individual"
      : "capital"
    : undefined;

  return JSON.stringify({
    ...values,
    rc: {
      ...values.rc,
      birthdate,
      birthCity,
      companyType,
      fiscalCode,
    },
  });
}
