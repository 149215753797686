import isEmpty from "lodash/isEmpty";
import React from "react";
import {FieldErrors, useForm} from "react-hook-form";
import {
  Alert,
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import {IconSpinner, IconTimes} from "../../../Icons";
import {IAppThunkActionStates} from "../../../types/thunk";

export interface IRejectFormData {
  revision: string;
}
interface IProps {
  isOpen: boolean;
  onSubmit: (values: IRejectFormData) => void;
  submitStates: IAppThunkActionStates;
  toggle: () => void;
}

const validate = async (values: IRejectFormData) => {
  const errors: FieldErrors<IRejectFormData> = {};

  if (!values.revision) {
    errors.revision = {
      type: "required",
      message: "La motivazione della richiesta revisione è obbligatoria",
    };
  }

  return {values: isEmpty(errors) ? values : {}, errors};
};

const RejectModal: React.FC<IProps> = ({
  isOpen,
  onSubmit,
  submitStates,
  toggle,
}) => {
  const {register, handleSubmit, errors} = useForm<IRejectFormData>({
    mode: "onBlur",
    resolver: validate,
  });

  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Richiesta revisione</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup>
            <Label for="revision">
              Spiega il perché è richiesta una revisione della proposta:
            </Label>
            <Input
              id="revision"
              name="revision"
              type="textarea"
              innerRef={register}
              invalid={!!errors?.revision}
            />
            {errors?.revision && (
              <FormFeedback className="d-block">
                {errors.revision.message}
              </FormFeedback>
            )}
          </FormGroup>
          {submitStates.isFail && (
            <Alert color="danger">{submitStates.error}</Alert>
          )}
          <div className="text-center">
            <Button color="danger" outline disabled={submitStates.isPending}>
              {submitStates.isPending ? (
                <IconSpinner className="icon-spin" />
              ) : (
                <IconTimes />
              )}
              Invia richiesta
            </Button>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default RejectModal;
