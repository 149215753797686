import * as Sentry from "@sentry/browser";
import moment from "moment";

const browserSupportsAllFeatures = () => {
  return (
    Object.values! &&
    Object.entries! &&
    Array.prototype.fill! &&
    Array.prototype.find! &&
    Array.prototype.findIndex! &&
    Array.prototype.includes! &&
    String.prototype.startsWith! &&
    Array.from! &&
    Number.isNaN!
  );
};

const fixMomentJson = () => {
  moment.fn.toJSON = function () {
    return this.toISOString(true);
  };
};

const polyfill = () => {
  fixMomentJson();
  if (browserSupportsAllFeatures()) {
    return Promise.resolve();
  } else {
    return import(/* webpackChunkName: 'polyfill' */ "./polyfill")
      .then(() => {
        Sentry.captureMessage("Polyfills loaded");
        console.log("Polyfills loaded");
      })
      .catch((error) => {
        console.log("Unable to load polyfills");
        console.error(error);
        // Handle failure
      });
  }
};

export default polyfill;
