import React from "react";
import {Table} from "reactstrap";
import FormatNumber from "../../helpers/FormatNumber";
import {IIddProfile} from "./";

import "./IddResultSnippet.scss";

interface IProps {
  idd: IIddProfile;
}

const IddResultSnippet: React.FunctionComponent<IProps> = (props) => (
  <Table bordered striped responsive>
    <thead>
      <tr>
        <th />
        <th>Tipologia cliente</th>
        <th>Conoscenza ed esperienza</th>
        <th>Situazione finanziaria</th>
        <th>Obiettivi di investimento</th>
        <th>Propensione al rischio</th>
        <th>Orizzonte temporale</th>
      </tr>
    </thead>

    <tbody>
      {!props.idd ? (
        <tr>
          <td colSpan={3} className="text-center table-danger py-4">
            Si è verificato un errore imprevisto
          </td>
        </tr>
      ) : (
        <>
          <tr>
            <td>Driver</td>
            <td>-</td>
            <td>
              <FormatNumber minimumFractionDigits={0}>
                {props.idd.conoscenzaEsperienza.value}
              </FormatNumber>
            </td>
            <td>
              <FormatNumber minimumFractionDigits={0}>
                {props.idd.situazioneFinanziaria.value}
              </FormatNumber>
            </td>
            <td>
              <FormatNumber minimumFractionDigits={0}>
                {props.idd.obiettiviInvestimento.value}
              </FormatNumber>
            </td>
            <td>
              <FormatNumber minimumFractionDigits={0}>
                {props.idd.propensioneRischio.value}
              </FormatNumber>
            </td>
            <td>-</td>
          </tr>
          <tr>
            <td>Profilo</td>
            <td>{props.idd.tipologiaClientela.label}</td>
            <td>{props.idd.conoscenzaEsperienza.label}</td>
            <td>{props.idd.situazioneFinanziaria.label}</td>
            <td>{props.idd.obiettiviInvestimento.label}</td>
            <td>{props.idd.propensioneRischio.label}</td>
            <td>{props.idd.orizzonteTemporale.label}</td>
          </tr>
        </>
      )}
    </tbody>
  </Table>
);

export default IddResultSnippet;
