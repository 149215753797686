import React from "react";
import {Container} from "reactstrap";
import ContainerMax from "./ContainerMax";

const ManualeOperativo = () => (
  <>
    <Container>
      <h2 className="m-0">Dati societari</h2>
    </Container>
    <ContainerMax>
      <div>
        <h3>
          <em>[ ragione sociale ]</em>
        </h3>
        <h4>Dati identificativi</h4>
        <p>
          [ ragione sociale ] Società con socio unico soggetta a controllo e
          coordinamento della xxx
        </p>
        <p>
          Codice fiscale 00000000000 – Partita iva 00000000000 – Rea BS-000000 –
          Capitale sociale Euro 500.000 i.V.
        </p>
        <p>
          Iscrizione al Registro Unico degli Intermediari Assicurativi e
          Riassicurativi n. A000000000. [ ragione sociale ] è soggetta alla
          vigilanza dell’IVASS.
        </p>
        <h4>Sede legale</h4>
        <p>Via xxxxxx, xx - xxxxx xxxxx (xx).</p>
        <h4>Sede amministrativa e operativa</h4>
        <p>Via xxxxx, xx - xxxxx xxxxx (xx)</p>
        <h4>Contatti</h4>
        <p>
          Tel: +39.000.0000000
          <br />
          Fax; +39.000.0000000
          <br />
          Sito internet: www.dominio.it
          <br />
          Email: info@dominio.it PEC: dominio@pec.dominio.eu
        </p>
        <h4>Presentazione reclami</h4>
        <p>Per inoltrare un reclamo, inviare una segnalazione per iscritto:</p>
        <ul>
          <li>
            a mezzo posta raccomandata direttamente all’intermediario [ ragione
            sociale ] al seguente indirizzo: Via xxxxxx, xx - xxxxx xxxxx (xx);
          </li>
          <li>a mezzo fax al n. 000/0000000;</li>
          <li>a mezzo email all’indirizzo: dominioe@pec.dominio.eu.</li>
        </ul>
        <p>
          Per inoltrare un reclamo alla singola Compagnia competente, consultare
          il sito all'indirizzo
          www.dominio.it/informativa-sul-diritto-di-reclamo/.
        </p>
      </div>
    </ContainerMax>
  </>
);

export default ManualeOperativo;
