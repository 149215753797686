export interface IOverdraftsNode {
  name: string;
  onlyAutonomo?: boolean;
  question: string;
  questions: IOverdraftsLeaf[];
  type: "node";
}

export interface IOverdraftsLeaf {
  name: string;
  onlyAutonomo?: boolean;
  question: string;
  description?: string;
  type: "question";
  weight: number;
}

export type IOverdraftsQuestion = IOverdraftsNode | IOverdraftsLeaf;

export interface IOverdraftsSection {
  questions: IOverdraftsQuestion[];
  title: string;
  subtitle?: string;
}

export interface IOverdraftsSections {
  [name: string]: IOverdraftsSection;
}

const overdraftsSections: IOverdraftsSections = {
  beniCasaAnimali: {
    questions: [
      {
        name: "rc",
        question: "Responsabilità Civile",
        questions: [
          {
            description:
              "Sei tutelato dai danni provocati a terzi da te o da componenti della tua famiglia?",
            name: "danniTerzi",
            question: "Danni a terzi",
            type: "question",
            weight: 6
          },
          {
            name: "tutelaLegale",
            question: "Tutela legale",
            type: "question",
            weight: 2
          }
        ],
        type: "node"
      },
      {
        name: "protezioneCasa",
        question: "Protezione della casa",
        questions: [
          {
            description:
              "Sei tutelato se, ad esempio in seguito a incendio, bagnamento, eventi atmosferici o atti vandalici, subisci danni a muri e impianti?",
            name: "danniLocali",
            question: "Danni a locali",
            type: "question",
            weight: 4
          },
          {
            description:
              "Sei tutelato se subisci danni ai beni contenuti nella tua casa?",
            name: "danniBeni",
            question: "Danni a beni",
            type: "question",
            weight: 2
          }
        ],
        type: "node"
      },
      {
        name: "furtoRapina",
        question: "Furto e rapina",
        type: "question",
        weight: 3
      },
      {
        name: "catastrofiNaturali",
        question: "Catastrofi naturali(terremoti, alluvioni, innondazioni)",
        type: "question",
        weight: 3
      },
      {
        description:
          "Se hai un’emergenza e necessiti dell’intervento di un fabbro, un elettricista, un idraulico, hai qualcuno che ti può assistere?",
        name: "assistenza",
        question: "Assistenza",
        type: "question",
        weight: 1
      },
      {
        name: "rcProfessionale",
        onlyAutonomo: true,
        question: "Rc Professionale",
        type: "question",
        weight: 3
      },
      {
        name: "tutelaLegaleProfessionale",
        onlyAutonomo: true,
        question: "Tutela legale Professionale",
        type: "question",
        weight: 1
      },
      {
        description:
          "Responsabilità civile, rimborso spese veterinarie, tutela legale e assistenza",
        name: "animaliDomestici",
        question: "Animali domestici",
        type: "question",
        weight: 1
      }
    ],
    title: "Tutelare beni, casa, animali"
  },
  infortuniMalattiaPrevenzione: {
    questions: [
      {
        name: "grandiInterventi",
        question: "Grandi interventi",
        type: "question",
        weight: 4
      },
      {
        name: "rimborsoSpeseMediche",
        question: "Rimborso delle spese mediche",
        type: "question",
        weight: 2
      },
      {
        name: "inabilitaTemporanea",
        question: "Inabilità temporanea",
        type: "question",
        weight: 2
      },
      {name: "diaria", question: "Diaria", type: "question", weight: 2},
      {
        name: "checkupPrevenzione",
        question: "Check up - Prevenzione",
        type: "question",
        weight: 1
      }
    ],
    title: "Tutelarsi da infortuni, malattia & prevenzione"
  },
  risparmioFuturo: {
    questions: [
      {
        name: "pensione",
        question: "Pensione (FIP / PIP...)",
        type: "question",
        weight: 1
      },
      {
        name: "obbligazioni",
        question: "Obbligazioni / Titoli di stato",
        type: "question",
        weight: 1
      },
      {name: "azioni", question: "Azioni", type: "question", weight: 1},
      {
        name: "fondi",
        question: "Fondi di investimento",
        type: "question",
        weight: 1
      },
      {
        name: "prodotti-assicurativi",
        question: "Prodotti di investimento assicurativi",
        type: "question",
        weight: 1
      },
      {name: "derivati", question: "Derivati", type: "question", weight: 1},
      {
        name: "altro",
        question: "Altri prodotti complessi",
        type: "question",
        weight: 1
      }
    ],
    subtitle:
      "Quali soluzioni possiedi attualmente o hai utilizzato in passato per costruire il capitale per il tuo futuro?",
    title: "Costruire un capitale per il futuro"
  },
  tenoreVita: {
    questions: [
      {
        name: "morte",
        question: "Temporanea caso morte",
        type: "question",
        weight: 5
      },
      {
        name: "invaliditaPermanente",
        question: "Invalidità permanente",
        type: "question",
        weight: 3
      },
      {
        name: "invaliditaPermanenteDaMalattia",
        question: "Invalidità permanente da malattia",
        type: "question",
        weight: 3
      },
      {
        name: "perditaLavoro",
        question: "Perdita del lavoro",
        type: "question",
        weight: 1
      }
    ],
    title: "Tutelare il tenore di vita personale e familiare"
  }
};

export default overdraftsSections;
