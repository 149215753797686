import produce from "immer";
import moment from "moment";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {Card, Container} from "reactstrap";
import {Layout, PageTitle} from "../App";
import LoaderOverlay from "../App/LoaderOverlay";
import PrivacySubscription from "../Caps/PrivacySubscription";
import {getCap} from "../Caps/selectors";
import CapIdentification from "../Proposals/shared/CapIdentification";
import ContractorDetails from "../Proposals/shared/ContractorDetails";
import CapDetails from "../Recommendation/CapDetails";
import {IRootState} from "../redux/reducers";
import {getUser} from "../Users/selectors";
import NewBeneProposalForm, {
  INewBeneProposalFormData,
} from "./shared/NewBeneProposalForm";
import {prepareJsonProduct} from "./shared/prepareJsonProduct";
import {actions, selectors} from "./slice";

export interface IBeneProposalViewRouteParams {
  id: string;
}

const View = () => {
  const {id} = useParams<IBeneProposalViewRouteParams>();
  const dispatch = useDispatch();

  const beneProposal = useSelector((state: IRootState) =>
    selectors.selectById(state, id)
  );
  const beneProposalCap = useSelector((state: IRootState) =>
    getCap(state, beneProposal?.cap)
  );
  const beneProposalContractor = useSelector((state: IRootState) =>
    getUser(state, beneProposal?.contractor)
  );

  const proposalDefaults =
    beneProposal &&
    produce(beneProposal.product, (draft) => {
      if (draft.rc) {
        draft.rc.birthdate = moment(draft.rc.birthdate, "YYYY-MM-DD").format(
          "DD/MM/YYYY"
        );
        draft.rc.birthCityMore =
          draft.rc.birthRegion === "EE" ? draft.rc.birthCity : "";
        draft.rc.birthCity =
          draft.rc.birthRegion === "EE" ? "Estero" : draft.rc.birthCity;
      }
    });

  const userRcData = beneProposalContractor && {
    name: beneProposalContractor.name,
    surname: beneProposalContractor.surname,
    birthdate: beneProposalContractor.birthday?.format("DD/MM/YYYY"),
    birthCity:
      beneProposalContractor.birthplaceCSiglaProvincia === "EE"
        ? "Estero"
        : beneProposalContractor.birthplace,
    birthNation:
      beneProposalContractor.birthplaceCSiglaProvincia === "EE"
        ? undefined
        : "Italia",
    birthCityIdObject:
      beneProposalContractor.birthplaceCSiglaProvincia === "EE" ? "0" : "1",
    birthCityBExist:
      beneProposalContractor.birthplaceCSiglaProvincia === "EE" ? "0" : "1",
    birthCityMore:
      beneProposalContractor.birthplaceCSiglaProvincia === "EE"
        ? beneProposalContractor.birthplace
        : "",
    birthRegion: beneProposalContractor.birthplaceCSiglaProvincia,
    gender: beneProposalContractor.gender,
  };

  const defaultValues = proposalDefaults && {
    ...proposalDefaults,
    rc: {...proposalDefaults.rc, ...userRcData},
  };

  const onSubmit = (values: INewBeneProposalFormData) => {
    dispatch(actions.create.reset());
    const dateEffect = moment(values.dateEffect, "DD/MM/YYYY").format(
      "YYYY-MM-DD"
    );

    dispatch(
      actions.resend({
        id: beneProposal!.id,
        dateEffect,
        jsonProduct: prepareJsonProduct(values),
      })
    );
  };

  useEffect(() => {
    if (!!id && !beneProposal) {
      // Non ho i dati, carico la raccomandazione
      dispatch(actions.get(id));
    }
  }, [dispatch, id, beneProposal]);
  useEffect(() => {
    return () => {
      dispatch(actions.create.reset());
      dispatch(actions.resend.reset());
    };
  }, [dispatch]);

  return (
    <Layout>
      <PageTitle>Polizze Bene Assicurazioni</PageTitle>
      <Container className="container-max p-md-5 auto-margin-3">
        {beneProposal && beneProposalCap ? (
          <>
            <ContractorDetails contractor={beneProposalCap.contractor} />
            <CapIdentification
              cap={beneProposalCap}
              isAdvisor
              isContractor={false}
            />
            <PrivacySubscription
              as={Card}
              body
              cap={beneProposalCap}
              className="auto-margin-3 d-block"
              color="drawer"
              icon
              id="contractor-privacy-subscription"
            />
            <CapDetails cap={beneProposalCap} canSeeCap isAdvisor />
            <NewBeneProposalForm
              defaultValues={defaultValues}
              onSubmit={onSubmit}
              isView
            />
          </>
        ) : (
          <LoaderOverlay visible>
            Caricamento polizza bene in corso...
          </LoaderOverlay>
        )}
      </Container>
    </Layout>
  );
};

export default View;
