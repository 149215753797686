import moment from "moment";
import {IIdentificationSnapshot} from "../Users";

const isIdValid = (
  identification?: IIdentificationSnapshot,
  isAdvisor: boolean = false
) => {
  if (!identification) {
    return false;
  }

  return (
    moment(identification.expirationDate, "DD-MM-YYYY").isAfter(moment()) &&
    identification.fileIdFaceName &&
    identification.fileIdFCName &&
    identification.esignId &&
    (isAdvisor || identification.contractorEsignId)
  );
};

export default isIdValid;
