import IRoutes from "../types/IRoutes";
import ListPage from "./List/Container";
import NewPage from "./New/Container";
import ViewPage from "./View/Container";

const routes: IRoutes = [
  {
    component: NewPage,
    exact: true,
    name: "recommendation-new",
    path: "/raccomandazioni/new",
    permission: "recommendation.manage",
  },
  {
    component: ViewPage,
    exact: true,
    name: "recommendation-view",
    path: "/raccomandazioni/:recommendationId",
    permission:
      "recommendation.read||recommendations.read||recommendation.manage||recommendation.approve||recommendation.contractor-read",
  },
  {
    component: ListPage,
    exact: true,
    name: "recommendation-list",
    path: "/raccomandazioni",
    permission:
      "recommendation.read||recommendations.read||recommendation.contractor-read",
  },
];

export default routes;
