import React from "react";
import {useSelector} from "react-redux";
import {IRootState} from "../../redux/reducers";
import {getUser} from "../../Users/selectors";

interface IProps {
  id: string;
}

const ContractorTd = ({id}: IProps) => {
  const contractor = useSelector((state: IRootState) => getUser(state, id));

  if (!contractor) {
    return <span>-</span>;
  }

  return (
    <div style={{lineHeight: 1}}>
      {contractor.surname} {contractor.name}
      <br />
      <small className="smaller text-info">CF: {contractor.fiscalCode}</small>
    </div>
  );
};

export default ContractorTd;
