import animateScrollTo from "animated-scroll-to";
import uniqBy from "lodash/uniqBy";
import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Button, Col, Form, FormGroup, Label, Row} from "reactstrap";
import {
  change,
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import {ThunkDispatch} from "redux-thunk";
import {ICap} from "../../../Caps";
import {focusFirstInvalid} from "../../../helpers/focusFirstInvalid";
import isIdValid from "../../../helpers/isIdValid";
import RenderField from "../../../helpers/RenderField";
import {IconFillIn, IconSpinner} from "../../../Icons";
import {IRootState} from "../../../redux/reducers";
import {IAppThunkActionStates} from "../../../types/thunk";
import {getHasPermission} from "../../../User/selectors";
import {actions} from "../../actions";
import {getProposalProductDocuments} from "../../selectors";
import {IProposalEntity} from "../../types";
import EditProposalCodeModal from "./ChooseProductForm/EditProposalCodeModal";

export interface IChooseProductFormData {
  code: string;
  company: string;
  product: string;
}
interface IChooseProductFormErrors {
  code?: string;
  company?: string;
  product?: string;
}

interface IOwnProps {
  cap: ICap;
  form: string;
  hasRevisions: boolean;
  isSent: boolean;
  onSubmit: (data: Partial<IProposalEntity>) => Promise<void>;
  onSubmitStates: IAppThunkActionStates;
  proposalCreated: boolean;
}
type IProps = IOwnProps & InjectedFormProps<IChooseProductFormData, IOwnProps>;

const validate = (values: IChooseProductFormData) => {
  const errors: IChooseProductFormErrors = {};

  if (values && !values.company) {
    errors.company = "Seleziona una compagnia";
  } else if (values && !values.product) {
    errors.product = "Seleziona un prodotto";
  } else if (values && !values.code) {
    errors.code = "Il codice proposta è obbligatorio";
  }

  return errors;
};

const ChooseProductForm = ({
  cap,
  form,
  handleSubmit,
  hasRevisions,
  isSent,
  onSubmit,
  onSubmitStates,
  proposalCreated,
}: IProps) => {
  const [isEditCodeModalOpen, setIsEditCodeModalOpen] = useState(false);
  const toggleEditCodeModal = () =>
    setIsEditCodeModalOpen(!isEditCodeModalOpen);

  const dispatch: ThunkDispatch<IRootState, any, actions> = useDispatch();
  useEffect(() => {
    dispatch(actions.proposalProductDocumentsList());
  }, [dispatch]);
  const proposalProductDocuments = useSelector(getProposalProductDocuments);
  const companies = uniqBy(
    proposalProductDocuments.map(
      (proposalProductDocument) => proposalProductDocument.product.company
    ),
    (company) => company.id
  );

  const selector = formValueSelector(form);
  const companyValue = useSelector<IRootState, string>((state) =>
    selector(state, "company")
  );
  const productValue = useSelector<IRootState, string>((state) =>
    selector(state, "product")
  );

  const canProposalManage = useSelector((state: IRootState) =>
    getHasPermission(state, "proposal.manage")
  );

  const companyChangeHandler = () => {
    dispatch(change("chooseProductForm", "product", ""));
  };

  const checkAndSubmit = useCallback(
    async (values: IChooseProductFormData) => {
      const product = proposalProductDocuments.find(
        (proposalProductDocument) =>
          proposalProductDocument.product.id === values.product
      )?.product;
      const jsonProduct = JSON.stringify(product);

      if (isIdValid(cap.onboarding?.identification, canProposalManage)) {
        await onSubmit({
          jsonProduct,
          code: values.code,
        });
      } else {
        const element = document.getElementById("cap-identification-details");
        animateScrollTo(element!, {verticalOffset: -16});
      }
    },
    [canProposalManage, cap.onboarding, onSubmit, proposalProductDocuments]
  );

  return (
    <>
      <Form onSubmit={handleSubmit(checkAndSubmit)}>
        <Row>
          <Col md={4}>
            <FormGroup>
              <Label for="company">Compagnia</Label>
              <Field
                id="company"
                name="company"
                component={RenderField}
                onChange={companyChangeHandler}
                type="select"
                disabled={proposalCreated || !canProposalManage}
              >
                <option value="">Seleziona</option>
                {companies.map((company) => (
                  <option key={company.id} value={company.id}>
                    {company.name}
                  </option>
                ))}
              </Field>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="product">Prodotto</Label>
              <Field
                id="product"
                name="product"
                component={RenderField}
                type="select"
                disabled={!companyValue || proposalCreated}
              >
                <option value="">Seleziona</option>
                {!!companyValue &&
                  proposalProductDocuments
                    .map(
                      (proposalProductDocument) =>
                        proposalProductDocument.product
                    )
                    .filter(
                      (proposalProductDocument) =>
                        proposalProductDocument.company.id.toString() ===
                        companyValue
                    )
                    .map((proposalProductDocument) => (
                      <option
                        key={proposalProductDocument.id}
                        value={proposalProductDocument.id}
                      >
                        {proposalProductDocument.name}
                      </option>
                    ))}
              </Field>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for="code">Numero proposta</Label>
              <Field
                id="code"
                name="code"
                component={RenderField}
                type="text"
                disabled={
                  !productValue ||
                  !canProposalManage ||
                  isSent ||
                  proposalCreated
                }
                helpText={
                  proposalProductDocuments.find(
                    (proposalProductDocument) =>
                      proposalProductDocument.product.id === productValue
                  )?.nameInstructions
                }
                appendAddOn={
                  productValue &&
                  canProposalManage &&
                  !isSent &&
                  proposalCreated &&
                  !hasRevisions && (
                    <Button
                      color="primary"
                      outline
                      onClick={toggleEditCodeModal}
                    >
                      <IconFillIn />
                    </Button>
                  )
                }
              />
            </FormGroup>
          </Col>
        </Row>
        {onSubmitStates.isFail && (
          <Alert color="danger">{onSubmitStates.error}</Alert>
        )}
        {canProposalManage && !isSent && !proposalCreated && (
          <div className="text-center">
            <Button
              type="submit"
              color="primary"
              outline
              disabled={onSubmitStates.isPending}
            >
              {onSubmitStates.isPending && (
                <IconSpinner className="icon-spin mr-2" />
              )}
              Conferma e prosegui
            </Button>
          </div>
        )}
      </Form>
      <EditProposalCodeModal
        formName={form}
        isOpen={isEditCodeModalOpen}
        onSubmit={onSubmit}
        onSubmitStates={onSubmitStates}
        toggle={toggleEditCodeModal}
      />
    </>
  );
};

export default reduxForm<IChooseProductFormData, IOwnProps>({
  onSubmitFail: focusFirstInvalid,
  validate,
})(ChooseProductForm);
