import React from "react";
import {DropEvent, FileRejection} from "react-dropzone";
import {Container} from "reactstrap";
import {IStateUi} from "../";
import {Layout} from "../../App";
import "./bulks.scss";
import FailFile from "./shared/FailFile";
import UploadingFile from "./shared/UploadingFile";
import WaitingFile from "./shared/WaitingFile";
import Instructions from "./update/Instructions";
import SuccessAccept from "./update/SuccessAccept";
import SuccessFile from "./update/SuccessFile";

export interface IProps {
  filePath?: string;
  report: Array<{[Key: string]: string}>;
  ui: IStateUi;
  onDrop: (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
  resetFileState: () => any;
  acceptBulkFile: (filePath: string) => any;
}

const RenderFileBulk: React.FC<IProps> = (props) => {
  if (props.ui.isUploadBulkFail) {
    return (
      <React.Fragment>
        {props.ui.uploadBulkError && (
          <FailFile uploadBulkError={props.ui.uploadBulkError} />
        )}
        <Instructions />
        <WaitingFile onDrop={props.onDrop} />
      </React.Fragment>
    );
  } else if (props.ui.isUploadBulkSuccess) {
    return (
      <SuccessFile
        acceptBulkError={props.ui.acceptBulkError}
        filePath={props.filePath!}
        isAcceptBulkFail={!!props.ui.isAcceptBulkFail}
        isAcceptBulkPending={!!props.ui.isAcceptBulkPending}
        report={props.report}
        acceptBulkFile={props.acceptBulkFile}
        resetFileState={props.resetFileState}
      />
    );
  } else if (props.ui.isUploadBulkPending) {
    return (
      <React.Fragment>
        <Instructions />
        <UploadingFile />
      </React.Fragment>
    );
  } else if (props.ui.isAcceptBulkSuccess) {
    return (
      <SuccessAccept
        acceptBulkError={props.ui.acceptBulkError}
        isAcceptBulkFail={!!props.ui.isAcceptBulkFail}
        report={props.report}
        resetFileState={props.resetFileState}
      />
    );
  } else {
    return (
      <React.Fragment>
        <Instructions />
        <WaitingFile onDrop={props.onDrop} />
      </React.Fragment>
    );
  }
};

class UpdatePage extends React.Component<IProps> {
  public componentWillUnmount() {
    this.props.resetFileState();
  }
  public render() {
    return (
      <Layout isProtected>
        <Container className="auto-margin-4">
          <h2>Modifica Advisor</h2>
          <div className="container-max p-md-5">
            <RenderFileBulk {...this.props} />
          </div>
        </Container>
      </Layout>
    );
  }
}

export default UpdatePage;
