import React from "react";
import {Alert, Button, Form, FormGroup, Label} from "reactstrap";
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import RenderField from "../helpers/RenderField";
import IconSpinner from "../Icons/IconSpinner";

export interface ILoginFormData {
  usid: string;
  password: string;
}

interface IOwnProps {
  isLoginFail: boolean;
  loginError: string;
}

type IProps = InjectedFormProps<ILoginFormData, IOwnProps> & IOwnProps;

const validate = (values: ILoginFormData) => {
  const errors: ILoginFormData = {usid: "", password: ""};
  if (!values.usid) {
    errors.usid = "Questo campo è obbligatorio";
  }
  if (!values.password) {
    errors.password = "Questo campo è obbligatorio";
  }
  return errors;
};

const LoginForm: React.FC<IProps> = (props) => {
  return (
    <Form onSubmit={props.handleSubmit}>
      <FormGroup>
        <Label for="usid">Nome utente o e-mail*</Label>
        <Field
          id="usid"
          name="usid"
          component={RenderField}
          type="text"
          placeholder="Nome utente o e-mail"
        />
      </FormGroup>
      <FormGroup>
        <Label for="password">Password*</Label>
        <Field
          id="password"
          name="password"
          component={RenderField}
          type="password"
          placeholder="Password"
        />
      </FormGroup>
      {props.isLoginFail ? (
        <Alert color="danger">{props.loginError}</Alert>
      ) : null}
      <Button type="submit" color="primary" block disabled={props.submitting}>
        {props.submitting ? <IconSpinner className="icon-spin" /> : ""} Entra
        nell'area riservata
      </Button>
    </Form>
  );
};

export default reduxForm<ILoginFormData, IOwnProps>({
  form: "loginForm",
  validate,
})(LoginForm);
