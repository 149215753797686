import React from "react";
import {Card, CardText, CardTitle, Col, Row} from "reactstrap";
import FormatNumber from "../helpers/FormatNumber";
import {ISavingFormData} from "./SavingForm";

interface IProps {
  saving: ISavingFormData;
}

const SavingTable: React.FC<IProps> = ({saving}) => {
  const savingFloat = parseFloat(saving.saving || "0");
  const gruzzolettoFloat = parseFloat(saving.gruzzoletto || "0");
  return (
    <div>
      <Row>
        <Col sm="6">
          <Card body className="text-white bg-primary text-center">
            <CardTitle>Capacità di risparmio mensile</CardTitle>
            <CardText className="h2">
              € <FormatNumber>{savingFloat}</FormatNumber>
            </CardText>
          </Card>
        </Col>
        <Col>
          <Card body className="text-white bg-primary text-center">
            <CardTitle>Capacità di risparmio annua</CardTitle>
            <CardText className="h2">
              € <FormatNumber>{savingFloat * 12}</FormatNumber>
            </CardText>
          </Card>
        </Col>
      </Row>
      <Card body className="text-white bg-primary text-center mt-3">
        <CardTitle>Liquidità disponibile</CardTitle>
        <CardText className="h2">
          € <FormatNumber>{gruzzolettoFloat}</FormatNumber>
        </CardText>
      </Card>
    </div>
  );
};

export default SavingTable;
