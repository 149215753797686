import {createSelector} from "@reduxjs/toolkit";
import {getCapsEntities} from "../../../Caps/selectors";
import {IRootState} from "../../../redux/reducers";
import {getUser, getUserPermissions} from "../../../User/selectors";
import {selectors as entitySelectors} from "../entities";

export const selectChosenCap = (state: IRootState) =>
  state.beneProposals.common.chosenCap;

export const selectTempCongruenceFilePath = (state: IRootState) =>
  state.beneProposals.common.tempCongruenceFilePath;

export const selectESignId = (state: IRootState) =>
  state.beneProposals.common.eSignId;

export const selectESignIdError = (state: IRootState) =>
  state.beneProposals.common.eSignIdError;

export const selectListIds = (state: IRootState) =>
  state.beneProposals.common.list;

export const selectList = createSelector(
  [entitySelectors.fSelectByIds, selectListIds],
  (fByIds, ids) => fByIds(ids)
);

export const selectChosenCapEntity = createSelector(
  [getCapsEntities, selectChosenCap],
  (caps, capId) => {
    return capId && caps[capId] ? caps[capId] : undefined;
  }
);

export const selectCanSeeChosenCap = createSelector(
  [selectChosenCapEntity, getUser, getUserPermissions],
  (chosenCap, user, permissions) =>
    permissions.includes("bene.read") ||
    permissions.includes("caps.read") ||
    (permissions.includes("cap.read") &&
      !!chosenCap &&
      chosenCap.user === user!.id)
);

export const selectLastCreatedId = (state: IRootState) =>
  state.beneProposals.common.lastCreatedId;

export const selectListLength = (state: IRootState) =>
  state.beneProposals.common.listLength;

export const selectListParams = (state: IRootState) =>
  state.beneProposals.common.listParams;

export const selectIsAdvisor = createSelector(
  [entitySelectors.selectById, getUser],
  (beneProposal, loggedUser) => {
    if (!beneProposal || !loggedUser) {
      return false;
    }
    return beneProposal.user === loggedUser.id;
  }
);
