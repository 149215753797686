import moment from "moment";
import {IBeneProposal} from "../BeneProposals/types";
import {ICap} from "../Caps/types";
import {IRecommendation} from "../Recommendation";

export const isCapComplianceApr21 = (cap: ICap) => {
  const complianceDateString =
    process.env.REACT_APP_DATE_REGULATORY_COMPLIANCE_APR21;

  if (!complianceDateString) {
    return false;
  }
  const complianceDate = moment(complianceDateString);
  const capCreatedDate = cap.dateStart;

  return capCreatedDate.isAfter(complianceDate);
};

export const isRecommendationComplianceApr21 = (
  recommendation: IRecommendation
) => {
  const complianceDateString =
    process.env.REACT_APP_DATE_REGULATORY_COMPLIANCE_APR21;

  if (!complianceDateString) {
    return false;
  }
  const complianceDate = moment(complianceDateString);
  const beneProposalCreatedDate = recommendation.date;

  return beneProposalCreatedDate.isAfter(complianceDate);
};

export const isBeneProposalComplianceApr21 = (beneProposal: IBeneProposal) => {
  const complianceDateString =
    process.env.REACT_APP_DATE_REGULATORY_COMPLIANCE_APR21;

  if (!complianceDateString) {
    return false;
  }
  const complianceDate = moment(complianceDateString);
  const beneProposalCreatedDate = beneProposal.date;

  return beneProposalCreatedDate.isAfter(complianceDate);
};
