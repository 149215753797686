import React from "react";
import {CardText} from "reactstrap";
import homeTypes from "../helpers/homeTypes";
import {IHomeFormData} from "./HomeForm";

interface IProps {
  details: IHomeFormData;
}

const HomeDetails = (props: IProps) => {
  const homeType: {value: string; label: string} | undefined = homeTypes.find(
    (type) => type.value === props.details.tipologiaAbitazione
  );
  return (
    <div>
      <CardText tag="address">
        {props.details.address}, {props.details.zipCode}, {props.details.city} (
        {props.details.region})
        {props.details.phone ? (
          <>
            {" - "}
            <abbr title="Telefono">Tel:</abbr> {props.details.phone}
          </>
        ) : null}
      </CardText>
      <CardText>
        {props.details.contratto === "proprieta"
          ? "Casa di proprietà"
          : props.details.contratto === "affitto"
          ? "Casa in affitto"
          : props.details.contratto === "altro"
          ? "Casa con altra tipologia di contratto"
          : props.details.contratto === "comodato"
          ? "Casa in comodato gratuito"
          : null}{" "}
        | Tipologia: {homeType ? homeType.label : "-"}
        {props.details.contratto === "proprieta" ? (
          props.details.mutuo === "si" ? (
            <span>
              {" "}
              {props.details.mutuo ? " | Mutuo: " + props.details.mutuo : null}
              {props.details.tasso ? " | Tasso: " + props.details.tasso : null}
              {props.details.durata
                ? " | Durata: " + props.details.durata
                : null}
              {props.details.rimanenti
                ? " | Anni rimanenti: " + props.details.rimanenti
                : null}
              {props.details.rata
                ? " | Rata: " + props.details.rata + " €"
                : null}
            </span>
          ) : (
            <span> | Mutuo: no</span>
          )
        ) : null}
        {props.details.contratto === "affitto" ? (
          <span>
            {" "}
            {props.details.importoAffitto
              ? " | Importo mensile affitto: " +
                props.details.importoAffitto +
                " €"
              : null}
          </span>
        ) : null}
      </CardText>
      {props.details.note ? <p>Note: {props.details.note}</p> : null}
    </div>
  );
};
export default HomeDetails;
