import moment from "moment";
import React from "react";
import {connect} from "react-redux";
import {RouteComponentProps} from "react-router";
import {Container} from "reactstrap";
import {Layout} from "../App";
import {actions as companiesActions} from "../Caps/actions/companies";
import {IRootState} from "../redux/reducers";
import {actions, DetailForm, IStateUi} from "./";
import {getUser} from "./selectors";
import {IUser, IUserEntity} from "./types";

interface IStateProps {
  ui: IStateUi;
  user?: IUser;
  // viewUser?: IStateViewUser;
  // denormalizedItems: any[];
}

interface IMatchParams {
  userId: string;
}

interface IDispatchProps {
  loadViewFromDb: (id: string) => Promise<void>;
  loadViewFromRoute: (json: string) => void;
  resetNewUserState: () => void;
  update: (user: IUserEntity) => Promise<void>;
  loadCompanies: () => Promise<void>;
}

type IOwnProps = RouteComponentProps<IMatchParams>;

type IProps = IStateProps & IDispatchProps & IOwnProps;

// const mapStateToProps = (state: IRootState): IStateProps => ({
//   ui: state.users.ui,
//   viewUser: state.users.viewUser
// });

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => {
  // ui: state.caps.ui,
  // users: state.users.list
  // const input = state.users.list;
  // const user = new schema.Entity("users");

  // const entities = {
  // users: state.users.entities
  // };

  return {
    ui: state.users.ui,
    // users: denormalize(input, [user], entities)
    user: getUser(state, ownProps.match.params.userId),
  };
};

const mapDispatchToProps = {
  loadViewFromDb: (id: string) => {
    return actions.viewFromDb(id);
  },
  loadViewFromRoute: (json: string) => {
    return actions.viewFromRoute(json);
  },
  resetNewUserState: () => {
    return actions.resetNewUserState();
  },
  update: (user: IUserEntity) => {
    const updateUser: Partial<IUserEntity> = {...user};
    return actions.update(user.id, updateUser);
  },
  loadCompanies: () => {
    return companiesActions.loadCompanies();
  },
};

class DetailPage extends React.Component<IProps> {
  public componentDidMount() {
    if (!!this.props.match.params.userId && !this.props.user) {
      this.props.loadViewFromDb(this.props.match.params.userId);
    }
  }

  public componentWillUnmount() {
    this.props.resetNewUserState();
  }

  public render() {
    // const user = this.props.user;
    // console.log("JOHN in RENDER"); // tslint:disable-line
    // console.log({user}); // tslint:disable-line
    return (
      // const DetailPage = (props: any) => (
      <Layout isProtected>
        <Container>
          <h2>
            Aggiornamento Utente
            {" - "}
            {this.props.user && this.props.user.usid}
            {" - "}
            {this.props.user && this.props.user.name}{" "}
            {this.props.user && this.props.user.surname}
          </h2>
        </Container>
        <Container className="container-max auto-margin-4 p-md-5">
          {this.props.user && this.props.user.id && (
            <DetailForm
              onSubmit={this.props.update}
              ui={this.props.ui}
              user={this.props.user}
              loadCompanies={this.props.loadCompanies}
            />
          )}
        </Container>
      </Layout>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailPage);
