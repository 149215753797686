import isEmpty from "lodash/isEmpty";
import React from "react";
import {FieldErrors, useForm} from "react-hook-form";
import {
  Alert,
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import {IconCheckmark, IconSpinner} from "../../../Icons";
import {IAppThunkActionStates} from "../../../types/thunk";

export interface IAcceptFormData {
  conferma: string;
}

interface IProps {
  isOpen: boolean;
  onSubmit: (values: IAcceptFormData) => void;
  submitStates: IAppThunkActionStates;
  toggle: () => void;
}

const validate = async (values: IAcceptFormData) => {
  const errors: FieldErrors<IAcceptFormData> = {};

  if (!values.conferma) {
    errors.conferma = {
      type: "required",
      message: "Compila il campo per procedere con l'approvazione",
    };
  } else if (values.conferma !== "APPROVO") {
    errors.conferma = {
      type: "mismatch",
      message: `Per confermare la proposta devi scrivere nel campo "APPROVO"`,
    };
  }

  return {values: isEmpty(errors) ? values : {}, errors};
};

const AcceptModal: React.FC<IProps> = ({
  isOpen,
  onSubmit,
  submitStates,
  toggle,
}) => {
  const {register, handleSubmit, errors} = useForm<IAcceptFormData>({
    mode: "onBlur",
    resolver: validate,
  });

  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Approvazione definitiva</ModalHeader>
      <ModalBody>
        <p>
          L'approvazione della proposta è definitiva e irreversibile. Non sarà
          più possibile richiedere revisioni né modificarne i dati.
        </p>
        <p>
          Per confermare l'approvazione scrivere "APPROVO" nel campo sottostante
          e cliccare sul bottone "Conferma definitiva".
        </p>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {submitStates.isFail && (
            <Alert color="danger">{submitStates.error}</Alert>
          )}
          <Row>
            <Col>
              <FormGroup>
                <Input
                  type="text"
                  name="conferma"
                  innerRef={register}
                  invalid={!!errors?.conferma}
                />
                {errors?.conferma && (
                  <FormFeedback>{errors.conferma.message}</FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col xs="auto">
              <Button color="success" outline disabled={submitStates.isPending}>
                {submitStates.isPending ? (
                  <IconSpinner className="icon-spin" />
                ) : (
                  <IconCheckmark />
                )}{" "}
                Conferma definitiva
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default AcceptModal;
