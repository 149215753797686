import React from "react";
import {ReactTableFunction} from "react-table";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {IRecommendation, RecommendationStatus} from "../Recommendation";

interface IProps {
  filter: any;
  onChange: ReactTableFunction;
  getStatusColor: (
    recommendation: IRecommendation
  ) => "primary" | "info" | "warning" | "success" | "danger";
  getStatusLabel: (status: RecommendationStatus) => string | JSX.Element;
}

const ColumnRecommendationStatusFilter: React.FC<IProps> = ({
  filter,
  getStatusColor,
  getStatusLabel,
  onChange,
}: any) => (
  <UncontrolledDropdown>
    <DropdownToggle
      caret
      size="sm"
      block
      color="primary"
      outline
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {!filter || !filter.value ? (
        <span>Tutti</span>
      ) : (
        <span>
          <span
            className={
              "statusDot text-" + getStatusColor({status: filter.value})
            }
          >
            &#x25cf;
          </span>{" "}
          <span>{getStatusLabel(filter.value)}</span>
        </span>
      )}
    </DropdownToggle>
    <DropdownMenu>
      <DropdownItem onClick={onChange.bind({}, undefined)}>Tutti</DropdownItem>
      {[
        RecommendationStatus.STATUS_CORRESPONDING,
        RecommendationStatus.STATUS_WAIT_REVIEW,
        RecommendationStatus.STATUS_TO_BE_VALIDATED,
        RecommendationStatus.STATUS_REQUEST_ESIGN,
        RecommendationStatus.STATUS_REQUEST_ESIGN_ADVISOR,
      ].map((status) => (
        <DropdownItem key={status} onClick={onChange.bind({}, status)}>
          <span className={"statusDot text-" + getStatusColor({status})}>
            &#x25cf;
          </span>{" "}
          <span>{getStatusLabel(status)}</span>
        </DropdownItem>
      ))}
    </DropdownMenu>
  </UncontrolledDropdown>
);

export default ColumnRecommendationStatusFilter;
