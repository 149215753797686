import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Button, Card} from "reactstrap";
import {ThunkDispatch} from "redux-thunk";
import {ICap} from "../../Caps";
import {IconESignedDocument, IconInfoCircle, IconSpinner} from "../../Icons";
import {IRootState} from "../../redux/reducers";
import {getUser} from "../../User/selectors";
import {actions} from "../actions";
import {getIsAdvisor, getThunkActionStates} from "../selectors";
import {IProposal, IProposalEntity, ProposalStatus} from "../types";
import "./DocumentManagement.scss";
import ChooseProductForm from "./DocumentManagement/ChooseProductForm";
import DocumentsTable from "./DocumentManagement/DocumentsTable";

type IProps =
  | {cap?: undefined; proposal: IProposal}
  | {cap: ICap; proposal?: undefined};

const DocumentManagement = ({cap, proposal}: IProps) => {
  const dispatch: ThunkDispatch<IRootState, any, actions> = useDispatch();
  const isAdvisor = useSelector<IRootState, boolean>((state) =>
    getIsAdvisor(state, proposal?.id)
  );
  const loggedUser = useSelector(getUser)!;

  // const allAdvisorESigns = proposal?.eSign?.files.every((file) =>
  //   file.esigns
  //     .filter((eSign) => eSign.whoEsign === "advisor")
  //     .every((eSign) => !!eSign.esignId)
  // );
  const isSent =
    proposal?.status === ProposalStatus.Complete ||
    proposal?.status === ProposalStatus.WaitingContractorsESign ||
    proposal?.status === ProposalStatus.ToBeValidated ||
    proposal?.status === ProposalStatus.WaitReview ||
    proposal?.status === ProposalStatus.Rejected;

  const handleChooseProductFormSubmit = proposal
    ? (data: Partial<IProposalEntity>) => {
        return dispatch(
          actions.update(proposal.id, {code: data.code}, "chooseProduct")
        );
      }
    : (data: Partial<IProposalEntity>) => {
        return dispatch(
          actions.create({
            cap: cap?.id,
            contractor: cap?.contractor.id,
            user: loggedUser.id,
            ...data,
          })
        );
      };
  const createStates = useSelector((state: IRootState) =>
    getThunkActionStates(state, "create")
  );
  const updateStates = useSelector((state: IRootState) =>
    getThunkActionStates(state, "update", "chooseProduct")
  );
  const sendStates = useSelector((state: IRootState) =>
    getThunkActionStates(state, "update", "sendProposal")
  );

  const handleSubmitToContractor = () => {
    dispatch(
      actions.update(
        proposal!.id,
        {
          status: ProposalStatus.WaitingContractorsESign,
        },
        "sendProposal"
      )
    ).then();
  };

  return (
    <Card body color="drawer auto-margin-3">
      <h3>
        <IconESignedDocument title="Proposta di polizza: caricamento e firma documenti" />{" "}
        Proposta di polizza: caricamento e firma documenti
      </h3>
      <ChooseProductForm
        cap={proposal ? proposal.cap : cap!}
        form={proposal ? "chooseProductBlockedForm" : "chooseProductForm"}
        hasRevisions={!!proposal?.revisions.length}
        initialValues={
          proposal && {
            company: proposal.product?.company.id,
            product: proposal.product?.id,
            code: proposal.code,
          }
        }
        onSubmit={handleChooseProductFormSubmit}
        onSubmitStates={proposal ? updateStates : createStates}
        proposalCreated={!!proposal}
        isSent={isSent}
      />
      {proposal?.eSign && (
        <>
          {isAdvisor && (
            <Alert color="info">
              <h4>Istruzioni per il caricamento dei file</h4>
              <div className="upload-instructions d-flex align-items-center w-100">
                <IconInfoCircle size={3} />
                <div className="w-100">
                  <p>
                    Nella tabella sottostante, verranno elencati tutti i file da
                    caricare, necessari alla creazione della proposta. Ogni file
                    che verrà caricato, dovrà cominciare con il nome del file
                    identificativo del documento.
                    <br />
                    Per il documento identificativo "compagnia_prodotto.pdf" si
                    possono caricare ad esempio:
                  </p>
                  <div style={{overflowX: "hidden"}}>
                    <ul>
                      <li>compagnia_prodotto.pdf</li>
                      <li>compagnia_prodotto_consulente.pdf</li>
                      <li>compagnia_prodotto_consulente_data.pdf</li>
                    </ul>
                  </div>
                  <p className="mb-0">
                    I file dovranno essere tutti in formato PDF (.pdf) ed ognuno
                    non dovrà superare la dimensione massima consentita di 16Mb
                  </p>
                </div>
              </div>
            </Alert>
          )}
          <DocumentsTable proposal={proposal} isSent={isSent} />
          {sendStates.isFail && (
            <Alert color="danger">{sendStates.error}</Alert>
          )}
          {sendStates.isSuccess && (
            <Alert color="success">Proposta inviata al cliente</Alert>
          )}
          {isAdvisor && !isSent && (
            <div>
              <Button
                color="primary"
                outline
                disabled={
                  proposal.status !== ProposalStatus.SignedByAdvisor || isSent
                }
                onClick={handleSubmitToContractor}
              >
                {sendStates.isPending && (
                  <IconSpinner className="icon-spin mr-2" />
                )}
                Carica nell'area riservata dell'utente
              </Button>
            </div>
          )}
        </>
      )}
    </Card>
  );
};

export default DocumentManagement;
