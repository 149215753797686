import {matchSorter} from "match-sorter";
import moment from "moment";
import {batch} from "react-redux";
import {Dispatch} from "redux";
import {change, SubmissionError} from "redux-form";
import {computeNetSalary} from "../helpers/computeNetSalary";
import {TargetsQuestionsKeys} from "../helpers/domandeObiettivi";
import errors, {ErrorCodes} from "../helpers/errors";
import {IRootState} from "../redux/reducers";
import IApiError from "../types/IApiError";
import {actions as userActions} from "../User/actions";
import {
  actions as usersActions,
  IIdentificationHistory,
  IPrivacySnapshot,
  IUserEntity,
  normalizeUser,
} from "../Users";
import usersService from "../Users/service";
import {
  ICap,
  ICapEntity,
  ICapParams,
  ICapsEntities,
  ICity,
  ICopyData,
  IDataSnapshot,
  IDeliverySnapshot,
  IOnboardingSnapshot,
  NewCapModalTypes,
  service,
} from "./";
import {ICompleteContractorFormData} from "./CompleteContractorForm";
import {ICreateContractorFormData} from "./CreateContractorForm";
import {IDeliveryFormData} from "./Delivery/DeliveryForm";
import {IEmploymentFormData, IEmploymentFormDataSingle} from "./EmploymentForm";
import {IHasIBIPsFormData} from "./hasIBIPs/HasIBIPsForm";
import {IHomeFormData} from "./HomeForm";
import {computeIdd, domandeIdd, IIddFormData} from "./idd";
import {IIncomesFormData} from "./IncomesForm";
import {IOverdraftsSectionFormData} from "./OverdraftSectionForm";
import {ISavingFormData} from "./SavingForm";
import {ITargetFormData} from "./TargetsForm";
import {normalizeCap} from "./types";
import {IUpdateContractorFormData} from "./UpdateContractorForm";
import {IUploadIdFormData} from "./UploadIdForm";

export enum ActionTypes {
  MERGE_ITEMS = "[Caps] Merge items",

  OPEN_NEW_CAP_MODAL = "[Caps] Open modal",
  CLOSE_NEW_CAP_MODAL = "[Caps] Close modal",

  LIST_REQUEST = "[Caps] List request",
  LIST_SUCCESS = "[Caps] List success",
  LIST_FAIL = "[Caps] List fail",

  CREATE_REQUEST = "[Caps] Create request",
  CREATE_SUCCESS = "[Caps] Create success",
  CREATE_FAIL = "[Caps] Create fail",

  UPDATE_REQUEST = "[Caps] Update request",
  UPDATE_SUCCESS = "[Caps] Update success",
  UPDATE_FAIL = "[Caps] Update fail",

  DELETE_REQUEST = "[Caps] Delete request",
  DELETE_SUCCESS = "[Caps] Delete success",
  DELETE_FAIL = "[Caps] Delete fail",
  RESET_DELETE_SUCCESS = "[Caps] Reset delete success",

  UNDO_DELETE_REQUEST = "[Caps] Undo delete request",
  UNDO_DELETE_SUCCESS = "[Caps] Undo delete success",
  UNDO_DELETE_FAIL = "[Caps] Undo delete fail",

  CREATE_CONTRACTOR_REQUEST = "[Caps] Create contractor request",
  CREATE_CONTRACTOR_SUCCESS = "[Caps] Create contractor success",
  CREATE_CONTRACTOR_FAIL = "[Caps] Create contractor fail",
  CREATE_CONTRACTOR_ANOTHER_ADVISOR = "[Caps] Create contractor another Advisor",
  CREATE_CONTRACTOR_RESET = "[Caps] Create contractor reset",

  USE_EXISTING_CONTRACTOR_REQUEST = "[Caps] Use existing contractor request",
  USE_EXISTING_CONTRACTOR_SUCCESS = "[Caps] Use existing contractor success",
  USE_EXISTING_CONTRACTOR_FAIL = "[Caps] Use existing contractor fail",

  SAVE_CONTRACTOR_ID_CREATE_ERROR = "[Caps] Save existing id contractor to fail creator",
  RESET_CREATE_USE_EXISTING_CONTRACTOR = "[Caps] Reset contractor",
  RESET_LAST_CREATED_ID = "[Caps] Reset last created id",
  RESET_READ_REDIRECT = "[Caps] Reset read redirect",

  COMPLETE_CONTRACTOR_REQUEST = "[Caps] Edit contractor request",
  COMPLETE_CONTRACTOR_SUCCESS = "[Caps] Edit contractor success",
  COMPLETE_CONTRACTOR_FAIL = "[Caps] Edit contractor fail",

  READ_REQUEST = "[Caps] New cap from db request",
  READ_SUCCESS = "[Caps] New cap from db success",
  READ_FAIL = "[Caps] New cap from db fail",
  READ_RESET_UI_ERROR = "[Caps] Reset errors from db",

  UPLOAD_ID_REQUEST = "[Caps] Upload identification document request",
  UPLOAD_ID_SUCCESS = "[Caps] Upload identification document success",
  UPLOAD_ID_FAIL = "[Caps] Upload identification document fail",

  CONFIRM_IDENTIFICATION_REQUEST = "[Caps] Confirm identification request",
  CONFIRM_IDENTIFICATION_SUCCESS = "[Caps] Confirm identification success",
  CONFIRM_IDENTIFICATION_FAIL = "[Caps] Confirm identification fail",

  HOME_REQUEST = "[Caps] Home request",
  HOME_SUCCESS = "[Caps] Home success",
  HOME_FAIL = "[Caps] Home fail",

  EMPLOYMENT_REQUEST = "[Caps] Employment request",
  EMPLOYMENT_SUCCESS = "[Caps] Employment success",
  EMPLOYMENT_FAIL = "[Caps] Employment fail",

  INCOMES_REQUEST = "[Caps] Incomes request",
  INCOMES_SUCCESS = "[Caps] Incomes success",
  INCOMES_FAIL = "[Caps] Incomes fail",

  TARGET_REQUEST = "[Caps] Target request",
  TARGET_SUCCESS = "[Caps] Target success",
  TARGET_FAIL = "[Caps] Target fail",

  SAVING_REQUEST = "[Caps] Saving request",
  SAVING_SUCCESS = "[Caps] Saving success",
  SAVING_FAIL = "[Caps] Saving fail",

  OVERDRAFT_REQUEST = "[Caps] Overdraft request",
  OVERDRAFT_SUCCESS = "[Caps] Overdraft success",
  OVERDRAFT_FAIL = "[Caps] Overdraft fail",

  HAS_IBIPS_REQUEST = "[Caps] Has IBIPs request",
  HAS_IBIPS_SUCCESS = "[Caps] Has IBIPs success",
  HAS_IBIPS_FAIL = "[Caps] Has IBIPs fail",

  INJURY = "[Caps] Injury submit",
  MORTE = "[Caps] Morte submit",
  RETIREMENT = "[Caps] Retirement submit",
  TUTELA = "[Caps] Tutela submit",

  IDD_REQUEST = "[Caps] Idd request",
  IDD_SUCCESS = "[Caps] Idd success",
  IDD_FAIL = "[Caps] Idd fail",
  IDD_FROM_VIEW = "[Caps] Idd from view",

  CLOSE_DEN_FAIL = "[Caps] Close DeN fail",
  CLOSE_DEN_REQUEST = "[Caps] Close DeN request",
  CLOSE_DEN_SUCCESS = "[Caps] Close DeN success",

  CLOSE_OVERDRAFTS_FAIL = "[Caps] Close overdrafts fail",
  CLOSE_OVERDRAFTS_REQUEST = "[Caps] Close overdrafts request",
  CLOSE_OVERDRAFTS_SUCCESS = "[Caps] Close overdrafts success",

  REQUEST_VALIDATION_FAIL = "[Caps] Request validation fail",
  REQUEST_VALIDATION_REQUEST = "[Caps] Request validation request",
  REQUEST_VALIDATION_SUCCESS = "[Caps] Request validation success",

  CLOSE_VALIDATION_FAIL = "[Caps] Close Validation fail",
  CLOSE_VALIDATION_REQUEST = "[Caps] Close Validation request",
  CLOSE_VALIDATION_SUCCESS = "[Caps] Close Validation success",

  TOGGLE_IDD_SEND_MODAL = "[Caps] Toggle idd send modal",
  IDD_SEND_FAIL = "[Caps] Idd send fail",
  IDD_SEND_REQUEST = "[Caps] Idd send request",
  IDD_SEND_SUCCESS = "[Caps] Idd send success",
  RESET_IS_IDD_SEND_SUCCESS = "[Caps] Reset idd send success",

  DELIVERY_REQUEST = "[Caps] Delivery request",
  DELIVERY_SUCCESS = "[Caps] Delivery success",
  DELIVERY_FAIL = "[Caps] Delivery fail",

  IDENTIFICATION_NOTIFICATION_REQUEST = "[Caps] Identification notification request",
  IDENTIFICATION_NOTIFICATION_SUCCESS = "[Caps] Identification notification success",
  IDENTIFICATION_NOTIFICATION_FAIL = "[Caps] Identification notification fail",

  VIEW_FROM_DB_REQUEST = "[Caps] View cap from db request",
  VIEW_FROM_DB_SUCCESS = "[Caps] View cap from db success",
  VIEW_FROM_DB_FAIL = "[Caps] View cap from db fail",
  VIEW_FROM_DB_RESET_UI_ERROR = "[Caps] Reset errors view from db",

  LOAD_COPY_REQUEST = "[Caps] Load copy request",
  LOAD_COPY_SUCCESS = "[Caps] Load copy success",
  LOAD_COPY_FAIL = "[Caps] Load copy fail",
  LOAD_COPY_RESET = "[Caps] Load copy reset",

  COPY_DATA_IN_CAP_REQUEST = "[Caps] Copy data in cap request",
  COPY_DATA_IN_CAP_SUCCESS = "[Caps] Copy data in cap success",
  COPY_DATA_IN_CAP_FAIL = "[Caps] Copy data in cap fail",

  LOAD_CITIES_REQUEST = "[Caps] Load cities request",
  LOAD_CITIES_SUCCESS = "[Caps] Load cities success",
  LOAD_CITIES_FAIL = "[Caps] Load cities fail",
}

interface IMergeItemsAction {
  type: typeof ActionTypes.MERGE_ITEMS;
  payload: {caps: ICapsEntities};
}

interface IOpenNewCapModal {
  payload: {modalType: NewCapModalTypes};
  type: typeof ActionTypes.OPEN_NEW_CAP_MODAL;
}
interface ICloseNewCapModal {
  payload: {modalType: NewCapModalTypes};
  type: typeof ActionTypes.CLOSE_NEW_CAP_MODAL;
}

interface IListRequestAction {
  type: typeof ActionTypes.LIST_REQUEST;
}

interface IListSuccessAction {
  type: typeof ActionTypes.LIST_SUCCESS;
  payload: {capIds: string[]; numCaps: number; params: ICapParams};
}

interface IListFailAction {
  type: typeof ActionTypes.LIST_FAIL;
  payload: IApiError;
}

interface ICreateRequestAction {
  type: typeof ActionTypes.CREATE_REQUEST;
}

interface ICreateSuccessAction {
  type: typeof ActionTypes.CREATE_SUCCESS;
  payload: {capId: string};
}

interface ICreateFailAction {
  type: typeof ActionTypes.CREATE_FAIL;
  payload: IApiError;
}

interface IUpdateRequestAction {
  type: typeof ActionTypes.UPDATE_REQUEST;
}

interface IUpdateSuccessAction {
  type: typeof ActionTypes.UPDATE_SUCCESS;
  payload: {capId: string};
}

interface IUpdateFailAction {
  type: typeof ActionTypes.UPDATE_FAIL;
  payload: IApiError;
}

interface IDeleteRequestAction {
  type: typeof ActionTypes.DELETE_REQUEST;
  payload: {id: string};
}
interface IDeleteSuccessAction {
  type: typeof ActionTypes.DELETE_SUCCESS;
  payload: {id: string};
}
interface IDeleteFailAction {
  type: typeof ActionTypes.DELETE_FAIL;
  payload: {
    id: string;
    error: IApiError;
  };
}

interface IResetDeleteSuccess {
  type: typeof ActionTypes.RESET_DELETE_SUCCESS;
}

interface IUndoDeleteFailAction {
  type: typeof ActionTypes.UNDO_DELETE_FAIL;
  payload: {error: IApiError};
}

interface IUndoDeleteRequestAction {
  type: typeof ActionTypes.UNDO_DELETE_REQUEST;
}

interface IUndoDeleteSuccessAction {
  type: typeof ActionTypes.UNDO_DELETE_SUCCESS;
}

interface ICreateContractorRequestAction {
  type: typeof ActionTypes.CREATE_CONTRACTOR_REQUEST;
}
interface ICreateContractorSuccessAction {
  type: typeof ActionTypes.CREATE_CONTRACTOR_SUCCESS;
  payload: {capId: string};
}
interface ICreateContractorFailAction {
  type: typeof ActionTypes.CREATE_CONTRACTOR_FAIL;
  payload?: IApiError;
}
interface ICreateContractorAnotherAdvisorAction {
  type: typeof ActionTypes.CREATE_CONTRACTOR_ANOTHER_ADVISOR;
  payload?: IApiError;
}
interface ICreateContractorResetAction {
  type: typeof ActionTypes.CREATE_CONTRACTOR_RESET;
}

interface IUseExistingContractorRequestAction {
  type: typeof ActionTypes.USE_EXISTING_CONTRACTOR_REQUEST;
}
interface IUseExistingContractorSuccessAction {
  type: typeof ActionTypes.USE_EXISTING_CONTRACTOR_SUCCESS;
  payload: {capId: string};
}
interface IUseExistingContractorFailAction {
  type: typeof ActionTypes.USE_EXISTING_CONTRACTOR_FAIL;
  payload: IApiError;
}

interface ISaveContractorIdCreateErrorAction {
  type: typeof ActionTypes.SAVE_CONTRACTOR_ID_CREATE_ERROR;
  payload: {contractorId: string};
}

interface IResetCreateUseExistingContractor {
  type: typeof ActionTypes.RESET_CREATE_USE_EXISTING_CONTRACTOR;
}
interface IResetLastCreatedId {
  type: typeof ActionTypes.RESET_LAST_CREATED_ID;
}
interface IResetReadRedirect {
  type: typeof ActionTypes.RESET_READ_REDIRECT;
}

interface ICompleteContractorRequestAction {
  type: typeof ActionTypes.COMPLETE_CONTRACTOR_REQUEST;
}

interface ICompleteContractorSuccessAction {
  type: typeof ActionTypes.COMPLETE_CONTRACTOR_SUCCESS;
  payload: {contractorId: string};
}

interface ICompleteContractorFailAction {
  type: typeof ActionTypes.COMPLETE_CONTRACTOR_FAIL;
  payload?: IApiError;
}

interface IReadRequestAction {
  type: typeof ActionTypes.READ_REQUEST;
}

interface IReadSuccessAction {
  type: typeof ActionTypes.READ_SUCCESS;
  payload: {capId: string};
}

interface IReadFailAction {
  type: typeof ActionTypes.READ_FAIL;
  payload: IApiError;
}

interface IReadResetUiErrorAction {
  type: typeof ActionTypes.READ_RESET_UI_ERROR;
}

interface IUploadIdRequestAction {
  type: typeof ActionTypes.UPLOAD_ID_REQUEST;
}
interface IUploadIdSuccessAction {
  payload: {userId: string};
  type: typeof ActionTypes.UPLOAD_ID_SUCCESS;
}
interface IUploadIdFailAction {
  type: typeof ActionTypes.UPLOAD_ID_FAIL;
  payload: {
    error: IApiError;
  };
}

interface IConfirmIdentificationRequestAction {
  type: typeof ActionTypes.CONFIRM_IDENTIFICATION_REQUEST;
}
interface IConfirmIdentificationSuccessAction {
  payload: {capId: string};
  type: typeof ActionTypes.CONFIRM_IDENTIFICATION_SUCCESS;
}
interface IConfirmIdentificationFailAction {
  type: typeof ActionTypes.CONFIRM_IDENTIFICATION_FAIL;
  payload: {
    error: IApiError;
  };
}

interface IHomeRequestAction {
  type: typeof ActionTypes.HOME_REQUEST;
}

interface IHomeSuccessAction {
  type: typeof ActionTypes.HOME_SUCCESS;
}

interface IHomeFailAction {
  type: typeof ActionTypes.HOME_FAIL;
  payload: {
    error: IApiError;
  };
}

interface IEmploymentRequestAction {
  type: typeof ActionTypes.EMPLOYMENT_REQUEST;
}

interface IEmploymentSuccessAction {
  type: typeof ActionTypes.EMPLOYMENT_SUCCESS;
}

interface IEmploymentFailAction {
  type: typeof ActionTypes.EMPLOYMENT_FAIL;
  payload: {
    error: IApiError;
  };
}

interface IIncomesRequestAction {
  type: typeof ActionTypes.INCOMES_REQUEST;
}

interface IIncomesSuccessAction {
  type: typeof ActionTypes.INCOMES_SUCCESS;
}
interface IIncomesFailAction {
  type: typeof ActionTypes.INCOMES_FAIL;
  payload: {
    error: IApiError;
  };
}

interface ITargetRequestAction {
  type: typeof ActionTypes.TARGET_REQUEST;
}

interface ITargetSuccessAction {
  type: typeof ActionTypes.TARGET_SUCCESS;
}

interface ITargetFailAction {
  type: typeof ActionTypes.TARGET_FAIL;
  payload: {
    error: IApiError;
  };
}

interface ISavingRequestAction {
  type: typeof ActionTypes.SAVING_REQUEST;
}

interface ISavingSuccessAction {
  type: typeof ActionTypes.SAVING_SUCCESS;
}
interface ISavingFailAction {
  type: typeof ActionTypes.SAVING_FAIL;
  payload: {
    error: IApiError;
  };
}

interface IOverdraftRequestAction {
  type: typeof ActionTypes.OVERDRAFT_REQUEST;
}

interface IOverdraftSuccessAction {
  type: typeof ActionTypes.OVERDRAFT_SUCCESS;
}

interface IOverdraftFailAction {
  type: typeof ActionTypes.OVERDRAFT_FAIL;
  payload: {
    error: IApiError;
  };
}

interface IHasIBIPsRequestAction {
  type: typeof ActionTypes.HAS_IBIPS_REQUEST;
}

interface IHasIBIPsSuccessAction {
  type: typeof ActionTypes.HAS_IBIPS_SUCCESS;
}

interface IHasIBIPsFailAction {
  type: typeof ActionTypes.HAS_IBIPS_FAIL;
  payload: {
    error: IApiError;
  };
}

interface IInjuryAction {
  type: typeof ActionTypes.INJURY;
  payload: {evaluated: boolean};
}

interface IIddRequestAction {
  type: typeof ActionTypes.IDD_REQUEST;
}
interface IIddSuccessAction {
  type: typeof ActionTypes.IDD_SUCCESS;
}
interface IIddFailAction {
  type: typeof ActionTypes.IDD_FAIL;
  payload: {error: IApiError};
}

interface IIddFromViewAction {
  type: typeof ActionTypes.IDD_FROM_VIEW;
  payload: {
    cap: ICap;
  };
}

interface IRetirementAction {
  type: typeof ActionTypes.RETIREMENT;
  payload: {evaluated: boolean};
}

interface IMorteAction {
  type: typeof ActionTypes.MORTE;
  payload: {evaluated: boolean};
}

interface ITutelaAction {
  type: typeof ActionTypes.TUTELA;
  payload: {evaluated: boolean};
}

interface IToggleIddSendModalAction {
  type: typeof ActionTypes.TOGGLE_IDD_SEND_MODAL;
  payload: {contractorId: string};
}

interface ICloseDenFailAction {
  type: typeof ActionTypes.CLOSE_DEN_FAIL;
  payload: {error: IApiError};
}

interface ICloseDenRequestAction {
  type: typeof ActionTypes.CLOSE_DEN_REQUEST;
}

interface ICloseDenSuccessAction {
  type: typeof ActionTypes.CLOSE_DEN_SUCCESS;
}

interface ICloseOverdraftsFailAction {
  type: typeof ActionTypes.CLOSE_OVERDRAFTS_FAIL;
  payload: {error: IApiError};
}

interface ICloseOverdraftsRequestAction {
  type: typeof ActionTypes.CLOSE_OVERDRAFTS_REQUEST;
}

interface ICloseOverdraftsSuccessAction {
  type: typeof ActionTypes.CLOSE_OVERDRAFTS_SUCCESS;
}

interface IRequestValidationFailAction {
  type: typeof ActionTypes.REQUEST_VALIDATION_FAIL;
  payload: {error: IApiError};
}

interface IRequestValidationRequestAction {
  type: typeof ActionTypes.REQUEST_VALIDATION_REQUEST;
}

interface IRequestValidationSuccessAction {
  type: typeof ActionTypes.REQUEST_VALIDATION_SUCCESS;
}

interface ICloseValidationFailAction {
  type: typeof ActionTypes.CLOSE_VALIDATION_FAIL;
  payload: {error: IApiError};
}

interface ICloseValidationRequestAction {
  type: typeof ActionTypes.CLOSE_VALIDATION_REQUEST;
}

interface ICloseValidationSuccessAction {
  type: typeof ActionTypes.CLOSE_VALIDATION_SUCCESS;
}

interface IIddSendFailAction {
  type: typeof ActionTypes.IDD_SEND_FAIL;
  payload: {error: IApiError};
}
interface IIddSendRequestAction {
  type: typeof ActionTypes.IDD_SEND_REQUEST;
}
interface IIddSendSuccessAction {
  type: typeof ActionTypes.IDD_SEND_SUCCESS;
}

interface IDeliveryFailAction {
  type: typeof ActionTypes.DELIVERY_FAIL;
  payload: {error: IApiError};
}
interface IDeliveryRequestAction {
  type: typeof ActionTypes.DELIVERY_REQUEST;
}
interface IDeliverySuccessAction {
  type: typeof ActionTypes.DELIVERY_SUCCESS;
}

interface IIdentificationNotificationFailAction {
  type: typeof ActionTypes.IDENTIFICATION_NOTIFICATION_FAIL;
  payload: {error: IApiError};
}
interface IIdentificationNotificationRequestAction {
  type: typeof ActionTypes.IDENTIFICATION_NOTIFICATION_REQUEST;
}
interface IIdentificationNotificationSuccessAction {
  type: typeof ActionTypes.IDENTIFICATION_NOTIFICATION_SUCCESS;
}

interface IResetIsIddSendSuccessAction {
  type: typeof ActionTypes.RESET_IS_IDD_SEND_SUCCESS;
}

interface IViewFromDbRequestAction {
  type: typeof ActionTypes.VIEW_FROM_DB_REQUEST;
}

interface IViewFromDbSuccessAction {
  type: typeof ActionTypes.VIEW_FROM_DB_SUCCESS;
  payload: {capId: string};
}

interface IViewFromDbFailAction {
  type: typeof ActionTypes.VIEW_FROM_DB_FAIL;
  payload: IApiError;
}

interface IViewFromDbResetUiErrorAction {
  type: typeof ActionTypes.VIEW_FROM_DB_RESET_UI_ERROR;
}

interface ILoadCopyFailAction {
  type: typeof ActionTypes.LOAD_COPY_FAIL;
  payload: {error: IApiError};
}
interface ILoadCopyRequestAction {
  type: typeof ActionTypes.LOAD_COPY_REQUEST;
}
interface ILoadCopySuccessAction {
  type: typeof ActionTypes.LOAD_COPY_SUCCESS;
  payload: {copyData: ICopyData};
}
interface ILoadCopyResetAction {
  type: typeof ActionTypes.LOAD_COPY_RESET;
}

interface ICopyDataInCapRequestAction {
  type: typeof ActionTypes.COPY_DATA_IN_CAP_REQUEST;
}
interface ICopyDataInCapSuccessAction {
  type: typeof ActionTypes.COPY_DATA_IN_CAP_SUCCESS;
}
interface ICopyDataInCapFailAction {
  type: typeof ActionTypes.COPY_DATA_IN_CAP_FAIL;
  payload: {
    error: IApiError;
  };
}

interface ILoadCitiesFailAction {
  type: typeof ActionTypes.LOAD_CITIES_FAIL;
  payload: {error: IApiError};
}
interface ILoadCitiesRequestAction {
  type: typeof ActionTypes.LOAD_CITIES_REQUEST;
}
interface ILoadCitiesSuccessAction {
  type: typeof ActionTypes.LOAD_CITIES_SUCCESS;
  payload: {cities: ICity[]};
  meta: {options: {exist?: boolean; name?: string; limit?: number}};
}

export const actions = {
  mergeItems: (caps: ICapsEntities): IMergeItemsAction => ({
    payload: {caps},
    type: ActionTypes.MERGE_ITEMS,
  }),

  closeNewCapModal: (modalType: NewCapModalTypes): ICloseNewCapModal => ({
    payload: {modalType},
    type: ActionTypes.CLOSE_NEW_CAP_MODAL,
  }),
  openNewCapModal: (modalType: NewCapModalTypes): IOpenNewCapModal => ({
    payload: {modalType},
    type: ActionTypes.OPEN_NEW_CAP_MODAL,
  }),

  list: (params: ICapParams = {limit: 50, offset: 0}) => (
    dispatch: Dispatch<Actions | usersActions>
  ) => {
    dispatch(actions.listRequest());
    return service
      .list(params)
      .then(({capsList, numCaps}) => {
        const {
          result,
          entities: {caps, users},
        } = normalizeCap(capsList);

        batch(() => {
          dispatch(usersActions.mergeItems(users));
          dispatch(actions.mergeItems(caps));

          dispatch(actions.listSuccess(result, numCaps, params));
        });
      })
      .catch((error) => {
        console.error(error); // tslint:disable-line
        dispatch(actions.listFail(error));
      });
  },
  listFail: (error: IApiError): IListFailAction => ({
    payload: error,
    type: ActionTypes.LIST_FAIL,
  }),
  listRequest: (): IListRequestAction => ({
    type: ActionTypes.LIST_REQUEST,
  }),
  listSuccess: (
    capIds: string[],
    numCaps: number,
    params: ICapParams
  ): IListSuccessAction => ({
    payload: {capIds, numCaps, params},
    type: ActionTypes.LIST_SUCCESS,
  }),

  create: (cap: Partial<ICapEntity>) => (
    dispatch: Dispatch<Actions | usersActions>
  ) => {
    dispatch(actions.createRequest());
    return service
      .create(cap)
      .then((createdCap) => {
        const {
          result,
          entities: {caps, users},
        } = normalizeCap(createdCap);
        batch(() => {
          dispatch(usersActions.mergeItems(users));
          dispatch(actions.mergeItems(caps));

          dispatch(actions.createSuccess(result));
        });
      })
      .catch((error) => {
        dispatch(actions.createFail(error));
      });
  },
  createFail: (error: IApiError): ICreateFailAction => ({
    payload: error,
    type: ActionTypes.CREATE_FAIL,
  }),
  createRequest: (): ICreateRequestAction => ({
    type: ActionTypes.CREATE_REQUEST,
  }),
  createSuccess: (capId: string): ICreateSuccessAction => ({
    payload: {capId},
    type: ActionTypes.CREATE_SUCCESS,
  }),

  update: (id: string, cap: Partial<ICapEntity>) => (
    dispatch: Dispatch<Actions | usersActions>
  ) => {
    dispatch(actions.updateRequest());

    return service
      .update(id, cap)
      .then((updatedCap) => {
        const {
          result,
          entities: {caps, users},
        } = normalizeCap(updatedCap);
        batch(() => {
          dispatch(usersActions.mergeItems(users));
          dispatch(actions.mergeItems(caps));

          dispatch(actions.updateSuccess(result));
        });
      })
      .catch((error) => {
        dispatch(actions.updateFail(error));
        throw error; // tslint:disable-line
      });
  },
  updateFail: (error: IApiError): IUpdateFailAction => ({
    payload: error,
    type: ActionTypes.UPDATE_FAIL,
  }),
  updateRequest: (): IUpdateRequestAction => ({
    type: ActionTypes.UPDATE_REQUEST,
  }),
  updateSuccess: (capId: string): IUpdateSuccessAction => ({
    payload: {capId},
    type: ActionTypes.UPDATE_SUCCESS,
  }),

  delete: (id: string) => (dispatch: Dispatch<Actions>) => {
    dispatch(actions.deleteRequest(id));
    return service
      .update(id, {
        status: "0",
      })
      .then(() => {
        dispatch(actions.deleteSuccess(id));
      })
      .catch((error) => {
        dispatch(actions.deleteFail(error, id));
      });
  },
  deleteFail: (error: IApiError, id: string): IDeleteFailAction => ({
    payload: {error, id},
    type: ActionTypes.DELETE_FAIL,
  }),
  deleteRequest: (id: string): IDeleteRequestAction => ({
    payload: {id},
    type: ActionTypes.DELETE_REQUEST,
  }),
  deleteSuccess: (id: string): IDeleteSuccessAction => ({
    payload: {id},
    type: ActionTypes.DELETE_SUCCESS,
  }),
  resetDeleteSuccess: (): IResetDeleteSuccess => ({
    type: ActionTypes.RESET_DELETE_SUCCESS,
  }),

  undoDelete: (params?: ICapParams) => (
    dispatch: Dispatch<Actions | usersActions>,
    getState: () => IRootState
  ) => {
    const lastDeleted = getState().caps.ui.lastDeleted;
    if (!lastDeleted) {
      return Promise.reject(errors[ErrorCodes.CAP_NOT_FOUND]);
    }
    const id = getState().caps.entities[lastDeleted].id;

    dispatch(actions.undoDeleteRequest());
    return service
      .update(id, {
        status: "1",
      })
      .then((responseJson) => {
        batch(() => {
          dispatch(actions.undoDeleteSuccess());
          dispatch(actions.resetDeleteSuccess());
        });
        actions.list(params)(dispatch);
      })
      .catch((error) => dispatch(actions.undoDeleteFail(error)));
  },
  undoDeleteFail: (error: IApiError): IUndoDeleteFailAction => ({
    payload: {error},
    type: ActionTypes.UNDO_DELETE_FAIL,
  }),
  undoDeleteRequest: (): IUndoDeleteRequestAction => ({
    type: ActionTypes.UNDO_DELETE_REQUEST,
  }),
  undoDeleteSuccess: (): IUndoDeleteSuccessAction => ({
    type: ActionTypes.UNDO_DELETE_SUCCESS,
  }),

  createContractor: (values: ICreateContractorFormData) => (
    dispatch: Dispatch<Actions | usersActions>,
    getState: () => IRootState
  ) => {
    dispatch(actions.createContractorRequest());
    const {user} = getState().user;
    if (!user) {
      dispatch(
        actions.createContractorFail(
          errors[ErrorCodes.MISSING_USER_IN_CAP_CREATION]
        )
      );
      return Promise.reject();
    }

    // Creo il contractor
    const newUser: Partial<IUserEntity> = {...values};
    // USID di default è la mail
    newUser.usid = newUser.email;
    newUser.rolesId = [];
    newUser.rolesId[7] = true;

    return usersService
      .create(newUser)
      .then((contractor) => {
        return service.create({
          contractor: contractor.id,
          user: user.id,
        });
      })
      .then((createdCap) => {
        const {
          result,
          entities: {caps, users},
        } = normalizeCap(createdCap);
        batch(() => {
          dispatch(usersActions.mergeItems(users));
          dispatch(actions.mergeItems(caps));

          dispatch(actions.createContractorSuccess(result));
        });
        // TODO: da eliminare: fix temporaneo per il bug della modale che non scrolla dopo l'invio del contractor
        setTimeout(() => {
          const modal = document.getElementsByClassName(
            "modal-open"
          )?.[0] as HTMLElement;
          if (modal) {
            modal.classList.remove("modal-open");
            modal.style.paddingRight = "0";
          }
        }, 100);
      })
      .catch((error) => {
        // Esiste già lo user che si sta creando, torniamo l'errore al form che lo gestisce
        // qui devo utilizzare ERROR.USERID dell'errore per salvare quel id che devo mantenere nello state.
        // Creerò una nuova actions, che lo tiene salvato e poi nello USEEXISTING lo richiamo
        if (error.code === ErrorCodes.USER_WITH_CF_ALREADY_EXISTS) {
          dispatch(actions.saveContractorIdCreateError(error.userId));
          dispatch(actions.createContractorFail());
          throw new SubmissionError({
            fiscalCode: error.message,
          });
        } else if (
          error.code === ErrorCodes.USER_WITH_USID_ALREADY_EXISTS ||
          error.code === ErrorCodes.USER_WITH_EMAIL_ALREADY_EXISTS
        ) {
          dispatch(actions.saveContractorIdCreateError(error.userId));
          dispatch(actions.createContractorFail());
          throw new SubmissionError({
            email: error.message,
          });
        } else if (
          error.code === ErrorCodes.USER_WITH_CAP_WITH_ANOTHER_ADVISOR
        ) {
          dispatch(actions.saveContractorIdCreateError(error.userId));
          dispatch(actions.createContractorAnotherAdvisor());
        }
        dispatch(actions.createContractorFail(error));
      });
  },
  createContractorAnotherAdvisor: (
    error?: IApiError
  ): ICreateContractorAnotherAdvisorAction => ({
    payload: error,
    type: ActionTypes.CREATE_CONTRACTOR_ANOTHER_ADVISOR,
  }),
  createContractorFail: (error?: IApiError): ICreateContractorFailAction => ({
    payload: error,
    type: ActionTypes.CREATE_CONTRACTOR_FAIL,
  }),
  createContractorRequest: (): ICreateContractorRequestAction => ({
    type: ActionTypes.CREATE_CONTRACTOR_REQUEST,
  }),
  createContractorReset: (): ICreateContractorResetAction => ({
    type: ActionTypes.CREATE_CONTRACTOR_RESET,
  }),
  createContractorSuccess: (capId: string): ICreateContractorSuccessAction => ({
    payload: {capId},
    type: ActionTypes.CREATE_CONTRACTOR_SUCCESS,
  }),

  saveContractorIdCreateError: (
    contractorId: string
  ): ISaveContractorIdCreateErrorAction => ({
    payload: {contractorId},
    type: ActionTypes.SAVE_CONTRACTOR_ID_CREATE_ERROR,
  }),

  useExistingContractor: () => (
    dispatch: Dispatch<Actions | usersActions | userActions>,
    getState: () => IRootState
  ) => {
    dispatch(actions.createContractorRequest());
    const {user, permissions} = getState().user;
    if (!user) {
      dispatch(
        actions.createContractorFail(
          errors[ErrorCodes.MISSING_USER_IN_CAP_CREATION]
        )
      );
      return Promise.reject();
    }

    const contractorId = getState().caps.newCap.errorExistingUserId;

    return service
      .create({
        contractor: contractorId,
        user: user.id,
      })
      .then((createdCap) => {
        const {
          result,
          entities: {caps, users},
        } = normalizeCap(createdCap);

        batch(() => {
          // Lasciare per primo il success altrimenti si bugga la modale perché viene
          //  rimossa prima di essere chiusa
          dispatch(actions.createContractorSuccess(result));

          // Se è una autoanalisi, aggiungo il permesso di contractor per triggerare la modale...
          if (contractorId === user.id) {
            dispatch(
              userActions.updateUserLocalStorage({
                permissions: [...permissions, "cap.contractor-read"],
              })
            );
          }
          // ...e poi aggiorno l'utente da server
          userActions.ping()(dispatch);

          dispatch(usersActions.mergeItems(users));
          dispatch(actions.mergeItems(caps));
        });
      })
      .catch((error) => {
        dispatch(actions.createContractorFail(error));
      });
  },
  useExistingContractorFail: (
    error: IApiError
  ): IUseExistingContractorFailAction => ({
    payload: error,
    type: ActionTypes.USE_EXISTING_CONTRACTOR_FAIL,
  }),
  useExistingContractorRequest: (): IUseExistingContractorRequestAction => ({
    type: ActionTypes.USE_EXISTING_CONTRACTOR_REQUEST,
  }),
  useExistingContractorSuccess: (
    capId: string
  ): IUseExistingContractorSuccessAction => ({
    payload: {capId},
    type: ActionTypes.USE_EXISTING_CONTRACTOR_SUCCESS,
  }),

  resetCreateUseExistingContractor: (): IResetCreateUseExistingContractor => ({
    type: ActionTypes.RESET_CREATE_USE_EXISTING_CONTRACTOR,
  }),
  resetLastCreatedId: (): IResetLastCreatedId => ({
    type: ActionTypes.RESET_LAST_CREATED_ID,
  }),
  resetReadRedirect: (): IResetReadRedirect => ({
    type: ActionTypes.RESET_READ_REDIRECT,
  }),

  updateContractor: (
    values: IUpdateContractorFormData,
    contractor: IUserEntity
  ) => (dispatch: Dispatch<Actions | usersActions>) => {
    dispatch(actions.completeContractorRequest());

    return usersService
      .update(contractor.id, {...values})
      .then((updatedUser) => {
        const {
          result,
          entities: {users},
        } = normalizeUser(updatedUser);
        batch(() => {
          dispatch(usersActions.mergeItems(users));

          dispatch(actions.completeContractorSuccess(result));
        });
      })
      .catch((error: IApiError) => {
        if (error.code === ErrorCodes.USER_WITH_CF_ALREADY_EXISTS) {
          dispatch(actions.completeContractorFail());
          throw new SubmissionError({
            fiscalCode: error.message,
          });
        } else if (
          error.code === ErrorCodes.USER_WITH_USID_ALREADY_EXISTS ||
          error.code === ErrorCodes.USER_WITH_EMAIL_ALREADY_EXISTS
        ) {
          dispatch(actions.completeContractorFail());
          throw new SubmissionError({
            email: error.message,
          });
        }

        dispatch(actions.completeContractorFail(error));
      });
  },

  completeContractor: (
    values: ICompleteContractorFormData,
    contractor: IUserEntity
  ) => (dispatch: Dispatch<Actions | usersActions>) => {
    dispatch(actions.completeContractorRequest());

    // TODO: Sistemare date con formatter e parser di reduxForm
    const birthday = moment(values.birthday, "DD/MM/YYYY").format("YYYY-MM-DD");
    const birthplace = values.birthplaceMore || values.birthplace;

    return usersService
      .update(contractor.id, {
        ...values,
        birthday,
        birthplace,
      })
      .then((updatedUser) => {
        const {
          result,
          entities: {users},
        } = normalizeUser(updatedUser);
        batch(() => {
          dispatch(usersActions.mergeItems(users));

          dispatch(actions.completeContractorSuccess(result));
        });
      })
      .catch((error: IApiError) => {
        dispatch(actions.completeContractorFail(error));
      });
  },
  completeContractorFail: (
    error?: IApiError
  ): ICompleteContractorFailAction => ({
    payload: error,
    type: ActionTypes.COMPLETE_CONTRACTOR_FAIL,
  }),
  completeContractorRequest: (): ICompleteContractorRequestAction => ({
    type: ActionTypes.COMPLETE_CONTRACTOR_REQUEST,
  }),
  completeContractorSuccess: (
    contractorId: string
  ): ICompleteContractorSuccessAction => ({
    payload: {contractorId},
    type: ActionTypes.COMPLETE_CONTRACTOR_SUCCESS,
  }),

  read: (id: string) => (dispatch: Dispatch<Actions | usersActions>) => {
    dispatch(actions.readRequest());
    return service
      .read(id)
      .then((readCap) => {
        const {
          result,
          entities: {caps, users},
        } = normalizeCap(readCap);
        batch(() => {
          dispatch(usersActions.mergeItems(users));
          dispatch(actions.mergeItems(caps));

          dispatch(actions.readSuccess(result));
        });
      })
      .catch((error) => {
        dispatch(actions.readFail(error));
      });
  },
  readFail: (error: IApiError): IReadFailAction => ({
    payload: error,
    type: ActionTypes.READ_FAIL,
  }),
  readRequest: (): IReadRequestAction => ({
    type: ActionTypes.READ_REQUEST,
  }),
  readSuccess: (capId: string): IReadSuccessAction => {
    return {
      payload: {capId},
      type: ActionTypes.READ_SUCCESS,
    };
  },

  readResetUiError: (): IReadResetUiErrorAction => {
    return {
      type: ActionTypes.READ_RESET_UI_ERROR,
    };
  },

  uploadId: (userId: string, cap: ICapEntity, values: IUploadIdFormData) => (
    dispatch: Dispatch<Actions | usersActions>
  ) => {
    dispatch(actions.uploadIdRequest());
    return usersService
      .uploadId(userId, values)
      .then(async (updatedUser) => {
        // Documento caricato, ora resetto l'onboarding del cap
        return service
          .update(cap.id, {jsonOnboarding: ""})
          .then((updatedCap) => {
            const {
              entities: {caps},
            } = normalizeCap(updatedCap);
            const {
              result,
              entities: {users},
            } = normalizeUser(updatedUser);
            batch(() => {
              dispatch(actions.mergeItems(caps));
              dispatch(usersActions.mergeItems(users));
              dispatch(actions.uploadIdSuccess(result));
            });
          })
          .catch((error) => {
            dispatch(actions.uploadIdFail(error));
          });
      })
      .catch((error) => {
        dispatch(actions.uploadIdFail(error));
      });
  },
  uploadIdFail: (error: IApiError): IUploadIdFailAction => ({
    payload: {error},
    type: ActionTypes.UPLOAD_ID_FAIL,
  }),
  uploadIdRequest: (): IUploadIdRequestAction => ({
    type: ActionTypes.UPLOAD_ID_REQUEST,
  }),
  uploadIdSuccess: (userId: string): IUploadIdSuccessAction => {
    return {
      payload: {userId},
      type: ActionTypes.UPLOAD_ID_SUCCESS,
    };
  },

  confirmIdentification: (cap: ICapEntity, user: IUserEntity) => (
    dispatch: Dispatch<Actions | usersActions>
  ) => {
    dispatch(actions.confirmIdentificationRequest());
    if (!user.jsonIdentificationHistory) {
      throw errors[ErrorCodes.JSON_ID_UNDEFINED];
    }
    if (!user.jsonPrivacy) {
      throw errors[ErrorCodes.JSON_PRIVACY_UNDEFINED];
    }

    // Creo il json per l'overboarding che contiene solo identification e privacy.
    const identificationHistory = JSON.parse(
      user.jsonIdentificationHistory
    ) as IIdentificationHistory;
    const privacy = JSON.parse(user.jsonPrivacy) as IPrivacySnapshot;
    const onboarding: IOnboardingSnapshot = {
      identification: identificationHistory[0],
      privacy,
    };
    const jsonOnboarding = JSON.stringify(onboarding);

    return service
      .update(cap.id, {jsonOnboarding})
      .then((updatedCap) => {
        const {
          result: capId,
          entities: {caps},
        } = normalizeCap(updatedCap);
        batch(() => {
          dispatch(actions.mergeItems(caps));

          dispatch(actions.confirmIdentificationSuccess(capId));
        });
      })
      .catch((error) => {
        dispatch(actions.confirmIdentificationFail(error));
      });
  },
  confirmIdentificationFail: (
    error: IApiError
  ): IConfirmIdentificationFailAction => ({
    payload: {error},
    type: ActionTypes.CONFIRM_IDENTIFICATION_FAIL,
  }),
  confirmIdentificationRequest: (): IConfirmIdentificationRequestAction => ({
    type: ActionTypes.CONFIRM_IDENTIFICATION_REQUEST,
  }),
  confirmIdentificationSuccess: (
    capId: string
  ): IConfirmIdentificationSuccessAction => {
    return {
      payload: {capId},
      type: ActionTypes.CONFIRM_IDENTIFICATION_SUCCESS,
    };
  },

  home: (values: IHomeFormData, cap: ICapEntity) => (
    dispatch: Dispatch<Actions | usersActions>
  ) => {
    dispatch(actions.homeRequest());

    // const newCap: ICapEntity = JSON.parse(JSON.stringify(cap));
    const data = JSON.parse(cap.jsonData) as IDataSnapshot;
    data.home = {...values, region: values.cityCSiglaProvincia};
    const jsonData = JSON.stringify(data);

    return actions
      .update(cap.id, {jsonData})(dispatch)
      .then(() => {
        dispatch(actions.homeSuccess());
      })
      .catch((error) => {
        dispatch(actions.homeFail(error));
      });
  },
  homeFail: (error: IApiError): IHomeFailAction => ({
    payload: {error},
    type: ActionTypes.HOME_FAIL,
  }),
  homeRequest: (): IHomeRequestAction => ({
    type: ActionTypes.HOME_REQUEST,
  }),
  homeSuccess: (): IHomeSuccessAction => {
    return {
      type: ActionTypes.HOME_SUCCESS,
    };
  },

  employment: (values: IEmploymentFormData, cap: ICapEntity) => (
    dispatch: Dispatch<Actions | usersActions>
  ) => {
    dispatch(actions.employmentRequest());
    const newCap: ICapEntity = JSON.parse(JSON.stringify(cap));
    const data = JSON.parse(newCap.jsonData) as IDataSnapshot;

    const computedValues = {} as IEmploymentFormData;
    computedValues.currentEmployment = computeNetSalary(
      values.currentEmployment
    );
    if (values.prevEmployments) {
      computedValues.prevEmployments = values.prevEmployments.map(
        (prevJob: IEmploymentFormDataSingle) => computeNetSalary(prevJob)
      );
    }

    data.employments = computedValues;
    newCap.jsonData = JSON.stringify(data);

    return actions
      .update(
        newCap.id,
        newCap
      )(dispatch)
      .then(() => {
        dispatch(actions.employmentSuccess());
      })
      .catch((error) => {
        dispatch(actions.employmentFail(error));
      });
  },
  employmentFail: (error: IApiError): IEmploymentFailAction => ({
    payload: {error},
    type: ActionTypes.EMPLOYMENT_FAIL,
  }),
  employmentRequest: (): IEmploymentRequestAction => ({
    type: ActionTypes.EMPLOYMENT_REQUEST,
  }),
  employmentSuccess: (): IEmploymentSuccessAction => {
    return {
      type: ActionTypes.EMPLOYMENT_SUCCESS,
    };
  },

  incomes: (values: IIncomesFormData, cap: ICapEntity) => (
    dispatch: Dispatch<Actions | usersActions>
  ) => {
    dispatch(actions.incomesRequest());
    const newCap: ICapEntity = JSON.parse(JSON.stringify(cap));
    const data = JSON.parse(newCap.jsonData) as IDataSnapshot;
    const newIncomes = Object.keys(values).length > 0 ? values : {incomes: []};
    data.incomes = newIncomes;
    newCap.jsonData = JSON.stringify(data);

    return actions
      .update(
        newCap.id,
        newCap
      )(dispatch)
      .then(() => {
        dispatch(actions.incomesSuccess());
      })
      .catch((error) => {
        dispatch(actions.incomesFail(error));
      });
  },
  incomesFail: (error: IApiError): IIncomesFailAction => ({
    payload: {error},
    type: ActionTypes.INCOMES_FAIL,
  }),
  incomesRequest: (): IIncomesRequestAction => ({
    type: ActionTypes.INCOMES_REQUEST,
  }),
  incomesSuccess: (): IIncomesSuccessAction => {
    return {
      type: ActionTypes.INCOMES_SUCCESS,
    };
  },

  target: (
    values: ITargetFormData,
    domandeObiettivi: Array<{
      inputId: string;
      inputName: TargetsQuestionsKeys;
      question: string;
      weight: number[];
    }>,
    cap: ICapEntity
  ) => (dispatch: Dispatch<Actions | usersActions>) => {
    dispatch(actions.targetRequest());
    const newCap: ICapEntity = JSON.parse(JSON.stringify(cap));
    const data = JSON.parse(newCap.jsonData) as IDataSnapshot;
    const weights: {[key in TargetsQuestionsKeys]: number[]} = {} as {
      [key in TargetsQuestionsKeys]: number[];
    };
    domandeObiettivi.forEach((domanda) => {
      weights[domanda.inputName] = domanda.weight;
    });

    data.targets = values;
    data.targetsWeights = weights;
    newCap.jsonData = JSON.stringify(data);

    return actions
      .update(
        newCap.id,
        newCap
      )(dispatch)
      .then(() => {
        dispatch(actions.targetSuccess());
      })
      .catch((error) => {
        dispatch(actions.targetFail(error));
      });
  },
  targetFail: (error: IApiError): ITargetFailAction => ({
    payload: {error},
    type: ActionTypes.TARGET_FAIL,
  }),
  targetRequest: (): ITargetRequestAction => ({
    type: ActionTypes.TARGET_REQUEST,
  }),
  targetSuccess: (): ITargetSuccessAction => {
    return {
      type: ActionTypes.TARGET_SUCCESS,
    };
  },

  saving: (values: ISavingFormData, cap: ICapEntity) => (
    dispatch: Dispatch<Actions | usersActions>
  ) => {
    dispatch(actions.savingRequest());
    const newCap: ICapEntity = JSON.parse(JSON.stringify(cap));
    const data = JSON.parse(newCap.jsonData) as IDataSnapshot;
    data.saving = values;
    newCap.jsonData = JSON.stringify(data);

    return actions
      .update(
        newCap.id,
        newCap
      )(dispatch)
      .then(() => {
        dispatch(actions.savingSuccess());
      })
      .catch((error) => {
        dispatch(actions.savingFail(error));
      });
  },
  savingFail: (error: IApiError): ISavingFailAction => ({
    payload: {error},
    type: ActionTypes.SAVING_FAIL,
  }),
  savingRequest: (): ISavingRequestAction => ({
    type: ActionTypes.SAVING_REQUEST,
  }),
  savingSuccess: (): ISavingSuccessAction => {
    return {
      type: ActionTypes.SAVING_SUCCESS,
    };
  },

  overdraft: (
    values: IOverdraftsSectionFormData,
    cap: ICapEntity,
    empty: boolean = false
  ) => (dispatch: Dispatch<Actions | usersActions>) => {
    dispatch(actions.overdraftRequest());
    const newCap: ICapEntity = JSON.parse(JSON.stringify(cap));
    const data = JSON.parse(newCap.jsonData) as IDataSnapshot;

    if (!data.overdrafts) {
      data.overdrafts = {};
    }
    if (values) {
      if (empty) {
        delete data.overdrafts[values.sectionName];
      } else {
        data.overdrafts[values.sectionName] = values;
      }

      data.overdrafts.oneComplete = [
        "beniCasaAnimali",
        "tenoreVita",
        "infortuniMalattiaPrevenzione",
      ].reduce(
        (accumulator, currentValue) =>
          accumulator || !!data.overdrafts?.[currentValue],
        false as boolean
      );
    }

    newCap.jsonData = JSON.stringify(data);

    return actions
      .update(
        newCap.id,
        newCap
      )(dispatch)
      .then(() => {
        dispatch(actions.overdraftSuccess());
      })
      .catch((error) => {
        dispatch(actions.overdraftFail(error));
      });
  },
  overdraftFail: (error: IApiError): IOverdraftFailAction => ({
    payload: {error},
    type: ActionTypes.OVERDRAFT_FAIL,
  }),
  overdraftRequest: (): IOverdraftRequestAction => ({
    type: ActionTypes.OVERDRAFT_REQUEST,
  }),
  overdraftSuccess: (): IOverdraftSuccessAction => {
    return {
      type: ActionTypes.OVERDRAFT_SUCCESS,
    };
  },

  hasIBIPs: (values: IHasIBIPsFormData | undefined, cap: ICapEntity) => (
    dispatch: Dispatch<Actions | usersActions>
  ) => {
    dispatch(actions.hasIBIPsRequest());
    const newCap: ICapEntity = JSON.parse(JSON.stringify(cap));
    const data = JSON.parse(newCap.jsonData) as IDataSnapshot;

    data.hasIBIPs = values;

    newCap.jsonData = JSON.stringify(data);

    return actions
      .update(
        newCap.id,
        newCap
      )(dispatch)
      .then(() => {
        dispatch(actions.hasIBIPsSuccess());
        dispatch(
          change(
            "overdraft-risparmioFuturo",
            "prodotti-assicurativi.checked",
            !!values && values.IBIPs.length > 0
          )
        );
      })
      .catch((error) => {
        console.error(error); // tslint:disable-line
        dispatch(actions.hasIBIPsFail(error));
      });
  },
  hasIBIPsFail: (error: IApiError): IHasIBIPsFailAction => ({
    payload: {error},
    type: ActionTypes.HAS_IBIPS_FAIL,
  }),
  hasIBIPsRequest: (): IHasIBIPsRequestAction => ({
    type: ActionTypes.HAS_IBIPS_REQUEST,
  }),
  hasIBIPsSuccess: (): IHasIBIPsSuccessAction => {
    return {
      type: ActionTypes.HAS_IBIPS_SUCCESS,
    };
  },

  idd: (values: IIddFormData, cap: ICapEntity) => (
    dispatch: Dispatch<Actions | usersActions>
  ) => {
    dispatch(actions.iddRequest());

    const idd = computeIdd(values);

    return service
      .setIdd(cap.id, idd, values, domandeIdd)
      .then((updatedCap) => {
        const {
          entities: {caps, users},
        } = normalizeCap(updatedCap);
        batch(() => {
          dispatch(usersActions.mergeItems(users));
          dispatch(actions.mergeItems(caps));

          dispatch(actions.iddSuccess());
        });
      })
      .catch((error) => {
        dispatch(actions.iddFail(error));
      });
  },
  iddFail: (error: IApiError): IIddFailAction => ({
    payload: {error},
    type: ActionTypes.IDD_FAIL,
  }),
  iddRequest: (): IIddRequestAction => ({
    type: ActionTypes.IDD_REQUEST,
  }),
  iddSuccess: (): IIddSuccessAction => {
    return {
      type: ActionTypes.IDD_SUCCESS,
    };
  },

  injury: (values: {evaluated: boolean}, cap: ICapEntity) => (
    dispatch: Dispatch<Actions | usersActions>
  ) => {
    const newCap: ICapEntity = JSON.parse(JSON.stringify(cap));
    const data = JSON.parse(newCap.jsonData) as IDataSnapshot;
    data.injury = values;
    newCap.jsonData = JSON.stringify(data);

    actions.update(newCap.id, newCap)(dispatch);
    dispatch({
      payload: values,
      type: ActionTypes.INJURY,
    } as IInjuryAction);
  },
  morte: (values: {evaluated: boolean}, cap: ICapEntity) => (
    dispatch: Dispatch<Actions | usersActions>
  ) => {
    const newCap: ICapEntity = JSON.parse(JSON.stringify(cap));
    const data = JSON.parse(newCap.jsonData) as IDataSnapshot;
    data.morte = values;
    newCap.jsonData = JSON.stringify(data);

    actions.update(newCap.id, newCap)(dispatch);
    dispatch({
      payload: values,
      type: ActionTypes.MORTE,
    } as IMorteAction);
  },
  retirement: (values: {evaluated: boolean}, cap: ICapEntity) => (
    dispatch: Dispatch<Actions | usersActions>
  ) => {
    const newCap: ICapEntity = JSON.parse(JSON.stringify(cap));
    const data = JSON.parse(newCap.jsonData) as IDataSnapshot;
    data.retirement = values;
    newCap.jsonData = JSON.stringify(data);

    actions.update(newCap.id, newCap)(dispatch);
    dispatch({
      payload: values,
      type: ActionTypes.RETIREMENT,
    } as IRetirementAction);
  },
  tutela: (values: {evaluated: boolean}, cap: ICapEntity) => (
    dispatch: Dispatch<Actions | usersActions>
  ) => {
    const newCap: ICapEntity = JSON.parse(JSON.stringify(cap));
    const data = JSON.parse(newCap.jsonData) as IDataSnapshot;
    data.tutela = values;
    newCap.jsonData = JSON.stringify(data);

    actions.update(newCap.id, newCap)(dispatch);
    dispatch({
      payload: values,
      type: ActionTypes.TUTELA,
    } as ITutelaAction);
  },

  closeDen: (cap: ICapEntity, email?: string) => (
    dispatch: Dispatch<Actions | usersActions>
  ) => {
    dispatch(actions.closeDenRequest());

    return service
      .closeDen(cap.id, email)
      .then((closedCap) => {
        const {
          entities: {caps, users},
        } = normalizeCap(closedCap);
        batch(() => {
          dispatch(usersActions.mergeItems(users));
          dispatch(actions.mergeItems(caps));

          dispatch(actions.closeDenSuccess());
        });
      })
      .catch((error) => {
        dispatch(actions.closeDenFail(error));
      });
  },
  closeDenFail: (error: IApiError): ICloseDenFailAction => ({
    payload: {error},
    type: ActionTypes.CLOSE_DEN_FAIL,
  }),
  closeDenRequest: (): ICloseDenRequestAction => ({
    type: ActionTypes.CLOSE_DEN_REQUEST,
  }),
  closeDenSuccess: (): ICloseDenSuccessAction => {
    return {
      type: ActionTypes.CLOSE_DEN_SUCCESS,
    };
  },

  closeOverdrafts: (cap: ICapEntity, email?: string) => (
    dispatch: Dispatch<Actions | usersActions>,
    getState: () => IRootState
  ) => {
    dispatch(actions.closeOverdraftsRequest());
    const newCap: ICapEntity = JSON.parse(JSON.stringify(cap));
    const data = JSON.parse(newCap.jsonData) as IDataSnapshot;

    ["beniCasaAnimali", "tenoreVita", "infortuniMalattiaPrevenzione"].forEach(
      (name) => {
        const values = getState().form[`overdraft-${name}`]
          ?.values as IOverdraftsSectionFormData;

        if (!data.overdrafts) {
          data.overdrafts = {};
        }
        if (values) {
          data.overdrafts.oneComplete = true;
          data.overdrafts[values.sectionName] = values;
        }
      }
    );

    newCap.jsonData = JSON.stringify(data);

    // Aggiorno cap e poi lo chiudo
    return actions
      .update(
        newCap.id,
        newCap
      )(dispatch)
      .then(() => {
        return service.closeOverdrafts(newCap.id, email).then((closedCap) => {
          const {
            entities: {caps, users},
          } = normalizeCap(closedCap);
          batch(() => {
            dispatch(usersActions.mergeItems(users));
            dispatch(actions.mergeItems(caps));

            dispatch(actions.closeOverdraftsSuccess());
          });
        });
      })
      .catch((error) => {
        dispatch(actions.closeOverdraftsFail(error));
      });
  },
  closeOverdraftsFail: (error: IApiError): ICloseOverdraftsFailAction => ({
    payload: {error},
    type: ActionTypes.CLOSE_OVERDRAFTS_FAIL,
  }),
  closeOverdraftsRequest: (): ICloseOverdraftsRequestAction => ({
    type: ActionTypes.CLOSE_OVERDRAFTS_REQUEST,
  }),
  closeOverdraftsSuccess: (): ICloseOverdraftsSuccessAction => {
    return {
      type: ActionTypes.CLOSE_OVERDRAFTS_SUCCESS,
    };
  },

  requestValidation: (capId: string, email?: string) => (
    dispatch: Dispatch<Actions | usersActions>
  ) => {
    dispatch(actions.requestValidationRequest());

    return service
      .requestValidation(capId, email)
      .then((validatedCap) => {
        const {
          entities: {caps, users},
        } = normalizeCap(validatedCap);
        batch(() => {
          dispatch(usersActions.mergeItems(users));
          dispatch(actions.mergeItems(caps));

          dispatch(actions.requestValidationSuccess());
        });
      })
      .catch((error) => {
        dispatch(actions.requestValidationFail(error));
      });
  },
  requestValidationFail: (error: IApiError): IRequestValidationFailAction => ({
    payload: {error},
    type: ActionTypes.REQUEST_VALIDATION_FAIL,
  }),
  requestValidationRequest: (): IRequestValidationRequestAction => ({
    type: ActionTypes.REQUEST_VALIDATION_REQUEST,
  }),
  requestValidationSuccess: (): IRequestValidationSuccessAction => {
    return {
      type: ActionTypes.REQUEST_VALIDATION_SUCCESS,
    };
  },

  closeValidation: (capId: string, responseValidation: number) => (
    dispatch: Dispatch<Actions | usersActions>
  ) => {
    dispatch(actions.closeValidationRequest());

    return service
      .closeValidation(capId, responseValidation)
      .then((validatedCap) => {
        const {
          entities: {caps, users},
        } = normalizeCap(validatedCap);
        batch(() => {
          dispatch(usersActions.mergeItems(users));
          dispatch(actions.mergeItems(caps));

          dispatch(actions.closeValidationSuccess());
        });
      })
      .catch((error) => {
        dispatch(actions.closeValidationFail(error));
      });
  },

  closeValidationFail: (error: IApiError): ICloseValidationFailAction => ({
    payload: {error},
    type: ActionTypes.CLOSE_VALIDATION_FAIL,
  }),
  closeValidationRequest: (): ICloseValidationRequestAction => ({
    type: ActionTypes.CLOSE_VALIDATION_REQUEST,
  }),
  closeValidationSuccess: (): ICloseValidationSuccessAction => {
    return {
      type: ActionTypes.CLOSE_VALIDATION_SUCCESS,
    };
  },

  // Gestiamo l'invio della mail per l'idd
  iddSend: (cap: ICapEntity) => (dispatch: Dispatch<Actions>) => {
    dispatch(actions.iddSendRequest());
    return service
      .iddSend(cap.id)
      .then(() => {
        dispatch(actions.iddSendSuccess());
      })
      .catch((error) => {
        dispatch(actions.iddSendFail(error));
      });
  },
  iddSendFail: (error: IApiError): IIddSendFailAction => ({
    payload: {error},
    type: ActionTypes.IDD_SEND_FAIL,
  }),
  iddSendRequest: (): IIddSendRequestAction => ({
    type: ActionTypes.IDD_SEND_REQUEST,
  }),
  iddSendSuccess: (): IIddSendSuccessAction => ({
    type: ActionTypes.IDD_SEND_SUCCESS,
  }),
  resetIsIddSendSuccess: (): IResetIsIddSendSuccessAction => ({
    type: ActionTypes.RESET_IS_IDD_SEND_SUCCESS,
  }),
  toggleIddSendModal: (contractorId: string): IToggleIddSendModalAction => {
    return {
      payload: {contractorId},
      type: ActionTypes.TOGGLE_IDD_SEND_MODAL,
    };
  },

  delivery: (values: IDeliveryFormData, capId: string) => (
    dispatch: Dispatch<Actions | usersActions>,
    getState: () => IRootState
  ) => {
    dispatch(actions.deliveryRequest());
    const cap = getState().caps.entities[capId];

    const newCap: ICapEntity = JSON.parse(JSON.stringify(cap));
    const delivery: IDeliverySnapshot = newCap.jsonDelivery
      ? JSON.parse(newCap.jsonDelivery)
      : {};
    delivery.values = values;
    newCap.jsonDelivery = JSON.stringify(delivery);

    return actions
      .update(
        newCap.id,
        newCap
      )(dispatch)
      .then(() => {
        dispatch(actions.deliverySuccess());
      })
      .catch((error) => {
        dispatch(actions.deliveryFail(error));
      });
  },
  deliveryFail: (error: IApiError): IDeliveryFailAction => ({
    payload: {error},
    type: ActionTypes.DELIVERY_FAIL,
  }),
  deliveryRequest: (): IDeliveryRequestAction => ({
    type: ActionTypes.DELIVERY_REQUEST,
  }),
  deliverySuccess: (): IDeliverySuccessAction => ({
    type: ActionTypes.DELIVERY_SUCCESS,
  }),

  identificationNotification: (capId: string) => (
    dispatch: Dispatch<Actions | usersActions>
  ) => {
    dispatch(actions.identificationNotificationRequest());
    return service
      .identificationNotification(capId)
      .then(() => {
        dispatch(actions.identificationNotificationSuccess());
      })
      .catch((error) => {
        dispatch(actions.identificationNotificationFail(error));
      });
  },
  identificationNotificationFail: (
    error: IApiError
  ): IIdentificationNotificationFailAction => ({
    payload: {error},
    type: ActionTypes.IDENTIFICATION_NOTIFICATION_FAIL,
  }),
  identificationNotificationRequest: (): IIdentificationNotificationRequestAction => ({
    type: ActionTypes.IDENTIFICATION_NOTIFICATION_REQUEST,
  }),
  identificationNotificationSuccess: (): IIdentificationNotificationSuccessAction => ({
    type: ActionTypes.IDENTIFICATION_NOTIFICATION_SUCCESS,
  }),

  // Serve ancora
  viewFromDb: (capId: string) => (
    dispatch: Dispatch<Actions | usersActions>
  ) => {
    dispatch(actions.viewFromDbRequest());
    return service
      .read(capId)
      .then((readCap) => {
        const {
          result,
          entities: {caps, users},
        } = normalizeCap(readCap);
        batch(() => {
          dispatch(usersActions.mergeItems(users));
          dispatch(actions.mergeItems(caps));

          dispatch(actions.viewFromDbSuccess(result));
        });
      })
      .catch((error) => {
        dispatch(actions.viewFromDbFail(error));
      });
  },
  viewFromDbFail: (error: IApiError): IViewFromDbFailAction => ({
    payload: error,
    type: ActionTypes.VIEW_FROM_DB_FAIL,
  }),
  viewFromDbRequest: (): IViewFromDbRequestAction => ({
    type: ActionTypes.VIEW_FROM_DB_REQUEST,
  }),
  viewFromDbSuccess: (capId: string): IViewFromDbSuccessAction => {
    return {
      payload: {capId},
      type: ActionTypes.VIEW_FROM_DB_SUCCESS,
    };
  },

  viewFromDbResetUiError: (): IViewFromDbResetUiErrorAction => {
    return {
      type: ActionTypes.VIEW_FROM_DB_RESET_UI_ERROR,
    };
  },

  loadCopy: (id: string) => (dispatch: Dispatch<Actions>) => {
    dispatch(actions.loadCopyRequest());
    return service
      .loadCopy(id)
      .then((copyData) => {
        dispatch(actions.loadCopySuccess(copyData));
      })
      .catch((error) => {
        dispatch(actions.loadCopyFail(error));
      });
  },
  loadCopyFail: (error: IApiError): ILoadCopyFailAction => ({
    payload: {error},
    type: ActionTypes.LOAD_COPY_FAIL,
  }),
  loadCopyRequest: (): ILoadCopyRequestAction => ({
    type: ActionTypes.LOAD_COPY_REQUEST,
  }),
  loadCopyReset: (): ILoadCopyResetAction => {
    return {
      type: ActionTypes.LOAD_COPY_RESET,
    };
  },
  loadCopySuccess: (copyData: ICopyData): ILoadCopySuccessAction => {
    return {
      payload: {copyData},
      type: ActionTypes.LOAD_COPY_SUCCESS,
    };
  },

  copyDataInCap: (copyData: Partial<IDataSnapshot>, cap: ICapEntity) => (
    dispatch: Dispatch<Actions | usersActions>
  ) => {
    dispatch(actions.copyDataInCapRequest());

    const newCap: ICapEntity = JSON.parse(JSON.stringify(cap));
    const data = JSON.parse(newCap.jsonData) as IDataSnapshot;
    newCap.jsonData = JSON.stringify({...data, ...copyData});

    return actions
      .update(
        newCap.id,
        newCap
      )(dispatch)
      .then(() => {
        dispatch(actions.copyDataInCapSuccess());
      })
      .catch((error) => {
        dispatch(actions.copyDataInCapFail(error));
      });
  },
  copyDataInCapFail: (error: IApiError): ICopyDataInCapFailAction => ({
    payload: {error},
    type: ActionTypes.COPY_DATA_IN_CAP_FAIL,
  }),
  copyDataInCapRequest: (): ICopyDataInCapRequestAction => ({
    type: ActionTypes.COPY_DATA_IN_CAP_REQUEST,
  }),
  copyDataInCapSuccess: (): ICopyDataInCapSuccessAction => {
    return {
      type: ActionTypes.COPY_DATA_IN_CAP_SUCCESS,
    };
  },

  loadCities: (options?: {exist?: boolean; name?: string; limit?: number}) => (
    dispatch: Dispatch<Actions>
  ) => {
    dispatch(actions.loadCitiesRequest());
    return service
      .loadCities(options)
      .then((cities) => {
        const orderedCities = matchSorter(cities, options?.name ?? "", {
          keys: ["cNomeComune"],
        });
        dispatch(actions.loadCitiesSuccess(orderedCities, options));
      })
      .catch((error) => {
        dispatch(actions.loadCitiesFail(error));
      });
  },
  loadCitiesFail: (error: IApiError): ILoadCitiesFailAction => ({
    payload: {error},
    type: ActionTypes.LOAD_CITIES_FAIL,
  }),
  loadCitiesRequest: (): ILoadCitiesRequestAction => ({
    type: ActionTypes.LOAD_CITIES_REQUEST,
  }),
  loadCitiesSuccess: (
    cities: ICity[],
    options?: {exist?: boolean; name?: string; limit?: number}
  ): ILoadCitiesSuccessAction => {
    return {
      payload: {cities},
      type: ActionTypes.LOAD_CITIES_SUCCESS,
      meta: {options: options ?? {}},
    };
  },
};

export type Actions =
  | IMergeItemsAction
  | IOpenNewCapModal
  | ICloseNewCapModal
  | IListRequestAction
  | IListSuccessAction
  | IListFailAction
  | ICreateRequestAction
  | ICreateSuccessAction
  | ICreateFailAction
  | IUpdateRequestAction
  | IUpdateSuccessAction
  | IUpdateFailAction
  | IDeleteRequestAction
  | IDeleteSuccessAction
  | IDeleteFailAction
  | IResetDeleteSuccess
  | IUndoDeleteRequestAction
  | IUndoDeleteSuccessAction
  | IUndoDeleteFailAction
  | ICreateContractorRequestAction
  | ICreateContractorSuccessAction
  | ICreateContractorFailAction
  | ICreateContractorResetAction
  | ICreateContractorAnotherAdvisorAction
  | IUseExistingContractorRequestAction
  | IUseExistingContractorSuccessAction
  | IUseExistingContractorFailAction
  | ISaveContractorIdCreateErrorAction
  | IResetCreateUseExistingContractor
  | IResetLastCreatedId
  | IResetReadRedirect
  | ICompleteContractorRequestAction
  | ICompleteContractorSuccessAction
  | ICompleteContractorFailAction
  | IReadRequestAction
  | IReadSuccessAction
  | IReadFailAction
  | IReadResetUiErrorAction
  | IUploadIdRequestAction
  | IUploadIdSuccessAction
  | IUploadIdFailAction
  | IConfirmIdentificationRequestAction
  | IConfirmIdentificationSuccessAction
  | IConfirmIdentificationFailAction
  | IHomeRequestAction
  | IHomeSuccessAction
  | IHomeFailAction
  | IEmploymentRequestAction
  | IEmploymentSuccessAction
  | IEmploymentFailAction
  | IIncomesRequestAction
  | IIncomesSuccessAction
  | IIncomesFailAction
  | ITargetRequestAction
  | ITargetSuccessAction
  | ITargetFailAction
  | ISavingRequestAction
  | ISavingSuccessAction
  | ISavingFailAction
  | IOverdraftRequestAction
  | IOverdraftSuccessAction
  | IOverdraftFailAction
  | IHasIBIPsFailAction
  | IHasIBIPsRequestAction
  | IHasIBIPsSuccessAction
  | IIddRequestAction
  | IIddSuccessAction
  | IIddFailAction
  | IIddFromViewAction
  | IInjuryAction
  | IRetirementAction
  | IMorteAction
  | ITutelaAction
  | IToggleIddSendModalAction
  | ICloseDenFailAction
  | ICloseDenRequestAction
  | ICloseDenSuccessAction
  | ICloseOverdraftsFailAction
  | ICloseOverdraftsRequestAction
  | ICloseOverdraftsSuccessAction
  | IRequestValidationFailAction
  | IRequestValidationRequestAction
  | IRequestValidationSuccessAction
  | ICloseValidationFailAction
  | ICloseValidationRequestAction
  | ICloseValidationSuccessAction
  | IIddSendFailAction
  | IIddSendRequestAction
  | IIddSendSuccessAction
  | IDeliveryRequestAction
  | IDeliverySuccessAction
  | IDeliveryFailAction
  | IIdentificationNotificationRequestAction
  | IIdentificationNotificationSuccessAction
  | IIdentificationNotificationFailAction
  | IResetIsIddSendSuccessAction
  | IViewFromDbFailAction
  | IViewFromDbRequestAction
  | IViewFromDbSuccessAction
  | IViewFromDbResetUiErrorAction
  | ILoadCopyFailAction
  | ILoadCopyRequestAction
  | ILoadCopySuccessAction
  | ILoadCopyResetAction
  | ICopyDataInCapRequestAction
  | ICopyDataInCapSuccessAction
  | ICopyDataInCapFailAction
  | ILoadCitiesFailAction
  | ILoadCitiesRequestAction
  | ILoadCitiesSuccessAction;
