import moment from "moment";
import React from "react";
import {Link} from "react-router-dom";
import {Button, Card, Table} from "reactstrap";
import {ICap} from "../Caps";
import {IconEye, IconWelfare} from "../Icons";

interface IProps {
  canSeeCap: boolean;
  cap: ICap;
  isAdvisor: boolean;
}

const CapDetails: React.FC<IProps> = ({canSeeCap, cap, isAdvisor}) => {
  return (
    <Card body color="drawer auto-margin-3">
      <h3>
        <IconWelfare title="Risultati questionari di adeguatezza IBIPs" />{" "}
        Analisi di riferimento
      </h3>
      <Table responsive>
        <thead>
          <tr>
            <th>Stato questionario</th>
            <th>Data</th>
            <th>Eseguito da</th>
            {canSeeCap && <th style={{width: "115px"}}>Azioni</th>}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Valido</td>
            <td>{moment(cap.dateIdd!).format("LL")}</td>
            <td>
              {cap.userIdd
                ? `${cap.userIdd.name} ${cap.userIdd.surname}`
                : "Assente"}
            </td>
            {canSeeCap && (
              <td>
                <Button
                  tag={Link}
                  size="sm"
                  color="primary"
                  outline
                  to={`/analisi/${isAdvisor ? "new" : "view"}/${cap.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <IconEye title="Dettagli" /> Dettagli
                </Button>
              </td>
            )}
          </tr>
        </tbody>
      </Table>
    </Card>
  );
};

export default CapDetails;
