import {faCheck, faInfoCircle, faTimes} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useState} from "react";
import {useFormContext} from "react-hook-form";
import {Button, Card, CardBody, CardFooter, Col, Row, Table} from "reactstrap";
import {IBeneLevel} from "../NewBeneProposalForm";
import {GuaranteeModal} from "./GuaranteeModal";

interface IProps {
  disabled?: boolean;
}

const featuresTable = {
  silver: {
    price: 10,
    morte: true,
    ip: true,
    serenita: true,
    rimborso: false,
    assistenza: false,
    legale: false,
    infortunio: false,
    malattia: false,
  },
  gold: {
    price: 15,
    morte: true,
    ip: true,
    serenita: true,
    rimborso: true,
    assistenza: true,
    legale: true,
    infortunio: false,
    malattia: false,
  },
  platinum: {
    price: 25,
    morte: true,
    ip: true,
    serenita: true,
    rimborso: true,
    assistenza: true,
    legale: true,
    infortunio: true,
    malattia: true,
  },
};

const Injuries = ({disabled}: IProps) => {
  const [guaranteeModalLevel, setGuaranteeModalLevel] = useState<
    IBeneLevel | undefined
  >(undefined);
  const {register} = useFormContext();

  return (
    <>
      <div>
        <h4>Polizza Bene Infortuni</h4>
        <h5>Soggetti assicurabili</h5>
        <ul>
          <li>
            Contraente e componenti del suo nucleo familiare (stabilmente
            conviventi con il Contraente)
          </li>
          <li>residenti e domiciliati in Italia (codice fiscale ITA)</li>
          <li>età non superiore a 70 anni (75 in uscita)</li>
          <li>in buono stato di salute</li>
          <li>purchè dell'elenco attività svolte (Classe unica)</li>
        </ul>
        <h5>Soggetti non assicurabili</h5>
        <ul>
          <li>
            persone che sono o sono state affette da alcolismo,
            tossicodipendenza, HIV e sindrome da immunodeficienza acquisita
            (AIDS)
          </li>
          <li>
            persone che percepiscono una pensione di invalidità permanente
            riconosciuta dall’INPS o da altro ente previdenziale pubblico.
          </li>
        </ul>
      </div>
      <Row className="mb-3">
        {Object.keys(featuresTable).map((type, index) => {
          const features =
            featuresTable[type as "silver" | "gold" | "platinum"];
          return (
            <Col
              lg
              key={type}
              className={index > 0 ? "mt-2 mt-lg-0" : undefined}
            >
              <label className="radio-card">
                <input
                  type="radio"
                  name="level"
                  value={type}
                  ref={register}
                  disabled={disabled}
                />
                <Card className={type}>
                  <CardBody>
                    <Table
                      borderless
                      style={{fontSize: "0.9em"}}
                      size="sm"
                      className="mb-0"
                    >
                      <thead>
                        <tr>
                          <th colSpan={2}>
                            <h5>
                              <span className="text-capitalize">{type}</span>{" "}
                              pack
                              <br />
                              <small>€ {features.price}/mese</small>
                            </h5>
                          </th>
                          <th className="text-right align-top" colSpan={2}>
                            <Button
                              size="sm"
                              color="link"
                              className="text-nowrap"
                              type="button"
                              onClick={() =>
                                setGuaranteeModalLevel(
                                  type as "silver" | "gold" | "platinum"
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faInfoCircle} /> Garanzie
                            </Button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan={3}>Morte € 50 000</td>
                          <td className="text-right" style={{width: "1px"}}>
                            {features.morte ? (
                              <FontAwesomeIcon
                                icon={faCheck}
                                fixedWidth
                                className="text-success"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faTimes}
                                fixedWidth
                                className="text-danger"
                              />
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>IP € 50 000</td>
                          <td className="text-right">
                            {features.ip ? (
                              <FontAwesomeIcon
                                icon={faCheck}
                                fixedWidth
                                className="text-success"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faTimes}
                                fixedWidth
                                className="text-danger"
                              />
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>Pacchetto serenità contenuto</td>
                          <td className="text-right">
                            {features.serenita ? (
                              <FontAwesomeIcon
                                icon={faCheck}
                                fixedWidth
                                className="text-success"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faTimes}
                                fixedWidth
                                className="text-danger"
                              />
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>Rimb. spese di cura € 5 000</td>
                          <td className="text-right">
                            {features.rimborso ? (
                              <FontAwesomeIcon
                                icon={faCheck}
                                fixedWidth
                                className="text-success"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faTimes}
                                fixedWidth
                                className="text-danger"
                              />
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>Assistenza</td>
                          <td className="text-right">
                            {features.assistenza ? (
                              <FontAwesomeIcon
                                icon={faCheck}
                                fixedWidth
                                className="text-success"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faTimes}
                                fixedWidth
                                className="text-danger"
                              />
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>Tutela legale € 10 000</td>
                          <td className="text-right">
                            {features.legale ? (
                              <FontAwesomeIcon
                                icon={faCheck}
                                fixedWidth
                                className="text-success"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faTimes}
                                fixedWidth
                                className="text-danger"
                              />
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>Diaria ric. Infortunio € 50/gg</td>
                          <td className="text-right">
                            {features.infortunio ? (
                              <FontAwesomeIcon
                                icon={faCheck}
                                fixedWidth
                                className="text-success"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faTimes}
                                fixedWidth
                                className="text-danger"
                              />
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>Diaria ric. Malattia € 50/gg</td>
                          <td className="text-right">
                            {features.malattia ? (
                              <FontAwesomeIcon
                                icon={faCheck}
                                fixedWidth
                                className="text-success"
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faTimes}
                                fixedWidth
                                className="text-danger"
                              />
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                  {!disabled && (
                    <CardFooter className="text-center">
                      Scegli <span className="text-capitalize">{type}</span>{" "}
                      Pack
                    </CardFooter>
                  )}
                </Card>
              </label>
            </Col>
          );
        })}
      </Row>
      <GuaranteeModal
        guaranteeModalLevel={guaranteeModalLevel}
        toggle={() => setGuaranteeModalLevel(undefined)}
      />
    </>
  );
};

export default Injuries;
