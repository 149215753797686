import React from "react";
import {useDropzone} from "react-dropzone";
import {useSelector} from "react-redux";
import {Button} from "reactstrap";
import {IconSpinner, IconUpload} from "../../../../Icons";
import {IRootState} from "../../../../redux/reducers";
import {IAppThunkActionStates} from "../../../../types/thunk";
import {getThunkActionStates} from "../../../selectors";

interface IProps {
  fileName: string;
  onDrop: (acceptedFiles: File[], fileName: string) => void;
}

const UploadFileButton = ({fileName, onDrop}: IProps) => {
  const uploadDocumentStates = useSelector<
    IRootState,
    IAppThunkActionStates | undefined
  >((state) => getThunkActionStates(state, "uploadDocument", fileName));
  const {getInputProps, open} = useDropzone({
    accept: "application/pdf",
    multiple: false,
    noDrag: true,
    onDrop: (acceptedFiles) => onDrop(acceptedFiles, fileName),
  });

  return (
    <>
      <Button
        color="primary"
        size="sm"
        outline
        className="flex-grow-1"
        onClick={open}
      >
        {uploadDocumentStates?.isPending ? (
          <IconSpinner className="icon-spin" />
        ) : (
          <IconUpload />
        )}{" "}
        Carica
      </Button>
      <input {...getInputProps()} />
    </>
  );
};

export default UploadFileButton;
