import React from "react";
import {Card, CardBody, CardText, CardTitle, Col, Row} from "reactstrap";
import {IUser} from "../../Users";

interface IProps {
  user: IUser;
}

const UserDetailsTable = ({user}: IProps) => (
  <Row className="align-items-center">
    <Col sm="6">
      <CardText>
        {user.surname} {user.name}
      </CardText>
      <CardText>
        RUI: {user.ruiCode}
        <br />
        CF: {user.fiscalCode}
        <br />
        Area: {user.profitCenter}
        <br />
      </CardText>
    </Col>
    <Col sm="6" className="align-self-center">
      <Card className="text-white bg-primary">
        <CardBody>
          <CardTitle>Contatti</CardTitle>
          <CardText>
            E-mail: {user.email}
            <br />
            {user.personalEmail && (
              <>
                E-mail personale: {user.personalEmail}
                <br />
              </>
            )}
            {user.personalPhone && <> Telefono: {user.personalPhone} </>}
          </CardText>
        </CardBody>
      </Card>
    </Col>
  </Row>
);

export default UserDetailsTable;
