import React from "react";
import {Button, Col, Row} from "reactstrap";
import {IconCsv} from "../../../Icons";

const Instructions = () => (
  <>
    <p>
      Per disattivare gli Advisor tramite file CSV, è richiesto un file che
      rispetti i seguenti criteri:
      <Button
        tag="a"
        href="/csv-examples/deactivate_bulk_example.csv"
        download
        size="sm"
        color="primary"
        outline
        className="float-right"
        target="_self"
      >
        <IconCsv />
        File di esempio
      </Button>
    </p>
    <Row tag="dl">
      <Col tag="dt" md={3} lg={2}>
        Campi indice
      </Col>
      <Col tag="dd" md={9} lg={10}>
        Per individuare l'Advisor che si vuole modificare, verranno letti i
        campi nel seguente ordine: codice fiscale, codice RUI, nome utente.
        Tutti gli altri campi verranno ignorati.
      </Col>
    </Row>
  </>
);

export default Instructions;
