import React from "react";
import Dropzone, {DropzoneState, FileRejection} from "react-dropzone";
import {FormFeedback, FormText} from "reactstrap";
import {WrappedFieldProps} from "redux-form";
import IApiError from "../types/IApiError";
import errors, {ErrorCodes} from "./errors";

export type DropzoneStatus = "success" | "warning" | "danger" | undefined;
interface IOwnProps {
  id: string;
  thumb: File;
  identificationFail: (error: IApiError) => void;
  renderContent: (
    state: DropzoneState & {
      id: string;
      status: DropzoneStatus;
      thumb: File;
    }
  ) => JSX.Element;
}
const mbExponent = 20;
const mb = 2 ** mbExponent; // 1 MB
const maxSize = 16 * mb;

const RenderDropzoneField = ({
  renderContent,
  identificationFail,
  id,
  input,
  meta: {touched, error, warning},
  thumb,
}: WrappedFieldProps & IOwnProps) => {
  const onDrop = (accepted: File[], fileRejections: FileRejection[]) => {
    const {onChange, onBlur} = input;
    const newFile = accepted[0];

    if (accepted.length === 0 && fileRejections.length > 1) {
      identificationFail(errors[ErrorCodes.ID_FILE_MULTIPLE]);
    } else if (accepted.length === 0 && fileRejections.length === 1) {
      identificationFail(errors[ErrorCodes.ID_FILE_NOT_VALID]);
    } else if (accepted[0] && accepted[0].size > maxSize) {
      // 16MB
      identificationFail(errors[ErrorCodes.ID_FILE_TOO_BIG]);
    } else {
      onChange(newFile);
      onBlur(newFile);
    }
  };

  const status: DropzoneStatus = touched
    ? error
      ? "danger"
      : warning
      ? "warning"
      : "success"
    : undefined;

  return (
    <>
      <Dropzone
        onDrop={onDrop}
        multiple={false}
        minSize={0}
        maxSize={500000000}
        accept="image/*"
      >
        {(state) => {
          return renderContent({...state, status, id, thumb});
        }}
      </Dropzone>
      {error && touched && (
        <FormFeedback className="d-block">{error}</FormFeedback>
      )}
      {!error && touched && warning && (
        <FormText color="warning" className="d-block">
          {warning}
        </FormText>
      )}
    </>
  );
};

export default RenderDropzoneField;
