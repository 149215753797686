import React from "react";
import {Alert} from "reactstrap";

interface IProps {
  acceptBulkError: string;
}

const FailAccept: React.FC<IProps> = (props) => (
  <Alert color="danger">{props.acceptBulkError}</Alert>
);

export default FailAccept;
