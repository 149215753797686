import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, Redirect} from "react-router-dom";
import {Button, Container} from "reactstrap";
import {Layout, PageTitle} from "../App";
import {ICap} from "../Caps";
import {getCap} from "../Caps/selectors";
import {IconBack} from "../Icons";
import {IRootState} from "../redux/reducers";
import {IAppThunkActionStates} from "../types/thunk";
import {actions} from "./actions";
import SearchContractor, {IResultItem} from "./New/SearchContractor";
import {
  getChosenCap,
  getLastCreatedId,
  getThunkActionStates,
} from "./selectors";
import Accountability from "./shared/Accountability";
import CapIdentification from "./shared/CapIdentification";
import ContractorDetails from "./shared/ContractorDetails";
import DocumentManagement from "./shared/DocumentManagement";
import IdLockOverlay from "./shared/IdLockOverlay";
import isIdValid from "../helpers/isIdValid";

const New = () => {
  const [isAccountabilityAccepted, setAccountability] = useState(false);
  const chosenCap = useSelector<IRootState, ICap | undefined>((state) =>
    getCap(state, getChosenCap(state))
  );
  const createThunkStates = useSelector<IRootState, IAppThunkActionStates>(
    (state) => getThunkActionStates(state, "create")
  );
  const lastCreatedId = useSelector(getLastCreatedId);
  const dispatch = useDispatch();

  const handleSelect = (value: string, item: IResultItem) => {
    dispatch(actions.chooseCap(item.cap?.id));
  };

  useEffect(() => {
    return () => {
      dispatch(actions.chooseCap(undefined));
    };
  }, [dispatch]);

  return (
    <Layout isProtected>
      {createThunkStates.isSuccess && (
        <Redirect
          to={{
            pathname: `/proposte/${lastCreatedId}`,
            state: {disableAutoScrollToTop: true},
          }}
        />
      )}
      <PageTitle>
        Nuova proposta
        <Button
          tag={Link}
          color="light"
          outline
          to="/proposte"
          className="ml-auto"
        >
          <IconBack /> Torna all'elenco
        </Button>
      </PageTitle>
      {!chosenCap || !chosenCap.idd ? (
        <SearchContractor
          onSelect={handleSelect}
          searchParams={{idd: "available"}}
        />
      ) : (
        <Container className="container-max p-md-5 auto-margin-3">
          <ContractorDetails contractor={chosenCap.contractor} />
          <Accountability
            onAccept={setAccountability}
            blocked={isAccountabilityAccepted}
          />
          {isAccountabilityAccepted && (
            <>
              <CapIdentification
                cap={chosenCap}
                isAdvisor
                isContractor={false}
              />
              <div style={{position: "relative"}} className="auto-margin-3">
                <DocumentManagement cap={chosenCap} />
                {!isIdValid(chosenCap.onboarding?.identification, true) && (
                  <IdLockOverlay />
                )}
              </div>
            </>
          )}
        </Container>
      )}
    </Layout>
  );
};

export default New;
