import isEmpty from "lodash/isEmpty";
import React from "react";
import {FieldErrors, useFormContext} from "react-hook-form";
import {
  Card,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import {RhfRadio} from "../../../helpers/RHFRadio";
import {
  capitalCompanyTypes,
  companyTypes,
  individualCompanyTypes,
} from "../companyTypes";
import {INewBeneProposalFormData} from "../NewBeneProposalForm";
import {
  Capital,
  ICapitalFormData,
  validation as capitalValidation,
} from "./Rc/Capital";
import {
  IIndividualFormData,
  Individual,
  validation as individualValidation,
} from "./Rc/Individual";

export type IRCFormData = {
  profType: string;
  legalForm: string;
} & IIndividualFormData &
  ICapitalFormData;

interface RcProps {
  disabled?: boolean;
}

export const validation = (values: IRCFormData) => {
  let errors: FieldErrors<IRCFormData> = {};

  if (!values.profType) {
    errors.profType = {
      type: "required",
      message: "Scegli una tipologia professionale",
    };
  }
  if (!values.legalForm) {
    errors.legalForm = {
      type: "required",
      message: "Scegli un tipo di società",
    };
  }

  if (individualCompanyTypes.includes(values.legalForm)) {
    const individualErrors = individualValidation(values).errors;
    errors = {
      ...errors,
      ...individualErrors,
    };
  }
  if (capitalCompanyTypes.includes(values.legalForm)) {
    const capitalErrors = capitalValidation(values).errors;
    errors = {
      ...errors,
      ...capitalErrors,
    };
  }

  return {values: isEmpty(errors) ? values : {}, errors};
};

export const Rc = ({disabled}: RcProps) => {
  const {
    errors,
    formState,
    register,
    watch,
  } = useFormContext<INewBeneProposalFormData>();
  const legalForm = watch("rc.legalForm") as string;

  return (
    <>
      <h4>Polizza Bene Professional</h4>
      <Row>
        <Col sm={6}>
          <h5>Adatto a:</h5>
          <dl>
            <dt>Professioni tecniche</dt>
            <dd>Architetti, ingegneri e geometri</dd>
            <dt>Professioni giuridiche</dt>
            <dd>Avvocati, commercialisti e consulenti del lavoro</dd>
          </dl>
        </Col>
        <Col sm={6}>
          <h5>Garanzie attivabili:</h5>
          <ul>
            <li>RC professionale</li>
            <li>Tutela legale</li>
            <li>Infortuni - Tutela reddito</li>
            <li>Assistenza</li>
          </ul>
        </Col>
      </Row>
      <hr className="mt-0 border-primary" />
      <div>
        <h5>Con Bene Professional puoi:</h5>
        <ul>
          <li>
            Tutelare il tuo patrimonio da eventuali risarcimenti dovuti a terzi
            a seguito dello svolgimento della tua attività professionale.
          </li>
          <li>
            Usufruire di assistenza legale ed ottenere il rimborso delle spese
            legali necessarie per tutelare i tuoi diritti nelle sedi competenti.
          </li>
          <li>
            Beneficiare di una copertura in caso di grave invalidità permanente
            a seguito di un infortunio anche in ambito extra-professionale.
          </li>
          <li>Usufruire di molteplici servizi di assistenza</li>
        </ul>
      </div>
      <Card body className="mb-3 auto-margin-3">
        <RhfRadio
          errors={errors}
          formState={formState}
          name="rc.profType"
          register={register}
          label="Tipologia professionale"
        >
          <option value="legal">Professioni giuridiche</option>
          <option value="technical">Professioni tecniche</option>
        </RhfRadio>
        <FormGroup>
          <Label>Tipo di società</Label>
          <Input
            type="select"
            name="rc.legalForm"
            innerRef={register}
            invalid={!!errors?.rc?.legalForm}
            valid={formState.touched.rc?.legalForm && !errors?.rc?.legalForm}
          >
            <option value="">Scegli</option>
            {companyTypes.map((companyType) => (
              <option key={companyType} value={companyType}>
                {companyType}
              </option>
            ))}
          </Input>
          {errors?.rc?.legalForm && (
            <FormFeedback>{errors.rc.legalForm.message}</FormFeedback>
          )}
        </FormGroup>
        {individualCompanyTypes.includes(legalForm) && (
          <Individual disabled={disabled} />
        )}
        {capitalCompanyTypes.includes(legalForm) && (
          <Capital disabled={disabled} />
        )}
      </Card>
    </>
  );
};
