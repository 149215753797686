import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {
  Alert,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";

import {Layout} from "../App";
import {IRootState} from "../redux/reducers";
import {
  actions,
  ActivateWithTokenForm,
  IActivateWithTokenFormData,
  IState,
} from "./";

interface IOwnProps {
  match: {params: {token?: string}};
}
type IStateProps = IState;
interface IDispatchProps {
  submitLostPasswordWithToken: (
    values: IActivateWithTokenFormData
  ) => Promise<void>;
}

const mapStateToProps = (state: IRootState): IState => state.user;
const mapDispatchToProps = {
  submitLostPasswordWithToken: (values: IActivateWithTokenFormData) => {
    return actions.lostPasswordWithToken(values);
  },
};

const LostPasswordWithTokenPage: React.FC<
  IStateProps & IDispatchProps & IOwnProps
> = (props) => (
  <Layout hasMenu={false}>
    <Container className="main-center auto-margin-4">
      <Row>
        <Col
          sm={{size: 8, offset: 2}}
          md={{size: 6, offset: 3}}
          lg={{size: 4, offset: 4}}
        >
          <img
            src="/img/logo.svg"
            className="logo mx-auto d-block img-fluid"
            alt={`${process.env.REACT_APP_TITLE} logo`}
          />
        </Col>
      </Row>
      <Row>
        <Col
          sm={{size: 8, offset: 2}}
          md={{size: 6, offset: 3}}
          lg={{size: 4, offset: 4}}
        >
          {props.isLostPasswordWithTokenSuccess ? (
            <Alert color="success">
              <h4 className="alert-heading">Password resettata</h4>
              La tua password è stata resettata. Ora puoi effettuare l'accesso
              alla pagina di{" "}
              <Link className="alert-link" to="/">
                login
              </Link>
            </Alert>
          ) : (
            <Card className="text-primary">
              <CardBody>
                <CardTitle>Reset password</CardTitle>
                <CardText>
                  Inserisci il codice che hai ricevuto via mail e scegli una
                  password per ripristinare la tua password
                </CardText>
                <ActivateWithTokenForm
                  submitText="Resetta la password"
                  onSubmit={props.submitLostPasswordWithToken}
                  isActivateWithTokenFail={props.isLostPasswordWithTokenFail}
                  activateWithTokenError={props.lostPasswordWithTokenError}
                  initialValues={{token: props.match.params.token}}
                />
              </CardBody>
            </Card>
          )}
          <Link to="/" className="text-light">
            Torna alla pagina di accesso
          </Link>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LostPasswordWithTokenPage);
