import {batch} from "react-redux";
import {Dispatch} from "redux";
import {SubmissionError} from "redux-form";
import {actions as capsActions, Actions as CapsActions} from "../Caps";
import {ErrorCodes} from "../helpers/errors";
import IApiError from "../types/IApiError";
import {actions as usersActions} from "../Users";
import {ThunkActionsNames} from "./reducers";
import service from "./service";
import {
  IProposalEntity,
  IProposalProductDocumentsDB,
  IProposalsEntities,
  normalizeProposal,
} from "./types";

export enum ActionTypes {
  MERGE_ITEMS = "[Proposals] Merge items",

  SET_IS_PENDING = "[Proposals] set the pending state of a thunk",
  SET_IS_SUCCESS = "[Proposals] set the success state of a thunk",
  SET_IS_FAIL = "[Proposals] set the fail state of a thunk",
  RESET_STATE = "[Proposals] reset the state of a thunk",

  CHOOSE_CAP = "[Proposals] Choose cap",

  CREATED = "[Proposals] proposal created",
  GOT = "[Proposals] got proposal",
  GOT_LIST = "[Proposals] got list of proposals",
  UPDATED = "[Proposals] proposal updated",
  DUPLICATED = "[Proposals] proposal duplicated",

  OPEN_E_SIGN_MANAGEMENT_MODAL = "[Proposals] open the eSign management modal",
  CLOSE_E_SIGN_MANAGEMENT_MODAL = "[Proposals] close the eSign management modal",

  DOCUMENT_UPLOADED = "[Proposals] document uploaded",
  DOCUMENT_DELETED = "[Proposals] document deleted",

  GOT_PROPOSAL_PRODUCT_DOCUMENTS_LIST = "[Proposals] got proposal product documents list",
}

interface IMergeItemsAction {
  type: typeof ActionTypes.MERGE_ITEMS;
  payload: {proposals: IProposalsEntities};
}

interface ISetIsPending {
  type: typeof ActionTypes.SET_IS_PENDING;
  payload: {thunkActionsName: ThunkActionsNames; id?: string};
}
interface ISetIsSuccess {
  type: typeof ActionTypes.SET_IS_SUCCESS;
  payload: {thunkActionsName: ThunkActionsNames; id?: string};
}
interface ISetIsFail {
  type: typeof ActionTypes.SET_IS_FAIL;
  error: IApiError;
  payload: {thunkActionsName: ThunkActionsNames; id?: string};
}
interface IResetState {
  type: typeof ActionTypes.RESET_STATE;
  payload: {thunkActionsName: ThunkActionsNames; id?: string};
}

interface ICreated {
  type: typeof ActionTypes.CREATED;
  payload: {proposalId: string};
}

interface IGot {
  type: typeof ActionTypes.GOT;
  payload: {proposalId: string};
}

interface IGotList {
  type: typeof ActionTypes.GOT_LIST;
  payload: {proposalsIds: ReadonlyArray<string>; numProposals: number};
}

interface IUpdated {
  type: typeof ActionTypes.UPDATED;
  payload: {proposalId: string};
}

interface IDuplicated {
  type: typeof ActionTypes.DUPLICATED;
  payload: {
    proposalsIds: {
      oldProposalId: string;
      newProposalId: string;
    };
  };
}

interface IOpenESignManagementModal {
  type: ActionTypes.OPEN_E_SIGN_MANAGEMENT_MODAL;
  payload: {fileName: string};
}
interface ICloseESignManagementModal {
  type: ActionTypes.CLOSE_E_SIGN_MANAGEMENT_MODAL;
}

interface IChooseCap {
  type: typeof ActionTypes.CHOOSE_CAP;
  payload: {capId: string | undefined};
}

interface IDocumentUploaded {
  type: typeof ActionTypes.DOCUMENT_UPLOADED;
}
interface IDocumentDeleted {
  type: typeof ActionTypes.DOCUMENT_DELETED;
}

interface IGotProposalProductDocumentsList {
  payload: {proposalProductDocuments: IProposalProductDocumentsDB[]};
  type: typeof ActionTypes.GOT_PROPOSAL_PRODUCT_DOCUMENTS_LIST;
}

export const actions = {
  mergeItems: (proposals: IProposalsEntities): IMergeItemsAction => ({
    payload: {proposals},
    type: ActionTypes.MERGE_ITEMS,
  }),

  setIsPending: (
    thunkActionsName: ThunkActionsNames,
    id?: string
  ): ISetIsPending => ({
    payload: {thunkActionsName, id},
    type: ActionTypes.SET_IS_PENDING,
  }),
  setIsSuccess: (
    thunkActionsName: ThunkActionsNames,
    id?: string
  ): ISetIsSuccess => ({
    payload: {thunkActionsName, id},
    type: ActionTypes.SET_IS_SUCCESS,
  }),
  setIsFail: (
    thunkActionsName: ThunkActionsNames,
    error: IApiError,
    id?: string
  ): ISetIsFail => ({
    payload: {thunkActionsName, id},
    error,
    type: ActionTypes.SET_IS_FAIL,
  }),
  resetState: (
    thunkActionsName: ThunkActionsNames,
    id?: string
  ): IResetState => ({
    payload: {thunkActionsName, id},
    type: ActionTypes.RESET_STATE,
  }),

  create: (proposal: Partial<IProposalEntity>) => (
    dispatch: Dispatch<actions | CapsActions | usersActions>
  ) => {
    dispatch(actions.setIsPending("create"));
    return service
      .create(proposal)
      .then((createdProposal) => {
        const {
          result,
          entities: {proposals, caps, users},
        } = normalizeProposal(createdProposal);
        batch(() => {
          dispatch(usersActions.mergeItems(users));
          dispatch(capsActions.mergeItems(caps));
          dispatch(actions.mergeItems(proposals));

          dispatch(actions.created(result));
          dispatch(actions.setIsSuccess("create"));
        });
      })
      .catch((error) => {
        if (error.code === ErrorCodes.WRONG_PROPOSAL_CODE) {
          dispatch(actions.resetState("create"));
          throw new SubmissionError({
            code: error.message,
          });
        }
        dispatch(actions.setIsFail("create", error));
      });
  },
  created: (proposalId: string): ICreated => ({
    payload: {
      proposalId,
    },
    type: ActionTypes.CREATED,
  }),

  get: (id: string) => (
    dispatch: Dispatch<actions | CapsActions | usersActions>
  ) => {
    dispatch(actions.setIsPending("get"));
    return service
      .read(id)
      .then((proposal) => {
        const {
          result,
          entities: {proposals, caps, users},
        } = normalizeProposal(proposal);

        batch(() => {
          dispatch(usersActions.mergeItems(users));
          dispatch(capsActions.mergeItems(caps));
          dispatch(actions.mergeItems(proposals));

          dispatch(actions.got(result));
          dispatch(actions.setIsSuccess("get"));
        });
      })
      .catch((error) => {
        dispatch(actions.setIsFail("get", error));
      });
  },
  got: (proposalId: string): IGot => ({
    payload: {
      proposalId,
    },
    type: ActionTypes.GOT,
  }),

  list: () => (dispatch: Dispatch<actions | usersActions | CapsActions>) => {
    dispatch(actions.setIsPending("list"));
    return service
      .list()
      .then(({proposalsList, numProposals}) => {
        const {
          result,
          entities: {proposals, caps, users},
        } = normalizeProposal(proposalsList);

        batch(() => {
          dispatch(usersActions.mergeItems(users));
          dispatch(capsActions.mergeItems(caps));
          dispatch(actions.mergeItems(proposals));

          dispatch(actions.setIsSuccess("list"));
          dispatch(actions.gotList(result, numProposals));
        });
      })
      .catch((error) => {
        dispatch(actions.setIsFail("list", error));
      });
  },
  gotList: (
    proposalsIds: ReadonlyArray<string>,
    numProposals: number
  ): IGotList => ({
    payload: {
      proposalsIds,
      numProposals,
    },
    type: ActionTypes.GOT_LIST,
  }),

  update: (id: string, data: Partial<IProposalEntity>, reducerId?: string) => (
    dispatch: Dispatch<actions | CapsActions | usersActions>
  ) => {
    dispatch(actions.setIsPending("update", reducerId));
    return service
      .update(id, data)
      .then((updatedProposal) => {
        const {
          result,
          entities: {proposals, caps, users},
        } = normalizeProposal(updatedProposal);

        batch(() => {
          dispatch(usersActions.mergeItems(users));
          dispatch(capsActions.mergeItems(caps));
          dispatch(actions.mergeItems(proposals));

          dispatch(actions.updated(result));
          dispatch(actions.setIsSuccess("update", reducerId));
        });
      })
      .catch((error) => {
        if (error.code === ErrorCodes.WRONG_PROPOSAL_CODE) {
          dispatch(actions.resetState("update", reducerId));
          throw new SubmissionError({
            code: error.message,
          });
        }
        dispatch(actions.setIsFail("update", error, reducerId));
      });
  },
  updated: (proposalId: string): IUpdated => ({
    payload: {
      proposalId,
    },
    type: ActionTypes.UPDATED,
  }),

  duplicate: (id: string) => (
    dispatch: Dispatch<actions | CapsActions | usersActions>
  ) => {
    dispatch(actions.setIsPending("duplicate"));
    return service
      .duplicate(id)
      .then(({oldProposal, newProposal}) => {
        const {
          result,
          entities: {proposals, caps, users},
        } = normalizeProposal([oldProposal, newProposal]);

        batch(() => {
          dispatch(usersActions.mergeItems(users));
          dispatch(capsActions.mergeItems(caps));
          dispatch(actions.mergeItems(proposals));

          dispatch(
            actions.duplicated({
              oldProposalId: result[0],
              newProposalId: result[1],
            })
          );
          dispatch(actions.setIsSuccess("duplicate"));
        });
      })
      .catch((error) => {
        dispatch(actions.setIsFail("duplicate", error));
      });
  },
  duplicated: (proposalsIds: {
    oldProposalId: string;
    newProposalId: string;
  }): IDuplicated => ({
    payload: {
      proposalsIds,
    },
    type: ActionTypes.DUPLICATED,
  }),

  sendDarta: (id: string) => (
    dispatch: Dispatch<actions | CapsActions | usersActions>
  ) => {
    dispatch(actions.setIsPending("sendDarta"));
    return service
      .sendDarta(id)
      .then((updatedProposal) => {
        const {
          entities: {proposals, caps, users},
        } = normalizeProposal(updatedProposal);

        batch(() => {
          dispatch(usersActions.mergeItems(users));
          dispatch(capsActions.mergeItems(caps));
          dispatch(actions.mergeItems(proposals));

          dispatch(actions.setIsSuccess("sendDarta"));
        });
      })
      .catch((error) => {
        dispatch(actions.setIsFail("sendDarta", error));
      });
  },

  openESignsManagementModal: (fileName: string): IOpenESignManagementModal => ({
    type: ActionTypes.OPEN_E_SIGN_MANAGEMENT_MODAL,
    payload: {fileName},
  }),
  closeESignsManagementModal: (): ICloseESignManagementModal => ({
    type: ActionTypes.CLOSE_E_SIGN_MANAGEMENT_MODAL,
  }),

  chooseCap: (capId: string | undefined): IChooseCap => {
    return {
      payload: {capId},
      type: ActionTypes.CHOOSE_CAP,
    };
  },

  uploadDocument: (proposalId: string, file: File, fileName: string) => (
    dispatch: Dispatch<actions | usersActions | CapsActions>
  ) => {
    dispatch(actions.setIsPending("uploadDocument", fileName));
    dispatch(actions.setIsPending("uploadDocument"));
    return service
      .uploadDocument(proposalId, file)
      .then((updatedProposal) => {
        const {
          entities: {proposals, caps, users},
        } = normalizeProposal(updatedProposal);

        batch(() => {
          dispatch(usersActions.mergeItems(users));
          dispatch(capsActions.mergeItems(caps));
          dispatch(actions.mergeItems(proposals));

          dispatch(actions.documentUploaded());
          dispatch(actions.setIsSuccess("uploadDocument", fileName));
          dispatch(actions.setIsSuccess("uploadDocument"));
        });
      })
      .catch((error) => {
        dispatch(actions.setIsFail("uploadDocument", error, fileName));
        dispatch(actions.setIsFail("uploadDocument", error));
      });
  },
  documentUploaded: (): IDocumentUploaded => ({
    type: ActionTypes.DOCUMENT_UPLOADED,
  }),

  deleteDocument: (proposalId: string, fileName: string) => (
    dispatch: Dispatch<actions | usersActions | CapsActions>
  ) => {
    dispatch(actions.setIsPending("deleteDocument", fileName));
    dispatch(actions.setIsPending("deleteDocument"));
    return service
      .deleteDocument(proposalId, fileName)
      .then((updatedProposal) => {
        const {
          entities: {proposals, caps, users},
        } = normalizeProposal(updatedProposal);

        batch(() => {
          dispatch(usersActions.mergeItems(users));
          dispatch(capsActions.mergeItems(caps));
          dispatch(actions.mergeItems(proposals));

          dispatch(actions.documentDeleted());
          dispatch(actions.setIsSuccess("deleteDocument", fileName));
          dispatch(actions.setIsSuccess("deleteDocument"));
        });
      })
      .catch((error) => {
        dispatch(actions.setIsFail("deleteDocument", error, fileName));
        dispatch(actions.setIsFail("deleteDocument", error));
      });
  },
  documentDeleted: (): IDocumentDeleted => ({
    type: ActionTypes.DOCUMENT_DELETED,
  }),

  proposalProductDocumentsList: () => (dispatch: Dispatch<actions>) => {
    dispatch(actions.setIsPending("proposalProductDocumentsList"));
    return service
      .proposalProductDocumentsList()
      .then((products) => {
        dispatch(
          actions.gotProposalProductDocumentsList(
            products.filter((product) => product.jsonEsigns && product.product)
          )
        );
        dispatch(actions.setIsSuccess("proposalProductDocumentsList"));
      })
      .catch((error) => {
        dispatch(actions.setIsFail("proposalProductDocumentsList", error));
      });
  },
  gotProposalProductDocumentsList: (
    proposalProductDocuments: IProposalProductDocumentsDB[]
  ): IGotProposalProductDocumentsList => ({
    payload: {proposalProductDocuments},
    type: ActionTypes.GOT_PROPOSAL_PRODUCT_DOCUMENTS_LIST,
  }),
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type actions =
  | IMergeItemsAction
  | ISetIsPending
  | ISetIsSuccess
  | ISetIsFail
  | IResetState
  | ICreated
  | IGot
  | IGotList
  | IUpdated
  | IDuplicated
  | IOpenESignManagementModal
  | ICloseESignManagementModal
  | IChooseCap
  | IDocumentUploaded
  | IDocumentDeleted
  | IGotProposalProductDocumentsList;
