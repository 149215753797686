import React from "react";
import {Card} from "reactstrap";
import {IconPerson} from "../../Icons";
import {IUser} from "../../Users";
import UserDetailsTable from "./UserDetailsTable";

interface IProps {
  user: IUser;
}

const UserDetails: React.FC<IProps> = ({user}) => (
  <Card body color="drawer auto-margin-3">
    <h3>
      <IconPerson title="Anagrafica cliente" /> Advisor
    </h3>
    <UserDetailsTable user={user} />
  </Card>
);

export default UserDetails;
