import React from "react";
import {FormFeedback, FormGroup, FormText, Input, Label} from "reactstrap";
import {Field} from "redux-form";

interface IRenderMultiFieldProps {
  id: string;
  name: string;
  className?: string;
  items: Array<{value: string; text: string; disabled?: boolean}>;
  type: string;
  disabled?: boolean;
  inline?: boolean;
  onChange?: any;
}

const BSInput = ({
  input,
  meta: {touched, error, warning, dirty},
  label,
  inline,
  ...props
}: any) => (
  <>
    <FormGroup check inline={inline !== false}>
      <Input
        {...(touched ? {invalid: !!error} : {})}
        {...(dirty ? {valid: !error} : {})}
        {...input}
        {...props}
      />
      <Label for={props.id} check>
        {label}
      </Label>
    </FormGroup>
    {props.last ? (
      <>
        <FormFeedback className={touched && error ? "d-block" : ""}>
          {error}
        </FormFeedback>
        {!error && warning && <FormText color="warning">{warning}</FormText>}
      </>
    ) : null}
  </>
);

const RenderMultiField = (props: IRenderMultiFieldProps) => (
  <FormGroup tag="fieldset" className={props.className}>
    {props.items.map(
      (item: {value: string; text: string; disabled?: boolean}, i) => (
        <Field
          key={item.value}
          type={props.type}
          name={props.name}
          id={`${props.id}-${props.name}-${item.value}`}
          onChange={props.onChange}
          value={item.value}
          component={BSInput}
          label={item.text}
          last={i === props.items.length - 1 ? 1 : 0}
          disabled={
            item.disabled !== undefined ? item.disabled : props.disabled
          }
          inline={props.inline}
        />
      )
    )}
  </FormGroup>
);

export default RenderMultiField;
