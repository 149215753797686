import React from "react";
import {CardText, Table} from "reactstrap";
import FormatNumber from "../helpers/FormatNumber";
import {IEmploymentFormData} from "./EmploymentForm";

interface IProps {
  employments: IEmploymentFormData;
}

const EmploymentsTable: React.FC<IProps> = ({employments}) => (
  <>
    <Table className="border" striped responsive>
      <thead>
        <tr>
          <th>Da</th>
          <th>A</th>
          <th>Professione</th>
          <th>Contratto</th>
          <th className="text-right">Reddito mensile netto*</th>
        </tr>
      </thead>
      <tbody>
        {employments && (
          <>
            <tr>
              <td>{employments.currentEmployment.dal}</td>
              <td>oggi</td>
              <td>{employments.currentEmployment.professione}</td>
              <td>{employments.currentEmployment.tipologia}</td>
              <td className="text-right">
                <FormatNumber>
                  {employments.currentEmployment.redditoNormalizzato}
                </FormatNumber>{" "}
                €
              </td>
            </tr>
            {employments.prevEmployments &&
              employments.prevEmployments.map((prevJob) => (
                <tr key={`${prevJob.dal}-${prevJob.al}`}>
                  <td>{prevJob.dal}</td>
                  <td>{prevJob.al}</td>
                  <td>{prevJob.professione}</td>
                  <td>{prevJob.tipologia}</td>
                  <td className="text-right">
                    <FormatNumber>{prevJob.redditoNormalizzato}</FormatNumber> €
                  </td>
                </tr>
              ))}
          </>
        )}
      </tbody>
    </Table>
    <CardText>
      * reddito mensile al netto delle imposte, distribuito su 12 mensilità.
    </CardText>
  </>
);

export default EmploymentsTable;
