import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Button, Card} from "reactstrap";
import ContainerMax from "../App/ContainerMax";
import {
  IconCheckmark,
  IconDownload,
  IconESign,
  IconPerson,
  IconSendNotification,
  IconSpinner,
} from "../Icons";
import {IRootState} from "../redux/reducers";
import {StrictUnion} from "../types/StrictUnion";
import {IAppThunkActionStates} from "../types/thunk";
import {actions as usersActions} from "../Users/actions";
import {getThunkActionStates, getUser} from "../Users/selectors";
import {actions} from "./actions";
import PrivacySubscriptionModal from "./PrivacySubscription/PrivacySubscriptionModal";
import {getIsAdvisor, getIsContractor} from "./selectors";
import {ICap, NewCapModalTypes} from "./types";

interface IProps {
  cap?: ICap;
  className?: string;
  id?: string;
  icon?: boolean;
}

interface IAsMax extends IProps {
  as: typeof ContainerMax;
  isLoading?: boolean;
  isLocked?: boolean;
}

interface IAsCard extends IProps {
  as: typeof Card;
  body?: boolean;
  color?: string;
}

const PrivacySubscription = ({
  cap,
  as: Tag,
  icon,
  ...props
}: StrictUnion<IAsCard, IAsMax>) => {
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(actions.closeNewCapModal(NewCapModalTypes.PrivacySubscription));
  };
  const openModal = () => {
    dispatch(actions.openNewCapModal(NewCapModalTypes.PrivacySubscription));
  };
  const sendPrivacySubscriptionNotification = () => {
    dispatch(
      usersActions.privacySubscriptionNotification(
        cap!.id,
        !!cap!.contractor.privacySubscription
      )
    );
  };

  // Leggiamo direttamente il contractor per beccare gli aggiornamenti
  const contractor = useSelector((state: IRootState) =>
    getUser(state, cap?.contractor.id)
  );
  const isAdvisor = useSelector((state: IRootState) =>
    getIsAdvisor(state, cap?.id)
  );
  const isContractor = useSelector((state: IRootState) =>
    getIsContractor(state, cap?.id)
  );
  const privacySubscriptionNotificationStates = useSelector<
    IRootState,
    IAppThunkActionStates
  >((state) => getThunkActionStates(state, "privacySubscriptionNotification"));

  return (
    <Tag {...props}>
      <h3>
        {icon && (
          <>
            <IconPerson title="Anagrafica cliente" />{" "}
          </>
        )}
        Informativa Clienti relativa alla sottoscrizione della polizza
      </h3>
      <p>
        Al fine della sottoscrizione della polizza, il contraente deve dare il
        proprio consenso al trattamento dei dati.
      </p>
      {contractor?.lastPrivacySubscriptionEsignId ? (
        <>
          <Button
            color="primary"
            outline
            download
            href={`${process.env.REACT_APP_API_BASE_URL}users/${contractor.id}/get-pdf-privacy-subscription`}
          >
            <IconDownload /> Scarica il documento privacy sottoscrizione
          </Button>
          <Alert color="success">Privacy firmata</Alert>
        </>
      ) : isContractor ? (
        <Button color="primary" outline onClick={openModal}>
          <IconESign /> Visualizza e firma
        </Button>
      ) : isAdvisor ? (
        <>
          <Button
            color="primary"
            outline
            onClick={sendPrivacySubscriptionNotification}
          >
            {privacySubscriptionNotificationStates.isPending ? (
              <IconSpinner className="icon-spin" />
            ) : privacySubscriptionNotificationStates.isSuccess ? (
              <IconCheckmark color="success" />
            ) : (
              <IconSendNotification />
            )}{" "}
            Invia promemoria
          </Button>
          <Alert color="danger">In attesa della firma del cliente</Alert>
        </>
      ) : (
        <Alert color="danger">In attesa della firma del cliente</Alert>
      )}
      {cap && (
        <PrivacySubscriptionModal
          toggle={closeModal}
          contractorId={cap.contractor.id}
        />
      )}
    </Tag>
  );
};

export default PrivacySubscription;
