import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {IconCheckCircle, IconTimesCircle} from "../Icons";

export const ColumnOpenCloseFilter = ({filter, onChange}: any) => (
  <UncontrolledDropdown>
    <DropdownToggle
      caret
      size="sm"
      block
      color="primary"
      outline
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {(!filter || (filter.value !== true && filter.value !== false)) &&
        "Tutti"}
      {filter && filter.value === true && (
        <span>
          <IconCheckCircle color="success" /> Completo
        </span>
      )}
      {filter && filter.value === false && (
        <span>
          <IconTimesCircle color="danger" /> Non completo
        </span>
      )}
    </DropdownToggle>
    <DropdownMenu>
      <DropdownItem onClick={onChange.bind({}, undefined)}>Tutti</DropdownItem>
      <DropdownItem onClick={onChange.bind({}, true)}>
        <IconCheckCircle color="success" /> Completo
      </DropdownItem>
      <DropdownItem onClick={onChange.bind({}, false)}>
        <IconTimesCircle color="danger" /> Non completo
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);
