import "moment/locale/it";
import React from "react";
import {Provider} from "react-redux";
import {BrowserRouter as Router} from "react-router-dom";
import store from "../redux/store";
import "./App.scss";
import ErrorBoundaryPage from "./ErrorBoundaryPage";
import Routes from "./Routes";
import ScrollToTop from "./ScrollToTop";

const PrevisionApp: React.FC = () => {
  return (
    <Provider store={store}>
      <Router basename={process.env.PUBLIC_URL}>
        <ScrollToTop>
          <ErrorBoundaryPage>
            <Routes />
          </ErrorBoundaryPage>
        </ScrollToTop>
      </Router>
    </Provider>
  );
};

export default PrevisionApp;
