import React from "react";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";

import {IconHouse, IconTimes} from "../Icons";
import {ICap, ICapEntity, IStateUi, NewCapModalTypes} from "./";
import HomeForm, {IHomeFormData} from "./HomeForm";

interface IProps {
  closeHome: () => void;
  ui: IStateUi;

  cap: ICap;
  homeSubmit: (values: IHomeFormData, cap: ICapEntity) => Promise<void>;
}

const HomeModal: React.FC<IProps> = (props) => (
  <Modal
    isOpen={props.ui.isModalOpen[NewCapModalTypes.Home]}
    toggle={props.closeHome}
    backdrop="static"
    keyboard={false}
    className="modal-form"
  >
    <ModalHeader
      toggle={props.closeHome}
      close={
        <Button size="sm" className="close-modal" onClick={props.closeHome}>
          <IconTimes size={1.25} className="icon-border-2 rounded-circle p-1" />
        </Button>
      }
    >
      <IconHouse size={3} className="icon-border-2 rounded-circle p-2" />
      Residenza
    </ModalHeader>
    <ModalBody>
      <HomeForm {...props} />
    </ModalBody>
  </Modal>
);

export default HomeModal;
