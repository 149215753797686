import classNames from "classnames";
import moment from "moment";
import React, {ReactNode} from "react";
import Autocomplete from "react-autocomplete";
import {Col, Container, FormGroup, Label} from "reactstrap";

import {ICap} from "../../Caps";
import {getHighlightedText} from "../../helpers/getHighlightedText";
import {IconSpinner, IconWarning} from "../../Icons";

export interface IResultItem {
  fiscalCode: string;
  id: number;
  name: string;
  cap?: ICap;
  surname: string;
}

interface IProps {
  denormalizedCaps: ICap[];
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelect: (value: string, item: IResultItem) => void;
  isListPending: boolean;
  value: string;
}

const renderInput = (props: any) => {
  return (
    <input
      className="form-control"
      type="search"
      id="search-contractor"
      placeholder="Nome, cognome o codice fiscale"
      {...props}
    />
  );
};

const getItemValue = (item: IResultItem) => {
  return `${item.name} ${item.surname}`;
};

const renderItem = (value: string, item: IResultItem, highlighted: boolean) => {
  return (
    <button
      key={item.id}
      className={classNames("dropdown-item", {
        hover: highlighted,
      })}
      type="button"
    >
      {getHighlightedText(`${item.name} ${item.surname}`, value)}{" "}
      <small className="text-secondary">
        {getHighlightedText(item.fiscalCode, value)}
      </small>
    </button>
  );
};

const shouldItemRender = (item: IResultItem, value: string) =>
  !!item.cap!.dateIdd &&
  moment(item.cap!.dateIdd).isAfter(moment().subtract(1, "year")) &&
  item.cap!.responseValidation === "1" &&
  !!item.name &&
  !!item.surname &&
  (`${item.name} ${item.surname}`
    .toLowerCase()
    .includes(value.toLowerCase().trim()) ||
    `${item.surname} ${item.name}`
      .toLowerCase()
      .includes(value.toLowerCase().trim()) ||
    item.fiscalCode.toLowerCase().includes(value.toLowerCase().trim()));

const renderMenu = (
  isListPending: boolean,
  items: ReactNode[],
  value: string,
  style: React.CSSProperties
): ReactNode => {
  return (
    <div
      className={classNames("dropdown-menu show", {
        "bg-warning": items.length === 0 && !isListPending,
      })}
      aria-labelledby="search-contractor"
      style={{...style}}
    >
      {items.length === 0 && !isListPending && (
        <span className="dropdown-item-text text-center">
          <IconWarning /> Nessun cliente con analisi IBIPs valida trovato
        </span>
      )}
      {isListPending && (
        <span className="dropdown-item-text text-center">
          <IconSpinner className="icon-spin" /> Caricamento...
        </span>
      )}
      {items}
    </div>
  );
};

const SearchContractor: React.FC<IProps> = ({
  denormalizedCaps,
  handleChange,
  handleSelect,
  isListPending,
  value,
}) => (
  <Container>
    <FormGroup row>
      <Label sm="auto" for="search-contractor">
        Ricerca cliente
      </Label>
      <Col>
        <Autocomplete // TODO: da sostituire con react-autosuggest
          items={denormalizedCaps.map((cap) => ({
            cap,
            fiscalCode: cap.contractor ? cap.contractor.fiscalCode : "",
            id: cap.id,
            name: cap.contractor ? cap.contractor.name : "",
            surname: cap.contractor ? cap.contractor.surname : "",
          }))}
          shouldItemRender={shouldItemRender}
          getItemValue={getItemValue}
          renderItem={renderItem.bind({}, value)}
          renderInput={renderInput}
          renderMenu={renderMenu.bind({}, isListPending)}
          value={value}
          onChange={handleChange}
          onSelect={handleSelect}
          wrapperStyle={{}}
        />
      </Col>
    </FormGroup>
  </Container>
);

export default SearchContractor;
