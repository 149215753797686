import IRoutes from "../types/IRoutes";
import DownloadDocuments from "./DownloadDocuments";
import List from "./List";
import New from "./New";
import View from "./View";

const routes: IRoutes = [
  {
    component: List,
    exact: true,
    name: "proposte",
    path: "/proposte",
    permission: "proposal.read||proposal.contractor-read",
    // Ulteriore controllo nel componente
  },
  {
    component: DownloadDocuments,
    exact: true,
    name: "proposte-download-documents",
    path: "/proposte/download-documents",
    permission: "proposal.manage||proposal.read",
    company: "99",
  },
  {
    component: New,
    exact: true,
    name: "proposte-new",
    path: "/proposte/new",
    permission: "proposal.manage",
    company: "99",
  },
  {
    component: View,
    exact: true,
    name: "proposte-view",
    path: "/proposte/:id?",
    permission: "proposal.read||proposal.contractor-read",
  },
];

export default routes;
