import React from "react";
import {Link} from "react-router-dom";
import {Button} from "reactstrap";

import {IconSpinner} from "../../../Icons";
import FailAccept from "../shared/FailAccept";
import UsersReport from "./UsersReport";

interface IProps {
  acceptBulkError?: string;
  filePath: string;
  isAcceptBulkFail: boolean;
  isAcceptBulkPending: boolean;
  report: Array<{[Key: string]: string}>;

  acceptBulkFile: (filePath: string) => any;
  resetFileState: () => any;
}

const SuccessFile: React.FC<IProps> = (props) => {
  const usersOk = props.report.filter(
    (userData) => userData.level === "success"
  );
  return (
    <React.Fragment>
      <p>Il file caricato contiene {props.report.length} Advisor da creare.</p>
      <UsersReport report={props.report} />
      {props.isAcceptBulkFail && props.acceptBulkError && (
        <FailAccept acceptBulkError={props.acceptBulkError} />
      )}
      <Button
        color="primary"
        onClick={props.acceptBulkFile.bind({}, props.filePath)}
        disabled={usersOk.length <= 0 || props.isAcceptBulkPending}
      >
        {props.isAcceptBulkPending && <IconSpinner className="icon-spin" />}{" "}
        Crea Advisor
      </Button>{" "}
      <Button color="secondary" onClick={props.resetFileState}>
        Carica un altro file
      </Button>{" "}
      <Button tag={Link} to="/users" color="secondary">
        Annulla
      </Button>
    </React.Fragment>
  );
};

export default SuccessFile;
