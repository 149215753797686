import React from "react";
import {useSelector} from "react-redux";
import {IRootState} from "../../redux/reducers";
import {getUser} from "../../Users/selectors";

interface IProps {
  id: string;
}

const AdvisorTd = ({id}: IProps) => {
  const advisor = useSelector((state: IRootState) => getUser(state, id));

  if (!advisor) {
    return <span>-</span>;
  }

  return (
    <div style={{lineHeight: 1}}>
      {advisor.surname} {advisor.name}
      <br />
      <small className="smaller text-info">
        RUI: {advisor.ruiCode}
        <br />
        CF: {advisor.fiscalCode}
      </small>
    </div>
  );
};

export default AdvisorTd;
