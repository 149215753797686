import isEmpty from "lodash/isEmpty";
import React from "react";
import {FieldErrors, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {change, formValueSelector} from "redux-form";
import {IconSpinner} from "../../../../Icons";
import {IRootState} from "../../../../redux/reducers";
import {IAppThunkActionStates} from "../../../../types/thunk";

interface IEditProposalCodeFormData {
  code: string;
}

interface IProps {
  formName: string;
  isOpen: boolean;
  onSubmit: (data: IEditProposalCodeFormData) => void;
  onSubmitStates: IAppThunkActionStates;
  toggle: () => void;
}

const validate = async (values: IEditProposalCodeFormData) => {
  const errors: FieldErrors<IEditProposalCodeFormData> = {};

  if (values && !values.code) {
    errors.code = {
      type: "required",
      message: "Inserisci un codice",
    };
  }

  return {values: isEmpty(errors) ? values : {}, errors};
};

const EditProposalCodeModal = ({
  formName,
  isOpen,
  onSubmit,
  onSubmitStates,
  toggle,
}: IProps) => {
  const dispatch = useDispatch();
  const {register, handleSubmit, errors, setError} = useForm<
    IEditProposalCodeFormData
  >({resolver: validate});

  const selector = formValueSelector(formName);
  const codeValue = useSelector<IRootState, string>((state) =>
    selector(state, "code")
  );

  const submit = async (values: IEditProposalCodeFormData) => {
    try {
      await onSubmit(values);
      dispatch(change(formName, "code", values.code));
      toggle();
    } catch (e) {
      setError("code", {type: "format", message: e.errors.code});
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <Form onSubmit={handleSubmit(submit)}>
        <ModalHeader toggle={toggle}>Aggiorna numero proposta</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="proposal-code">Numero proposta</Label>
            <Input
              defaultValue={codeValue}
              id="proposal-code"
              innerRef={register}
              name="code"
              type="text"
            />
            {errors?.code && (
              <FormFeedback className="d-block">
                {errors.code.message}
              </FormFeedback>
            )}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            type="submit"
            disabled={onSubmitStates.isPending}
          >
            {onSubmitStates.isPending && (
              <IconSpinner className="icon-spin mr-2" />
            )}
            Aggiorna
          </Button>
          <Button color="secondary" onClick={toggle}>
            Annulla
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditProposalCodeModal;
