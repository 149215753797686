import React from "react";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import {IconPerson, IconTimes} from "../Icons";
import IApiError from "../types/IApiError";
import {IStateUi, NewCapModalTypes} from "./";
import IdentificationForm, {IUploadIdFormData} from "./UploadIdForm";

interface IProps {
  closeIdentification: () => void;
  isUploadIdFail: boolean | undefined;
  ui: IStateUi;
  uploadIdError: string | undefined;
  uploadIdFail: (error: IApiError) => void;
  uploadIdSubmit: (values: IUploadIdFormData) => Promise<void>;
}

const UploadIdModal: React.FC<IProps> = (props) => (
  <Modal
    isOpen={props.ui.isModalOpen[NewCapModalTypes.UploadId]}
    toggle={props.closeIdentification}
    backdrop="static"
    keyboard={false}
    className="modal-form"
  >
    <ModalHeader
      toggle={props.closeIdentification}
      close={
        <Button
          size="sm"
          className="close-modal"
          onClick={props.closeIdentification}
        >
          <IconTimes size={1.25} className="icon-border-2 rounded-circle p-1" />
        </Button>
      }
    >
      <IconPerson size={3} className="icon-border-2 rounded-circle p-2" />
      Identificazione del cliente
    </ModalHeader>
    <ModalBody>
      <IdentificationForm {...props} />
    </ModalBody>
  </Modal>
);

export default UploadIdModal;
