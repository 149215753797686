import React, {VFC} from "react";
import {FieldError, UseFormMethods} from "react-hook-form";
import {FormFeedback, FormGroup, Input, InputProps, Label} from "reactstrap";
import get from "lodash/get";

interface RhfRadioProps extends InputProps {
  errors?: UseFormMethods["errors"];
  formState?: UseFormMethods["formState"];
  label: string;
  name: string;
  register: UseFormMethods["register"];
  children: Array<React.ReactElement<{value: string; children: string}>>; // JSX.Element[];
}

export const RhfRadio: VFC<RhfRadioProps> = ({
  children,
  errors,
  formState,
  label,
  name,
  register,
  ...rest
}) => {
  const error = errors && (get(errors, name) as FieldError | undefined);
  const touched =
    !!formState && (get(formState.touched, name) as boolean | undefined);

  const radios = Array.isArray(children) ? children : [children];

  return (
    <FormGroup tag="fieldset">
      <Label>{label}</Label>
      {radios.map((radio) => (
        <FormGroup key={radio.props.children + "-" + radio.props.value} check>
          <Input
            type="radio"
            value={radio.props.value}
            name={name}
            id={`${name}-${radio.props.value}`}
            innerRef={register}
            invalid={!!error}
            valid={touched && !error}
            {...rest}
          />
          <Label for={`${name}-${radio.props.value}`} check>
            {radio.props.children}
          </Label>
        </FormGroup>
      ))}
      {error && (
        <FormFeedback className="d-block">{error.message}</FormFeedback>
      )}
    </FormGroup>
  );
};
