export const getLSUser = () => {
  const initUser = JSON.parse(
    localStorage.getItem(
      process.env.REACT_APP_VERSION! +
        "-" +
        process.env.REACT_APP_LOCALSTORAGE_USER_KEY!
    ) || '{"user": null, "permissions": []}'
  );

  return initUser;
};
