import React from "react";
import {Card} from "reactstrap";
import {ContractorTable} from "../../Caps";
import {IconPerson} from "../../Icons";
import {IUser} from "../../Users";

interface IProps {
  contractor: IUser;
}

const PersonDetails = ({contractor}: IProps) => (
  <Card body color="drawer auto-margin-3">
    <h3>
      <IconPerson title="Anagrafica cliente" /> Anagrafica
    </h3>
    <ContractorTable contractor={contractor} />
  </Card>
);

export default PersonDetails;
