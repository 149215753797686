import React from "react";
import {IconBan, IconCheckmark} from "../../Icons";
import {IDeliverySnapshot} from "../types";

interface IProps {
  delivery: IDeliverySnapshot;
  hasIbips: boolean;
  hasLifeNotIbips: boolean;
  hasLoss: boolean;
}

const DeliveryDetails = ({
  delivery,
  hasIbips,
  hasLifeNotIbips,
  hasLoss,
}: IProps) => (
  <div>
    <p>
      {hasLifeNotIbips ? (
        delivery.values?.lifeNotIbips ? (
          <IconCheckmark className="text-success" />
        ) : (
          <IconBan className="text-danger" />
        )
      ) : (
        <IconBan className="text-secondary" />
      )}{" "}
      Prodotti assicurativi del Ramo Vita diversi dai Prodotti di Investimento
      Assicurativo
    </p>
    <ol type="I">
      <li>DIP Vita;</li>
      <li>DIP Aggiuntivo Vita o DIP Aggiuntivo Multirischi;</li>
      <li>Condizioni di assicurazione comprensive del glossario;</li>
      <li>Modulo di proposta e, ove non previsto, Modulo di polizza.</li>
    </ol>
    <p>
      {hasIbips ? (
        delivery.values?.ibips ? (
          <IconCheckmark className="text-success" />
        ) : (
          <IconBan className="text-danger" />
        )
      ) : (
        <IconBan className="text-secondary" />
      )}{" "}
      Prodotti di Investimento Assicurativo
    </p>
    <ol type="I">
      <li>Documento contenente le informazioni chiave (KID);</li>
      <li>DIP Aggiuntivo IBIP;</li>
      <li>Condizioni di assicurazione comprensive del glossario;</li>
      <li>Modulo di proposta e, ove non previsto, Modulo di polizza.</li>
    </ol>
    <p>
      {hasLoss ? (
        delivery.values?.loss ? (
          <IconCheckmark className="text-success" />
        ) : (
          <IconBan className="text-danger" />
        )
      ) : (
        <IconBan className="text-secondary" />
      )}{" "}
      Prodotti assicurativi del Ramo Danni
    </p>
    <ol type="I">
      <li>DIP;</li>
      <li>DIP Aggiuntivo Danni o DIP Aggiuntivo R.C. Auto;</li>
      <li>Condizioni di assicurazione comprensive del glossario;</li>
      <li>Modulo di proposta e, ove non previsto, Modulo di polizza.</li>
    </ol>
    <p>Luogo: {delivery.values?.place}</p>
  </div>
);

export default DeliveryDetails;
