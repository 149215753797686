import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Modal, ModalBody, ModalHeader} from "reactstrap";
import {
  Actions,
  actions as capsActions,
  dehydrateCap,
  ICap,
  ICapEntity,
  NewCapModalTypes,
} from "../Caps";
import IdentificationDetails from "../Caps/IdentificationDetails";
import {getCapsUi} from "../Caps/selectors";
import {IUploadIdFormData} from "../Caps/UploadIdForm";
import {actions as eSignActions} from "../ESign";
import {PDFTypes} from "../ESign/types";
import isIdValid from "../helpers/isIdValid";
import IApiError from "../types/IApiError";
import CapIdentificationDetails from "./CapIdentificationDetails";

interface IProps {
  cap: ICap;
  isAdvisor: boolean;
  isContractor: boolean;

  closeModal?: (modalType: NewCapModalTypes) => Actions;
  disableUpload?: boolean;
  isUploadIdFail?: boolean;
  openModal?: (modalType: NewCapModalTypes) => Actions;
  uploadIdError?: string;
  uploadIdFail?: (error: IApiError) => void;
  uploadIdSubmit?: (
    userId: string,
    cap: ICapEntity,
    values: IUploadIdFormData
  ) => Promise<void>;
}

const CapIdentification = ({
  cap,
  isAdvisor,
  isContractor,

  closeModal,
  disableUpload,
  isUploadIdFail,
  openModal,
  uploadIdError,
  uploadIdFail,
  uploadIdSubmit,
}: IProps) => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (isContractor && !isIdValid(cap.onboarding?.identification, isAdvisor)) {
      setIsModalOpen(true);
    } else {
      setIsModalOpen(false);
    }
  }, [cap.onboarding, isAdvisor, isContractor]);

  const capUi = useSelector(getCapsUi);

  const openRequestOTPIdentificationModal = React.useCallback(
    (capId: string) => {
      dispatch(eSignActions.openModal(PDFTypes.identification, capId));
    },
    [dispatch]
  );

  const getItemEntity = React.useCallback(() => {
    if (!cap) {
      return cap;
    }

    const {
      result,
      entities: {caps},
    } = dehydrateCap(cap);
    return caps[result];
  }, [cap]);

  const getContractorEntity = React.useCallback(() => {
    if (!cap) {
      return cap;
    }

    const {
      result,
      entities: {caps, users},
    } = dehydrateCap(cap);
    const itemEntity = caps[result];
    return users[itemEntity.contractor];
  }, [cap]);

  const identificationSubmit = React.useCallback(async () => {
    const itemEntity = getItemEntity();
    await capsActions.confirmIdentification(
      itemEntity,
      getContractorEntity()
    )(dispatch);

    dispatch(eSignActions.openModal(PDFTypes.identification, itemEntity.id));
  }, [dispatch, getContractorEntity, getItemEntity]);

  return (
    <>
      <CapIdentificationDetails
        chosenCap={cap}
        disableUpload={disableUpload}
        isAdvisor={isAdvisor}
        isContractor={isContractor}
        capUi={capUi}
        identificationSubmit={identificationSubmit}
        openRequestOTPIdentificationModal={openRequestOTPIdentificationModal}
        closeModal={closeModal}
        isUploadIdFail={isUploadIdFail}
        openModal={openModal}
        uploadIdError={uploadIdError}
        uploadIdFail={uploadIdFail}
        uploadIdSubmit={uploadIdSubmit}
      />
      <Modal isOpen={isModalOpen} size="xl" centered>
        <ModalHeader>Conferma l'identificazione</ModalHeader>
        <ModalBody className="auto-margin-3">
          <IdentificationDetails
            cap={cap}
            contractor={cap.contractor}
            details={cap.onboarding?.identification}
            isAdvisor={isAdvisor}
            isContractor={isContractor}
            isIdentificationPending={capUi?.isIdentificationPending}
            identificationError={capUi?.identificationError}
            identificationSubmit={identificationSubmit}
            openRequestOTPIdentificationModal={
              openRequestOTPIdentificationModal
            }
          />
          <Alert color="warning">
            Per firmare la raccomandazione, è necessario confermare con una
            firma la tua identificazione
          </Alert>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CapIdentification;
