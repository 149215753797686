import React from "react";
import {Alert, Col, Row} from "reactstrap";
import {IconLock, IconUnlock} from "../Icons";

interface IProps {
  drawers: {[key: string]: () => 0 | 1};
  children: (count: {complete: number; total: number}) => React.ReactNode;
  showLock?: boolean;
}

const SectionCounter: React.FC<IProps> = ({drawers, children, showLock}) => {
  const complete = Object.keys(drawers).reduce(
    (tot, key) => tot + drawers[key](),
    0
  );
  const total = Object.keys(drawers).length;

  return (
    <div className="text-center">
      <Alert
        color={complete === total ? "success" : "danger"}
        className="d-inline-block text-left mb-0"
      >
        <Row className="align-items-center">
          {showLock !== false && (
            <Col xs="auto">
              {complete === total ? (
                <IconUnlock size={2.75} className="p-0" />
              ) : (
                <IconLock size={2.75} className="p-0" />
              )}
            </Col>
          )}
          <Col xs="auto">{children({complete, total})}</Col>
        </Row>
      </Alert>
    </div>
  );
};

export default SectionCounter;
