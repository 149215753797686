import React from "react";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import {IconPerson, IconTimes} from "../Icons";
import {IUser} from "../Users";
import {IStateUi, NewCapModalTypes} from "./";
import CreateContractorForm, {
  ICreateContractorFormData,
} from "./CreateContractorForm";

interface IProps {
  closeCreateContractorModal: () => void;
  contractor?: IUser;
  createContractorReset: () => void;
  createContractorSubmit: (values: ICreateContractorFormData) => Promise<void>;
  errorExistingUserId?: string;
  loggedUser: IUser;
  ui: IStateUi;
  useExistingContractorSubmit: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => Promise<void>;
}

const CreateContractorModal: React.FC<IProps> = (props) => (
  <Modal
    isOpen={props.ui.isModalOpen[NewCapModalTypes.CreateContractor]}
    toggle={props.closeCreateContractorModal}
    backdrop="static"
    keyboard={false}
    className="modal-form"
  >
    <ModalHeader
      toggle={props.closeCreateContractorModal}
      close={
        <Button
          size="sm"
          className="close-modal"
          onClick={props.closeCreateContractorModal}
        >
          <IconTimes size={1.25} className="icon-border-2 rounded-circle p-1" />
        </Button>
      }
    >
      <IconPerson size={3} className="icon-border-2 rounded-circle p-2" />
      Area personale del cliente
    </ModalHeader>
    <ModalBody>
      <CreateContractorForm {...props} />
    </ModalBody>
  </Modal>
);

export default CreateContractorModal;
