import {connect} from "react-redux";
import {IRootState} from "../redux/reducers";
import {actions as userActions} from "../User/actions";
import {getUser} from "../User/selectors";
import {actions} from "./actions";
import {IInsertPhoneFormData} from "./InsertPhoneForm";
import RequestOTPModal from "./RequestOTPModal";
import {
  getAdditionalParams,
  getESign,
  getIsModalOpen,
  getOpenedTransaction,
  getPDFType,
  getReferenceId,
  getThunkActionStates,
} from "./selectors";
import {ISignFEADocRequestData} from "./types";

const mapStateToProps = (state: IRootState) => ({
  additionalParams: getAdditionalParams(state),
  createFEATransactionStates: getThunkActionStates(
    state,
    "createFEATransaction"
  ),
  isModalOpen: getIsModalOpen(state),
  isProfileFail: state.user.isProfileFail,
  isProfilePending: state.user.isProfilePending,
  openedTransaction: getESign(state, getOpenedTransaction(state)),
  pdfType: getPDFType(state),
  profileError: state.user.profileError,
  referenceId: getReferenceId(state),
  signFEADocStates: getThunkActionStates(state, "signFEADoc"),
  signingUser: getUser(state),
});

const mapDispatchToProps = {
  addPersonalPhone: (values: IInsertPhoneFormData) =>
    userActions.profile(values),
  cancelESign: () => actions.cancelESign(),
  createFEATransaction: () => actions.createFEATransaction(),
  signFEADoc: (data: ISignFEADocRequestData) => actions.signFEADoc(data),
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestOTPModal);
