import classNames from "classnames";
import React from "react";
import {Link} from "react-router-dom";
import {Button, UncontrolledAlert} from "reactstrap";

const ActivateAlert: React.FC<{className?: string}> = (props) => (
  <UncontrolledAlert
    color="warning"
    className={classNames(
      "rounded-0 border-top-0 border-left-0 border-right-0",
      props.className
    )}
  >
    Se stai accedendo per la prima volta, devi attivare il tuo account
    <Link to="/activate" className="ml-3">
      <Button color="dark" outline>
        Inizia subito
      </Button>
    </Link>
  </UncontrolledAlert>
);

export default ActivateAlert;
