import React from "react";
import FormatNumber from "../helpers/FormatNumber";
import SectionCounter from "./SectionCounter";
import {ICap} from "./types";

interface IProps {
  cap?: ICap;
}

const OnboardingCounter: React.FC<IProps> = ({cap}) => {
  const drawers = {
    contractor: () => (cap && cap.contractor ? 1 : 0),
    privacy: () => (cap && cap.contractor.lastPrivacyEsignId ? 1 : 0),
  };

  return (
    <SectionCounter drawers={drawers}>
      {({complete, total}) => (
        <span>
          {complete === total ? "Hai" : "Non hai"} completato la sezione
          Onboarding
          <br />
          Avanzamento: <FormatNumber>{(complete / total) * 100}</FormatNumber>%
          ({complete} moduli su {total})
        </span>
      )}
    </SectionCounter>
  );
};

export default OnboardingCounter;
