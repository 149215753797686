import React from "react";
import {connect} from "react-redux";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  Label,
  Row,
} from "reactstrap";
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import {dehydrateCap, ICap, ICapEntity, IStateUi} from ".";
import domandeObiettivi, {
  TargetsQuestionsKeys,
} from "../helpers/domandeObiettivi";
import RenderSlider from "../helpers/RenderSlider";
import IconSpinner from "../Icons/IconSpinner";
import {IRootState} from "../redux/reducers";

export interface ITargetFormData {
  obj1: string;
  obj2: string;
  obj3: string;
  objValue1: number;
  objValue2: number;
  objValue3: number;
  dom1: number;
  dom2: number;
  dom3: number;
  dom4: number;
  dom5: number;
  dom6: number;
  dom7: number;
  dom8: number;
  dom9: number;
  dom10: number;
}

type ITargetFormErrors = {[K in TargetsQuestionsKeys]?: string};

interface IOwnProps {
  cap: ICap;
  closeTarget: () => void;
  targetSubmit: (values: ITargetFormData, cap: ICapEntity) => Promise<void>;
  ui: IStateUi;
}

type IProps = InjectedFormProps<ITargetFormData, IOwnProps> & IOwnProps;

const validate = (values: ITargetFormData) => {
  const errors: ITargetFormErrors = {};
  if (!values.dom1) {
    errors.dom1 = "Questa risposta è obbligatoria";
  }
  if (!values.dom2) {
    errors.dom2 = "Questa risposta è obbligatoria";
  }
  if (!values.dom3) {
    errors.dom3 = "Questa risposta è obbligatoria";
  }
  if (!values.dom4) {
    errors.dom4 = "Questa risposta è obbligatoria";
  }
  if (!values.dom5) {
    errors.dom5 = "Questa risposta è obbligatoria";
  }
  if (!values.dom6) {
    errors.dom6 = "Questa risposta è obbligatoria";
  }
  if (!values.dom7) {
    errors.dom7 = "Questa risposta è obbligatoria";
  }
  if (!values.dom8) {
    errors.dom8 = "Questa risposta è obbligatoria";
  }
  if (!values.dom9) {
    errors.dom9 = "Questa risposta è obbligatoria";
  }
  if (!values.dom10) {
    errors.dom10 = "Questa risposta è obbligatoria";
  }

  return errors;
};

const TargetForm: React.FC<IProps> = (props) => {
  const submitWithCap = async (values: ITargetFormData) => {
    const {
      result,
      entities: {caps},
    } = dehydrateCap(props.cap);
    await props.targetSubmit(values, caps[result]);
  };
  return (
    <Form onSubmit={props.handleSubmit(submitWithCap)}>
      <Card className="mb-3">
        <CardHeader className="bg-primary text-white">
          Quanto desidera...
        </CardHeader>
        <CardBody>
          {domandeObiettivi.map((domanda) => (
            <React.Fragment key={domanda.inputId}>
              <hr />
              <Row className="align-item-center">
                <Col>
                  <Label>{domanda.question}</Label>
                </Col>
                <Col className="d-flex">
                  <span className="mr-3">Pochissimo</span>
                  <Field
                    id={domanda.inputId}
                    name={domanda.inputName}
                    marks={{
                      1: "1",
                      2: "2",
                      3: "3",
                      4: "4",
                      5: "5",
                      6: "6",
                      7: "7",
                    }}
                    min={1}
                    max={7}
                    component={RenderSlider}
                  />{" "}
                  Tantissimo
                </Col>
              </Row>
            </React.Fragment>
          ))}
        </CardBody>
      </Card>
      {props.ui.isTargetFail && (
        <Alert color="danger">{props.ui.targetError}</Alert>
      )}
      <Button type="submit" color="primary" outline disabled={props.submitting}>
        {props.submitting ? <IconSpinner className="icon-spin" /> : ""} Salva e
        torna all'analisi
      </Button>{" "}
      <Button
        type="button"
        color="secondary"
        outline
        onClick={props.closeTarget}
      >
        Annulla
      </Button>{" "}
    </Form>
  );
};

const TargetFormConnected = reduxForm<ITargetFormData, IOwnProps>({
  form: "targetForm",
  validate,
})(TargetForm);

export default connect((state: IRootState, ownProps: IOwnProps) => {
  let initialValues = ownProps.cap!.data.targets;

  if (!initialValues) {
    initialValues = {
      objValue1: 1,
      objValue2: 1,
      objValue3: 1,
    } as ITargetFormData;
    domandeObiettivi.forEach((domanda) => {
      initialValues![domanda.inputName] = 1;
    });
  }

  return {
    initialValues,
  };
})(TargetFormConnected);
