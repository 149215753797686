import React from "react";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import {IconEmployment, IconTimes} from "../Icons";
import {ICap, ICapEntity, IStateUi, NewCapModalTypes} from "./";
import EmploymentForm, {IEmploymentFormData} from "./EmploymentForm";

interface IProps {
  closeEmployment: () => void;
  ui: IStateUi;

  employmentSubmit: (
    values: IEmploymentFormData,
    cap: ICapEntity
  ) => Promise<void>;
  cap: ICap;
}

const EmploymentsModal: React.FC<IProps> = (props) => (
  <Modal
    isOpen={props.ui.isModalOpen[NewCapModalTypes.Employment]}
    toggle={props.closeEmployment}
    backdrop="static"
    keyboard={false}
    className="modal-form"
  >
    <ModalHeader
      toggle={props.closeEmployment}
      close={
        <Button
          size="sm"
          className="close-modal"
          onClick={props.closeEmployment}
        >
          <IconTimes size={1.25} className="icon-border-2 rounded-circle p-1" />
        </Button>
      }
    >
      <IconEmployment size={3} className="icon-border-2 rounded-circle p-2" />
      Situazione professionale
    </ModalHeader>
    <ModalBody>
      <EmploymentForm {...props} />
    </ModalBody>
  </Modal>
);

export default EmploymentsModal;
