import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {IconCheckCircle, IconClock} from "../Icons";

export const ColumnOnboardingFilter = ({filter, onChange}: any) => (
  <UncontrolledDropdown>
    <DropdownToggle
      caret
      size="sm"
      block
      color="primary"
      outline
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {(!filter || !filter.value) && <span>Tutti</span>}
      {filter && filter.value === "valid" && (
        <span>
          <IconCheckCircle color="success" /> Completato
        </span>
      )}
      {filter && filter.value === "esign-privacy-less" && (
        <span>
          <IconClock />{" "}
          <span style={{fontSize: "80%"}}>Consenso da firmare</span>
        </span>
      )}
    </DropdownToggle>
    <DropdownMenu>
      <DropdownItem onClick={onChange.bind({}, undefined)}>Tutti</DropdownItem>
      <DropdownItem onClick={onChange.bind({}, "valid")}>
        <IconCheckCircle color="success" /> Completato
      </DropdownItem>
      <DropdownItem onClick={onChange.bind({}, "esign-privacy-less")}>
        <IconClock color="primary" /> Consenso da firmare
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);
