const categoryTax: {[key: string]: number} = {
  aggiuntaLordo: 0.2,
  altro: 0.26,
  artigiano: 0.24,
  cassa: 0.15,
  commerciante: 0.24,
  dipendente: 0.33,
  subordinato: 0.33,
};

export default categoryTax;
