import last from "lodash/last";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Button, Container} from "reactstrap";
import {Layout} from "../App";
import {IconDownload, IconSpinner} from "../Icons";
import {IRootState} from "../redux/reducers";
import {IPrivacySubscriptionHistory, IUserDB} from "../Users";
import {actions} from "./";
import PrivacySubscriptionForm from "./PrivacySubscriptionForm";

import "./Profile.scss";
import {getPrivacySubscription, getUser} from "./selectors";

const PrivacyPage = () => {
  const dispatch = useDispatch();

  const privacySubscription = useSelector((state: IRootState) =>
    getPrivacySubscription(state)
  );
  const user = useSelector((state: IRootState) => getUser(state)!);

  useEffect(() => {
    console.count("Controllo effect");
  }, []);
  console.count("Controllo render");
  useEffect(() => {
    console.count("Carico privacy");
    const loadPrivacy = async () => {
      await dispatch(actions.loadPrivacy());
    };
    loadPrivacy().then();
  }, [dispatch]);

  const userPrivacySubscription = ((user as unknown) as IUserDB)
    ?.jsonPrivacySubscription;
  const privacySubscriptionHistory = userPrivacySubscription
    ? (JSON.parse(userPrivacySubscription) as IPrivacySubscriptionHistory)
    : undefined;
  const lastPrivacySubscriptionHistory =
    privacySubscriptionHistory && last(privacySubscriptionHistory);
  const privacySubscriptionInitialValues =
    lastPrivacySubscriptionHistory?.values;

  console.log({lastPrivacySubscriptionHistory});

  const isUpdating = !!(
    privacySubscription &&
    lastPrivacySubscriptionHistory &&
    parseInt(privacySubscription.version, 10) >
      parseInt(lastPrivacySubscriptionHistory.version, 10)
  );

  return (
    <Layout isProtected>
      <Container>
        <h2>
          Privacy sottoscrizione di {user && user.name} {user && user.surname}
        </h2>
      </Container>
      <Container className="container-max auto-margin-4 p-md-5">
        {privacySubscription ? (
          <PrivacySubscriptionForm
            className="con-scroll"
            form="privacySubscriptionForm"
            isUpdating={isUpdating}
            initialValues={privacySubscriptionInitialValues}
            privacy={privacySubscription}
          />
        ) : (
          <div className="text-center">
            <IconSpinner className="icon-spin mr-2 my-4" />
            Caricamento privacy sottoscrizione in corso...
          </div>
        )}
        {user.lastPrivacySubscriptionEsignId && (
          <Alert color="success">
            Il consenso alla sottoscrizione è firmato
          </Alert>
        )}
        <div className="d-flex">
          <Button
            className="mr-2"
            color="primary"
            outline
            download
            disabled={!user.lastPrivacySubscriptionEsignId}
            href={`${process.env.REACT_APP_API_BASE_URL}users/${user.id}/get-pdf-privacy-subscription`}
          >
            <IconDownload /> Scarica il pdf della sottoscrizione
          </Button>
        </div>
      </Container>
    </Layout>
  );
};

export default PrivacyPage;
