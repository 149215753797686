import moment from "moment";
import {denormalize, normalize, schema} from "normalizr";
import {
  dehydrateCapProperties,
  hydrateCapProperties,
  ICap,
  ICapDB,
  ICapsEntities,
  IInvestmentOption,
} from "../Caps";
import {IUser, IUserDB} from "../Users";
import {
  dehydrateUserProperties,
  hydrateUserProperties,
  IUsersEntities,
} from "../Users";

export enum RecommendationStatus {
  STATUS_TO_BE_VALIDATED = "0", // Inviato per l'approvazione
  STATUS_CORRESPONDING = "1", // Congrua
  STATUS_WAIT_REVIEW = "2", // Richiesta revisione
  STATUS_NOT_VALIDATE = "3", // Non validabile
  STATUS_IBIPS_REQUEST = "4", // Richiesto rinnovo IBIPs
  STATUS_REQUEST_VERIFICATION = "5", // Verifica adeguatezza richiesta
  STATUS_REQUEST_ESIGN = "6", // Manca la firma del contractor
  STATUS_REJECTED = "7", // Raccomandazione archiviata (i.e. è stata duplicata ed ora è solo in consultazione)
  STATUS_REQUEST_ESIGN_ADVISOR = "8", // Manca la firma dell'Advisor
}

export interface IRevision {
  date: moment.Moment;
  text: string;
}

export interface RecomandationDocs {
  flagReadAllegato4Bis: string;
  flagReadSetInformativo: string;
  urlSetInformativo: string;
}

// È l'interfaccia delle raccomandazioni salvate nello store redux
//  i.e. senza tipi e con gli id delle altre entità
export interface IRecommendationEntity {
  cap: string;
  code: string;
  comment: string;
  contractor: string;
  date: string;
  esignId?: string;
  userEsignId?: string;
  id: string;
  jsonDocs: string | null;
  jsonOptions: string;
  jsonRevisions?: string;
  status: RecommendationStatus;
  user: string;
}
// È l'interfaccia delle raccomandazioni come arrivano dal db
//  i.e. senza tipi ma con le altre entità esplicitate
export interface IRecommendationDB {
  cap: ICapDB;
  code: string;
  comment: string;
  contractor: IUserDB;
  date: string;
  esignId?: string;
  userEsignId?: string;
  id: string;
  jsonDocs: string | null;
  jsonOptions: string;
  jsonRevisions?: string;
  status: RecommendationStatus;
  user: IUserDB;
}
// È l'interfaccia delle raccomandazioni idratate
//  i.e. con i tipi e con le altre entità esplicitate
export interface IRecommendation {
  cap: ICap;
  code: string;
  comment: string;
  contractor: IUser;
  date: moment.Moment;
  docs: RecomandationDocs | null;
  esignId?: string;
  userEsignId?: string;
  id: string;
  options: Array<{option: IInvestmentOption; percent: number}>;
  revisions: IRevision[];
  status: RecommendationStatus;
  user: IUser;
}

export interface IRecommendationParams {
  limit: number;
  name?: string;
  offset: number;
  contractors?: string;
  user?: string;
  code?: string;
  status?: string;
  sort?: string;
}

export interface IDehydratedRecommendations {
  result: string[];
  entities: {
    caps: ICapsEntities;
    recommendations: IRecommendationsEntities;
    users: IUsersEntities;
  };
}
export interface IDehydratedRecommendation {
  result: string;
  entities: {
    caps: ICapsEntities;
    recommendations: IRecommendationsEntities;
    users: IUsersEntities;
  };
}

const userEntity = new schema.Entity("users");
const capEntity = new schema.Entity("caps");
const recommendationEntity = new schema.Entity("recommendations", {
  cap: capEntity,
  contractor: userEntity,
  user: userEntity,
});
export const recommendationSchema = recommendationEntity;

export interface IRecommendationsEntities {
  [key: string]: IRecommendationEntity;
}

const dehydrateRecommendationProperties = (
  recommendationGeneric: IRecommendation
) => {
  const {
    cap,
    contractor,
    date,
    docs,
    options,
    revisions,
    user,
    ...rest
  } = recommendationGeneric;
  const recommendation: IRecommendationDB = {
    ...rest,
    cap: dehydrateCapProperties(cap),
    contractor: dehydrateUserProperties(contractor),
    date: date.format("LL"),
    jsonDocs: docs ? JSON.stringify(docs) : null,
    jsonOptions: JSON.stringify(options),
    jsonRevisions: JSON.stringify(revisions),
    user: dehydrateUserProperties(user),
  };

  return recommendation;
};

export function normalizeRecommendation(
  recommendation: IRecommendationDB
): IDehydratedRecommendation;
export function normalizeRecommendation(
  recommendation: IRecommendationDB[]
): IDehydratedRecommendations;
export function normalizeRecommendation(
  recommendation: IRecommendationDB | IRecommendationDB[]
) {
  if (Array.isArray(recommendation)) {
    return normalize(recommendation, [recommendationSchema]);
  } else {
    return normalize(recommendation, recommendationSchema);
  }
}

export function dehydrateRecommendation(
  recommendation: IRecommendation
): IDehydratedRecommendation;
export function dehydrateRecommendation(
  recommendation: IRecommendation[]
): IDehydratedRecommendations;
export function dehydrateRecommendation(
  recommendation: IRecommendation | IRecommendation[]
) {
  if (Array.isArray(recommendation)) {
    // Risultato multiplo
    return normalizeRecommendation(
      recommendation.map(dehydrateRecommendationProperties)
    );
  } else {
    // Risultato singolo
    return normalizeRecommendation(
      dehydrateRecommendationProperties(recommendation)
    );
  }
}

const hydrateRevision = (revision: {date: string; text: string}) => ({
  ...revision,
  date: moment(revision.date),
});
const hydrateRecommendationProperties = (
  recommendationGeneric: IRecommendationDB
) => {
  const {
    cap,
    contractor,
    date,
    jsonDocs,
    jsonOptions,
    jsonRevisions,
    user,
    ...rest
  } = recommendationGeneric;
  const recommendation: IRecommendation = {
    ...rest,
    cap: hydrateCapProperties(cap),
    contractor: hydrateUserProperties(contractor),
    date: moment(date),
    docs: jsonDocs ? (JSON.parse(jsonDocs) as RecomandationDocs) : null,
    options: JSON.parse(jsonOptions),
    revisions: jsonRevisions
      ? JSON.parse(jsonRevisions).map(hydrateRevision)
      : [],
    user: hydrateUserProperties(user),
  };

  return recommendation;
};

export function hydrateRecommendation({
  result,
  entities,
}: IDehydratedRecommendation): IRecommendation;
export function hydrateRecommendation({
  result,
  entities,
}: IDehydratedRecommendations): IRecommendation[];
export function hydrateRecommendation({
  result,
  entities,
}: IDehydratedRecommendation | IDehydratedRecommendations):
  | IRecommendation
  | IRecommendation[] {
  if (typeof result === "string") {
    // Risultato singolo
    return hydrateRecommendationProperties(
      denormalize(result, recommendationEntity, entities)
    );
  } else {
    // Risultato multiplo
    return denormalize(result, [recommendationEntity], entities).map(
      hydrateRecommendationProperties
    );
  }
}
