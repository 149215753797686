import {faFileSignature} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import isEmpty from "lodash/isEmpty";
import moment from "moment";
import React from "react";
import {
  FieldErrors,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import {Card, Form} from "reactstrap";
import {DeepPartial} from "utility-types";
import {dateIt} from "../../helpers/validators";
import ChoseProductLevel from "./ChoseProductLevel";
import {IRCFormData, validation as rcValidation} from "./ChoseProductLevel/Rc";
import ChoseProductType from "./ChoseProductType";

interface IProps {
  defaultValues?: DeepPartial<INewBeneProposalFormData>;
  houseDisabled?: boolean;
  injuriesDisabled?: boolean;
  isView?: boolean;
  onSubmit: SubmitHandler<INewBeneProposalFormData>;
}

export type IBeneLevel = "silver" | "gold" | "platinum";
export type IBeneType = "house" | "injuries" | "rc";
export type INewBeneProposalFormData = {
  type: IBeneType;
  level: IBeneLevel;
  rc: IRCFormData;
  dateEffect: string;
};

const validate = async (values: INewBeneProposalFormData) => {
  const errors: FieldErrors<INewBeneProposalFormData> = {};

  // Cerco di capire perché ogni tanto dateEffect è undefined
  if (
    process.env.NODE_ENV === "development" &&
    !values.dateEffect === undefined
  ) {
    console.trace("dateEffect è vuoto");
    debugger;
  }
  const cleanDateEffect = values.dateEffect?.replace(/_/g, "");

  if (!values.type) {
    errors.type = {
      type: "required",
      message: "Scegli un tipo di Polizza Bene Assicurazioni",
    };
  }

  if (values.type === "rc") {
    // RC professionale con la sua validazione
    const errorsRc = rcValidation(values.rc).errors;
    if (!isEmpty(errorsRc)) {
      errors.rc = errorsRc;
    }
  } else {
    // Home & Injuries richiedono solo level
    if (!values.level) {
      errors.level = {
        type: "required",
        message: "Scegli un pack di Polizza Bene Assicurazioni",
      };
    }
  }

  if (!values.dateEffect) {
    errors.dateEffect = {
      type: "required",
      message: "Indica la data di inizio validità",
    };
  } else if (
    !dateIt(cleanDateEffect) ||
    !moment(cleanDateEffect, "DD/MM/YYYY").isValid()
  ) {
    errors.dateEffect = {
      type: "invalid",
      message:
        "La data di inizio validità non è nel formato corretto gg/mm/aaaa",
    };
  } else if (
    moment(cleanDateEffect, "DD/MM/YYYY").isBefore(moment().subtract(1, "day"))
  ) {
    errors.dateEffect = {
      type: "invalid",
      message: `Non è possibile inserire una data di inizio validità precedente a oggi ${moment().format(
        "DD/MM/YYYY"
      )}`,
    };
  } else if (
    moment(cleanDateEffect, "DD/MM/YYYY").isAfter(moment().add(60, "days"))
  ) {
    errors.dateEffect = {
      type: "invalid",
      message: `La data di inizio validità inserita è successiva alla data massima ${moment()
        .add(60, "days")
        .format("DD/MM/YYYY")}`,
    };
  }

  return {values: isEmpty(errors) ? values : {}, errors};
};

const NewBeneProposalForm = ({
  defaultValues,
  houseDisabled,
  injuriesDisabled,
  isView,
  onSubmit,
}: IProps) => {
  const methods = useForm<INewBeneProposalFormData>({
    mode: "onBlur",
    resolver: validate,
    defaultValues,
  });

  const {handleSubmit, watch} = methods;
  const type = watch("type");

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Card body color="drawer" className="auto-margin-3">
          <h3>
            <FontAwesomeIcon icon={faFileSignature} title="Polizza bene" />{" "}
            Scegli il tuo Digital Package Bene Assicurazioni
          </h3>
          <p>
            I digital package di bene assicurazioni sono dedicati a chi vuol
            proteggere il proprio patrimonio e i propri cari. Oltre alle
            classiche coperture, forniscono anche soluzioni di servizio, quali
            assistenza e tutela legale. La sottoscrizione è semplice e veloce
            grazie alla firma OTP e al pagamento online. Il tutto con pochi euro
            al mese!
          </p>
          <ChoseProductType
            houseDisabled={houseDisabled}
            injuriesDisabled={injuriesDisabled}
            isView={!!isView}
          />
          {type && <ChoseProductLevel isView={!!isView} />}
        </Card>
        {/*<DevTool control={control} />*/}
      </Form>
    </FormProvider>
  );
};

export default NewBeneProposalForm;
