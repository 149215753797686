import isEmpty from "lodash/isEmpty";
import React from "react";
import {FieldErrors, useForm} from "react-hook-form";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import {IconCheckmark} from "../Icons";
import {IAcceptFormData} from "../Proposals/View/ValidateButtons/AcceptModal";

interface IProps {
  isOpen: boolean;
  onConfirm: () => void;
  toggle: () => void;
}

const validate = async (values: IAcceptFormData) => {
  const errors: FieldErrors<IAcceptFormData> = {};

  if (!values.conferma) {
    errors.conferma = {
      type: "required",
      message: "Compila il campo per procedere con la conferma dei dati",
    };
  } else if (values.conferma !== "CONFERMO") {
    errors.conferma = {
      type: "mismatch",
      message: `Per confermare i dati devi scrivere nel campo "CONFERMO"`,
    };
  }

  return {values: isEmpty(errors) ? values : {}, errors};
};

const ManageContractorConfirmModal = ({isOpen, onConfirm, toggle}: IProps) => {
  const {register, handleSubmit, errors} = useForm<IAcceptFormData>({
    mode: "onBlur",
    resolver: validate,
  });

  return (
    <Modal centered isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>
        Conferma dei dati fondamentali per la firma elettronica
      </ModalHeader>
      <ModalBody>
        <p>
          Confermi che i dati inseriti sono stati ricontrollati, sono corretti e
          corrispondono a quelli riportati sul documento d'identità?
        </p>
        <p>
          Per confermare i dati scrivere "CONFERMO" e cliccare sul bottone
          "Conferma definitiva".
        </p>
        <Form onSubmit={handleSubmit(onConfirm)}>
          <Row>
            <Col>
              <FormGroup>
                <Input
                  type="text"
                  name="conferma"
                  innerRef={register}
                  invalid={!!errors?.conferma}
                />
                {errors?.conferma && (
                  <FormFeedback>{errors.conferma.message}</FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col xs="auto">
              <Button color="success" outline>
                <IconCheckmark />
                Conferma definitiva
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default ManageContractorConfirmModal;
