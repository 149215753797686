import React from "react";
import {IconLock} from "../../Icons";

const IdLockOverlay = () => (
  <div className="component-loader flex-column text-center">
    <IconLock size={4} /> Per procedere alla compilazione della raccomandazione
    personalizzata,
    <br />
    il cliente deve caricare un documento di identità valido.
  </div>
);

export default IdLockOverlay;
