import {createSelector} from "reselect";
import {IRootState} from "../redux/reducers";
import {getUser} from "../User/selectors";
import {getUsersEntities} from "../Users/selectors";
import {hydrateCap} from "./types";

export const getCapsEntities = (state: IRootState) => state.caps.entities;
export const getCapsListItems = (state: IRootState) => state.caps.list.items;
export const getIsListPending = (state: IRootState) =>
  state.caps.list.isPending;
export const getCapsUi = (state: IRootState) => state.caps.ui;

export const getParamsCapId = (state: IRootState, capId: string | undefined) =>
  capId;

export const getCap = createSelector(
  [getCapsEntities, getUsersEntities, getParamsCapId],
  (caps, users, capId) => {
    return !!capId && !!caps[capId]
      ? hydrateCap({
          entities: {caps, users},
          result: capId,
        })
      : undefined;
  }
);

export const getIsAdvisor = createSelector(
  [getCap, getUser],
  (cap, loggedUser) => {
    if (!cap || !loggedUser) {
      return false;
    }
    return cap.user.id === loggedUser.id;
  }
);

export const getIsContractor = createSelector(
  [getCap, getUser],
  (cap, loggedUser) => {
    if (!cap || !loggedUser) {
      return false;
    }
    return cap.contractor.id === loggedUser.id;
  }
);

export const getOptions = (state: IRootState) => state.caps.options ?? [];
export const selectCompanies = (state: IRootState) =>
  state.caps.companies ?? [];
export const getCities = (state: IRootState) => state.caps.cities ?? [];

export const getErrorExistingUserId = (state: IRootState) =>
  state.caps.newCap.errorExistingUserId;

export const getIsUploadIdFail = (state: IRootState) =>
  state.caps.ui.isUploadIdFail;
export const getUploadIdError = (state: IRootState) =>
  state.caps.ui.uploadIdError;
