import React from "react";
import {Col, FormGroup, Label, Row} from "reactstrap";
import {Field} from "redux-form";
import {birthdayFormatter} from "../helpers/formatters";
import {ucWordsNormalizer} from "../helpers/normalizers";
import RenderField from "../helpers/RenderField";
import RenderMultiField from "../helpers/RenderMultiField";
import {AutoSuggestCitiesReduxForm} from "./CompleteContractor/AutoSuggestCitiesReduxForm";

const PersonalData = (props: {
  genderValue?: "m" | "f";
  birthplaceValue?: string;
}) => (
  <>
    <FormGroup>
      <Label for="birthday">Data di nascita</Label>
      <Field
        id="birthday"
        name="birthday"
        component={RenderField}
        type="text"
        placeholder="Data di nascita (gg/mm/aaaa)"
        format={birthdayFormatter}
      />
    </FormGroup>
    <FormGroup>
      <Label for="birthplace">Luogo di nascita</Label>
      <Row>
        <Col>
          <Field
            id="birthplace"
            name="birthplace"
            placeholder="Luogo di nascita"
            component={AutoSuggestCitiesReduxForm}
            normalize={ucWordsNormalizer}
          />
        </Col>
        <Col xs="auto" style={{width: "90px"}}>
          <Field
            name="birthplaceCSiglaProvincia"
            readOnly
            component={RenderField}
            noValidate
          />
        </Col>
      </Row>
    </FormGroup>
    {props.birthplaceValue?.toLowerCase() === "estero" && (
      <FormGroup>
        <Label for="birthplaceMore">Specifica la selezione "Estero"</Label>
        <Field
          id="birthplaceMore"
          name="birthplaceMore"
          component={RenderField}
          type="text"
          placeholder={`Specifica la selezione "Estero"`}
          normalize={ucWordsNormalizer}
        />
      </FormGroup>
    )}
    <Label>Genere</Label>
    <RenderMultiField
      id="gender"
      name="gender"
      type="radio"
      items={[
        {value: "m", text: "Maschio"},
        {value: "f", text: "Femmina"},
      ]}
    />
    <FormGroup>
      <Label for="education">Livello di istruzione</Label>
      <Field
        id="education"
        name="education"
        type="select"
        component={RenderField}
      >
        <option value="">Seleziona</option>
        <option value="1">Nessun titolo di studio</option>
        <option value="2">Licenza scuola primaria/media</option>
        <option value="3">Diploma di scuola superiore</option>
        <option value="4">Laurea</option>
        <option value="5">
          Laurea o specializzazione post-universitaria in campo economico
          finanziario
        </option>
      </Field>
    </FormGroup>
    <FormGroup>
      <Label for="maritalStatus">Stato civile</Label>
      <Field
        id="maritalStatus"
        name="maritalStatus"
        component={RenderField}
        type="select"
      >
        <option value="">Seleziona</option>
        <option value="celibe">
          {props.genderValue
            ? props.genderValue === "m"
              ? "Celibe"
              : "Nubile"
            : "Celibe / Nubile"}
        </option>
        <option value="coniugato">
          {props.genderValue
            ? props.genderValue === "m"
              ? "Sposato"
              : "Sposata"
            : "Sposato / a"}
        </option>
        <option value="convivente">Convivente</option>
        <option value="divorziato">
          {" "}
          {props.genderValue
            ? props.genderValue === "m"
              ? "Divorziato"
              : "Divorziata"
            : "Divorziato / a"}
        </option>
        <option value="vedovo">
          {props.genderValue
            ? props.genderValue === "m"
              ? "Vedovo"
              : "Vedova"
            : "Vedovo / a"}
        </option>
      </Field>
    </FormGroup>
  </>
);

export default PersonalData;
