import React from "react";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";

import {IconPerson, IconTimes} from "../Icons";
import {IStateUi, NewCapModalTypes} from "./";
import UpdateContractorForm, {
  IUpdateContractorFormData,
} from "./UpdateContractorForm";

import {IUser, IUserEntity} from "../Users";

interface IProps {
  closeUpdateContractorModal: () => void;
  contractor: IUser;
  errorExistingUserId?: string;
  loggedUser: IUser;
  ui: IStateUi;
  updateContractorSubmit: (
    values: IUpdateContractorFormData,
    contractor: IUserEntity
  ) => Promise<void>;
  useExistingContractorSubmit: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => Promise<void>;
}

const UpdateContractorModal: React.FC<IProps> = (props) => (
  <Modal
    isOpen={props.ui.isModalOpen[NewCapModalTypes.UpdateContractor]}
    toggle={props.closeUpdateContractorModal}
    backdrop="static"
    keyboard={false}
    className="modal-form"
  >
    <ModalHeader
      toggle={props.closeUpdateContractorModal}
      close={
        <Button
          size="sm"
          className="close-modal"
          onClick={props.closeUpdateContractorModal}
        >
          <IconTimes size={1.25} className="icon-border-2 rounded-circle p-1" />
        </Button>
      }
    >
      <IconPerson size={3} className="icon-border-2 rounded-circle p-2" />
      Area personale del cliente
    </ModalHeader>
    <ModalBody>
      <UpdateContractorForm {...props} />
    </ModalBody>
  </Modal>
);

export default UpdateContractorModal;
