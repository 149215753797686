import React from "react";
import {Button, Form} from "reactstrap";
import {InjectedFormProps, reduxForm} from "redux-form";
import {focusFirstInvalid} from "../../../helpers/focusFirstInvalid";
import RenderMultiFieldCheckBox from "../../../helpers/RenderMultiFieldCheckBox";
import {IconSpinner} from "../../../Icons";

export interface IAccountabilityFormData {
  accountabilityCheck1?: boolean;
  accountabilityCheck2?: boolean;
  accountabilityCheck3?: boolean;
  accountabilityCheck4?: boolean;
}
interface IAccountabilityFormErrors {
  accountabilityCheck1?: string;
  accountabilityCheck2?: string;
  accountabilityCheck3?: string;
  accountabilityCheck4?: string;
}

interface IOwnProps {
  blocked?: boolean;
  form: string;
  initialValues?: IAccountabilityFormData;
  onAccept?: (value: boolean) => void;
}
type IProps = IOwnProps & InjectedFormProps<IAccountabilityFormData, IOwnProps>;

const validate = (values: IAccountabilityFormData) => {
  const errors: IAccountabilityFormErrors = {};

  if (values && !values.accountabilityCheck1) {
    errors.accountabilityCheck1 = "Per proseguire devi confermare questo punto";
  }
  if (values && !values.accountabilityCheck2) {
    errors.accountabilityCheck2 = "Per proseguire devi confermare questo punto";
  }
  if (values && !values.accountabilityCheck3) {
    errors.accountabilityCheck3 = "Per proseguire devi confermare questo punto";
  }
  if (values && !values.accountabilityCheck4) {
    errors.accountabilityCheck4 = "Per proseguire devi confermare questo punto";
  }

  return errors;
};

const AccountabilityForm = ({
  blocked,
  handleSubmit,
  onAccept,
  submitting,
}: IProps) => {
  const onSubmit = () => {
    onAccept?.(true);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="auto-margin-3">
      <RenderMultiFieldCheckBox
        disabled={blocked}
        id="accountabilityCheck1"
        name="accountabilityCheck1"
        type="checkbox"
        items={[
          {
            text: "Dichiaro che il Contraente coincide con l'Assicurato",
            value: "accountabilityCheck1",
          },
        ]}
      />
      <RenderMultiFieldCheckBox
        disabled={blocked}
        id="accountabilityCheck2"
        name="accountabilityCheck2"
        type="checkbox"
        items={[
          {
            text: "Dichiaro che il Contraente coincide con il Pagatore",
            value: "accountabilityCheck2",
          },
        ]}
      />
      <RenderMultiFieldCheckBox
        disabled={blocked}
        id="accountabilityCheck3"
        name="accountabilityCheck3"
        type="checkbox"
        items={[
          {
            text: "Dichiaro che il l'Assicurato è maggiorenne",
            value: "accountabilityCheck3",
          },
        ]}
      />
      <RenderMultiFieldCheckBox
        disabled={blocked}
        id="accountabilityCheck4"
        name="accountabilityCheck4"
        type="checkbox"
        items={[
          {
            text: `Dichiaro che per la presente proposta, fatta eccezione per me e per il contraente/assicurato, non è richiesta la firma di altri soggetti`,
            value: "accountabilityCheck4",
          },
        ]}
      />
      {!blocked && (
        <div className="text-center">
          <Button type="submit" color="primary" outline disabled={submitting}>
            {submitting ? <IconSpinner className="icon-spin" /> : ""} Conferma e
            prosegui
          </Button>
        </div>
      )}
    </Form>
  );
};

export default reduxForm<IAccountabilityFormData, IOwnProps>({
  onSubmitFail: focusFirstInvalid,
  validate,
})(AccountabilityForm);
