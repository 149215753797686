import moment from "moment";
import React from "react";
import {connect} from "react-redux";
import {Alert, Button, Card, CardBody, CardHeader, Form} from "reactstrap";
import {Dispatch} from "redux";
import {formValueSelector, InjectedFormProps, reduxForm} from "redux-form";
import {IStateUi} from ".";
import {focusFirstInvalid} from "../helpers/focusFirstInvalid";
import {dateIt} from "../helpers/validators";
import {IconSpinner} from "../Icons";
import {IRootState} from "../redux/reducers";
import {dehydrateUser, IUser, IUserEntity} from "../Users";
import PersonalData from "./CompleteContractorFormPersonalData";

export interface ICompleteContractorFormData {
  birthday: string;
  birthplace: string;
  birthplaceIdObject: string;
  birthplaceCSiglaProvincia: string;
  birthplaceBExist: string;
  birthplaceMore: string;
  personalPhone: string;
  gender: "m" | "f";
  education: "1" | "2" | "3" | "4" | "5";
  maritalStatus:
    | "celibe"
    | "coniugato"
    | "convivente"
    | "divorziato"
    | "vedovo";
}

interface ICompleteContractorFormErrors {
  birthday?: string;
  birthplace?: string;
  birthplaceMore?: string;
  gender?: string;
  education?: string;
  personalPhone?: string;
  maritalStatus?: string;
}

interface IOwnProps {
  ui: IStateUi;
  closeCompleteContractor: () => void;
  contractor: IUser;
  completeContractorSubmit: (
    values: ICompleteContractorFormData,
    contractor: IUserEntity
  ) => Promise<void>;
}

interface IProps extends InjectedFormProps<ICompleteContractorFormData> {
  genderValue?: "m" | "f";
  birthplaceValue?: string;
  dispatch: Dispatch;
}

const validate = (values: ICompleteContractorFormData) => {
  const errors: ICompleteContractorFormErrors = {};
  if (!values.birthday) {
    errors.birthday = "Questo campo è obbligatorio";
  } else if (
    !dateIt(values.birthday as any) ||
    !moment(values.birthday, "DD-MM-YYYY").isValid()
  ) {
    errors.birthday =
      "La data di nascita non è nel formato corretto gg/mm/aaaa";
  } else if (
    moment(values.birthday, "DD-MM-YYYY").isBefore(
      moment().subtract(100, "years")
    )
  ) {
    errors.birthday =
      "La data di nascita inserita è precedente alla data minima " +
      moment().subtract(100, "years").format("DD-MM-YYYY");
  } else if (
    moment(values.birthday, "DD-MM-YYYY").isAfter(
      moment().subtract(18, "years")
    )
  ) {
    errors.birthday =
      "La data di nascita inserita è successiva alla data massima " +
      moment().subtract(18, "years").format("DD-MM-YYYY");
  }
  if (!values.birthplace) {
    errors.birthplace = "Questo campo è obbligatorio";
  } else if (!values.birthplaceIdObject) {
    errors.birthplace = "Scegli un comune dalla lista";
  } else if (values.birthplaceIdObject === "0" && !values.birthplaceMore) {
    errors.birthplaceMore = `Specifica la selezione "Estero"`;
  }
  if (!values.education) {
    errors.education = "Questo campo è obbligatorio";
  }
  if (!values.maritalStatus) {
    errors.maritalStatus = "Questo campo è obbligatorio";
  }
  if (!values.personalPhone) {
    errors.personalPhone = "Questo campo è obbligatorio";
  }
  if (!values.gender) {
    errors.gender = "Questo campo è obbligatorio";
  }

  return errors;
};

const CompleteContractorForm: React.FC<IProps & IOwnProps> = (props) => {
  const submitWithCap = async (values: ICompleteContractorFormData) => {
    const {
      result,
      entities: {users},
    } = dehydrateUser(props.contractor);
    await props.completeContractorSubmit(values, users[result]);
  };
  return (
    <Form
      onSubmit={props.handleSubmit(submitWithCap)}
      noValidate
      autoComplete="off"
    >
      <Card className="mb-3">
        <CardHeader className="bg-primary text-white">
          {" "}
          Dati personali
        </CardHeader>
        <CardBody>
          <PersonalData
            genderValue={props.genderValue}
            birthplaceValue={props.birthplaceValue}
          />
        </CardBody>
      </Card>
      {props.ui.isCompleteContractorFail ? (
        <Alert color="danger">{props.ui.completeContractorError}</Alert>
      ) : null}
      <Button type="submit" color="primary" outline disabled={props.submitting}>
        {props.submitting && !props.ui.isCompleteContractorResetButton && (
          <>
            <IconSpinner className="icon-spin" />{" "}
          </>
        )}
        Salva e torna all'analisi
      </Button>{" "}
      <Button
        type="button"
        color="secondary"
        outline
        onClick={props.closeCompleteContractor}
        disabled={props.submitting}
      >
        Annulla
      </Button>
    </Form>
  );
};

const CompleteContractorFormConnected = reduxForm({
  form: "completeContractorForm",
  onSubmitFail: focusFirstInvalid,
  validate,
})(CompleteContractorForm);

const selector = formValueSelector("completeContractorForm");
export default connect((state: IRootState, ownProps: IOwnProps) => {
  const genderValue = selector(state, "gender");
  const birthplaceValue = selector(state, "birthplace");
  const initialValues = {
    birthday:
      ownProps.contractor.birthday &&
      ownProps.contractor.birthday.format("DD/MM/YYYY"),
    birthplace:
      ownProps.contractor.birthplaceIdObject === "0"
        ? "ESTERO"
        : ownProps.contractor.birthplace,
    birthplaceMore:
      ownProps.contractor.birthplaceIdObject === "0"
        ? ownProps.contractor.birthplace
        : "",
    birthplaceIdObject: ownProps.contractor.birthplaceIdObject,
    birthplaceCSiglaProvincia: ownProps.contractor.birthplaceCSiglaProvincia,
    birthplaceBExist: ownProps.contractor.birthplaceBExist,
    education: ownProps.contractor.education,
    gender: ownProps.contractor.gender,
    maritalStatus: ownProps.contractor.maritalStatus,
    personalPhone: ownProps.contractor.personalPhone,
  };

  return {
    genderValue,
    initialValues,
    birthplaceValue,
  };
})(CompleteContractorFormConnected);
