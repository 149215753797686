import React from "react";
import {Alert, Button, Form, FormGroup, Label} from "reactstrap";
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import RenderField from "../../helpers/RenderField";
import {IconSpinner, IconTimes} from "../../Icons";

export interface IRejectFormData {
  revision: string;
}
interface IRejectFormErrors {
  revision?: string;
}
interface IOwnProps {
  isRejectPending?: boolean;
  rejectError?: string;
}
type IProps = InjectedFormProps<IRejectFormData, IOwnProps> & IOwnProps;

const validate = (values: IRejectFormData) => {
  const validationErrors: IRejectFormErrors = {};
  if (!values.revision) {
    validationErrors.revision =
      "La motivazione della richiesta revisione è obbligatoria";
  }
  return validationErrors;
};

const RejectForm: React.FC<IProps> = ({
  handleSubmit,
  isRejectPending,
  rejectError,
}) => (
  <Form onSubmit={handleSubmit}>
    <FormGroup>
      <Label for="revision">
        Spiega il perché è richiesta una revisione della raccomandazione:
      </Label>
      <Field
        component={RenderField}
        id="revision"
        name="revision"
        type="textarea"
      />
    </FormGroup>
    {rejectError && <Alert color="danger">{rejectError}</Alert>}
    <div className="text-center">
      <Button color="danger" outline disabled={isRejectPending}>
        {isRejectPending ? (
          <IconSpinner className="icon-spin" />
        ) : (
          <IconTimes />
        )}{" "}
        Invia richiesta
      </Button>
    </div>
  </Form>
);

export default reduxForm<IRejectFormData, IOwnProps>({
  form: "rejectForm",
  validate,
})(RejectForm);
