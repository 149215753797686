import {combineReducers} from "redux";
import {reducer as formReducer} from "redux-form";
import {registerSelectors} from "reselect-tools";
import {reducer as beneProposalsReducer} from "../BeneProposals/slice";
import {reducer as capsReducer} from "../Caps/";
import {reducer as eSignReducer} from "../ESign/";
import {yn} from "../helpers/yn";
import {reducer as proposalsReducer} from "../Proposals";
import {reducer as recommendationsReducer} from "../Recommendation";
import {reducer as ticketsReducer} from "../Tickets/reducers";
import {reducer as userReducer} from "../User/";
import {reducer as usersReducer} from "../Users/";

import {selectors as beneProposalsSelectors} from "../BeneProposals/slice";
import * as capsSelectors from "../Caps/selectors";
import * as eSignSelectors from "../ESign/selectors";
import * as proposalsSelectors from "../Proposals/selectors";
import * as recommendationsSelectors from "../Recommendation/selectors";
import * as ticketsSelectors from "../Tickets/selectors";
import * as userSelectors from "../User/selectors";
import * as usersSelectors from "../Users/selectors";

// export type RootActions =
//   | typeof beneProposalsActions
//   | CapsActions
//   | ESignActions
//   | ProposalsActions
//   | RecommendationsActions
//   | TicketsActions
//   | UserActions
//   | UsersActions;

export const rootReducer = combineReducers({
  beneProposals: beneProposalsReducer,
  caps: capsReducer,
  eSign: eSignReducer,
  form: formReducer,
  proposals: proposalsReducer,
  recommendations: recommendationsReducer,
  tickets: ticketsReducer,
  user: userReducer,
  users: usersReducer,
});

export type IRootState = ReturnType<typeof rootReducer>;

if (yn(process.env.REACT_APP_ENABLE_DEV_TOOLS)) {
  // Reselect debug
  registerSelectors(beneProposalsSelectors);
  registerSelectors(capsSelectors);
  registerSelectors(eSignSelectors);
  registerSelectors(proposalsSelectors);
  registerSelectors(recommendationsSelectors);
  registerSelectors(ticketsSelectors);
  registerSelectors(userSelectors);
  registerSelectors(usersSelectors);
}
