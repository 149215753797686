import React from "react";
import {Button, Container} from "reactstrap";
import {IconDownload} from "../Icons";
import {ICap} from "./types";

interface IProps {
  viewCap: ICap;
}

const DownloadBar: React.FC<IProps> = ({viewCap}) => {
  return (
    <Container fluid className="fixedValidation">
      <div className="text-center">
        <Button
          color="primary"
          outline
          download
          href={`${process.env.REACT_APP_API_BASE_URL}caps/${viewCap.id}/get-pdf`}
        >
          <IconDownload /> Scarica il pdf dell'analisi
        </Button>
      </div>
    </Container>
  );
};

export default DownloadBar;
