import moment from "moment";
import React from "react";
import {Card} from "reactstrap";
import {ICap, IInvestmentOption} from "../../Caps";
import {IconBulb} from "../../Icons";
import ChooseOptionsForm, {
  IChooseOptionsFormData,
} from "../New/ChooseOptionsForm";
import {IRevision, RecommendationStatus} from "../types";
import OptionsDetailsRead from "./OptionsDetailsRead";
import ValidateButtons from "./ValidateButtons";

// TODO: dividere bene i tre casi possibili: back-office (no-form+bottoni), agente(form+no-bottoni), normale(no-form+no-bottoni)

interface IPropsShared {
  code: string;
  comment: string;
  esignId?: string;
  userEsignId: string | undefined;
  date: moment.Moment;
  isBackOffice?: boolean;
  options: Array<{option: IInvestmentOption; percent: number}>;
  status: RecommendationStatus;
  handleValidate: (values: {
    status:
      | RecommendationStatus.STATUS_CORRESPONDING
      | RecommendationStatus.STATUS_WAIT_REVIEW;
    revision?: string;
  }) => Promise<void>;
  isAdvisor: boolean;
  isAcceptPending?: boolean;
  isRejectPending?: boolean;
  rejectError?: string;
  submitError?: string;
  acceptError?: string;
  viewRecommendationId: string;
  revisions: IRevision[];
}

interface IPropsRead {
  editable?: false;
  cap: ICap;
  options: Array<{option: IInvestmentOption; percent: number}>;
  isContractor: boolean;
}

interface IPropsEdit {
  availableOptions: IInvestmentOption[];
  editable: true;
  optionsList: () => Promise<void>;
  onSubmit: (values: IChooseOptionsFormData) => void;
  suitableOptions: IInvestmentOption[];
  userId: string;
  cap: ICap;
}

type IProps = IPropsShared & (IPropsRead | IPropsEdit);

const OptionsDetails: React.FC<IProps> = (props) => {
  const optionsObject: {[key: string]: {percent: string; value: boolean}} = {};

  for (const option of props.options) {
    optionsObject[option.option.id] = {
      percent: option.percent.toString(),
      value: true,
    };
  }

  const isOldAndClosed = () => {
    return !(
      !props.date ||
      props.date?.isAfter(moment(process.env.REACT_APP_DATE_START_DELIVERY)) ||
      !props.esignId ||
      !props.userEsignId
    );
  };

  return (
    <Card body color="drawer auto-margin-3">
      <div className="d-flex justify-content-between align-items-center">
        <h3>
          <IconBulb title="Dati raccomandazione" /> Dati raccomandazione
          personalizzata
        </h3>
      </div>
      {props.editable ? (
        <ChooseOptionsForm
          availableOptions={props.availableOptions}
          form="updateOptionsForm"
          initialValues={{
            code: props.code,
            comment: props.comment,
            company: props.options[0].option.product.company.id.toString(),
            options: optionsObject,
            product: props.options[0].option.product.id.toString(),
            userId: props.userId,
          }}
          revisions={props.revisions}
          optionsList={props.optionsList}
          submitError={props.submitError}
          onSubmit={props.onSubmit}
          suitableOptions={props.suitableOptions}
          userId={props.userId}
          cap={props.cap}
        />
      ) : (
        <OptionsDetailsRead
          code={props.code}
          revisions={props.revisions}
          comment={props.comment}
          options={props.options}
          cap={props.cap}
        />
      )}
      {props.status === RecommendationStatus.STATUS_TO_BE_VALIDATED &&
        props.isBackOffice &&
        props.esignId &&
        (isOldAndClosed() ||
          props.cap.contractor.lastPrivacySubscriptionEsignId) && (
          <ValidateButtons
            handleValidate={props.handleValidate}
            rejectError={props.rejectError}
            isRejectPending={props.isRejectPending}
            isAcceptPending={props.isAcceptPending}
            acceptError={props.acceptError}
          />
        )}
    </Card>
  );
};

export default OptionsDetails;
