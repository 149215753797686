type IBSVar =
  | "blue"
  | "indigo"
  | "purple"
  | "pink"
  | "red"
  | "orange"
  | "yellow"
  | "green"
  | "teal"
  | "cyan"
  | "white"
  | "gray"
  | "gray-dark"
  | "primary"
  | "secondary"
  | "success"
  | "info"
  | "warning"
  | "danger"
  | "light"
  | "dark"
  | "drawer"
  | "breakpoint-xs"
  | "breakpoint-sm"
  | "breakpoint-md"
  | "breakpoint-lg"
  | "breakpoint-xl"
  | "font-family-sans-serif"
  | "font-family-monospace";

const bodyElement = getComputedStyle(document.body);
export const getBSVar = (bSVarName: IBSVar) =>
  bodyElement.getPropertyValue(`--${bSVarName}`).trim();
