import React from "react";
import {Layout, PageTitle} from "../App";

const Page: React.FC = () => (
  <Layout isProtected>
    <PageTitle>Gestione firme digitali</PageTitle>
  </Layout>
);

export default Page;
