import React from "react";
import {Col, Container, Nav, NavItem, NavLink, Row} from "reactstrap";
import ContainerMax from "./ContainerMax";

const ManualeOperativo = () => (
  <>
    <Container>
      <h2 className="m-0">
        Manuale Operativo F.E.A. - Servizio di Firma Elettronica Avanzata
        <br />
        <small>Rev. n. 0.1 del 21/04/2020</small>
      </h2>
    </Container>
    <ContainerMax>
      <Row>
        <Col sm={3}>
          <Nav vertical>
            <NavItem>
              <NavLink href="#_TOC_250022">Scopo del documento</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#_TOC_250021">Acronimi e definizioni</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#_TOC_250020" className="pl-5">
                Certificatore
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#_TOC_250019" className="pl-5">
                Titolare
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#_TOC_250018" className="pl-5">
                Cliente
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#_TOC_250017" className="pl-5">
                [ ragione sociale ]
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#_TOC_250016" className="pl-5">
                Consulente assicurativo
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#_TOC_250015" className="pl-5">
                FEA
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#_TOC_250014" className="pl-5">
                FEA con OTP
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#_TOC_250013" className="pl-5">
                OTP
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#_TOC_250012" className="pl-5">
                SMS
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#_TOC_250011">Gli attori</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#_TOC_250010">Actalis/Aruba</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#_TOC_250009">Software</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#_TOC_250008">Scopo del documento</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#_TOC_250007">Limite d’uso</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#_TOC_250006">Obblighi</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#_TOC_250005">Tutela assicurativa</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#_TOC_250004">
                La soluzione per [ ragione sociale ]
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#_TOC_250003">Modalità di firma</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#_TOC_250002">Componenti</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#_TOC_250001">
                Descrizione del processo di generazione e apposizione della FEA
                per la sottoscrizione di una proposta di polizza distribuita da
                [ ragione sociale ]
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#_TOC_250000">
                Requisiti per l’utilizzo della FEA
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
        <Col sm={9}>
          <h3 id="_TOC_250022">Scopo del documento</h3>
          <p>
            Il presente manuale operativo descrive il processo di apposizione di
            una Firma Elettronica Avanzata con OTP (di seguito anche “Servizio”)
            come alternativa alla firma autografa, tramite cui il contraente
            aderisce ai servizi offerti da [ ragione sociale ]
          </p>
          <p>Il presente documento è redatto come documento informatico.</p>
          <h3 id="_TOC_250021">Acronimi e definizioni</h3>
          <dl>
            <dt className="h4" id="_TOC_250020">
              Certificatore
            </dt>
            <dd>
              È l’ente, pubblico o privato, abilitato a rilasciare certificati
              digitali previa specifica procedura di certificazione in
              conformità con gli standard nazionali ed europei.
            </dd>
            <dt
              className="h4"
              id="_TOC_250019"
              style={{position: "sticky", top: "10px"}}
            >
              Titolare
            </dt>
            <p>
              È la persona fisica identificata dal Certificatore, cui è stata
              attribuita la firma digitale (o remota) ed è stata consegnata la
              chiave privata del certificatore stesso.
            </p>
            <dt className="h4" id="_TOC_250018">
              Cliente
            </dt>
            <dd>
              È il soggetto a favore del quale [ ragione sociale ] mette a
              disposizione una soluzione di firma elettronica avanzata al fine
              di sottoscrivere i documenti informatici.
            </dd>
            <dt className="h4" id="_TOC_250017">
              [ ragione sociale ]
            </dt>
            <dd>
              [ ragione sociale ] (di seguito “[ ragione sociale ]”) avente sede
              legale in via xxxxxx, xx - xxxxx xxxxx (xx).
            </dd>
            <dt className="h4" id="_TOC_250016">
              Consulente assicurativo
            </dt>
            <dd>
              Intermediario iscritto al RUI operante su mandato di [ ragione
              sociale ].
            </dd>
            <dt className="h4" id="_TOC_250015">
              FEA
            </dt>
            <dd>
              Firma Elettronica Avanzata, insieme di dati in forma elettronica
              allegati oppure connessi a un documento informatico che consentono
              l'identificazione del firmatario del documento e garantiscono la
              connessione univoca al firmatario, creati con mezzi sui quali il
              firmatario può conservare un controllo esclusivo, collegati ai
              dati ai quali detta firma si riferisce in modo da consentire di
              rilevare se i dati stessi siano stati successivamente modificati.
            </dd>
            <dt className="h4" id="_TOC_250014">
              FEA con OTP
            </dt>
            <dd>
              Particolare metodo di firma che rientra nella fattispecie di Firma
              Elettronica Avanzata ed è una modalità di sottoscrizione
              elettronica consistente nell’apposizione da parte del contraente
              di un codice casuale pervenuto al suo telefono cellulare in fase
              di sottoscrizione che deve poi essere verificato al fine di
              consentire la connessione tra il firmatario e la firma.
            </dd>
            <dt className="h4" id="_TOC_250013">
              OTP
            </dt>
            <dd>One Time Password.</dd>
            <dt className="h4" id="_TOC_250012">
              SMS
            </dt>
            <dd>
              Short Message Service, servizio di messaggistica testuale offerto
              dalle compagnie di telefonia mobile.
            </dd>
          </dl>
          <h3 id="_TOC_250011">Gli attori</h3>
          <p>
            Nell’ambito del progetto FEA per [ ragione sociale ] si vedono
            coinvolti una serie di attori che ne hanno permesso la realizzazione
            nel rispetto della normativa vigente:
          </p>
          <ul>
            <li>
              [ ragione sociale ]: soggetto che eroga servizi di firma
              elettronica avanzata attraverso la società [ ragione sociale ];
            </li>
            <li>
              Actalis/Aruba: Certification Authority accreditata per l’emissione
              dei certificati asimmetrici e conservazione della chiave pubblica
              di crittografia;
            </li>
          </ul>
          <h3 id="_TOC_250010">Actalis/Aruba</h3>
          <p>
            Actalis progetta, realizza e gestisce servizi e soluzioni per la
            sicurezza on-line, la firma digitale e la certificazione dei
            documenti, sviluppa e offre prodotti abilitanti, fornisce kit
            completi (hardware e software) di firma digitale e strong
            authentication. Actalis è un certificatore accreditato di firma
            digitale iscritto all’Elenco Pubblico dei Certificatori tenuto
            presso AgID, nonché un gestore
          </p>
          <p>
            accreditato di Posta Elettronica Certificata (PEC). Actalis eroga
            inoltre servizi di marcatura temporale (digital timestamping), firma
            digitale massiva automatica ed altri servizi di supporto alla
            dematerializzazione dei documenti.
          </p>
          <p>
            Nel marzo 2009 Actalis è stata acquisita con una quota azionaria
            pari al 100% da Aruba S.p.A., leader italiano nei servizi di web
            hosting, e-mail e registrazione nomi a dominio. Actalis/Aruba, in
            qualità di Certification Authority, ha rilasciato i certificati
            asimmetrici per la crittografia delle firme grafometriche, la firma
            digitale remota per la procura contrattuale, i Timestamp per la data
            certa di chiusura dei documenti e il certificato di protezione del
            documento.
          </p>
          <h3 id="_TOC_250009">Software</h3>
          <p>
            Il software di gestione del processo è Prevision Family, realizzato
            da Dvision s.r.l.. Per la gestione dell’acquisizione della firma
            elettronica avanzata (FEA), [ ragione sociale ] utilizza il software
            realizzato da Dvision s.r.l. che genera i documenti da firmare e ne
            gestisce l’operatività in sicurezza e senza possibilità di
            iterazioni, interventi da parte di software esterni. Alla
            conclusione rilascia un documento di tipologia PDF con le firme
            acquisite e cifrate e chiuso con certificato [ ragione sociale ].
          </p>
          <h3 id="_TOC_250008">Scopo del documento</h3>
          <p>
            Questo documento si pone lo scopo di descrivere le procedure
            operative e le regole predisposte e utilizzate al fine di gestire i
            servizi di Firma Elettronica Avanzata con OTP. Il documento
            recepisce quanto richiesto dalle Regole Tecniche (DPCM 22 febbraio
            2013). In particolare sono descritte, nel documento, le procedure
            atte a soddisfare quanto richiesto in tema di generazione,
            apposizione e verifica della Firma Elettronica Avanzata, Firma
            Digitale Remota e Validazione Temporale dei documenti informatici.
            Sono recepite le indicazioni espresse dal D.Lgs. del 7 marzo 2005,
            n. 82 e successive modifiche (Codice dell’Amministrazione Digitale -
            CAD).
          </p>
          <h3 id="_TOC_250007">Limite d’uso</h3>
          <p>
            La FEA ha l’efficacia prevista dall’articolo 2702 del Codice Civile
            e integra il requisito della forma scritta. Nei documenti normativi
            citati nel precedente paragrafo e con particolare riferimento alle
            Regole Tecniche, approvate con Decreto del Presidente del Consiglio
            in data 22 febbraio 2013, si pongono alcune limitazioni
            all’operatività della Firma Elettronica Avanzata che di seguito
            riassumiamo:
          </p>
          <ul>
            <li>
              La FEA non consente il libero scambio di documenti informatici: il
              suo uso è limitato al contesto;
            </li>
            <li>
              La FEA è utilizzabile limitatamente ai rapporti giuridici
              intercorrenti tra il sottoscrittore (Cliente) e il soggetto che
              eroga soluzioni di FEA ([ ragione sociale ]) al fine di
              utilizzarle nel processo di dematerializzazione per motivi
              istituzionali, societari o commerciali;
            </li>
            <li>
              La FEA pur avendo l’efficacia prevista dall’articolo 2702 del
              Codice Civile presenta alcune eccezioni e in particolare
              relativamente agli atti di cui l’art. 1350 punti 1-12 del Codice
              Civile. In questi casi si deve utilizzare la Firma Digitale. Di
              conseguenza la soluzione di FEA può gestire tutti i documenti ad
              eccezione di quanto previsto dall’articolo 21 comma 2-bis del
              D.Lgs 82/2005 che cita testualmente “
              <em>
                Salvo quanto previsto dall’articolo 25. Le scritture private di
                cui all’articolo 1350, primo comma, numero da 1 a 12, del Codice
                Civile, se fatte con documento informatico, sono sottoscritte, a
                pena di nullità, con firma elettronica qualificata o con firma
                digitale
              </em>
              ”.
            </li>
          </ul>
          <h3 id="_TOC_250006">Obblighi</h3>
          <p>
            I soggetti che erogano soluzioni FEA ([ ragione sociale ]) hanno una
            serie di obblighi al fine di mantenere tutti i requisiti richiesti
            dal CAD:
          </p>
          <ol>
            <li>
              Identificare in modo certo il Cliente tramite un valido documento
              di riconoscimento e associarlo in maniera univoca ad un numero di
              telefono cellulare;
            </li>
            <li>
              Informare il Cliente in merito agli esatti termini e condizioni
              relative all’uso del Servizio, compresa ogni eventuale limitazione
              dell’uso;
            </li>
            <li>
              Subordinare l’attivazione del Servizio all’accettazione delle
              condizioni da parte del Cliente;
            </li>
            <li>
              Conservare per almeno 20 anni evidenza di quanto al punto 3;
            </li>
            <li>
              Garantire la disponibilità, integrità, leggibilità e autenticità
              del documento di accettazione del Servizio (punto 3);
            </li>
            <li>
              Fornire liberamente e gratuitamente copia del documento di
              accettazione e le informazioni di cui al punto 3) al Cliente su
              sua richiesta;
            </li>
            <li>
              Rendere note le modalità con cui effettuare la richiesta di cui al
              punto 6), pubblicandole anche sul proprio sito internet;
            </li>
            <li>
              Rendere note le caratteristiche del sistema realizzato atte a
              garantire quanto prescritto dalle Regole Tecniche (articolo 56,
              comma 1);
            </li>
            <li>
              Specificare le caratteristiche delle tecnologie utilizzate e come
              queste consentono di ottemperare a quanto prescritto
            </li>
          </ol>
          <h3 id="_TOC_250005">Tutela assicurativa</h3>
          <p>
            Al fine di proteggere i Titolari e i terzi da eventuali danni
            cagionati da inadeguate soluzioni tecniche riconducibili
            all’utilizzo della FEA, [ ragione sociale ] ha stipulato una polizza
            assicurativa per la responsabilità civile con primaria Compagnia
            assicurativa.
          </p>
          <h3 id="_TOC_250004">La soluzione per [ ragione sociale ]</h3>
          <p>
            La soluzione realizzata per [ ragione sociale ] garantisce i
            requisiti fondamentali di garanzia per il firmatario, in
            particolare:
          </p>
          <ul>
            <li>riconducibilità della firma all’autore;</li>
            <li>integrità del documento;</li>
            <li>immodificabilità del documento informatico</li>
          </ul>
          <p>
            Tutti questi requisiti vengono garantiti, oltre che da soluzioni HW
            e SW realizzati da società operanti nella tematica, anche da
            certificati di crittografia e marche temporali rilasciate da una
            Certification Authority riconosciuta e abilitata a rilasciare i
            certificati.
          </p>
          <h3 id="_TOC_250003">Modalità di firma</h3>
          <p>
            La soluzione realizzata prevede la protezione dei dati del Cliente a
            mezzo di crittografia con certificato rilasciato da una
            Certification Authority e intestato a [ ragione sociale ]. Il
            contratto così sottoscritto da parte del Cliente, viene finalizzato
            con un certificato rilasciato da Actalis/Aruba per [ ragione sociale
            ] al fine di garantire l’integrità del documento dopo la firma del
            Cliente.
          </p>
          <h3 id="_TOC_250002">Componenti</h3>
          <p>
            La soluzione implementata si compone di processi strutturati, di
            soluzioni hardware e software per il processo di acquisizione della
            firma e per la gestione del documento da firmare.
          </p>
          <h3 id="_TOC_250001">
            Descrizione del processo di generazione e apposizione della FEA per
            la sottoscrizione di una proposta di polizza distribuita da [
            ragione sociale ].
          </h3>
          <p>
            La generazione e l'apposizione della FEA sui documenti relativi alla
            sottoscrizione di una proposta di polizza distribuita da [ ragione
            sociale ] avvengono secondo il seguente processo:
          </p>
          <ul>
            <li>
              Il Consulente assicurativo crea l’area riservata personale del
              Cliente;
            </li>
            <li>
              Il Cliente riceve nella propria casella di posta le credenziali
              univoche (nome utente e password) per accedere alla propria area
              personale;
            </li>
            <li>
              Il Cliente esprime il proprio consenso esplicito all’utilizzo
              della FEA tramite “point & click”;
            </li>
            <li>
              Il Cliente, o il Consulente assicurativo su incarico del Cliente,
              carica copia fronte e retro del documento identificativo del
              Cliente, in corso di validità, pi&ugrave; una fotografia del viso
              del Cliente affiancato al documento di identità;
            </li>
            <li>
              Il Consulente e il Cliente, dopo essersi incontrati di persona o
              aver partecipato ad una videocall, confermano entrambi la corretta
              identificazione del Cliente, apponendo sui documenti caricati una
              firma elettronica da remoto tramite
            </li>
            <li>
              Successivamente, ogni qualvolta necessario, il Consulente
              assicurativo richiede, la FEA del Cliente;
            </li>
            <li>
              Il Cliente accede alla propria area riservata con le proprie
              credenziali;
            </li>
            <li>
              Il Cliente verifica la correttezza della documentazione oggetto
              della richiesta;
            </li>
            <li>
              il Cliente richiede un codice OTP inserendo il proprio numero di
              cellulare;
            </li>
            <li>
              Il sistema richiede ad Actalis/Aruba di generare un certificato
              digitale, protetto con una password temporanea (OTP) che viene
              inviata al Cliente tramite SMS. A garanzia di sicurezza, tale
              password non viene registrata in alcun modo nel sistema;
            </li>
            <li>
              Il Cliente digita la password ricevuta all'interno dell'apposito
              campo nella propria area riservata;
            </li>
            <li>
              La digitazione corretta della password permetterà di sbloccare il
              certificato, rendendo possibile l'apposizione della FEA sui
              documenti inerenti la proposta di polizza;
            </li>
            <li>
              Qualora il Cliente non riceva la password via SMS, dovrà essere
              generato un nuovo certificato digitale e una nuova password che
              sarà inviata nuovamente via
            </li>
          </ul>
          <p>
            NOTA: l’intestatario del numero di telefono cellulare fornito per
            l’adesione al servizio di Firma Elettronica Avanzata deve essere lo
            stesso che ha richiesto la consulenza assicurativa. Sono
            utilizzabili solo numerazioni attribuite da operatori telefonici
            Italiani.
          </p>
          <h3 id="_TOC_250000">Requisiti per l’utilizzo della FEA</h3>
          <p>
            La procedura di sottoscrizione tramite firma elettronica avanzata è
            conforme alle disposizioni del d.lgs. 7 marzo 2005, n. 82 "Codice
            dell'Amministrazione Digitale" e alle disposizioni contenute nel
            DPCM del 22 febbraio 2013; I documenti sottoscritti tramite firma
            elettronica avanzata avranno la stessa efficacia giuridica e
            probatoria riconosciuta dall’ordinamento alle scritture private, ai
            sensi dell’art. 21, 2° comma del d.lgs. 82/2005 e dell’art. 2702
            codice civile.
          </p>
          <p>
            L’utente Cliente può, in ogni momento del rapporto intercorrente,
            revocare la propria adesione alla procedura di firma elettronica
            avanzata adottata.
          </p>
          <p>
            [ ragione sociale ], al momento della sottoscrizione del contratto
            di utilizzo da parte dell’Utente, fornisce a quest’ultimo copia
            integrale della documentazione contrattuale (Condizioni di Utilizzo
            e
          </p>
          <p>
            Informativa ai sensi dell’art. 13 del d.lgs. 196/2003) con facoltà
            per il Cliente stesso di ottenere anche la copia cartacea.
          </p>
          <p>
            [ ragione sociale ] è autorizzato dagli Assicuratori che hanno
            sottoscritto l’Assicurazione, a proporre l’utilizzo della soluzione
            di firma elettronica avanzata per la sottoscrizione di un contratto,
            o appendice, di assicurazione e di ogni atto necessario alla
            conclusione o alla gestione di un contratto di assicurazione. Si
            prende quindi atto che i documenti sottoscritti tramite firma
            elettronica avanzata hanno la stessa efficacia giuridica e
            probatoria anche nei confronti degli Assicuratori.
          </p>
        </Col>
      </Row>
    </ContainerMax>
  </>
);

export default ManualeOperativo;
