import {TargetsQuestionsKeys} from "../helpers/domandeObiettivi";
import {ITargetFormData} from "./TargetsForm";

export const targetSerie = (
  targets?: ITargetFormData,
  targetsWeights?: {[key in TargetsQuestionsKeys]: number[]}
): number[] => {
  if (!targets || !targetsWeights) {
    return [];
  }

  const len =
    targetsWeights[Object.keys(targetsWeights)[0] as TargetsQuestionsKeys]
      .length;
  const chartData: number[] = new Array(len).fill(0);
  const chartDataMax: number[] = new Array(len).fill(0);
  Object.keys(targetsWeights).forEach((questionName) => {
    const weights = targetsWeights[questionName as TargetsQuestionsKeys];
    const answer = (targets[questionName as TargetsQuestionsKeys] / 7) * 10; // le risposte vanno da 1 a 7 quindi normalizziamo a 10

    weights.forEach((weight, i) => {
      if (answer) {
        chartData[i] += weight * answer;
        chartDataMax[i] += weight;
      }
    });
  });

  return chartData.map((data, i) => chartData[i] / chartDataMax[i]);
};
