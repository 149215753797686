import React from "react";
import {Alert, Button, Col, Form, Row} from "reactstrap";
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import RenderField from "../../helpers/RenderField";
import {IconCheckmark, IconSpinner} from "../../Icons";

export interface IAcceptFormData {
  conferma: string;
}
interface IAcceptFormErrors {
  conferma?: string;
}
interface IOwnProps {
  isAcceptPending?: boolean;
  acceptError?: string;
}
type IProps = InjectedFormProps<IAcceptFormData, IOwnProps> & IOwnProps;

const validate = (values: IAcceptFormData) => {
  const validationErrors: IAcceptFormErrors = {};
  if (!values.conferma) {
    validationErrors.conferma =
      "Compila il campo per procedere con l'approvazione";
  } else if (values.conferma !== "APPROVO") {
    validationErrors.conferma =
      'Per confermare la raccomandazione devi scrivere nel campo "APPROVO"';
  }
  return validationErrors;
};

const AcceptForm: React.FC<IProps> = ({
  handleSubmit,
  isAcceptPending,
  acceptError,
}) => (
  <Form onSubmit={handleSubmit}>
    {acceptError && <Alert color="danger">{acceptError}</Alert>}
    <Row>
      <Col>
        <Field name="conferma" component={RenderField} type="text" />
      </Col>
      <Col xs="auto">
        <Button color="success" outline disabled={isAcceptPending}>
          {isAcceptPending ? (
            <IconSpinner className="icon-spin" />
          ) : (
            <IconCheckmark />
          )}{" "}
          Conferma definitiva
        </Button>
      </Col>
    </Row>
  </Form>
);

export default reduxForm<IAcceptFormData, IOwnProps>({
  form: "acceptForm",
  validate,
})(AcceptForm);
