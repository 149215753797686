import classNames from "classnames";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, Redirect, useParams} from "react-router-dom";
import {Button, Container} from "reactstrap";
import {Layout, LoaderOverlay, PageTitle} from "../App";
import isIdValid from "../helpers/isIdValid";
import {IconBack, IconRefresh} from "../Icons";
import {IRootState} from "../redux/reducers";
import {IAppThunkActionStates} from "../types/thunk";
import {actions} from "./actions";
import {
  getDuplicatedId,
  getIsAdvisor,
  getIsBackOffice,
  getIsContractor,
  getProposal,
  getThunkActionStates,
} from "./selectors";
import Accountability from "./shared/Accountability";
import CapIdentification from "./shared/CapIdentification";
import ContractorDetails from "./shared/ContractorDetails";
import DocumentManagement from "./shared/DocumentManagement";
import IdLockOverlay from "./shared/IdLockOverlay";
import UserDetails from "./shared/UserDetails";
import {IProposal, ProposalStatus} from "./types";
import ProposalRevisions from "./View/ProposalRevisions";
import SendDarta from "./View/SendDarta";
import StatusAlert from "./View/StatusAlert";
import ValidateButtons from "./View/ValidateButtons";

interface IRouteParams {
  id: string;
}

const View = () => {
  const {id} = useParams<IRouteParams>();
  const dispatch = useDispatch();
  const proposal = useSelector<IRootState, IProposal | undefined>((state) =>
    getProposal(state, id)
  );

  const isContractor = useSelector<IRootState, boolean>((state) =>
    getIsContractor(state, id)
  );
  const isAdvisor = useSelector<IRootState, boolean>((state) =>
    getIsAdvisor(state, id)
  );
  const isBackOffice = useSelector(getIsBackOffice);

  const reviewProposal = () => {
    actions.duplicate(id)(dispatch).then();
  };

  const duplicateStates = useSelector<IRootState, IAppThunkActionStates>(
    (state) => getThunkActionStates(state, "duplicate")
  );
  const duplicatedId = useSelector(getDuplicatedId);

  useEffect(() => {
    if (!!id && !proposal) {
      // Non ho i dati, carico la raccomandazione
      dispatch(actions.get(id));
    }

    return () => {
      dispatch(actions.resetState("create"));
      dispatch(actions.resetState("duplicate"));
    };
  }, [dispatch, id, proposal]);

  return (
    <Layout isProtected>
      {!id && <Redirect to="/proposte" />}
      {duplicateStates.isSuccess && (
        <Redirect
          to={{
            pathname: `/proposte/${duplicatedId}`,
            state: {disableAutoScrollToTop: true},
          }}
        />
      )}
      <PageTitle>
        Proposta{" "}
        {proposal?.status === ProposalStatus.Rejected
          ? proposal.code?.split("_")[0] + " (Archiviata)"
          : proposal?.code}
        <Button
          tag={Link}
          color="light"
          outline
          to="/proposte"
          className="ml-auto"
        >
          <IconBack /> Torna all'elenco
        </Button>
      </PageTitle>
      <Container className="container-max p-md-5 auto-margin-3">
        {proposal ? (
          <>
            <StatusAlert isBackOffice={isBackOffice} status={proposal.status} />
            {!isAdvisor && <UserDetails user={proposal.user} />}
            <ContractorDetails contractor={proposal.contractor} />
            {!isContractor && <Accountability blocked isInView />}
            <CapIdentification
              cap={proposal.cap}
              disableUpload={proposal.status === ProposalStatus.Complete}
              isAdvisor={isAdvisor}
              isContractor={isContractor}
            />
            <div style={{position: "relative"}} className="auto-margin-3">
              <DocumentManagement proposal={proposal} />
              <ProposalRevisions revisions={proposal.revisions} />
              {proposal.status === ProposalStatus.WaitReview && isAdvisor && (
                <Button
                  color="primary"
                  outline
                  onClick={reviewProposal}
                  disabled={duplicateStates.isPending}
                >
                  <IconRefresh
                    className={classNames({
                      "icon-spin": duplicateStates.isPending,
                    })}
                  />{" "}
                  Modifica documentazione proposta
                </Button>
              )}
              {proposal.status === ProposalStatus.ToBeValidated &&
                isBackOffice && <ValidateButtons proposal={proposal} />}
              {proposal.status === ProposalStatus.Complete && isBackOffice && (
                <SendDarta proposal={proposal} />
              )}
              {!isIdValid(proposal.cap.onboarding?.identification, isAdvisor) &&
                proposal.status !== ProposalStatus.Complete && (
                  <IdLockOverlay />
                )}
            </div>
          </>
        ) : (
          <LoaderOverlay visible={!proposal}>
            Caricamento proposta in corso...
          </LoaderOverlay>
        )}
      </Container>
    </Layout>
  );
};

export default View;
