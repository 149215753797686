import {IActivateWithTokenFormData, IPrivacy} from ".";
import {apiService} from "../App/service";
import {IUploadIdFormData} from "../Caps/UploadIdForm";
import {IUserDB, IUserEntity} from "../Users";
import {IActivateFormData} from "./ActivateForm";
import {ILoginFormData} from "./LoginForm";
import {ILostPasswordFormData} from "./LostPasswordForm";

const apiUrl = `${process.env.REACT_APP_API_BASE_URL}`;

const userService = {
  login(values: ILoginFormData) {
    return apiService.post(`${apiUrl}login`, values);
  },
  ping() {
    return apiService.get(`${apiUrl}ping`);
  },
  logout() {
    return apiService.get(`${apiUrl}logout`);
  },
  loadPrivacy() {
    return apiService
      .get(`${apiUrl}get-last-privacy`)
      .then(
        (responseJson) =>
          [responseJson.privacy, responseJson.privacySubscription] as [
            IPrivacy,
            IPrivacy
          ]
      );
  },
  profile(values: Partial<IUserEntity>) {
    return apiService.post(`${apiUrl}profile`, values);
  },
  privacy(values: {jsonPrivacy: string}) {
    return apiService.post(`${apiUrl}privacy`, values);
  },
  privacySubscription(values: {jsonPrivacySubscription: string}) {
    return apiService.post(`${apiUrl}privacy-subscription`, values);
  },
  uploadId(values: IUploadIdFormData) {
    return apiService
      .post(`${apiUrl}identificate`, values)
      .then((responseJson) => responseJson.user as IUserDB);
  },
  activate(values: IActivateFormData) {
    return apiService.post(`${apiUrl}activate-user`, values);
  },
  activateWithToken(values: IActivateWithTokenFormData) {
    return apiService.post(`${apiUrl}set-password-activate`, values);
  },
  lostPasswordWithToken(values: IActivateWithTokenFormData) {
    return apiService.post(`${apiUrl}set-password`, values);
  },
  lostPassword(values: ILostPasswordFormData) {
    return apiService.post(`${apiUrl}reset-password`, values);
  },
};

export default userService;
