import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Button} from "reactstrap";
import ContainerMax from "../App/ContainerMax";
import {actions as eSignActions} from "../ESign";
import {PDFTypes} from "../ESign/types";
import {IconDownload, IconESign, IconEye, IconSpinner} from "../Icons";
import IconSendNotification from "../Icons/IconSendNotification";
import {IRootState} from "../redux/reducers";
import {actions} from "./actions";
import {getCapsUi, getIsAdvisor, getIsContractor} from "./selectors";
import {showAttachments3And4} from "../helpers/showAttachments3And4";
import {ICap} from "./types";

interface IProps {
  cap?: ICap;
  id?: string;
}

const CloseCap = ({cap, id}: IProps) => {
  const dispatch = useDispatch();
  const requestValidation = () => {
    dispatch(actions.requestValidation(cap!.id, cap!.contractor.email));
  };
  const signCap = () => {
    dispatch(eSignActions.openModal(PDFTypes.cap, cap!.id));
  };

  const capUi = useSelector(getCapsUi);
  const isAdvisor = useSelector((state: IRootState) =>
    getIsAdvisor(state, cap?.id)
  );
  const isContractor = useSelector((state: IRootState) =>
    getIsContractor(state, cap?.id)
  );

  return (
    <ContainerMax
      isLocked={(!cap?.dateSendValidation && !isAdvisor) || !cap?.dateDelivery}
      isLoading={capUi.isReadPending}
      id={id}
    >
      <h3 className="d-flex justify-content-between">Chiusura analisi</h3>
      <p>
        Per la chiusura dell’analisi è necessario che il contraente apponga la
        propria firma sui seguenti documenti:
      </p>
      <ul>
        <li>Analisi</li>
        {cap && showAttachments3And4(cap) ? <li>Allegato 4</li> : null}
        <li>
          Dichiarazione di avvenuta consegna della documentazione contrattuale e
          precontrattuale ex art. 56 del regolamento IVASS n.40/2018
        </li>
        <li>
          Consenso alla trasmissione delle comunicazioni da parte
          dell’intermediario tramite posta elettronica o formato cartaceo
        </li>
      </ul>
      {capUi.isRequestValidationFail && (
        <Alert color="danger">{capUi.requestValidationError}</Alert>
      )}
      {cap?.esignId ? (
        <>
          <div>
            <Button
              color="primary"
              outline
              download
              href={`${process.env.REACT_APP_API_BASE_URL}caps/${cap?.id}/get-pdf`}
            >
              <IconDownload /> Scarica la documentazione
            </Button>
          </div>
          <Alert color="success">Documenti firmati</Alert>
        </>
      ) : (
        <>
          {(isAdvisor || isContractor) && (
            <div>
              {(isAdvisor || isContractor) && (
                <Button
                  color="primary"
                  outline
                  className="mr-2"
                  download
                  href={`${process.env.REACT_APP_API_BASE_URL}caps/${cap?.id}/get-pdf-preview`}
                >
                  <IconEye /> Anteprima
                </Button>
              )}
              {isAdvisor && !isContractor && (
                <Button
                  color="primary"
                  outline
                  className="mr-2"
                  onClick={requestValidation}
                >
                  {capUi.isRequestValidationPending ? (
                    <IconSpinner className="icon-spin" />
                  ) : cap?.dateSendValidation ? (
                    <IconSendNotification />
                  ) : (
                    <IconESign />
                  )}{" "}
                  {cap?.dateSendValidation
                    ? "Invia promemoria"
                    : "Richiedi la firma"}
                </Button>
              )}
              {isContractor && (
                <Button color="primary" outline onClick={signCap}>
                  <IconESign /> Firma
                </Button>
              )}
            </div>
          )}
          {!isContractor &&
            !cap?.dateResponseValidation &&
            cap?.dateSendValidation && (
              <Alert color="warning">
                In attesa della firma da parte del cliente
              </Alert>
            )}
        </>
      )}
    </ContainerMax>
  );
};

export default CloseCap;
