import moment from "moment";
import React, {useCallback, useState} from "react";
import {useDispatch} from "react-redux";
import {Button} from "reactstrap";
import {IconCheckmark, IconTimes} from "../../Icons";
import {actions} from "../actions";
import {IProposal, ProposalStatus} from "../types";
import AcceptModal from "./ValidateButtons/AcceptModal";
import RejectModal, {IRejectFormData} from "./ValidateButtons/RejectModal";

interface IProps {
  proposal: IProposal;
}

const ValidateButtons = ({proposal}: IProps) => {
  const dispatch = useDispatch();
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);

  const toggleAcceptModal = () => {
    setIsAcceptModalOpen((prev) => !prev);
  };
  const toggleRejectModal = () => {
    setIsRejectModalOpen((prev) => !prev);
  };

  const handleAccept = useCallback(() => {
    dispatch(actions.update(proposal.id, {status: ProposalStatus.Complete}));
  }, [dispatch, proposal.id]);
  const handleReject = useCallback(
    (values: IRejectFormData) => {
      const revisions = [
        ...proposal.revisions,
        {
          date: moment(),
          text: values.revision,
        },
      ];

      dispatch(
        actions.update(proposal.id, {
          jsonRevisions: JSON.stringify(revisions),
          status: ProposalStatus.WaitReview,
        })
      );
    },
    [dispatch, proposal.id, proposal.revisions]
  );

  return (
    <div className="text-center">
      <Button
        color="success"
        outline
        onClick={toggleAcceptModal}
        className="mr-3"
      >
        <IconCheckmark /> Conferma la proposta
      </Button>
      <AcceptModal
        isOpen={isAcceptModalOpen}
        onSubmit={handleAccept}
        submitStates={{}}
        toggle={toggleAcceptModal}
      />
      <Button color="danger" outline onClick={toggleRejectModal}>
        <IconTimes /> Richiedi una revisione
      </Button>
      <RejectModal
        isOpen={isRejectModalOpen}
        onSubmit={handleReject}
        submitStates={{}}
        toggle={toggleRejectModal}
      />
    </div>
  );
};

export default ValidateButtons;
