import React from "react";
import {Alert, Button, Card} from "reactstrap";
import {
  Actions,
  dehydrateCap,
  ICap,
  ICapEntity,
  IStateUi,
  NewCapModalTypes,
} from "../Caps";
import IdentificationDetails from "../Caps/IdentificationDetails";
import {IUploadIdFormData} from "../Caps/UploadIdForm";
import UploadIdModal from "../Caps/UploadIdModal";
import isIdValid from "../helpers/isIdValid";
import {IconPerson} from "../Icons";
import IApiError from "../types/IApiError";

interface IProps {
  capUi?: IStateUi;
  chosenCap: ICap;
  closeModal?: (modalType: NewCapModalTypes) => Actions;
  disableUpload?: boolean;
  identificationSubmit?: () => Promise<void>;
  isAdvisor?: boolean;
  isContractor?: boolean;
  openModal?: (modalType: NewCapModalTypes) => Actions;
  openRequestOTPIdentificationModal?: (capId: string) => void;
  isUploadIdFail?: boolean;
  uploadIdError?: string;
  uploadIdFail?: (error: IApiError) => void;
  uploadIdSubmit?: (
    userId: string,
    cap: ICapEntity,
    values: IUploadIdFormData
  ) => Promise<void>;
}

const CapIdentificationDetails: React.FC<IProps> = ({
  capUi,
  chosenCap,
  closeModal,
  disableUpload,
  identificationSubmit,
  isAdvisor,
  isContractor,
  isUploadIdFail,
  openModal,
  openRequestOTPIdentificationModal,
  uploadIdError,
  uploadIdFail,
  uploadIdSubmit,
}) => {
  const openUploadIdModal = () => {
    openModal?.(NewCapModalTypes.UploadId);
  };
  const closeUploadIdModal = () => {
    closeModal?.(NewCapModalTypes.UploadId);
  };

  const uploadIdSubmitWithData = async (values: IUploadIdFormData) => {
    if (!chosenCap) {
      return chosenCap;
    }

    const {
      result,
      entities: {caps, users},
    } = dehydrateCap(chosenCap);
    const itemEntity = caps[result];
    const contractor = users[itemEntity.contractor];

    return uploadIdSubmit?.(contractor.id, itemEntity, values);
  };

  const renderAlert = () => {
    // Se la raccomandazione è chiusa (siamo nella view) non mostriamo nessun alert
    if (disableUpload) {
      return null;
    }
    if (!chosenCap.onboarding?.identification) {
      // Non c'è ancora un id nel cap
      return (
        <Alert color="danger">
          Per procedere alla compilazione della raccomandazione personalizzata,
          devi confermare l'identità del tuo cliente
        </Alert>
      );
    } else if (
      !chosenCap.onboarding.identification.fileIdFaceName ||
      !chosenCap.onboarding.identification.fileIdFCName
    ) {
      // L'id manca della terza foto o la quarta
      return (
        <Alert color="danger">
          L'identificazione del cliente deve essere ripetuta perché, ai fini
          dell'antiriciclaggio, è necessario aggiungere al documento d'identità
          la fotografia del viso del cliente affiancato al documento stesso.
        </Alert>
      );
    } else if (!chosenCap.onboarding.identification.esignId) {
      // Manca la firma dell'advisor
      return (
        <Alert color="danger">
          Per procedere alla compilazione della raccomandazione personalizzata,
          devi firmare il PDF dell'dentitificazione del tuo cliente
        </Alert>
      );
    } else if (!chosenCap.onboarding.identification.contractorEsignId) {
      // Manca la firma del cliente
      return (
        <Alert color="warning">
          Il cliente non ha ancora firmato l'identificazione.
        </Alert>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Card body color="drawer auto-margin-3" id="cap-identification-details">
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            <IconPerson title="Identificazione del cliente" /> Identificazione
          </h3>
          {isAdvisor &&
            !disableUpload &&
            !isIdValid(chosenCap.onboarding?.identification) && (
              <Button color="primary" outline onClick={openUploadIdModal}>
                Carica un documento del cliente
              </Button>
            )}
        </div>
        <IdentificationDetails
          cap={chosenCap}
          contractor={chosenCap.contractor}
          details={chosenCap.onboarding && chosenCap.onboarding.identification}
          isAdvisor={isAdvisor}
          isContractor={isContractor}
          isIdentificationPending={capUi && capUi.isIdentificationPending}
          identificationError={capUi && capUi.identificationError}
          identificationSubmit={identificationSubmit}
          openRequestOTPIdentificationModal={openRequestOTPIdentificationModal}
        />
        {isAdvisor && chosenCap.contractor.identification && renderAlert()}
      </Card>
      {capUi && uploadIdFail && (
        <UploadIdModal
          closeIdentification={closeUploadIdModal}
          uploadIdFail={uploadIdFail}
          uploadIdSubmit={uploadIdSubmitWithData}
          ui={capUi}
          isUploadIdFail={isUploadIdFail}
          uploadIdError={uploadIdError}
        />
      )}
    </>
  );
};

export default CapIdentificationDetails;
