export const individualCompanyTypes = [
  "Ditta Individuale",
  "Impresa Coniugale",
  "Impresa Familiare",
];
export const capitalCompanyTypes = [
  "Altri Consorzi",
  "Asili, Scuole, Servizi educativi",
  "ASL/Enti ospedalieri",
  "Associazioni, proloco",
  "ATI",
  "Aziende parastatali",
  "Cassa mutua",
  "Circoli",
  "Comitati",
  "Comunità",
  "Condomini",
  "Consorzio di imprese",
  "Consorzio di privati",
  "Consorzio grandine",
  "Cooperative",
  "Enti comunali",
  "Enti privati",
  "Enti provinciali",
  "Enti regionali",
  "Enti statali",
  "Enti/Istituti",
  "Fondazioni, ordini",
  "Gruppo europeo di interesse economico",
  "Municipalizzate",
  "Mutue",
  "Opera pia, società di mutuo soccorso",
  "Organizzazioni",
  "S.a.p.a.",
  "S.a.s.",
  "S.d.f.",
  "S.n.c.",
  "S.p.a.",
  "S.r.l.",
  "S.r.l.s",
  "S.s.",
  "Società cooperative",
  "Società estera con sede in Italia",
  "Università",
];

export const companyTypes = [...individualCompanyTypes, ...capitalCompanyTypes];
