import React from "react";
import {Alert, Card} from "reactstrap";
import {ICap, IInvestmentOption} from "../../Caps";
import {IconBulb} from "../../Icons";
import ChooseOptionsForm, {IChooseOptionsFormData} from "./ChooseOptionsForm";

interface IProps {
  availableOptions?: IInvestmentOption[];
  cap: ICap;
  optionsList: () => Promise<void>;
  onSubmit: (values: IChooseOptionsFormData) => void;
  submitError?: string;
  suitableOptions: IInvestmentOption[];
  userId: string;
}

const ChooseOptionsDetails: React.FC<IProps> = ({
  availableOptions,
  cap,
  optionsList,
  onSubmit,
  submitError,
  suitableOptions,
  userId,
}) => (
  <Card body color="drawer auto-margin-3">
    <h3>
      <IconBulb title="Dati raccomandazione" /> Dati raccomandazione
    </h3>
    {suitableOptions.length > 0 ? (
      <ChooseOptionsForm
        submitError={submitError}
        form="chooseOptionsForm"
        initialValues={{
          userId,
        }}
        optionsList={optionsList}
        onSubmit={onSubmit}
        suitableOptions={suitableOptions}
        availableOptions={availableOptions}
        userId={userId}
        cap={cap}
      />
    ) : (
      <Alert color="danger" className="d-flex auto-margin-2">
        Nessun opzione d'investimento adeguata.
      </Alert>
    )}
  </Card>
);

export default ChooseOptionsDetails;
