import {batch} from "react-redux";
import {Dispatch} from "redux";
import {Actions as CapsActions, actions as capsActions} from "../Caps";
import IApiError from "../types/IApiError";
import {actions as usersActions} from "../Users";
import {
  IRecommendationEntity,
  IRecommendationsEntities,
  service,
  UpdatePerTypeTypes,
} from "./index";
import {
  IRecommendationParams,
  normalizeRecommendation,
  RecommendationStatus,
} from "./types";

export enum ActionTypes {
  MERGE_ITEMS = "[Recommendations] Merge items",

  CHOOSE_CAP = "[Recommendations] Chose cap",
  CHOOSE_CAP_FROM_CAP = "[Recommendations] Chose cap from cap",

  CREATE_REQUEST = "[Recommendations] Create request",
  CREATE_SUCCESS = "[Recommendations] Create success",
  CREATE_FAIL = "[Recommendations] Create fail",
  CREATE_RESET = "[Recommendations] Create reset",

  READ_REQUEST = "[Recommendations] Read request",
  READ_SUCCESS = "[Recommendations] Read success",
  READ_FAIL = "[Recommendations] Read fail",

  LIST_REQUEST = "[Recommendations] List request",
  LIST_SUCCESS = "[Recommendations] List success",
  LIST_FAIL = "[Recommendations] List fail",

  UPDATE_REQUEST = "[Recommendations] Update request",
  UPDATE_SUCCESS = "[Recommendations] Update success",
  UPDATE_FAIL = "[Recommendations] Update fail",
}

interface IMergeItemsAction {
  type: typeof ActionTypes.MERGE_ITEMS;
  payload: {recommendations: IRecommendationsEntities};
}

interface IChooseCap {
  type: typeof ActionTypes.CHOOSE_CAP;
  payload: {cap: string | null};
}
interface IChooseCapFromCap {
  type: typeof ActionTypes.CHOOSE_CAP_FROM_CAP;
  payload: {isFromCap: boolean};
}

interface ICreateRequestAction {
  type: typeof ActionTypes.CREATE_REQUEST;
}
interface ICreateSuccessAction {
  type: typeof ActionTypes.CREATE_SUCCESS;
  payload: {recommendationId: string};
}
interface ICreateFailAction {
  type: typeof ActionTypes.CREATE_FAIL;
  payload: IApiError;
}
interface ICreateResetAction {
  type: typeof ActionTypes.CREATE_RESET;
}

interface IReadRequestAction {
  type: typeof ActionTypes.READ_REQUEST;
}
interface IReadSuccessAction {
  type: typeof ActionTypes.READ_SUCCESS;
  payload: {recommendationId: string};
}
interface IReadFailAction {
  type: typeof ActionTypes.READ_FAIL;
  payload: {error: IApiError};
}

interface IListRequestAction {
  type: typeof ActionTypes.LIST_REQUEST;
}
interface IListSuccessAction {
  type: typeof ActionTypes.LIST_SUCCESS;
  payload: {
    recommendationIds: string[];
    numRecommendations: number;
    params: IRecommendationParams;
  };
}
interface IListFailAction {
  type: typeof ActionTypes.LIST_FAIL;
  payload: IApiError;
}

interface IUpdateRequestAction {
  type: typeof ActionTypes.UPDATE_REQUEST;
  payload: {updateType?: UpdatePerTypeTypes};
}
interface IUpdateSuccessAction {
  type: typeof ActionTypes.UPDATE_SUCCESS;
  payload: {recommendationId: string; updateType?: UpdatePerTypeTypes};
}
interface IUpdateFailAction {
  type: typeof ActionTypes.UPDATE_FAIL;
  payload: {error: IApiError; updateType?: UpdatePerTypeTypes};
}

export const actions = {
  mergeItems: (
    recommendations: IRecommendationsEntities
  ): IMergeItemsAction => ({
    payload: {recommendations: recommendations},
    type: ActionTypes.MERGE_ITEMS,
  }),

  chooseCap: (cap: string | null): IChooseCap => {
    return {
      payload: {cap},
      type: ActionTypes.CHOOSE_CAP,
    };
  },
  chooseCapFromCap: (isFromCap: boolean): IChooseCapFromCap => {
    return {
      payload: {isFromCap},
      type: ActionTypes.CHOOSE_CAP_FROM_CAP,
    };
  },

  create: (recommendation: Partial<IRecommendationEntity>) => (
    dispatch: Dispatch<actions | usersActions | CapsActions>
  ) => {
    dispatch(actions.createRequest());
    return service
      .create({
        ...recommendation,
        status: RecommendationStatus.STATUS_REQUEST_ESIGN_ADVISOR,
      })
      .then((createdRecommendation) => {
        const {
          result,
          entities: {recommendations, caps, users},
        } = normalizeRecommendation(createdRecommendation);
        batch(() => {
          dispatch(usersActions.mergeItems(users));
          dispatch(capsActions.mergeItems(caps));
          dispatch(actions.mergeItems(recommendations));

          dispatch(actions.createSuccess(result));
        });
      })
      .catch((error) => {
        dispatch(actions.createFail(error));
      });
  },
  createFail: (error: IApiError): ICreateFailAction => ({
    payload: error,
    type: ActionTypes.CREATE_FAIL,
  }),
  createRequest: (): ICreateRequestAction => ({
    type: ActionTypes.CREATE_REQUEST,
  }),
  createReset: (): ICreateResetAction => ({
    type: ActionTypes.CREATE_RESET,
  }),
  createSuccess: (recommendationId: string): ICreateSuccessAction => ({
    payload: {recommendationId: recommendationId},
    type: ActionTypes.CREATE_SUCCESS,
  }),

  read: (id: string) => (
    dispatch: Dispatch<actions | usersActions | CapsActions>
  ) => {
    dispatch(actions.readRequest());
    return service
      .read(id)
      .then((recommendation) => {
        const {
          result,
          entities: {recommendations, caps, users},
        } = normalizeRecommendation(recommendation);
        batch(() => {
          dispatch(usersActions.mergeItems(users));
          dispatch(capsActions.mergeItems(caps));
          dispatch(actions.mergeItems(recommendations));

          dispatch(actions.readSuccess(result));
        });
      })
      .catch((error) => {
        console.error(error); // tslint:disable-line
        dispatch(actions.readFail(error));
      });
  },
  readFail: (error: IApiError): IReadFailAction => ({
    payload: {error},
    type: ActionTypes.READ_FAIL,
  }),
  readRequest: (): IReadRequestAction => ({
    type: ActionTypes.READ_REQUEST,
  }),
  readSuccess: (recommendationId: string): IReadSuccessAction => ({
    payload: {recommendationId: recommendationId},
    type: ActionTypes.READ_SUCCESS,
  }),

  list: (params: IRecommendationParams = {limit: 50, offset: 0}) => (
    dispatch: Dispatch<actions | usersActions | CapsActions>
  ) => {
    dispatch(actions.listRequest());
    return service
      .list(params)
      .then(({recommendationsList, numRecommendations}) => {
        const {
          result,
          entities: {recommendations, caps, users},
        } = normalizeRecommendation(recommendationsList);

        batch(() => {
          dispatch(usersActions.mergeItems(users));
          dispatch(capsActions.mergeItems(caps));
          dispatch(actions.mergeItems(recommendations));

          dispatch(actions.listSuccess(result, numRecommendations, params));
        });
      })
      .catch((error) => {
        console.error(error); // tslint:disable-line
        dispatch(actions.listFail(error));
      });
  },
  listFail: (error: IApiError): IListFailAction => ({
    payload: error,
    type: ActionTypes.LIST_FAIL,
  }),
  listRequest: (): IListRequestAction => ({
    type: ActionTypes.LIST_REQUEST,
  }),
  listSuccess: (
    recommendationIds: string[],
    numRecommendations: number,
    params: IRecommendationParams
  ): IListSuccessAction => ({
    payload: {
      recommendationIds: recommendationIds,
      numRecommendations: numRecommendations,
      params,
    },
    type: ActionTypes.LIST_SUCCESS,
  }),

  update: (
    id: string,
    recommendation: Partial<IRecommendationEntity>,
    updateType?: UpdatePerTypeTypes
  ) => (dispatch: Dispatch<actions | usersActions | CapsActions>) => {
    dispatch(actions.updateRequest(updateType));
    return service
      .update(id, recommendation)
      .then((updatedRecommendation) => {
        const {
          result,
          entities: {recommendations, caps, users},
        } = normalizeRecommendation(updatedRecommendation);
        batch(() => {
          dispatch(usersActions.mergeItems(users));
          dispatch(capsActions.mergeItems(caps));
          dispatch(actions.mergeItems(recommendations));

          dispatch(actions.updateSuccess(result, updateType));
        });
      })
      .catch((error) => {
        console.error(error); // tslint:disable-line
        dispatch(actions.updateFail(error, updateType));
      });
  },
  updateFail: (
    error: IApiError,
    updateType?: UpdatePerTypeTypes
  ): IUpdateFailAction => ({
    payload: {error, updateType},
    type: ActionTypes.UPDATE_FAIL,
  }),
  updateRequest: (updateType?: UpdatePerTypeTypes): IUpdateRequestAction => ({
    payload: {updateType},
    type: ActionTypes.UPDATE_REQUEST,
  }),
  updateSuccess: (
    recommendationId: string,
    updateType?: UpdatePerTypeTypes
  ): IUpdateSuccessAction => ({
    payload: {recommendationId: recommendationId, updateType},
    type: ActionTypes.UPDATE_SUCCESS,
  }),
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type actions =
  | IMergeItemsAction
  | IChooseCap
  | IChooseCapFromCap
  | ICreateRequestAction
  | ICreateSuccessAction
  | ICreateFailAction
  | ICreateResetAction
  | IReadRequestAction
  | IReadSuccessAction
  | IReadFailAction
  | IListRequestAction
  | IListSuccessAction
  | IListFailAction
  | IUpdateRequestAction
  | IUpdateSuccessAction
  | IUpdateFailAction;
