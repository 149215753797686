import {createSelector} from "reselect";
import {IRootState} from "../redux/reducers";
import {getUsersEntities} from "../Users/selectors";
import {ThunkActionsNames} from "./reducers";
import {hydrateESign} from "./types";

export const getESignsEntities = (state: IRootState) => state.eSign.entities;
export const getIsModalOpen = (state: IRootState) => state.eSign.isModalOpen;
export const getPDFType = (state: IRootState) => state.eSign.pdfType;
export const getReferenceId = (state: IRootState) => state.eSign.referenceId;
export const getAdditionalParams = (state: IRootState) =>
  state.eSign.additionalParams;
export const getThunkActionName = (
  state: IRootState,
  name: ThunkActionsNames
) => name;
export const getThunkActionsStates = (state: IRootState) =>
  state.eSign.thunkActionsStates;
export const getOpenedTransaction = (state: IRootState) =>
  state.eSign.openedTransaction;

export const getParamsESignId = (
  state: IRootState,
  eSignId: string | undefined
) => eSignId;

export const getThunkActionStates = createSelector(
  [getThunkActionsStates, getThunkActionName],
  (thunkActionsStates, thunkActionName) => {
    return thunkActionsStates[thunkActionName];
  }
);

export const getESign = createSelector(
  [getESignsEntities, getUsersEntities, getParamsESignId],
  (eSigns, users, eSignId) => {
    return !!eSignId && !!eSigns[eSignId]
      ? hydrateESign({
          entities: {eSigns, users},
          result: eSignId,
        })
      : undefined;
  }
);
