import moment from "moment";
import React from "react";
import {Card, Container} from "reactstrap";
import {
  Actions,
  ICap,
  ICapEntity,
  IInvestmentOption,
  NewCapModalTypes,
} from "../../Caps";
import PrivacySubscription from "../../Caps/PrivacySubscription";
import {IUploadIdFormData} from "../../Caps/UploadIdForm";
import {IconLock} from "../../Icons";
import IApiError from "../../types/IApiError";
import CapDetails from "../CapDetails";
import CapIdentification from "../CapIdentification";
import ContractorDetails from "../ContractorDetails";
import SavingDetails from "../SavingDetails";
import ChooseOptionsDetails from "./ChooseOptionsDetails";
import {IChooseOptionsFormData} from "./ChooseOptionsForm";

interface IProps {
  availableOptions?: IInvestmentOption[];
  canSeeCap: boolean;
  chosenCap: ICap;
  closeModal: (modalType: NewCapModalTypes) => Actions;
  isAdvisor: boolean;
  onSubmit: (values: IChooseOptionsFormData) => void;
  openModal: (modalType: NewCapModalTypes) => Actions;
  optionsList: () => Promise<void>;
  submitError?: string;
  uploadIdError?: string;
  isUploadIdFail?: boolean;
  uploadIdFail: (error: IApiError) => void;
  uploadIdSubmit: (
    userId: string,
    cap: ICapEntity,
    values: IUploadIdFormData
  ) => Promise<void>;
  userId: string;
}

const ChoseOption: React.FC<IProps> = ({
  availableOptions,
  canSeeCap,
  chosenCap,
  closeModal,
  isAdvisor,
  isUploadIdFail,
  onSubmit,
  openModal,
  optionsList,
  submitError,
  uploadIdError,
  uploadIdFail,
  uploadIdSubmit,
  userId,
}) => {
  return (
    <Container className="container-max p-md-5 auto-margin-3">
      <ContractorDetails contractor={chosenCap.contractor} />
      <CapIdentification
        cap={chosenCap}
        closeModal={closeModal}
        isAdvisor={isAdvisor}
        isUploadIdFail={isUploadIdFail}
        openModal={openModal}
        uploadIdError={uploadIdError}
        uploadIdFail={uploadIdFail}
        uploadIdSubmit={uploadIdSubmit}
        isContractor={false}
      />
      <PrivacySubscription
        as={Card}
        body
        cap={chosenCap}
        className="auto-margin-3 d-block"
        color="drawer"
        icon
        id="contractor-privacy-subscription"
      />
      <div style={{position: "relative"}} className="auto-margin-3">
        <CapDetails
          cap={chosenCap}
          canSeeCap={canSeeCap}
          isAdvisor={isAdvisor}
        />
        <SavingDetails cap={chosenCap} />
        <ChooseOptionsDetails
          availableOptions={availableOptions}
          optionsList={optionsList}
          submitError={submitError}
          onSubmit={onSubmit}
          suitableOptions={chosenCap.idd!.suitableOptions}
          userId={userId}
          cap={chosenCap}
        />
        {!chosenCap.contractor.identification && (
          <div className="component-loader flex-column">
            <IconLock size={4} /> Per procedere alla compilazione della
            raccomandazione personalizzata,
            <br />
            il cliente deve caricare un documento di identità valido.
          </div>
        )}
        {(chosenCap.onboarding &&
          chosenCap.onboarding.identification &&
          moment(
            chosenCap.onboarding.identification.expirationDate,
            "DD-MM-YYYY"
          ).isBefore(moment())) ||
          (!chosenCap.onboarding?.identification?.fileIdFaceName && (
            <div className="component-loader flex-column text-center">
              <IconLock size={4} /> Per procedere alla compilazione della
              raccomandazione personalizzata,
              <br />
              il cliente deve caricare un documento di identità valido.
            </div>
          ))}
      </div>
    </Container>
  );
};
export default ChoseOption;
