import last from "lodash/last";
import moment from "moment";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Badge, Button} from "reactstrap";
import {IconSend, IconSpinner} from "../../Icons";
import {IRootState} from "../../redux/reducers";
import {IAppThunkActionStates} from "../../types/thunk";
import {actions} from "../actions";
import {getThunkActionStates} from "../selectors";
import {IProposal} from "../types";

interface IProps {
  proposal: IProposal;
}

const SendDarta = ({proposal}: IProps) => {
  const dispatch = useDispatch();

  const sendDartaStates = useSelector<IRootState, IAppThunkActionStates>(
    (state) => getThunkActionStates(state, "sendDarta")
  );

  const sendDarta = () => {
    dispatch(actions.sendDarta(proposal.id));
  };

  return (
    <div className="text-center">
      {sendDartaStates.isFail && (
        <Alert color="danger">{sendDartaStates.error}</Alert>
      )}
      <Alert color="info">
        Cliccando sul bottone{" "}
        <Badge color="light">
          <IconSend /> Invia documentazione
        </Badge>
        , il PDF firmato della proposta verrà inviato in allegato all'indirizzo
        di posta {process.env.REACT_APP_EMAIL_DARTA_TO}
      </Alert>
      <Button
        color="primary"
        outline
        onClick={sendDarta}
        disabled={sendDartaStates.isPending}
      >
        {sendDartaStates.isPending ? (
          <IconSpinner className="icon-spin" />
        ) : (
          <IconSend />
        )}{" "}
        Invia documentazione
      </Button>{" "}
      {proposal.datesSendDarta && (
        <span>
          Ultimo invio:{" "}
          {moment(last(proposal.datesSendDarta)?.date).format("L[ alle ]LT")}
        </span>
      )}
    </div>
  );
};

export default SendDarta;
