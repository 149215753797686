import {
  conoscenzaEsperienzaCriteria,
  IIddFormData,
  obiettiviInvestimentoCriteria,
  propensioneRischioCriteria,
  situazioneFinanziariaCriteria,
} from ".";
import {
  conoscenzaEsperienzaCriteriaType,
  obiettiviInvestimentoType,
  orizzonteTemporaleType,
  propensioneRischioType,
  situazioneFinanziariaType,
  tipologiaClienteType,
} from "../types";

export interface IIddProfile {
  conoscenzaEsperienza: {
    label: conoscenzaEsperienzaCriteriaType;
    value: number;
  };
  obiettiviInvestimento: {
    label: obiettiviInvestimentoType;
    value: number;
  };
  orizzonteTemporale: {
    label: orizzonteTemporaleType;
  };
  propensioneRischio: {
    label: propensioneRischioType;
    value: number;
  };
  situazioneFinanziaria: {
    label: situazioneFinanziariaType;
    value: number;
  };
  tipologiaClientela: {
    label: tipologiaClienteType;
  };
}

export const computeIdd = (data: IIddFormData): IIddProfile => {
  const tipologiaClientela = computeTipologiaClientela(data);
  const conoscenzaEsperienza = computeConoscenzaEsperienza(data);
  const situazioneFinanziaria = computeSituazioneFinanziaria(data);
  const obiettiviInvestimento = computeObiettiviInvestimento(data);
  const orizzonteTemporale = computeOrizzonteTemporale(data);
  const propensioneRischio = computePropensioneRischio(data);

  return {
    conoscenzaEsperienza: {
      label: getConoscenzaEsperienzaLabel(conoscenzaEsperienza),
      value: conoscenzaEsperienza,
    },
    obiettiviInvestimento: {
      label: getObiettiviInvestimentoLabel(obiettiviInvestimento),
      value: obiettiviInvestimento,
    },
    orizzonteTemporale: {
      label: orizzonteTemporale,
    },
    propensioneRischio: {
      label: getPropensioneRischioLabel(propensioneRischio),
      value: propensioneRischio,
    },
    situazioneFinanziaria: {
      label: getSituazioneFinanziariaLabel(situazioneFinanziaria),
      value: situazioneFinanziaria,
    },
    tipologiaClientela: {
      label: tipologiaClientela,
    },
  };
};

const computeTipologiaClientela = (data: IIddFormData) => {
  /**
   *  La domanda unica porta alla valorizzazione del driver "Tipologia della clientela", distinguendo tra clientela Retail e Professionale
   */
  return data.clientType === "1" ? "Retail" : "Professionale";
};

const computeConoscenzaEsperienza = (data: IIddFormData) => {
  /**
   * [1] 1 punto per risposta corretta, 0 punti per risposta sbagliata/Non so. Il punteggio ottenuto sommando i punteggi delle singole questions (min 0, max 4) andrà a contribuire alla valorizzazione del driver.
   * In ottica di valorizzazione del driver, i punteggi ottenuti andranno tradotti nel seguente modo:
   * [1.1]
   * 4 = 1
   * 3 = 2
   * 2 = 3
   * 1 = 4
   * [1.9] Peso della sezione: 40%
   *
   * [2] La risposta 1 vale 1, la 2 vale 2 ecc..
   * [2.9] Peso della sezione: 20%
   *
   * [3] Domanda a risposta multipla, il valore che andrà a contribuire alla valorizzazione del Driver sarà quello più basso tra le scelte del sottoscrittore (corrispondente al livello più complesso di prodotti, tra quelli in cui il sottoscrittore ha già investito. La risposta Nessuno non è compatibile con altre risposte.
   * [3.9] Peso della sezione: 20%
   *
   * [4] Peso della sezione: 20%
   */
  // 1
  let part1 =
    (data.question_c1 === "1" ? 1 : 0) +
    (data.question_c2 === "2" ? 1 : 0) +
    (data.question_c3 === "2" ? 1 : 0) +
    (data.question_c4 === "1" ? 1 : 0);
  // 1.1
  part1 = Math.min(4, 5 - part1);

  // 2
  const part2 = parseInt(data.question_c5!, 10);

  // 3
  const part3Values = [4, 3, 2, 2, 2, 1, 1];
  let part3Index;
  if (data.question_c6) {
    part3Index =
      Math.max(
        ...data
          .question_c6!.map((val, i) => (val ? i : -1))
          .filter((val) => val >= 0)
      ) - 1;
  } else {
    part3Index = 0;
  }
  const part3 = Number.isInteger(part3Index)
    ? part3Values[part3Index]
    : part3Values[0];

  // 4
  const part4Values = [4, 4, 3, 2, 1];
  const part4 = part4Values[parseInt(data.question_b1!, 10) - 1];

  return (
    part1 * 0.4 + // 1.9
    part2 * 0.2 + // 2.9
    part3 * 0.2 + // 3.9
    part4 * 0.2 //   4
  );
};

const computeSituazioneFinanziaria = (data: IIddFormData) => {
  /**
   * La media delle valorizzazioni delle singole questions definirà il livello del Driver Situazione Finanziaria
   */
  const part1Values = [4, 3, 3, 2, 1];
  const part1 = part1Values[parseInt(data.question_d1!, 10) - 1];
  const part2Values = [4, 2, 1];
  const part2 = part2Values[parseInt(data.question_d2!, 10) - 1];
  const part3Values = [4, 4, 3, 2, 1];
  const part3 = part3Values[parseInt(data.question_d3!, 10) - 1];
  const part4Values = [1, 2, 3, 4];
  const part4 = part4Values[parseInt(data.question_d4!, 10) - 1];
  const part5Values = [1, 2, 3, 4, 4];
  const part5 = part5Values[parseInt(data.question_d5!, 10) - 1];

  return (part1 + part2 + part3 + part4 + part5) / 5;
};

const computeObiettiviInvestimento = (data: IIddFormData) => {
  /**
   * "I livelli di valorizzazione del driver ""Obiettivi di investimento"" sono direttamente connessi alle risposte della domanda relativa agli obiettivi di investimento
   */
  const part1Values = [4, 2, 1];
  return part1Values[parseInt(data.question_e1!, 10) - 1];
};

const computeOrizzonteTemporale = (data: IIddFormData) => {
  /**
   * L'orizzonte temporale non contribuisce alla valorizzazione del driver, ma qualora la risposta dovesse essere di Molto-breve o Breve periodo, il cliente risulterebbe fuori dal Target Market, bloccando pertanto la vendita di qualsiasi prodotto di investimento.
   */
  const part1Values: orizzonteTemporaleType[] = [
    "Molto breve",
    "Breve",
    "Medio",
    "Lungo",
  ];
  return part1Values[parseInt(data.question_e2!, 10) - 1];
};

const computePropensioneRischio = (data: IIddFormData) => {
  /**
   * Le valorizzazioni delle variabili relative al Driver ""Propensione al rischio"" concorrono alla determinazione del profilo di rischio del cliente, che, a seconda del valore risultante dalla media tra i due valori associati alle risposte, determinerà il livello di propensione al rischio
   */
  return (
    (10 - parseInt(data.question_e3!, 10) - parseInt(data.question_e4!, 10)) / 2
  );
};

// Number to label helpers

const roundExcess = (inputNumber: number) => {
  let returnNumber = inputNumber * -1;
  returnNumber = Math.round(returnNumber);
  return returnNumber * -1;
};

export const getConoscenzaEsperienzaLabel = (value: number) => {
  const index = conoscenzaEsperienzaCriteria.length - roundExcess(value);
  return conoscenzaEsperienzaCriteria[index];
};

export const getSituazioneFinanziariaLabel = (value: number) => {
  const index = situazioneFinanziariaCriteria.length - roundExcess(value);
  return situazioneFinanziariaCriteria[index];
};

export const getObiettiviInvestimentoLabel = (value: number) => {
  const index = obiettiviInvestimentoCriteria.length - roundExcess(value);
  return obiettiviInvestimentoCriteria[index];
};

export const getPropensioneRischioLabel = (value: number) => {
  const index = propensioneRischioCriteria.length - roundExcess(value);
  return propensioneRischioCriteria[index];
};
