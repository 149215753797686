import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Button, Table} from "reactstrap";
import {
  IconCheckCircle,
  IconCheckmark,
  IconClock,
  IconDownload,
  IconESign,
  IconEye,
  IconSpinner,
  IconTrash,
} from "../../../Icons";
import {IRootState} from "../../../redux/reducers";
import {IAppThunkActionStates} from "../../../types/thunk";
import {actions} from "../../actions";
import {
  getIsAdvisor,
  getIsContractor,
  getThunkActionStates,
} from "../../selectors";
import {IProposal, IProposalESign, ProposalStatus} from "../../types";
import ESignsManagementModal from "./DocumentsTable/ESignsManagementModal";
import UploadFileButton from "./DocumentsTable/UploadFileButton";

interface IProps {
  isSent: boolean;
  proposal: IProposal;
}

const eSignsCount = (eSigns: IProposalESign[], filter?: string) => {
  let filteredESigns = eSigns;
  if (filter) {
    filteredESigns = eSigns.filter((eSign) => eSign.whoEsign === filter);
  }

  const total = filteredESigns.length;
  const partial = filteredESigns.filter((eSign) => eSign.esignId).length;
  return [partial, total];
};

const DocumentsTable = ({proposal, isSent}: IProps) => {
  const dispatch = useDispatch();
  const uploadDocumentStates = useSelector<IRootState, IAppThunkActionStates>(
    (state) => getThunkActionStates(state, "uploadDocument")
  );
  const deleteDocumentStates = useSelector<
    IRootState,
    {[key: string]: IAppThunkActionStates}
  >((state) => getThunkActionStates(state, "deleteDocument", "ALL"));

  const isContractor = useSelector<IRootState, boolean>((state) =>
    getIsContractor(state, proposal.id)
  );
  const isAdvisor = useSelector<IRootState, boolean>((state) =>
    getIsAdvisor(state, proposal.id)
  );

  const allAdvisorESigns = proposal?.eSign?.files.every((file) =>
    file.esigns
      .filter((eSign) => eSign.whoEsign === "advisor")
      .every((eSign) => !!eSign.esignId)
  );

  const uploadFile = (acceptedFiles: File[], fileName: string) => {
    dispatch(actions.uploadDocument(proposal.id, acceptedFiles[0], fileName));
  };
  const openESignsManagementModal = (fileName: string) => {
    dispatch(actions.openESignsManagementModal(fileName));
  };
  const handleDelete = (fileName: string) => {
    dispatch(actions.deleteDocument(proposal.id, fileName));
  };

  return proposal.eSign ? (
    <>
      <Table striped bordered responsive>
        <thead>
          <tr>
            <th style={{width: "170px"}}>Azioni</th>
            <th>Documento</th>
            <th>Caricamento</th>
            <th>Firma consulente</th>
            <th>Firma cliente</th>
          </tr>
        </thead>
        <tbody>
          {proposal.eSign.files.map((document) => {
            const [partialAdvisorESign, totalAdvisorESign] = eSignsCount(
              document.esigns,
              "advisor"
            );
            const [partialContractorESign, totalContractorESign] = eSignsCount(
              document.esigns,
              "contractor"
            );

            return (
              <tr key={document.fileName}>
                <td>
                  {document.uploaded ? (
                    [
                      ProposalStatus.Complete,
                      ProposalStatus.ToBeValidated,
                      ProposalStatus.WaitReview,
                      ProposalStatus.Rejected,
                    ].includes(proposal.status) ? (
                      <div className="d-flex">
                        <Button
                          color="primary"
                          size="sm"
                          outline
                          download
                          className="flex-grow-1"
                          href={`${process.env.REACT_APP_API_BASE_URL}proposals/${proposal.id}/download-file-esign?fileName=${document.fileName}`}
                        >
                          <IconDownload /> Scarica la proposta firmata
                        </Button>
                      </div>
                    ) : (
                      <div className="d-flex">
                        <Button
                          color="primary"
                          size="sm"
                          outline
                          download
                          className="flex-grow-1"
                          href={`${process.env.REACT_APP_API_BASE_URL}proposals/${proposal.id}/download-file?fileName=${document.fileName}`}
                        >
                          <IconEye /> Visualizza il documento
                        </Button>
                        {isAdvisor && !isSent && (
                          <Button
                            color="danger"
                            size="sm"
                            outline
                            className="flex-grow-0 ml-2"
                            title="Elimina"
                            onClick={() => handleDelete(document.fileName)}
                          >
                            {deleteDocumentStates[document.fileName]
                              ?.isPending ? (
                              <IconSpinner className="icon-spin" />
                            ) : (
                              <IconTrash />
                            )}
                          </Button>
                        )}
                      </div>
                    )
                  ) : (
                    <div className="d-flex">
                      {isAdvisor ? (
                        <UploadFileButton
                          fileName={document.fileName}
                          onDrop={uploadFile}
                        />
                      ) : (
                        <span>Non ancora caricato</span>
                      )}
                    </div>
                  )}
                </td>
                <td>
                  {document.fileName}
                  {document.requiredFile && " (Obbligatorio)"}
                </td>
                <td>
                  {document.uploaded ? (
                    <>
                      <IconCheckmark color="success" /> Caricato
                    </>
                  ) : (
                    <>
                      <IconClock /> In attesa
                    </>
                  )}
                </td>
                {document.uploaded ? (
                  totalAdvisorESign > 0 ? (
                    <td>
                      {partialAdvisorESign} di {totalAdvisorESign}
                      {partialAdvisorESign === totalAdvisorESign && (
                        <IconCheckCircle
                          title="Completato"
                          className="text-success ml-2"
                        />
                      )}
                      {isAdvisor && (
                        <Button
                          color="primary"
                          size="sm"
                          outline
                          className="ml-4"
                          onClick={() =>
                            openESignsManagementModal(document.fileName)
                          }
                        >
                          {totalAdvisorESign > partialAdvisorESign ? (
                            <>
                              <IconESign /> Firma
                            </>
                          ) : (
                            <>
                              <IconEye /> Visualizza
                            </>
                          )}
                        </Button>
                      )}
                    </td>
                  ) : (
                    <td>Nessuna firma richiesta</td>
                  )
                ) : (
                  <td>In attesa caricamento file</td>
                )}
                {document.uploaded ? (
                  totalContractorESign > 0 ? (
                    <td>
                      {partialContractorESign} di {totalContractorESign}
                      {partialContractorESign === totalContractorESign && (
                        <IconCheckCircle
                          title="Completato"
                          className="text-success ml-2"
                        />
                      )}
                      {isContractor && allAdvisorESigns && (
                        <Button
                          color="primary"
                          size="sm"
                          outline
                          className="ml-4"
                          onClick={() =>
                            openESignsManagementModal(document.fileName)
                          }
                        >
                          {totalContractorESign > partialContractorESign ? (
                            <>
                              <IconESign /> Firma
                            </>
                          ) : (
                            <>
                              <IconEye /> Visualizza
                            </>
                          )}
                        </Button>
                      )}
                      <ESignsManagementModal
                        proposal={proposal}
                        isAdvisor={isAdvisor}
                        isContractor={isContractor}
                        fileName={document.fileName}
                      />
                    </td>
                  ) : (
                    <td>Nessuna firma richiesta</td>
                  )
                ) : (
                  <td>In attesa caricamento file</td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
      {uploadDocumentStates.isFail && (
        <Alert color="danger">{uploadDocumentStates.error}</Alert>
      )}
      {deleteDocumentStates["DEFAULT"]?.isFail && (
        <Alert color="danger">{deleteDocumentStates["DEFAULT"]?.error}</Alert>
      )}
    </>
  ) : null;
};

export default DocumentsTable;
