import React from "react";
import {Alert} from "reactstrap";
import {
  IconCheckCircle,
  IconDanger,
  IconInfoCircleSolid,
  IconWarning,
} from "../../Icons";
import {RecommendationStatus} from "../types";

interface IProps {
  isBackOffice: boolean;
  recommendationStatus: RecommendationStatus;
}

const StatusAlert: React.FC<IProps> = ({
  isBackOffice,
  recommendationStatus,
}) => {
  if (
    recommendationStatus === RecommendationStatus.STATUS_REQUEST_ESIGN_ADVISOR
  ) {
    return (
      <Alert color="danger">
        <IconDanger /> La raccomandazione non è ancora stata firmata
        dall'advisor
      </Alert>
    );
  }

  if (recommendationStatus === RecommendationStatus.STATUS_REQUEST_ESIGN) {
    return (
      <Alert color="danger">
        <IconDanger /> La raccomandazione non è ancora stata firmata dal cliente
      </Alert>
    );
  }

  if (recommendationStatus === RecommendationStatus.STATUS_TO_BE_VALIDATED) {
    return (
      <Alert color="info">
        <IconInfoCircleSolid />{" "}
        {isBackOffice
          ? "raccomandazione da validare"
          : "raccomandazione è stata correttamente inviata al back office per l'approvazione"}
      </Alert>
    );
  }
  if (recommendationStatus === RecommendationStatus.STATUS_CORRESPONDING) {
    return (
      <Alert color="success">
        <IconCheckCircle /> La raccomandazione è stata validata e risulta
        congrua con i risultati del questionario IBIPs associato
      </Alert>
    );
  }
  if (recommendationStatus === RecommendationStatus.STATUS_WAIT_REVIEW) {
    return (
      <Alert color="warning">
        <IconWarning />{" "}
        {isBackOffice
          ? "In attesa di revisione"
          : "La raccomandazione è stata esaminata e non risulta congrua con i risultati dell'ultimo questionario IBIPs valido"}
      </Alert>
    );
  }
  if (recommendationStatus === RecommendationStatus.STATUS_NOT_VALIDATE) {
    return (
      <Alert color="danger">
        <IconDanger /> Non validabile
      </Alert>
    );
  }
  if (recommendationStatus === RecommendationStatus.STATUS_IBIPS_REQUEST) {
    return (
      <Alert color="danger">
        <IconDanger /> Richiesto rinnovo IBIPs
      </Alert>
    );
  }
  if (
    recommendationStatus === RecommendationStatus.STATUS_REQUEST_VERIFICATION
  ) {
    return (
      <Alert color="danger">
        <IconDanger /> Verifica adeguatezza richiesta
      </Alert>
    );
  }
  if (recommendationStatus === RecommendationStatus.STATUS_REJECTED) {
    return (
      <Alert color="info">
        <IconDanger /> Questa raccomandazione è archiviata, non è più possibile
        alcuna modifica.
      </Alert>
    );
  }
  return <Alert color="danger">Stato non valido</Alert>;
};

export default StatusAlert;
