import React from "react";
import {Alert} from "reactstrap";

interface IProps {
  uploadBulkError: string;
}

const FailFile: React.FC<IProps> = (props) => (
  <Alert color="danger">{props.uploadBulkError}</Alert>
);

export default FailFile;
