import {
  createEntityAdapter,
  createSlice,
  EntityState,
  PayloadAction,
} from "@reduxjs/toolkit";
import {Dictionary} from "@reduxjs/toolkit/src/entities/models";
import {createSelector} from "reselect";
import {actionTypes as userActionTypes} from "../User";
import {IEntityUtility} from "./entityUtilityFactory";

interface ICreateEntitySliceOptions<E, DB, H, NES> {
  entityUtility: IEntityUtility<E, DB, H, NES>;
  nameSpace: string;
  selectState: <S>(state: S) => EntityState<E>;
}
export const createEntitySlice = <E, DB, H, NES>({
  entityUtility,
  nameSpace,
  selectState,
}: ICreateEntitySliceOptions<E, DB, H, NES>) => {
  const entityAdapter = createEntityAdapter<E>();

  const entities = createSlice({
    name: nameSpace,
    initialState: entityAdapter.getInitialState(),
    reducers: {
      received: (state, action: PayloadAction<{[key: string]: E}>) =>
        entityAdapter.upsertMany(state as EntityState<E>, action),
    },
    extraReducers: (builder) => {
      builder.addCase(userActionTypes.LOGOUT_SUCCESS, () => {
        return entityAdapter.getInitialState();
      });
    },
  });

  // Entities selectors
  const adapterSelectors = entityAdapter.getSelectors(selectState);

  const fSelectEntitiesByIds = createSelector(
    adapterSelectors.selectEntities,
    (entities: Dictionary<E>) => (ids: ReadonlyArray<string>) =>
      ids.map((id) => entities[id])
  );

  // parsed selectors
  const selectById = createSelector([adapterSelectors.selectById], (byId) => {
    if (!byId) {
      return undefined;
    }

    return entityUtility.deserialize(byId);
  });

  const selectAll = createSelector([adapterSelectors.selectAll], (all) => {
    return all
      .filter((entity): entity is E => !!entity)
      .map(entityUtility.deserialize);
  });

  const fSelectByIds = createSelector(
    [fSelectEntitiesByIds],
    (byIds) => (ids: ReadonlyArray<string>) => {
      return byIds(ids)
        .filter((entity): entity is E => !!entity)
        .map(entityUtility.deserialize);
    }
  );

  return {
    selectors: {
      entities: {
        ...adapterSelectors,
        selectByIds: fSelectEntitiesByIds,
      },
      selectById,
      selectAll,
      fSelectByIds,
    },
    actions: entities.actions,
    reducer: entities.reducer,
  };
};
