import moment from "moment";
import React from "react";
import {connect} from "react-redux";
import {Alert, Col, FormGroup, Label, Row} from "reactstrap";
import {
  Field,
  Form,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from "redux-form";

import FormatNumber from "../helpers/FormatNumber";
import RenderSlider from "../helpers/RenderSlider";
import {
  dannoBiologicoColIndex,
  dannoBiologicoFemmine,
  dannoBiologicoMaschi,
  dannoBiologicoRowIndex,
  dannoRenditaAnnua,
} from "../helpers/tabelleCalcoli";
import {IconWarning} from "../Icons";
import {IRootState} from "../redux/reducers";
import {IUser} from "../Users";
import {IEmploymentFormData} from "./EmploymentForm";

export interface IInailTableFormData {
  invaliditaPermanente: number;
  morte: boolean;
}

interface IStateProps {
  invaliditaPermanenteValue?: number;
  morteValue?: boolean;
}

interface IOwnProps {
  employments: IEmploymentFormData;
  contractor: IUser;
}

type IProps = InjectedFormProps<IInailTableFormData, IOwnProps> &
  IStateProps &
  IOwnProps;

const InailTable: React.FC<IProps & IOwnProps> = (props) => {
  // PARAMETRI
  const lavoro =
    props.employments && props.employments.currentEmployment.tipologia;

  const redditoNetto =
    (props.employments &&
      props.employments.currentEmployment.redditoNormalizzato) ||
    0;
  const redditoLordo =
    props.employments && props.employments.currentEmployment.reddito;

  const genere = props.contractor.gender;

  const oggi = moment();
  const anni = Math.floor(
    moment.duration(oggi.diff(props.contractor.birthday)).as("years")
  );

  const invaliditaPermanente = props.invaliditaPermanenteValue || 0;
  const morte: boolean = props.morteValue || false;

  let dannoBiologico: number = 0;
  let dannoPatrimoniale: number = 0;
  let renditaAnnuaDannoBio: number = 0;
  let renditaAnnuaTotale = 0;
  let renditaConvenzionale = 0;

  let coefficiente: number = 0;
  let col: number = -1;
  let row: number = -1;

  const categoria =
    props.employments && props.employments.currentEmployment.categoria;

  // Calcolo danno biologico
  if (invaliditaPermanente < 6 || invaliditaPermanente >= 16) {
    dannoBiologico = 0;
  } else {
    col = (dannoBiologicoColIndex.find((val) => anni <= val[0]) ||
      dannoBiologicoColIndex[dannoBiologicoColIndex.length])[1];

    if (invaliditaPermanente === 15) {
      row = 9;
    } else {
      row = dannoBiologicoRowIndex[invaliditaPermanente];
    }

    if (genere === "m") {
      dannoBiologico = dannoBiologicoMaschi[row][col];
    } else if (genere === "f") {
      dannoBiologico = dannoBiologicoFemmine[row][col];
    }

    if (
      lavoro === "disoccupato" ||
      lavoro === "pensione" ||
      categoria === "altro"
    ) {
      dannoBiologico = 0;
    }
  }

  // Calcolo rendita annua capofamiglia:
  if (invaliditaPermanente < 16) {
    renditaAnnuaTotale = 0;
  } else {
    renditaAnnuaDannoBio = dannoRenditaAnnua[invaliditaPermanente];

    if (invaliditaPermanente > 15 && invaliditaPermanente <= 20) {
      coefficiente = 0.4;
    } else if (invaliditaPermanente > 20 && invaliditaPermanente <= 25) {
      coefficiente = 0.5;
    } else if (invaliditaPermanente > 25 && invaliditaPermanente <= 35) {
      coefficiente = 0.6;
    } else if (invaliditaPermanente > 35 && invaliditaPermanente <= 50) {
      coefficiente = 0.7;
    } else if (invaliditaPermanente > 50 && invaliditaPermanente <= 70) {
      coefficiente = 0.8;
    } else if (invaliditaPermanente > 70 && invaliditaPermanente <= 85) {
      coefficiente = 0.9;
    } else {
      coefficiente = 1;
    }

    if (lavoro === "dipendente" || lavoro === "subordinato") {
      renditaConvenzionale = redditoNetto * 12; // NETTO

      if (renditaConvenzionale > 27200) {
        renditaConvenzionale = 27200;
      }

      if (renditaConvenzionale < 14400) {
        renditaConvenzionale = 14400;
      }

      dannoPatrimoniale =
        (renditaConvenzionale * coefficiente * invaliditaPermanente) / 100; // TRASFORMO NETTO IN LORDO

      renditaAnnuaTotale = renditaAnnuaDannoBio + dannoPatrimoniale; // LORDO + LORDO
    } else if (lavoro === "autonomo") {
      if (categoria === "altro") {
        dannoBiologico = 0;
        renditaAnnuaTotale = 0;
      } else {
        renditaConvenzionale = parseFloat(redditoLordo || "0");

        if (renditaConvenzionale > 27200) {
          renditaConvenzionale = 27200;
        }

        if (renditaConvenzionale < 14400) {
          renditaConvenzionale = 14400;
        }

        if (categoria === "commerciante") {
          renditaConvenzionale = 14400;
        }

        dannoPatrimoniale =
          (renditaConvenzionale * coefficiente * invaliditaPermanente) / 100;

        renditaAnnuaTotale = renditaAnnuaDannoBio + dannoPatrimoniale; // VISUALIZZO NETTO
      }
    } else if (lavoro === "disoccupato" || lavoro === "pensione") {
      renditaAnnuaTotale = 0;
      dannoBiologico = 0;
    }
  }

  // TODO: Eliminare la connessione tramite reduxForm e creare invece una
  //  con redux liscio connessione (onChange => action => reducers => state)

  const echo = (values: IInailTableFormData) => {
    console.log(values); // tslint:disable-line
  };

  return (
    <Form onSubmit={echo}>
      <>
        {lavoro === "autonomo" && categoria === "altro" ? (
          <Alert color="danger" className="mt-3 mb-0 alertred">
            <IconWarning size="2" />
            <span className="ml-3 mr-3">
              In funzione dei dati forniti rispetto alla Sua occupazione
              attuale, non ha diritto alla tutela INAIL
            </span>
            <IconWarning size="2" />
          </Alert>
        ) : null}
        <Row className="mt-3">
          <Col sm="3">
            <Label>Invalidità permanente: </Label>
          </Col>
          <Col
            sm="9"
            className="pb-5 d-flex align-items-center overflow-slider"
          >
            <Field
              id="invaliditaPermanente"
              name="invaliditaPermanente"
              marks={{
                0: "0",
                6: "6",
                16: "16",
                67: "67",
                100: "100",
              }}
              min={0}
              max={100}
              component={RenderSlider}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm="3">
            <Label>Rimborso una tantum: </Label>
          </Col>
          <Col sm="9">
            {dannoBiologico === 0 ? (
              <Label className="text-danger">{dannoBiologico} €</Label>
            ) : (
              <Label>
                <FormatNumber>{dannoBiologico}</FormatNumber> €
              </Label>
            )}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col sm="3">
            <Label>Rendita annua: </Label>
          </Col>
          <Col sm="9">
            {renditaAnnuaTotale === 0 ? (
              <Label className="text-danger">
                {renditaAnnuaTotale.toFixed(2)} €
              </Label>
            ) : (
              <Label>
                <FormatNumber>{renditaAnnuaTotale}</FormatNumber> €
              </Label>
            )}
          </Col>
        </Row>
        <FormGroup>
          <Label>
            <Field id="morte" name="morte" type="checkbox" component="input" />{" "}
            In caso di decesso
          </Label>
        </FormGroup>
        {morte ? (
          <Alert color="danger" className="mt-3 mb-3 alertred">
            <span>
              L'INAIL riconosce all'eventuale coniuge una reversibilità del 50%,
              mentre non spetta nulla ad eventuali conviventi
            </span>
          </Alert>
        ) : null}
      </>
    </Form>
  );
};

const InailTableFormConnected = reduxForm<IInailTableFormData, IOwnProps>({
  form: "inailTable",
  initialValues: {
    invaliditaPermanente: 0,
    morte: false,
  },
})(InailTable);

const selector = formValueSelector("inailTable");
export default connect((state: IRootState) => {
  const invaliditaPermanenteValue = selector(state, "invaliditaPermanente");
  const morteValue = selector(state, "morte");
  return {
    invaliditaPermanenteValue,
    morteValue,
  };
})(InailTableFormConnected);
