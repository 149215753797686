import moment from "moment";
import categoryTax from "./categoryTax";

export const rangeEtaPensionabile = [
  [2018, 2020, 66, 7],
  [2021, 2022, 67, 2],
  [2023, 2024, 67, 5],
  [2025, 2026, 67, 8],
  [2027, 2028, 67, 11],
  [2029, 2030, 68, 1],
  [2031, 2032, 68, 3],
  [2033, 2034, 68, 5],
  [2035, 2036, 68, 7],
  [2037, 2038, 68, 9],
  [2039, 2040, 68, 11],
  [2041, 2042, 69, 1],
  [2043, 2044, 69, 3],
  [2045, 2046, 69, 5],
  [2047, 2048, 69, 7],
];

export const etaMassimaPerSimulazione = 66;
export const etaInizioLavoro = 16;

export const CoefficientiCrescitaOptions = {
  high: 0.0298, // 0.029894781388920766
  low: 0.0099, // 0.009960778743762333
  mid: 0.0192, //  0.0192912998178542
};

export const calcoloEtaPensionabile = (birthday: moment.Moment) => {
  let etaPensionabile = 0;
  let days;
  let years = 0;
  let months = 0;

  for (const [start, end, y, m] of rangeEtaPensionabile) {
    days = y * 365 + m * 30;
    const checkDate = birthday.clone().add(days, "days");
    if (checkDate.year() >= start && checkDate.year() <= end) {
      months = m;
      years = y;
      etaPensionabile = y + m / 12;
      break;
    }
  }
  if (!etaPensionabile) {
    const range = rangeEtaPensionabile[rangeEtaPensionabile.length - 1];
    months = range[3];
    years = range[2];
    etaPensionabile = years + months / 12;
  }
  return {etaPensionabile, years, months};
};

const redditoDaLavoroFinale = (
  an: number,
  ep: number,
  rl: number,
  coeffCrescita: number
) => {
  const anni = an + ep - 2018;
  const rf = rl * (1 + coeffCrescita) ** anni;
  return rf;
};

const calcoloMontanteRetributivo = (
  rl: number,
  anniVersati: number,
  anniMancanti: number,
  coeffCrescita: number,
  coeffInps: number
) => {
  let montanteContributivo = 0;
  let montanteContributivoAnnoCorrente = 0;
  // retribuzione iniziale
  const retribuzioneIniziale = rl / (1 + coeffCrescita) ** anniVersati;

  // anni totali di versamento
  const anniTotali = anniVersati + anniMancanti;

  // anni mancanti al pensionamento
  let retribuzioneRivalutataAnnualmente = retribuzioneIniziale;

  for (let i = 0; i < anniTotali; i++) {
    montanteContributivoAnnoCorrente =
      retribuzioneRivalutataAnnualmente * coeffInps;
    montanteContributivo =
      montanteContributivo + montanteContributivoAnnoCorrente;
    retribuzioneRivalutataAnnualmente =
      retribuzioneRivalutataAnnualmente +
      retribuzioneRivalutataAnnualmente * coeffCrescita;
  }

  // coefficiente di correzione empirico
  const coeffCorrezione = (96 + 0.43 * anniVersati) / 100;

  montanteContributivo *= coeffCorrezione;

  return montanteContributivo;
};

export const retirementEstimate = ({
  anniVersati,
  categoria,
  crescita,
  dataDiNascita,
  redditoLordo,
}: {
  anniVersati: number;
  categoria: string;
  crescita: "low" | "mid" | "high";
  dataDiNascita: moment.Moment;
  redditoLordo: number;
}) => {
  // const sesso = "M"; // sembra irrilevante er il calcolo
  const coeffCrescita = CoefficientiCrescitaOptions[crescita];
  const coeffInps = categoryTax[categoria];

  const annoDiNascita = dataDiNascita.year();
  const etaDiPensionamento = Math.floor(
    calcoloEtaPensionabile(dataDiNascita).etaPensionabile
  );
  const annoDiPensionamento = annoDiNascita + etaDiPensionamento;
  const anniMancantiAlPensionamento = annoDiPensionamento - moment().year(); // anno corrente

  const rf = redditoDaLavoroFinale(
    annoDiNascita,
    etaDiPensionamento,
    redditoLordo,
    coeffCrescita
  );
  const ultimoStipendio = rf / 12;

  const mr = calcoloMontanteRetributivo(
    redditoLordo,
    anniVersati,
    anniMancantiAlPensionamento,
    coeffCrescita,
    coeffInps
  );
  const pensioneAnnua = mr * 0.0645; // ma è sempre uguale?
  const pensioneMensile = pensioneAnnua / 12;
  const gapAnnuo = pensioneAnnua - rf;
  const gapMensile = pensioneMensile - ultimoStipendio;

  // Netti
  const rfNetto = rf / 1.43;
  const pensioneAnnuaNetta = pensioneAnnua / 1.3;
  const pensioneMensileNetta = pensioneMensile / 1.3;
  const ultimoStipendioNetto = ultimoStipendio / 1.43;
  const gapAnnuoNetto = pensioneAnnuaNetta - rfNetto;
  const gapMensileNetto = pensioneMensileNetta - ultimoStipendioNetto;

  return {
    annoDiPensionamento,
    etaPensionabile: calcoloEtaPensionabile(dataDiNascita),
    lordo: {
      gapAnnuo,
      gapMensile,
      pensioneMensile,
      ultimoStipendio,
    },
    netto: {
      gapAnnuo: gapAnnuoNetto,
      gapMensile: gapMensileNetto,
      pensioneMensile: pensioneMensileNetta,
      ultimoStipendio: ultimoStipendioNetto,
    },
  };
};
