import {ResizeObserver} from "@juggle/resize-observer";
import React from "react";
import {useFormContext} from "react-hook-form";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {animated, useSpring} from "react-spring";
import useMeasure from "react-use-measure";
import {Card} from "reactstrap";
import {IRootState} from "../../redux/reducers";
import {selectors} from "../slice";
import {IBeneProposalViewRouteParams} from "../View";
import {AlertDateAndButton} from "./AlertDateAndButton";
import House from "./ChoseProductLevel/House";
import Injuries from "./ChoseProductLevel/Injuries";
import {Rc} from "./ChoseProductLevel/Rc";
import "./ChoseProductType.scss";

interface IProps {
  isView: boolean;
}

const AnimatedCard = animated(Card);
const padding = 20;
const border = 1;

const ChoseProductLevel = ({isView}: IProps) => {
  const {id} = useParams<Partial<IBeneProposalViewRouteParams>>();

  const {errors, formState, register, trigger, watch} = useFormContext();
  const type = watch("type");
  const level = watch("level");
  const legalForm = watch("rc.legalForm");

  const [ref, {height}] = useMeasure({polyfill: ResizeObserver});
  const props = useSpring({
    height: isView ? "auto" : height + padding * 2 + border * 2,
  });

  const isAdvisor = useSelector(
    (state: IRootState) => !!id && selectors.selectIsAdvisor(state, id)
  );

  return (
    <AnimatedCard
      color="drawer"
      body
      className="border-primary"
      style={{overflow: "hidden", ...props}}
    >
      <div ref={ref}>
        {type === "house" && <House disabled={isView} />}
        {type === "injuries" && <Injuries disabled={isView} />}
        {type === "rc" && <Rc disabled={isView} />}
        {(isAdvisor || !isView) && (
          <AlertDateAndButton
            disabled={!level && !legalForm}
            errors={errors}
            formState={formState}
            isView={isView}
            register={register}
            trigger={trigger}
          />
        )}
      </div>
    </AnimatedCard>
  );
};

export default ChoseProductLevel;
