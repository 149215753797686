import Slider, {Handle} from "rc-slider";
import "rc-slider/assets/index.css";
import Tooltip from "rc-tooltip";
import React from "react";

const handle = (props: any) => {
  const {value, dragging, index, ...restProps} = props;
  return (
    <Tooltip
      prefixCls="rc-slider-tooltip"
      overlay={value}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </Tooltip>
  );
};

const RenderSlider = ({input, ...props}: any) => {
  const newInput = {...input, value: parseFloat(input.value)};
  return (
    <Slider
      handle={handle}
      value={newInput.value}
      onChange={newInput.onChange}
      {...newInput}
      {...props}
    />
  );
};

export default RenderSlider;
