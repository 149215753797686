import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import platform from "platform";
import React from "react";
import {
  FieldErrors,
  NestedValue,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router";
import {
  Alert,
  Button,
  CustomInput,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {IconCheckmark, IconSend, IconSpinner} from "../../Icons";
import {IRootState} from "../../redux/reducers";
import {IAppThunkActionStates} from "../../types/thunk";
import {getUser} from "../../User/selectors";
import {actions} from "../actions";
import {getThunkActionStates} from "../selectors";

interface INewTicketFormData {
  user: string;
  version: string;
  os: string;
  browser: string;
  url: string;
  area: string;
  contractor?: string;
  title: string;
  description: string;
  attachment: NestedValue<File[]>;
}

interface IProps {
  toggle: () => void;
}

const validate = async (values: INewTicketFormData) => {
  const errors: FieldErrors<INewTicketFormData> = {};

  if (!values.area) {
    errors.area = {
      type: "required",
      message: "Scegli la sezione in cui si è verificato il problema",
    };
  }
  if (!values.title) {
    errors.title = {
      type: "required",
      message: "Inserisci il titolo descrittivo del problema",
    };
  }
  if (!values.description) {
    errors.description = {
      type: "required",
      message: "Inserisci la descrizione dettagliata del problema",
    };
  }

  return {values: isEmpty(errors) ? values : {}, errors};
};

const NewTicketForm = ({toggle}: IProps) => {
  const dispatch = useDispatch();

  const {register, handleSubmit, errors, formState} = useForm<
    INewTicketFormData
  >({
    mode: "onBlur",
    resolver: validate,
  });
  const location = useLocation();
  const loggedUser = useSelector(getUser);
  const createStates = useSelector<IRootState, IAppThunkActionStates>((state) =>
    getThunkActionStates(state, "create")
  );

  const osInfo = JSON.stringify({
    description: platform.os?.toString(),
    ...platform.os,
  });
  const browserInfo = JSON.stringify(omit(platform, ["os"]));
  const urlInfo = JSON.stringify(location);

  const submit: SubmitHandler<INewTicketFormData> = (values) => {
    // Sappiamo che è un FileList ma dà errore, lo trasformiamo
    const attachment = values.attachment?.[0];
    dispatch(actions.create({...values, attachment}));
  };

  return (
    <Form onSubmit={handleSubmit(submit)}>
      <ModalBody>
        <Input
          type="hidden"
          name="user"
          innerRef={register}
          defaultValue={loggedUser?.id}
        />
        <Input
          type="hidden"
          name="version"
          innerRef={register}
          defaultValue={process.env.REACT_APP_VERSION}
        />
        <Input
          type="hidden"
          name="os"
          innerRef={register}
          defaultValue={osInfo}
        />
        <Input
          type="hidden"
          name="browser"
          innerRef={register}
          defaultValue={browserInfo}
        />
        <Input
          type="hidden"
          name="url"
          innerRef={register}
          defaultValue={urlInfo}
        />
        <FormGroup>
          <Label for="area">
            In che sezione stavi lavorando quando si è verificato il problema?
          </Label>
          <Input
            type="select"
            name="area"
            id="area"
            innerRef={register}
            invalid={!!errors?.area}
            valid={formState.touched.area && !errors?.area}
          >
            <option value="">Seleziona area</option>
            <option value="cap">Analisi</option>
            <option value="recommendation">Raccomandazione</option>
            <option value="proposal">Proposta</option>
            <option value="bene-proposal">Bene Assicurazioni</option>
            <option value="profile">Gestione account</option>
            <option value="users">Gestione utenti</option>
          </Input>
          {errors?.area && <FormFeedback>{errors.area.message}</FormFeedback>}
        </FormGroup>
        <FormGroup>
          <Label for="title">Che cosa è successo?</Label>
          <Input
            type="text"
            name="title"
            id="title"
            placeholder="Titolo che descrive il problema"
            innerRef={register}
            invalid={!!errors?.title}
            valid={formState.touched.title && !errors?.title}
          />
          {errors?.title && <FormFeedback>{errors.title.message}</FormFeedback>}
        </FormGroup>
        <FormGroup>
          <Label for="description">
            Puoi darci maggiori dettagli su come si è generato il problema?
          </Label>
          <Input
            type="textarea"
            name="description"
            id="description"
            placeholder="Descrizione dettagliata del problema"
            innerRef={register}
            invalid={!!errors?.description}
            valid={formState.touched.description && !errors?.description}
          />
          {errors?.description && (
            <FormFeedback>{errors.description.message}</FormFeedback>
          )}
        </FormGroup>
        <p className="mt-4">
          Completa la segnalazione compilando i campi facoltativi:
        </p>
        <FormGroup>
          <Label for="contractor">Nome e cognome del cliente</Label>
          <Input
            type="text"
            name="contractor"
            id="contractor"
            placeholder="Nome e cognome del cliente"
            innerRef={register}
            invalid={!!errors?.contractor}
            valid={formState.touched.contractor && !errors?.contractor}
          />
          {errors?.contractor && (
            <FormFeedback>{errors.contractor.message}</FormFeedback>
          )}
        </FormGroup>
        <FormGroup className="mb-0">
          <Label for="attachment">Allega file</Label>
          <CustomInput
            multiple={false}
            accept="image/*,application/pdf"
            type="file"
            name="attachment"
            id="attachment"
            innerRef={register}
            label="Scegli un file"
          />
        </FormGroup>
        {createStates.isFail && (
          <Alert color="danger" className="mb-0 mt-3">
            {createStates.error}
          </Alert>
        )}
      </ModalBody>
      {createStates.isSuccess ? (
        <ModalFooter>
          <Alert color="success" className="mr-2 flex-grow-1">
            <IconCheckmark /> Feedback inviato
          </Alert>
          <Button color="secondary" onClick={toggle}>
            Chiudi
          </Button>
        </ModalFooter>
      ) : (
        <ModalFooter>
          <Button
            color="primary"
            className="mr-2"
            disabled={createStates.isPending}
          >
            {createStates.isPending ? (
              <IconSpinner className="icon-spin" />
            ) : (
              <IconSend />
            )}{" "}
            Invia
          </Button>
          <Button color="secondary" onClick={toggle}>
            Annulla
          </Button>
        </ModalFooter>
      )}
    </Form>
  );
};

export default NewTicketForm;
