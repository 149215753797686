import {connect} from "react-redux";
import {actions, NewCapModalTypes} from "../../Caps";
import {IUploadIdFormData} from "../../Caps/UploadIdForm";
import {IRootState} from "../../redux/reducers";
import IApiError from "../../types/IApiError";
import {actions as userActions} from "../../User";
import {hydrateUserProperties} from "../../Users";
import {getUser} from "../selectors";
import Page from "./Page";

const mapStateToProps = (state: IRootState) => {
  return {
    capUi: state.caps.ui,
    ui: state.user,
    user: hydrateUserProperties(getUser(state) as any),
  };
};

const mapDispatchToProps = {
  closeModal: (modalType: NewCapModalTypes) => {
    return actions.closeNewCapModal(modalType);
  },
  openModal: (modalType: NewCapModalTypes) => {
    return actions.openNewCapModal(modalType);
  },
  uploadIdFail: (error: IApiError) => {
    return userActions.uploadIdFail(error);
  },
  uploadIdSubmit: (values: IUploadIdFormData) => {
    return userActions.uploadId(values);
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(Page);
