import IRoutes from "../types/IRoutes";
import ManualeOperativo from "./ManualeOperativo";
import DatiSocietari from "./DatiSocietari";

const routes: IRoutes = [
  {
    component: ManualeOperativo,
    name: "manuale-operativo",
    path: "/manuale-operativo",
  },
  {
    component: DatiSocietari,
    name: "dati-societari",
    path: "/dati-societari",
  },
];

export default routes;
