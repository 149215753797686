import {Dispatch} from "redux";
import IApiError from "../../types/IApiError";
import {service} from "../index";
import {IInvestmentCompany} from "../types";

export enum ActionTypes {
  LOAD_COMPANIES_REQUEST = "[Caps] Load companies request",
  LOAD_COMPANIES_SUCCESS = "[Caps] Load companies success",
  LOAD_COMPANIES_FAIL = "[Caps] Load companies fail",
}

interface ILoadCompaniesFailAction {
  type: typeof ActionTypes.LOAD_COMPANIES_FAIL;
  payload: {error: IApiError};
}
interface ILoadCompaniesRequestAction {
  type: typeof ActionTypes.LOAD_COMPANIES_REQUEST;
}
interface ILoadCompaniesSuccessAction {
  type: typeof ActionTypes.LOAD_COMPANIES_SUCCESS;
  payload: {companies: IInvestmentCompany[]};
}

export const actions = {
  loadCompanies: () => (dispatch: Dispatch<Actions>) => {
    dispatch(actions.loadCompaniesRequest());
    return service
      .loadCompanies()
      .then((companies) => {
        dispatch(actions.loadCompaniesSuccess(companies));
      })
      .catch((error) => {
        dispatch(actions.loadCompaniesFail(error));
      });
  },
  loadCompaniesFail: (error: IApiError): ILoadCompaniesFailAction => ({
    payload: {error},
    type: ActionTypes.LOAD_COMPANIES_FAIL,
  }),
  loadCompaniesRequest: (): ILoadCompaniesRequestAction => ({
    type: ActionTypes.LOAD_COMPANIES_REQUEST,
  }),
  loadCompaniesSuccess: (
    companies: IInvestmentCompany[]
  ): ILoadCompaniesSuccessAction => {
    return {
      payload: {companies},
      type: ActionTypes.LOAD_COMPANIES_SUCCESS,
    };
  },
};

export type Actions =
  | ILoadCompaniesFailAction
  | ILoadCompaniesRequestAction
  | ILoadCompaniesSuccessAction;
