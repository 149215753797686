import React from "react";

import {animated, Transition} from "react-spring/renderprops";
import {Badge, Card, Table} from "reactstrap";

import {IIddSnapshot, IInvestmentOption} from "..";

import {
  conoscenzaEsperienzaCriteria,
  obiettiviInvestimentoCriteria,
  orizzonteTemporaleCriteria,
  propensioneRischioCriteria,
  situazioneFinanziariaCriteria,
} from "./domandeIdd";

import {IconCheckmark, IconTimes} from "../../Icons";

interface IProps {
  idd: IIddSnapshot;
  option: IInvestmentOption;
}

const OptionCriteriaTableInner: React.FC<IProps> = ({idd, option}) => {
  // console.log("CAP JOHN"); // tslint:disable-line
  // console.log({idd}); // tslint:disable-line
  option.criteria = JSON.parse(option.criteriaJson);
  return (
    <div className="bg-drawer p-3 auto-margin-3">
      <Card color="drawer" body className="border-primary option-details">
        <Table borderless size="sm" className="border-primary mb-0">
          <tbody>
            <tr>
              <td>Conoscenza ed esperienza</td>
              {conoscenzaEsperienzaCriteria.map((criterio) =>
                option.criteria!.conoscenzaEsperienza.includes(criterio) ? (
                  <td key={criterio}>
                    {idd.idd.conoscenzaEsperienza.label === criterio ? (
                      <Badge color="primary">
                        <IconCheckmark /> {criterio}
                      </Badge>
                    ) : (
                      <>
                        <IconCheckmark color="success" /> {criterio}
                      </>
                    )}
                  </td>
                ) : (
                  <td key={criterio}>
                    {idd.idd.conoscenzaEsperienza.label === criterio ? (
                      <Badge color="primary">
                        <IconTimes /> {criterio}
                      </Badge>
                    ) : (
                      <>
                        <IconTimes color="danger" /> {criterio}
                      </>
                    )}
                  </td>
                )
              )}
            </tr>
            <tr>
              <td>Situazione finanziaria</td>
              {situazioneFinanziariaCriteria.map((criterio) =>
                option.criteria!.situazioneFinanziaria.includes(criterio) ? (
                  <td key={criterio}>
                    {idd.idd.situazioneFinanziaria.label === criterio ? (
                      <Badge color="primary">
                        <IconCheckmark /> {criterio}
                      </Badge>
                    ) : (
                      <>
                        <IconCheckmark color="success" /> {criterio}
                      </>
                    )}
                  </td>
                ) : (
                  <td key={criterio}>
                    {idd.idd.situazioneFinanziaria.label === criterio ? (
                      <Badge color="primary">
                        <IconTimes /> {criterio}
                      </Badge>
                    ) : (
                      <>
                        <IconTimes color="danger" /> {criterio}
                      </>
                    )}
                  </td>
                )
              )}
            </tr>
            <tr>
              <td>Obiettivi di investimento</td>
              {obiettiviInvestimentoCriteria.map((criterio) =>
                option.criteria!.obiettiviInvestimento.includes(criterio) ? (
                  <td key={criterio}>
                    {idd.idd.obiettiviInvestimento.label === criterio ? (
                      <Badge color="primary">
                        <IconCheckmark /> {criterio}
                      </Badge>
                    ) : (
                      <>
                        <IconCheckmark color="success" /> {criterio}
                      </>
                    )}
                  </td>
                ) : (
                  <td key={criterio}>
                    {idd.idd.obiettiviInvestimento.label === criterio ? (
                      <Badge color="primary">
                        <IconTimes /> {criterio}
                      </Badge>
                    ) : (
                      <>
                        <IconTimes color="danger" /> {criterio}
                      </>
                    )}
                  </td>
                )
              )}
            </tr>
            <tr>
              <td>Propensione al rischio</td>
              {propensioneRischioCriteria.map((criterio) =>
                option.criteria!.propensioneRischio.includes(criterio) ? (
                  <td key={criterio}>
                    {idd.idd.propensioneRischio.label === criterio ? (
                      <Badge color="primary">
                        <IconCheckmark /> {criterio}
                      </Badge>
                    ) : (
                      <>
                        <IconCheckmark color="success" /> {criterio}
                      </>
                    )}
                  </td>
                ) : (
                  <td key={criterio}>
                    {idd.idd.propensioneRischio.label === criterio ? (
                      <Badge color="primary">
                        <IconTimes /> {criterio}
                      </Badge>
                    ) : (
                      <>
                        <IconTimes color="danger" /> {criterio}
                      </>
                    )}
                  </td>
                )
              )}
            </tr>
            <tr>
              <td>Orizzonte temporale</td>
              {orizzonteTemporaleCriteria.map((criterio) =>
                option.criteria!.orizzonteTemporale.includes(criterio) ? (
                  <td key={criterio}>
                    {idd.idd.orizzonteTemporale.label === criterio ? (
                      <Badge color="primary">
                        <IconCheckmark /> {criterio}
                      </Badge>
                    ) : (
                      <>
                        <IconCheckmark color="success" /> {criterio}
                      </>
                    )}
                  </td>
                ) : (
                  <td key={criterio}>
                    {idd.idd.orizzonteTemporale.label === criterio ? (
                      <Badge color="primary">
                        <IconTimes /> {criterio}
                      </Badge>
                    ) : (
                      <>
                        <IconTimes color="danger" /> {criterio}
                      </>
                    )}
                  </td>
                )
              )}
            </tr>
          </tbody>
        </Table>
      </Card>
      <ul className="list-inline text-center">
        <li className="list-inline-item">
          <IconCheckmark color="success" /> Adeguato
        </li>
        <li className="list-inline-item">
          <IconTimes color="danger" /> Non adeguato
        </li>
        <li className="list-inline-item">
          <Badge color="primary text-primary">Profilo utente</Badge> Profilo
          utente
        </li>
      </ul>
    </div>
  );
};

const OptionCriteriaTable = ({idd, option}: IProps) => (
  <Transition
    items={OptionCriteriaTableInner({idd, option})}
    from={{overflow: "hidden", height: 0}}
    enter={{height: "auto"}}
    leave={{height: 0}}
  >
    {(item) => {
      // console.log({item}); // tslint:disable-line
      return (
        item && ((style) => <animated.div style={style}>{item}</animated.div>)
      );
    }}
  </Transition>
);

export default OptionCriteriaTable;
