const arrayChunk = <T>(collection: T[], size: number = 2) => {
  const result = [];

  // add each chunk to the result
  for (let x = 0; x < Math.ceil(collection.length / size); x++) {
    const start = x * size;
    const end = start + size;

    result.push(collection.slice(start, end));
  }

  return result;
};

export default arrayChunk;
