import React from "react";

function escapeRegExp(regExpString: string) {
  return regExpString.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

export const getHighlightedText = (text: string, highlight: string) => {
  const highlights = highlight.split(" ").map((word) =>
    word
      .toLocaleLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .trim()
  );
  const pattern = escapeRegExp(`(${highlights.join("|")})`);
  const parts: (string | undefined)[] = text.split(new RegExp(pattern, "gi"));

  return (
    <span>
      {parts.map(
        (part, i) =>
          part && (
            <span
              key={i}
              style={
                highlights.includes(part.toLowerCase())
                  ? {fontWeight: "bold"}
                  : {}
              }
            >
              {part}
            </span>
          )
      )}{" "}
    </span>
  );
};
