import React from "react";
import {Alert} from "reactstrap";
import {IRevision} from "../types";

interface IProps {
  revisions: IRevision[];
}

const ProposalRevisions = ({revisions}: IProps) => {
  if (revisions.length === 0) {
    return null;
  }

  return (
    <>
      <p className="mt-3">
        <strong>Messaggi del Back-office:</strong>
      </p>
      {revisions
        .slice(0)
        .reverse()
        .map((revision) => (
          <Alert color="warning" key={revision.date.format()}>
            <h6 className="alert-heading">
              <em>{revision.date.format("LL[ alle ]LT")}</em>
            </h6>
            {revision.text}
          </Alert>
        ))}
    </>
  );
};

export default ProposalRevisions;
