import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Button, Container, Table} from "reactstrap";
import {Layout, LoaderOverlay, PageTitle} from "../App";
import {IconDownload} from "../Icons";
import {IRootState} from "../redux/reducers";
import {IAppThunkActionStates} from "../types/thunk";
import {actions} from "./actions";
import {getProposalProductDocuments, getThunkActionStates} from "./selectors";

const DownloadDocument = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.proposalProductDocumentsList());
  }, [dispatch]);
  const proposalProductDocumentsListStates = useSelector<
    IRootState,
    IAppThunkActionStates
  >((state) => getThunkActionStates(state, "proposalProductDocumentsList"));
  const proposalProductDocuments = useSelector(getProposalProductDocuments);

  return (
    <Layout isProtected>
      <PageTitle className="px-md-5">Scarica documentazione</PageTitle>
      <Container className="container-max p-md-5 auto-margin-3">
        <Table bordered striped>
          <thead>
            <tr>
              <th>Compagnia</th>
              <th>Prodotto</th>
              <th>Documenti da utilizzare in Prevision</th>
              <th>Documenti da utilizzare Offline</th>
            </tr>
          </thead>
          <tbody>
            {proposalProductDocumentsListStates.isSuccess &&
              proposalProductDocuments.length === 0 && (
                <tr className="table-info">
                  <td colSpan={4}>Nessun prodotto trovato</td>
                </tr>
              )}
            {proposalProductDocumentsListStates.isFail && (
              <tr className="table-danger">
                <td colSpan={4}>{proposalProductDocumentsListStates.error}</td>
              </tr>
            )}
            {proposalProductDocuments.map((proposalProductDocument) => (
              <tr key={proposalProductDocument.id}>
                <td>{proposalProductDocument.product.company.name}</td>
                <td>{proposalProductDocument.product.name}</td>
                <td>
                  <Button
                    color="primary"
                    size="sm"
                    outline
                    tag="a"
                    href={`${process.env.REACT_APP_API_BASE_URL}proposal-product-documents/${proposalProductDocument.id}/download-zip`}
                    download
                  >
                    <IconDownload /> Scarica
                  </Button>
                </td>
                <td>
                  <Button
                    color="primary"
                    size="sm"
                    outline
                    tag="a"
                    href={`${process.env.REACT_APP_API_BASE_URL}proposal-product-documents/${proposalProductDocument.id}/download-zip-offline`}
                    download
                  >
                    <IconDownload /> Scarica
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <LoaderOverlay visible={proposalProductDocumentsListStates.isPending}>
          Caricamento raccomandazione in corso...
        </LoaderOverlay>
      </Container>
    </Layout>
  );
};

export default DownloadDocument;
