import moment from "moment";
import React from "react";
import {Alert, Badge, Button, Card, CardBody, CardHeader} from "reactstrap";
import {Layout, PageTitle} from "../../App";
import ContainerMax from "../../App/ContainerMax";
import {Actions, IStateUi as ICapStateUi, NewCapModalTypes} from "../../Caps";
import IdDetails from "../../Caps/IdDetails";
import {IUploadIdFormData} from "../../Caps/UploadIdForm";
import UploadIdModal from "../../Caps/UploadIdModal";
import {
  IconArchive,
  IconCheckmark,
  IconDanger,
  IconPlus,
  IconWarning,
} from "../../Icons";
import IApiError from "../../types/IApiError";
import {IState as IUserUi} from "../../User";
import {IUser} from "../../Users";

interface IProps {
  capUi: ICapStateUi;
  closeModal: (modalType: NewCapModalTypes) => Actions;
  uploadIdFail: (error: IApiError) => void;
  uploadIdSubmit: (values: IUploadIdFormData) => Promise<void>;
  openModal: (modalType: NewCapModalTypes) => Actions;
  ui: IUserUi;
  user: IUser;
}

const Page: React.FC<IProps> = ({
  capUi,
  closeModal,
  uploadIdFail,
  uploadIdSubmit,
  openModal,
  ui,
  user,
}) => {
  const openIdentificationModal = React.useCallback(
    () => openModal(NewCapModalTypes.UploadId),
    [openModal]
  );
  const closeIdentificationModal = React.useCallback(
    () => closeModal(NewCapModalTypes.UploadId),
    [closeModal]
  );

  return (
    <Layout isProtected>
      <PageTitle>
        Gestione documenti d'identita
        <Button
          color="light"
          outline
          className="ml-auto"
          onClick={openIdentificationModal}
        >
          <IconPlus /> Carica nuovo documento d'identità
        </Button>
      </PageTitle>
      <ContainerMax>
        {user.identification ? (
          user.identification.map((document, index) => (
            <Card
              key={`${document.idNumber}-${moment(document.date).format(
                "L[-]LT"
              )}`}
              color="drawer"
              className="text-primary"
            >
              <CardHeader tag="h5" className="d-flex align-items-center">
                Caricato il {moment(document.date).format("LL")}
                {index > 0 ? (
                  <Badge color="info" className="ml-auto">
                    <IconArchive /> Archiviato
                  </Badge>
                ) : (document.fileIdFaceName, document.fileIdFCName) ? (
                  moment(document.expirationDate, "DD-MM-YYYY").isBefore(
                    moment()
                  ) ? (
                    <Badge color="danger" className="ml-auto">
                      <IconDanger /> Scaduto
                    </Badge>
                  ) : moment(document.expirationDate, "DD-MM-YYYY").isBefore(
                      moment().add(30, "days")
                    ) ? (
                    <Badge color="warning" className="ml-auto">
                      <IconWarning /> In scadenza
                    </Badge>
                  ) : (
                    <Badge color="success" className="ml-auto">
                      <IconCheckmark /> Valido
                    </Badge>
                  )
                ) : (
                  <Badge color="danger" className="ml-auto">
                    <IconDanger /> Non valido
                  </Badge>
                )}
              </CardHeader>
              <CardBody>
                <IdDetails details={document} contractorId={user.id} />
              </CardBody>
            </Card>
          ))
        ) : (
          <Alert color="info">Non hai ancora caricato nessun documento</Alert>
        )}
      </ContainerMax>
      <UploadIdModal
        closeIdentification={closeIdentificationModal}
        uploadIdFail={uploadIdFail}
        uploadIdSubmit={uploadIdSubmit}
        isUploadIdFail={ui.isUploadIdFail}
        uploadIdError={ui.uploadIdError}
        ui={capUi}
      />
    </Layout>
  );
};

export default Page;
