const lastCharFiscalCode = (fc: string): boolean => {
  if (fc.length !== 16) {
    return false;
  }

  const map = [
    1,
    0,
    5,
    7,
    9,
    13,
    15,
    17,
    19,
    21,
    1,
    0,
    5,
    7,
    9,
    13,
    15,
    17,
    19,
    21,
    2,
    4,
    18,
    20,
    11,
    3,
    6,
    8,
    12,
    14,
    16,
    10,
    22,
    25,
    24,
    23,
  ];

  let s = 0;
  for (let i = 0; i < 15; i++) {
    let c = fc.charCodeAt(i);
    if (c < 65) {
      c = c - 48;
    } else {
      c = c - 55;
    }
    if (i % 2 === 0) {
      s += map[c];
    } else {
      s += c < 10 ? c : c - 10;
    }
  }
  const expected = String.fromCharCode(65 + (s % 26));
  return expected === fc.charAt(15);
};

const REGEX_FISCAL_CODE = /^[A-Z]{6}[A-Z0-9]{2}[ABCDEHLMPRST][A-Z0-9]{2}[A-Z][A-Z0-9]{3}[A-Z]$/;

export const fiscalCode = (value: string) => {
  return REGEX_FISCAL_CODE.test(value) && lastCharFiscalCode(value);
};

// eslint-disable-next-line no-useless-escape
const REGEX_EMAIL = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const email = (value: string) => REGEX_EMAIL.test(value);

// const REGEX_PASSWORD = /^(?=.*[a-zA-Z\-0-9]{8,})$/;
// const REGEX_PASSWORD = /^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{8,})$/;
// const REGEX_PASSWORD = /^(?=.*[a-zA-Z0-9]{8,})$/;
// /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

const REGEX_PASSWORD = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*?£]{8,}$/;

export const password = (value: string) => REGEX_PASSWORD.test(value);

const REGEXP_DATE_IT = /^([0-2][0-9]|(3)[0-1])(\/|\\|-)(((0)[0-9])|((1)[0-2]))(\/|\\|-)\d{4}$/;

export const dateIt = (value: string) => REGEXP_DATE_IT.test(value);
