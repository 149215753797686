import React from "react";
import {connect} from "react-redux";
import {Button, Container} from "reactstrap";
import {Layout} from "../App";
import {IconDownload} from "../Icons";
import {IRootState} from "../redux/reducers";
import {IUser} from "../Users";
import {actions} from "./";
import {IPrivacyFormData} from "./PrivacyForm";

import "./Profile.scss";
import {getUser} from "./selectors";

interface IStateProps {
  isPrivacyFail?: boolean;
  isPrivacyOpen?: boolean;
  privacyError?: string;
  user: IUser | null;
}

interface IDispacthProps {
  privacySubmit: (values: IPrivacyFormData) => Promise<void>;
}

type IProps = IStateProps & IDispacthProps;

const mapStateToProps = (state: IRootState) => ({
  isPrivacyFail: state.user.isPrivacyFail,
  isPrivacyOpen: state.user.isPrivacyOpen,
  privacyError: state.user.privacyError,
  user: getUser(state),
});

const mapDispatchToProps = {
  privacySubmit: (values: IPrivacyFormData) => {
    return actions.privacy(values);
  },
};

const PrivacyPage: React.FC<IProps> = (props) => {
  return (
    <Layout isProtected>
      <Container>
        <h2>
          Documenti di {props.user && props.user.name}{" "}
          {props.user && props.user.surname}
        </h2>
      </Container>
      <Container className="container-max auto-margin-4 p-md-5">
        <div className="float-right">
          <Button color="primary" outline download>
            <IconDownload title="Scarica tutti i tuoi file" /> Scarica tutti i
            file firmati ed archiviati
          </Button>{" "}
        </div>
      </Container>
    </Layout>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPage);
