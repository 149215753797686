import React from "react";
import {Alert, Button} from "reactstrap";
import {IconCheckCircle, IconSpinner} from "../Icons";
import {IUser} from "../Users";

interface IProps {
  details: IUser;
  hideButtons?: boolean;
  isContractor?: boolean;
  resetPasswordContractor?: () => Promise<void>;
  isResetPasswordContractorPending?: boolean;
  isResetPasswordContractorSuccess?: boolean;
  resetPasswordContractorError?: string;
}

const CreateContractorDetails: React.FC<IProps> = ({
  details,
  isContractor,
  hideButtons,
  resetPasswordContractor,
  isResetPasswordContractorPending,
}) => (
  <>
    <Alert color="success" className="d-flex align-items-center">
      <IconCheckCircle className="mb-0 mr-3" />
      {isContractor ? (
        <p className="mb-0">La tua area personale è attiva</p>
      ) : (
        <p className="mb-0">L'area personale del cliente è attiva</p>
      )}
    </Alert>
    <div className="d-flex">
      <ul className="list-inline">
        <li className="list-inline-item">
          {details.surname} {details.name}
        </li>
        <li className="list-inline-item">|</li>
        <li className="list-inline-item">CF: {details.fiscalCode}</li>
        <li className="list-inline-item">|</li>
        <li className="list-inline-item">Email: {details.email}</li>
      </ul>
      {!hideButtons && (
        <Button
          color="primary"
          outline
          className="ml-auto"
          onClick={resetPasswordContractor}
        >
          {isResetPasswordContractorPending && (
            <IconSpinner className="icon-spin mr-2" />
          )}
          Invia una nuova password
        </Button>
      )}
    </div>
  </>
);

export default CreateContractorDetails;
