import React from "react";
import {Alert, Col, Row, Table} from "reactstrap";
import {ICap, IInvestmentOption} from "../../Caps";
import {IddResultSnippet} from "../../Caps/idd";
import OptionCriteriaTable from "../../Caps/idd/OptionsCriteriaTable";
import FormatNumber from "../../helpers/FormatNumber";
import {IconInfoCircle, IconInfoCircleSolid} from "../../Icons";
import {IRevision} from "../types";

interface IProps {
  code: string;
  comment: string;
  cap: ICap;
  revisions: IRevision[];
  options: Array<{option: IInvestmentOption; percent: number}>;
}

interface IState {
  isExpanded: boolean[];
}

class OptionsDetailsRead extends React.Component<IProps, IState> {
  public readonly state: Readonly<IState> = {
    isExpanded: [],
  };

  constructor(props: IProps) {
    super(props);

    this.clickIconDetail = this.clickIconDetail.bind(this);
  }

  public clickIconDetail(index: number) {
    this.setState(
      (prevState) => {
        const newIsExpanded = prevState.isExpanded;
        newIsExpanded[index] = !prevState.isExpanded[index];

        // console.log({prevState}); // tslint:disable-line

        return {
          isExpanded: newIsExpanded,
        };
      }
      // () => console.log(this.state) // tslint:disable-line
    );
  }

  public render() {
    const {code, comment, revisions, options, cap} = this.props;

    return (
      <>
        {!!cap && cap.idd && (
          <div>
            <h6>Profilo cliente</h6>
            <IddResultSnippet idd={cap.idd.idd} />
          </div>
        )}
        <Row className="mt-3">
          <Col>
            <strong>Compagnia:</strong>
            <br />
            <span>{options[0].option.product.company.name}</span>
          </Col>
          <Col>
            <strong>Prodotto:</strong>
            <br />
            <span>{options[0].option.product.name}</span>
          </Col>
          <Col>
            <strong>Numero proposta:</strong>
            <br />
            <span>{code}</span>
          </Col>
        </Row>
        <div>
          <strong>
            Opzioni di investimento per la raccomandazione personalizzata:
          </strong>
          <br />
          <Table responsive>
            <thead>
              <tr>
                <th>Opzioni di investimenti</th>
                <th>Livello di rischio</th>
                <th className="text-right" style={{width: "100px"}}>
                  %
                </th>
                <th style={{width: "26px"}} />
              </tr>
            </thead>
            <tbody>
              {options.map((option, index) => (
                <React.Fragment key={option.option.id}>
                  <tr>
                    <td>{option.option.name}</td>
                    <td>{option.option.risk}</td>
                    <td className="text-right">
                      <FormatNumber>{option.percent}</FormatNumber> %
                    </td>
                    <td>
                      {this.state.isExpanded[index] ? (
                        <IconInfoCircleSolid
                          title="Chiudi dettagli"
                          onClick={this.clickIconDetail.bind(this, index)}
                        />
                      ) : (
                        <IconInfoCircle
                          title="Dettagli"
                          onClick={this.clickIconDetail.bind(this, index)}
                        />
                      )}
                    </td>
                  </tr>
                  {!!cap && cap.idd && this.state.isExpanded[index] && (
                    <tr>
                      <td colSpan={4}>
                        <OptionCriteriaTable
                          idd={cap.idd}
                          option={option.option}
                        />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
              <tr>
                <td colSpan={3} className="text-right">
                  Totale: <FormatNumber>100</FormatNumber> %
                </td>
              </tr>
            </tbody>
          </Table>
          <div>
            <p>
              Si consiglia sempre di{" "}
              <strong>diversificare l'investimento</strong>
              , distribuendolo tra i diversi strumenti al fine di bilanciare il
              rischio e ottimizzare i profitti.
              <br />
              <strong>
                Il sistema colloca equamente le risorse tra le opzioni
                disponibili
              </strong>
              , lasciando al Consulente l'opportunità di{" "}
              <strong>personalizzare le percentuali</strong> di ogni opzione,
              perché si adattino al profilo e agli obiettivi del Cliente.
            </p>
          </div>
          <strong>
            Motiva la scelta delle opzioni di investimento selezionate:
          </strong>
          <br />
          {comment || (
            <span className="text-info">
              <IconInfoCircleSolid /> L'advisor non ha lasciato nessuna
              motivazione
            </span>
          )}
          <br />
          {revisions.length > 0 && (
            <>
              <p className="mt-3">
                <strong>Messaggi del Back-office:</strong>
              </p>
              {revisions
                .slice(0)
                .reverse()
                .map((revision) => (
                  <Alert color="warning" key={revision.date.format()}>
                    <h6 className="alert-heading">
                      <em>{revision.date.format("LL[ alle ]LT")}</em>
                    </h6>
                    {revision.text}
                  </Alert>
                ))}
            </>
          )}
        </div>
      </>
    );
  }
}

export default OptionsDetailsRead;
