import React from "react";
import {Alert, Button, Form, FormGroup, Label} from "reactstrap";
import {Field, InjectedFormProps, reduxForm} from "redux-form";

import RenderField from "../helpers/RenderField";
import IconSpinner from "../Icons/IconSpinner";

export interface ILostPasswordFormData {
  usid: string;
}

interface IOwnProps {
  isLostPasswordFail: boolean;
  lostPasswordError: string;
}

type IProps = InjectedFormProps<ILostPasswordFormData, IOwnProps> & IOwnProps;

const validate = (values: ILostPasswordFormData) => {
  const errors: ILostPasswordFormData = {usid: ""};
  if (!values.usid) {
    errors.usid = "Questo campo è obbligatorio";
  }
  return errors;
};

const LostPasswordForm: React.FC<IProps> = (props) => (
  <Form onSubmit={props.handleSubmit}>
    <FormGroup>
      <Label for="exampleEmail">Nome utente o e-mail</Label>
      <Field
        id="usid"
        name="usid"
        component={RenderField}
        type="text"
        placeholder="Nome utente o e-mail"
      />
    </FormGroup>
    {props.isLostPasswordFail ? (
      <Alert color="danger">{props.lostPasswordError}</Alert>
    ) : null}
    <Button type="submit" color="primary" block disabled={props.submitting}>
      {props.submitting ? <IconSpinner className="icon-spin" /> : ""} Recupera
      password
    </Button>
  </Form>
);

export default reduxForm<ILostPasswordFormData, IOwnProps>({
  form: "lostPasswordForm",
  validate,
})(LostPasswordForm);
