import moment from "moment";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import ReactTable, {Column} from "react-table";
import {Alert, Badge, Button, Container} from "reactstrap";
import {Layout, PageTitle} from "../App";
import {
  IconEye,
  IconPlus,
  IconRefresh,
  IconSearch,
  IconSpinner,
} from "../Icons";
import {IRevision} from "../Recommendation";
import {IRootState} from "../redux/reducers";
import {IAppThunkActionStates} from "../types/thunk";
import NotAuthorizedPage from "../User/NotAuthorizedPage";
import {
  getHasCompany,
  getHasPermission,
  getUserCompanies,
  getUserPermissions,
} from "../User/selectors";
import {actions} from "./actions";
import {getItemsIds, getProposals, getThunkActionStates} from "./selectors";
import {IProposal, ProposalStatus} from "./types";

interface ILoadingProps {
  loading: boolean;
}
const LoadingComponent = ({loading}: ILoadingProps) =>
  loading ? (
    <div className="-loading -active">
      <div className="-loading-inner">
        <IconSpinner className="icon-spin" /> Caricamento proposte in corso...
      </div>
    </div>
  ) : null;

const getStatusColor = (status: ProposalStatus) => {
  switch (status) {
    case ProposalStatus.Deleted:
      return "danger";
    case ProposalStatus.WaitingFiles:
      return "danger";
    case ProposalStatus.Complete:
      return "success";
    case ProposalStatus.WaitingAdvisorsESigns:
      return "danger";
    case ProposalStatus.SignedByAdvisor:
      return "warning";
    case ProposalStatus.WaitingContractorsESign:
      return "danger";
    case ProposalStatus.ToBeValidated:
      return "primary";
    case ProposalStatus.WaitReview:
      return "warning";
    case ProposalStatus.Rejected:
      return "info";
    default:
      return "danger";
  }
};

const getStatusLabel = (status: ProposalStatus, revisions?: IRevision[]) => {
  const date =
    revisions && revisions.length > 0
      ? revisions[revisions.length - 1].date.format("LL")
      : undefined;
  switch (status) {
    case ProposalStatus.Deleted:
      return "Eliminato";
    case ProposalStatus.WaitingFiles:
      return "In attesa dei file";
    case ProposalStatus.Complete:
      return "Congruo";
    case ProposalStatus.WaitingAdvisorsESigns:
      return "In attesa delle firme dell'Advisor";
    case ProposalStatus.SignedByAdvisor:
      return (
        <>
          Firmata dall'Advisor
          <br /> <Badge color="warning">Non inviato al cliente</Badge>
        </>
      );
    case ProposalStatus.WaitingContractorsESign:
      return "In attesa delle firme del cliente";
    case ProposalStatus.ToBeValidated:
      return "Inviato per l'approvazione";
    case ProposalStatus.WaitReview:
      return revisions && revisions.length ? (
        <>
          Richiesta revisione
          <br />
          {date}{" "}
          <Badge color="warning">
            {revisions.length}{" "}
            {revisions.length === 1 ? "revisione" : "revisioni"}
          </Badge>
          <br />
          <span className="revision-snippet">
            <strong>Ultima revisione:</strong>{" "}
            {revisions[revisions.length - 1].text}
          </span>
        </>
      ) : (
        "Richiesta revisione"
      );
    case ProposalStatus.Rejected:
      return "Raccomandazione archiviata";
    default:
      return "Stato sconosciuto";
  }
};

const List = () => {
  const canContractorManageProposal = false;
  // const numItems = 0;
  // const limit = 50;

  const dispatch = useDispatch();
  const listThunkStates = useSelector<IRootState, IAppThunkActionStates>(
    (state) => getThunkActionStates(state, "list")
  );
  const denormalizedItems = useSelector<IRootState, IProposal[]>((state) =>
    getProposals(state, getItemsIds(state))
  );

  const userPermissions = useSelector(getUserPermissions);
  const userCompanies = useSelector(getUserCompanies);

  const canManageProposals = useSelector((state: IRootState) =>
    getHasPermission(state, "proposal.manage")
  );
  const canSeeDarta = useSelector((state: IRootState) =>
    getHasCompany(state, "99")
  );
  const canProposalManage = canManageProposals && canSeeDarta;

  const columns = React.useMemo(() => {
    let columns: Column<IProposal>[] = [
      {
        Header: "Numero proposta",
        accessor: (proposal: IProposal) => {
          if (proposal.status === ProposalStatus.Rejected) {
            return proposal.code?.split("_")[0] + " (Archiviata)";
          }
          return proposal.code;
        },
        id: "code",
      },
    ];

    if (!canContractorManageProposal || canProposalManage) {
      columns = columns.concat([
        {
          Header: "Cliente",
          accessor: (proposal: IProposal) =>
            proposal.contractor ? (
              <div style={{lineHeight: 1}}>
                {proposal.contractor.surname} {proposal.contractor.name}
                <br />
                <small className="smaller text-info">
                  CF: {proposal.contractor.fiscalCode}
                </small>
              </div>
            ) : (
              "-"
            ),
          id: "contractor",
        },
      ]);
    }

    columns = columns.concat([
      {
        Header: "Advisor",
        accessor: (proposal: IProposal) =>
          proposal.user ? (
            <div style={{lineHeight: 1}}>
              {proposal.user.surname} {proposal.user.name}
              <br />
              <small className="smaller text-info">
                RUI: {proposal.user.ruiCode}
                <br />
                CF: {proposal.user.fiscalCode}
              </small>
            </div>
          ) : (
            "-"
          ),
        id: "user",
      },
      {
        Header: "Inserita il",
        accessor: (proposal: IProposal) =>
          moment(proposal.date).format("DD MMMM YYYY"),
        id: "date",
        sortMethod: (a, b, desc) => {
          if (a === b) {
            return 0;
          }
          if (desc) {
            return moment(a, "DD MMMM YYYY").isAfter(moment(b, "DD MMMM YYYY"))
              ? 1
              : -1;
          } else {
            return moment(a, "DD MMMM YYYY").isBefore(moment(b, "DD MMMM YYYY"))
              ? -1
              : 1;
          }
        },
      },
      {
        Header: "Stato",
        accessor: (proposal: IProposal) => (
          <span data-value={proposal.status}>
            <span
              className={"statusDot text-" + getStatusColor(proposal.status)}
            >
              &#x25cf;
            </span>{" "}
            <span>{getStatusLabel(proposal.status, proposal.revisions)}</span>
          </span>
        ),
        id: "status",
        sortable: false,
      },
      {
        Header: "Azioni",
        accessor: (proposal: IProposal) => {
          if (
            proposal.status === ProposalStatus.WaitReview &&
            canProposalManage
          ) {
            return (
              <Button
                tag={Link}
                size="sm"
                color="primary"
                outline
                block
                to={`/proposte/${proposal.id}`}
              >
                <IconSearch /> Rivedi
              </Button>
            );
          }
          return (
            <Button
              tag={Link}
              size="sm"
              color="primary"
              outline
              block
              to={`/proposte/${proposal.id}`}
            >
              <IconEye /> Visualizza
            </Button>
          );
        },
        id: "azioni",
        sortable: false,
        width: 150,
      },
    ]);

    return columns;
  }, [canContractorManageProposal, canProposalManage]);

  const refreshList = () => {
    dispatch(actions.list());
  };

  // const fetchData = () => {
  // const filters: {[key: string]: string} = {};
  // const sort = state.sorted
  //   .map((col: SortingRule) => (col.desc ? "-" + col.id : col.id))
  //   .join(",");
  //
  // state.filtered.forEach((filter: {id: string; value: string}) => {
  //   filters[filter.id] = filter.value;
  // });
  //
  // const params: IRecommendationParams = {
  //   limit: state.pageSize,
  //   offset: state.page * state.pageSize,
  //   sort,
  //   ...filters,
  // };
  //
  // this.lastParams = params;
  // dispatch(actions.list());
  // };

  useEffect(() => {
    dispatch(actions.list());
  }, [dispatch]);

  if (
    !(
      userCompanies.includes("99") && userPermissions.includes("proposal.read")
    ) &&
    !userPermissions.includes("proposals.read") &&
    !userPermissions.includes("proposal.contractor-read")
  ) {
    // Non potendo fare questo controllo direttamente sulla route controlliamo qui che l'utente abbia i permessi giusti per accedere alla pagina
    return <NotAuthorizedPage />;
  }

  return (
    <Layout isProtected>
      <PageTitle fluid className="px-md-5">
        Proposte
        {canProposalManage && (
          <Button
            tag={Link}
            color="light"
            outline
            to="/proposte/download-documents"
            className="ml-auto"
          >
            Scarica Documentazione
          </Button>
        )}
        {canProposalManage && (
          <Button
            tag={Link}
            color="light"
            outline
            to="/proposte/new"
            className="ml-2"
          >
            <IconPlus /> Nuova proposta
          </Button>
        )}
      </PageTitle>
      <Container fluid className="auto-margin-4 px-0 px-sm-3 px-md-5">
        {listThunkStates.isFail && (
          <Alert color="danger" className="d-flex align-items-center">
            {listThunkStates.error}{" "}
            <Button
              color="light"
              outline
              size="sm"
              onClick={refreshList}
              className="ml-auto"
            >
              <IconRefresh /> Aggiorna
            </Button>
          </Alert>
        )}
        <Container fluid className="container-max">
          <ReactTable
            LoadingComponent={LoadingComponent}
            data={denormalizedItems}
            columns={columns}
            // manual
            // pages={Math.ceil(numItems / limit)}
            // onFetchData={fetchData}
            defaultPageSize={50}
            defaultSorted={[
              {
                desc: true,
                id: "date",
              },
            ]}
            minRows={5}
            loading={listThunkStates.isPending}
            className="-striped -highlight"
            previousText="Precedente"
            nextText="Prossima"
            noDataText="Non è stata creata ancora nessuna proposta"
            pageText="Pagina"
            ofText="di"
            rowsText="proposte"
          />
        </Container>
      </Container>
    </Layout>
  );
};

export default List;
