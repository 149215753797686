import React from "react";
import {Alert, Button, FormGroup, Label} from "reactstrap";
import {Field, Form, InjectedFormProps, reduxForm} from "redux-form";
import {integerNormalizer} from "../helpers/normalizers";
import RenderField from "../helpers/RenderField";
import {IconSpinner} from "../Icons";

export interface IInsertPhoneFormData {
  personalPhone?: string;
}
interface IInsertPhoneFormErrors {
  personalPhone?: string;
}

interface IOwnProps {
  addPersonalPhone: (values: IInsertPhoneFormData) => Promise<void>;
  cancelESign: () => void;
  isProfileFail?: boolean;
  isProfilePending?: boolean;
  profileError?: string;
}

type IProps = IOwnProps & InjectedFormProps<IInsertPhoneFormData, IOwnProps>;

const validate = (values: IInsertPhoneFormData) => {
  const errors: IInsertPhoneFormErrors = {};
  if (!values.personalPhone) {
    errors.personalPhone = "Inserisci un numero di cellulare";
  }
  return errors;
};

const InsertPhoneForm: React.FC<IProps> = ({
  addPersonalPhone,
  cancelESign,
  handleSubmit,
  isProfileFail,
  isProfilePending,
  profileError,
}) => {
  const insertPhoneSubmit = async (values: IInsertPhoneFormData) => {
    await addPersonalPhone(values);
  };
  return (
    <Form onSubmit={handleSubmit(insertPhoneSubmit)}>
      <p>
        Inserisci il tuo numero di cellulare, in questo modo potremo inviarti
        l'OTP per firmare il documento
      </p>
      <FormGroup>
        <Label for="personalPhone">Numero di cellulare</Label>
        <Field
          id="personalPhone"
          name="personalPhone"
          component={RenderField}
          type="text"
          placeholder="Numero di cellulare"
          normalize={integerNormalizer}
        />
      </FormGroup>
      {isProfileFail && <Alert color="danger">{profileError}</Alert>}
      <Button color="primary" outline type="submit">
        {isProfilePending && <IconSpinner className="icon-spin mr-2" />}Conferma
      </Button>{" "}
      <Button color="secondary" outline type="button" onClick={cancelESign}>
        Annulla
      </Button>
    </Form>
  );
};

export default reduxForm<IInsertPhoneFormData, IOwnProps>({
  form: "insertPhoneForm",
  validate,
})(InsertPhoneForm);
