import React from "react";
import {animated, Transition} from "react-spring/renderprops";
import {Button, Card} from "reactstrap";
import {IconFillIn} from "../Icons";

interface IProps {
  closeDrawerButton?: JSX.Element;
  icon: (props: {size: number; className: string}) => JSX.Element;
  isButtonDisabled?: boolean;
  id?: string;
  isOpen?: boolean;
  modal?: JSX.Element;
  openDrawerButton?: JSX.Element;
  alwaysVisible?: JSX.Element;
  openModal?: () => void;
  title: string;
}

const Drawer: React.FC<IProps> = ({
  closeDrawerButton: ClosedDrawerButton = (
    <>
      <IconFillIn /> Modifica
    </>
  ),
  children,
  icon: Icon,
  id,
  isButtonDisabled = false,
  isOpen = false,
  modal: Modal,
  openDrawerButton: OpenDrawerButton = (
    <>
      <IconFillIn /> Inserisci
    </>
  ),
  alwaysVisible: AlwaysVisible,
  openModal,
  title,
}) => {
  // console.log("render " + title); // tslint:disable-line
  return (
    <Card
      id={id}
      body
      color={isOpen ? "drawer" : "light"}
      className="drawer auto-margin-3"
    >
      <div className="d-flex justify-content-between align-items-center">
        <h4 className="m-0 text-primary">
          <Icon size={2.5} className="icon-border-2 rounded-circle p-2" />{" "}
          {title}
        </h4>
        {openModal && (
          <Button
            color="primary"
            outline
            onClick={openModal}
            // hidden={isButtonDisabled}
            disabled={isButtonDisabled}
          >
            {isOpen ? ClosedDrawerButton : OpenDrawerButton}
          </Button>
        )}
      </div>
      {AlwaysVisible}
      <Transition
        items={isOpen || null}
        from={{opacity: 0, height: 0, width: "100%", overflow: "hidden"}}
        enter={{opacity: 1, height: "auto"}}
        leave={{opacity: 0, height: 0}}
      >
        {(show) =>
          show &&
          ((styles) => (
            <animated.div className="auto-margin-3" style={styles}>
              {children}
            </animated.div>
          ))
        }
      </Transition>
      {Modal}
    </Card>
  );
};

export default Drawer;
