import React from "react";
import {Alert, Button} from "reactstrap";
import {IconPlus, IconRetirement} from "../../Icons";
import IconSpinner from "../../Icons/IconSpinner";
import {
  dehydrateCap,
  Drawer,
  ICap,
  ICapEntity,
  IInvestmentOption,
  IStateUi,
} from "../index";
import HasIBIPsForm, {IHasIBIPsFormData} from "./HasIBIPsForm";

interface IProps {
  cap: ICap;
  disabled?: boolean;
  hasIBIPsSubmit?: (
    values: IHasIBIPsFormData | undefined,
    cap: ICapEntity
  ) => void;
  optionsList: () => Promise<void>;
  options?: IInvestmentOption[];
  ui: IStateUi;
}

const HasIBIPsDrawer: React.FC<IProps> = ({
  cap,
  disabled,
  hasIBIPsSubmit,
  options,
  optionsList,
  ui,
}) => {
  const [isFormVisible, setFormVisibility] = React.useState(
    !!cap.data.hasIBIPs && cap.data.hasIBIPs.IBIPs.length > 0
  );
  const showForm = () => setFormVisibility(true);
  const hideForm = () => setFormVisibility(false);
  const doesNotHave = async () => {
    const {
      result,
      entities: {caps},
    } = dehydrateCap(cap);
    await hasIBIPsSubmit?.({IBIPs: []}, caps[result]);
  };

  return (
    <Drawer
      id="idd-drawer"
      icon={IconRetirement}
      title="Possiedi attualmente dei prodotti IBIPs per costruire il capitale per il tuo futuro?"
      isOpen
    >
      {isFormVisible ? (
        <div>
          <HasIBIPsForm
            cap={cap}
            disabled={disabled || !hasIBIPsSubmit}
            hideForm={hideForm}
            hasIBIPsSubmit={hasIBIPsSubmit}
            initialValues={
              cap.data.hasIBIPs && cap.data.hasIBIPs.IBIPs.length > 0
                ? cap.data.hasIBIPs
                : {
                    IBIPs: [
                      {
                        companyId: "",
                        duration: "",
                        intermediary: "",
                        productId: "",
                        recurrence: "",
                        recurrentPremium: "",
                        subscriptionYear: "",
                        uniquePremium: "",
                      },
                    ],
                  }
            }
            options={options}
            optionsList={optionsList}
            ui={ui}
          />
        </div>
      ) : cap.data.hasIBIPs && cap.data.hasIBIPs.IBIPs.length === 0 ? (
        <>
          <Alert color="info">
            <strong className="text-light">Attualmente</strong> non hai prodotti
            di investimento assicurativi{" "}
          </Alert>
          {!disabled && (
            <Button color="info" onClick={showForm}>
              <IconPlus title="Aggiungi una fonte di reddito" /> Aggiungi
              prodotto
            </Button>
          )}
        </>
      ) : (
        <div>
          <div className="text-center">
            <Button
              color="primary"
              disabled={disabled}
              onClick={showForm}
              className="has-ibips-button"
            >
              Sì
            </Button>
            <Button
              color="primary"
              disabled={disabled}
              onClick={doesNotHave}
              className="has-ibips-button"
            >
              {ui.isHasIBIPsPending && (
                <IconSpinner className="mr-2 icon-spin" />
              )}
              No
            </Button>
          </div>
        </div>
      )}
    </Drawer>
  );
};

export default HasIBIPsDrawer;
