import React from "react";
import IconBase from "./IconBase";

const IconEmployment = (props: any) => (
  <IconBase {...props}>
    <path d="M28.16 18.276c-.836-1.931-1.993-3.8-3.458-5.549-1.77-2.13-3.533-3.554-4.431-4.211l3.393-6.295a.79.79 0 0 0-.146-.945C22.641.418 21.709 0 20.67 0c-.952 0-1.822.359-2.581.678-.6.246-1.164.486-1.623.486-.141 0-.26-.021-.38-.065C14.504.518 13.285.16 12.107.16c-1.49 0-2.759.6-4.005 1.876a.804.804 0 0 0-.093.997l3.659 5.528a26.343 26.343 0 0 0-4.371 4.166c-1.457 1.749-2.621 3.618-3.459 5.549-1.051 2.427-1.583 4.963-1.583 7.529A6.204 6.204 0 0 0 8.449 32h15.103a6.202 6.202 0 0 0 6.192-6.194c0-2.567-.53-5.103-1.584-7.53M9.712 2.7c.772-.665 1.511-.958 2.395-.958.945 0 2.01.318 3.427.846.292.106.605.16.925.16.777 0 1.523-.308 2.235-.605.685-.287 1.338-.561 1.969-.561.308 0 .739.054 1.283.44l-3.193 5.934h-5.561L9.712 2.7zm13.84 27.703H8.449a4.602 4.602 0 0 1-4.596-4.598c0-4.265 1.562-8.309 4.636-12.021 1.942-2.349 3.918-3.807 4.517-4.231h5.977c.604.418 2.572 1.883 4.516 4.231 3.081 3.712 4.639 7.749 4.639 12.021a4.588 4.588 0 0 1-4.586 4.598" />
    <path d="M16.595 16.615c1.064 0 1.933.867 1.933 1.932a.794.794 0 1 0 1.591 0 3.527 3.527 0 0 0-3.523-3.523 3.53 3.53 0 0 0-3.524 3.411h-.731a.794.794 0 1 0 0 1.591h.729v1.137h-.729a.796.796 0 0 0 0 1.593h.731a3.53 3.53 0 0 0 3.524 3.41 3.528 3.528 0 0 0 3.523-3.523.797.797 0 0 0-1.591 0 1.936 1.936 0 0 1-1.933 1.932 1.936 1.936 0 0 1-1.931-1.818h1.931c.438 0 .793-.356.793-.796s-.354-.797-.793-.797H14.66v-1.137h1.935a.794.794 0 0 0 .793-.797.793.793 0 0 0-.793-.794h-1.931a1.937 1.937 0 0 1 1.931-1.821" />
  </IconBase>
);

export default IconEmployment;
