import React from "react";
import IconBase from "./IconBase";

const IconUpload = (props: any) => (
  <IconBase {...props}>
    <path d="M18.5 24h-5a1.5 1.5 0 0 1-1.5-1.5V12H6.52a1.25 1.25 0 0 1-.88-2.14l9.5-9.5a1.21 1.21 0 0 1 1.71 0l9.51 9.5c.8.8.23 2.14-.88 2.14H20v10.5c0 .83-.67 1.5-1.5 1.5zm13.5-.5v7c0 .83-.67 1.5-1.5 1.5h-29A1.5 1.5 0 0 1 0 30.5v-7c0-.83.67-1.5 1.5-1.5H10v.5a3.5 3.5 0 0 0 3.5 3.5h5a3.5 3.5 0 0 0 3.5-3.5V22h8.5c.83 0 1.5.67 1.5 1.5zM24.25 29A1.25 1.25 0 1 0 23 30.25c.69 0 1.25-.56 1.25-1.25zm4 0A1.25 1.25 0 1 0 27 30.25c.69 0 1.25-.56 1.25-1.25z" />
  </IconBase>
);

export default IconUpload;
