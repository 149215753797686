import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {IconBan, IconCheckCircle, IconClock} from "../Icons";

export const ColumnValidationFilter = ({filter, onChange}: any) => (
  <UncontrolledDropdown>
    <DropdownToggle
      caret
      size="sm"
      block
      color="primary"
      outline
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {(!filter || !filter.value) && <span>Tutti</span>}
      {filter && filter.value === "valid" && (
        <span>
          <IconCheckCircle color="success" /> Analisi completata
        </span>
      )}
      {filter && filter.value === "not-esign" && (
        <span>
          <IconClock /> In attesa di firma
        </span>
      )}
      {filter && filter.value === "not-sent" && (
        <span>
          <IconBan color="danger" />{" "}
          <span style={{fontSize: "80%"}}>Richiesta firma non inviata</span>
        </span>
      )}
    </DropdownToggle>
    <DropdownMenu>
      <DropdownItem onClick={onChange.bind({}, undefined)}>Tutti</DropdownItem>
      <DropdownItem onClick={onChange.bind({}, "valid")}>
        <IconCheckCircle color="success" /> Analisi completata
      </DropdownItem>
      <DropdownItem onClick={onChange.bind({}, "not-esign")}>
        <IconClock color="primary" /> In attesa di firma
      </DropdownItem>
      <DropdownItem onClick={onChange.bind({}, "not-sent")}>
        <IconBan color="danger" /> Richiesta firma non inviata
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);
