import {faCheck, faExclamationTriangle} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import ReactTable, {
  ControlledStateOverrideProps,
  SortingRule,
} from "react-table";
import {Alert, Button, Container} from "reactstrap";
import {debounce} from "throttle-debounce";
import {Layout, PageTitle} from "../App";
import ColumnDateFilter from "../helpers/ColumnDateFilter";
import {ColumnIddFilter} from "../helpers/ColumnIddFilter";
import {ColumnOnboardingFilter} from "../helpers/ColumnOnboardingFilter";
import {ColumnOpenCloseFilter} from "../helpers/ColumnOpenCloseFilter";
import {ColumnValidationFilter} from "../helpers/ColumnValidationFilter";
import {
  IconBan,
  IconCheckCircle,
  IconClock,
  IconComplete,
  IconEye,
  IconPlus,
  IconRefresh,
  IconSpinner,
  IconTimesCircle,
  IconTrash,
  IconUndo,
} from "../Icons/";
import {IRootState} from "../redux/reducers";
import {getHasPermission, getUser} from "../User/selectors";
import {IUser} from "../Users";
import {actions, Actions, ICap, ICapParams, IStateList, IStateUi} from "./";
import "./ListPage.scss";
import {hydrateCap} from "./types";

interface IStateProps {
  canContractorManageCap: boolean;
  canManageCap: boolean;
  caps: IStateList;
  items: ICap[];
  loggedUser: IUser | null;
  ui: IStateUi;
}

interface IDispatchProps {
  delete: (cap: ICap) => Promise<void>;
  readResetUiError: () => Actions;
  list: (params?: ICapParams) => Promise<void>;
  resetDeleteSuccess: () => Actions;
  undoDelete: (params?: ICapParams) => Promise<void | Actions>;
  viewFromDbResetUiError: () => Actions;
}

type IProps = IStateProps & IDispatchProps;

const mapStateToProps = (state: IRootState) => {
  const result = state.caps.list.items;

  const entities = {
    caps: state.caps.entities,
    users: state.users.entities,
  };

  return {
    canContractorManageCap: getHasPermission(state, "cap.contractor-manage"),
    canManageCap: getHasPermission(state, "cap.manage"),
    caps: state.caps.list,
    items: hydrateCap({result, entities}),
    loggedUser: getUser(state),
    ui: state.caps.ui,
  };
};

const mapDispatchToProps = {
  delete: (cap: ICap) => actions.delete(cap.id.toString()),
  list: (params?: ICapParams) => actions.list(params),
  readResetUiError: () => actions.readResetUiError(),
  resetDeleteSuccess: () => actions.resetDeleteSuccess(),
  undoDelete: (params?: ICapParams) => actions.undoDelete(params),
  viewFromDbResetUiError: () => actions.viewFromDbResetUiError(),
};

// Button component for deleting cap
interface IDeleteCapButtonProps {
  onClick: any;
  cap: ICap;
}

const DeleteCapButton: React.FC<IDeleteCapButtonProps> = (props) => {
  const handleDelete = () => {
    props.onClick(props.cap);
  };
  return (
    <Button
      size="sm"
      color="danger"
      outline
      className="ml-1"
      onClick={handleDelete}
    >
      {props.children}
    </Button>
  );
};

class ListPage extends React.Component<IProps, ControlledStateOverrideProps> {
  public static contractors(cap: ICap) {
    return cap.contractor ? (
      <p key={cap.contractor.id} className="mb-1">
        {cap.contractor.name} {cap.contractor.surname}
      </p>
    ) : null;
  }

  private static loadingComponent(props: any) {
    return props.loading ? (
      <div className="-loading -active">
        <div className="-loading-inner">
          <IconSpinner className="icon-spin" /> Caricamento analisi in corso...
        </div>
      </div>
    ) : null;
  }

  private lastParams?: ICapParams;

  constructor(props: IProps) {
    super(props);
    this.refreshList = this.refreshList.bind(this);
    this.undoDelete = this.undoDelete.bind(this);
    this.fetchData = debounce(300, this.fetchData.bind(this));
  }

  public getColumns(props: any) {
    let columns: any[] = [];

    if (!this.props.canContractorManageCap || this.props.canManageCap) {
      columns = [
        {
          Header: "Ultima",
          accessor: (cap: ICap) => (
            <div className="h-100 d-flex align-items-center justify-content-center">
              {cap.isLast ? (
                <FontAwesomeIcon
                  icon={faCheck}
                  className="text-success"
                  title="Questa è l'analisi di riferimento per il cliente"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="text-warning"
                  title="Esiste un'analisi più recente per questo cliente"
                />
              )}
            </div>
          ),
          id: "isLast",
          sortable: false,
          filterable: false,
          width: 63,
        },

        {
          Header: "Cliente",
          accessor: (cap: ICap) =>
            cap.contractor ? (
              <div style={{lineHeight: 1}}>
                {cap.contractor.surname} {cap.contractor.name}
                <br />
                <small className="smaller text-info">
                  CF: {cap.contractor.fiscalCode}
                </small>
              </div>
            ) : (
              "-"
            ),
          id: "contractor",
        },
      ];
    }

    columns = columns.concat([
      {
        Header: "Advisor",
        accessor: (cap: ICap) =>
          cap.user ? (
            <div style={{lineHeight: 1}}>
              {cap.user.surname} {cap.user.name}
              <br />
              <small className="smaller text-info">
                RUI: {cap.user.ruiCode}
                <br />
                CF: {cap.user.fiscalCode}
              </small>
            </div>
          ) : (
            "-"
          ),
        id: "user",
      },
      {
        Filter: ({filter, onChange}: any) => (
          <ColumnDateFilter filter={filter} onChange={onChange} />
        ),
        Header: "Data creazione",
        accessor: (cap: ICap) => cap.dateStart.format("LL"),
        // filterable: false,
        id: "dateStart",
      },
      {
        Filter: ColumnOnboardingFilter,
        Header: "Onboarding",
        accessor: (cap: ICap) => {
          return cap.dateOnboarding || cap.contractor.lastPrivacyEsignId ? (
            <>
              <IconCheckCircle
                color="success"
                size={1.25}
                title={`Completato il ${
                  cap.dateOnboarding &&
                  moment(cap.dateOnboarding).format("L[ alle ]LT")
                }`}
              />{" "}
              <small>Completato</small>
            </>
          ) : (
            <>
              <IconClock
                color="primary"
                size={1.25}
                title="Consenso mancante"
              />{" "}
              <small>Consenso da firmare</small>
            </>
          );
        },
        id: "onboarding",
        sortable: false,
      },
      {
        Filter: ColumnOpenCloseFilter,
        Header: "Demands & Needs Test",
        accessor: (cap: ICap) =>
          cap.dateDen ? (
            <>
              <IconCheckCircle
                color="success"
                size={1.25}
                title={`Completato il ${cap.dateDen.format("L[ alle ]LT")}`}
              />{" "}
              <small>Completato</small>
            </>
          ) : (
            <>
              <IconTimesCircle
                color="danger"
                size={1.25}
                title="Non completato"
              />{" "}
              <small>Non completato</small>
            </>
          ),
        id: "den",
        sortable: false,
      },
      {
        Filter: ColumnOpenCloseFilter,
        Header: "Scoperture",
        accessor: (cap: ICap) =>
          cap.dateScoperture ? (
            <>
              <IconCheckCircle
                color="success"
                size={1.25}
                title={`Completato il ${cap.dateScoperture.format(
                  "L[ alle ]LT"
                )}`}
              />{" "}
              <small>Completato</small>
            </>
          ) : (
            <>
              <IconTimesCircle
                color="danger"
                size={1.25}
                title="Non completato"
              />{" "}
              <small>Non completato</small>
            </>
          ),
        id: "marketing",
        sortable: false,
      },
      {
        Filter: ColumnIddFilter,
        Header: "IBIPs",
        accessor: (cap: ICap) =>
          cap.dateIdd ? (
            <>
              <IconCheckCircle
                color="success"
                size={1.25}
                title={`Completato il ${cap.dateIdd.format("L[ alle ]LT")}`}
              />{" "}
              <small>Completato</small>
            </>
          ) : (
            <>
              <IconTimesCircle
                color="danger"
                size={1.25}
                title="Non completato"
              />{" "}
              <small>Non completato</small>
            </>
          ),
        id: "idd",
        sortable: false,
      },
      {
        Filter: ColumnValidationFilter,
        Header: "Chiusura analisi",
        accessor: (cap: ICap) =>
          !!cap.dateResponseValidation && cap.responseValidation === "1" ? (
            cap.esignId ? (
              <>
                <IconCheckCircle
                  color="success"
                  size={1.25}
                  title={`Firmata il ${
                    cap.dateResponseValidation &&
                    moment(cap.dateResponseValidation).format("L[ alle ]LT")
                  }`}
                />{" "}
                <small>Analisi completata</small>
              </>
            ) : (
              <>
                <IconClock
                  color="primary"
                  size={1.25}
                  title="In attesa di firma"
                />{" "}
                <small>In attesa di firma</small>
              </>
            )
          ) : !!cap.dateResponseValidation && cap.responseValidation === "0" ? (
            <>
              <IconTimesCircle
                color="danger"
                size={1.25}
                title={`Rifiutata il ${
                  cap.dateResponseValidation &&
                  moment(cap.dateResponseValidation).format("L[ alle ]LT")
                }`}
              />{" "}
              <small>Rifiutata</small>
            </>
          ) : cap.dateSendValidation ? (
            <>
              <IconClock
                color="primary"
                size={1.25}
                title="In attesa di firma"
              />{" "}
              <small>In attesa di firma</small>
            </>
          ) : (
            <>
              <IconBan
                color="danger"
                size={1.25}
                title="Richiesta firma non inviata"
              />{" "}
              <small>Richiesta firma non inviata</small>
            </>
          ),
        id: "validation",
        sortable: false,
      },
      {
        Header: "Azioni",
        accessor: this.azioni.bind(this),
        filterable: false,
        id: "azioni",
        sortable: false,
        width: 192,
      },
    ]);

    return columns;
  }

  public componentWillUnmount() {
    this.props.readResetUiError();
    this.props.viewFromDbResetUiError();
  }

  public render() {
    return (
      <Layout isProtected>
        <PageTitle fluid className="px-md-5">
          Analisi personalizzate
          {this.props.canManageCap && (
            <Button
              tag={Link}
              color="light"
              outline
              to="/analisi/new"
              className="ml-auto"
            >
              <IconPlus /> Nuova analisi
            </Button>
          )}
        </PageTitle>
        <Container fluid className="auto-margin-4 px-0 px-sm-3 px-md-5">
          {!!this.props.ui.isDeleteSuccess && (
            <Alert
              color="info"
              toggle={this.props.resetDeleteSuccess}
              className="d-flex align-items-center"
            >
              Analisi eliminata correttamente{" "}
              <Button
                size="sm"
                color="light"
                outline
                onClick={this.undoDelete}
                className="ml-auto"
              >
                <IconUndo
                  className={
                    this.props.ui.isUndoDeletePending ? "icon-spin-bw" : ""
                  }
                />{" "}
                Annulla
              </Button>
            </Alert>
          )}
          {this.props.caps.isFail && (
            <Alert color="danger" className="d-flex align-items-center">
              {this.props.caps.error}{" "}
              <Button
                color="light"
                outline
                size="sm"
                onClick={this.refreshList}
                className="ml-auto"
              >
                <IconRefresh /> Aggiorna
              </Button>
            </Alert>
          )}
          {this.props.ui.isReadFail && (
            <Alert color="danger" toggle={this.props.readResetUiError}>
              {this.props.ui.readError}
            </Alert>
          )}
          {this.props.ui.isViewFromDbFail && (
            <Alert color="danger" toggle={this.props.viewFromDbResetUiError}>
              {this.props.ui.viewFromDbError}
            </Alert>
          )}
          <Container fluid className="container-max">
            <ReactTable
              LoadingComponent={ListPage.loadingComponent}
              data={this.props.items}
              columns={this.getColumns(this.props)}
              manual
              pages={Math.ceil(
                this.props.caps.numItems! / this.props.caps.params.limit
              )}
              defaultPageSize={50}
              defaultSorted={[
                {
                  desc: true,
                  id: "dateStart",
                },
              ]}
              minRows={5}
              filterable
              loading={this.props.caps.isPending}
              className="-striped -highlight"
              previousText="Precedente"
              nextText="Prossima"
              onFetchData={this.fetchData}
              noDataText="Nessuna analisi trovata"
              pageText="Pagina"
              ofText="di"
              rowsText="analisi"
            />
          </Container>
        </Container>
      </Layout>
    );
  }

  private undoDelete() {
    this.props.undoDelete(this.lastParams);
  }

  private refreshList() {
    this.props.list(this.lastParams);
  }

  private fetchData(state: any) {
    const filters: {[key: string]: string} = {};
    const sort = state.sorted
      .map((col: SortingRule) => (col.desc ? "-" + col.id : col.id))
      .join(",");

    state.filtered.forEach((filter: {id: string; value: string}) => {
      if (filter.id === "dateStart") {
        // Se il filtro contiene le date le trasformiamo
        // *Occhio* che dateStart in verità contiene entrambi dateStart e dateEnd
        filters[filter.id] = filter.value
          .split(",")
          .map((date) =>
            date ? moment(date, "DD-MM-YYYY").format("YYYY-MM-DD") : undefined
          )
          .join(",");
      } else {
        filters[filter.id] = filter.value;
      }
    });

    const params: ICapParams = {
      limit: state.pageSize,
      offset: state.page * state.pageSize,
      sort,
      ...filters,
    };

    this.lastParams = params;
    this.props.list(params);
  }

  private azioni(cap: ICap) {
    const isAdvisor = cap.user.id === this.props.loggedUser?.id;
    const isContractor = cap.contractor.id === this.props.loggedUser?.id;

    // Modificato da JOHN controllo sottostante: Aggiunto anche che NON DEVI ESSERE ADVISOR e CONTRACTOR, altrimenti capitavi solo in VIEW

    // Se sono il cliente di questo cap devo vedere la view.
    // Oppure se non ha permessi di modifica lo mandiamo alla visualizzazione
    // if (isContractor || !this.props.canManageCap) {
    if ((isContractor && !isAdvisor) || !this.props.canManageCap) {
      return (
        <Button
          tag={Link}
          size="sm"
          color="primary"
          outline
          block
          to={`/analisi/view/${cap.id}`}
        >
          <IconEye /> Visualizza
        </Button>
      );
    }
    if (cap.dateResponseValidation) {
      return (
        <Button
          tag={Link}
          size="sm"
          color="primary"
          outline
          block
          to={`/analisi/new/${cap.id}`}
        >
          <IconEye /> Visualizza
        </Button>
      );
    } else if (isAdvisor && !cap.dateIdd && !cap.dateScoperture) {
      return (
        <>
          <Button
            tag={Link}
            size="sm"
            color="primary"
            outline
            to={`/analisi/new/${cap.id}`}
          >
            <IconComplete /> Completa
          </Button>
          <DeleteCapButton cap={cap} onClick={this.props.delete}>
            {this.props.ui.isDeletePending &&
            this.props.ui.isDeletePending[cap.id] ? (
              <IconSpinner className="icon-spin" />
            ) : (
              <IconTrash />
            )}
            Elimina
          </DeleteCapButton>
        </>
      );
    } else if (isAdvisor && cap.dateIdd && !cap.dateScoperture) {
      return (
        <Button
          tag={Link}
          size="sm"
          color="primary"
          outline
          block
          to={`/analisi/new/${cap.id}`}
        >
          <IconComplete /> Integra analisi
        </Button>
      );
    } else if (isAdvisor && !cap.dateIdd && cap.dateScoperture) {
      return (
        <Button
          tag={Link}
          size="sm"
          color="primary"
          outline
          block
          to={`/analisi/new/${cap.id}`}
        >
          <IconComplete /> Integra IBIPs
        </Button>
      );
    } else {
      return (
        <Button
          tag={Link}
          size="sm"
          color="primary"
          outline
          block
          to={`/analisi/new/${cap.id}`}
        >
          <IconEye /> Visualizza
        </Button>
      );
    }
  }
}

const ConnectedPage = connect(mapStateToProps, mapDispatchToProps)(ListPage);

export default ConnectedPage;
