import React from "react";
import {IconSpinner} from "../../../Icons";

const UploadingFile: React.FC = () => (
  <div className="loading-file">
    <IconSpinner className="icon-spin mr-2" /> Caricamento file in corso...
  </div>
);

export default UploadingFile;
