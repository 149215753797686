import classNames from "classnames";
import last from "lodash/last";
import moment from "moment";
import React from "react";
import {RouteComponentProps} from "react-router";
import {
  Alert,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import ContainerMax from "../App/ContainerMax";
import checkHasAllContractorDetails from "../helpers/hasAllContractorDetails";
import {isCapComplianceApr21} from "../helpers/isComplianceApr21";
import overdraftsSections from "../helpers/overdraftsSections";
import {yn} from "../helpers/yn";
import {
  IconDoctor,
  IconEmployment,
  IconEye,
  IconHouse,
  IconIncomes,
  IconInfoCircle,
  IconOverdraft,
  IconPerson,
  IconRetirement,
  IconSavings,
  IconTargets,
  IconUpload,
} from "../Icons";
import IApiError from "../types/IApiError";
import {IState as IUserState} from "../User";
import {
  Actions,
  ContractorTable,
  Drawer,
  EmploymentsTable,
  HomeDetails,
  ICap,
  IInvestmentOption,
  InailTable,
  IncomesDetails,
  InpsTable,
  IStateUi,
  NewCapModalTypes,
  OverdraftSectionForm,
  RetirementDetails,
  SavingTable,
  TargetsDetails,
  targetSerie,
} from "./";
import CloseCap from "./CloseCap";
import CreateContractorDetails from "./CreateContractorDetails";
import Delivery from "./Delivery";
import DownloadBar from "./DownloadBar";
import HasIBIPsDrawer from "./hasIBIPs/HasIBIPsDrawer";
import {ContractorProductTable, IddResultSnippet, IddViewModal} from "./idd";
import IdentificationDetails from "./IdentificationDetails";
import PrivacyDetails from "./PrivacyDetails";
import PrivacySubscription from "./PrivacySubscription";
import {showAttachments3And4} from "../helpers/showAttachments3And4";
import {IUploadIdFormData} from "./UploadIdForm";
import UploadIdModal from "./UploadIdModal";

interface IMatchParams {
  capId: string;
}

interface IProps {
  closeModal: (modalType: NewCapModalTypes) => Actions;
  openModal: (modalType: NewCapModalTypes) => Actions;
  openRequestOTPPrivacyModal: (userId: string) => void;
  openRequestOTPIdentificationModal: (capId: string) => void;
  privacyOpenModal: () => void;
  uploadIdFail: (error: IApiError) => void;
  uploadIdSubmit: (values: IUploadIdFormData) => Promise<void>;
  closeIdd?: () => any;
  isContractor: boolean;
  options?: IInvestmentOption[];
  optionsList: () => Promise<void>;
  ui: IStateUi;
  userUi: IUserState;
  viewCap?: ICap;
}

interface IState {
  activeTab: string;
  viewIddModalIsOpen: boolean;
}

class ViewCap extends React.Component<
  IProps & RouteComponentProps<IMatchParams>,
  IState
> {
  public readonly state: Readonly<IState> = {
    activeTab: "marketing",
    viewIddModalIsOpen: false,
  };

  constructor(props: IProps & RouteComponentProps<IMatchParams>) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.toggleViewIdd = this.toggleViewIdd.bind(this);
  }

  public toggle(tab: string) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  public toggleViewIdd() {
    this.setState((prevState) => ({
      viewIddModalIsOpen: !prevState.viewIddModalIsOpen,
    }));
  }

  public render() {
    const hasAllContractorDetails = checkHasAllContractorDetails(
      this.props.viewCap
    );
    // Devo mostrare la pensione?
    let showRetirement = false;
    if (this.props.viewCap && this.props.viewCap.data.employments) {
      const emp = this.props.viewCap.data.employments.currentEmployment;
      if (emp.tipologia !== "pensione" && emp.tipologia !== "disoccupato") {
        showRetirement = true;
      }
    } else {
      showRetirement = true;
    }

    // Stampiamo la pensione cmq solo nel caso sia nel file di conf
    showRetirement =
      showRetirement && (yn(process.env.REACT_APP_ENABLE_RETIREMENT) || false);

    // calcolo le barre per i targets.
    const targetsData =
      this.props.viewCap &&
      targetSerie(
        this.props.viewCap.data.targets,
        this.props.viewCap.data.targetsWeights
      );

    const isContractor = this.props.isContractor;

    return (
      <>
        <Container>
          <h2>Analisi personalizzata</h2>
        </Container>
        {isContractor && (
          <div className="container-max px-md-5 pt-3 pb-3 auto-margin-4 container">
            <p>
              <IconInfoCircle
                size={1.5}
                title="Stato avanzamento consulenza personalizzata"
                className="mr-3"
              />
              In questa pagina puoi controllare lo stato avanzamento della Tua
              consulenza personalizzata.
            </p>
          </div>
        )}
        <ContainerMax isLoading={this.props.ui.isViewFromDbPending}>
          <h3>{isContractor ? "Onboarding" : "Onboarding del Cliente"}</h3>
          <Drawer
            icon={IconPerson}
            isOpen={!!(this.props.viewCap && this.props.viewCap.contractor)}
            title="Area personale"
          >
            {this.props.viewCap && this.props.viewCap.contractor && (
              <CreateContractorDetails
                details={this.props.viewCap.contractor}
                hideButtons
                isContractor={this.props.isContractor}
              />
            )}
          </Drawer>
          <Drawer
            icon={IconPerson}
            isOpen={!!(this.props.viewCap && this.props.viewCap.contractor)}
            title="Accettazione del consenso"
          >
            {this.props.viewCap && (
              <PrivacyDetails
                capId={this.props.viewCap.id}
                contractorId={this.props.viewCap.contractor.id}
                details={
                  Array.isArray(this.props.viewCap.contractor.privacy)
                    ? last(this.props.viewCap.contractor.privacy)
                    : this.props.viewCap.contractor.privacy
                }
                eSignId={this.props.viewCap.contractor.lastPrivacyEsignId}
                isCapComplianceApr21={isCapComplianceApr21(this.props.viewCap)}
                isContractor={this.props.isContractor}
                openRequestOTPPrivacyModal={
                  this.props.openRequestOTPPrivacyModal
                }
                privacyOpenModal={this.props.privacyOpenModal}
                showAttachments3And4={showAttachments3And4(this.props.viewCap)}
              />
            )}
          </Drawer>
        </ContainerMax>
        <ContainerMax
          isLoading={this.props.ui.isViewFromDbPending}
          isLocked={
            !this.props.viewCap ||
            !this.props.viewCap.contractor.lastPrivacyEsignId
          }
        >
          <h3>Demands and Needs</h3>
          <p>
            La consulenza inizia con la raccolta di tutte le informazioni
            necessarie per la corretta interpretazione delle risposte al
            questionario IBIPs e l'analisi delle scoperture.
          </p>

          <Drawer
            icon={IconPerson}
            isOpen={this.props.viewCap && hasAllContractorDetails}
            title="Anagrafica"
          >
            {this.props.viewCap && (
              <ContractorTable contractor={this.props.viewCap.contractor} />
            )}
          </Drawer>
          <Drawer
            icon={IconHouse}
            isOpen={!!(this.props.viewCap && this.props.viewCap.data.home)}
            title="Residenza"
          >
            {this.props.viewCap && this.props.viewCap.data.home && (
              <HomeDetails details={this.props.viewCap.data.home} />
            )}
          </Drawer>
          <Drawer
            icon={IconTargets}
            isOpen={!!(this.props.viewCap && this.props.viewCap.data.targets)}
            title="Obiettivi"
          >
            {this.props.viewCap &&
              this.props.viewCap.data.targets &&
              this.props.viewCap.data.targetsWeights && (
                <TargetsDetails
                  details={this.props.viewCap.data.targets}
                  weights={this.props.viewCap.data.targetsWeights}
                />
              )}
          </Drawer>
          <Drawer
            icon={IconEmployment}
            isOpen={
              !!(this.props.viewCap && this.props.viewCap.data.employments)
            }
            title="Situazione professionale"
          >
            {this.props.viewCap && this.props.viewCap.data.employments && (
              <EmploymentsTable
                employments={this.props.viewCap.data.employments}
              />
            )}
          </Drawer>
          <Drawer
            icon={IconIncomes}
            isOpen={!!(this.props.viewCap && this.props.viewCap.data.incomes)}
            title="Altri redditi"
          >
            {this.props.viewCap && this.props.viewCap.data.incomes && (
              <IncomesDetails incomes={this.props.viewCap.data.incomes} />
            )}
          </Drawer>
          <h3 className="drawer-spacer">Analisi generale</h3>
          {!isContractor && (
            <Drawer
              icon={IconDoctor}
              isOpen={!!(this.props.viewCap && this.props.viewCap.data.injury)}
              title="INPS e INAIL"
            >
              {this.props.viewCap &&
              this.props.viewCap.data.injury &&
              this.props.viewCap.data.employments ? (
                <div>
                  <h3 className="mb-3 p-3 subtitle">
                    INPS: Tutela da infortuni e malattie fuori dal luogo di
                    lavoro
                  </h3>
                  <p>
                    La tutela da infortuni e malattia che non si verificano in
                    contesto lavorativo è garantita dall’INPS. Per accedere alla
                    protezione è richiesta una anzianità contributiva di almeno
                    5 anni, di cui 3 maturati in maniera continuativa.
                  </p>
                  <InpsTable
                    employments={this.props.viewCap.data.employments}
                    contractor={this.props.viewCap.contractor}
                  />
                  <h3 className="mb-3 p-3 mt-3 subtitle">
                    INAIL: tutela da infortuni e malattia sul lavoro
                  </h3>
                  <p>
                    L’INAIL (Istituto nazionale Assicurazione Infortuni sul
                    Lavoro) tutela esclusivamente i dipendenti; gli artigiani e
                    i commercianti sono protetti solo nel caso abbiano deciso di
                    aderire all’istituto.
                  </p>
                  <InailTable
                    employments={this.props.viewCap.data.employments}
                    contractor={this.props.viewCap.contractor}
                  />
                </div>
              ) : null}{" "}
            </Drawer>
          )}
          <Drawer
            icon={IconSavings}
            isOpen={!!(this.props.viewCap && this.props.viewCap.data.saving)}
            title="Capacità di risparmio"
          >
            {this.props.viewCap && this.props.viewCap.data.saving && (
              <SavingTable saving={this.props.viewCap.data.saving} />
            )}
          </Drawer>
        </ContainerMax>
        <ContainerMax
          isLoading={this.props.ui.isViewFromDbPending}
          isLocked={!this.props.viewCap || !this.props.viewCap.dateDen}
        >
          <Nav justified className="nav-max mt-n5 d-none d-sm-flex">
            <NavItem>
              <NavLink
                href="#"
                className={classNames({
                  active: this.state.activeTab === "marketing",
                })}
                onClick={this.toggle.bind({}, "marketing")}
              >
                Analisi delle scoperture
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                className={classNames({
                  active: this.state.activeTab === "idd",
                })}
                onClick={this.toggle.bind({}, "idd")}
              >
                Questionario IBIPs
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="marketing" className="auto-margin-4">
              <h3>Analisi delle scoperture</h3>
              {!this.props.viewCap || !this.props.viewCap.dateScoperture ? (
                <Alert color="info">
                  Analisi delle scoperture non completata
                </Alert>
              ) : (
                <>
                  {showRetirement && (
                    <Drawer
                      icon={IconRetirement}
                      isOpen={
                        !!(
                          this.props.viewCap &&
                          this.props.viewCap.data.retirement
                        )
                      }
                      title="Stima della pensione"
                    >
                      {this.props.viewCap &&
                      this.props.viewCap.data.retirement &&
                      this.props.viewCap.data.employments ? (
                        <div>
                          <h3 className="m-0">STIMA DELLA PENSIONE</h3>
                          <RetirementDetails
                            employments={this.props.viewCap.data.employments}
                            contractor={this.props.viewCap.contractor}
                          />
                        </div>
                      ) : null}
                    </Drawer>
                  )}
                  <Drawer
                    icon={IconOverdraft}
                    isOpen={
                      !!(
                        this.props.viewCap &&
                        this.props.viewCap.data.overdrafts &&
                        this.props.viewCap.data.overdrafts.beniCasaAnimali
                      )
                    }
                    title="Tutelare beni, casa, animali"
                  >
                    {["beniCasaAnimali"].map((name, i) => {
                      const overdraft = overdraftsSections[name];
                      return (
                        <OverdraftSectionForm
                          cap={this.props.viewCap!}
                          disabled
                          hideButtons
                          form={`overdraft-${name}`}
                          target={(targetsData && targetsData[i]) || 0}
                          key={name}
                          name={name}
                          questions={overdraft.questions}
                          title={overdraft.title}
                          autonomo={
                            !!this.props.viewCap &&
                            this.props.viewCap.data.employments &&
                            this.props.viewCap.data.employments
                              .currentEmployment.tipologia === "autonomo"
                          }
                        />
                      );
                    })}
                  </Drawer>
                  <Drawer
                    icon={IconOverdraft}
                    isOpen={
                      !!(
                        this.props.viewCap &&
                        this.props.viewCap.data.overdrafts &&
                        this.props.viewCap.data.overdrafts.tenoreVita
                      )
                    }
                    title="Tutelare il tenore di vita personale e familiare"
                  >
                    {["tenoreVita"].map((name, i) => {
                      const overdraft = overdraftsSections[name];
                      return (
                        <OverdraftSectionForm
                          cap={this.props.viewCap!}
                          disabled
                          hideButtons
                          form={`overdraft-${name}`}
                          target={(targetsData && targetsData[i]) || 0}
                          key={name}
                          name={name}
                          questions={overdraft.questions}
                          title={overdraft.title}
                          autonomo={
                            !!this.props.viewCap &&
                            this.props.viewCap.data.employments &&
                            this.props.viewCap.data.employments
                              .currentEmployment.tipologia === "autonomo"
                          }
                        />
                      );
                    })}
                  </Drawer>
                  <Drawer
                    icon={IconOverdraft}
                    isOpen={
                      !!(
                        this.props.viewCap &&
                        this.props.viewCap.data.overdrafts &&
                        this.props.viewCap.data.overdrafts
                          .infortuniMalattiaPrevenzione
                      )
                    }
                    title="Tutelarsi da infortuni, malattia & prevenzione"
                  >
                    {["infortuniMalattiaPrevenzione"].map((name, i) => {
                      const overdraft = overdraftsSections[name];
                      return (
                        <OverdraftSectionForm
                          cap={this.props.viewCap!}
                          disabled
                          hideButtons
                          form={`overdraft-${name}`}
                          target={(targetsData && targetsData[i]) || 0}
                          key={name}
                          name={name}
                          questions={overdraft.questions}
                          title={overdraft.title}
                          autonomo={
                            !!this.props.viewCap &&
                            this.props.viewCap.data.employments &&
                            this.props.viewCap.data.employments
                              .currentEmployment.tipologia === "autonomo"
                          }
                        />
                      );
                    })}
                  </Drawer>
                </>
              )}
            </TabPane>
            <TabPane tabId="idd" className="auto-margin-4">
              <h3 className="d-block d-sm-none pt-5">Questionario IBIPs</h3>
              {!this.props.viewCap || !this.props.viewCap.dateIdd ? (
                <Alert color="info">
                  Valutazione di adeguatezza IBIPs non completata
                </Alert>
              ) : (
                <>
                  <HasIBIPsDrawer
                    cap={this.props.viewCap!}
                    disabled
                    options={this.props.options}
                    optionsList={this.props.optionsList}
                    ui={this.props.ui}
                  />
                  <Drawer
                    icon={IconRetirement}
                    isOpen
                    title="Costruire un capitale per il futuro"
                  >
                    {["risparmioFuturo"].map((name) => {
                      const overdraft = overdraftsSections[name];
                      return (
                        <OverdraftSectionForm
                          cap={this.props.viewCap!}
                          form={`overdraft-${name}`}
                          disabled
                          hideButtons
                          target={(targetsData && targetsData[3]) || 0}
                          key={name}
                          name={name}
                          bars={false}
                          questions={overdraft.questions}
                          title={overdraft.title}
                        />
                      );
                    })}
                  </Drawer>
                  <Drawer
                    icon={IconDoctor}
                    isOpen
                    closeDrawerButton={
                      <>
                        <IconEye /> Vedi risposte
                      </>
                    }
                    openModal={
                      this.props.viewCap &&
                      this.props.viewCap.idd &&
                      this.openModal(NewCapModalTypes.ViewIdd)
                    }
                    modal={
                      this.props.viewCap &&
                      this.props.viewCap.idd && (
                        <IddViewModal
                          closeViewIdd={this.closeModal(
                            NewCapModalTypes.ViewIdd
                          )}
                          isOpen={
                            !!this.props.ui.isModalOpen[
                              NewCapModalTypes.ViewIdd
                            ]
                          }
                          capItem={this.props.viewCap}
                          ui={this.props.ui}
                        />
                      )
                    }
                    title="Valutazione di adeguatezza IBIPs"
                  >
                    {this.props.viewCap && this.props.viewCap.idd && (
                      <>
                        <div>
                          <h6>Profilo cliente</h6>
                          <IddResultSnippet idd={this.props.viewCap.idd.idd} />
                        </div>
                        <ContractorProductTable idd={this.props.viewCap.idd} />
                      </>
                    )}
                  </Drawer>
                </>
              )}
            </TabPane>
          </TabContent>
        </ContainerMax>
        <ContainerMax
          isLocked={
            !this.props.viewCap?.dateIdd && !this.props.viewCap?.dateScoperture
          }
          isLoading={this.props.ui.isReadPending}
          id="identification"
        >
          <h3 className="d-flex justify-content-between">
            Identificazione del cliente
          </h3>
          <Drawer
            icon={IconPerson}
            isOpen={!!this.props.viewCap}
            modal={
              this.props.viewCap && (
                <UploadIdModal
                  closeIdentification={this.closeModal(
                    NewCapModalTypes.UploadId
                  )}
                  uploadIdFail={this.props.uploadIdFail}
                  uploadIdSubmit={this.props.uploadIdSubmit}
                  ui={this.props.ui}
                  isUploadIdFail={this.props.userUi.isUploadIdFail}
                  uploadIdError={this.props.userUi.uploadIdError}
                />
              )
            }
            closeDrawerButton={
              <>
                <IconUpload /> Carica copia documento
              </>
            }
            openModal={
              this.props.isContractor &&
              (!this.props.viewCap ||
                !this.props.viewCap.onboarding ||
                !this.props.viewCap.onboarding.identification)
                ? this.openModal(NewCapModalTypes.UploadId)
                : undefined
            }
            title="Identificazione"
          >
            {this.props.viewCap && (
              <IdentificationDetails
                cap={this.props.viewCap}
                contractor={this.props.viewCap.contractor}
                details={
                  this.props.viewCap.onboarding &&
                  this.props.viewCap.onboarding.identification
                }
                isContractor={this.props.isContractor}
                openRequestOTPIdentificationModal={
                  this.props.openRequestOTPIdentificationModal
                }
              />
            )}
          </Drawer>
        </ContainerMax>

        {!this.props.viewCap?.dateResponseValidation ||
        this.props.viewCap?.dateResponseValidation?.isAfter(
          moment(process.env.REACT_APP_DATE_START_DELIVERY)
        ) ? (
          <>
            <Delivery cap={this.props.viewCap} />
            <CloseCap cap={this.props.viewCap} id="close-cap" />
            <PrivacySubscription
              as={ContainerMax}
              cap={this.props.viewCap}
              isLocked={!this.props.viewCap?.esignId}
              isLoading={this.props.ui.isReadPending}
              id="subscription-privacy"
            />
          </>
        ) : (
          <DownloadBar viewCap={this.props.viewCap} />
        )}
      </>
    );
  }

  private openModal(modalType: NewCapModalTypes) {
    return () => this.props.openModal(modalType);
  }
  private closeModal(modalType: NewCapModalTypes) {
    return () => this.props.closeModal(modalType);
  }
}

export default ViewCap;
