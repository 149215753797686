import moment from "moment";
import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import ReactTable, {
  Column,
  ControlledStateOverrideProps,
  SortingRule,
} from "react-table";
import {Alert, Button, Container} from "reactstrap";
import {Layout, PageTitle} from "../App";
import ColumnDateFilter from "../helpers/ColumnDateFilter";
import {IconEye, IconPlus, IconRefresh, IconSpinner} from "../Icons";
import {IRootState} from "../redux/reducers";
import {getHasPermission} from "../User/selectors";
import AdvisorTd from "./List/AdvisorTd";
import ContractorTd from "./List/ContractorTd";
import {actions, selectors} from "./slice";
import {IBeneProposal, IBeneProposalParams} from "./types";

interface ILoadingProps {
  loading: boolean;
}

const defaultPageSize = 50;
const productTypeLabel = {
  house: "Bene Casa",
  injuries: "Bene Infortuni",
  rc: "Bene Professional",
};

const LoadingComponent = ({loading}: ILoadingProps) =>
  loading ? (
    <div className="-loading -active">
      <div className="-loading-inner">
        <IconSpinner className="icon-spin" /> Caricamento proposte in corso...
      </div>
    </div>
  ) : null;

const List = () => {
  const dispatch = useDispatch();

  const beneProposalsList = useSelector(selectors.selectList);
  const listLength = useSelector(selectors.selectListLength);
  const lastParams = useSelector(selectors.selectListParams);
  const listThunkStates = useSelector((state: IRootState) =>
    selectors.selectThunkActionStates(state, "beneProposals/list")
  );
  const canManageBeneProposal = useSelector((state: IRootState) =>
    getHasPermission(state, "bene.manage")
  );

  const columns = React.useMemo(() => {
    let columns: Column<IBeneProposal>[] = [];

    if (canManageBeneProposal) {
      columns = columns.concat([
        {
          Header: "Cliente",
          accessor: (proposal: IBeneProposal) => (
            <ContractorTd id={proposal.contractor} />
          ),
          id: "contractor",
        },
      ]);
    }

    columns = columns.concat([
      {
        Header: "Advisor",
        accessor: (proposal: IBeneProposal) => <AdvisorTd id={proposal.user} />,
        id: "user",
      },
      {
        Header: "Tipo",
        accessor: (proposal: IBeneProposal) => (
          <span>{productTypeLabel[proposal.product.type]}</span>
        ),
        id: "productType",
      },
      {
        Header: "Inserita il",
        Filter: ({filter, onChange}) => (
          <ColumnDateFilter filter={filter} onChange={onChange} />
        ),
        accessor: (proposal: IBeneProposal) =>
          moment(proposal.date).format("DD MMMM YYYY"),
        id: "date",
      },
      {
        Header: "Azioni",
        accessor: (proposal: IBeneProposal) => (
          <Button
            tag={Link}
            size="sm"
            color="primary"
            outline
            block
            to={`/proposte-bene/${proposal.id}`}
          >
            <IconEye /> Visualizza
          </Button>
        ),
        id: "azioni",
        sortable: false,
        filterable: false,
        width: 150,
      },
    ]);

    return columns;
  }, [canManageBeneProposal]);

  const refreshList = () => {
    dispatch(actions.list(lastParams));
  };

  const fetchData = useCallback(
    (state: ControlledStateOverrideProps) => {
      const filters: {[key: string]: string} = {};
      const sort = state.sorted
        .map((col: SortingRule) => (col.desc ? "-" + col.id : col.id))
        .join(",");

      state.filtered.forEach((filter: {id: string; value: string}) => {
        if (filter.id === "date") {
          // Se il filtro contiene le date le trasformiamo
          filters[filter.id] = filter.value
            .split(",")
            .map((date) =>
              date ? moment(date, "DD-MM-YYYY").format("YYYY-MM-DD") : undefined
            )
            .join(",");
        } else {
          filters[filter.id] = filter.value;
        }
      });

      const params: IBeneProposalParams = {
        limit: state.pageSize ?? defaultPageSize,
        offset: (state.page ?? 1) * (state.pageSize ?? defaultPageSize),
        sort,
        ...filters,
      };

      dispatch(actions.list(params));
    },
    [dispatch]
  );

  return (
    <Layout isProtected>
      <PageTitle fluid className="px-md-5">
        Polizze Bene Assicurazioni
        {canManageBeneProposal && (
          <Button
            tag={Link}
            color="light"
            outline
            to="/proposte-bene/new"
            className="ml-auto"
          >
            <IconPlus /> Nuova polizza Bene
          </Button>
        )}
      </PageTitle>
      <Container fluid className="auto-margin-4 px-0 px-sm-3 px-md-5">
        {listThunkStates.isFail && (
          <Alert color="danger" className="d-flex align-items-center">
            {listThunkStates.error}{" "}
            <Button
              color="light"
              outline
              size="sm"
              onClick={refreshList}
              className="ml-auto"
            >
              <IconRefresh /> Aggiorna
            </Button>
          </Alert>
        )}
        <Container fluid className="container-max">
          <ReactTable
            LoadingComponent={LoadingComponent}
            data={beneProposalsList}
            columns={columns}
            manual
            pages={Math.ceil(listLength / lastParams.limit)}
            onFetchData={fetchData}
            defaultPageSize={defaultPageSize}
            defaultSorted={[
              {
                desc: true,
                id: "date",
              },
            ]}
            filterable
            minRows={5}
            loading={listThunkStates.isPending}
            className="-striped -highlight"
            previousText="Precedente"
            nextText="Prossima"
            noDataText="Non è stata creata ancora nessuna proposta"
            pageText="Pagina"
            ofText="di"
            rowsText="proposte"
          />
        </Container>
      </Container>
    </Layout>
  );
};

export default List;
