import React from "react";
import {Container} from "reactstrap";
import {IconLock, IconSpinner} from "../Icons";

interface IProps {
  id?: string;
  isLoading?: boolean;
  isLocked?: boolean;
}

const ContainerMax: React.FC<IProps> = ({
  children,
  id,
  isLoading,
  isLocked,
}) => (
  <Container id={id} className="container-max px-md-5 pt-md-5 pb-md-4">
    <div className="auto-margin-4">{children}</div>
    {isLoading && (
      <div className="component-loader">
        <IconSpinner className="icon-spin mr-2" size={2} /> Caricamento in
        corso...
      </div>
    )}
    {!isLoading && isLocked && (
      <div className="component-loader">
        <IconLock size={4} />
      </div>
    )}
  </Container>
);

export default ContainerMax;
