import classNames from "classnames";
import debounce from "lodash/debounce";
import React, {useEffect, useMemo} from "react";
import AutoSuggest, {
  ChangeEvent,
  RenderSuggestionParams,
  RenderSuggestionsContainerParams,
  SuggestionSelectedEventData,
  SuggestionsFetchRequestedParams,
} from "react-autosuggest";
import {useDispatch, useSelector} from "react-redux";
import {FormFeedback, Input} from "reactstrap";
import {change, Field, touch, WrappedFieldProps} from "redux-form";
import {getHighlightedText} from "../../helpers/getHighlightedText";
import {IconSpinner, IconWarning} from "../../Icons";
import {IRootState} from "../../redux/reducers";
import {actions} from "../actions";
import {getCities} from "../selectors";
import {ICity} from "../types";

const getSuggestionValue = (suggestion: ICity) => suggestion.cNomeComune;

interface IOwnProps {
  id?: string;
  placeholder?: string;
  noValidate?: boolean;
  strict?: boolean;
}

type IProps = IOwnProps & WrappedFieldProps;

export const AutoSuggestCitiesReduxForm = ({
  id,
  placeholder,
  noValidate,
  strict,
  input: {
    onChange: reduxFormOnChange,
    onBlur: reduxFormOnBlur,
    name,
    ...reduxFormInputProps
  },
  meta: {form, initial, touched, dirty, error, active, ...otherMeta},
  ...props
}: IProps) => {
  const dispatch = useDispatch();

  const cities = useSelector(getCities);
  const isCityListPending = useSelector(
    (state: IRootState) => state.caps.ui.isCitiesListPending
  );

  const theme = {
    suggestionsContainer: "dropdown",
    suggestionsList: `dropdown-menu w-100 ${cities.length ? "show" : ""}`,
    suggestion: "dropdown-item",
  };

  const onBlur = (event: React.FocusEvent<HTMLElement>) => {
    //   console.log("targetValue:", event.target.value);
    //   dispatch(blur(form, name, event.target.value));
    dispatch(touch(form, name));
  };

  const onChange = (event: React.FormEvent, {newValue}: ChangeEvent) => {
    dispatch(change(form, `${name}CSiglaProvincia`, ""));
    dispatch(change(form, `${name}IdObject`, ""));
    dispatch(change(form, `${name}BExist`, ""));
    dispatch(change(form, `${name}More`, ""));
    reduxFormOnChange(newValue);
  };

  const inputProps = {
    id,
    placeholder,
    onChange,
    onBlur,
    name,
    ...reduxFormInputProps,
  };

  const onSuggestionSelected = (
    event: React.FormEvent<any>,
    {suggestion, suggestionIndex}: SuggestionSelectedEventData<ICity>
  ) => {
    event.preventDefault();
    dispatch(
      change(form, `${name}CSiglaProvincia`, suggestion.cSiglaProvincia)
    );
    dispatch(change(form, `${name}IdObject`, suggestion.idObject));
    dispatch(change(form, `${name}BExist`, suggestion.bExist));
    dispatch(
      actions.loadCitiesSuccess([cities[suggestionIndex]], {exist: true})
    );
  };

  const renderInputComponent: any = (inputProps: any) => (
    <Input
      {...(noValidate !== true && touched ? {invalid: !!error} : {})}
      {...(noValidate !== true && dirty ? {valid: !error} : {})}
      {...inputProps}
    />
  );

  const onSuggestionsClearRequested = () => {};

  const renderSuggestionsContainer = ({
    containerProps,
    children,
    query,
  }: RenderSuggestionsContainerParams) => {
    return (
      <div {...containerProps}>
        {cities.length === 0 && !isCityListPending && query && active && (
          <div className="dropdown-menu show w-100 bg-warning">
            <span className="dropdown-item-text text-center">
              <IconWarning /> Nessun comune trovato per "{query}"
            </span>
          </div>
        )}
        {isCityListPending && (
          <span className="dropdown-item-text text-center">
            <IconSpinner className="icon-spin" /> Caricamento...
          </span>
        )}
        {children}
      </div>
    );
  };

  const renderSuggestion = (
    suggestion: ICity,
    {query, isHighlighted}: RenderSuggestionParams
  ) => (
    <button
      className={classNames("dropdown-item", {
        active: isHighlighted,
      })}
      type="button"
    >
      {getHighlightedText(suggestion.cNomeComune, query)} (
      {suggestion.cSiglaProvincia})
    </button>
  );

  const onSuggestionsFetchRequested = useMemo(
    () =>
      debounce(({value, reason}: SuggestionsFetchRequestedParams) => {
        if (reason !== "input-focused") {
          dispatch(actions.loadCities({limit: 10, name: value, exist: strict}));
        }
      }, 500),
    [dispatch, strict]
  );

  useEffect(() => {
    dispatch(actions.loadCities({name: initial, limit: 1}));
  }, [dispatch, initial]);

  return (
    <>
      <Field type="hidden" name={`${name}IdObject`} component="input" />
      <Field type="hidden" name={`${name}BExist`} component="input" />
      <AutoSuggest
        suggestions={cities}
        getSuggestionValue={getSuggestionValue}
        inputProps={inputProps}
        renderInputComponent={renderInputComponent}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        onSuggestionSelected={onSuggestionSelected}
        focusInputOnSuggestionClick={false}
        theme={theme}
      />
      {error && touched && (
        <FormFeedback className="d-block">{error}</FormFeedback>
      )}
    </>
  );
};
