import React from "react";
import {Container} from "reactstrap";

interface IProps {
  className?: string;
  fluid?: boolean;
}

const PageTitle: React.FC<IProps> = ({children, className, fluid}) => (
  <Container fluid={fluid} className={className}>
    <h2 className="d-flex align-items-center m-0">{children}</h2>
  </Container>
);

export default PageTitle;
