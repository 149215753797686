import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {
  Alert,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";

import {Layout} from "../App";
import {IRootState} from "../redux/reducers";
import {
  actions,
  ActivateAlert,
  ILostPasswordFormData,
  IState,
  LostPasswordForm,
} from "./";

import "./LoginPage.scss";

const mapStateToProps = (state: IRootState): IState => state.user;
const mapDispatchToProps = {
  onSubmitForm: (values: ILostPasswordFormData) => {
    return actions.lostPassword(values);
  },
};

const LostPasswordPage = (props: any) => {
  return (
    <Layout hasMenu={false}>
      <ActivateAlert className="mt-n4" />
      <Container className="main-center auto-margin-4">
        <Row>
          <Col
            sm={{size: 8, offset: 2}}
            md={{size: 6, offset: 3}}
            lg={{size: 4, offset: 4}}
          >
            <img
              src="/img/logo.svg"
              className="logo mx-auto d-block img-fluid"
              alt={`${process.env.REACT_APP_TITLE} logo`}
            />
          </Col>
        </Row>
        <Row>
          <Col
            sm={{size: 8, offset: 2}}
            md={{size: 6, offset: 3}}
            lg={{size: 4, offset: 4}}
          >
            {props.isLostPasswordSuccess ? (
              <Alert color="success">
                <h4 className="alert-heading">Recupera password</h4>
                Controlla la tua casella di posta "{props.lostPasswordEmail}
                ".
                <br />
                Ti abbiamo inviato una email con il link per impostare la tua
                nuova password.
              </Alert>
            ) : (
              <Card className="text-primary">
                <CardBody>
                  <CardTitle tag="h3">Hai perso la password?</CardTitle>
                  <CardText>
                    Inserisci la tua email oppure il tuo nome utente.
                    <br /> Ti invieremo un messaggio con le istruzioni per
                    impostare una nuova password.
                  </CardText>
                  <LostPasswordForm onSubmit={props.onSubmitForm} {...props} />
                </CardBody>
              </Card>
            )}
            <Link to="/" className="text-light">
              Torna alla pagina di accesso
            </Link>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LostPasswordPage);
