import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Button, Card} from "reactstrap";
import {dehydrateCap, ICap, NewCapModalTypes} from "../../../Caps";
import {actions as capsActions} from "../../../Caps/actions";
import IdentificationDetails from "../../../Caps/IdentificationDetails";
import {IStateUi} from "../../../Caps/reducers";
import {getIsUploadIdFail, getUploadIdError} from "../../../Caps/selectors";
import {IUploadIdFormData} from "../../../Caps/UploadIdForm";
import UploadIdModal from "../../../Caps/UploadIdModal";
import isIdValid from "../../../helpers/isIdValid";
import {IconPerson} from "../../../Icons";
import IApiError from "../../../types/IApiError";

interface IProps {
  cap: ICap;
  capUi: IStateUi;
  disableUpload?: boolean;
  isAdvisor: boolean;
  isContractor: boolean;
  identificationSubmit: () => Promise<void>;
  openRequestOTPIdentificationModal: (capId: string) => void;
}

const CapIdentificationDetails = ({
  cap,
  capUi,
  disableUpload,
  isAdvisor,
  isContractor,
  identificationSubmit,
  openRequestOTPIdentificationModal,
}: IProps) => {
  const dispatch = useDispatch();
  const openUploadIdModal = React.useCallback(() => {
    dispatch(capsActions.openNewCapModal(NewCapModalTypes.UploadId));
  }, [dispatch]);
  const closeUploadIdModal = React.useCallback(() => {
    dispatch(capsActions.closeNewCapModal(NewCapModalTypes.UploadId));
  }, [dispatch]);
  const isUploadIdFail = useSelector(getIsUploadIdFail);
  const uploadIdError = useSelector(getUploadIdError);

  const uploadIdSubmitWithData = React.useCallback(
    async (values: IUploadIdFormData) => {
      if (!cap) {
        return cap;
      }

      const {
        result,
        entities: {caps, users},
      } = dehydrateCap(cap);
      const itemEntity = caps[result];
      const contractor = users[itemEntity.contractor];

      return await capsActions.uploadId(
        contractor.id,
        itemEntity,
        values
      )(dispatch);
    },
    [cap, dispatch]
  );
  const uploadIdFail = React.useCallback(
    (error: IApiError) => {
      dispatch(capsActions.uploadIdFail(error));
    },
    [dispatch]
  );

  const renderAlert = () => {
    if (disableUpload) {
      return null;
    }
    if (!cap.onboarding?.identification) {
      // Non c'è ancora un id nel cap
      return (
        <Alert color="danger">
          Per procedere alla compilazione della proposta, devi confermare
          l'identità del tuo cliente
        </Alert>
      );
    } else if (
      !cap.onboarding.identification.fileIdFaceName ||
      !cap.onboarding.identification.fileIdFCName
    ) {
      // L'id manca della terza foto o la quarta
      return (
        <Alert color="danger">
          L'identificazione del cliente deve essere ripetuta perché, ai fini
          dell'antiriciclaggio, è necessario aggiungere al documento d'identità
          la fotografia del viso del cliente affiancato al documento stesso.
        </Alert>
      );
    } else if (!cap.onboarding.identification.esignId) {
      // Manca la firma dell'advisor
      return (
        <Alert color="danger">
          Per procedere alla compilazione della proposta, devi firmare il PDF
          dell'dentitificazione del tuo cliente
        </Alert>
      );
    } else if (!cap.onboarding.identification.contractorEsignId) {
      // Manca la firma del cliente
      return (
        <Alert color="danger">
          Per procedere alla compilazione della proposta, il cliente deve
          firmare il PDF dell'dentitificazione
        </Alert>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      <Card body color="drawer auto-margin-3" id="cap-identification-details">
        <div className="d-flex justify-content-between align-items-center">
          <h3>
            <IconPerson title="Identificazione del cliente" /> Identificazione
          </h3>
          {isAdvisor &&
            !disableUpload &&
            !isIdValid(cap.onboarding?.identification) && (
              <Button color="primary" outline onClick={openUploadIdModal}>
                Carica un documento del cliente
              </Button>
            )}
        </div>
        <IdentificationDetails
          cap={cap}
          contractor={cap.contractor}
          details={cap.onboarding && cap.onboarding.identification}
          isAdvisor={isAdvisor}
          isContractor={isContractor}
          isIdentificationPending={capUi?.isIdentificationPending}
          identificationError={capUi?.identificationError}
          identificationSubmit={identificationSubmit}
          openRequestOTPIdentificationModal={openRequestOTPIdentificationModal}
        />
        {isAdvisor && cap.contractor.identification && renderAlert()}
      </Card>
      {capUi && uploadIdFail && (
        <UploadIdModal
          closeIdentification={closeUploadIdModal}
          uploadIdFail={uploadIdFail}
          uploadIdSubmit={uploadIdSubmitWithData}
          ui={capUi}
          isUploadIdFail={isUploadIdFail}
          uploadIdError={uploadIdError}
        />
      )}
    </>
  );
};

export default CapIdentificationDetails;
