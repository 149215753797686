import animateScrollTo from "animated-scroll-to";
import moment from "moment";
import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {
  Alert,
  Button,
  Col,
  Form,
  FormGroup,
  Label,
  Row,
  Table,
} from "reactstrap";
import {Dispatch} from "redux";
import {change, Field, formValueSelector, reduxForm} from "redux-form";
import roundTo from "round-to";
import {ICap, IInvestmentOption, IInvestmentProduct} from "../../Caps";
import {IddResultSnippet} from "../../Caps/idd";
import OptionCriteriaTable from "../../Caps/idd/OptionsCriteriaTable";
import errors, {ErrorCodes} from "../../helpers/errors";
import {focusFirstInvalid} from "../../helpers/focusFirstInvalid";
import FormatNumber from "../../helpers/FormatNumber";
import isIdValid from "../../helpers/isIdValid";
import {naturalNormalizer} from "../../helpers/normalizers";
import RenderCustomCheckboxField from "../../helpers/RenderCustomCheckboxField";
import RenderField from "../../helpers/RenderField";
import {IconInfoCircle, IconInfoCircleSolid, IconSpinner} from "../../Icons";
import {IRootState} from "../../redux/reducers";
import {IRevision} from "../types";

export interface IChooseOptionsFormData {
  code: string;
  comment: string;
  company: string;
  options: {[Key: string]: {value: boolean; percent: string}};
  product: string;
  userId: string;
  cap: ICap;
}
interface IChooseOptionsFormErrors {
  code?: string;
  comment?: string;
  company?: string;
  product?: string;
  options: {[Key: string]: {percent: string}};
  _error?: string;
}
// interface IStateProps {
//   activeInput: string;
//   companyValue: string;
//   dispatch: Dispatch;
//   optionsTotal: number;
//   optionsValue: Array<{value: boolean; percent: string}>;
//   productValue: string;
// }
interface IOwnProps {
  availableOptions?: IInvestmentOption[];
  form: string;
  submitError?: string;
  optionsList: () => Promise<void>;
  initialValues: {
    userId: string;
  } & Partial<IChooseOptionsFormData>;
  revisions?: IRevision[];
  suitableOptions: IInvestmentOption[];
  userId: string;
  cap: ICap;
}

interface IState {
  isExpanded: boolean[];
}

// TODO: Rivedere i tipi che scazzano
// type IProps = InjectedFormProps<
//   IChooseOptionsFormData,
//   IOwnProps,
//   {[Key: string]: {percent: string}} | string
// > &
//   IStateProps &
//   IOwnProps;

const validate = (values: IChooseOptionsFormData) => {
  const validationErrors: IChooseOptionsFormErrors = {options: {}};
  let optionsTotal = 0;
  let commentRequired = false;
  const optionsLength = values.options ? Object.keys(values.options).length : 0;

  const optionPercentValue = roundTo.down(100 / optionsLength, 0);
  const sparePercentage = 100 - optionPercentValue * optionsLength;

  Object.keys(values.options || {}).forEach((key, index) => {
    if (
      values.options[key] &&
      values.options[key].value &&
      (!values.options[key].percent ||
        parseFloat(values.options[key].percent) <= 0)
    ) {
      validationErrors.options[key] = {percent: "Inserisci una percentuale"};
    } else if (!values.options[key] || !values.options[key].value) {
      commentRequired = true;
    }

    if (
      (index < sparePercentage &&
        values.options[key] &&
        values.options[key].percent !== (optionPercentValue + 1).toString()) ||
      (index >= sparePercentage &&
        values.options[key] &&
        values.options[key].percent !== optionPercentValue.toString())
    ) {
      commentRequired = true;
    }

    optionsTotal +=
      values.options[key] &&
      values.options[key].value &&
      values.options[key].percent
        ? parseFloat(values.options[key].percent)
        : 0;
  });
  if (optionsTotal !== 100) {
    validationErrors._error = errors[ErrorCodes.OPTIONS_TOTAL_NOT_100].message;
  }
  if (!values.code) {
    validationErrors.code = "Il codice raccomandazione è obbligatorio";
  }

  if (commentRequired && !values.comment) {
    validationErrors.comment =
      "Se si modificano le percentuali delle opzioni selezionate è richiesta una motivazione relativa alla scelta effettuata";
  }

  return validationErrors;
};

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => {
  const selector = formValueSelector(ownProps.form);
  const companyValue = selector(state, "company");
  const productValue = selector(state, "product");

  const optionsValue: Array<{value: boolean; percent: string}> =
    selector(state, "options") || {};
  let optionsTotal = 0;
  Object.keys(optionsValue).forEach((key) => {
    optionsTotal += parseFloat(
      (optionsValue[parseInt(key, 10)] &&
        optionsValue[parseInt(key, 10)].percent) ||
        "0"
    );
  });

  const activeInput =
    state.form[ownProps.form] && state.form[ownProps.form].active;

  return {
    activeInput,
    companyValue,
    optionsTotal,
    optionsValue,
    productValue,
  };
};

const companyChangeHandler = (form: string, dispatch: Dispatch) => {
  dispatch(change(form, `product`, ""));
};

const productChangeHandler = (
  form: string,
  dispatch: Dispatch,
  options: IInvestmentOption[],
  event: React.ChangeEvent<HTMLSelectElement>
) => {
  const value = event.target.value;
  const selectedOptions = options.filter(
    (option) => option.product.id.toString() === value
  );
  const optionPercentValue = roundTo.down(100 / selectedOptions.length, 0);
  const sparePercentage = 100 - optionPercentValue * selectedOptions.length;

  const newOptions: {[key: string]: {value: boolean; percent: string}} = {};
  selectedOptions.forEach((option, index) => {
    newOptions[option.id] = {
      percent:
        index < sparePercentage
          ? (optionPercentValue + 1).toString()
          : optionPercentValue.toString(),
      value: true,
    };
  });
  dispatch(change(form, "options", newOptions));
};

const productCheckboxChangeHandler: any = (
  form: string,
  dispatch: Dispatch,
  event: React.ChangeEvent<HTMLInputElement>,
  value: boolean
) => {
  if (!value) {
    const percentName = event.target.name.replace("value", "percent");
    dispatch(change(form, percentName, ""));
  }
};

const productPercentChangeHandler: any = (
  form: string,
  dispatch: Dispatch,
  event: React.ChangeEvent<HTMLInputElement>
) => {
  const checkboxName = event.target.name.replace("percent", "value");
  if (!parseInt(event.currentTarget.value, 10)) {
    dispatch(change(form, checkboxName, false));
  } else {
    dispatch(change(form, checkboxName, true));
  }
};

const productPercentBlurHandler: any = (
  form: string,
  dispatch: Dispatch,
  event: React.FocusEvent<HTMLInputElement>
) => {
  if (!parseInt(event.currentTarget.value, 10)) {
    setImmediate(() => dispatch(change(form, event.target.name, "")));
  }
};

const isValidNow = (option: IInvestmentOption) =>
  (!option.product.dateStart ||
    moment(option.product.dateStart).isBefore(moment())) &&
  (!option.product.dateEnd || moment(option.product.dateEnd).isAfter(moment()));

class ChooseOptionsForm extends React.Component<any, IState> {
  public readonly state: Readonly<IState> = {
    isExpanded: [],
  };

  constructor(props: any) {
    super(props);

    this.clickIconDetail = this.clickIconDetail.bind(this);
    this.checkAndSubmit = this.checkAndSubmit.bind(this);
  }

  public componentDidMount() {
    this.props.optionsList();
  }

  public render() {
    const {
      activeInput,
      companyValue,
      dispatch,
      error,
      form,
      handleSubmit,
      optionsTotal,
      optionsValue,
      productValue,
      revisions,
      submitError,
      submitting,
      suitableOptions,
      availableOptions,
      cap,
    } = this.props;

    const hasOurIbips =
      (cap as ICap).data.hasIBIPs &&
      (cap as ICap).data.hasIBIPs!.IBIPs.filter(
        (ibip) => ibip.companyId !== "other"
      ).length > 0;

    const availableOptionsId = availableOptions
      ? (availableOptions as IInvestmentOption[])
          .filter(isValidNow)
          .map((option) => option.id)
      : [];

    const removedProductsId: string[] = [];
    const removedProducts: IInvestmentProduct[] = [];
    (suitableOptions as IInvestmentOption[]).forEach((option) => {
      if (!availableOptionsId.includes(option.id)) {
        if (!removedProductsId.includes(option.product.id)) {
          const productToBeRemoved = (availableOptions as IInvestmentOption[]).find(
            (availableOption) => availableOption.id === option.id
          )?.product;
          if (productToBeRemoved) {
            removedProductsId.push(productToBeRemoved.id);
            removedProducts.push(productToBeRemoved);
          }
        }
      }
    });

    const intersectedOptions = (suitableOptions as IInvestmentOption[]).filter(
      (option) => availableOptionsId.includes(option.id)
    );

    return (
      <Form onSubmit={handleSubmit(this.checkAndSubmit)}>
        <Field component="input" type="hidden" name="userId" />
        <>
          <div>
            <h6>Profilo cliente</h6>
            <IddResultSnippet idd={cap.idd.idd} />
          </div>
        </>
        {hasOurIbips && (
          <Alert color="warning">
            Il cliente ha già sottoscritto i seguenti prodotti di investimento
            assicurativi:
            <ul className="mb-0">
              {(cap as ICap).data
                .hasIBIPs!.IBIPs.filter((IBIP) => IBIP.companyId !== "other")
                .map((IBIP) => (
                  <li key={JSON.stringify(IBIP)}>
                    {IBIP.company!.name} -{" "}
                    {IBIP.product?.name ?? IBIP.productOther} -{" "}
                    {IBIP.intermediary}
                    {IBIP.uniquePremium && (
                      <>
                        {" "}
                        - <FormatNumber>{IBIP.uniquePremium}</FormatNumber> €
                        Premio unico
                      </>
                    )}
                    {IBIP.recurrentPremium && (
                      <>
                        {" "}
                        - <FormatNumber>{IBIP.recurrentPremium}</FormatNumber> €
                        Premio ricorrente
                      </>
                    )}
                    {IBIP.subscriptionYear && (
                      <> - Anno sottoscrizione: {IBIP.subscriptionYear}</>
                    )}
                    {IBIP.duration && (
                      <> - Durata sottoscrizione: {IBIP.duration} anni</>
                    )}
                  </li>
                ))}
            </ul>
          </Alert>
        )}
        {removedProducts.length > 0 && (
          <Alert
            className="mb-0"
            color={intersectedOptions.length > 0 ? "warning" : "danger"}
          >
            {intersectedOptions.length > 0 ? "Alcuni" : "Tutti i"} prodotti
            risultati validi durante la consulenza effettuata al cliente, non
            sono più disponibili:
            <ul className="mb-0">
              {removedProducts.map((product) => (
                <li key={product.id}>
                  {product.name} - Rimosso in data{" "}
                  {moment(product.dateEnd).format("LL[ alle ]LT")}
                </li>
              ))}
            </ul>
          </Alert>
        )}
        {intersectedOptions.length > 0 && (
          <Row className="mt-4">
            <Col>
              <FormGroup>
                <Label for="company">Compagnia</Label>
                <Field
                  id="company"
                  name="company"
                  component={RenderField}
                  onChange={companyChangeHandler.bind({}, form, dispatch)}
                  type="select"
                >
                  <option value="">Seleziona</option>
                  {(intersectedOptions as IInvestmentOption[])
                    .map((option) => option.product.company)
                    .filter(
                      (value, index, array) =>
                        array.findIndex(
                          (element) => element.name === value.name
                        ) === index
                    )
                    .map((option) => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                </Field>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="product">Prodotto</Label>
                <Field
                  id="product"
                  name="product"
                  component={RenderField}
                  onChange={productChangeHandler.bind(
                    {},
                    form,
                    dispatch,
                    intersectedOptions
                  )}
                  type="select"
                  disabled={!companyValue}
                >
                  <option value="">Seleziona</option>
                  {!!companyValue &&
                    (intersectedOptions as IInvestmentOption[])
                      .map((option) => option.product)
                      .filter(
                        (value) => value.company.id.toString() === companyValue
                      )
                      .filter(
                        (value, index, array) =>
                          array.findIndex(
                            (element) => element.name === value.name
                          ) === index
                      )
                      .map((option) => (
                        <option key={option.id} value={option.id}>
                          {option.name}
                        </option>
                      ))}
                </Field>
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="code">Numero proposta</Label>
                <Field
                  id="code"
                  name="code"
                  component={RenderField}
                  type="text"
                  disabled={!productValue}
                />
              </FormGroup>
            </Col>
          </Row>
        )}
        {!!productValue && (
          <>
            <Table>
              <thead>
                <tr>
                  <th>Opzioni di investimenti</th>
                  <th>Livello di rischio</th>
                  <th className="text-right" style={{width: "160px"}}>
                    %
                  </th>
                  <th style={{width: "26px"}} />
                </tr>
              </thead>
              <tbody>
                {(intersectedOptions as IInvestmentOption[])
                  .filter((value) => {
                    return value.product.id.toString() === productValue;
                  })
                  .map((option, index) => (
                    <React.Fragment key={option.id}>
                      <tr>
                        <td>
                          <Field
                            id={`${option.id}-checkbox`}
                            name={`options.${option.id}.value`}
                            component={RenderCustomCheckboxField}
                            type="checkbox"
                            label={option.name}
                            onChange={productCheckboxChangeHandler.bind(
                              {},
                              form,
                              dispatch
                            )}
                            noValidate
                          />
                        </td>
                        <td>{option.risk}</td>
                        <td>
                          <Field
                            name={`options.${option.id}.percent`}
                            component={RenderField}
                            appendAddOn="%"
                            type="text"
                            maxLength={3} // 100
                            normalize={naturalNormalizer}
                            onChange={productPercentChangeHandler.bind(
                              {},
                              form,
                              dispatch
                            )}
                            onBlur={productPercentBlurHandler.bind(
                              {},
                              form,
                              dispatch
                            )}
                            disabled={
                              activeInput !== `options.${option.id}.percent` &&
                              (!optionsValue[option.id] ||
                                !optionsValue[option.id].value)
                            }
                          />
                        </td>
                        <td>
                          {this.state.isExpanded[index] ? (
                            <IconInfoCircleSolid
                              title="Chiudi dettagli"
                              onClick={this.clickIconDetail.bind(this, index)}
                            />
                          ) : (
                            <IconInfoCircle
                              title="Dettagli"
                              onClick={this.clickIconDetail.bind(this, index)}
                            />
                          )}
                        </td>
                      </tr>
                      {this.state.isExpanded[index] && (
                        <tr>
                          <td colSpan={4}>
                            <OptionCriteriaTable
                              idd={cap.idd}
                              option={option}
                            />
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                <tr>
                  <td colSpan={3} className="text-right">
                    <span>
                      Totale:{" "}
                      <FormatNumber
                        maximumFractionDigits={0}
                        minimumFractionDigits={0}
                      >
                        {optionsTotal}
                      </FormatNumber>{" "}
                      %
                    </span>
                    {error && (
                      <>
                        <br />
                        <span className="invalid-feedback d-inline">
                          {error}
                        </span>
                      </>
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
            <div>
              <p>
                Si consiglia sempre di{" "}
                <strong>diversificare l'investimento</strong>, distribuendolo
                tra i diversi strumenti al fine di bilanciare il rischio e
                ottimizzare i profitti.
                <br />
                <strong>
                  Il sistema colloca equamente le risorse tra le opzioni
                  disponibili
                </strong>
                , lasciando al Consulente l'opportunità di{" "}
                <strong>personalizzare le percentuali</strong> di ogni opzione,
                perché si adattino al profilo e agli obiettivi del Cliente.
              </p>
            </div>
            <FormGroup>
              <Label for="comment">
                Motiva la scelta delle opzioni di investimento selezionate:
              </Label>
              <Field
                id={"comment"}
                name={"comment"}
                component={RenderField}
                type="textarea"
              />
            </FormGroup>
            {!!revisions && (
              <>
                <Label>Messaggi del Backoffice</Label>
                {(revisions as IRevision[])
                  .slice(0)
                  .reverse()
                  .map((revision) => (
                    <Alert key={JSON.stringify(revision)} color="warning">
                      <h6 className="alert-heading">
                        <em>{revision.date.format("LL[ alle ]LT")}</em>
                      </h6>
                      {revision.text}
                    </Alert>
                  ))}
              </>
            )}
            <Alert color="info">
              <IconInfoCircleSolid /> Salvando la raccomandazione
              personalizzata, verrà inviata una email al Cliente con la
              richiesta di apporre la firma digitale tramite OTP e
              successivamente diventerà visibile al Back Office.
              <br />
              Il pdf consultabile della raccomandazione sarà disponibile solo
              quando il Cliente l'avrà validato tramite OTP.
            </Alert>
            {submitError && <Alert color="danger">{submitError}</Alert>}
            <div className="text-center">
              <Button
                type="submit"
                color="primary"
                outline
                disabled={submitting}
              >
                {submitting ? <IconSpinner className="icon-spin" /> : ""} Salva
                la raccomandazione
              </Button>{" "}
              <Button
                color="secondary"
                outline
                tag={Link}
                to="/raccomandazioni"
                disabled={submitting}
              >
                Torna all'elenco raccomandazioni
              </Button>
            </div>
          </>
        )}
      </Form>
    );
  }

  private clickIconDetail(index: number) {
    this.setState((prevState) => {
      const newIsExpanded = prevState.isExpanded;
      newIsExpanded[index] = !prevState.isExpanded[index];

      return {
        isExpanded: newIsExpanded,
      };
    });
  }

  private checkAndSubmit(values: IChooseOptionsFormData) {
    if (
      !isIdValid((this.props.cap as ICap)?.onboarding?.identification, true)
    ) {
      const element = document.getElementById("cap-identification-details");
      animateScrollTo(element!, {verticalOffset: -16});
      return;
    }
    if (!(this.props.cap as ICap).contractor.lastPrivacySubscriptionEsignId) {
      const element = document.getElementById(
        "contractor-privacy-subscription"
      );
      animateScrollTo(element!, {verticalOffset: -16});
      return;
    }
    this.props.onSubmit(values);
  }
}

const ConnectedChooseOptionForm = reduxForm<
  IChooseOptionsFormData,
  any,
  {[Key: string]: {percent: string}} | string
>({
  onSubmitFail: focusFirstInvalid,
  validate,
})(ChooseOptionsForm);

export default connect(mapStateToProps)(ConnectedChooseOptionForm);
