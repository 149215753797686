import React from "react";
import {RouteComponentProps, withRouter} from "react-router-dom";

interface IRouteState {
  disableAutoScrollToTop?: boolean;
}

class ScrollToTop extends React.Component<
  RouteComponentProps<{}, {}, IRouteState | null>
> {
  public componentDidUpdate(
    prevProps: RouteComponentProps<{}, {}, IRouteState | null>
  ) {
    if (
      this.props.location !== prevProps.location &&
      !this.props.location.state?.disableAutoScrollToTop
    ) {
      window.scrollTo(0, 0);
    }
  }

  public render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
