import React from "react";
import {connect} from "react-redux";
import {Alert, Button, Col, Form, FormGroup, Label, Row} from "reactstrap";
import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import {focusFirstInvalid} from "../helpers/focusFirstInvalid";
import {floatFormatter} from "../helpers/formatters";
import homeTypes from "../helpers/homeTypes";
import {
  naturalNormalizer,
  positiveFloatNormalizer,
  ucWordsNormalizer,
} from "../helpers/normalizers";
import {floatParser} from "../helpers/parsers";
import RenderField from "../helpers/RenderField";
import RenderMultiField from "../helpers/RenderMultiField";
import IconSpinner from "../Icons/IconSpinner";
import {IRootState} from "../redux/reducers";
import {AutoSuggestCitiesReduxForm} from "./CompleteContractor/AutoSuggestCitiesReduxForm";
import {IStateUi} from "./reducers";
import {dehydrateCap, ICap, ICapEntity} from "./types";

export interface IHomeFormData {
  address: string;
  zipCode: string;
  city: string;
  cityCSiglaProvincia: string;
  cityIdObject: string;
  cityBExist: string;
  region: string;
  phone: string;
  tipologiaAbitazione: string;
  contratto: "proprieta" | "affitto" | "comodato" | "altro";
  mutuo: "si" | "no";
  rata: string;
  tasso: "fisso" | "variabile";
  importoAffitto: string;
  durata: string;
  rimanenti: string;
  note: string;
}

type Keys =
  | "address"
  | "zipCode"
  | "city"
  | "region"
  | "tipologiaAbitazione"
  | "contratto";
type IHomeFormErrors = {[K in Keys]?: string};

interface IProps extends InjectedFormProps<IHomeFormData, IProps> {
  contrattoValue?: "proprieta" | "affitto" | "comodato" | "altro";
  mutuoValue?: "si" | "no";
}

interface IOwnProps {
  cap: ICap;
  closeHome: () => void;
  homeSubmit: (values: IHomeFormData, cap: ICapEntity) => Promise<void>;
  ui: IStateUi;
}

const validate = (values: IHomeFormData) => {
  const errors: IHomeFormErrors = {};
  if (!values.address) {
    errors.address = "Questo campo è obbligatorio";
  }
  if (!values.zipCode) {
    errors.zipCode = "Questo campo è obbligatorio";
  }
  if (!values.city) {
    errors.city = "Questo campo è obbligatorio";
  } else if (!values.cityIdObject) {
    errors.city = "Scegli un comune dalla lista";
  }
  if (!values.region) {
    errors.region = "Questo campo è obbligatorio";
  }
  if (!values.tipologiaAbitazione) {
    errors.tipologiaAbitazione = "Questo campo è obbligatorio";
  }
  if (!values.contratto) {
    errors.contratto = "Questo campo è obbligatorio";
  }
  return errors;
};

const HomeForm: React.FC<IProps & IOwnProps> = (props) => {
  const submitWithCap = async (values: IHomeFormData) => {
    const {
      result,
      entities: {caps},
    } = dehydrateCap(props.cap);
    await props.homeSubmit(values, caps[result]);
  };
  return (
    <Form onSubmit={props.handleSubmit(submitWithCap)}>
      <FormGroup>
        <Label for="address">Indirizzo</Label>
        <Field
          id="address"
          name="address"
          component={RenderField}
          type="text"
          placeholder="Indirizzo"
          normalize={ucWordsNormalizer}
        />
      </FormGroup>
      <FormGroup>
        <Label for="zipCode">Cap</Label>
        <Field
          id="zipCode"
          name="zipCode"
          component={RenderField}
          type="text"
          placeholder="Cap"
        />
      </FormGroup>
      <FormGroup>
        <Label for="city">Comune e provincia</Label>
        <Row>
          <Col>
            <Field
              id="city"
              name="city"
              placeholder="Comune e provincia"
              strict
              component={AutoSuggestCitiesReduxForm}
              normalize={ucWordsNormalizer}
            />
          </Col>
          <Col xs="auto" style={{width: "90px"}}>
            <Field
              name="cityCSiglaProvincia"
              readOnly
              component={RenderField}
              noValidate
            />
          </Col>
        </Row>
      </FormGroup>
      <FormGroup>
        <Label for="phone">Telefono fisso</Label>
        <Field
          id="phone"
          name="phone"
          type="text"
          component={RenderField}
          normalize={naturalNormalizer}
          placeholder="Telefono fisso"
        />
      </FormGroup>
      <FormGroup>
        <Label for="tipologiaAbitazione">Tipologia abitazione</Label>
        <Field
          id="tipologiaAbitazione"
          name="tipologiaAbitazione"
          type="select"
          component={RenderField}
        >
          <option value="">Seleziona</option>
          {homeTypes.map((homeType: {label: string; value: string}) => (
            <option key={homeType.value} value={homeType.value}>
              {homeType.label}
            </option>
          ))}
        </Field>
      </FormGroup>
      <Label>Contratto</Label>
      <RenderMultiField
        id="contratto"
        name="contratto"
        type="radio"
        items={[
          {value: "proprieta", text: "Proprietà"},
          {value: "affitto", text: "Affitto"},
          {value: "comodato", text: "Comodato gratuito"},
          {value: "altro", text: "Altro"},
        ]}
      />
      {props.contrattoValue === "proprieta" ? (
        <div>
          <Label>Mutuo</Label>
          <RenderMultiField
            id="mutuo"
            name="mutuo"
            type="radio"
            items={[
              {value: "si", text: "Sì"},
              {value: "no", text: "No"},
            ]}
          />

          {props.mutuoValue === "si" ? (
            <div>
              <FormGroup>
                <Label for="rata">Rata mensile</Label>
                <Field
                  id="rata"
                  name="rata"
                  type="text"
                  parse={floatParser}
                  format={floatFormatter}
                  normalize={positiveFloatNormalizer}
                  component={RenderField}
                  placeholder="Rata mensile"
                />
              </FormGroup>
              <Label>Tasso</Label>
              <RenderMultiField
                id="tasso"
                name="tasso"
                type="radio"
                items={[
                  {value: "fisso", text: "Fisso"},
                  {value: "variabile", text: "Variabile"},
                ]}
              />
              <FormGroup>
                <Label for="durata">Durata (anni)</Label>
                <Field
                  id="durata"
                  name="durata"
                  type="text"
                  component={RenderField}
                  normalize={naturalNormalizer}
                  placeholder="Durata in anni"
                />
              </FormGroup>
              <FormGroup>
                <Label for="rimanenti">Anni rimanenti</Label>
                <Field
                  id="rimanenti"
                  name="rimanenti"
                  type="text"
                  component={RenderField}
                  normalize={naturalNormalizer}
                  placeholder="Anni rimanenti"
                />
              </FormGroup>
            </div>
          ) : null}
        </div>
      ) : null}
      {props.contrattoValue === "affitto" ? (
        <div>
          <FormGroup>
            <Label>Importo mensile</Label>
            <Field
              id="importoAffitto"
              name="importoAffitto"
              type="text"
              component={RenderField}
              parse={floatParser}
              format={floatFormatter}
              normalize={positiveFloatNormalizer}
              placeholder="Importo mensile"
            />
          </FormGroup>
        </div>
      ) : null}
      <FormGroup>
        <Label for="note">
          Note (m2, stato immobile, certificazioni impianti)
        </Label>
        <Field type="textarea" name="note" component={RenderField} id="note" />
      </FormGroup>
      {props.ui.isHomeFail && (
        <Alert color="danger">{props.ui.homeError}</Alert>
      )}
      <Button type="submit" color="primary" outline disabled={props.submitting}>
        {props.submitting ? <IconSpinner className="icon-spin" /> : ""} Salva e
        torna all'analisi
      </Button>{" "}
      <Button type="button" color="secondary" outline onClick={props.closeHome}>
        Annulla
      </Button>
    </Form>
  );
};

const HomeFormConnected = reduxForm({
  form: "homeForm",
  onSubmitFail: focusFirstInvalid,
  validate,
})(HomeForm);

const selector = formValueSelector("homeForm");
export default connect((state: IRootState, ownProps: IOwnProps) => {
  const contrattoValue = selector(state, "contratto");
  const mutuoValue = selector(state, "mutuo");
  const initialValues = ownProps.cap.data.home;

  return {
    contrattoValue,
    initialValues,
    mutuoValue,
  };
})(HomeFormConnected);
