import isEmpty from "lodash/isEmpty";
import moment from "moment";
import React, {VFC} from "react";
import {FieldErrors, useFormContext} from "react-hook-form";
import InputMask from "react-input-mask";
import {Col, FormFeedback, FormGroup, Input, Label, Row} from "reactstrap";
import {
  emailNormalizer,
  fiscalCodeNormalize,
  naturalNormalizer,
} from "../../../../helpers/normalizers";
import {RhfInput} from "../../../../helpers/RHFInput";
import {RhfRadio} from "../../../../helpers/RHFRadio";
import {dateIt, email, fiscalCode} from "../../../../helpers/validators";
import {RHFAutocompleteCitiesController} from "../../../../shared/RHFAutocompleteCitiesController";
import {INewBeneProposalFormData} from "../../NewBeneProposalForm";

interface IIndividualProps {
  disabled?: boolean;
}

export interface IIndividualFormData {
  address: string;
  birthCity: string;
  birthCityBExist: string;
  birthCityIdObject: string;
  birthCityMore: string;
  birthdate: string;
  birthNation: string;
  birthRegion: string;
  cap: string;
  city: string;
  companyName: string;
  fiscalCode: string;
  gender: "m" | "f";
  name: string;
  number: string;
  pec: string;
  region: string;
  surname: string;
  vatNumber: string;
}

export const validation = (values: IIndividualFormData) => {
  const errors: FieldErrors<IIndividualFormData> = {};
  const cleanBirthDate = values.birthdate?.replace(/_/g, "");

  // Dati societari
  if (!values.companyName) {
    errors.companyName = {
      type: "required",
      message: "Inserisci la ragione sociale",
    };
  }
  if (!values.vatNumber) {
    errors.vatNumber = {
      type: "required",
      message: "Inserisci la partita iva",
    };
  } else if (values.vatNumber.length !== 11) {
    errors.vatNumber = {
      type: "pattern",
      message: "La partita iva non è nel formato corretto",
    };
  }
  if (!values.fiscalCode) {
    errors.fiscalCode = {
      type: "required",
      message: "Inserisci il codice fiscale",
    };
  } else if (!fiscalCode(values.fiscalCode)) {
    errors.fiscalCode = {
      type: "pattern",
      message: "Il codice fiscale non è nel formato corretto",
    };
  }
  if (!values.pec) {
    errors.pec = {
      type: "required",
      message: "Inserisci la PEC",
    };
  } else if (!email(values.pec)) {
    errors.pec = {
      type: "pattern",
      message: "La PEC non è nel formato corretto",
    };
  }
  // Dati personali
  if (!values.surname) {
    errors.surname = {
      type: "required",
      message: "Inserisci il cognome",
    };
  }
  if (!values.name) {
    errors.name = {
      type: "required",
      message: "Inserisci il nome",
    };
  }
  if (!values.gender) {
    errors.gender = {
      type: "required",
      message: "Inserisci il Genere",
    };
  }
  if (!values.birthdate) {
    errors.birthdate = {
      type: "required",
      message: "Inserisci la data di nascita",
    };
  } else if (
    !dateIt(cleanBirthDate as any) ||
    !moment(cleanBirthDate, "DD/MM/YYYY").isValid()
  ) {
    errors.birthdate = {
      type: "invalid",
      message: "La data di nascita non è nel formato corretto gg/mm/aaaa",
    };
  } else if (!moment(cleanBirthDate, "DD/MM/YYYY").isBefore(moment())) {
    errors.birthdate = {
      type: "invalid",
      message: "La data di nascita dev'essere antecedente a oggi",
    };
  }
  if (!values.birthCity) {
    errors.birthCity = {
      type: "required",
      message: "Inserisci il comune di nascita",
    };
  } else if (!values.birthCityIdObject) {
    errors.birthCity = {
      type: "notInList",
      message: "Scegli un comune dalla lista",
    };
  } else if (values.birthCityIdObject === "0" && !values.birthCityMore) {
    errors.birthCityMore = {
      type: "required",
      message: "inserisci il nome del comune di nascita estero",
    };
  }
  if (!values.birthRegion) {
    errors.birthRegion = {
      type: "required",
      message: "Inserisci la provincia di nascita",
    };
  }
  if (!values.birthNation) {
    errors.birthNation = {
      type: "required",
      message: "Inserisci la nazione di nascita",
    };
  }

  // Sede legale
  if (!values.address) {
    errors.address = {
      type: "required",
      message: "Inserisci l'indirizzo della sede legale",
    };
  }
  if (!values.number) {
    errors.number = {
      type: "required",
      message: "Inserisci il numero dell'indirizzo della sede legale",
    };
  }
  if (!values.cap) {
    errors.cap = {
      type: "required",
      message: "Inserisci il cap della sede legale",
    };
  }
  if (!values.city) {
    errors.city = {
      type: "required",
      message: "Inserisci la città della sede legale",
    };
  }
  if (!values.region) {
    errors.city = {
      type: "required",
      message: "Inserisci la città della sede legale",
    };
  }

  return {values: isEmpty(errors) ? values : {}, errors};
};

export const Individual: VFC<IIndividualProps> = ({disabled}) => {
  const {
    control,
    errors,
    formState,
    register,
    setValue,
    watch,
  } = useFormContext<INewBeneProposalFormData>();

  const birthCityValue = watch("rc.birthCity") as string;

  return (
    <>
      <h5>Dati societari</h5>
      <RhfInput
        errors={errors}
        formState={formState}
        name="rc.companyName"
        register={register}
        label="Ragione sociale"
      />
      <RhfInput
        errors={errors}
        formState={formState}
        label="Partita IVA"
        name="rc.vatNumber"
        onChange={(e) => {
          setValue("rc.vatNumber", naturalNormalizer(e.target.value));
        }}
        register={register}
      />
      <RhfInput
        errors={errors}
        formState={formState}
        label="Codice fiscale"
        name="rc.fiscalCode"
        onChange={(e) => {
          setValue("rc.fiscalCode", fiscalCodeNormalize(e.target.value));
        }}
        register={register}
      />
      <RhfInput
        errors={errors}
        formState={formState}
        label="PEC"
        name="rc.pec"
        onChange={(e) => {
          setValue("rc.pec", emailNormalizer(e.target.value));
        }}
        register={register}
      />
      <hr className="border-primary" />
      <h5>Dati personali</h5>
      <RhfInput
        errors={errors}
        formState={formState}
        name="rc.surname"
        register={register}
        label="Cognome"
        readOnly
      />
      <RhfInput
        errors={errors}
        formState={formState}
        name="rc.name"
        register={register}
        label="Nome"
        readOnly
      />
      <RhfRadio
        errors={errors}
        formState={formState}
        name="rc.gender"
        register={register}
        label="Genere"
        disabled
      >
        <option value="m">Maschio</option>
        <option value="f">Femmina</option>
      </RhfRadio>
      <FormGroup>
        <Label for="rc.birthdate">Data di nascita</Label>
        <Input
          tag={InputMask}
          mask="99/99/9999"
          type="text"
          id="rc.birthdate"
          name="rc.birthdate"
          placeholder="Data di nascita (gg/mm/aaaa)"
          innerRef={register}
          invalid={!!errors?.rc?.birthdate}
          valid={formState.touched.rc?.birthdate && !errors?.rc?.birthdate}
          readOnly
        />
        {errors?.rc?.birthdate && (
          <FormFeedback>{errors.rc?.birthdate.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup className="mb-0">
        <Label for="rc.birthCity">Comune di nascita</Label>
        <Row>
          <Col>
            <RHFAutocompleteCitiesController
              control={control}
              disabled
              errors={errors}
              formState={formState}
              id="rc.birthCity"
              name="rc.birthCity"
              onSelect={(select) => {
                if (select[0]?.cSiglaProvincia === "EE") {
                  setValue("rc.birthNation", "");
                } else {
                  setValue("rc.birthNation", "Italia");
                }
              }}
              placeholder="Comune di nascita"
              regionName="rc.birthRegion"
              setValue={setValue}
            />
          </Col>
          <Col xs="auto" style={{width: "90px"}}>
            <RhfInput name="rc.birthRegion" register={register} readOnly />
          </Col>
        </Row>
      </FormGroup>
      {birthCityValue.toLowerCase() === "estero" && (
        <RhfInput
          errors={errors}
          formState={formState}
          name="rc.birthCityMore"
          register={register}
          label={`inserisci il nome del comune di nascita estero`}
          readOnly
        />
      )}
      <RhfInput
        errors={errors}
        formState={formState}
        name="rc.birthNation"
        register={register}
        label="Nazione di nascita"
        readOnly={birthCityValue.toLowerCase() !== "estero"}
      />
      <hr className="border-primary" />
      <h5>Sede legale</h5>
      <FormGroup className="mb-0">
        <Row>
          <Col>
            <RhfInput
              errors={errors}
              formState={formState}
              name="rc.address"
              register={register}
              label="Indirizzo"
            />
          </Col>
          <Col xs="auto" style={{width: "150px"}}>
            <RhfInput
              errors={errors}
              formState={formState}
              name="rc.number"
              register={register}
              label="Numero"
            />
          </Col>
        </Row>
      </FormGroup>
      <RhfInput
        errors={errors}
        formState={formState}
        name="rc.cap"
        register={register}
        label="CAP"
      />
      <FormGroup className="mb-0">
        <Label for="city">Città</Label>
        <Row>
          <Col>
            <RHFAutocompleteCitiesController
              control={control}
              errors={errors}
              formState={formState}
              id="city"
              name="rc.city"
              placeholder="Città"
              regionName="rc.region"
              setValue={setValue}
              strict
            />
          </Col>
          <Col xs="auto" style={{width: "90px"}}>
            <RhfInput name="rc.region" register={register} readOnly />
          </Col>
        </Row>
      </FormGroup>
    </>
  );
};
