import React from "react";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import {IconSavings, IconTimes} from "../Icons";
import {ICap, ICapEntity, IStateUi, NewCapModalTypes} from "./";
import SavingForm, {ISavingFormData} from "./SavingForm";

interface IProps {
  closeSaving: () => void;
  ui: IStateUi;

  cap: ICap;
  savingSubmit: (values: ISavingFormData, cap: ICapEntity) => Promise<void>;
}

const SavingModal: React.FC<IProps> = (props) => (
  <Modal
    isOpen={props.ui.isModalOpen[NewCapModalTypes.Saving]}
    toggle={props.closeSaving}
    backdrop="static"
    keyboard={false}
    className="modal-form"
  >
    <ModalHeader
      toggle={props.closeSaving}
      close={
        <Button size="sm" className="close-modal" onClick={props.closeSaving}>
          <IconTimes size={1.25} className="icon-border-2 rounded-circle p-1" />
        </Button>
      }
    >
      <IconSavings size={3} className="icon-border-2 rounded-circle p-2" />
      Capacità di risparmio
    </ModalHeader>
    <ModalBody>
      <SavingForm {...props} />
    </ModalBody>
  </Modal>
);

export default SavingModal;
