import classNames from "classnames";
import React from "react";
import Dropzone, {DropEvent, FileRejection} from "react-dropzone";

interface IProps {
  onDrop: (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => void;
}

const WaitingFile: React.FC<IProps> = (props) => (
  <Dropzone onDrop={props.onDrop} multiple={false}>
    {({
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
    }) => (
      <div
        {...getRootProps()}
        className={classNames("dropzone-area", {
          "dropzone-area--isAccepted": isDragAccept,
          "dropzone-area--isActive": isDragActive,
          "dropzone-area--isRejected": isDragReject,
        })}
      >
        <input {...getInputProps()} />
        <p>
          Trascina il file qui, oppure clicca per cercare il file sul tuo
          computer
        </p>
      </div>
    )}
  </Dropzone>
);

export default WaitingFile;
