import React from "react";
import {Modal, ModalBody, ModalHeader, Table} from "reactstrap";
import {IBeneLevel} from "../NewBeneProposalForm";

interface IProps {
  guaranteeModalLevel?: IBeneLevel;
  toggle: () => void;
}

const guarantees = {
  silver: {
    title: "Morte, IP, Pacchetto Serenità – forma 24H",
    rows: 3,
    premium: 10,
  },
  gold: {
    title:
      "Morte, IP, Pacchetto Serenità + RSC, Assistenza, Tutela Legale – forma 24H",
    rows: 6,
    premium: 15,
  },
  platinum: {
    title:
      "Morte, IP, Pacchetto Serenità + RSC, Assistenza, Tutela Legale + Diarie – forma 24H",
    rows: 8,
    premium: 25,
  },
};

export const GuaranteeModal = ({guaranteeModalLevel, toggle}: IProps) => {
  const guarantee = guaranteeModalLevel && guarantees[guaranteeModalLevel];
  return (
    <Modal
      isOpen={guaranteeModalLevel !== undefined}
      size="xl"
      toggle={toggle}
      centered
    >
      {guarantee && (
        <>
          <ModalHeader toggle={toggle}>{guarantee.title}</ModalHeader>
          <ModalBody>
            <Table bordered className="text-primary">
              <thead>
                <tr>
                  <th>Garanzie</th>
                  <th>Massimale</th>
                  <th>Premio lordo mensile</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong>Morte</strong> da Infortunio
                  </td>
                  <td className="align-middle text-right">50.000 €</td>
                  <td
                    rowSpan={guarantee.rows}
                    className="align-middle text-right"
                  >
                    {guarantee.premium} €
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Invalidità Permanente</strong> da Infortunio -
                    franchigia 3% relativa 10% con supervalutazione (riassorbita
                    al 15%, da 50% liquida il doppio fino a 200%)
                  </td>
                  <td className="align-middle text-right">50.000 €</td>
                </tr>
                <tr>
                  <td>
                    <strong>Pacchetto Serenità</strong> (IP con liquidazione
                    maggiorata se si tratta di minore, o per
                    rapina-estorsione-sequestro, o per adeguamento
                    abitazione/autovettura, in caso di IP gravi)
                  </td>
                  <td className="align-middle text-right"></td>
                </tr>
                {(guaranteeModalLevel === "gold" ||
                  guaranteeModalLevel === "platinum") && (
                  <>
                    <tr>
                      <td>
                        <strong>Rimborso Spese di Cura</strong> da Infortunio
                      </td>
                      <td className="align-middle text-right">5.000 €</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Assistenza</strong>
                      </td>
                      <td className="align-middle text-right"></td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Tutela Legale</strong>
                      </td>
                      <td className="align-middle text-right">10.000 €</td>
                    </tr>
                  </>
                )}
                {guaranteeModalLevel === "platinum" && (
                  <>
                    <tr>
                      <td>
                        <strong>Diaria da Ricovero</strong> (365 gg,
                        Convalescenza 60 gg) da Infortunio
                      </td>
                      <td className="align-middle text-right">
                        50 € al giorno
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Diaria da Ricovero</strong> (365 gg,
                        Convalescenza 20 gg) da Malattia
                      </td>
                      <td className="align-middle text-right">
                        50 € al giorno
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          </ModalBody>
        </>
      )}
    </Modal>
  );
};
