import React from "react";
import {Link} from "react-router-dom";
import {Button, Col, Container, Row} from "reactstrap";

import {Layout} from ".";
import {IconBack} from "../Icons";

const NotFoundPage = () => (
  <Layout isProtected>
    <Container className="main-center">
      <img
        src="/img/logo.svg"
        className="logo mx-auto mb-4 d-block"
        alt={`${process.env.REACT_APP_TITLE} logo`}
      />
      <Row>
        <Col className="text-center">
          <h3 className="mb-4">Pagina non trovata ☹</h3>
          <p>
            <Link to="/">
              <Button color="light" outline>
                <IconBack /> Torna alla pagina principale
              </Button>
            </Link>
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default NotFoundPage;
