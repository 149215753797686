import animateScrollTo from "animated-scroll-to";
import React from "react";
import {Link, Redirect} from "react-router-dom";
import {Button} from "reactstrap";
import {debounce} from "throttle-debounce";
import {actions} from "../";
import {Layout, PageTitle} from "../../App";
import {
  dehydrateCap,
  ICap,
  ICapEntity,
  IInvestmentOption,
  NewCapModalTypes,
} from "../../Caps";
import {Actions as CapActions} from "../../Caps/actions";
import CloseCap from "../../Caps/CloseCap";
import Delivery from "../../Caps/Delivery";
import {IUploadIdFormData} from "../../Caps/UploadIdForm";
import IApiError from "../../types/IApiError";
import {IUser, IUserEntity} from "../../Users";
import ChooseOptions from "./ChooseOptions";
import {IChooseOptionsFormData} from "./ChooseOptionsForm";

import "./Page.scss";
import SearchContractor, {IResultItem} from "./SearchContractor";

interface IStateProps {
  availableOptions?: IInvestmentOption[];
  canSeeCap: boolean;
  chosenCap?: ICap;
  denormalizedCaps: ICap[];
  isBackOffice?: boolean;
  isChosenCapFromCap?: boolean;
  isCreateSuccess?: boolean;
  isListPending?: boolean;
  isUploadIdFail?: boolean;
  lastCreatedId?: string;
  optionsList: () => Promise<void>;
  submitError?: string;
  uploadIdError?: string;
  user: IUser | null;
}
interface IDispatchProps {
  chooseCap: (cap: string | null) => actions;
  chooseCapFromCap: (isFromCap: boolean) => actions;
  closeModal: (modalType: NewCapModalTypes) => CapActions;
  confirmIdentificationSubmit: (
    cap: ICapEntity,
    user: IUserEntity
  ) => Promise<void>;
  createRecommendation: (
    values: IChooseOptionsFormData,
    cap: ICap
  ) => Promise<void>;
  openModal: (modalType: NewCapModalTypes) => CapActions;
  searchCaps: (userFC?: string, value?: string) => Promise<void>;
  uploadIdFail: (error: IApiError) => void;
  uploadIdSubmit: (
    userId: string,
    cap: ICapEntity,
    values: IUploadIdFormData
  ) => Promise<void>;
}
type IProps = IDispatchProps & IStateProps;

interface IState {
  searchValue: string;
}

class Page extends React.PureComponent<IProps, IState> {
  public readonly state: Readonly<IState> = {
    searchValue: "",
  };

  private searchCaps = debounce(300, this.props.searchCaps.bind(this));

  constructor(props: IProps) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleChooseOptionSubmit = this.handleChooseOptionSubmit.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  public componentDidMount() {
    this.searchCaps(this.props.user?.fiscalCode);
  }

  public componentWillUnmount() {
    this.props.chooseCap(null);
  }

  public render() {
    const {
      availableOptions,
      canSeeCap,
      chosenCap,
      closeModal,
      denormalizedCaps,
      isBackOffice,
      isCreateSuccess,
      isListPending,
      isUploadIdFail,
      lastCreatedId,
      openModal,
      optionsList,
      submitError,
      uploadIdError,
      uploadIdFail,
      uploadIdSubmit,
      user,
    } = this.props;
    return (
      <Layout isProtected>
        {isCreateSuccess && (
          <Redirect to={`/raccomandazioni/${lastCreatedId}`} />
        )}
        <PageTitle>
          Nuova raccomandazione
          <Button
            tag={Link}
            color="light"
            outline
            to="/raccomandazioni"
            className="ml-auto"
          >
            Torna all'elenco
          </Button>
        </PageTitle>
        {!chosenCap || !chosenCap.idd ? (
          <SearchContractor
            denormalizedCaps={denormalizedCaps}
            handleChange={this.handleChange}
            handleSelect={this.handleSelect}
            isListPending={!!isListPending}
            value={this.state.searchValue}
          />
        ) : (
          <>
            <ChooseOptions
              availableOptions={availableOptions}
              canSeeCap={canSeeCap}
              chosenCap={chosenCap}
              closeModal={closeModal}
              isAdvisor
              isUploadIdFail={isUploadIdFail}
              onSubmit={this.handleChooseOptionSubmit}
              openModal={openModal}
              optionsList={optionsList}
              submitError={submitError}
              uploadIdError={uploadIdError}
              uploadIdFail={uploadIdFail}
              uploadIdSubmit={uploadIdSubmit}
              userId={user!.id}
            />
            {isBackOffice && (
              <>
                <Delivery cap={chosenCap} />
                <CloseCap cap={chosenCap} id="close-cap" />
              </>
            )}
          </>
        )}
      </Layout>
    );
  }

  private getContractorEntity = () => {
    if (!this.props.chosenCap) {
      return this.props.chosenCap;
    }

    const {
      result,
      entities: {caps, users},
    } = dehydrateCap(this.props.chosenCap);
    const itemEntity = caps[result];
    return users[itemEntity.contractor];
  };

  private getItemEntity = () => {
    if (!this.props.chosenCap) {
      return this.props.chosenCap;
    }

    const {
      result,
      entities: {caps},
    } = dehydrateCap(this.props.chosenCap);
    return caps[result];
  };

  private handleChooseOptionSubmit(values: IChooseOptionsFormData) {
    return this.props
      .createRecommendation(values, this.props.chosenCap!)
      .then(() => {
        animateScrollTo(0);
      });
  }

  private handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({searchValue: event.target.value});
    this.searchCaps(this.props.user?.fiscalCode, event.target.value);
  }

  private handleSelect(value: string, item: IResultItem) {
    this.setState({searchValue: value});
    this.props.chooseCap(item.cap!.id.toString());
  }
}

export default Page;
