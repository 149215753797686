import moment from "moment";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Button} from "reactstrap";
import {
  IconCheckmark,
  IconClock,
  IconDanger,
  IconDownload,
  IconESign,
  IconSendNotification,
  IconSpinner,
} from "../Icons";
import {IRootState} from "../redux/reducers";
import {IIdentificationSnapshot, IUser} from "../Users";
import {actions} from "./actions";
import IdDetails from "./IdDetails";
import {ICap} from "./types";

interface IProps {
  cap: ICap;
  contractor: IUser;
  details?: IIdentificationSnapshot;
  isContractor?: boolean;
  isAdvisor?: boolean;
  identificationSubmit?: () => Promise<void>;
  isIdentificationPending?: boolean;
  identificationError?: string;
  openRequestOTPIdentificationModal?: (capId: string) => void;
}

const IdentificationDetails: React.FC<IProps> = ({
  cap,
  contractor,
  details,
  identificationSubmit,
  isAdvisor = false,
  isContractor = false,
  isIdentificationPending,
  identificationError,
  openRequestOTPIdentificationModal,
}) => {
  const dispatch = useDispatch();
  const identificationNotification = () => {
    dispatch(actions.identificationNotification(cap!.id));
  };

  const openRequestOTPIdentificationModalWithId = () => {
    openRequestOTPIdentificationModal?.(cap.id);
  };

  const isNotificationPending = useSelector(
    (state: IRootState) => state.caps.ui.isIdentificationPending
  );
  const isNotificationSuccess = useSelector(
    (state: IRootState) => state.caps.ui.isIdentificationSuccess
  );

  if (details) {
    // Se ho i dettagli li stampiamo e controlliamo lo stato della firma
    const expired = moment(details.expirationDate, "DD-MM-YYYY").isBefore(
      moment()
    );
    const hasFaceId = !!details.fileIdFaceName;
    const hasFCId = !!details.fileIdFCName;

    return (
      <>
        <IdDetails details={details} contractorId={contractor.id} />
        <div className="text-center">
          {(details.esignId && details.contractorEsignId) ||
          (details.esignId &&
            !hasFaceId &&
            !hasFCId &&
            !!cap.dateResponseValidation) ? (
            // è valida oppure è valida con due foto e chiusa
            <Button
              color="primary"
              outline
              download
              href={`${process.env.REACT_APP_API_BASE_URL}caps/${cap.id}/get-pdf-identificate`}
            >
              <IconDownload /> Scarica il pdf dell'identificazione
            </Button>
          ) : details.esignId &&
            (!hasFaceId || !hasFCId) &&
            !cap.dateResponseValidation ? (
            // è valida con 2 foto ma non chiusa
            // Siamo nel caso che l'id è firmata ma è vecchia e quindi non valida
            <Alert color="danger" className="mb-0 mt-3">
              L'identificazione del cliente deve essere ripetuta perché, ai fini
              dell'antiriciclaggioasdadasdas, è necessario aggiungere al
              documento d'identità la fotografia del viso del cliente affiancato
              al documento stesso.
            </Alert>
          ) : openRequestOTPIdentificationModal ? (
            // Non è valida e abbiamo l'upload, quindi mettiamo i bottoni per advisor o contractor
            isAdvisor ? (
              <>
                <Button
                  color="primary"
                  outline
                  disabled={
                    !!details.esignId || expired || !hasFaceId || !hasFCId
                  }
                  onClick={openRequestOTPIdentificationModalWithId}
                  className="mr-2"
                >
                  {details.esignId ? (
                    <>
                      <IconCheckmark /> Hai firmato il pdf dell'identificazione
                    </>
                  ) : (
                    <>
                      <IconESign /> Firma il pdf dell'identificazione
                    </>
                  )}
                </Button>
                {hasFaceId &&
                  hasFCId &&
                  (isContractor ? (
                    <Button
                      color="primary"
                      outline
                      disabled={
                        !details.esignId || expired || !hasFaceId || !hasFCId
                      }
                      onClick={openRequestOTPIdentificationModalWithId}
                    >
                      <IconESign /> firma come cliente
                    </Button>
                  ) : (
                    <>
                      <Button color="primary" outline disabled className="mr-2">
                        <IconClock /> In attesa della firma del cliente
                      </Button>
                      {isAdvisor && (
                        <Button
                          color="primary"
                          outline
                          onClick={identificationNotification}
                        >
                          {isNotificationPending ? (
                            <IconSpinner className="icon-spin" />
                          ) : isNotificationSuccess ? (
                            <IconCheckmark color="success" />
                          ) : (
                            <IconSendNotification />
                          )}{" "}
                          Invia notifica email
                        </Button>
                      )}
                    </>
                  ))}
              </>
            ) : isContractor ? (
              <>
                <Button color="primary" outline disabled>
                  {details.esignId ? (
                    <>
                      <IconCheckmark /> Firmato dall'Advisor
                    </>
                  ) : (
                    <>
                      <IconClock /> In attesa della firma dell'Advisor
                    </>
                  )}
                </Button>{" "}
                {hasFaceId && hasFCId && (
                  <Button
                    color="primary"
                    outline
                    disabled={
                      !details.esignId || expired || !hasFaceId || !hasFCId
                    }
                    onClick={openRequestOTPIdentificationModalWithId}
                  >
                    <IconESign /> Firma il pdf dell'identificazione
                  </Button>
                )}
              </>
            ) : null
          ) : isAdvisor ? (
            <Alert color="warning" className="mb-0">
              Non hai ancora firmato il pdf di questo documento
            </Alert>
          ) : isContractor ? (
            <Alert color="info" className="mb-0">
              Questo è l'ultimo documento che hai inserito. Siamo in attesa che
              il tuo Advisor firmi il pdf dell'identificazione.
            </Alert>
          ) : (
            <Alert color="info" className="mb-0">
              È stato inserito questo documento. Siamo in attesa che l'Advisor
              firmi il pdf dell'identificazione.
            </Alert>
          )}
        </div>
      </>
    );
  }

  if (contractor.identification) {
    // Se non ho i dettagli controlliamo se il contractor ha già dei documenti nel suo archivio
    const lastId = contractor.identification[0];
    const expired = moment(lastId.expirationDate, "DD-MM-YYYY").isBefore(
      moment()
    );
    const hasFaceId = !!lastId.fileIdFaceName;
    const hasFCId = !!lastId.fileIdFCName;

    if (isAdvisor) {
      // All'Advisor mostriamo il documento inserito e il bottone per confermare
      return (
        <div className="auto-margin-3">
          {hasFaceId && hasFCId ? (
            expired ? (
              <Alert color="danger">
                L'ultimo documento disponibile per il cliente è scaduto. Potrai
                confermare la sua identità solo dopo che sarà stato caricato un
                nuovo documento valido.
              </Alert>
            ) : (
              <Alert color="info">
                Questo è l'ultimo documento disponibile per il cliente. Conferma
                la sua identità cliccando sul relativo bottone.
              </Alert>
            )
          ) : (
            <Alert color="danger">
              L'ultimo documento disponibile per il cliente non è valido: manca
              una fotografia obbligatoria. Potrai confermare la sua identità
              solo dopo che sarà stato caricato un nuovo documento valido.
            </Alert>
          )}
          <IdDetails details={lastId} contractorId={contractor.id} />
          <div className="text-center">
            <Button
              color="success"
              outline
              onClick={identificationSubmit}
              disabled={expired || !hasFaceId || !hasFCId}
            >
              {isIdentificationPending && (
                <IconSpinner className="icon-spin mr-3" />
              )}
              Conferma e firma identificazione
            </Button>
            {identificationError && (
              <Alert color="danger" className="mt-3 mb-0">
                {identificationError}
              </Alert>
            )}
          </div>
        </div>
      );
    } else if (isContractor) {
      // Al contractor mostriamo il suo ultimo inserimento e mostriamo avviso se scaduto
      return (
        <div className="auto-margin-3">
          {expired ? (
            <Alert color="danger">
              L'ultimo documento che hai inserito è scaduto. Carica una copia
              fronte e retro di un documento di identità valido al tuo Advisor
              di riferimento cliccando sul relativo bottone
            </Alert>
          ) : (
            <Alert color="info">
              Questo è l'ultimo documento che hai inserito. Siamo in attesa che
              il tuo Advisor confermi l'identificazione.
            </Alert>
          )}
          <IdDetails details={lastId} contractorId={contractor.id} />
        </div>
      );
    } else {
      // Se non è né contraente né advisor mostro solo un alert
      return (
        <div className="auto-margin-3">
          <Alert color="info">
            È stato inserito questo documento. Siamo in attesa che l'Advisor
            confermi l'identificazione.
          </Alert>
          <IdDetails details={lastId} contractorId={contractor.id} />
        </div>
      );
    }
  }

  if (isContractor) {
    // Se non ho i dettagli e sono il contraente mostriamo l'avviso info di caricamento
    return (
      <div className="auto-margin-3">
        <Alert color="info">
          Per procedere con la consulenza, carica una copia fronte e retro del
          tuo documento di identità al tuo Advisor di riferimento cliccando sul
          relativo bottone
        </Alert>
      </div>
    );
  }

  // Se non ho i dettagli e non sono il contraente mostriamo l'avviso danger che siamo in attesa
  return (
    <div className="auto-margin-3">
      <Alert color="danger">
        <IconDanger className="mr-3" />
        In attesa del caricamento dei dettagli del documento
      </Alert>
    </div>
  );
};

export default IdentificationDetails;
