export type TargetsQuestionsKeys =
  | "dom1"
  | "dom2"
  | "dom3"
  | "dom4"
  | "dom5"
  | "dom6"
  | "dom7"
  | "dom8"
  | "dom9"
  | "dom10"
  | "objValue1"
  | "objValue2"
  | "objValue3";

const domandeObiettivi: Array<{
  inputId: string;
  inputName: TargetsQuestionsKeys;
  question: string;
  weight: number[];
}> = [
  {
    inputId: "dom1",
    inputName: "dom1",
    question: "Tutelarsi da danni causati involontariamente a terzi?",
    weight: [5, 0, 0, 0],
  },
  {
    inputId: "dom2",
    inputName: "dom2",
    question:
      "Tutelare la propria serenità a fronte di eventuali imprevisti quotidiani?",
    weight: [5, 0, 0, 0],
  },
  {
    inputId: "dom3",
    inputName: "dom3",
    question: "Tutelare la propria famiglia in caso di evento fatale?",
    weight: [0, 4, 0, 0],
  },
  {
    inputId: "dom4",
    inputName: "dom4",
    question:
      "Non dover dipendere dallo Stato per indennizzi, cure e assistenza sanitaria?",
    weight: [0, 0, 4, 0],
  },
  {
    inputId: "dom5",
    inputName: "dom5",
    question:
      "Proteggere la vostra qualità di vita nel caso di gravi infortuni che impediscano di lavorare?",
    weight: [0, 0, 4, 0],
  },
  {
    inputId: "dom6",
    inputName: "dom6",
    question: "Risparmiare per realizzare i propri desideri e progetti?",
    weight: [0, 0, 0, 3],
  },
  {
    inputId: "dom7",
    inputName: "dom7",
    question: "Disporre di un capitale per poter integrare la pensione?",
    weight: [0, 0, 0, 2],
  },
  {
    inputId: "dom8",
    inputName: "dom8",
    question: "Tutelare il patrimonio costruito con sacrificio negli anni?",
    weight: [2, 4, 2, 0],
  },
  {
    inputId: "dom9",
    inputName: "dom9",
    question:
      "Mantenere o migliorare il proprio tenore di vita anche durante la vecchiaia?",
    weight: [0, 1, 0, 3],
  },
  {
    inputId: "dom10",
    inputName: "dom10",
    question: "Garantirsi cure mediche adeguate nel momento del bisogno?",
    weight: [0, 2, 0, 2],
  },
];

export default domandeObiettivi;
