import classNames from "classnames";
import moment from "moment";
import React, {ReactNode, useEffect, useMemo} from "react";
import Autocomplete from "react-autocomplete";
import {useDispatch, useSelector} from "react-redux";
import {Col, Container, FormGroup, Label} from "reactstrap";
import {debounce} from "throttle-debounce";
import {Required} from "utility-types";
import {actions as capsActions, ICap, ICapParams} from "../../Caps";
import {getIsListPending} from "../../Caps/selectors";
import {getHighlightedText} from "../../helpers/getHighlightedText";
import {IconSpinner, IconWarning} from "../../Icons";
import {getDenormalizedCaps} from "../../Recommendation/selectors";
import {getUser} from "../../User/selectors";

export interface IResultItem {
  fiscalCode: string;
  id: number;
  name: string;
  cap?: ICap;
  surname: string;
}

interface IProps {
  searchParams:
    | Required<Partial<ICapParams>, "idd">
    | Required<Partial<ICapParams>, "overdrafts">;
  onSelect: (value: string, item: IResultItem) => void;
}

const renderInput = (props: any) => {
  return (
    <input
      className="form-control"
      type="search"
      id="search-contractor"
      placeholder="Nome, cognome o codice fiscale"
      {...props}
    />
  );
};

const getItemValue = (item: IResultItem) => {
  return `${item.name} ${item.surname}`;
};

const SearchContractor = ({onSelect, searchParams}: IProps) => {
  const [value, setValue] = React.useState("");
  const dispatch = useDispatch();
  const loggedUser = useSelector(getUser);
  const searchCaps = useMemo(
    () =>
      debounce(300, (value?: string) => {
        const defaultSearchParams = {
          contractor: value,
          user: loggedUser?.fiscalCode,
          limit: 15,
          offset: 0,
          sort: "-dateStart",
        };

        dispatch(
          capsActions.list({
            ...defaultSearchParams,
            ...searchParams,
          })
        );
      }),
    [dispatch, loggedUser?.fiscalCode, searchParams]
  );

  const shouldItemRender = (item: IResultItem, value: string) => {
    if (
      searchParams.idd &&
      (!item.cap!.dateIdd ||
        moment(item.cap!.dateIdd).isBefore(moment().subtract(1, "year")))
    ) {
      return false;
    }
    if (searchParams.overdrafts && !item.cap!.dateScoperture) {
      return false;
    }

    return (
      item.cap!.responseValidation === "1" &&
      !!item.name &&
      !!item.surname &&
      (`${item.name} ${item.surname}`
        .toLowerCase()
        .includes(value.toLowerCase().trim()) ||
        `${item.surname} ${item.name}`
          .toLowerCase()
          .includes(value.toLowerCase().trim()) ||
        item.fiscalCode.toLowerCase().includes(value.toLowerCase().trim()))
    );
  };

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
      searchCaps(event.target.value);
    },
    [searchCaps]
  );

  const denormalizedCaps = useSelector(getDenormalizedCaps);
  const isListPending = useSelector(getIsListPending);

  const renderMenu = React.useCallback(
    (
      items: ReactNode[],
      value: string,
      style: React.CSSProperties
    ): ReactNode => {
      return (
        <div
          className={classNames("dropdown-menu show", {
            "bg-warning": items.length === 0 && !isListPending,
          })}
          aria-labelledby="search-contractor"
          style={{...style}}
        >
          {items.length === 0 && !isListPending && (
            <span className="dropdown-item-text text-center">
              <IconWarning /> Nessun cliente con i requisiti necessari trovato
            </span>
          )}
          {isListPending && (
            <span className="dropdown-item-text text-center">
              <IconSpinner className="icon-spin" /> Caricamento...
            </span>
          )}
          {items}
        </div>
      );
    },
    [isListPending]
  );

  useEffect(() => {
    searchCaps();
  }, [searchCaps]);

  const renderItem = React.useCallback(
    (item: IResultItem, highlighted: boolean) => {
      return (
        <button
          key={item.id}
          className={classNames("dropdown-item", {
            hover: highlighted,
          })}
          type="button"
        >
          {getHighlightedText(`${item.name} ${item.surname}`, value)}{" "}
          <small className="text-secondary">
            {getHighlightedText(item.fiscalCode, value)}
          </small>
        </button>
      );
    },
    [value]
  );

  return (
    <Container>
      <FormGroup row>
        <Label sm="auto" for="search-contractor">
          Ricerca cliente
        </Label>
        <Col>
          <Autocomplete // TODO: da sostituire con react-autosuggest
            items={denormalizedCaps.map((cap) => ({
              cap,
              fiscalCode: cap.contractor ? cap.contractor.fiscalCode : "",
              id: cap.id,
              name: cap.contractor ? cap.contractor.name : "",
              surname: cap.contractor ? cap.contractor.surname : "",
            }))}
            shouldItemRender={shouldItemRender}
            getItemValue={getItemValue}
            renderItem={renderItem}
            renderInput={renderInput}
            renderMenu={renderMenu}
            value={value}
            onChange={handleChange}
            onSelect={onSelect}
            wrapperStyle={{}}
          />
        </Col>
      </FormGroup>
    </Container>
  );
};

export default SearchContractor;
