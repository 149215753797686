import React from "react";
import {CustomInput, FormFeedback, FormText} from "reactstrap";

const RenderCustomCheckboxField = ({
  input,
  meta: {touched, error, warning, dirty},
  ...props
}: any) => (
  <>
    <CustomInput
      {...(props.noValidate !== true && touched ? {invalid: !!error} : {})}
      {...(props.noValidate !== true && dirty ? {valid: !error} : {})}
      {...input}
      {...props}
    />
    {error && <FormFeedback>{error}</FormFeedback>}
    {!error && warning && <FormText color="warning">{warning}</FormText>}
  </>
);

export default RenderCustomCheckboxField;
