const homeType = [
  {value: "1", label: "Appartamento monolocale"},
  {value: "2", label: "Appartamento bilocale"},
  {value: "3", label: "Appartamento trilocale"},
  {value: "4", label: "Appartamento quadrilocale"},
  {value: "5", label: "Appartamento plurilocale"},
  {value: "6", label: "Villetta bifamiliare"},
  {value: "7", label: "Villetta trifamiliare"},
  {value: "8", label: "Villetta a schiera"},
  {value: "9", label: "Villa singola"},
  {value: "10", label: "Altro"}
];

export default homeType;
