import React from "react";
import {IconSpinner} from "../Icons";

interface IProps {
  visible?: boolean;
}

const LoaderOverlay: React.FC<IProps> = ({children, visible}) =>
  visible ? (
    <div className="component-loader">
      <IconSpinner className="icon-spin mr-2" size={2} /> {children}
    </div>
  ) : null;

export default LoaderOverlay;
