import {connect} from "react-redux";
import {IRootState} from "../../redux/reducers";
import {actions} from "../actions";
import {
  getCanApprove,
  getCanCreate,
  getCanView,
  getDenormalizedItems,
  getNumItems,
  getParams,
  getUi,
} from "../selectors";
import {IRecommendationParams} from "../types";
import Page from "./Page";

const mapStateToProps = (state: IRootState) => ({
  canApprove: getCanApprove(state),
  canContractorManageRecommendation: getCanView(state),
  canCreate: getCanCreate(state),
  denormalizedItems: getDenormalizedItems(state),
  numItems: getNumItems(state) ?? 0,
  params: getParams(state),
  ui: getUi(state),
});

const mapDispatchToProps = {
  list: (params?: IRecommendationParams) => actions.list(params),
};

const ConnectedPage = connect(mapStateToProps, mapDispatchToProps)(Page);

export default ConnectedPage;
