import React from "react";
import {Button} from "reactstrap";
import {IconCheckmark, IconTimes} from "../../Icons";
import {RecommendationStatus} from "../types";
import AcceptModal from "./AcceptModal";
import {IRejectFormData} from "./RejectForm";
import RejectModal from "./RejectModal";

interface IProps {
  handleValidate: (values: {
    revision?: string;
    status:
      | RecommendationStatus.STATUS_CORRESPONDING
      | RecommendationStatus.STATUS_WAIT_REVIEW;
  }) => Promise<void>;
  isRejectPending?: boolean;
  isAcceptPending?: boolean;
  rejectError?: string;
  acceptError?: string;
}

interface IState {
  isAcceptModalOpen: boolean;
  isRejectModalOpen: boolean;
}

class ValidateButtons extends React.PureComponent<IProps, IState> {
  public readonly state: Readonly<IState> = {
    isAcceptModalOpen: false,
    isRejectModalOpen: false,
  };

  constructor(props: IProps) {
    super(props);

    this.toggleAcceptModal = this.toggleAcceptModal.bind(this);
    this.toggleRejectModal = this.toggleRejectModal.bind(this);
  }

  public render() {
    const {
      handleValidate,
      isRejectPending,
      isAcceptPending,
      rejectError,
      acceptError,
    } = this.props;

    const accept = () => {
      handleValidate({status: RecommendationStatus.STATUS_CORRESPONDING});
    };
    const reject = (values: IRejectFormData) => {
      handleValidate({
        revision: values.revision,
        status: RecommendationStatus.STATUS_WAIT_REVIEW,
      });
    };

    return (
      <div className="text-center">
        <Button color="success" outline onClick={this.toggleAcceptModal}>
          <IconCheckmark /> Conferma la raccomandazione
        </Button>
        <AcceptModal
          isOpen={this.state.isAcceptModalOpen}
          toggle={this.toggleAcceptModal}
          isAcceptPending={isAcceptPending}
          acceptError={acceptError}
          accept={accept}
        />{" "}
        <Button color="danger" outline onClick={this.toggleRejectModal}>
          <IconTimes /> Richiedi una revisione
        </Button>
        <RejectModal
          isOpen={this.state.isRejectModalOpen}
          toggle={this.toggleRejectModal}
          isRejectPending={isRejectPending}
          rejectError={rejectError}
          reject={reject}
        />
      </div>
    );
  }

  private toggleAcceptModal() {
    this.setState((prevState) => ({
      isAcceptModalOpen: !prevState.isAcceptModalOpen,
    }));
  }

  private toggleRejectModal() {
    this.setState((prevState) => ({
      isRejectModalOpen: !prevState.isRejectModalOpen,
    }));
  }
}

export default ValidateButtons;
