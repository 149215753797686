import React from "react";
import {Alert, Button, Form, FormGroup, Label} from "reactstrap";
import {Field, InjectedFormProps, reduxForm} from "redux-form";

import RenderField from "../helpers/RenderField";
import IconSpinner from "../Icons/IconSpinner";

export interface IActivateFormData {
  usid: string;
}

interface IOwnProps {
  isActivateFail: boolean;
  activateError: string;
}

type IProps = InjectedFormProps<IActivateFormData, IOwnProps> & IOwnProps;

const validate = (values: IActivateFormData) => {
  const errors: IActivateFormData = {usid: ""};
  if (!values.usid) {
    errors.usid = "Questo campo è obbligatorio";
  }
  return errors;
};

const ActivateForm: React.FC<IProps> = (props) => (
  <Form onSubmit={props.handleSubmit}>
    <FormGroup>
      <Label for="exampleEmail">Nome utente o e-mail</Label>
      <Field
        id="usid"
        name="usid"
        component={RenderField}
        type="text"
        placeholder="Nome utente o e-mail"
      />
    </FormGroup>
    {props.isActivateFail ? (
      <Alert color="danger">{props.activateError}</Alert>
    ) : null}
    <Button color="primary" block>
      {props.submitting ? <IconSpinner className="icon-spin" /> : ""} Attiva il
      tuo account
    </Button>
  </Form>
);

export default reduxForm<IActivateFormData, IOwnProps>({
  form: "activateForm",
  validate,
})(ActivateForm);
