import React from "react";
import {Card, CardText, CardTitle, Col, Row} from "reactstrap";
import arrayChunk from "../helpers/array-chunk";
import FormatNumber from "../helpers/FormatNumber";
import {IIncomeData, IIncomesFormData} from "./IncomesForm";

interface IProps {
  incomes: IIncomesFormData;
}

const IncomesDetails: React.FC<IProps> = ({incomes}) => (
  <>
    {arrayChunk(
      incomes.incomes.length > 0 ? incomes.incomes : [{} as IIncomeData],
      3
    ).map((incomeChunk, index) => (
      <Row key={index}>
        {incomeChunk.map((income) => (
          <Col key={income.description || "Altri redditi"}>
            <Card body className="text-white bg-primary text-center">
              <CardTitle>{income.description || "Altri redditi"}</CardTitle>
              <CardText className="h2">
                € <FormatNumber>{income.value || 0}</FormatNumber>
              </CardText>
            </Card>
          </Col>
        ))}
      </Row>
    ))}
  </>
);

export default IncomesDetails;
