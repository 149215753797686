import {connect} from "react-redux";
import {actions} from "../";
import {
  actions as capsActions,
  ICap,
  ICapEntity,
  NewCapModalTypes,
} from "../../Caps";
import {actions as optionsActions} from "../../Caps/actions/options";
import {
  getCapsUi,
  getIsListPending,
  getIsUploadIdFail,
  getOptions,
  getUploadIdError,
} from "../../Caps/selectors";
import {IUploadIdFormData} from "../../Caps/UploadIdForm";
import {actions as eSignActions} from "../../ESign";
import {PDFTypes} from "../../ESign/types";
import {IRootState} from "../../redux/reducers";
import IApiError from "../../types/IApiError";
import {getUser} from "../../User/selectors";
import {IUserEntity} from "../../Users";
import {
  getCanApprove,
  getCanSeeChosenCap,
  getChosenCap,
  getCreateError,
  getDenormalizedCaps,
  getIsChosenCapFromCap,
  getIsCreateSuccess,
  getLastCreatedId,
} from "../selectors";
import {IChooseOptionsFormData} from "./ChooseOptionsForm";
import Page from "./Page";

const mapStateToProps = (state: IRootState) => ({
  availableOptions: getOptions(state),
  canSeeCap: getCanSeeChosenCap(state),
  capUi: getCapsUi(state),
  chosenCap: getChosenCap(state),
  denormalizedCaps: getDenormalizedCaps(state),
  isBackOffice: getCanApprove(state),
  isChosenCapFromCap: getIsChosenCapFromCap(state),
  isCreateSuccess: getIsCreateSuccess(state),
  isListPending: getIsListPending(state),
  lastCreatedId: getLastCreatedId(state),
  isUploadIdFail: getIsUploadIdFail(state),
  uploadIdError: getUploadIdError(state),
  submitError: getCreateError(state),
  user: getUser(state),
});

const mapDispatchToProps = {
  chooseCap: (cap: string | null) => actions.chooseCap(cap),
  chooseCapFromCap: (isFromCap: boolean) => actions.chooseCapFromCap(isFromCap),
  closeModal: (modalType: NewCapModalTypes) => {
    return capsActions.closeNewCapModal(modalType);
  },
  createRecommendation: (values: IChooseOptionsFormData, cap: ICap) => {
    const ids = Object.keys(values.options).filter(
      (key) => values.options[key] && values.options[key].value
    );

    const options = ids.map((id) => ({
      option: cap.idd!.suitableOptions.find(
        (option) => option.id.toString() === id
      ),
      percent: parseFloat(values.options[id].percent),
    }));

    return actions.create({
      cap: cap.id!.toString(),
      code: values.code,
      comment: values.comment, // || null,
      jsonOptions: JSON.stringify(options),
      user: values.userId.toString(),
    });
  },
  confirmIdentificationSubmit(cap: ICapEntity, user: IUserEntity) {
    return capsActions.confirmIdentification(cap, user);
  },
  openModal: (modalType: NewCapModalTypes) => {
    return capsActions.openNewCapModal(modalType);
  },
  openRequestOTPIdentificationModal: (capId: string) => {
    return eSignActions.openModal(PDFTypes.identification, capId);
  },
  optionsList: () => {
    return optionsActions.loadOptions();
  },
  searchCaps: (userFC?: string, value?: string) =>
    capsActions.list({
      contractor: value,
      idd: "available",
      user: userFC,
      limit: 15,
      offset: 0,
      sort: "-dateStart",
    }),
  uploadIdFail: (error: IApiError) => {
    return capsActions.uploadIdFail(error);
  },
  uploadIdSubmit: (
    userId: string,
    cap: ICapEntity,
    values: IUploadIdFormData
  ) => {
    return capsActions.uploadId(userId, cap, values);
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(Page);
