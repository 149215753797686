import React from "react";
import {Card, CardBody, CardHeader, FormGroup, Label} from "reactstrap";
import {Field, FieldArray, FormSection} from "redux-form";
import {floatFormatter} from "../helpers/formatters";
import {
  naturalNormalizer,
  positiveFloatNormalizer,
} from "../helpers/normalizers";
import {floatParser} from "../helpers/parsers";
import RenderField from "../helpers/RenderField";
import RenderFieldArray from "../helpers/RenderFieldArray";
import RenderMultiField from "../helpers/RenderMultiField";
import {IEmploymentFormData} from "./EmploymentForm";

interface IProps {
  values?: IEmploymentFormData;
}

const PersonalData: React.FC<IProps> = ({values}) => (
  <div className="mb-3">
    <FormSection name="currentEmployment">
      <Card className="mb-3">
        <CardHeader className="bg-primary text-white">
          {" "}
          ultima occupazione
        </CardHeader>
        <CardBody>
          <Label>Tipologia</Label>
          <RenderMultiField
            id="tipologia"
            name="tipologia"
            type="radio"
            items={[
              {value: "dipendente", text: "Dipendente"},
              {value: "subordinato", text: "Subordinato"},
              {value: "autonomo", text: "Autonomo"},
              {value: "disoccupato", text: "Disoccupato"},
              {value: "pensione", text: "In pensione"},
            ]}
          />
          {(values &&
            values.currentEmployment &&
            values.currentEmployment.tipologia === "dipendente") ||
          (values &&
            values.currentEmployment &&
            values.currentEmployment.tipologia === "subordinato") ? (
            <div>
              <FormGroup>
                <Label for="professione">Professione</Label>
                <Field
                  id="professione"
                  name="professione"
                  type="text"
                  component={RenderField}
                  placeholder="Professione"
                />
              </FormGroup>
              <FormGroup>
                <Label for="dal">Dal (indicare anno)</Label>
                <Field
                  id="dal"
                  name="dal"
                  type="text"
                  normalize={naturalNormalizer}
                  component={RenderField}
                  placeholder="Dal, anno"
                />
              </FormGroup>
              <FormGroup>
                <Label for="scadenza">
                  Scadenza contratto (se a tempo determinato, indicare anno)
                </Label>
                <Field
                  id="scadenza"
                  name="scadenza"
                  type="text"
                  normalize={naturalNormalizer}
                  component={RenderField}
                  placeholder="Scadenza"
                />
              </FormGroup>
              <FormGroup>
                <Label for="stipendio">
                  Stipendio mensile (importo al netto)
                </Label>
                <Field
                  id="stipendio"
                  name="stipendio"
                  type="text"
                  parse={floatParser}
                  format={floatFormatter}
                  normalize={positiveFloatNormalizer}
                  component={RenderField}
                  placeholder="Stipendio"
                />
              </FormGroup>
              <FormGroup>
                <Label for="mensilita">Mensilità</Label>
                <Field
                  id="mensilita"
                  name="mensilita"
                  component={RenderField}
                  type="select"
                >
                  <option value="">Seleziona</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                </Field>
              </FormGroup>
              <FormGroup hidden>
                <Label for="previsione">Previsione di crescita</Label>
                <Field
                  id="previsione"
                  name="previsione"
                  type="select"
                  component={RenderField}
                  disabled
                >
                  <option value="mid">
                    1.92% - Leggero aumento rispetto rivalutazione ISTAT
                  </option>
                </Field>
              </FormGroup>
            </div>
          ) : values &&
            values.currentEmployment &&
            values.currentEmployment.tipologia === "autonomo" ? (
            <div>
              <FormGroup>
                <Label for="professione">Professione</Label>
                <Field
                  id="professione"
                  name="professione"
                  type="text"
                  component={RenderField}
                  placeholder="Professione"
                />
              </FormGroup>
              <Label>Categoria</Label>
              <RenderMultiField
                id="categoria"
                name="categoria"
                type="radio"
                items={[
                  {value: "artigiano", text: "Artigiano"},
                  {value: "commerciante", text: "Commerciante"},
                  {value: "altro", text: "Altro"},
                ]}
              />
              <FormGroup>
                <Label>
                  <Field
                    id="cassa"
                    name="cassa"
                    type="checkbox"
                    component="input"
                  />{" "}
                  Iscritto ad una cassa professionale di categoria (forense,
                  geometri, medici, psicologi, ecc..)
                </Label>
              </FormGroup>
              <FormGroup>
                <Label for="dal">Dal (indicare anno)</Label>
                <Field
                  id="dal"
                  name="dal"
                  type="text"
                  normalize={naturalNormalizer}
                  component={RenderField}
                  placeholder="Dal, anno"
                />
              </FormGroup>
              <FormGroup>
                <Label for="reddito">Reddito lordo annuo</Label>
                <Field
                  id="reddito"
                  name="reddito"
                  type="text"
                  parse={floatParser}
                  format={floatFormatter}
                  normalize={positiveFloatNormalizer}
                  component={RenderField}
                  placeholder="Reddito lordo annuo"
                />
              </FormGroup>
              <FormGroup>
                <Label for="percentuale">
                  Tassazione in percentuale sul Reddito lordo annuo
                </Label>
                <Field
                  id="percentuale"
                  name="percentuale"
                  type="text"
                  normalize={naturalNormalizer}
                  component={RenderField}
                  placeholder="Tassazione percentuale sul reddito"
                />
              </FormGroup>
              <FormGroup hidden>
                <Label for="previsione">Previsione di crescita</Label>
                <Field
                  id="previsione"
                  name="previsione"
                  type="select"
                  component={RenderField}
                  disabled
                >
                  <option value="mid">
                    1.92% - Leggero aumento rispetto rivalutazione ISTAT
                  </option>
                </Field>
              </FormGroup>
            </div>
          ) : null}
        </CardBody>
      </Card>
    </FormSection>
    <FieldArray
      name="prevEmployments"
      component={RenderFieldArray}
      prevEmploymentsValues={
        values && values.currentEmployment && values.prevEmployments
      }
    />
  </div>
);

export default PersonalData;
