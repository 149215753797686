import moment from "moment";

export const floatParser = (value: string) => {
  return value ? value.replace(",", ".") : "";
};

export const birthdayParser = (value: string) => (value ? moment(value) : "");

export const cityParser = (name: string) => {
  return name.toUpperCase();
};
