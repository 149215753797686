import React from "react";
import ReactTable, {RowInfo} from "react-table";

interface IProps {
  usersList: Array<{[Key: string]: string}>;
}

const addClass = (finalState: any, rowInfo?: RowInfo) => {
  return rowInfo ? {className: `table-${rowInfo.original.level}`} : {};
};

const UsersTable: React.FC<IProps> = (props) => (
  <ReactTable
    data={props.usersList}
    columns={[
      {
        Header: "Codice fiscale",
        accessor: "fiscalCode",
      },
      {
        Header: "Nome utente",
        accessor: "usid",
      },
      {
        Header: "Codice RUI",
        accessor: "ruiCode",
      },
      {
        Header: "Data iscrizione Codice RUI",
        accessor: "dateRui",
      },
      {
        Header: "Nome e cognome",
        accessor: (user: {[Key: string]: string}) =>
          `${user.name} ${user.surname}`,
        id: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Report",
        accessor: "report",
        style: {whiteSpace: "unset"},
      },
    ]}
    getTrProps={addClass}
    defaultPageSize={50}
    className="table-hover mb-3"
    minRows={0}
    previousText="Precedente"
    nextText="Prossima"
    pageText="Pagina"
    ofText="di"
    rowsText="Advisor"
  />
);

export default UsersTable;
