import React from "react";
import {connect} from "react-redux";
import {RouteComponentProps} from "react-router";
import {Container} from "reactstrap";
import {Layout} from "../App";
import {IRootState} from "../redux/reducers";
import {actions as companiesActions} from "../Caps/actions/companies";
import {
  actions,
  AddUserForm,
  IStateNewUser,
  IStateUi,
  IUser,
  IUserEntity,
} from "../Users";

interface IStateProps {
  ui: IStateUi;
  user: IUser;
  newUser: IStateNewUser;
  // viewUser?: IStateViewUser;
  // denormalizedItems: any[];
}

interface IMatchParams {
  userId: string;
}

interface IDispatchProps {
  create: (user: IUserEntity) => Promise<void>;
  loadFromDb: (id: string) => Promise<void>;
  loadFromRoute: (json: string) => actions;
  loadCompanies: () => Promise<void>;
}

type IProps = IStateProps &
  IDispatchProps &
  RouteComponentProps<IMatchParams, {}, {fromUser?: string}>;

// const mapStateToProps = (state: IRootState): IStateProps => ({
//   ui: state.users.ui,
//   viewUser: state.users.viewUser
// });

const mapStateToProps = (state: IRootState) => {
  // ui: state.caps.ui,
  // users: state.users.list
  // const input = state.users.list;
  // const user = new schema.Entity("users");

  // const entities = {
  // users: state.users.entities
  // };

  return {
    newUser: state.users.newUser,
    ui: state.users.ui,
    // users: denormalize(input, [user], entities)
  };
};

const mapDispatchToProps = {
  create: (user: IUserEntity) => {
    return actions.create(user);
  },
  loadFromDb: (id: string) => {
    return actions.fromDb(id);
  },
  loadFromRoute: (json: string) => {
    return actions.fromRoute(json);
  },
  loadCompanies: () => {
    return companiesActions.loadCompanies();
  },
};

class AddUserPage extends React.Component<IProps> {
  public componentDidMount() {
    if (this.props.match.params.userId) {
      if (this.props.location.state && this.props.location.state.fromUser) {
        // Prendo lo user dalla route
        this.props.loadFromRoute(this.props.location.state.fromUser);
        this.props.history.replace({
          pathname: this.props.location.pathname,
          state: {},
        });
      } else {
        // console.log("QUI SCAZZA"); // tslint:disable-line
        // carica da db il cap con id = this.props.match.params.capId
        this.props.loadFromDb(this.props.match.params.userId);
      }
    } else {
      // non ho dati, continuo la creazione
      // Se ci sono li svuoto
      // this.props.reset();
      // this.props.history.push("/users");
    }
  }

  public render() {
    // const user = this.props.user;
    // console.log("JOHN in RENDER"); // tslint:disable-line
    // console.log({user}); // tslint:disable-line
    return (
      // const DetailPage = (props: any) => (
      <Layout isProtected>
        <Container>
          <h2>Inserimento Nuovo Utente</h2>
        </Container>
        <Container className="container-max auto-margin-4 p-md-5">
          <AddUserForm
            onSubmit={this.props.create}
            newUser={this.props.newUser}
            loadCompanies={this.props.loadCompanies}
          />
        </Container>
      </Layout>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUserPage);
