import React from "react";
import {connect} from "react-redux";
import {Alert, Button, Form} from "reactstrap";
import {formValueSelector, InjectedFormProps, reduxForm} from "redux-form";
import {dehydrateCap, ICap, ICapEntity, IStateUi} from ".";
import {focusFirstInvalid} from "../helpers/focusFirstInvalid";
import IconSpinner from "../Icons/IconSpinner";
import {IRootState} from "../redux/reducers";
import PersonalData from "./EmploymentFormPersonalData";

export interface IEmploymentFormDataSingle {
  redditoNormalizzato: number;
  tipologia:
    | "dipendente"
    | "subordinato"
    | "autonomo"
    | "disoccupato"
    | "pensione";
  professione: string;
  dal: string;
  al: string;
  scadenza: string;
  stipendio: string;
  mensilita: number;
  categoria: "artigiano" | "commerciante" | "altro";
  cassa: boolean;
  reddito: string;
  percentuale: number;
  previsione: "low" | "mid" | "high";
}

export interface IEmploymentFormData {
  currentEmployment: IEmploymentFormDataSingle;
  prevEmployments: IEmploymentFormDataSingle[];
}

interface IProps extends InjectedFormProps<IEmploymentFormData, IProps> {
  currentEmploymentValues?: IEmploymentFormData;
}

interface IOwnProps {
  cap: ICap;
  closeEmployment: () => void;
  employmentSubmit: (
    values: IEmploymentFormData,
    cap: ICapEntity
  ) => Promise<void>;
  ui: IStateUi;
}

const validate = (values: IEmploymentFormData) => {
  const errors: any = {};

  if (!!values.currentEmployment) {
    errors.currentEmployment = {};
    if (!values.currentEmployment.tipologia) {
      errors.currentEmployment.tipologia = "Scegli una tipologia di lavoro";
    }
    if (!values.currentEmployment.dal) {
      errors.currentEmployment.dal =
        "Indica l'anno nel quale hai iniziato questo lavoro";
    }
    if (!values.currentEmployment.stipendio) {
      errors.currentEmployment.stipendio = "Lo stipendio è obbligatorio";
    }
    if (parseFloat(values.currentEmployment.stipendio) <= 0) {
      errors.currentEmployment.stipendio =
        "Lo stipendio mensile inserito non può essere uguale a 0";
    }
    if (!values.currentEmployment.mensilita) {
      errors.currentEmployment.mensilita = "Scegli le mensilità";
    }
    if (!values.currentEmployment.previsione) {
      errors.currentEmployment.previsione = "Scegli la previsione";
    }
    if (!values.currentEmployment.categoria) {
      errors.currentEmployment.categoria = "Scegli la categoria";
    }
    if (!values.currentEmployment.reddito) {
      errors.currentEmployment.reddito = "Il reddito è obbligatorio";
    }
    if (parseFloat(values.currentEmployment.reddito) <= 0) {
      errors.currentEmployment.reddito =
        "Il reddito inserito non può essere uguale a 0";
    }
    if (
      values.currentEmployment.percentuale &&
      values.currentEmployment.percentuale > 100
    ) {
      errors.currentEmployment.percentuale =
        "La percentuale dev'essere minore di 100";
    }
  }

  return errors;
};

const EmploymentForm: React.FC<IProps & IOwnProps> = (props) => {
  const submitWithCap = async (values: IEmploymentFormData) => {
    const {
      result,
      entities: {caps},
    } = dehydrateCap(props.cap);
    await props.employmentSubmit(values, caps[result]);
  };
  return (
    <Form onSubmit={props.handleSubmit(submitWithCap)}>
      <PersonalData values={props.currentEmploymentValues} />
      {props.ui.isEmploymentFail && (
        <Alert color="danger">{props.ui.employmentError}</Alert>
      )}
      <Button type="submit" color="primary" outline disabled={props.submitting}>
        {props.submitting ? <IconSpinner className="icon-spin" /> : ""} Salva e
        torna all'analisi
      </Button>{" "}
      <Button
        type="button"
        color="secondary"
        outline
        onClick={props.closeEmployment}
      >
        Annulla
      </Button>
    </Form>
  );
};

const EmploymentFormConnected = reduxForm({
  form: "employmentForm",
  onSubmitFail: focusFirstInvalid,
  validate,
})(EmploymentForm);

const selector = formValueSelector("employmentForm");
export default connect((state: IRootState, ownProps: IOwnProps) => {
  const currentEmploymentValues = {
    currentEmployment: selector(state, "currentEmployment"),
    prevEmployments: selector(state, "prevEmployments"),
  };

  let initialValues = ownProps.cap!.data.employments;

  if (!initialValues) {
    initialValues = {
      currentEmployment: {previsione: "mid"},
    } as IEmploymentFormData;
  }

  return {
    currentEmploymentValues,
    initialValues,
  };
})(EmploymentFormConnected);
