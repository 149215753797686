import {
  ActionCreatorWithPreparedPayload,
  AsyncThunk,
  AsyncThunkPayloadCreator,
  createAction,
  createAsyncThunk,
} from "@reduxjs/toolkit";

type AsyncThunkResetActionCreator<ThunkArg> = ActionCreatorWithPreparedPayload<
  ThunkArg[], // [ThunkArg]
  undefined,
  string,
  never,
  {
    arg: ThunkArg;
  }
>;

type AsyncThunkWithReset<Returned, ThunkArg, ThunkApiConfig> = AsyncThunk<
  Returned,
  ThunkArg,
  ThunkApiConfig
> & {
  reset: AsyncThunkResetActionCreator<{thunkId: string} | void>;
};

export const createAsyncThunkAndReset = <Returned, ThunkArg, ThunkApiConfig>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkApiConfig>
) => {
  const thunk = createAsyncThunk(
    typePrefix,
    payloadCreator
  ) as AsyncThunkWithReset<Returned, ThunkArg, ThunkApiConfig>;

  thunk.reset = createAction(
    typePrefix + "/reset",
    (arg: {thunkId: string} | void) => {
      return {
        payload: undefined,
        meta: {arg},
      };
    }
  );

  return thunk;
};
