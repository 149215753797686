import React from "react";
import {Alert, Button} from "reactstrap";
import {IconCheckCircle, IconDanger, IconDownload, IconESign} from "../Icons";
import {IPrivacySnapshot} from "../Users";

interface IProps {
  capId: string;
  contractorId: string;
  details?: IPrivacySnapshot | "true";
  eSignId?: string;
  isCapComplianceApr21: boolean;
  isContractor: boolean;
  openRequestOTPPrivacyModal?: (userId: string) => void;
  privacyOpenModal?: () => void;
  showAttachments3And4: boolean;
}

const PrivacyDetails: React.FC<IProps> = ({
  capId,
  contractorId,
  details,
  eSignId,
  isCapComplianceApr21,
  isContractor,
  openRequestOTPPrivacyModal,
  privacyOpenModal,
  showAttachments3And4,
}) => {
  const openRequestOTPPrivacyModalWithId = () => {
    openRequestOTPPrivacyModal?.(contractorId);
  };

  if (!details || details === "true") {
    return (
      <>
        <Alert color="danger" className="mb-0">
          <IconDanger className="mr-3" />
          {isContractor ? (
            <span>In attesa che accetti il consenso</span>
          ) : (
            <span>In attesa che il cliente accetti il consenso</span>
          )}
        </Alert>
        {isContractor && (
          <div className="text-center mt-3">
            <Button color="primary" outline onClick={privacyOpenModal}>
              Controlla il consenso
            </Button>
          </div>
        )}
      </>
    );
  }

  if (!eSignId) {
    return (
      <>
        <Alert color="danger" className="mb-0">
          <IconDanger className="mr-3" />
          {isContractor ? (
            <span>In attesa che firmi il consenso</span>
          ) : (
            <span>In attesa che il cliente firmi il consenso</span>
          )}
        </Alert>
        {isContractor && (
          <div className="text-center mt-3">
            <Button
              color="primary"
              outline
              onClick={openRequestOTPPrivacyModalWithId}
            >
              <IconESign /> Firma il pdf del consenso
            </Button>
          </div>
        )}
      </>
    );
  }

  return (
    <div>
      <Alert color="success">
        <IconCheckCircle className="mr-3" />
        {isContractor ? (
          <span>Hai accettato il consenso</span>
        ) : (
          <span>Il cliente ha accettato il consenso</span>
        )}
      </Alert>
      <div className="text-center">
        <Button
          color="primary"
          outline
          download
          href={`${process.env.REACT_APP_API_BASE_URL}users/${contractorId}/get-pdf-privacy`}
        >
          <IconDownload /> Scarica il pdf del consenso
        </Button>
        {isCapComplianceApr21 && (
          <>
            <Button
              color="primary"
              outline
              target="_blank"
              href={`${process.env.REACT_APP_API_BASE_URL}caps/${capId}/get-pdf-allegato3`}
              className="ml-2"
            >
              <IconDownload title="Allegato 3" /> Scarica Allegato 3
            </Button>
            <Button
              color="primary"
              outline
              target="_blank"
              href={`${process.env.REACT_APP_API_BASE_URL}caps/${capId}/get-pdf-allegato4ter`}
              className="ml-2"
            >
              <IconDownload title="Allegato 4-ter" /> Scarica Allegato 4-ter
            </Button>
          </>
        )}
        {showAttachments3And4 && (
          <Button
            color="primary"
            outline
            target="_blank"
            href="/documents/Allegato3.pdf"
            className="ml-2"
          >
            <IconDownload title="Allegato 3" /> Consulta Allegato 3
          </Button>
        )}
      </div>
    </div>
  );
};

export default PrivacyDetails;
