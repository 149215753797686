const dannoBiologicoColIndex = [
  [20, 1],
  [25, 2],
  [30, 3],
  [35, 4],
  [40, 5],
  [45, 6],
  [50, 7],
  [55, 8],
  [60, 9],
  [65, 11],
  [150, 12],
];

const dannoBiologicoRowIndex: {[key: number]: number} = {
  6: 0,
  7: 1,
  8: 2,
  9: 3,
  10: 4,
  11: 5,
  12: 6,
  13: 7,
  14: 8,
  15: 9,
};

const dannoBiologicoMaschi = [
  [
    929.37,
    5576.22,
    5297.41,
    5018.6,
    4739.79,
    4460.98,
    4182.17,
    3903.35,
    3624.54,
    3345.73,
    3066.92,
    2788.11,
  ],
  [
    987.46,
    6912.22,
    6566.61,
    6221.0,
    5875.39,
    5529.78,
    5184.17,
    4838.55,
    4492.94,
    4147.33,
    3801.72,
    3456.11,
  ],
  [
    1045.55,
    8364.4,
    7946.18,
    7527.96,
    7109.74,
    6691.52,
    6273.3,
    5855.08,
    5436.86,
    5018.64,
    4600.42,
    4182.2,
  ],
  [
    1103.63,
    9932.67,
    9436.04,
    8939.4,
    8442.77,
    7946.14,
    7449.5,
    6952.87,
    6456.24,
    5959.6,
    5462.97,
    4966.34,
  ],
  [
    1161.72,
    11617.2,
    11036.34,
    10455.48,
    9874.62,
    9293.76,
    8712.9,
    8132.04,
    7551.18,
    6970.32,
    6389.46,
    5808.6,
  ],
  [
    1277.89,
    14056.79,
    13353.95,
    12651.11,
    11948.27,
    11245.43,
    10542.59,
    9839.75,
    9136.91,
    8434.07,
    7731.23,
    7028.4,
  ],
  [
    1394.06,
    16728.72,
    15892.28,
    15055.85,
    14219.41,
    13382.98,
    12546.54,
    11710.1,
    10873.67,
    10037.23,
    9200.8,
    8364.36,
  ],
  [
    1510.23,
    19632.99,
    18651.34,
    17669.69,
    16688.04,
    15706.39,
    14724.74,
    13743.09,
    12761.44,
    11779.79,
    10798.14,
    9816.5,
  ],
  [
    1626.41,
    22769.74,
    21631.25,
    20492.77,
    19354.28,
    18215.79,
    17077.31,
    15938.82,
    14800.33,
    13661.84,
    12523.36,
    11384.87,
  ],
  [
    1742.58,
    26138.7,
    24831.77,
    23524.83,
    22217.9,
    20910.96,
    19604.03,
    18297.09,
    16990.16,
    15683.22,
    14376.29,
    13069.35,
  ],
];

const dannoBiologicoFemmine = [
  [
    1016.5,
    6099.0,
    5794.05,
    5489.1,
    5184.15,
    4879.2,
    4574.25,
    4269.3,
    3964.35,
    3659.4,
    3354.45,
    3049.5,
  ],
  [
    1074.59,
    7522.13,
    7146.02,
    6769.92,
    6393.81,
    6017.7,
    5641.6,
    5265.49,
    4889.38,
    4513.28,
    4137.17,
    3781.07,
  ],
  [
    1132.68,
    9061.44,
    6608.37,
    8155.3,
    7702.22,
    7249.15,
    6796.08,
    6343.01,
    5889.94,
    5436.86,
    4983.79,
    4530.72,
  ],
  [
    1190.76,
    10716.84,
    10181.0,
    9645.16,
    9109.31,
    8573.47,
    8037.63,
    7501.79,
    6965.95,
    6430.1,
    5894.26,
    5358.42,
  ],
  [
    1248.85,
    12488.5,
    11864.08,
    11239.65,
    10615.23,
    9990.8,
    9386.38,
    8741.95,
    8117.53,
    7493.1,
    6868.68,
    6244.25,
  ],
  [
    1365.02,
    15015.22,
    14264.46,
    13513.7,
    12762.94,
    12012.18,
    11281.42,
    10510.85,
    9759.89,
    9009.13,
    8258.37,
    7507.61,
  ],
  [
    1481.19,
    17774.28,
    16885.57,
    15996.85,
    15108.14,
    14219.42,
    13330.71,
    12442.0,
    11553.28,
    10664.57,
    9775.85,
    8887.14,
  ],
  [
    1597.36,
    20765.68,
    19727.4,
    18689.11,
    17650.83,
    16612.54,
    15574.26,
    14535.98,
    13497.69,
    12459.41,
    11421.12,
    10382.84,
  ],
  [
    1713.53,
    23989.42,
    22789.95,
    21590.48,
    20391.01,
    19191.54,
    17992.07,
    16792.59,
    15593.12,
    14393.65,
    13194.18,
    11994.71,
  ],
  [
    1829.71,
    27445.65,
    26073.37,
    24701.09,
    23328.8,
    21956.52,
    20584.24,
    19211.96,
    17839.67,
    16467.39,
    15095.11,
    13722.83,
  ],
];

const dannoRenditaAnnua: {[key: number]: number} = {
  16: 1161.71,
  17: 1277.9,
  18: 1394.07,
  19: 1510.24,
  20: 1626.41,
  21: 1742.58,
  22: 1858.75,
  23: 1974.92,
  24: 2091.09,
  25: 2207.27,
  26: 2323.44,
  27: 2439.61,
  28: 2555.78,
  29: 2671.95,
  30: 2846.21,
  31: 3020.47,
  32: 3194.72,
  33: 3368.98,
  34: 3543.24,
  35: 3717.49,
  36: 3891.75,
  37: 4066.02,
  38: 4240.28,
  39: 4414.53,
  40: 4646.88,
  41: 4879.22,
  42: 5111.56,
  43: 5343.9,
  44: 5576.25,
  45: 5808.59,
  46: 6040.93,
  47: 6273.27,
  48: 6505.62,
  49: 6737.97,
  50: 6970.31,
  51: 7202.66,
  52: 7435.0,
  53: 7667.34,
  54: 7899.68,
  55: 8132.03,
  56: 8364.37,
  57: 8596.71,
  58: 8829.05,
  59: 9061.4,
  60: 9293.74,
  61: 9526.09,
  62: 9758.44,
  63: 9990.78,
  64: 10223.12,
  65: 10455.46,
  66: 10629.72,
  67: 10803.98,
  68: 10978.24,
  69: 11152.49,
  70: 11326.75,
  71: 11501.01,
  72: 11675.26,
  73: 11849.52,
  74: 12023.79,
  75: 12198.04,
  76: 12372.3,
  77: 12546.56,
  78: 12720.82,
  79: 12895.07,
  80: 13069.33,
  81: 13243.59,
  82: 13417.84,
  83: 13592.1,
  84: 13766.36,
  85: 13940.62,
  86: 14114.87,
  87: 14289.13,
  88: 14463.39,
  89: 14637.64,
  90: 14811.91,
  91: 14986.17,
  92: 15160.43,
  93: 15334.68,
  94: 15508.94,
  95: 15683.2,
  96: 15857.45,
  97: 16031.71,
  98: 16205.97,
  99: 16380.22,
  100: 16554.4,
};

export {
  dannoBiologicoColIndex,
  dannoBiologicoFemmine,
  dannoBiologicoMaschi,
  dannoBiologicoRowIndex,
  dannoRenditaAnnua,
};
