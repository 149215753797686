import React from "react";
import {connect} from "react-redux";
import {Alert, Button, Col, Form, FormGroup, Label, Row} from "reactstrap";
import {
  Field,
  FieldArray,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from "redux-form";

import {dehydrateCap, ICap, ICapEntity, IStateUi} from ".";
import {focusFirstInvalid} from "../helpers/focusFirstInvalid";
import RenderField from "../helpers/RenderField";
import {IconPlus, IconTrash} from "../Icons";
import IconSpinner from "../Icons/IconSpinner";
import {IRootState} from "../redux/reducers";

export interface IIncomeData {
  description: string;
  value: string;
}
export interface IIncomesFormData {
  incomes: IIncomeData[];
}
export interface IIncomesFormErrors {
  incomes: Array<Partial<IIncomeData>>;
}

interface IStateProps {
  atLeastOneIncome: boolean;
}

interface IOwnProps {
  cap: ICap;
  closeIncomes: () => void;
  incomesSubmit: (values: IIncomesFormData, cap: ICapEntity) => Promise<void>;
  ui: IStateUi;
}

type IProps = IStateProps &
  IOwnProps &
  InjectedFormProps<
    IIncomesFormData,
    IOwnProps,
    string | Array<Partial<IIncomeData>>
  >;

const validate = (values: IIncomesFormData) => {
  const errors: IIncomesFormErrors = {incomes: []};

  if (values.incomes) {
    values.incomes.forEach((income, index) => {
      const partialError: Partial<IIncomeData> = {};
      if (!income.description) {
        partialError.description = "La descrizione è obbligatoria";
      }
      if (!income.value) {
        partialError.value = "L'importo è obbligatorio";
      }
      if (partialError.description || partialError.value) {
        errors.incomes[index] = partialError;
      }
    });
  }

  return errors;
};

const MultiInputRender: React.FC<any> = ({fields}) => (
  <>
    {fields.map((name: string, index: number) => (
      <Row key={name}>
        <Col>
          <FormGroup>
            <Field
              component={RenderField}
              type="text"
              name={`${name}.description`}
              placeholder="Descrizione"
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Field
              component={RenderField}
              type="text"
              name={`${name}.value`}
              placeholder="Importo"
            />
          </FormGroup>
        </Col>
        <Col sm="auto">
          <Button
            color="danger"
            outline
            title="Elimina questa fonte di reddito"
            onClick={fields.remove.bind({}, index)}
          >
            <IconTrash title="Elimina questa fonte di reddito" />
          </Button>
        </Col>
      </Row>
    ))}
    <div className="mb-3">
      <Button
        type="button"
        color="info"
        outline
        onClick={fields.push.bind({}, {})}
      >
        <IconPlus title="Aggiungi una fonte di reddito" /> Aggiungi una fonte di
        reddito
      </Button>
    </div>
  </>
);

const IncomesForm: React.FC<IProps> = (props) => {
  const submitWithCap = async (values: IIncomesFormData) => {
    const {
      result,
      entities: {caps},
    } = dehydrateCap(props.cap);
    await props.incomesSubmit(values, caps[result]);
  };
  return (
    <Form onSubmit={props.handleSubmit(submitWithCap)}>
      <p>
        Indicare l'importo netto mensile di ulteriori redditi esterni al lavoro
        se presenti:
      </p>
      {props.atLeastOneIncome && (
        <Row>
          <Col>
            <Label>Descrizione</Label>
          </Col>
          <Col>
            <Label>Importo mensile netto</Label>
          </Col>
          <Col sm="auto">
            <div className="btn-placeholder-x" />
          </Col>
        </Row>
      )}
      <FieldArray component={MultiInputRender} name="incomes" />
      {props.ui.isIncomesFail && (
        <Alert color="danger">{props.ui.incomesError}</Alert>
      )}
      <div>
        {" "}
        <Button
          type="submit"
          color="primary"
          outline
          disabled={props.submitting}
        >
          {props.submitting ? <IconSpinner className="icon-spin" /> : ""}{" "}
          {props.atLeastOneIncome ? (
            <span>Salva e torna all'analisi</span>
          ) : (
            <span>Continua senza aggiungere fonti di reddito</span>
          )}
        </Button>{" "}
        <Button
          type="button"
          color="secondary"
          outline
          onClick={props.closeIncomes}
        >
          Annulla
        </Button>
      </div>
    </Form>
  );
};

const IncomesFormConnected = reduxForm<
  IIncomesFormData,
  IOwnProps & any,
  string | Array<Partial<IIncomeData>>
>({
  form: "incomesForm",
  onSubmitFail: focusFirstInvalid,
  validate,
})(IncomesForm);

const selector = formValueSelector("incomesForm");
export default connect((state: IRootState, ownProps: IOwnProps) => {
  const incomes = selector(state, "incomes");
  const atLeastOneIncome = !!incomes && incomes.length > 0;
  const initialValues = ownProps.cap!.data.incomes;

  return {
    atLeastOneIncome,
    initialValues,
  };
})(IncomesFormConnected);
