import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {IconCheckCircle, IconTimesCircle} from "../Icons";

export const ColumnIddFilter = ({filter, onChange}: any) => (
  <UncontrolledDropdown>
    <DropdownToggle
      caret
      size="sm"
      block
      color="primary"
      outline
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {(!filter || !filter.value || filter.value === "all") && (
        <span>Tutti</span>
      )}
      {filter && filter.value === "available||not-available" && (
        <span>
          <IconCheckCircle color="success" /> Completo
        </span>
      )}
      {filter && filter.value === "not-complete" && (
        <span>
          <IconTimesCircle color="danger" /> Non completo
        </span>
      )}
    </DropdownToggle>
    <DropdownMenu>
      <DropdownItem onClick={onChange.bind({}, "all")}>Tutti</DropdownItem>
      <DropdownItem onClick={onChange.bind({}, "available||not-available")}>
        <IconCheckCircle color="success" /> Completo
      </DropdownItem>
      <DropdownItem onClick={onChange.bind({}, "not-complete")}>
        <IconTimesCircle color="danger" /> Non completo
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);
