import React, {VFC} from "react";
import {FieldError, UseFormMethods} from "react-hook-form";
import {FormFeedback, FormGroup, Input, InputProps, Label} from "reactstrap";
import get from "lodash/get";

interface RhfInputProps extends InputProps {
  errors?: UseFormMethods["errors"];
  formState?: UseFormMethods["formState"];
  id?: string;
  label?: string;
  placeholder?: string;
  name: string;
  register: UseFormMethods["register"];
}

export const RhfInput: VFC<RhfInputProps> = ({
  errors,
  formState,
  id,
  label,
  placeholder,
  name,
  register,
  ...rest
}) => {
  const error = errors && (get(errors, name) as FieldError | undefined);
  const touched =
    !!formState && (get(formState.touched, name) as boolean | undefined);
  const placeholderReal = placeholder || label;
  const realId = id ?? name;

  return (
    <FormGroup>
      {label && <Label for={realId}>{label}</Label>}
      <Input
        name={name}
        id={realId}
        innerRef={register}
        invalid={!!error}
        valid={touched && !error}
        placeholder={placeholderReal}
        {...rest}
      />
      {error && <FormFeedback>{error.message}</FormFeedback>}
    </FormGroup>
  );
};
