import React from "react";
import {connect} from "react-redux";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import beneProposalsRoutes from "../BeneProposals/routes";
import capsRoutes from "../Caps/routes";
import makeRoutes from "../helpers/makeRoutes";
import proposalsRoutes from "../Proposals/routes";
import recommendationsRoutes from "../Recommendation/routes";
import {IRootState} from "../redux/reducers";
import {ICompaniesName, IPermissionName} from "../User/";
import userRoutes from "../User/routes";
import {getUser, getUserCompanies, getUserPermissions} from "../User/selectors";
import {IUser} from "../Users";
import usersRoutes from "../Users/routes";
import appRoutes from "./appRoutes";
import MaintenancePage from "./MaintenancePage";
import NotFoundPage from "./NotFoundPage";

interface IStateProps {
  user: IUser | null;
  permissions: IPermissionName[];
  companies: ICompaniesName[];
}

const mapStateToProps = (state: IRootState) => ({
  companies: getUserCompanies(state),
  permissions: getUserPermissions(state),
  user: getUser(state),
});

const Routes: React.FC<IStateProps> = ({user, companies, permissions}) => {
  useLocation(); // ci serve per causare il refresh della pagina al cambio route
  const isLogged = !!user;
  const maintenanceMode = false; //moment().isAfter(moment("2021-02-23 22:00:00"));

  if (!permissions.includes) {
    console.log(
      "Errore include",
      JSON.stringify(permissions, null, 2),
      permissions
    );
  }

  const homeComponent = () => {
    if (!isLogged) {
      return <Redirect to="/login" />;
    } else if (
      permissions.includes("cap.read") ||
      permissions.includes("cap.contractor-read")
    ) {
      return <Redirect to="/analisi" />;
    } else if (
      permissions.includes("recommendation.read") ||
      permissions.includes("recommendations.read") ||
      permissions.includes("recommendation.contractor-read")
    ) {
      return <Redirect to="/raccomandazioni" />;
    } else if (permissions.includes("users.manage")) {
      return <Redirect to="/users" />;
    } else {
      return <Redirect to="/user/profile" />;
    }
  };

  if (maintenanceMode) {
    return <MaintenancePage />;
  }

  return (
    <Switch>
      <Route exact path="/" component={homeComponent} />
      {makeRoutes({
        routes: appRoutes,
        companies,
        permissions,
        isLogged,
      })}
      {makeRoutes({
        routes: capsRoutes,
        companies,
        permissions,
        isLogged,
      })}
      {makeRoutes({
        routes: userRoutes,
        companies,
        permissions,
        isLogged,
      })}
      {makeRoutes({
        routes: usersRoutes,
        companies,
        permissions,
        isLogged,
      })}
      {makeRoutes({
        routes: recommendationsRoutes,
        companies,
        permissions,
        isLogged,
      })}
      {makeRoutes({
        routes: proposalsRoutes,
        companies,
        permissions,
        isLogged,
      })}
      {makeRoutes({
        routes: beneProposalsRoutes,
        companies,
        permissions,
        isLogged,
      })}
      <Route component={NotFoundPage} />
    </Switch>
  );
};

export default connect(mapStateToProps)(Routes);
