import React from "react";
import {Collapse} from "reactstrap";

import {IconBan, IconChevronUp} from "../../../Icons";
import UsersTable from "../shared/UsersTable";

interface IState {
  isUsersKoOpen: boolean;
  isUsersOkOpen: boolean;
}

interface IProps {
  report: Array<{[Key: string]: string}>;
}

class UsersReport extends React.Component<IProps, IState> {
  public state = {
    isUsersKoOpen: true,
    isUsersOkOpen: false,
  };

  public toggleUsersOk = () => {
    this.setState((prevState) => ({isUsersOkOpen: !prevState.isUsersOkOpen}));
  };

  public toggleUsersKo = () => {
    this.setState((prevState) => ({isUsersKoOpen: !prevState.isUsersKoOpen}));
  };

  public render() {
    const usersOk = this.props.report.filter(
      (userData) => userData.level === "success"
    );
    const usersKo = this.props.report.filter(
      (userData) => userData.level !== "success"
    );

    return (
      <React.Fragment>
        <div className="list-group mb-3">
          <button
            onClick={this.toggleUsersOk}
            className={`list-group-item list-group-item-${
              usersOk.length > 0 ? "" : "no-"
            }action list-group-item-primary text-left`}
          >
            {usersOk.length > 0 ? (
              <IconChevronUp
                className={`mr-3 ${
                  this.state.isUsersOkOpen ? "icon-rotate-0" : "icon-rotate-180"
                }`}
              />
            ) : (
              <IconBan className="mr-3" />
            )}
            {usersOk.length}{" "}
            {usersOk.length === 1
              ? "Advisor verrà disattivato"
              : "Advisor verranno disattivati"}{" "}
          </button>
        </div>
        {usersOk.length > 0 && (
          <Collapse isOpen={this.state.isUsersOkOpen}>
            <UsersTable usersList={usersOk} />
          </Collapse>
        )}
        {usersKo.length > 0 && (
          <div className="list-group mb-3">
            <button
              onClick={this.toggleUsersKo}
              className="list-group-item list-group-item-action list-group-item-primary text-left"
            >
              <IconChevronUp
                className={
                  "mr-3 " +
                  (this.state.isUsersKoOpen
                    ? "icon-rotate-0"
                    : "icon-rotate-180")
                }
              />
              {usersKo.length}{" "}
              {usersKo.length === 1
                ? "Advisor contiene degli errori e non verrà disattivato"
                : "Advisor contengono degli errori e non verranno disattivati"}
            </button>
          </div>
        )}
        {usersKo.length > 0 && (
          <Collapse isOpen={this.state.isUsersKoOpen}>
            <UsersTable usersList={usersKo} />
          </Collapse>
        )}
      </React.Fragment>
    );
  }
}

export default UsersReport;
