import moment from "moment";
import React from "react";
import {Progress, Table} from "reactstrap";
import FormatNumber from "../helpers/FormatNumber";
import {retirementEstimate} from "../helpers/retirementTables";
import {IUser} from "../Users";
import {IEmploymentFormData} from "./EmploymentForm";

interface IProps {
  employments: IEmploymentFormData;
  contractor: IUser;
}

const RetirementDetails: React.FC<IProps> = (props) => {
  const job = props.employments.currentEmployment;
  if (
    job &&
    job.tipologia &&
    (job.tipologia === "pensione" || job.tipologia === "disoccupato")
  ) {
    return null;
  }

  const redditoLordo = job
    ? job.reddito
      ? parseInt(job.reddito, 10)
      : job.redditoNormalizzato * 12 * 1.43
    : 0;

  const anniVersati =
    (job && job.dal && moment().year() - parseInt(job.dal, 10)) || 20;

  const categoria = job
    ? job.cassa
      ? "cassa"
      : job.tipologia === "autonomo"
      ? job.categoria
      : job.tipologia
    : "dipendente";

  const retirementData = retirementEstimate({
    anniVersati,
    categoria,
    crescita: (job && job.previsione) || "low",
    dataDiNascita: moment(props.contractor.birthday, "DD-MM-YYYY"),
    redditoLordo,
  });
  const gapPerc = Math.ceil(
    ((retirementData.netto.ultimoStipendio -
      retirementData.netto.pensioneMensile) /
      retirementData.netto.ultimoStipendio) *
      100
  );

  return (
    <div>
      <Table className="table-borderless" size="sm">
        <tbody>
          <tr>
            <td>
              <p>
                andrà in pensione nel {retirementData.annoDiPensionamento}
                <br />a {retirementData.etaPensionabile.years} anni e{" "}
                {retirementData.etaPensionabile.months} mesi (pensione di
                vecchiaia)
              </p>
            </td>
            <td>
              <Progress value="100" className="retirement-bar">
                100%
              </Progress>
            </td>
            <td>
              Ultimo stipendio (netto):
              <br />
              <FormatNumber>
                {retirementData.netto.ultimoStipendio}
              </FormatNumber>{" "}
              €
            </td>
          </tr>
          <tr>
            <td>
              <p>
                Ultimo reddito da lavoro (netto): €{" "}
                <FormatNumber>
                  {retirementData.netto.ultimoStipendio * 12}
                </FormatNumber>{" "}
                annui
                <br />
                Pensione pubblica (netto): €{" "}
                <FormatNumber>
                  {retirementData.netto.pensioneMensile * 12}
                </FormatNumber>{" "}
                annui
              </p>
            </td>
            <td>
              <Progress multi className="retirement-bar">
                <Progress bar color="info" value={100 - gapPerc}>
                  {100 - gapPerc}%
                </Progress>
                <Progress bar color="secondary" value={gapPerc} />
              </Progress>
            </td>
            <td>
              Pensione pubblica (netto)
              <br />
              <FormatNumber>
                {retirementData.netto.pensioneMensile}
              </FormatNumber>{" "}
              €
            </td>
          </tr>
          <tr>
            <td>
              <p className="text-danger font-weight-bold text-uppercase">
                Gap da colmare: €{" "}
                <FormatNumber>{-retirementData.netto.gapAnnuo}</FormatNumber>
              </p>
            </td>
            <td>
              <Progress multi className="retirement-bar">
                <Progress bar color="drawer" value={100 - gapPerc} />
                <Progress bar color="danger" value={gapPerc}>
                  {gapPerc} %
                </Progress>
              </Progress>
            </td>
            <td className="text-danger font-weight-bold text-uppercase">
              Gap
              <br />
              <FormatNumber>{-retirementData.netto.gapMensile}</FormatNumber> €
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default RetirementDetails;
