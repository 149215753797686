import React from "react";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";

import {IconIncomes, IconTimes} from "../Icons";
import {ICap, ICapEntity, IStateUi, NewCapModalTypes} from "./";
import IncomesForm, {IIncomesFormData} from "./IncomesForm";

interface IProps {
  closeIncomes: () => void;
  ui: IStateUi;

  cap: ICap;
  incomesSubmit: (values: IIncomesFormData, cap: ICapEntity) => Promise<void>;
}

const IncomesModal: React.FC<IProps> = (props) => (
  <Modal
    isOpen={props.ui.isModalOpen[NewCapModalTypes.Incomes]}
    toggle={props.closeIncomes}
    backdrop="static"
    keyboard={false}
    className="modal-form"
  >
    <ModalHeader
      toggle={props.closeIncomes}
      close={
        <Button size="sm" className="close-modal" onClick={props.closeIncomes}>
          <IconTimes size={1.25} className="icon-border-2 rounded-circle p-1" />
        </Button>
      }
    >
      <IconIncomes size={3} className="icon-border-2 rounded-circle p-2" />
      Altri redditi
    </ModalHeader>
    <ModalBody>
      <IncomesForm {...props} />
    </ModalBody>
  </Modal>
);

export default IncomesModal;
