import React from "react";
import {Alert, Button, Col, Row} from "reactstrap";
import {IconCsv} from "../../../Icons";

const Instructions = () => (
  <>
    <p>
      Per la creazioni di nuovi Advisor tramite file CSV, è richiesto un file
      che rispetti i seguenti criteri:
      <Button
        tag="a"
        href="/csv-examples/create_bulk_example.csv"
        download
        size="sm"
        color="primary"
        outline
        className="float-right"
        target="_self"
      >
        <IconCsv />
        File di esempio
      </Button>
    </p>
    <Row tag="dl">
      <Col tag="dt" md={3} lg={2}>
        Campi obbligatori
      </Col>
      <Col tag="dd" md={9} lg={10}>
        I seguenti campi devono essere presenti obbligatoriamente per ogni
        Advisor: codice fiscale, codice RUI, data iscrizione RUI, Nome, Cognome
        e indirizzo email.
      </Col>
      <Col tag="dt" md={3} lg={2}>
        Campi univoci
      </Col>
      <Col tag="dd" md={9} lg={10}>
        I seguenti campi devono essere univoci nel file caricato e non devono
        essere già presenti nel database: codice fiscale, nome utente, codice
        RUI e indirizzo email.
      </Col>
      <Col tag="dt" md={3} lg={2}>
        Date
      </Col>
      <Col tag="dd" md={9} lg={10}>
        Le date vanno inserite nel formato aaaa-mm-gg o aaaa-mm-gg hh:mm:ss.
      </Col>
    </Row>
    <Alert color="warning">
      Ogni Advisor inserito riceverà una email con le istruzioni per
      l'attivazione dell'account. Accertarsi che le caselle email siano attive!
    </Alert>
  </>
);

export default Instructions;
