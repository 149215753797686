import React from "react";
import {connect} from "react-redux";
import {Alert, Button, Form, FormGroup} from "reactstrap";
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import {ICap, ICapEntity, IStateUi} from ".";
import {floatFormatter} from "../helpers/formatters";
import {positiveFloatNormalizer} from "../helpers/normalizers";
import {floatParser} from "../helpers/parsers";
import RenderField from "../helpers/RenderField";
import IconSpinner from "../Icons/IconSpinner";
import {IRootState} from "../redux/reducers";
import {dehydrateCap} from "./types";

export interface ISavingFormData {
  saving: string;
  gruzzoletto: string;
}

interface IOwnProps {
  cap: ICap;
  closeSaving: () => void;
  savingSubmit: (values: ISavingFormData, cap: ICapEntity) => Promise<void>;
  ui: IStateUi;
}

type IProps = InjectedFormProps<ISavingFormData, IOwnProps> & IOwnProps;

const SavingForm: React.FC<IProps> = (props) => {
  const submitWithCap = async (values: ISavingFormData) => {
    const {
      result,
      entities: {caps},
    } = dehydrateCap(props.cap);
    await props.savingSubmit(values, caps[result]);
  };
  return (
    <Form onSubmit={props.handleSubmit(submitWithCap)}>
      <p>
        Quanto è il tuo risparmio netto mensile? (Esclusi eventuali polizze,
        accantonamenti, investimenti , ecc.)
      </p>
      <FormGroup key="saving">
        <Field
          id="saving"
          name="saving"
          type="text"
          parse={floatParser}
          format={floatFormatter}
          normalize={positiveFloatNormalizer}
          component={RenderField}
          placeholder={`Risparmio mensile`}
        />
      </FormGroup>
      <p>Liquidità disponibile</p>
      <FormGroup>
        <Field
          id="gruzzoletto"
          name="gruzzoletto"
          type="text"
          parse={floatParser}
          format={floatFormatter}
          normalize={positiveFloatNormalizer}
          component={RenderField}
          placeholder="Valore del capitale"
        />
      </FormGroup>
      {props.ui.isSavingFail && (
        <Alert color="danger">{props.ui.savingError}</Alert>
      )}
      <Button type="submit" color="primary" outline disabled={props.submitting}>
        {props.submitting ? <IconSpinner className="icon-spin" /> : ""} Salva e
        torna all'analisi
      </Button>{" "}
      <Button
        type="button"
        color="secondary"
        outline
        onClick={props.closeSaving}
      >
        Annulla
      </Button>
    </Form>
  );
};

const SavingFormConnected = reduxForm<ISavingFormData, IOwnProps>({
  form: "savingForm",
})(SavingForm);

export default connect((state: IRootState, ownProps: IOwnProps) => {
  const initialValues = ownProps.cap?.data.saving;

  return {
    initialValues,
  };
})(SavingFormConnected);
