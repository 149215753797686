import moment from "moment";
import React from "react";
import {connect} from "react-redux";
import {Table} from "reactstrap";
import {formValueSelector} from "redux-form";
import FormatNumber from "../helpers/FormatNumber";
import {IUser} from "../Users";
import {IEmploymentFormData} from "./EmploymentForm";

interface IProps {
  employments: IEmploymentFormData;
  contractor: IUser;
  invaliditaPermanenteValue: number;
}

const InpsTable: React.FC<IProps> = (props) => {
  // Variabili calcolo inps
  const redditoNettoAnnuo =
    (props.employments &&
      props.employments.currentEmployment.redditoNormalizzato) ||
    0;

  const redditoLordoAnnuo =
    props.employments && props.employments.currentEmployment.reddito;

  const oggi = moment();
  const eta = Math.floor(
    moment.duration(oggi.diff(props.contractor.birthday)).as("years")
  );

  const job = props.employments.currentEmployment;

  const anniVersati =
    (job &&
      job.dal &&
      moment().year() - (parseInt(job.dal, 10) || moment().year())) ||
    0;

  let coefficienteContributo = 0;
  if (
    (job && job.tipologia === "dipendente") ||
    (job && job.tipologia === "subordinato")
  ) {
    coefficienteContributo = 33 / 100;
  } else if (job && job.tipologia === "autonomo") {
    if (
      (job && job.categoria === "artigiano") ||
      (job && job.categoria === "commerciante")
    ) {
      coefficienteContributo = 24 / 100;
    } else {
      coefficienteContributo = 26 / 100;
    }

    if (job && job.cassa) {
      coefficienteContributo = 15 / 100;
    }
  }

  const coefficienteInps = 5;

  let contributiVersatiAnno: number = 0;
  let contributiVersatiTotali: number;
  let contributiFigurativi: number = 0;
  let contributiTotali: number;
  let assegnoInvalidita67: number;
  let assegnoInvalidita100: number;
  let assegnoReversibilita: number;

  // Calcolo inps 67
  if (
    (job && job.tipologia === "dipendente") ||
    (job && job.tipologia === "subordinato")
  ) {
    contributiVersatiAnno = redditoNettoAnnuo * 12 * coefficienteContributo;
  } else if (job && job.tipologia === "autonomo") {
    contributiVersatiAnno =
      parseFloat(redditoLordoAnnuo || "0") * coefficienteContributo;
  }

  contributiVersatiTotali = contributiVersatiAnno * anniVersati;
  contributiTotali = contributiFigurativi + contributiVersatiTotali;
  assegnoInvalidita67 = (contributiTotali * coefficienteInps) / 100;
  contributiFigurativi = contributiVersatiAnno * (60 - eta);
  contributiTotali = contributiFigurativi + contributiVersatiTotali;
  assegnoInvalidita100 = (contributiTotali * coefficienteInps) / 100;
  assegnoReversibilita = (assegnoInvalidita67 * 60) / 100;

  return (
    <div>
      <Table className="mt-3 border text-right" striped responsive>
        <thead>
          <tr>
            <th className="text-left">Indennità permanente</th>
            <th>&#60; di 67%</th>
            <th>da 67% a 99%</th>
            <th>100%</th>
            <th>Reversibilità in caso di decesso</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th className="text-left" scope="row">
              Annuale
            </th>
            <td className="text-danger">
              <span className="text-nowrap font-weight-bold">
                <FormatNumber>0</FormatNumber> €
              </span>
            </td>
            {assegnoInvalidita67 === 0 ? (
              <td className="text-danger">
                <span className="text-nowrap font-weight-bold">
                  <FormatNumber>0</FormatNumber> €
                </span>
              </td>
            ) : (
              <td>
                <FormatNumber>{assegnoInvalidita67.toFixed(2)}</FormatNumber> €
              </td>
            )}
            {assegnoInvalidita100 === 0 ? (
              <td className="text-danger">
                <span className="text-nowrap font-weight-bold">
                  <FormatNumber>0</FormatNumber> €
                </span>
              </td>
            ) : (
              <td>
                <FormatNumber>{assegnoInvalidita100.toFixed(2)}</FormatNumber> €
              </td>
            )}
            {assegnoReversibilita === 0 ? (
              <td className="text-danger">
                <span className="text-nowrap font-weight-bold">
                  <FormatNumber>0</FormatNumber> €
                </span>
              </td>
            ) : (
              <td>
                <FormatNumber>{assegnoReversibilita.toFixed(2)}</FormatNumber> €
              </td>
            )}
          </tr>
          <tr>
            <th className="text-left" scope="row">
              Mensile
            </th>
            <td className="text-danger">
              <span className="text-nowrap font-weight-bold">
                <FormatNumber>0</FormatNumber> €
              </span>
            </td>
            {assegnoInvalidita67 === 0 ? (
              <td className="text-danger">
                <span className="text-nowrap font-weight-bold">
                  <FormatNumber>0</FormatNumber> €
                </span>
              </td>
            ) : (
              <td>
                <FormatNumber>
                  {(assegnoInvalidita67 / 12).toFixed(2)}
                </FormatNumber>{" "}
                €
              </td>
            )}
            {assegnoInvalidita100 === 0 ? (
              <td className="text-danger">
                <span className="text-nowrap font-weight-bold">
                  <FormatNumber>0</FormatNumber> €
                </span>
              </td>
            ) : (
              <td>
                <FormatNumber>
                  {(assegnoInvalidita100 / 12).toFixed(2)}
                </FormatNumber>{" "}
                €
              </td>
            )}
            {assegnoReversibilita === 0 ? (
              <td className="text-danger">
                <span className="text-nowrap font-weight-bold">
                  <FormatNumber>0</FormatNumber> €
                </span>
              </td>
            ) : (
              <td>
                <FormatNumber>
                  {(assegnoReversibilita / 12).toFixed(2)}
                </FormatNumber>{" "}
                €
              </td>
            )}
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

// export default InpsTable;

const selector = formValueSelector("inailTable");
export default connect((state) => {
  const invaliditaPermanenteValue = selector(state, "invaliditaPermanente");
  return {
    invaliditaPermanenteValue,
  };
})(InpsTable);
