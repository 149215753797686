import moment from "moment";
import React from "react";
import {
  Button,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Input,
  Label,
  UncontrolledDropdown,
} from "reactstrap";
import {birthdayFormatter} from "./formatters";

interface IState {
  from?: string;
  to?: string;
}

interface IProps {
  filter: any;
  onChange: any;
}

class ColumnDateFilter extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.dateFilter = this.dateFilter.bind(this);
    this.dateFilterFrom = this.dateFilterFrom.bind(this);
    this.dateFilterTo = this.dateFilterTo.bind(this);

    const result = this.props.filter
      ? this.props.filter.value.split(",")
      : ["", ""];
    this.state = {
      from: result[0],
      to: result[1],
    };
  }

  public dateFilterFrom(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({from: birthdayFormatter(event.target.value)});
  }

  public dateFilterTo(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({to: birthdayFormatter(event.target.value)});
  }

  public dateFilter() {
    const dateStart = `${this.state.from},${this.state.to}`;
    this.props.onChange(dateStart);
  }

  public render() {
    const result = this.props.filter && this.props.filter.value.split(",");
    let filtro = "Dal - Al";
    if (result) {
      if (result[0] && result[1]) {
        filtro = `${moment(result[0], "DD-MM-YYYY").format("L")} - ${moment(
          result[1],
          "DD-MM-YYYY"
        ).format("L")}`;
      } else if (!result[0] && !result[1]) {
        filtro = `Dal - al`;
      } else if (!result[0] && result[1]) {
        filtro = `Fino al ${moment(result[1], "DD-MM-YYYY").format("L")}`;
      } else if (result[0] && !result[1]) {
        filtro = `Dal ${moment(result[0], "DD-MM-YYYY").format("L")}`;
      }
    }

    return (
      <UncontrolledDropdown>
        <DropdownToggle
          caret
          size="sm"
          block
          color="primary"
          outline
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {filtro}
        </DropdownToggle>
        <DropdownMenu>
          <FormGroup className="text-primary mt-3 mb-3 mr-3 ml-3">
            <Label for="from">Dal:</Label>
            <Input
              type="text"
              id="from"
              name="from"
              onChange={this.dateFilterFrom}
              value={this.state.from}
              placeholder="Dal (gg/mm/aaaa)"
            />
          </FormGroup>
          <FormGroup className="text-primary mt-3 mb-3 mr-3 ml-3">
            <Label for="from">Al:</Label>
            <Input
              type="text"
              id="to"
              name="to"
              onChange={this.dateFilterTo}
              value={this.state.to}
              placeholder="Al (gg/mm/aaaa)"
            />
          </FormGroup>
          <div className="text-center">
            <Button
              type="submit"
              color="primary"
              outline
              onClick={this.dateFilter}
            >
              Filtra per data
            </Button>
          </div>
        </DropdownMenu>
      </UncontrolledDropdown>
    );
  }
}

export default ColumnDateFilter;
