import moment from "moment";
import {schema} from "normalizr";
import {capSchema, ICapDB, ICapsEntities, IUserSnapshot} from "../Caps";
import {entityUtilityFactory} from "../helpers/entityUtilityFactory";
import {IUserDB, IUsersEntities, userSchema} from "../Users";
import {IRCFormData} from "./shared/ChoseProductLevel/Rc";
import {IBeneLevel, IBeneType} from "./shared/NewBeneProposalForm";

export interface IBeneProposalDB {
  cap: ICapDB;
  contractor: IUserDB;
  date: string;
  id: string;
  jsonData: string;
  jsonProduct: string;
  jsonUser: string;
  status: string;
  user: IUserDB;
  declarationConsistencyEsignId: string;
  jsonDatesSendEmail: string;
}

export interface IBeneProposalEntity {
  cap: string;
  contractor: string;
  date: string;
  id: string;
  jsonData: string;
  jsonProduct: string;
  jsonUser: string;
  status: string;
  user: string;
  declarationConsistencyEsignId: string;
  jsonDatesSendEmail: string;
}

export interface IBeneProposal {
  cap: string;
  contractor: string;
  date: moment.Moment;
  id: string;
  data: IDataSnapshot;
  product: IProductSnapshot;
  userSnapshot: IUserSnapshot;
  status: string;
  user: string;
  declarationConsistencyEsignId: string;
  datesSendEmail: IDatesSendEmailSnapshot;
}

export type IDatesSendEmailSnapshot = Array<{date: string}>;

export type IDataSnapshot = Array<{
  outputAccessoProdotto?: {
    uid: string | null;
    outcome?: {
      messages: string[] | null;
    };
  };
  date: string;
}>;

interface IProductSnapshot {
  type: IBeneType;
  level: IBeneLevel;
  rc: IRCFormData;
}

export interface IBeneProposalParams {
  limit: number;
  offset: number;
  contractor?: string;
  date?: string;
  sort?: string;
  user?: string;
}

export type BohFiles = Array<{
  emailSentDate: string;
}>;

const beneProposalSchema = new schema.Entity("beneProposals", {
  cap: capSchema,
  contractor: userSchema,
  user: userSchema,
});

export interface IBeneProposalsEntities {
  [key: string]: IBeneProposalEntity;
}

export type EntityShape = {
  beneProposals: IBeneProposalsEntities;
  caps: ICapsEntities;
  users: IUsersEntities;
};

const beneProposalParse = (beneProposalGeneric: IBeneProposalEntity) => {
  const {
    date,
    jsonData,
    jsonProduct,
    jsonUser,
    jsonDatesSendEmail,
    ...rest
  } = beneProposalGeneric;
  const beneProposal: IBeneProposal = {
    ...rest,
    date: moment(date),
    data: JSON.parse(jsonData) as IDataSnapshot,
    product: JSON.parse(jsonProduct) as IProductSnapshot,
    userSnapshot: JSON.parse(jsonUser) as IUserSnapshot,
    datesSendEmail: JSON.parse(jsonDatesSendEmail) as IDatesSendEmailSnapshot,
  };

  return beneProposal;
};

const beneProposalStringify = (beneProposalGeneric: IBeneProposal) => {
  const {
    date,
    data,
    product,
    userSnapshot,
    datesSendEmail,
    ...rest
  } = beneProposalGeneric;
  const beneProposal: IBeneProposalEntity = {
    ...rest,
    date: date.format(),
    jsonData: JSON.stringify(data),
    jsonProduct: JSON.stringify(product),
    jsonUser: JSON.stringify(userSnapshot),
    jsonDatesSendEmail: JSON.stringify(datesSendEmail),
  };

  return beneProposal;
};

export const beneProposalEntityUtility = entityUtilityFactory<
  IBeneProposalEntity,
  IBeneProposalDB,
  IBeneProposal,
  EntityShape
>({
  schema: beneProposalSchema,
  deserialize: beneProposalParse,
  serialize: beneProposalStringify,
});
