import {DeepReadonly} from "utility-types/dist/mapped-types";
import {IAppThunkActionStates} from "../types/thunk";
import {actions as userActions, actionTypes as userActionTypes} from "../User";
import {actions, ActionTypes} from "./actions";

export type ThunkActionsNames = "create";

export type IState = DeepReadonly<{
  thunkActionsStates: {
    [key in ThunkActionsNames]?: {[key: string]: IAppThunkActionStates};
  };
}>;

const ticketsInitialState = {
  thunkActionsStates: {},
};

export const reducer = (
  state: IState = ticketsInitialState,
  action: actions | userActions
): IState => {
  switch (action.type) {
    case userActionTypes.LOGOUT_SUCCESS:
      return {
        ...ticketsInitialState,
      };

    case ActionTypes.SET_IS_PENDING:
      return {
        ...state,
        thunkActionsStates: {
          ...state.thunkActionsStates,
          [action.payload.thunkActionsName]: {
            ...state.thunkActionsStates[action.payload.thunkActionsName],
            [action.payload.id ?? "DEFAULT"]: {
              isPending: true,
              isSuccess: false,
              isFail: false,
              error: undefined,
            },
          },
        },
      };
    case ActionTypes.SET_IS_SUCCESS:
      return {
        ...state,
        thunkActionsStates: {
          ...state.thunkActionsStates,
          [action.payload.thunkActionsName]: {
            ...state.thunkActionsStates[action.payload.thunkActionsName],
            [action.payload.id ?? "DEFAULT"]: {
              isPending: false,
              isSuccess: true,
              isFail: false,
              error: undefined,
            },
          },
        },
      };
    case ActionTypes.SET_IS_FAIL:
      return {
        ...state,
        thunkActionsStates: {
          ...state.thunkActionsStates,
          [action.payload.thunkActionsName]: {
            ...state.thunkActionsStates[action.payload.thunkActionsName],
            [action.payload.id ?? "DEFAULT"]: {
              isPending: false,
              isSuccess: false,
              isFail: true,
              error:
                action.error.message ?? "Errore sconosciuto, riprova più tardi",
            },
          },
        },
      };
    case ActionTypes.RESET_STATE:
      return {
        ...state,
        thunkActionsStates: {
          ...state.thunkActionsStates,
          [action.payload.thunkActionsName]: {
            ...state.thunkActionsStates[action.payload.thunkActionsName],
            [action.payload.id ?? "DEFAULT"]: {
              isPending: false,
              isSuccess: false,
              isFail: false,
              error: undefined,
            },
          },
        },
      };

    default:
      return state;
  }
};

export default reducer;
