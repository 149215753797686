import React, {useEffect, VFC} from "react";
import {useFormContext} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {Alert, Button, Card, CardTitle, Table} from "reactstrap";
import {actions as eSignActions} from "../../../ESign";
import {PDFTypes} from "../../../ESign/types";
import {obj2queryString} from "../../../helpers/obj2queryString";
import {
  IconCheckmark,
  IconDanger,
  IconDownload,
  IconESign,
  IconEye,
  IconInfoCircle,
} from "../../../Icons";
import {actions, selectors} from "../../slice";
import {IBeneProposalViewRouteParams} from "../../View";

interface ProposalDocumentsProps {
  hasESign: boolean;
  isView: boolean;
}

export const ProposalDocuments: VFC<ProposalDocumentsProps> = ({
  hasESign,
  isView,
}) => {
  const dispatch = useDispatch();
  const {watch} = useFormContext();
  const {id} = useParams<IBeneProposalViewRouteParams>();

  const signBeneProposal = () => {
    dispatch(eSignActions.openModal(PDFTypes.beneProposal, "0"));
  };
  const eSignIdError = useSelector(selectors.selectESignIdError);

  useEffect(() => {
    dispatch(
      actions.congruenceFileCreated({path: undefined, eSignId: undefined})
    );
  }, [dispatch]);

  const urlParams = isView
    ? {id}
    : {
        type: watch("type"),
        ...(watch("rc.profType") && {profType: watch("rc.profType")}),
      };
  const urlQueryString = obj2queryString(urlParams);
  const docButtonsContent = isView ? (
    <>
      <IconDownload className="mr-2" />
      Scarica
    </>
  ) : (
    <>
      <IconEye className="mr-2" />
      Anteprima
    </>
  );

  return (
    <Card body className="mb-3">
      <CardTitle tag="h5">Documenti proposta</CardTitle>
      <Table>
        <thead>
          <tr>
            <th>Documento</th>
            {isView ? <th>Download</th> : <th>Anteprima</th>}
            <th>Firma</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Dichiarazione di coerenza</td>
            <td>
              <Button
                color="primary"
                outline
                type="button"
                target="_blank"
                rel="noopener noreferrer"
                href={`${process.env.REACT_APP_API_BASE_URL}bene-proposals/get-pdf-dichiarazione-coerenza?${urlQueryString}`}
              >
                {docButtonsContent}
              </Button>
            </td>
            <td>
              {hasESign ? (
                <Button color="primary" outline type="button" disabled>
                  <IconCheckmark color="success" className="mr-2" />
                  Firmata
                </Button>
              ) : (
                <Button
                  color="primary"
                  outline
                  type="button"
                  onClick={signBeneProposal}
                  className="text-nowrap"
                >
                  <IconESign className="mr-2" />
                  Firma
                </Button>
              )}
            </td>
          </tr>
          <tr>
            <td>Allegato 4</td>
            <td>
              <Button
                color="primary"
                outline
                type="button"
                target="_blank"
                href={`${process.env.REACT_APP_API_BASE_URL}bene-proposals/get-pdf-allegato4?${urlQueryString}`}
              >
                {docButtonsContent}
              </Button>
            </td>
            <td>
              <span className="text-nowrap">Non richiesta</span>
            </td>
          </tr>
          <tr>
            <td>Set informativo</td>
            <td>
              <p>
                <Button
                  color="primary"
                  outline
                  type="button"
                  target="_blank"
                  href={`${process.env.REACT_APP_API_BASE_URL}bene-proposals/get-set-informativo?${urlQueryString}`}
                  disabled={watch("type") === "rc" && !watch("rc.profType")}
                >
                  {docButtonsContent}
                </Button>
              </p>
              {watch("type") === "rc" && !watch("rc.profType") && (
                <span className="text-info">
                  <IconInfoCircle /> Per vedere l'anteprima del set informativo
                  selezionare la tipologia professionale
                </span>
              )}
            </td>
            <td>
              <span className="text-nowrap">Non richiesta</span>
            </td>
          </tr>
        </tbody>
      </Table>
      {hasESign ? (
        <Alert color="success" className="mb-0">
          Dichiarazione di coerenza firmata
        </Alert>
      ) : (
        <>
          <Alert color={eSignIdError ? "danger" : "info"} className="mb-0">
            {eSignIdError && <IconDanger className="mr-2" />} Prima di procedere
            occorre firmare il documento di Dichiarazione di coerenza.
          </Alert>
        </>
      )}
    </Card>
  );
};
