import React from "react";
import {Button, Card, Col, FormGroup, Label, Row} from "reactstrap";
import {Dispatch} from "redux";
import {change, Field, WrappedFieldArrayProps} from "redux-form";
import {floatFormatter} from "../../helpers/formatters";
import {
  integerNormalizer,
  positiveFloatNormalizer,
} from "../../helpers/normalizers";
import {floatParser} from "../../helpers/parsers";
import RenderField from "../../helpers/RenderField";
import RenderMultiField from "../../helpers/RenderMultiField";
import {IconPlus, IconTrash} from "../../Icons";
import {
  dehydrateCap,
  ICap,
  ICapEntity,
  IInvestmentCompany,
  IInvestmentProduct,
} from "../types";
import {IHasIBIPsData, IHasIBIPsFormData} from "./HasIBIPsForm";

interface IProps {
  companies: IInvestmentCompany[];
  dispatch: Dispatch;
  products: IInvestmentProduct[];
  cap: ICap;
  disabled?: boolean;
  hasIBIPsSubmit?: (
    values: IHasIBIPsFormData | undefined,
    cap: ICapEntity
  ) => void;
  hideForm: () => void;
  IBIPsValues: IHasIBIPsData[];
}

const companyChangeHandler = (
  index: number,
  dispatch: Dispatch,
  event: React.ChangeEvent<HTMLInputElement>
) => {
  dispatch(
    change(
      "hasIbipsForm",
      `IBIPs[${index}].productId`,
      event.currentTarget.value === "other" ? "other" : ""
    )
  );
};

const recurrenceChangeHandle = (
  index: number,
  dispatch: Dispatch,
  event: React.ChangeEvent,
  value: string
) => {
  if (value === "unique") {
    // cancello input recurrent
    dispatch(change("hasIbipsForm", `IBIPs[${index}].recurrentPremium`, ""));
  } else if (value === "recurrent") {
    // cancello input unique
    dispatch(change("hasIbipsForm", `IBIPs[${index}].uniquePremium`, ""));
  }
};

const HasIBIPsProductsArray: React.FC<
  IProps & WrappedFieldArrayProps<IHasIBIPsFormData>
> = ({
  cap,
  companies,
  disabled,
  dispatch,
  fields,
  hasIBIPsSubmit,
  hideForm,
  IBIPsValues,
  products,
}) => {
  const removeFiled = async (index: number) => {
    fields.remove(index);
    if (fields.length <= 1) {
      const {
        result,
        entities: {caps},
      } = dehydrateCap(cap);
      await hasIBIPsSubmit?.(undefined, caps[result]);
      hideForm();
    }
  };

  return (
    <>
      {fields.map((name: string, index: number) => (
        <Card body key={name} className="bg-drawer border-primary mb-3">
          <Row>
            <Col>
              <Row>
                <Col xs={6}>
                  <FormGroup>
                    <Label for={`${name}.company`}>Compagnia</Label>
                    <Row>
                      <Col>
                        <Field
                          component={RenderField}
                          disabled={disabled}
                          id={`${name}.companyId`}
                          name={`${name}.companyId`}
                          onChange={companyChangeHandler.bind(
                            {},
                            index,
                            dispatch
                          )}
                          type="select"
                        >
                          <option value="">Seleziona compagnia</option>
                          {companies.map((company) => (
                            <option key={company.id} value={company.id}>
                              {company.name}
                            </option>
                          ))}
                          <option value="other">Altro</option>
                        </Field>
                      </Col>
                      {IBIPsValues && IBIPsValues[index].companyId === "other" && (
                        <Col>
                          <Field
                            component={RenderField}
                            disabled={disabled}
                            name={`${name}.companyOther`}
                            placeholder="Specifica la compagnia"
                            type="text"
                          />
                        </Col>
                      )}
                    </Row>
                  </FormGroup>
                </Col>
                <Col xs={6}>
                  <FormGroup>
                    <Label for={`${name}.product`}>Prodotto</Label>
                    <Row>
                      <Col>
                        <Field
                          component={RenderField}
                          disabled={
                            disabled ||
                            (IBIPsValues &&
                              (IBIPsValues[index].companyId === "" ||
                                IBIPsValues[index].companyId === "other"))
                          }
                          id={`${name}.productId`}
                          name={`${name}.productId`}
                          type="select"
                        >
                          <option value="">Seleziona prodotto</option>
                          {products
                            .filter(
                              (product) =>
                                IBIPsValues &&
                                product.company.id.toString() ===
                                  IBIPsValues[index].companyId
                            )
                            .map((product) => (
                              <option key={product.id} value={product.id}>
                                {product.name}
                              </option>
                            ))}
                          <option value="other">Altro</option>
                        </Field>
                      </Col>
                      {IBIPsValues && IBIPsValues[index].productId === "other" && (
                        <Col>
                          <Field
                            component={RenderField}
                            disabled={disabled}
                            name={`${name}.productOther`}
                            placeholder="Specifica il prodotto"
                            type="text"
                          />
                        </Col>
                      )}
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label for={`${name}.subscriptionYear`}>
                      Anno di sottoscrizione
                    </Label>
                    <Field
                      component={RenderField}
                      disabled={
                        disabled ||
                        (IBIPsValues && IBIPsValues[index].productId === "")
                      }
                      id={`${name}.subscriptionYear`}
                      name={`${name}.subscriptionYear`}
                      placeholder="Anno di sottoscrizione"
                      normalize={integerNormalizer}
                      maxLength={4}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for={`${name}.duration`}>Durata (anni)</Label>
                    <Field
                      component={RenderField}
                      disabled={
                        disabled ||
                        (IBIPsValues && IBIPsValues[index].productId === "")
                      }
                      id={`${name}.duration`}
                      name={`${name}.duration`}
                      placeholder="Durata (anni)"
                      normalize={integerNormalizer}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label for={`${name}.intermediary`}>Intermediario</Label>
                    <Field
                      component={RenderField}
                      disabled={
                        disabled ||
                        (IBIPsValues && IBIPsValues[index].productId === "")
                      }
                      id={`${name}.intermediary`}
                      name={`${name}.intermediary`}
                      placeholder="Intermediario"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className="d-flex">
                    <Label className="mb-0 mr-2">Premio:</Label>
                    <RenderMultiField
                      className="mb-0"
                      disabled={disabled}
                      id={`${name}.recurrence`}
                      items={[
                        {value: "unique", text: "Unico"},
                        {value: "recurrent", text: "Ricorrente"},
                        {value: "single+recurrent", text: "Unico + Ricorrente"},
                      ]}
                      name={`${name}.recurrence`}
                      type="radio"
                      onChange={recurrenceChangeHandle.bind(
                        {},
                        index,
                        dispatch
                      )}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup className="mb-0">
                    <Label for={`${name}.uniquePremium`}>
                      Premio unico iniziale
                    </Label>
                    <Field
                      component={RenderField}
                      disabled={
                        disabled ||
                        !IBIPsValues ||
                        !IBIPsValues[index].recurrence ||
                        IBIPsValues[index].recurrence === "recurrent"
                      }
                      format={floatFormatter}
                      id={`${name}.uniquePremium`}
                      name={`${name}.uniquePremium`}
                      normalize={positiveFloatNormalizer}
                      parse={floatParser}
                      placeholder="Premio unico iniziale"
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mb-0">
                    <Label for={`${name}.recurrentPremium`}>
                      Premio ricorrente annuale
                    </Label>
                    <Field
                      component={RenderField}
                      disabled={
                        disabled ||
                        !IBIPsValues ||
                        !IBIPsValues[index].recurrence ||
                        IBIPsValues[index].recurrence === "unique"
                      }
                      format={floatFormatter}
                      id={`${name}.recurrentPremium`}
                      name={`${name}.recurrentPremium`}
                      normalize={positiveFloatNormalizer}
                      parse={floatParser}
                      placeholder="Premio ricorrente annuale"
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            {!disabled && (
              <Col sm="auto">
                <Button
                  color="danger"
                  outline
                  disabled={disabled}
                  title="Elimina questa fonte di reddito"
                  onClick={removeFiled.bind({}, index)}
                >
                  <IconTrash title="Elimina questo prodotto" />
                </Button>
              </Col>
            )}
          </Row>
        </Card>
      ))}
      {!disabled && (
        <div className="mb-3">
          <Button
            type="button"
            color="info"
            outline
            onClick={fields.push.bind({}, {} as IHasIBIPsFormData)}
          >
            <IconPlus title="Aggiungi un prodotto" /> Aggiungi prodotto
          </Button>
        </div>
      )}
    </>
  );
};

export default HasIBIPsProductsArray;
