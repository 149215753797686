import React from "react";
import {connect} from "react-redux";
import {Container} from "reactstrap";
import {Layout} from "../App";
import {IRootState} from "../redux/reducers";
import {actions, IProfileFormData, IState, ProfileForm} from "./";

import "./Profile.scss";

interface IStateProps {
  isProfileFail?: boolean;
  isProfilePending?: boolean;
  isProfileSuccess?: boolean;
  profileError?: string;
  user: IState;
}

interface IDispacthProps {
  profile: any;
}

type IProps = IStateProps & IDispacthProps;

const mapStateToProps = (state: IRootState): IStateProps => ({
  isProfileFail: state.user.isProfileFail,
  isProfilePending: state.user.isProfilePending,
  isProfileSuccess: state.user.isProfileSuccess,
  profileError: state.user.profileError,
  user: state.user,
});

const mapDispatchToProps = {
  profile: (values: IProfileFormData) => actions.profile(values),
};

const ProfilePage: React.FC<IProps> = (props) => {
  // console.log(props.userEntity); // tslint:disable-line
  return (
    <Layout isProtected>
      <Container>
        <h2>
          Impostazioni del profilo personale di{" "}
          {props.user.user && props.user.user.name}{" "}
          {props.user.user && props.user.user.surname}
        </h2>
      </Container>
      <Container className="container-max auto-margin-4 p-md-5">
        {props.user.user ? (
          <ProfileForm
            isProfileFail={props.isProfileFail}
            isProfilePending={props.isProfilePending}
            isProfileSuccess={props.isProfileSuccess}
            profileError={props.profileError}
            onSubmit={props.profile}
            initialValues={{
              email: props.user.user.email,
              personalPhone: props.user.user.personalPhone,
              usid: props.user.user.usid,
            }}
          />
        ) : null}
      </Container>
    </Layout>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfilePage);
