import {apiService} from "../App/service";
import {
  IRecommendationDB,
  IRecommendationEntity,
  IRecommendationParams,
} from "./types";

const apiUrl = `${process.env.REACT_APP_API_BASE_URL}recommendations`;

const recommendationService = {
  create(recommendation: Partial<IRecommendationEntity>) {
    return apiService
      .post(apiUrl, recommendation)
      .then((responseJson) => responseJson.recommendation as IRecommendationDB);
  },

  read(id: string) {
    return apiService
      .get(`${apiUrl}/${id}`)
      .then((responseJson) => responseJson.recommendation as IRecommendationDB);
  },

  list(params?: IRecommendationParams) {
    return apiService.get(apiUrl, params).then((responseJson) => ({
      numRecommendations: responseJson.numRecommendations as number,
      recommendationsList: responseJson.recommendations as IRecommendationDB[],
    }));
  },

  update(id: string, recommendation: Partial<IRecommendationEntity>) {
    return apiService
      .put(`${apiUrl}/${id}`, recommendation)
      .then((responseJson) => responseJson.recommendation as IRecommendationDB);
  },

  // delete(id: string) {
  //   return apiService.delete(`${apiUrl}/${id}`);
  // }
};

export default recommendationService;
