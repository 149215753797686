import React from "react";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";

import {IddForm, IIddFormData} from ".";
import {ICap, ICapEntity, IStateUi, NewCapModalTypes} from "..";
import {IconOverdraft, IconTimes} from "../../Icons";

interface IProps {
  closeIdd: () => void;
  ui: IStateUi;
  cap: ICap;

  iddSubmit: (values: IIddFormData, cap: ICapEntity) => Promise<void>;
}

const IddModal: React.FC<IProps> = (props) => (
  <Modal
    isOpen={props.ui.isModalOpen[NewCapModalTypes.Idd]}
    toggle={props.closeIdd}
    backdrop="static"
    keyboard={false}
    className="modal-form"
  >
    <ModalHeader
      tag="div"
      toggle={props.closeIdd}
      close={
        <Button size="sm" className="close-modal" onClick={props.closeIdd}>
          <IconTimes size={1.25} className="icon-border-2 rounded-circle p-1" />
        </Button>
      }
    >
      <IconOverdraft size={3} className="icon-border-2 rounded-circle p-2" />
      Valutazione Adeguatezza IBIPs
    </ModalHeader>
    <ModalBody>
      <IddForm {...props} />
    </ModalBody>
  </Modal>
);

export default IddModal;
