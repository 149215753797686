import React from "react";

interface IProps {
  children: number | string;
  maximumFractionDigits?: number;
  minimumFractionDigits?: number;
}

const FormatNumber: React.FC<IProps> = (props) => {
  let input = 0;
  if (typeof props.children === "string") {
    input = parseFloat(props.children as string);
  } else {
    input = props.children as number;
  }

  return (
    <React.Fragment>
      {input !== undefined && input !== null
        ? input.toLocaleString("it-IT", {
            maximumFractionDigits:
              props.maximumFractionDigits !== undefined
                ? props.maximumFractionDigits
                : 2,
            minimumFractionDigits:
              props.minimumFractionDigits !== undefined
                ? props.minimumFractionDigits
                : 2,
          })
        : "-"}
    </React.Fragment>
  );
};

export default FormatNumber;
