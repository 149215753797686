import React from "react";
import {Button, Col, Row} from "reactstrap";
import {IconCsv} from "../../../Icons";

const Instructions = () => (
  <>
    <p>
      Per l'aggiornamento degli Advisor tramite file CSV, è richiesto un file
      che rispetti i seguenti criteri:
      <Button
        tag="a"
        href="/csv-examples/update_bulk_example.csv"
        download
        size="sm"
        color="primary"
        outline
        className="float-right"
        target="_self"
      >
        <IconCsv />
        File di esempio
      </Button>
    </p>
    <Row tag="dl">
      <Col tag="dt" md={3} lg={2}>
        Campi indice
      </Col>
      <Col tag="dd" md={9} lg={10}>
        Per individuare l'Advisor che si vuole modificare, verranno letti i
        campi nel seguente ordine: codice fiscale, codice RUI, nome utente.
      </Col>
      <Col tag="dt" md={3} lg={2}>
        Campi univoci
      </Col>
      <Col tag="dd" md={9} lg={10}>
        I seguenti campi devono essere univoci nel file caricato e non devono
        essere già presenti nel database: codice fiscale, nome utente, codice
        RUI e indirizzo email.
      </Col>
      <Col tag="dt" md={3} lg={2}>
        Date
      </Col>
      <Col tag="dd" md={9} lg={10}>
        Le date vanno inserite nel formato aaaa-mm-gg o aaaa-mm-gg hh:mm:ss.
      </Col>
    </Row>
  </>
);

export default Instructions;
