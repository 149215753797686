import React from "react";
import {connect} from "react-redux";
import {Link, Redirect} from "react-router-dom";
import {Card, CardBody, Col, Container, Row} from "reactstrap";
import {Layout} from "../App";
import {IRootState} from "../redux/reducers";
import {IUser} from "../Users";
import {actions, ActivateAlert, ILoginFormData, LoginForm} from "./";

import "./LoginPage.scss";

interface IStateProps {
  user: IUser;
}
type IDispatchProps = typeof mapDispatchToProps;

const mapStateToProps = (state: IRootState) => state.user;
const mapDispatchToProps = {
  onSubmitForm: (values: ILoginFormData) => {
    return actions.login(values);
  },
};

const Page: React.FC<IStateProps & IDispatchProps & any> = (props) => (
  <Layout hasMenu={false}>
    {props.user && <Redirect to="/" />}
    <ActivateAlert className="mt-n4" />
    <Container className="main-center auto-margin-4">
      <Row>
        <Col
          sm={{size: 8, offset: 2}}
          md={{size: 6, offset: 3}}
          lg={{size: 4, offset: 4}}
        >
          <img
            src="/img/logo.svg"
            className="logo mx-auto d-block img-fluid"
            alt={`${process.env.REACT_APP_TITLE} logo`}
          />
        </Col>
      </Row>
      <Row>
        <Col
          sm={{size: 8, offset: 2}}
          md={{size: 6, offset: 3}}
          lg={{size: 4, offset: 4}}
        >
          <Card className="text-primary">
            <CardBody>
              <LoginForm onSubmit={props.onSubmitForm} {...props} />
              {props.isLoginSuccess && <Redirect to="/" push />}
              <div className="mt-2">
                <Link to="/lost-password">Hai dimenticato la password?</Link>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </Layout>
);

const ConnectedPage = connect(mapStateToProps, mapDispatchToProps)(Page);

export default ConnectedPage;
