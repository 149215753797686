import React from "react";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import {IconPerson, IconTimes} from "../Icons";
import {IUser, IUserEntity} from "../Users";
import {IStateUi, NewCapModalTypes} from "./";
import CompleteContractorForm, {
  ICompleteContractorFormData,
} from "./CompleteContractorForm";

interface IProps {
  ui: IStateUi;
  contractor: IUser;

  completeContractorSubmit: (
    values: ICompleteContractorFormData,
    contractor: IUserEntity
  ) => Promise<void>;
  closeCompleteContractor: () => void;
}

const CompleteContractorModal: React.FC<IProps> = (props) => (
  <Modal
    isOpen={props.ui.isModalOpen[NewCapModalTypes.CompleteContractor]}
    toggle={props.closeCompleteContractor}
    backdrop="static"
    keyboard={false}
    className="modal-form"
  >
    <ModalHeader
      toggle={props.closeCompleteContractor}
      close={
        <Button
          size="sm"
          className="close-modal"
          onClick={props.closeCompleteContractor}
        >
          <IconTimes size={1.25} className="icon-border-2 rounded-circle p-1" />
        </Button>
      }
    >
      <IconPerson size={3} className="icon-border-2 rounded-circle p-2" />
      Anagrafica Cliente
    </ModalHeader>
    <ModalBody>
      <CompleteContractorForm {...props} />
    </ModalBody>
  </Modal>
);

export default CompleteContractorModal;
