import moment from "moment";
import React from "react";
import {Link} from "react-router-dom";
import ReactTable, {
  ControlledStateOverrideProps,
  SortingRule,
} from "react-table";
import {Alert, Badge, Button, Container} from "reactstrap";
import {debounce} from "throttle-debounce";
import {Layout, PageTitle} from "../../App";
import ColumnDateFilter from "../../helpers/ColumnDateFilter";
import ColumnRecommendationStatusFilter from "../../helpers/ColumnRecommendationStatusFilter";
import {
  IconEye,
  IconPlus,
  IconRefresh,
  IconSearch,
  IconSpinner,
} from "../../Icons";
import {IStateListUI} from "../reducers";
import {
  IRecommendation,
  IRecommendationParams,
  IRevision,
  RecommendationStatus,
} from "../types";
import "./page.scss";

interface IStateProps {
  canCreate: boolean;
  canContractorManageRecommendation: boolean;
  denormalizedItems: IRecommendation[];
  canApprove: boolean;
  numItems: number;
  params: IRecommendationParams;
  ui: IStateListUI;
}
interface IDispatchProps {
  list: (params?: IRecommendationParams) => Promise<void>;
}
type IProps = IStateProps & IDispatchProps;

type IState = ControlledStateOverrideProps;

const LoadingComponent: React.FC<{loading: boolean}> = ({loading}) =>
  loading ? (
    <div className="-loading -active">
      <div className="-loading-inner">
        <IconSpinner className="icon-spin" /> Caricamento raccomandazioni in
        corso...
      </div>
    </div>
  ) : null;

class Page extends React.PureComponent<IProps, IState> {
  private static getStatusColor(recommendation: IRecommendation) {
    switch (recommendation.status) {
      case RecommendationStatus.STATUS_TO_BE_VALIDATED:
        return "primary";
      case RecommendationStatus.STATUS_REJECTED:
        return "info";
      case RecommendationStatus.STATUS_WAIT_REVIEW:
        return "warning";
      case RecommendationStatus.STATUS_CORRESPONDING:
        return "success";
      case RecommendationStatus.STATUS_NOT_VALIDATE:
      case RecommendationStatus.STATUS_IBIPS_REQUEST:
      case RecommendationStatus.STATUS_REQUEST_VERIFICATION:
      case RecommendationStatus.STATUS_REQUEST_ESIGN:
      case RecommendationStatus.STATUS_REQUEST_ESIGN_ADVISOR:
        return "danger";
      default:
        return "danger";
    }
  }

  private lastParams?: IRecommendationParams;
  private readonly newProperty = this.getStatusLabel.bind(this);

  constructor(props: IProps) {
    super(props);

    this.azioni = this.azioni.bind(this);
    this.fetchData = debounce(300, this.fetchData.bind(this));
    this.refreshList = this.refreshList.bind(this);
  }

  public getColumns(props: any) {
    let columns: any = [
      {
        Header: "Numero proposta",
        accessor: (recommendation: IRecommendation) => recommendation.code,
        id: "code",
      },
    ];

    if (!this.props.canContractorManageRecommendation || this.props.canCreate) {
      columns = columns.concat([
        {
          Header: "Cliente",
          accessor: (recommendation: IRecommendation) =>
            recommendation.contractor ? (
              <div style={{lineHeight: 1}}>
                {recommendation.contractor.surname}{" "}
                {recommendation.contractor.name}
                <br />
                <small className="smaller text-info">
                  CF: {recommendation.contractor.fiscalCode}
                </small>
              </div>
            ) : (
              "-"
            ),
          id: "contractor",
        },
      ]);
    }

    columns = columns.concat([
      {
        Header: "Advisor",
        accessor: (recommendation: IRecommendation) =>
          recommendation.user ? (
            <div style={{lineHeight: 1}}>
              {recommendation.user.surname} {recommendation.user.name}
              <br />
              <small className="smaller text-info">
                RUI: {recommendation.user.ruiCode}
                <br />
                CF: {recommendation.user.fiscalCode}
              </small>
            </div>
          ) : (
            "-"
          ),
        id: "user",
      },
      {
        Filter: ({filter, onChange}: any) => (
          <ColumnDateFilter filter={filter} onChange={onChange} />
        ),
        Header: "Inserita il",
        accessor: (recommendation: IRecommendation) =>
          moment(recommendation.date).format("LL"),
        // filterable: false,
        id: "date",
      },
      {
        Filter: ({filter, onChange}: any) => (
          <ColumnRecommendationStatusFilter
            filter={filter}
            onChange={onChange}
            getStatusColor={Page.getStatusColor}
            getStatusLabel={this.newProperty}
          />
        ),
        Header: "Stato",
        accessor: (recommendation: IRecommendation) => (
          <span data-value={recommendation.status}>
            <span
              className={
                "statusDot text-" + Page.getStatusColor(recommendation)
              }
            >
              &#x25cf;
            </span>{" "}
            <span>
              {this.getStatusLabel(
                recommendation.status,
                recommendation.revisions
              )}
            </span>
          </span>
        ),
        filterMethod: (filter: any, row: any) =>
          row[filter.id].props["data-value"] === filter.value,
        id: "status",
        sortable: false,
      },
      {
        Header: "Azioni",
        accessor: this.azioni,
        filterable: false,
        id: "azioni",
        sortable: false,
        width: 150,
      },
    ]);

    return columns;
  }

  public render() {
    const {
      canCreate,
      denormalizedItems,
      canApprove,
      numItems,
      params,
      ui,
    } = this.props;
    return (
      <Layout isProtected>
        <PageTitle fluid className="px-md-5">
          Raccomandazioni personalizzate
          {canCreate && (
            <Button
              tag={Link}
              color="light"
              outline
              to="/raccomandazioni/new"
              className="ml-auto"
            >
              <IconPlus /> Nuova raccomandazione
            </Button>
          )}
        </PageTitle>
        <Container fluid className="auto-margin-4 px-0 px-sm-3 px-md-5">
          {ui.isListFail && (
            <Alert color="danger" className="d-flex align-items-center">
              {ui.listError}{" "}
              <Button
                color="light"
                outline
                size="sm"
                onClick={this.refreshList}
                className="ml-auto"
              >
                <IconRefresh /> Aggiorna
              </Button>
            </Alert>
          )}
          <Container fluid className="container-max">
            <ReactTable
              LoadingComponent={LoadingComponent}
              data={denormalizedItems}
              columns={this.getColumns(this.props)}
              manual
              pages={Math.ceil(numItems! / params.limit)}
              defaultPageSize={50}
              defaultSorted={[
                {
                  desc: true,
                  id: "date",
                },
              ]}
              defaultFiltered={
                canApprove
                  ? [
                      {
                        id: "status",
                        value: RecommendationStatus.STATUS_TO_BE_VALIDATED,
                      },
                    ]
                  : undefined
              }
              minRows={5}
              filterable
              loading={ui.isListPending}
              className="-striped -highlight"
              previousText="Precedente"
              nextText="Prossima"
              onFetchData={this.fetchData}
              noDataText="Non è stata creata ancora nessuna raccomandazione"
              pageText="Pagina"
              ofText="di"
              rowsText="raccomandazioni"
            />
          </Container>
        </Container>
      </Layout>
    );
  }

  private refreshList() {
    this.props.list(this.lastParams);
  }

  private fetchData(state: any) {
    const filters: {[key: string]: string} = {};
    const sort = state.sorted
      .map((col: SortingRule) => (col.desc ? "-" + col.id : col.id))
      .join(",");

    state.filtered.forEach((filter: {id: string; value: string}) => {
      if (filter.id === "date") {
        // Se il filtro contiene le date le trasformiamo
        // *Occhio* che date in verità contiene entrambi dateStart e dateEnd
        filters[filter.id] = filter.value
          .split(",")
          .map((date) =>
            date ? moment(date, "DD-MM-YYYY").format("YYYY-MM-DD") : undefined
          )
          .join(",");
      } else {
        filters[filter.id] = filter.value;
      }
    });

    const params: IRecommendationParams = {
      limit: state.pageSize,
      offset: state.page * state.pageSize,
      sort,
      ...filters,
    };

    this.lastParams = params;
    this.props.list(params);
  }

  private azioni(recommendation: IRecommendation) {
    if (
      recommendation.status === RecommendationStatus.STATUS_WAIT_REVIEW &&
      !this.props.canApprove
    ) {
      return (
        <Button
          tag={Link}
          size="sm"
          color="primary"
          outline
          block
          to={`/raccomandazioni/${recommendation.id}`}
        >
          <IconSearch /> Rivedi
        </Button>
      );
    }
    return (
      <Button
        tag={Link}
        size="sm"
        color="primary"
        outline
        block
        to={`/raccomandazioni/${recommendation.id}`}
      >
        <IconEye /> Visualizza
      </Button>
    );
  }

  private getStatusLabel(
    status: RecommendationStatus,
    revisions?: IRevision[]
  ) {
    const type = this.props.canApprove ? "backOffice" : "advisor";
    const date =
      revisions && revisions.length > 0
        ? revisions[revisions.length - 1].date.format("LL")
        : undefined;
    const statuses = {
      advisor: {
        [RecommendationStatus.STATUS_TO_BE_VALIDATED]:
          "Inviato per l'approvazione",
        [RecommendationStatus.STATUS_REQUEST_ESIGN]:
          "Firma del cliente mancante",
        [RecommendationStatus.STATUS_WAIT_REVIEW]:
          revisions && revisions.length ? (
            <>
              Richiesta revisione
              <br />
              {date}{" "}
              <Badge color="warning">
                {revisions.length}{" "}
                {revisions.length === 1 ? "revisione" : "revisioni"}
              </Badge>
              <br />
              <span className="revision-snippet">
                <strong>Ultima revisione:</strong>{" "}
                {revisions[revisions.length - 1].text}
              </span>
            </>
          ) : (
            "Richiesta revisione"
          ),
        [RecommendationStatus.STATUS_CORRESPONDING]: "Congrua",
        [RecommendationStatus.STATUS_NOT_VALIDATE]: "Non validabile",
        [RecommendationStatus.STATUS_IBIPS_REQUEST]: "Richiesto rinnovo IBIPs",
        [RecommendationStatus.STATUS_REQUEST_VERIFICATION]:
          "Verifica adeguatezza richiesta",
        [RecommendationStatus.STATUS_REJECTED]: "Raccomandazione archiviata",
        [RecommendationStatus.STATUS_REQUEST_ESIGN_ADVISOR]:
          "Firma Advisor mancante",
      },
      backOffice: {
        [RecommendationStatus.STATUS_TO_BE_VALIDATED]: "Da validare",
        [RecommendationStatus.STATUS_REQUEST_ESIGN]:
          "Firma del cliente mancante",
        [RecommendationStatus.STATUS_WAIT_REVIEW]: "In attesa di revisione",
        [RecommendationStatus.STATUS_CORRESPONDING]: "Congrua",
        [RecommendationStatus.STATUS_NOT_VALIDATE]: "Non validabile",
        [RecommendationStatus.STATUS_IBIPS_REQUEST]: "Richiesto rinnovo IBIPs",
        [RecommendationStatus.STATUS_REQUEST_VERIFICATION]:
          "Verifica adeguatezza richiesta",
        [RecommendationStatus.STATUS_REJECTED]: "Raccomandazione archiviata",
        [RecommendationStatus.STATUS_REQUEST_ESIGN_ADVISOR]:
          "Firma Advisor mancante",
      },
    };

    return (
      statuses[type][status] ||
      "Stato raccomandazione non valido per " + type + " " + status
    );
  }
}

export default Page;
