import React from "react";
import {connect, DispatchProp} from "react-redux";
import {Alert, Button, Form} from "reactstrap";
import {Dispatch} from "redux";
import {
  FieldArray,
  formValueSelector,
  initialize,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import {focusFirstInvalid} from "../../helpers/focusFirstInvalid";
import {IconCheckmark} from "../../Icons";
import IconSpinner from "../../Icons/IconSpinner";
import {IRootState} from "../../redux/reducers";
import {IStateUi} from "../reducers";
import {
  dehydrateCap,
  ICap,
  ICapEntity,
  IInvestmentCompany,
  IInvestmentOption,
  IInvestmentProduct,
} from "../types";
import HasIBIPsProductsArray from "./HasIBIPsProductsArray";

export interface IHasIBIPsData {
  company?: IInvestmentCompany;
  companyId: string;
  companyOther?: string;
  product?: IInvestmentProduct;
  productId: string;
  productOther?: string;
  intermediary: string;
  recurrence: string;
  uniquePremium: string;
  recurrentPremium: string;
  subscriptionYear: string;
  duration: string;
}
export interface IHasIBIPsFormData {
  IBIPs: IHasIBIPsData[];
}
export interface IHasIBIPsFormErrors {
  IBIPs: Array<Partial<IHasIBIPsData>>;
}

interface IOwnProps {
  cap: ICap;
  disabled?: boolean;
  hideForm: () => void;
  hasIBIPsSubmit?: (
    values: IHasIBIPsFormData | undefined,
    cap: ICapEntity
  ) => void;
  options?: IInvestmentOption[];
  optionsList: () => Promise<void>;
  ui: IStateUi;
}

interface IStateProps {
  IBIPsValues: IHasIBIPsData[];
}

type IProps = IStateProps &
  DispatchProp &
  IOwnProps &
  InjectedFormProps<
    IHasIBIPsFormData,
    IOwnProps,
    Array<Partial<IHasIBIPsData>>
  >;

const valuesSelector = formValueSelector("hasIbipsForm");
const mapStateToProps = (state: IRootState) => ({
  IBIPsValues: valuesSelector(state, "IBIPs"),
});

const validate = (values: IHasIBIPsFormData) => {
  const errors: IHasIBIPsFormErrors = {IBIPs: []};

  if (values.IBIPs) {
    values.IBIPs.forEach((IBIP, index) => {
      const partialError: Partial<IHasIBIPsData> = {};
      if (!IBIP.companyId) {
        partialError.companyId = "La compagnia è obbligatoria";
      }
      if (IBIP.companyId === "other" && !IBIP.companyOther) {
        partialError.companyOther = "Specifica la compagnia";
      }
      if (!IBIP.productId) {
        partialError.productId = "Il prodotto è obbligatorio";
      }
      if (IBIP.productId === "other" && !IBIP.productOther) {
        partialError.productOther = "Specifica il prodotto";
      }
      if (!IBIP.intermediary) {
        partialError.intermediary = "L'intermediario è obbligatorio";
      }
      if (!IBIP.recurrence) {
        partialError.recurrence = "La tipologia è obbligatoria";
      }
      if (parseInt(IBIP.subscriptionYear, 10) > new Date().getFullYear()) {
        partialError.subscriptionYear =
          "L anno di sottoscrizione è successivo alla data odierna";
      }
      if (
        !IBIP.uniquePremium &&
        IBIP.recurrence &&
        IBIP.recurrence !== "recurrent"
      ) {
        partialError.uniquePremium = "Il premio unico è obbligatorio";
      }
      if (
        !IBIP.recurrentPremium &&
        IBIP.recurrence &&
        IBIP.recurrence !== "unique"
      ) {
        partialError.recurrentPremium = "Il premio ricorrente è obbligatorio";
      }
      if (
        partialError.companyId ||
        partialError.companyOther ||
        partialError.productId ||
        partialError.productOther ||
        partialError.intermediary ||
        partialError.recurrence ||
        partialError.subscriptionYear ||
        partialError.uniquePremium ||
        partialError.recurrentPremium
      ) {
        errors.IBIPs[index] = partialError;
      }
    });
  }

  return errors;
};

const HasIBIPsForm: React.FC<IProps> = (props) => {
  const {optionsList} = props;
  const [companies, setCompanies] = React.useState<IInvestmentCompany[]>([]);
  const [products, setProducts] = React.useState<IInvestmentProduct[]>([]);
  React.useEffect(() => {
    optionsList().then();
  }, [optionsList]);
  React.useEffect(() => {
    const companiesSet: {[key: string]: IInvestmentCompany} = {};
    const productsSet: {[key: string]: IInvestmentProduct} = {};
    if (props.options) {
      props.options.forEach((option) => {
        companiesSet[option.product.company.id] = option.product.company;
      });
      props.options.forEach((option) => {
        productsSet[option.product.id] = option.product;
      });
    }
    setCompanies(Object.values(companiesSet));
    setProducts(Object.values(productsSet));
  }, [props.options]);

  const submitWithCap = async (values: IHasIBIPsFormData) => {
    const {
      result,
      entities: {caps},
    } = dehydrateCap(props.cap);

    values.IBIPs.forEach((IBIP) => {
      IBIP.company = companies.find(
        (company) => company.id.toString() === IBIP.companyId
      );
      IBIP.product = products.find(
        (product) => product.id.toString() === IBIP.productId
      );
    });

    props.hasIBIPsSubmit?.(values, caps[result]);
  };
  return (
    <Form onSubmit={props.handleSubmit(submitWithCap)}>
      <FieldArray
        component={HasIBIPsProductsArray}
        dispatch={props.dispatch}
        name="IBIPs"
        cap={props.cap}
        disabled={props.disabled}
        hasIBIPsSubmit={props.hasIBIPsSubmit}
        hideForm={props.hideForm}
        companies={companies}
        products={products}
        IBIPsValues={props.IBIPsValues}
      />
      {props.ui.isHasIBIPsFail && (
        <Alert color="danger">{props.ui.hasIBIPsError}</Alert>
      )}
      {props.cap.data.hasIBIPs &&
        !props.disabled &&
        (props.pristine && !props.ui.isHasIBIPsFail ? (
          <Alert color="success" className="mb-0">
            Prodotti salvati correttamente
          </Alert>
        ) : (
          <Alert color="danger" className="mb-0">
            Modifiche non salvate
          </Alert>
        ))}
      {!props.disabled && (
        <div className="text-center mt-3">
          <Button
            type="submit"
            color="primary"
            outline
            disabled={props.submitting}
          >
            {props.submitting ? (
              <>
                <IconSpinner className="icon-spin" /> Salvataggio in corso...
              </>
            ) : props.cap.data.hasIBIPs && props.pristine ? (
              <>
                <IconCheckmark color="success" /> Salvato
              </>
            ) : (
              <>Salva e continua</>
            )}
          </Button>
        </div>
      )}
    </Form>
  );
};

const HasIBIPsFormConnected = reduxForm<
  IHasIBIPsFormData,
  IOwnProps & any,
  Array<Partial<IHasIBIPsData>>
>({
  form: "hasIbipsForm",
  onSubmitFail: focusFirstInvalid,
  onSubmitSuccess: (result: any, dispatch: Dispatch<any>, props: any) => {
    dispatch(initialize(props.form, props.values));
  },
  validate,
})(HasIBIPsForm);

export default connect(mapStateToProps)(HasIBIPsFormConnected);
