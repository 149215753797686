import last from "lodash/last";
import React, {useEffect} from "react";
import {connect} from "react-redux";
import {Button, Container} from "reactstrap";
import {Layout} from "../App";
import {actions as eSignActions} from "../ESign";
import {PDFTypes} from "../ESign/types";
import {IconDownload, IconSpinner} from "../Icons";
import {IRootState} from "../redux/reducers";
import {IPrivacyHistory, IUser, IUserDB} from "../Users";
import {actions, IPrivacy} from "./";
import PrivacyForm, {IPrivacyFormData} from "./PrivacyForm";
import "./Profile.scss";
import {getPrivacy, getUser} from "./selectors";

interface IStateProps {
  isPrivacyFail?: boolean;
  isPrivacyOpen?: boolean;
  privacyError?: string;
  privacy?: IPrivacy;
  user: IUser;
}

interface IDispatchProps {
  privacySubmit: (values: IPrivacyFormData) => Promise<void>;
  openRequestOTPModal: (capId: string) => void;
  loadPrivacy: () => Promise<void>;
}

type IProps = IStateProps & IDispatchProps;

const mapStateToProps = (state: IRootState) => ({
  isPrivacyFail: state.user.isPrivacyFail,
  isPrivacyOpen: state.user.isPrivacyOpen,
  privacy: getPrivacy(state),
  privacyError: state.user.privacyError,
  user: getUser(state)!,
});

const mapDispatchToProps = {
  openRequestOTPModal: (userId: string) => {
    return eSignActions.openModal(PDFTypes.privacy, userId);
  },
  privacySubmit: (values: IPrivacyFormData) => {
    return actions.privacy(values);
  },
  loadPrivacy: () => {
    return actions.loadPrivacy();
  },
};

const PrivacyPage: React.FC<IProps> = ({openRequestOTPModal, ...props}) => {
  const openRequestOTPModalWithId = () => {
    openRequestOTPModal(props.user.id);
  };

  useEffect(() => {
    props.loadPrivacy().then();
    // eslint-disable-next-line
  }, []);

  const userPrivacy = ((props.user as unknown) as IUserDB)?.jsonPrivacy;
  const privacyHistory = userPrivacy
    ? (JSON.parse(userPrivacy) as IPrivacyHistory)
    : undefined;
  const lastPrivacyHistory =
    privacyHistory && Array.isArray(privacyHistory)
      ? last(privacyHistory)
      : privacyHistory;
  const privacyInitialValues =
    lastPrivacyHistory === "true" ? undefined : lastPrivacyHistory?.values;

  const isUpdating =
    lastPrivacyHistory === "true" ||
    !!(
      props.privacy &&
      lastPrivacyHistory &&
      parseInt(props.privacy.version, 10) >
        parseInt(lastPrivacyHistory.version, 10)
    );

  return (
    <Layout isProtected>
      <Container>
        <h2>
          Privacy policy di {props.user && props.user.name}{" "}
          {props.user && props.user.surname}
        </h2>
      </Container>
      <Container className="container-max auto-margin-4 p-md-5">
        {props.privacy ? (
          <PrivacyForm
            {...props}
            className="con-scroll"
            form="privacyForm"
            isUpdating={isUpdating}
            initialValues={privacyInitialValues}
            privacy={props.privacy}
          />
        ) : (
          <div className="text-center">
            <IconSpinner className="icon-spin mr-2 my-4" />
            Caricamento privacy in corso...
          </div>
        )}
        <div className="d-flex">
          {props.user.lastPrivacyEsignId ? (
            <Button
              className="mr-2"
              color="primary"
              outline
              download
              href={`${process.env.REACT_APP_API_BASE_URL}users/${props.user.id}/get-pdf-privacy`}
            >
              <IconDownload /> Scarica il pdf del consenso
            </Button>
          ) : (
            <Button color="primary" outline onClick={openRequestOTPModalWithId}>
              Firma il pdf del consenso
            </Button>
          )}
          <Button
            color="danger"
            outline
            tag="a"
            href={`mailto:${process.env.REACT_APP_EMAIL_PRIVACY}?subject=Richiesta cancellazione dati personali`}
            className="ml-auto"
          >
            Richiesta cancellazione Dati Personali
          </Button>
        </div>
      </Container>
    </Layout>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPage);
