import React from "react";
import {Button, Card, CardBody, CardHeader, FormGroup, Label} from "reactstrap";
import {Field} from "redux-form";

import {IconPlus} from "../Icons";
import {floatFormatter} from "./formatters";
import {naturalNormalizer, positiveFloatNormalizer} from "./normalizers";
import {floatParser} from "./parsers";
import RenderField from "./RenderField";
import RenderMultiField from "./RenderMultiField";

const RenderFieldArray = ({
  fields,
  meta: {dirty, touched, error, submitFailed},
  ...props
}: any) => (
  <React.Fragment>
    {fields.map((job: any, index: any) => (
      <Card key={index} className="mb-3">
        <CardHeader className="bg-primary text-white">
          Occupazione precedente #{index + 1}
          <button
            type="button"
            className="close"
            title="Elimina"
            onClick={fields.remove.bind({}, index)}
          >
            <span aria-hidden="true">×</span>
          </button>
        </CardHeader>
        <CardBody>
          <Label>Tipologia</Label>
          <RenderMultiField
            id={`${job}.tipologia`}
            name={`${job}.tipologia`}
            type="radio"
            items={[
              {value: "dipendente", text: "Dipendente"},
              {value: "subordinato", text: "Subordinato"},
              {value: "autonomo", text: "Autonomo"},
            ]}
          />
          {props.prevEmploymentsValues &&
          (props.prevEmploymentsValues[index].tipologia === "dipendente" ||
            props.prevEmploymentsValues[index].tipologia === "subordinato") ? (
            <div>
              <FormGroup>
                <Label for={`${job}.professione`}>Professione</Label>
                <Field
                  id={`${job}.professione`}
                  name={`${job}.professione`}
                  type="text"
                  component={RenderField}
                  placeholder="Professione"
                />
              </FormGroup>
              <FormGroup>
                <Label for={`${job}.dal`}>Dal (indicare anno)</Label>
                <Field
                  id={`${job}.dal`}
                  name={`${job}.dal`}
                  normalize={naturalNormalizer}
                  type="text"
                  component={RenderField}
                  placeholder="Dal, anno"
                />
              </FormGroup>
              <FormGroup>
                <Label for={`${job}.al`}>Al (indicare anno)</Label>
                <Field
                  id={`${job}.al`}
                  name={`${job}.al`}
                  normalize={naturalNormalizer}
                  type="text"
                  component={RenderField}
                  placeholder="Al, anno"
                />
              </FormGroup>
              <FormGroup>
                <Label for={`${job}.stipendio`}>
                  Stipendio mensile (importo al netto)
                </Label>
                <Field
                  id={`${job}.stipendio`}
                  name={`${job}.stipendio`}
                  parse={floatParser}
                  format={floatFormatter}
                  normalize={positiveFloatNormalizer}
                  type="text"
                  component={RenderField}
                  placeholder="Stipendio"
                />
              </FormGroup>
              <FormGroup>
                <Label for={`${job}.mensilita`}>Mensilità</Label>
                <Field
                  id={`${job}.mensilita`}
                  name={`${job}.mensilita`}
                  component={RenderField}
                  type="select"
                >
                  <option value="">Seleziona</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                </Field>
              </FormGroup>
            </div>
          ) : props.prevEmploymentsValues &&
            props.prevEmploymentsValues[index].tipologia === "autonomo" ? (
            <div>
              <FormGroup>
                <Label for={`${job}.professione`}>Professione</Label>
                <Field
                  id={`${job}.professione`}
                  name={`${job}.professione`}
                  type="text"
                  component={RenderField}
                  placeholder="Professione"
                />
              </FormGroup>
              <Label>Categoria</Label>
              <RenderMultiField
                id={`${job}.categoria`}
                name={`${job}.categoria`}
                type="radio"
                items={[
                  {value: "artigiano", text: "Artigiano"},
                  {value: "commerciante", text: "Commerciante"},
                  {value: "altro", text: "Altro"},
                ]}
              />
              <FormGroup>
                <Label>
                  <Field
                    id={`${job}.cassa`}
                    name={`${job}.cassa`}
                    type="checkbox"
                    component="input"
                  />{" "}
                  Iscritto ad una cassa professionale di categoria (forense,
                  geometri, medici, psicologi, ecc..)
                </Label>
              </FormGroup>
              <FormGroup>
                <Label for={`${job}.dal`}>Dal (indicare anno)</Label>
                <Field
                  id={`${job}.dal`}
                  name={`${job}.dal`}
                  normalizer={naturalNormalizer}
                  type="text"
                  component={RenderField}
                  placeholder="Dal, anno"
                />
              </FormGroup>
              <FormGroup>
                <Label for={`${job}.al`}>Al (indicare anno)</Label>
                <Field
                  id={`${job}.al`}
                  name={`${job}.al`}
                  normalize={naturalNormalizer}
                  type="text"
                  component={RenderField}
                  placeholder="Al, anno"
                />
              </FormGroup>
              <FormGroup>
                <Label for={`${job}.reddito`}>Reddito lordo annuo</Label>
                <Field
                  id={`${job}.reddito`}
                  name={`${job}.reddito`}
                  type="text"
                  parse={floatParser}
                  format={floatFormatter}
                  normalize={positiveFloatNormalizer}
                  component={RenderField}
                  placeholder="Reddito lordo annuo"
                />
              </FormGroup>
              <FormGroup>
                <Label for={`${job}.percentuale`}>
                  Tassazione in percentuale sul Reddito lordo annuo
                </Label>
                <Field
                  id={`${job}.percentuale`}
                  name={`${job}.percentuale`}
                  normalizer={naturalNormalizer}
                  type="text"
                  component={RenderField}
                  placeholder="Tassazione percentuale sul reddito"
                />
              </FormGroup>
            </div>
          ) : null}
        </CardBody>
      </Card>
    ))}
    <Button
      type="button"
      color="info"
      outline
      onClick={fields.push.bind({}, {})}
    >
      <IconPlus /> Aggiungi occupazione precedente
    </Button>
  </React.Fragment>
);

export default RenderFieldArray;
