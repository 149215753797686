import {
  faCheck,
  faGavel,
  faHeadphones,
  faHouseDamage,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {useFormContext} from "react-hook-form";
import {Card, CardBody, CardFooter, Col, Row, Table} from "reactstrap";

interface IProps {
  disabled?: boolean;
}

const featuresTable = {
  silver: {price: 8, rc: "500.000", legal: "10.000", damages: "10.000"},
  gold: {price: 11, rc: "1.000.000", legal: "15.000", damages: "20.000"},
};

const House = ({disabled}: IProps) => {
  const {register} = useFormContext();

  return (
    <>
      <div>
        <h4>Polizza Bene Casa</h4>
        <h5>Caratteristiche generali</h5>
        <ul>
          <li>
            Assicurato il contenuto della dimora abituale a Primo Rischio
            Assoluto.
          </li>
          <li>
            Garanzia Incendio All Risks che comprende anche Eventi naturali,
            Atti vandalici, Danni da acqua gas e gelo.
          </li>
          <li>
            RC Vita privata, compresa la conduzione della dimora abituale.
          </li>
          <li>
            Tutela Legale Base (extracontrattuale e difesa penale) durante la
            vita privata.
          </li>
        </ul>
      </div>
      <Row className="mb-3">
        {Object.keys(featuresTable).map((type, index) => {
          const features = featuresTable[type as "silver" | "gold"];
          return (
            <Col key={type} className={index > 0 ? "mt-2 mt-md-0" : undefined}>
              <label className="radio-card">
                <input
                  type="radio"
                  name="level"
                  value={type}
                  ref={register}
                  disabled={disabled}
                />
                <Card className={type}>
                  <CardBody>
                    <Table borderless size="sm" className="mb-0">
                      <thead>
                        <tr>
                          <th colSpan={2}>
                            <h5>
                              <span className="text-capitalize">{type}</span>{" "}
                              pack
                              <br />
                              <small>€ {features.price}/mese</small>
                            </h5>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <FontAwesomeIcon icon={faUser} /> RC Vita Privata
                          </td>
                          <td className="text-right">€ {features.rc}</td>
                        </tr>
                        <tr>
                          <td>
                            <FontAwesomeIcon icon={faGavel} /> Tutela Legale
                          </td>
                          <td className="text-right">€ {features.legal}</td>
                        </tr>
                        <tr>
                          <td>
                            <FontAwesomeIcon icon={faHouseDamage} /> Danni al
                            contenuto
                          </td>
                          <td className="text-right">€ {features.damages}</td>
                        </tr>
                        <tr>
                          <td>
                            <FontAwesomeIcon icon={faHeadphones} /> Assistenza
                          </td>
                          <td className="text-right">
                            <FontAwesomeIcon
                              icon={faCheck}
                              className="text-success"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                  {!disabled && (
                    <CardFooter className="text-center">
                      Scegli <span className="text-capitalize">{type}</span>{" "}
                      Pack
                    </CardFooter>
                  )}
                </Card>
              </label>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

export default House;
