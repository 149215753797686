import React from "react";
import {Link} from "react-router-dom";
import {Alert, Button, Form, FormGroup, Label} from "reactstrap";
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import {focusFirstInvalid} from "../helpers/focusFirstInvalid";
import RenderField from "../helpers/RenderField";
import {password} from "../helpers/validators";
import IconSpinner from "../Icons/IconSpinner";

export interface IActivateWithTokenFormData {
  token: string;
  new_password: string;
  new_password2: string;
}

export interface IActivateWithTokenFormErrors {
  token?: string;
  new_password?: string;
  new_password2?: string;
}

interface IProps {
  activateWithTokenError?: string;
  isActivateWithTokenFail?: boolean;
  isActivateWithTokenExpired?: boolean;
  onSubmit: any;
  submitText: string;
}

const validate = (values: IActivateWithTokenFormData) => {
  const errors: IActivateWithTokenFormErrors = {};
  if (!values.token) {
    errors.token = "Il token è obbligatorio";
  }
  if (!values.new_password) {
    errors.new_password = "La password è obbligatorio";
  }
  if (!password(values.new_password)) {
    errors.new_password =
      "La password non è nel formato corretto. Servono almeno 8 caratteri tra i quali: almeno un numero, almeno una lettera minuscola ed una maiuscola";
  }
  if (values.new_password && values.new_password !== values.new_password2) {
    errors.new_password2 = "Le password inserite non coincidono";
  }
  return errors;
};

const ActivateWithTokenForm: React.FC<
  IProps & InjectedFormProps<IActivateWithTokenFormData, IProps>
> = (props) => (
  <Form onSubmit={props.handleSubmit}>
    <FormGroup>
      <Label for="exampleEmail">Token segreto</Label>
      <Field
        id="token"
        name="token"
        component={RenderField}
        type="text"
        placeholder="Token segreto"
      />
    </FormGroup>
    <FormGroup>
      <Label for="exampleEmail">Password</Label>
      <Field
        id="new_password"
        name="new_password"
        component={RenderField}
        type="password"
        placeholder="Password"
      />
    </FormGroup>
    <FormGroup>
      <Label for="exampleEmail">Ripeti la password</Label>
      <Field
        id="new_password2"
        name="new_password2"
        component={RenderField}
        type="password"
        placeholder="Ripeti la password"
      />
    </FormGroup>
    {props.isActivateWithTokenFail ? (
      <Alert color="danger">{props.activateWithTokenError}</Alert>
    ) : null}
    {props.isActivateWithTokenExpired ? (
      <Alert color="danger">
        {props.activateWithTokenError} Richiedine uno nuovo nella{" "}
        <Link to="/activate" className="text-light">
          pagina di attivazione
        </Link>
      </Alert>
    ) : null}
    <Button color="primary" block>
      {props.submitting ? <IconSpinner className="icon-spin" /> : ""}{" "}
      {props.submitText}
    </Button>
  </Form>
);

export default reduxForm<IActivateWithTokenFormData, IProps>({
  form: "activateForm",
  onSubmitFail: focusFirstInvalid,
  validate,
})(ActivateWithTokenForm);
