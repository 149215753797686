import React from "react";
import {connect} from "react-redux";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import {Field, InjectedFormProps, reduxForm} from "redux-form";

import {dehydrateCap, ICap, ICapEntity, IStateUi} from "../";
import {focusFirstInvalid} from "../../helpers/focusFirstInvalid";
import RenderField from "../../helpers/RenderField";
import RenderMultiField from "../../helpers/RenderMultiField";
import IconSpinner from "../../Icons/IconSpinner";
import {IRootState} from "../../redux/reducers";
import domandeIdd from "./domandeIdd";

export type MultiKeys = "question_c6";
export type SingleKeys =
  | "clientType"
  | "name"
  | "surname"
  | "birthPlace"
  | "birthday"
  | "fiscalCode"
  | "address"
  | "question_b1"
  | "question_c1"
  | "question_c2"
  | "question_c3"
  | "question_c4"
  | "question_c5"
  | "question_d1"
  | "question_d2"
  | "question_d3"
  | "question_d4"
  | "question_d5"
  | "question_e1"
  | "question_e2"
  | "question_e3"
  | "question_e4";
type IIddFormErrors = {[K in SingleKeys]?: string};
export type IIddFormData = {[K in SingleKeys]?: string} &
  {[K in MultiKeys]?: boolean[]};

interface IOwnProps {
  cap: ICap;
  ui: IStateUi;
  closeIdd?: () => void;
  iddSubmit: (values: IIddFormData, cap: ICapEntity) => Promise<void>;
}

const validate = (values: IIddFormData) => {
  const errors: IIddFormErrors = {};

  if (!values.name) {
    errors.name = "Rispondi a questa domanda";
  }
  if (!values.surname) {
    errors.surname = "Rispondi a questa domanda";
  }
  if (!values.birthPlace) {
    errors.birthPlace = "Rispondi a questa domanda";
  }
  if (!values.birthday) {
    errors.birthday = "Rispondi a questa domanda";
  }
  if (!values.fiscalCode) {
    errors.fiscalCode = "Rispondi a questa domanda";
  }
  if (!values.address) {
    errors.address = "Rispondi a questa domanda";
  }
  if (!values.question_b1) {
    errors.question_b1 = "Rispondi a questa domanda";
  }
  if (!values.question_c1) {
    errors.question_c1 = "Rispondi a questa domanda";
  }
  if (!values.question_c2) {
    errors.question_c2 = "Rispondi a questa domanda";
  }
  if (!values.question_c3) {
    errors.question_c3 = "Rispondi a questa domanda";
  }
  if (!values.question_c4) {
    errors.question_c4 = "Rispondi a questa domanda";
  }
  if (!values.question_c5) {
    errors.question_c5 = "Rispondi a questa domanda";
  }
  if (!values.question_d1) {
    errors.question_d1 = "Rispondi a questa domanda";
  }
  if (!values.question_d2) {
    errors.question_d2 = "Rispondi a questa domanda";
  }
  if (!values.question_d3) {
    errors.question_d3 = "Rispondi a questa domanda";
  }
  if (!values.question_d4) {
    errors.question_d4 = "Rispondi a questa domanda";
  }
  if (!values.question_d5) {
    errors.question_d5 = "Rispondi a questa domanda";
  }
  if (!values.question_e1) {
    errors.question_e1 = "Rispondi a questa domanda";
  }
  if (!values.question_e2) {
    errors.question_e2 = "Rispondi a questa domanda";
  }
  if (!values.question_e3) {
    errors.question_e3 = "Rispondi a questa domanda";
  }
  if (!values.question_e4) {
    errors.question_e4 = "Rispondi a questa domanda";
  }

  return errors;
};

const IddForm: React.FC<
  InjectedFormProps<IIddFormData, IOwnProps> & IOwnProps
> = (props) => {
  const contractor = props.cap!.contractor;
  const submitWithCap = async (values: IIddFormData) => {
    const {
      result,
      entities: {caps},
    } = dehydrateCap(props.cap);
    await props.iddSubmit(values, caps[result]);
  };
  return (
    <Form onSubmit={props.handleSubmit(submitWithCap)}>
      {contractor && <h2>Questionario Valutazione Adeguatezza IBIPs</h2>}
      {contractor && (
        <Card className="mb-3" hidden>
          <Field
            component={RenderField}
            id="clientType"
            name="clientType"
            type="hidden"
          />
          <CardHeader className="bg-primary text-white">
            Dati anagrafici
          </CardHeader>
          <CardBody>
            <FormGroup>
              <Label for="name">Nome</Label>
              <Field
                component={RenderField}
                id="name"
                name="name"
                readOnly
                type="text"
                valid
              />
            </FormGroup>
            <FormGroup>
              <Label for="surname">Cognome</Label>
              <Field
                component={RenderField}
                id="surname"
                name="surname"
                readOnly
                type="text"
                valid
              />
            </FormGroup>
            <FormGroup>
              <Label for="birthPlace">Luogo di nascita</Label>
              <Field
                component={RenderField}
                id="birthPlace"
                name="birthPlace"
                readOnly
                type="text"
                valid
              />
            </FormGroup>
            <FormGroup>
              <Label for="birthday">Data di nascita</Label>
              <Field
                component={RenderField}
                id="birthday"
                name="birthday"
                readOnly
                type="text"
                valid
              />
            </FormGroup>
            <FormGroup>
              <Label for="fiscalCode">Codice fiscale</Label>
              <Field
                component={RenderField}
                id="fiscalCode"
                name="fiscalCode"
                readOnly
                type="text"
                valid
              />
            </FormGroup>
            <FormGroup>
              <Label for="address">Indirizzo</Label>
              <Field
                component={RenderField}
                id="address"
                name="address"
                readOnly={!!props.initialValues.address}
                type="text"
                valid={!!props.initialValues.address}
              />
            </FormGroup>
          </CardBody>
        </Card>
      )}
      {domandeIdd.sections.map((section) => (
        <Card key={section.title} className="mb-3">
          <CardHeader className="bg-primary text-white">
            {section.title}
          </CardHeader>
          <CardBody>
            {section.questions.map((question) => (
              <React.Fragment key={question.name}>
                <Label>
                  <strong>{question.label}</strong>
                </Label>
                {question.multiple ? (
                  question.answers.map((answer) => (
                    <FormGroup check key={answer.value}>
                      <Field
                        disabled={!!question.getInitialValue}
                        component={RenderField}
                        id={question.name + "-" + answer.value}
                        name={`${question.name}[${answer.value}]`}
                        type="checkbox"
                      />
                      <Label
                        for={question.name + "-" + answer.value}
                        check
                        style={
                          !question.getInitialValue
                            ? {color: "currentColor"}
                            : {}
                        }
                      >
                        {answer.text}
                      </Label>
                    </FormGroup>
                  ))
                ) : (
                  <RenderMultiField
                    disabled={!!question.getInitialValue}
                    id={question.name}
                    name={question.name}
                    type="radio"
                    items={question.answers}
                    inline={false}
                  />
                )}
              </React.Fragment>
            ))}
          </CardBody>
        </Card>
      ))}
      {props.ui.isIddFail && <Alert color="danger">{props.ui.iddError}</Alert>}
      <Button type="submit" color="primary" outline disabled={props.submitting}>
        {props.submitting ? <IconSpinner className="icon-spin" /> : ""} Salva e
        torna all'analisi
      </Button>{" "}
      <Button type="button" color="secondary" outline onClick={props.closeIdd}>
        Annulla
      </Button>
    </Form>
  );
};

const IddFormConnected = reduxForm({
  form: "iddForm",
  onSubmitFail: focusFirstInvalid,
  validate,
})(IddForm);

export default connect((state: IRootState, ownProps: IOwnProps) => {
  const initialValues: IIddFormData = {
    clientType: "1",
    // question_c6: []
  };

  const item = ownProps.cap;
  const contractor = item!.contractor;

  if (contractor) {
    (["name", "surname", "fiscalCode", "address"] as const).forEach((key) => {
      initialValues[key] = contractor[key];
    });
    initialValues.birthday =
      contractor.birthday && contractor.birthday.format("LL");
    initialValues.birthPlace = contractor.birthplace; // Occhio alle maiuscole!
    initialValues.address =
      item &&
      item.data.home &&
      `${item.data.home.address}, ${item.data.home.zipCode}, ${item.data.home.city} (${item.data.home.region})`;

    // Pre-compilo le domande che posso inferire dai dati del D&N
    domandeIdd.sections.forEach((section) =>
      section.questions.forEach((question) => {
        if (!!question.getInitialValue) {
          (initialValues as any)[question.name] = question.getInitialValue(
            item!
          );
        }
      })
    );
  }

  return {
    initialValues,
  };
})(IddFormConnected);
