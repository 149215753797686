import * as React from "react";
import {Container} from "reactstrap";
import {Layout} from ".";

const HelpPage = () => (
  <Layout isProtected>
    <Container>
      <h2>Prevision Family Help</h2>
    </Container>
    <Container className="container-max auto-margin-4 p-md-5">
      <div>
        Per consultare la guida completa relativa a questa piattaforma,
        collegati al seguente indirizzo{" "}
        <a
          href="https://help.prevision.family"
          target="_blank"
          rel="noopener noreferrer"
        >
          <strong>https://help.prevision.family</strong>
        </a>
        <br />
        ed inserisci le seguenti credenziali:
        <p className="p-3">
          Nome utente:{" "}
          <strong className="pl-3">{process.env.REACT_APP_HELP_USER}</strong>
          <br />
          Password:{" "}
          <strong className="pl-3">
            {process.env.REACT_APP_HELP_PASSWORD}
          </strong>
        </p>
      </div>
    </Container>
  </Layout>
);

export default HelpPage;
