import React, {useState} from "react";
import {Button, Container, FormGroup, Input, Label} from "reactstrap";
import {IconCheckmark, IconClock, IconDownload, IconESign} from "../../Icons";
import {IRecommendation} from "../types";

interface IProps {
  isAdvisor: boolean;
  isContractor: boolean;
  openRequestOTPModalWithId: () => void;
  recommendation: IRecommendation;
}

const AdvisorButton = ({
  isAdvisor,
  openRequestOTPModalWithId,
  recommendation,
}: IProps) => {
  if (recommendation.userEsignId === "0") {
    return null;
  }

  return (
    <>
      {isAdvisor ? (
        recommendation.userEsignId ? (
          <Button color="primary" className="mr-3" outline disabled>
            <IconCheckmark /> Hai firmato la raccomandazione
          </Button>
        ) : (
          <Button
            color="primary"
            className="mr-3"
            outline
            onClick={openRequestOTPModalWithId}
          >
            <IconESign /> Firma la raccomandazione
          </Button>
        )
      ) : recommendation.userEsignId ? (
        <Button color="primary" className="mr-3" outline disabled>
          <IconCheckmark /> L'Advisor ha firmato la raccomandazione
        </Button>
      ) : (
        <Button color="primary" className="mr-3" outline disabled>
          <IconClock /> In attesa della firma dell'Advisor
        </Button>
      )}
    </>
  );
};

const ContractorButton = ({
  isContractor,
  openRequestOTPModalWithId,
  recommendation,
  disabled,
}: IProps & {disabled: boolean}) => (
  <>
    {isContractor ? (
      recommendation.esignId ? (
        <Button color="primary" outline disabled>
          <IconCheckmark /> Hai firmato la raccomandazione
        </Button>
      ) : recommendation.userEsignId ? (
        <Button
          color="primary"
          outline
          disabled={disabled}
          onClick={openRequestOTPModalWithId}
        >
          <IconESign /> Firma la raccomandazione
        </Button>
      ) : (
        <Button color="primary" outline disabled>
          <IconClock /> Prima di poter firmare devi attendere la firma del tuo
          Advisor
        </Button>
      )
    ) : recommendation.esignId ? (
      <Button color="primary" outline disabled>
        <IconCheckmark /> Il cliente ha firmato la raccomandazione
      </Button>
    ) : (
      <Button color="primary" outline disabled>
        <IconClock /> In attesa della firma del cliente
      </Button>
    )}
  </>
);

const ESignBar = (props: IProps) => {
  const [flags, setFlags] = useState({
    info: !!props.recommendation.docs?.flagReadSetInformativo,
    att4bis: !!props.recommendation.docs?.flagReadAllegato4Bis,
  });

  const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log({flags});
    setFlags((flags) => ({
      ...flags,
      [event.target.name]: event.target.checked,
    }));
    console.log({
      flags: {
        ...flags,
        [event.target.name]: event.target.checked,
      },
    });
  };

  return (
    <Container fluid className="fixedValidation">
      <div className="text-center">
        {(!props.recommendation.esignId || props.recommendation.docs) &&
          props.isContractor && (
            <FormGroup tag="fieldset">
              <FormGroup check>
                <Input
                  type="checkbox"
                  name="info"
                  id="info"
                  disabled={
                    !!props.recommendation.docs ||
                    !props.recommendation.userEsignId
                  }
                  checked={flags.info}
                  onChange={handleCheck}
                />
                <Label for="info" check>
                  Ho ricevuto e letto il set informativo{" "}
                  <a
                    className="ml-4"
                    href={props.recommendation.options[0]?.option.product.url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <strong>
                      <IconDownload /> Scarica
                    </strong>
                  </a>
                </Label>
              </FormGroup>
              <FormGroup check>
                <Input
                  type="checkbox"
                  name="att4bis"
                  id="att4bis"
                  disabled={
                    !!props.recommendation.docs ||
                    !props.recommendation.userEsignId
                  }
                  checked={flags.att4bis}
                  onChange={handleCheck}
                />
                <Label for="att4bis" check>
                  Ho ricevuto e letto l'allegato 4bis{" "}
                  <a
                    className="ml-4"
                    href={`${process.env.REACT_APP_API_BASE_URL}recommendations/${props.recommendation.id}/get-pdf-allegato4bis`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <strong>
                      <IconDownload /> Scarica
                    </strong>
                  </a>
                </Label>
              </FormGroup>
            </FormGroup>
          )}
        {props.recommendation.esignId && props.recommendation.userEsignId ? (
          <Button
            color="primary"
            outline
            download
            href={`${process.env.REACT_APP_API_BASE_URL}recommendations/${props.recommendation.id}/get-pdf`}
          >
            <IconDownload title="Scarica la raccomandazione personalizzata" />{" "}
            Scarica la raccomandazione personalizzata
          </Button>
        ) : (
          <>
            <AdvisorButton {...props} />
            <ContractorButton
              {...props}
              disabled={!flags.info || !flags.att4bis}
            />
          </>
        )}
      </div>
    </Container>
  );
};

export default ESignBar;
