import {faExclamationCircle} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import isEmpty from "lodash/isEmpty";
import React from "react";
import {FieldErrors, useForm} from "react-hook-form";
import {useSelector} from "react-redux";
import {
  Alert,
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import {ucWordsNormalizer} from "../../helpers/normalizers";
import {IconInfoCircle, IconSpinner} from "../../Icons";
import {getCapsUi} from "../selectors";

export type IDeliveryFormData = {
  ibips: boolean;
  lifeNotIbips: boolean;
  loss: boolean;
  place: string;
};
interface IProps {
  hasLifeNotIbips: boolean;
  hasIbips: boolean;
  hasLoss: boolean;
  hasOverdrafts: boolean;
  initialValues?: Partial<IDeliveryFormData>;
  onCancel?: () => void;
  onSubmit: (values: IDeliveryFormData) => void;
}

const validate = async (values: IDeliveryFormData) => {
  const errors: FieldErrors<IDeliveryFormData> = {};

  if (values && !values.ibips && !values.lifeNotIbips && !values.loss) {
    errors.ibips = {
      type: "required",
      message: "Seleziona almeno una tipologia di prodotto",
    };
  }

  if (values && !values.place) {
    errors.place = {
      type: "required",
      message: "Inserisci un luogo",
    };
  }

  return {values: isEmpty(errors) ? values : {}, errors};
};

const DeliveryForm = ({
  hasLifeNotIbips,
  hasIbips,
  hasLoss,
  hasOverdrafts,
  initialValues,
  onCancel,
  onSubmit,
}: IProps) => {
  const capUi = useSelector(getCapsUi);
  const {
    errors,
    formState,
    register,
    handleSubmit,
  } = useForm<IDeliveryFormData>({
    defaultValues: {...{place: "place"}, ...initialValues},
    resolver: validate,
  });

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className="auto-margin-3">
      {(!hasLifeNotIbips || !hasOverdrafts) && (
        <span className="text-danger">
          <IconInfoCircle className="mr-2" />
          Opzione non selezionabile in quanto non è
          {hasOverdrafts
            ? ` stata compilata la sezione
          "Tutelare il tenore di vita personale e familiare" nel blocco delle
          scoperture`
            : ` stata completata la sezione delle scoperture`}
        </span>
      )}
      <FormGroup check>
        <Label check>
          <Input
            type="checkbox"
            name="lifeNotIbips"
            innerRef={register}
            disabled={!hasLifeNotIbips}
          />{" "}
          Prodotti assicurativi del Ramo Vita diversi dai Prodotti di
          Investimento Assicurativo
          <ol type="I">
            <li>DIP Vita;</li>
            <li>DIP Aggiuntivo Vita o DIP Aggiuntivo Multirischi;</li>
            <li>Condizioni di assicurazione comprensive del glossario;</li>
            <li>Modulo di proposta e, ove non previsto, Modulo di polizza.</li>
          </ol>
        </Label>
      </FormGroup>
      {!hasIbips && (
        <span className="text-danger">
          <IconInfoCircle className="mr-2" />
          Opzione non selezionabile in quanto non è stata compilata la sezione
          IBIPs
        </span>
      )}
      <FormGroup check>
        <Label check>
          <Input
            type="checkbox"
            name="ibips"
            innerRef={register}
            disabled={!hasIbips}
          />{" "}
          Prodotti di Investimento Assicurativo
          <ol type="I">
            <li>Documento contenente le informazioni chiave (KID);</li>
            <li>DIP Aggiuntivo IBIP;</li>
            <li>Condizioni di assicurazione comprensive del glossario;</li>
            <li>Modulo di proposta e, ove non previsto, Modulo di polizza.</li>
          </ol>
        </Label>
      </FormGroup>
      {(!hasLoss || !hasOverdrafts) && (
        <span className="text-danger">
          <IconInfoCircle className="mr-2" />
          Opzione non selezionabile in quanto non è
          {hasOverdrafts
            ? ` stata compilata né la
          sezione "Tutelare beni, casa, animali" né la sezione "Tutelarsi da
          infortuni, malattia & prevenzione" nel blocco delle scoperture`
            : ` stata completata la sezione delle scoperture`}
        </span>
      )}
      <FormGroup check>
        <Label check>
          <Input
            type="checkbox"
            name="loss"
            innerRef={register}
            disabled={!hasLoss}
          />{" "}
          Prodotti assicurativi del Ramo Danni
          <ol type="I">
            <li>DIP;</li>
            <li>DIP Aggiuntivo Danni o DIP Aggiuntivo R.C. Auto;</li>
            <li>Condizioni di assicurazione comprensive del glossario;</li>
            <li>Modulo di proposta e, ove non previsto, Modulo di polizza.</li>
          </ol>
        </Label>
      </FormGroup>
      <FormGroup>
        <Label for="place">Luogo</Label>
        <Input
          type="text"
          name="place"
          id="place"
          placeholder="Luogo"
          innerRef={register}
          onChange={(event) => {
            const {value} = event.target;
            event.target.value = ucWordsNormalizer(value);
          }}
          invalid={!!errors?.place}
          valid={formState.touched.place && !errors?.place}
        />
        {errors?.place && <FormFeedback>{errors.place.message}</FormFeedback>}
      </FormGroup>
      {capUi.isDeliveryFail && (
        <Alert color="danger">{capUi.deliveryError}</Alert>
      )}
      {errors?.ibips && (
        <p className="text-danger">
          <FontAwesomeIcon icon={faExclamationCircle} className="mr-2" />
          {errors.ibips.message}
        </p>
      )}
      <div>
        <Button color="primary" outline>
          {capUi.isDeliveryPending && <IconSpinner className="icon-spin" />}{" "}
          Salva
        </Button>
        <Button color="secondary" outline onClick={onCancel} className="ml-2">
          Annulla
        </Button>
      </div>
    </Form>
  );
};

export default DeliveryForm;
