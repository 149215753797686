import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {
  Alert,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";

import {Layout} from "../App";
import {IRootState} from "../redux/reducers";
import {actions, ActivateForm, IActivateFormData, IState} from "./";

import "./LoginPage.scss";

const mapStateToProps = (state: IRootState): IState => state.user;
const mapDispatchToProps = {
  onSubmitForm: (values: IActivateFormData) => {
    return actions.activate(values);
  },
};

const Page = (props: any) => (
  <Layout hasMenu={false}>
    <Container className="main-center auto-margin-4">
      <Row>
        <Col
          sm={{size: 8, offset: 2}}
          md={{size: 6, offset: 3}}
          lg={{size: 4, offset: 4}}
        >
          <img
            src="/img/logo.svg"
            className="logo mx-auto d-block img-fluid"
            alt={`${process.env.REACT_APP_TITLE} logo`}
          />
        </Col>
      </Row>
      <Row>
        <Col
          sm={{size: 8, offset: 2}}
          md={{size: 6, offset: 3}}
          lg={{size: 4, offset: 4}}
        >
          {props.isActivateSuccess ? (
            <Alert color="success">
              <h3 className="alert-heading">Attiva il tuo account</h3>
              Controlla la tua casella di posta "{props.activateEmail}
              ".
              <br />
              Ti abbiamo inviato una email con il link per impostare la tua
              password e attivare il tuo account.
            </Alert>
          ) : (
            <Card className="text-primary">
              <CardBody>
                <CardTitle>
                  <h3>Attiva il tuo account</h3>
                </CardTitle>
                <CardText>
                  Per accedere all applicazione devi impostare la tua prima
                  password personale.
                  <br />
                  Inserisci la tua mail e li ti verranno inviate le istruzioni
                  per concludere la procedura.
                </CardText>
                <ActivateForm onSubmit={props.onSubmitForm} {...props} />
              </CardBody>
            </Card>
          )}
          <Link to="/" className="text-light">
            Torna alla pagina di accesso
          </Link>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default connect(mapStateToProps, mapDispatchToProps)(Page);
