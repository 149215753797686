import classNames from "classnames";
import moment from "moment";
import React from "react";
import {DropzoneState} from "react-dropzone";
import {connect} from "react-redux";
import {Alert, Button, Col, Form, FormGroup, Label, Row} from "reactstrap";
import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import {focusFirstInvalid} from "../helpers/focusFirstInvalid";
import {birthdayFormatter} from "../helpers/formatters";
import RenderDropzoneField, {
  DropzoneStatus,
} from "../helpers/RenderDropzoneField";
import RenderField from "../helpers/RenderField";
import {dateIt} from "../helpers/validators";
import {
  IconIdBack,
  IconIdFront,
  IconSpinner,
  IconIdFace,
  IconFiscalCode,
} from "../Icons";
import {IRootState} from "../redux/reducers";
import IApiError from "../types/IApiError";

import "./UploadIdForm.scss";

export enum IdTypes {
  Card = "Carta d'identità",
  Passport = "Passaporto",
}

export interface IUploadIdFormData {
  idType: IdTypes;
  idNumber: string;
  releasedBy: string;
  releaseDate: string;
  expirationDate: string;
  idFront: File;
  idBack: File;
  idFace: File;
  idFC: File;
}

interface IUploadIdFormErrors {
  idType?: string;
  idNumber?: string;
  releasedBy?: string;
  releaseDate?: string;
  expirationDate?: string;
  idFront?: string;
  idBack?: string;
  idFace?: string;
  idFC?: string;
}

interface IStateProps {
  frontThumbnail: File;
  backThumbnail: File;
  faceThumbnail: File;
  fCThumbnail: File;
}

interface IOwnProps {
  closeIdentification: () => void;
  uploadIdFail: (error: IApiError) => void;
  uploadIdSubmit: (values: IUploadIdFormData) => Promise<void>;
  isUploadIdFail: boolean | undefined;
  uploadIdError: string | undefined;
}

type IProps = IStateProps & IOwnProps & InjectedFormProps<IUploadIdFormData>;

const useRenderIdFrontInner = ({
  getInputProps,
  getRootProps,
  id,
  isDragAccept,
  isDragActive,
  isDragReject,
  status,
  thumb,
}: DropzoneState & {
  id: string;
  status: DropzoneStatus;
  thumb: File;
}): JSX.Element => {
  const [thumbUrl, setThumbUrl] = React.useState<string>();
  React.useEffect(() => {
    setThumbUrl(thumb && URL.createObjectURL(thumb));
  }, [thumb]);

  return (
    <>
      <div
        {...getRootProps()}
        className={classNames(
          "dropzone-area-id aspect-ratio aspect-ratio-square",
          {
            ...{
              "dropzone-area-id--isAccepted": isDragAccept,
              "dropzone-area-id--isActive": isDragActive,
              "dropzone-area-id--isRejected": isDragReject,
            },
            ...{
              "dropzone-area-id--isDanger": status === "danger",
              "dropzone-area-id--isSuccess": status === "success",
              "dropzone-area-id--isWarning": status === "warning",
            },
          }
        )}
      >
        <input {...getInputProps()} id={id} />
        {thumb && (
          <div
            className="dropzone-area-id-thumbnail"
            style={{
              backgroundImage: `url(${thumbUrl})`,
            }}
          />
        )}
        <div
          className={classNames(
            "dropzone-area-id-placeholder align-items-center",
            {
              "has-thumbnail": thumb,
            }
          )}
        >
          <p className="mb-0">
            Trascina il file qui, oppure clicca per cercare il file sul tuo
            computer
          </p>
          <IconIdFront size={5} />
        </div>
      </div>
    </>
  );
};

const useRenderIdBackInner = ({
  getInputProps,
  getRootProps,
  id,
  isDragAccept,
  isDragActive,
  isDragReject,
  status,
  thumb,
}: DropzoneState & {
  id: string;
  status: DropzoneStatus;
  thumb: File;
}): JSX.Element => {
  const [thumbUrl, setThumbUrl] = React.useState<string>();
  React.useEffect(() => {
    setThumbUrl(thumb && URL.createObjectURL(thumb));
  }, [thumb]);

  return (
    <>
      <div
        {...getRootProps()}
        className={classNames(
          "dropzone-area-id aspect-ratio aspect-ratio-square",
          {
            ...{
              "dropzone-area-id--isAccepted": isDragAccept,
              "dropzone-area-id--isActive": isDragActive,
              "dropzone-area-id--isRejected": isDragReject,
            },
            ...{
              "dropzone-area-id--isDanger": status === "danger",
              "dropzone-area-id--isSuccess": status === "success",
              "dropzone-area-id--isWarning": status === "warning",
            },
          }
        )}
      >
        <input {...getInputProps()} id={id} />
        {thumb && (
          <div
            className="dropzone-area-id-thumbnail"
            style={{
              backgroundImage: `url(${thumbUrl})`,
            }}
          />
        )}
        <div
          className={classNames(
            "dropzone-area-id-placeholder align-items-center",
            {
              "has-thumbnail": thumb,
            }
          )}
        >
          <p className="mb-0">
            Trascina il file qui, oppure clicca per cercare il file sul tuo
            computer
          </p>
          <IconIdBack size={5} />
        </div>
      </div>
    </>
  );
};

const useRenderIdFaceInner = ({
  getInputProps,
  getRootProps,
  id,
  isDragAccept,
  isDragActive,
  isDragReject,
  status,
  thumb,
}: DropzoneState & {
  id: string;
  status: DropzoneStatus;
  thumb: File;
}): JSX.Element => {
  const [thumbUrl, setThumbUrl] = React.useState<string>();
  React.useEffect(() => {
    setThumbUrl(thumb && URL.createObjectURL(thumb));
  }, [thumb]);

  return (
    <>
      <div
        {...getRootProps()}
        className={classNames(
          "dropzone-area-id aspect-ratio aspect-ratio-square",
          {
            ...{
              "dropzone-area-id--isAccepted": isDragAccept,
              "dropzone-area-id--isActive": isDragActive,
              "dropzone-area-id--isRejected": isDragReject,
            },
            ...{
              "dropzone-area-id--isDanger": status === "danger",
              "dropzone-area-id--isSuccess": status === "success",
              "dropzone-area-id--isWarning": status === "warning",
            },
          }
        )}
      >
        <input {...getInputProps()} id={id} />
        {thumb && (
          <div
            className="dropzone-area-id-thumbnail"
            style={{
              backgroundImage: `url(${thumbUrl})`,
            }}
          />
        )}
        <div
          className={classNames(
            "dropzone-area-id-placeholder align-items-center",
            {
              "has-thumbnail": thumb,
            }
          )}
        >
          <p className="mb-0">
            Trascina il file qui, oppure clicca per cercare il file sul tuo
            computer
          </p>
          <IconIdFace size={5} />
        </div>
      </div>
    </>
  );
};

const useRenderIdFCInner = ({
  getInputProps,
  getRootProps,
  id,
  isDragAccept,
  isDragActive,
  isDragReject,
  status,
  thumb,
}: DropzoneState & {
  id: string;
  status: DropzoneStatus;
  thumb: File;
}): JSX.Element => {
  const [thumbUrl, setThumbUrl] = React.useState<string>();
  React.useEffect(() => {
    setThumbUrl(thumb && URL.createObjectURL(thumb));
  }, [thumb]);

  return (
    <>
      <div
        {...getRootProps()}
        className={classNames(
          "dropzone-area-id aspect-ratio aspect-ratio-square",
          {
            ...{
              "dropzone-area-id--isAccepted": isDragAccept,
              "dropzone-area-id--isActive": isDragActive,
              "dropzone-area-id--isRejected": isDragReject,
            },
            ...{
              "dropzone-area-id--isDanger": status === "danger",
              "dropzone-area-id--isSuccess": status === "success",
              "dropzone-area-id--isWarning": status === "warning",
            },
          }
        )}
      >
        <input {...getInputProps()} id={id} />
        {thumb && (
          <div
            className="dropzone-area-id-thumbnail"
            style={{
              backgroundImage: `url(${thumbUrl})`,
            }}
          />
        )}
        <div
          className={classNames(
            "dropzone-area-id-placeholder align-items-center",
            {
              "has-thumbnail": thumb,
            }
          )}
        >
          <p className="mb-0">
            Trascina il file qui, oppure clicca per cercare il file sul tuo
            computer
          </p>
          <IconFiscalCode size={5} />
        </div>
      </div>
    </>
  );
};

const validate = (values: IUploadIdFormData) => {
  const errors: IUploadIdFormErrors = {};

  if (!values.idType) {
    errors.idType = "Il tipo è obbligatorio";
  }

  if (!values.idNumber) {
    errors.idNumber = "Il numero è obbligatorio";
  }

  if (!values.releasedBy) {
    errors.releasedBy = `Il campo "rilasciato da" è obbligatorio`;
  }

  if (!values.releaseDate) {
    errors.releaseDate = "La data di rilascio è obbligatorio";
  } else if (
    !dateIt(values.releaseDate as any) ||
    !moment(values.releaseDate, "DD-MM-YYYY").isValid()
  ) {
    errors.releaseDate =
      "La data di rilascio non è nel formato corretto gg/mm/aaaa";
  } else if (moment(values.releaseDate, "DD-MM-YYYY").isAfter(moment())) {
    errors.releaseDate =
      "La data di rilascio non può essere successiva alla data odierna.";
  }

  if (!values.expirationDate) {
    errors.expirationDate = "La scadenza è obbligatorio";
  } else if (
    !dateIt(values.expirationDate as any) ||
    !moment(values.expirationDate, "DD-MM-YYYY").isValid()
  ) {
    errors.expirationDate = "La scadenza non è nel formato corretto gg/mm/aaaa";
  } else if (moment(values.expirationDate, "DD-MM-YYYY").isBefore(moment())) {
    errors.expirationDate = "Il documento è scaduto";
  }

  if (!values.idFront) {
    errors.idFront = "Documento / fotografia frontale è obbligatorio";
  }
  if (!values.idBack) {
    errors.idBack = "Documento / fotografia retro è obbligatorio";
  }
  if (!values.idFace) {
    errors.idFace =
      "Fotografia del viso del cliente affiancato al documento di identità è obbligatorio";
  }
  if (!values.idFC) {
    errors.idFC =
      "Fotografia della Tessera Sanitaria o del Codice Fiscale è obbligatorio";
  }

  return errors;
};

const warn = (values: IUploadIdFormData) => {
  const warnings: IUploadIdFormErrors = {};

  if (
    moment(values.expirationDate, "DD-MM-YYYY").isBefore(
      moment().add(15, "days")
    )
  ) {
    warnings.expirationDate = "Il documento sta per scadere";
  }

  return warnings;
};

const UploadIdForm: React.FC<IProps> = (props) => {
  return (
    <Form onSubmit={props.handleSubmit(props.uploadIdSubmit)}>
      <FormGroup>
        <Label for="idType">Tipo di documento</Label>
        <Field id="idType" name="idType" type="select" component={RenderField}>
          <option value="">Seleziona</option>
          {Object.keys(IdTypes).map((key) => (
            <option key={key} value={key}>
              {IdTypes[key as keyof typeof IdTypes]}
            </option>
          ))}
        </Field>
      </FormGroup>
      <FormGroup>
        <Label for="idNumber">Numero documento</Label>
        <Field
          id="idNumber"
          name="idNumber"
          component={RenderField}
          type="text"
          placeholder="Numero documento"
        />
      </FormGroup>
      <FormGroup>
        <Label for="releasedBy">Rilasciato da</Label>
        <Field
          id="releasedBy"
          name="releasedBy"
          component={RenderField}
          type="text"
          placeholder="Rilasciato da"
        />
      </FormGroup>
      <FormGroup>
        <Label for="releaseDate">In data</Label>
        <Field
          id="releaseDate"
          name="releaseDate"
          component={RenderField}
          type="text"
          placeholder="In data (gg/mm/aaaa)"
          format={birthdayFormatter}
        />
      </FormGroup>
      <FormGroup>
        <Label for="releaseDate">Scadenza</Label>
        <Field
          id="expirationDate"
          name="expirationDate"
          component={RenderField}
          type="text"
          placeholder="Scadenza (gg/mm/aaaa)"
          format={birthdayFormatter}
        />
      </FormGroup>
      <Row className="upload-form-groups">
        <Col>
          <FormGroup>
            <Label for="idFront">Documento / fotografia frontale</Label>
            <Field
              id="idFront"
              name="idFront"
              component={RenderDropzoneField}
              renderContent={useRenderIdFrontInner}
              thumb={props.frontThumbnail}
              identificationFail={props.uploadIdFail}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="idBack">Documento / fotografia retro</Label>
            <Field
              id="idBack"
              name="idBack"
              component={RenderDropzoneField}
              renderContent={useRenderIdBackInner}
              thumb={props.backThumbnail}
              identificationFail={props.uploadIdFail}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="idFace">
              Fotografia del viso del cliente affiancato al documento di
              identità
            </Label>
            <Field
              id="idFace"
              name="idFace"
              component={RenderDropzoneField}
              renderContent={useRenderIdFaceInner}
              thumb={props.faceThumbnail}
              identificationFail={props.uploadIdFail}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label for="idFC">
              Fotografia del fronte della Tessera Sanitaria o del Codice Fiscale
            </Label>
            <Field
              id="idFC"
              name="idFC"
              component={RenderDropzoneField}
              renderContent={useRenderIdFCInner}
              thumb={props.fCThumbnail}
              identificationFail={props.uploadIdFail}
            />
          </FormGroup>
        </Col>
      </Row>
      {props.isUploadIdFail && (
        <Alert color="danger">{props.uploadIdError}</Alert>
      )}
      <Button type="submit" color="primary" outline disabled={props.submitting}>
        {props.submitting ? <IconSpinner className="icon-spin" /> : ""} Salva e
        torna
      </Button>{" "}
      <Button
        type="button"
        color="secondary"
        outline
        onClick={props.closeIdentification}
      >
        Annulla
      </Button>
    </Form>
  );
};

const UploadIdFormConnected = reduxForm({
  form: "uploadIdForm",
  onSubmitFail: focusFirstInvalid,
  validate,
  warn,
})(UploadIdForm);

const selector = formValueSelector("uploadIdForm");
export default connect((state: IRootState) => {
  return {
    faceThumbnail: selector(state, "idFace"),
    backThumbnail: selector(state, "idBack"),
    frontThumbnail: selector(state, "idFront"),
    fCThumbnail: selector(state, "idFC"),
  };
})(UploadIdFormConnected);
