import React from "react";
import {Spring} from "react-spring/renderprops";
import {
  Button,
  Card,
  CardTitle,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import {debounce} from "throttle-debounce";
import {
  IconBan,
  IconCheckmark,
  IconChevronUp,
  IconClock,
  IconDownload,
  IconSendNotification,
  IconSpinner,
  IconTimes,
} from "../Icons";

import "./Progress.scss";
import {IAppThunkActionStates} from "../types/thunk";

interface IProps {
  capId: string;
  capReady: boolean;
  chooseCap: () => void;
  closeValidation: (capId: string, responseValidation: 0 | 1) => Promise<void>;
  denDisabled: boolean;
  denHandleClick: () => void;
  denState: boolean;
  hasSubscription: boolean;
  hasSuitableOption: boolean;
  iddDisabled: boolean;
  iddHandleClick: () => Promise<void>;
  iddState: boolean;
  isCloseDenPending: boolean;
  isContractor: boolean;
  isDelivered: boolean;
  isIddSendPending: boolean;
  isLast: boolean;
  isOverdraftPending: boolean;
  isRejected: boolean;
  isRequestValidationPending: boolean;
  isSigned: boolean;
  isValidated: boolean;
  isValidationRequested: boolean;
  openRequestOTPCapModal: (capId: string) => void;
  overdraftDisabled: boolean;
  overdraftHandleClick: () => void;
  overdraftOneComplete: boolean;
  overdraftState: boolean;
  privacySubscriptionNotificationStates: IAppThunkActionStates;
  requestValidation: () => Promise<void>;
  scrollToIdd: () => void;
  scrollToOverdraft: () => void;
  scrollToSendCredentials: () => void;
  sendPrivacySubscriptionNotification: () => void;
}

interface IState {
  isBottom: boolean;
  isOpen: boolean;
  isStuck: boolean;
  maxBottom: number;
}

class Progress extends React.PureComponent<IProps, IState> {
  private static outerHeight() {
    const thisElement = document.getElementById("cap-progress");
    return thisElement!.offsetHeight;
  }

  private static getFooterHeight() {
    const footerElement = document.getElementById("app-footer");
    const footerHeight = footerElement!.offsetHeight;
    const footerMargin =
      parseFloat(
        window
          .getComputedStyle(footerElement!, "")
          .getPropertyValue("margin-top")
      ) +
      parseFloat(
        window
          .getComputedStyle(footerElement!, "")
          .getPropertyValue("margin-bottom")
      );
    return footerHeight + footerMargin;
  }

  private static getDistanceFromBottom() {
    const rootElement = document.getElementById("root");
    return rootElement!.offsetHeight - window.innerHeight - window.scrollY;
  }

  private static getElementMaxBottom() {
    const maxBottom =
      Progress.outerHeight() +
      Progress.getFooterHeight() -
      Progress.getDistanceFromBottom();

    return Math.max(120, maxBottom);
  }

  public readonly state = {
    isBottom: false,
    isOpen: false,
    isStuck: false,
    maxBottom: 0,
    tooltipOpen: undefined,
  };

  private checkStuckDebounced: debounce<() => void>;

  constructor(props: IProps) {
    super(props);

    this.checkStuckDebounced = debounce(50, this.checkStuck.bind(this));
    this.chooseCap = this.chooseCap.bind(this);
    this.denHandleClick = this.denHandleClick.bind(this);
    this.iddHandleClick = this.iddHandleClick.bind(this);
    this.overdraftHandleClick = this.overdraftHandleClick.bind(this);
    this.requestValidation = this.requestValidation.bind(this);
    this.toggleOpen = this.toggleOpen.bind(this);
    this.requestValidationAndSign = this.requestValidationAndSign.bind(this);
  }

  public componentDidMount() {
    document.addEventListener("scroll", this.checkStuckDebounced);
  }

  public componentWillUnmount() {
    this.checkStuckDebounced.cancel();
    document.removeEventListener("scroll", this.checkStuckDebounced);
  }

  public render() {
    const {
      denDisabled,
      denState,
      hasSubscription,
      hasSuitableOption,
      iddDisabled,
      iddState,
      isCloseDenPending,
      isContractor,
      isDelivered,
      isIddSendPending,
      isLast,
      isOverdraftPending,
      isRejected,
      isRequestValidationPending,
      isSigned,
      isValidated,
      isValidationRequested,
      overdraftDisabled,
      overdraftOneComplete,
      overdraftState,
      privacySubscriptionNotificationStates,
      scrollToIdd,
      scrollToOverdraft,
      scrollToSendCredentials,
      sendPrivacySubscriptionNotification,
    } = this.props;
    const {isBottom} = this.state;
    return (
      <Spring to={this.getSpringProps()} immediate={isBottom}>
        {(styles) => (
          <div id="cap-progress" className="cap-progress" style={styles}>
            <Container fluid>
              {isValidated ? (
                <>
                  <h4 className="d-flex align-items-center">
                    Consulenza approvata dal cliente
                    <span className="ml-auto">
                      {isSigned && (
                        <Button
                          color="primary"
                          outline
                          download
                          href={`${process.env.REACT_APP_API_BASE_URL}caps/${this.props.capId}/get-pdf`}
                          className="mr-2"
                        >
                          <IconDownload /> Scarica il pdf dell'analisi
                        </Button>
                      )}
                      <Button
                        color="primary"
                        outline
                        className="toggle-button"
                        onClick={this.toggleOpen}
                      >
                        {this.state.isOpen ? (
                          <span>Chiudi dettagli</span>
                        ) : (
                          <span>Maggiori dettagli</span>
                        )}{" "}
                        <IconChevronUp
                          style={{
                            transform: `rotateX(${
                              this.state.isOpen ? 180 : 0
                            }deg)`,
                          }}
                        />
                      </Button>
                    </span>
                  </h4>
                  <p>
                    Il cliente ha confermato la correttezza delle informazioni
                    fornite e la coerenza con le informazioni ricevute dal
                    consulente.
                  </p>
                  <br />
                </>
              ) : isRejected ? (
                <>
                  <h4 className="d-flex align-items-center">
                    Consulenza contestata dal cliente
                    <Button
                      color="primary"
                      outline
                      className="toggle-button ml-auto"
                      onClick={this.toggleOpen}
                    >
                      {this.state.isOpen ? (
                        <span>Chiudi dettagli</span>
                      ) : (
                        <span>Maggiori dettagli</span>
                      )}{" "}
                      <IconChevronUp
                        style={{
                          transform: `rotateX(${
                            this.state.isOpen ? 180 : 0
                          }deg)`,
                        }}
                      />
                    </Button>
                  </h4>
                  <p>
                    Il cliente ha contestato la correttezza delle informazioni
                    fornite e la coerenza con le informazioni ricevute dal
                    consulente.
                  </p>
                  <br />
                </>
              ) : (
                <>
                  <h4 className="d-flex align-items-center">
                    Richiedi al cliente la validazione della consulenza
                    <Button
                      color="primary"
                      outline
                      className="toggle-button ml-auto"
                      onClick={this.toggleOpen}
                    >
                      {this.state.isOpen ? (
                        <span>Chiudi dettagli</span>
                      ) : (
                        <span>Maggiori dettagli</span>
                      )}{" "}
                      <IconChevronUp
                        style={{
                          transform: `rotateX(${
                            this.state.isOpen ? 180 : 0
                          }deg)`,
                        }}
                      />
                    </Button>
                  </h4>
                  <p>
                    Senza l’approvazione esplicita del cliente non sarà
                    possibile proporgli soluzioni assicurative e finanziarie.
                    <br />
                    Per ottenere l’approvazione{" "}
                    <strong>invia le credenziali</strong> di accesso e{" "}
                    <strong>completa almeno uno dei due moduli</strong>:
                    Questionario IBIPs o Analisi delle Scoperture.
                  </p>
                </>
              )}
              {/* TODO: Da dividere in componenti, uno per ogni sezione in modo da rendere più ordinati */}
              {/*  testi, bottoni e tooltip */}
              <Row>
                <Col>
                  <Card
                    id="den-tooltip"
                    body
                    className="d-flex align-items-center justify-content-center auto-margin-2"
                  >
                    {denState ? (
                      <>
                        <IconCheckmark
                          size={3}
                          color="success"
                          title="Demand and Needs test: completato"
                        />
                        <CardTitle>
                          Demand and Needs test
                          <br />
                          <strong>completato</strong>
                        </CardTitle>
                        <Button
                          id="den-progress-button"
                          color="primary"
                          outline
                          onClick={this.denHandleClick}
                          hidden={denDisabled}
                        >
                          {isCloseDenPending && (
                            <IconSpinner className="icon-spin mr-2" />
                          )}
                          Invia una notifica al tuo cliente
                        </Button>
                      </>
                    ) : (
                      <>
                        <IconBan
                          size={3}
                          color="danger"
                          title="Demand and Needs test: non completato"
                        />
                        <CardTitle>
                          Demand and Needs test
                          <br />
                          <strong>non completato</strong>
                        </CardTitle>
                      </>
                    )}
                  </Card>
                  <UncontrolledTooltip
                    autohide={false}
                    className="tooltip-md text-left"
                    target="den-tooltip"
                  >
                    <div className="text-left">
                      {denDisabled ? (
                        <>
                          <h5>Non hai completato il Demand and Needs test</h5>
                          Per poter concludere questa sezione, è necessario
                          completare le sezioni:
                          <ul className="mb-0">
                            <li>Anagrafica cliente</li>
                            <li>Residenza</li>
                            <li>Situazione professionale</li>
                            <li>Altri redditi</li>
                            <li>Obiettivi cliente</li>
                            <li>Capacità di risparmio</li>
                          </ul>
                        </>
                      ) : (
                        <>
                          <h5>Sezione completata correttamente</h5>
                          Hai compilato correttamente la sezione del Demand and
                          Needs test
                        </>
                      )}
                    </div>
                  </UncontrolledTooltip>
                </Col>
                <Col>
                  <Card
                    id="overdraft-tooltip"
                    body
                    className="d-flex align-items-center justify-content-center auto-margin-2"
                  >
                    {overdraftState ? (
                      <>
                        <IconCheckmark
                          size={3}
                          color="success"
                          title="Scoperture concluse"
                        />
                        <CardTitle>
                          Analisi delle scoperture:
                          <br />
                          <strong>concluse</strong>
                        </CardTitle>
                      </>
                    ) : (
                      <>
                        <IconBan
                          size={3}
                          color="danger"
                          title="Scoperture non completate"
                        />
                        <CardTitle>
                          Analisi delle scoperture:
                          <br />
                          <strong>non completata</strong>
                        </CardTitle>
                      </>
                    )}
                    {denState ? (
                      overdraftOneComplete ? (
                        overdraftState ? (
                          <Button
                            id="overdraft-progress-button"
                            color="primary"
                            outline
                            onClick={this.overdraftHandleClick}
                            hidden={overdraftDisabled}
                            disabled={isOverdraftPending}
                          >
                            {isOverdraftPending && (
                              <IconSpinner className="icon-spin" />
                            )}{" "}
                            Invia nuovamente una notifica al cliente
                          </Button>
                        ) : (
                          <Button
                            id="overdraft-progress-button"
                            color="primary"
                            outline
                            onClick={scrollToOverdraft}
                            hidden={
                              overdraftDisabled || isValidated || isRejected
                            }
                          >
                            {isOverdraftPending && (
                              <IconSpinner className="icon-spin" />
                            )}{" "}
                            Procedi
                          </Button>
                        )
                      ) : (
                        <Button
                          id="overdraft-progress-button"
                          color="primary"
                          outline
                          onClick={scrollToOverdraft}
                          hidden={
                            overdraftDisabled || isValidated || isRejected
                          }
                        >
                          {isOverdraftPending && (
                            <IconSpinner className="icon-spin" />
                          )}{" "}
                          Procedi
                        </Button>
                      )
                    ) : (
                      <Button
                        id="overdraft-progress-button"
                        color="primary"
                        outline
                        onClick={scrollToSendCredentials}
                        hidden={overdraftDisabled || isValidated || isRejected}
                      >
                        Procedi
                      </Button>
                    )}
                  </Card>
                  <UncontrolledTooltip
                    autohide={false}
                    className="tooltip-md"
                    target="overdraft-tooltip"
                  >
                    <div className="text-left">
                      {denState ? (
                        overdraftOneComplete ? (
                          overdraftState ? (
                            <>
                              <h5>Sezione completata correttamente</h5>
                              Hai compilato correttamente la sezione
                              dell'Analisi delle scoperture
                            </>
                          ) : (
                            <>
                              <h5>
                                Non hai completato l'Analisi delle scoperture
                              </h5>
                              Per poter concludere questa sezione, è necessario
                              salvare almeno uno dei tre moduli dell'analisi
                              delle scoperture
                              <ul className="mb-0">
                                <li>Tutelare beni, casa, animali</li>
                                <li>
                                  Tutelare il tenore di vita personale e
                                  familiare
                                </li>
                                <li>
                                  Tutelarsi da infortuni, malattia & prevenzione
                                </li>
                              </ul>
                            </>
                          )
                        ) : (
                          <>
                            <h5>
                              Non hai completato l'Analisi delle scoperture
                            </h5>
                            Per poter concludere questa sezione, è necessario
                            salvare almeno uno dei tre moduli dell'analisi delle
                            scoperture
                            <ul className="mb-0">
                              <li>Tutelare beni, casa, animali</li>
                              <li>
                                Tutelare il tenore di vita personale e familiare
                              </li>
                              <li>
                                Tutelarsi da infortuni, malattia & prevenzione
                              </li>
                            </ul>
                          </>
                        )
                      ) : (
                        <>
                          <h5>
                            Non hai completato la sezione Demands and Needs
                          </h5>
                          Attenzione, prima devi completare la sezione Demands
                          and Needs e fornire le credenziali di accesso al
                          cliente
                        </>
                      )}
                    </div>
                  </UncontrolledTooltip>
                </Col>
                <Col>
                  <Card
                    id="idd-tooltip"
                    body
                    className="d-flex align-items-center justify-content-center auto-margin-2"
                  >
                    {iddState ? (
                      <>
                        <IconCheckmark
                          size={3}
                          color="success"
                          title="IBIPs concluso"
                        />
                        <CardTitle>
                          Questionario IBIPs:
                          <br />
                          <strong>completato</strong>
                        </CardTitle>
                        <Button
                          id="idd-progress-button"
                          color="primary"
                          outline
                          onClick={this.iddHandleClick}
                        >
                          {isIddSendPending && (
                            <IconSpinner className="icon-spin mr-2" />
                          )}
                          Invia nuovamente una notifica al cliente
                        </Button>
                      </>
                    ) : (
                      <>
                        <IconBan
                          size={3}
                          color="danger"
                          title="IBIPs non concluso"
                        />
                        <CardTitle>
                          Questionario IBIPs:
                          <br />
                          <strong>non completato</strong>
                        </CardTitle>
                        <Button
                          id="idd-progress-button"
                          color="primary"
                          outline
                          onClick={scrollToIdd}
                          hidden={
                            iddDisabled ||
                            denDisabled ||
                            isValidated ||
                            isRejected
                          }
                        >
                          Procedi
                        </Button>
                      </>
                    )}
                  </Card>
                  <UncontrolledTooltip
                    autohide={false}
                    className="tooltip-md"
                    target="idd-tooltip"
                  >
                    <div className="text-left">
                      {denDisabled ? (
                        <>
                          <h5>Non hai completato il Questionario IBIPs</h5>
                          Attenzione, prima devi completare la sezione Demands
                          and Needs e fornire le credenziali di accesso al
                          cliente
                        </>
                      ) : iddState ? (
                        <>
                          <h5>Sezione completata correttamente</h5>
                          Hai compilato correttamente il questionario di
                          adeguatezza IBIPs
                        </>
                      ) : (
                        <>
                          <h5>Valutazione di adeguatezza IBIPs</h5>
                          La compilazione del questionario di valutazione di
                          adeguatezza IBIPs permette di verificare quali fondi
                          del vostro paniere sono appropriati ed adeguati
                          all'esperienza, alla situazione finanziaria, agli
                          obiettivi di investimento, alla propensione al rischio
                          e all'orizzonte temporale del vostro Cliente.
                        </>
                      )}
                    </div>
                  </UncontrolledTooltip>
                </Col>
                <Col>
                  <Card
                    id="validation-tooltip"
                    body
                    className="d-flex align-items-center justify-content-center auto-margin-2"
                  >
                    {isValidated ? (
                      isSigned ? (
                        hasSubscription ? (
                          // Firmata, validata e privacy
                          <>
                            <IconCheckmark
                              size={3}
                              color="success"
                              title="Analisi completata"
                            />
                            <CardTitle>
                              Chiusura analisi:
                              <br />
                              <strong>Analisi completata</strong>
                            </CardTitle>
                            {iddState && hasSuitableOption && isLast === true && (
                              <Button
                                color="primary"
                                outline
                                onClick={this.chooseCap}
                              >
                                Crea raccomandazione
                              </Button>
                            )}
                          </>
                        ) : (
                          // Manca privacy
                          <>
                            <IconClock
                              size={3}
                              color="primary"
                              title="In attesa che il cliente firmi la privacy sottoscrizione"
                            />
                            <CardTitle>
                              Chiusura analisi:
                              <br />
                              <strong>
                                In attesa che il cliente firmi la privacy
                                sottoscrizione
                              </strong>
                            </CardTitle>
                            <Button
                              color="primary"
                              outline
                              onClick={sendPrivacySubscriptionNotification}
                            >
                              {privacySubscriptionNotificationStates.isPending ? (
                                <IconSpinner className="icon-spin" />
                              ) : privacySubscriptionNotificationStates.isSuccess ? (
                                <IconCheckmark color="success" />
                              ) : (
                                <IconSendNotification />
                              )}{" "}
                              Invia promemoria
                            </Button>
                          </>
                        )
                      ) : (
                        // Manca firma (non dovrebbe mai succedere ora
                        <>
                          <IconClock
                            size={3}
                            color="primary"
                            title="In attesa della firma del cliente"
                          />
                          <CardTitle>
                            Chiusura analisi:
                            <br />
                            <strong>In attesa della firma del cliente</strong>
                          </CardTitle>
                          {isContractor && (
                            <Button
                              id="request-activation-sign-progress-button"
                              color="primary"
                              outline
                              disabled={isRequestValidationPending}
                              onClick={this.requestValidationAndSign}
                            >
                              {isRequestValidationPending && (
                                <IconSpinner className="icon-spin" />
                              )}{" "}
                              Valida e firma l'analisi
                            </Button>
                          )}
                        </>
                      )
                    ) : isRejected ? (
                      // Rifiutata (non dovremmo mai arrivarci ora)
                      <>
                        <IconTimes
                          size={3}
                          color="danger"
                          title="Analisi rifiutata dal cliente"
                        />
                        <CardTitle>
                          Validazione:
                          <br />
                          <strong>Rifiutata</strong>
                        </CardTitle>
                      </>
                    ) : isValidationRequested ? (
                      isContractor ? (
                        // Autoanalisi
                        <>
                          <IconClock
                            size={3}
                            color="primary"
                            title="In attesa della firma del cliente"
                          />
                          <CardTitle>
                            Firma auto-raccomandazione:
                            <br />
                            <strong>In attesa di conferma</strong>
                          </CardTitle>
                          <Button
                            id="request-activation-sign-progress-button"
                            color="primary"
                            outline
                            disabled={isRequestValidationPending}
                            onClick={this.requestValidationAndSign}
                          >
                            {isRequestValidationPending && (
                              <IconSpinner className="icon-spin" />
                            )}{" "}
                            Firma l'analisi
                          </Button>
                        </>
                      ) : (
                        // La richiesta di validazione è stata inviata
                        <>
                          <IconClock
                            size={3}
                            color="primary"
                            title="In attesa della firma del cliente"
                          />
                          <CardTitle>
                            Chiusura analisi:
                            <br />
                            <strong>In attesa della firma del cliente</strong>
                          </CardTitle>
                          <Button
                            id="reminder-progress-button"
                            color="primary"
                            outline
                            onClick={this.requestValidation}
                            // hidden={iddDisabled || denDisabled}
                          >
                            {isRequestValidationPending && (
                              <IconSpinner className="icon-spin" />
                            )}{" "}
                            Invia promemoria
                          </Button>
                        </>
                      )
                    ) : isContractor ? (
                      // Auto analisi
                      <>
                        <IconBan
                          size={3}
                          color="danger"
                          title="Non completata"
                        />
                        <CardTitle>
                          Chiusura auto-analisi:
                          <br />
                          <strong>Non completata</strong>
                        </CardTitle>
                        <Button
                          id="request-activation-sign-progress-button"
                          color="primary"
                          outline
                          hidden={
                            isValidationRequested ||
                            !denState ||
                            (!iddState && !overdraftState)
                          }
                          disabled={isRequestValidationPending}
                          onClick={this.requestValidationAndSign}
                        >
                          {isRequestValidationPending && (
                            <IconSpinner className="icon-spin" />
                          )}{" "}
                          Firma l'analisi
                        </Button>
                      </>
                    ) : (
                      // Non è possibile richiedere la validazione
                      <>
                        <IconBan
                          size={3}
                          color="danger"
                          title="Non completata"
                        />
                        <CardTitle>
                          Chiusura analisi:
                          <br />
                          <strong>Non completata</strong>
                        </CardTitle>
                        <Button
                          id="request-activation-progress-button"
                          color="primary"
                          outline
                          hidden={
                            isValidationRequested ||
                            !denState ||
                            !isDelivered ||
                            (!iddState && !overdraftState)
                          }
                          disabled={isRequestValidationPending}
                          onClick={this.requestValidation}
                        >
                          {isRequestValidationPending && (
                            <IconSpinner className="icon-spin" />
                          )}{" "}
                          Richiedi firma
                        </Button>
                      </>
                    )}
                  </Card>
                  <UncontrolledTooltip
                    autohide={false}
                    className="tooltip-md"
                    target="validation-tooltip"
                  >
                    <div className="text-left">
                      {denState &&
                      isDelivered &&
                      (iddState || overdraftState) ? (
                        isValidated ? (
                          hasSuitableOption ? (
                            hasSubscription ? (
                              // Firmata, validata e privacy
                              <>
                                <h5>Chiusura analisi</h5>
                                L’analisi è completata e firmata dal cliente.
                                <br />
                                Il cliente ha firmato la privacy sottoscrizione
                              </>
                            ) : (
                              // Manca la privacy sottoscrizione
                              <>
                                <h5>Chiusura analisi</h5>
                                In attesa che il cliente firmi la privacy
                                sottoscrizione
                              </>
                            )
                          ) : (
                            // Firmata e validata ma non abbiamo prodotti validi
                            <>
                              <h5>Chiusura analisi</h5>
                              L’analisi è completata e firmata dal cliente, ma
                              non ci sono opzioni d'investimento adeguate
                            </>
                          )
                        ) : isRejected ? (
                          // Rifiutata (non dovremmo mai arrivarci ora)
                          <>
                            <h5>Richiedi validazione utente</h5>
                            L'analisi è stata rifiutata dal cliente
                          </>
                        ) : isValidationRequested ? (
                          // La richiesta di validazione è stata inviata
                          <>
                            <h5>Chiusura analisi</h5>
                            La richiesta di firma è stata inviata al cliente
                          </>
                        ) : isContractor ? (
                          // Auto analisi
                          <>
                            <h5>Chiusura analisi</h5>
                            Ti viene comunque invita la richiesta di firma per
                            la conferma dei dati inseriti nell'analisi. Firmala
                            direttamente con il pulsante sottostante
                          </>
                        ) : (
                          // È possibile richiedere la validazione
                          <>
                            <h5>Chiusura analisi</h5>
                            Invia la richiesta di firma a cliente per
                            permettergli di visualizzare e firmare la
                            documentazione prodotta
                          </>
                        )
                      ) : (
                        // Non è possibile richiedere la validazione
                        <>
                          <h5>Chiusura analisi</h5>
                          Per poter chiudere l’analisi devi completare la
                          sezione Demands and Needs Test, una tra le sezioni
                          Analisi delle scoperture e Questionario IBIPs,
                          l’identificazione e la sezione “Dichiarazione di
                          avvenuta consegna della documentazione contrattuale e
                          precontrattuale ex art. 56 del regolamento IVASS
                          n.40/2018”
                        </>
                      )}
                    </div>
                  </UncontrolledTooltip>
                </Col>
              </Row>
            </Container>
          </div>
        )}
      </Spring>
    );
  }

  private denHandleClick() {
    const button = document.getElementById("den-progress-button");
    if (button) {
      button.blur();
    }
    this.props.denHandleClick();
  }

  private iddHandleClick() {
    const button = document.getElementById("idd-progress-button");
    if (button) {
      button.blur();
    }

    this.props.iddHandleClick();
  }

  private overdraftHandleClick() {
    const button = document.getElementById("overdraft-progress-button");
    if (button) {
      button.blur();
    }

    this.props.overdraftHandleClick();
  }

  private requestValidation() {
    const button = document.getElementById(
      "request-activation-progress-button"
    );
    if (button) {
      button.blur();
    }

    this.props.requestValidation();
  }

  private async requestValidationAndSign() {
    const button = document.getElementById(
      "request-activation-sign-progress-button"
    );
    if (button) {
      button.blur();
    }

    if (!this.props.isValidationRequested) {
      await this.props.requestValidation();
    }
    if (!this.props.isValidated && !this.props.isRejected) {
      await this.props.closeValidation(this.props.capId, 1);
    }
    this.props.openRequestOTPCapModal(this.props.capId);
  }

  private chooseCap() {
    window.scrollTo(0, 0);
    this.props.chooseCap();
  }

  private getSpringProps() {
    const {capReady} = this.props;
    const {isOpen, isBottom, isStuck, maxBottom} = this.state;

    if (!capReady) {
      if (!isStuck) {
        return {
          bottom: "0px",
          transform: "translateY(100%)",
        };
      } else {
        return {
          bottom: "0px",
          transform: "translateY(0%)",
        };
      }
    } else if (isOpen || isBottom) {
      return {
        bottom: "0px",
        transform: "translateY(0%)",
      };
    } else {
      return {
        bottom: maxBottom + "px",
        transform: "translateY(100%)",
      };
    }
  }

  private checkStuck() {
    // console.log("checkStuck"); // tslint:disable-line
    this.setState({
      isBottom: Progress.getDistanceFromBottom() < Progress.getFooterHeight(),
      isStuck:
        Progress.getDistanceFromBottom() + 120 <
        Progress.getFooterHeight() + Progress.outerHeight(),
      maxBottom: Progress.getElementMaxBottom(),
    });
  }

  private toggleOpen() {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  }
}

export default Progress;
