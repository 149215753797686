import * as React from "react";
import {Alert, Button, Form, FormGroup, FormText, Label} from "reactstrap";
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import {password} from "../helpers/validators";
import {integerNormalizer} from "../helpers/normalizers";
import RenderField from "../helpers/RenderField";
import {IconCheckmark, IconInfoCircleSolid, IconWarning} from "../Icons";
import IconSpinner from "../Icons/IconSpinner";

export interface IProfileFormData {
  email: string;
  newpassword2: string;
  newpassword: string;
  password: string;
  personalPhone: string;
  usid: string;
}

interface IOwnProps {
  onSubmit: any;
  isProfileFail?: boolean;
  isProfilePending?: boolean;
  isProfileSuccess?: boolean;
  profileError?: string;
}

type IProps = InjectedFormProps<IProfileFormData, IOwnProps> & IOwnProps;

const validate = (values: IProfileFormData) => {
  const errors: Partial<IProfileFormData> = {};
  if (!values.usid) {
    errors.usid = "Questo campo è obbligatorio";
  }
  if (!values.personalPhone) {
    errors.personalPhone = "Questo campo è obbligatorio";
  }
  if (values.newpassword && !password(values.newpassword)) {
    errors.newpassword =
      "La password non è nel formato corretto. Servono almeno 8 caratteri tra i quali: almeno un numero, almeno una lettera minuscola ed una maiuscola";
  }
  if (values.password && !values.newpassword) {
    errors.newpassword = "Questo campo è obbligatorio";
  }
  if (values.password && !values.newpassword2) {
    errors.newpassword2 = "Questo campo è obbligatorio";
  }
  if (values.newpassword && !values.password) {
    errors.password = "Questo campo è obbligatorio";
  }
  if (
    (values.newpassword || values.newpassword2) &&
    values.newpassword !== values.newpassword2
  ) {
    errors.newpassword2 = "Le password inserite non coincidono";
  }
  return errors;
};

const ProfileForm: React.FC<IProps> = (props) => (
  <Form onSubmit={props.handleSubmit}>
    <FormGroup>
      <Label for="email">Email</Label>
      <Field
        name="email"
        readOnly
        type="email"
        id="email"
        component={RenderField}
        placeholder="Email"
        autoComplete="email"
      />
    </FormGroup>
    <FormGroup>
      <Label for="usid">Nome utente</Label>
      <Field
        id="usid"
        name="usid"
        component={RenderField}
        type="text"
        placeholder="Nome utente"
        autoComplete="username"
      />
      <FormText color="info">
        <IconWarning /> Modificando il Nome utente e salvando il Profilo
        personale, verrà effettuato il logout dalla piattaforma. Potrai
        riaccedere con le nuove credenziali.
      </FormText>
    </FormGroup>
    <FormGroup>
      <Label for="personalPhone">Numero di cellulare</Label>
      <Field
        id="personalPhone"
        name="personalPhone"
        component={RenderField}
        type="text"
        placeholder="Numero di cellulare"
        normalize={integerNormalizer}
      />
      <FormText color="info">
        <IconInfoCircleSolid /> Questo è il numero viene usato per la firma
        digitale
      </FormText>
    </FormGroup>
    <div className="mt-4 mb-4">
      <h5>Modifica password</h5>Compila i campi sottostanti solo se vuoi
      modificare la tua password{" "}
    </div>
    <FormGroup>
      <Label for="password">Vecchia Password</Label>
      <Field
        id="password"
        name="password"
        component={RenderField}
        type="password"
        placeholder="Password"
        autoComplete="current-password"
      />
    </FormGroup>
    <FormGroup>
      <Label for="newpassword">Nuova Password</Label>
      <Field
        id="newpassword"
        name="newpassword"
        component={RenderField}
        type="password"
        placeholder="Nuova Password"
        autoComplete="new-password"
      />
    </FormGroup>
    <FormGroup>
      <Label for="newpassword2">Ripeti la nuova Password</Label>
      <Field
        id="newpassword2"
        name="newpassword2"
        component={RenderField}
        type="password"
        placeholder="Ripetere nuova Password"
        autoComplete="new-password"
      />
    </FormGroup>
    {props.isProfileFail ? (
      <Alert color="danger">{props.profileError}</Alert>
    ) : null}
    <Button type="submit" color="primary" disabled={props.isProfilePending}>
      {props.submitting ? <IconSpinner className="icon-spin" /> : ""} Salva il
      profilo personale
    </Button>
    {props.isProfileSuccess ? (
      <Alert color="success" className="mb-0 mt-3">
        <IconCheckmark /> Profilo aggiornato
      </Alert>
    ) : null}
  </Form>
);

export default reduxForm<IProfileFormData, IOwnProps>({
  form: "profileForm",
  validate,
})(ProfileForm);
