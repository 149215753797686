import React from "react";
import ReactTable, {RowInfo, RowRenderProps} from "react-table";
import {Alert} from "reactstrap";

import {
  IconCheckCircle,
  IconDownload,
  IconInfoCircle,
  IconInfoCircleSolid,
  IconTimesCircle,
} from "../../Icons";
import {IIddSnapshot, IInvestmentOption} from "../types";

import OptionCriteriaTable from "./OptionsCriteriaTable";

import "react-table/react-table.css";

interface IProps {
  idd: IIddSnapshot;
}

const columns = [
  {
    Header: "",
    accessor: (row: any) => row.icon,
    id: "status",
    resizable: false,
    sortable: false,
    width: 28,
  },
  {
    Header: "Compagnia",
    accessor: (row: IInvestmentOption) => row.product.company.name,
    id: "company",
  },
  {
    Header: "Prodotto",
    accessor: (row: any) => (
      <>
        <span>{row.product.name}</span>
        <a className="float-right" href={row.product.url} download>
          <IconDownload title="Download documentazione prodotto" />
        </a>
      </>
    ),
    id: "product",
  },
  {Header: "Opzione di investimento", accessor: "name"},
  {Header: "Descrizione livello di rischio", accessor: "risk"},
  {
    Expander: ({isExpanded}: RowRenderProps) =>
      isExpanded ? (
        <IconInfoCircleSolid title="Chiudi dettagli" />
      ) : (
        <IconInfoCircle title="Dettagli" />
      ),
    Header: "",
    expander: true,
    width: 26,
  },
];

const ContractorProductTable: React.FC<IProps> = (props) => {
  // console.log({props}); // tslint:disable-line

  const subComponent = (row: RowInfo) => {
    // console.log("stampo ROW"); // tslint:disable-line
    // console.log({row}); // tslint:disable-line
    return <OptionCriteriaTable idd={props.idd} option={row.original} />;
  };

  const filteredOptions = props.idd.suitableOptions
    ? props.idd.suitableOptions.map((option) => ({
        ...option,
        icon: <IconCheckCircle color="success" />,
      }))
    : [];

  const badOptions = props.idd.unsuitableOptions
    ? props.idd.unsuitableOptions.map((option) => ({
        ...option,
        icon: <IconTimesCircle color="danger" />,
      }))
    : [];

  return (
    <>
      <div>
        <h6>Prodotti adeguati e appropriati al cliente</h6>
        {filteredOptions.length > 0 ? (
          <ReactTable
            data={filteredOptions}
            columns={columns}
            className="-striped -highlight"
            SubComponent={subComponent}
            minRows={1}
            showPagination={false}
          />
        ) : (
          <Alert color="warning">
            Nessun prodotto adeguato e appropriato disponibile
          </Alert>
        )}
      </div>
      <div>
        <h6>Prodotti non adeguati e non appropriati al cliente</h6>
        {badOptions.length > 0 ? (
          <ReactTable
            data={badOptions}
            columns={columns}
            className="-striped -highlight"
            SubComponent={subComponent}
            minRows={1}
            showPagination={false}
          />
        ) : (
          <Alert color="info">
            Nessun prodotto non adeguato e non appropriato disponibile
          </Alert>
        )}
      </div>
    </>
  );
};

export default ContractorProductTable;
