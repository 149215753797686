import React from "react";
import FormatNumber from "../helpers/FormatNumber";
import checkHasAllContractorDetails from "../helpers/hasAllContractorDetails";
import SectionCounter from "./SectionCounter";
import {ICap} from "./types";

interface IProps {
  cap?: ICap;
}

const DenCounter: React.FC<IProps> = ({cap}) => {
  const drawers = {
    contractor: () => (checkHasAllContractorDetails(cap) ? 1 : 0),
    employment: () => (cap && cap.data.employments ? 1 : 0),
    home: () => (cap && cap.data.home ? 1 : 0),
    incomes: () => (cap && cap.data.incomes ? 1 : 0),
    saving: () => (cap && cap.data.saving ? 1 : 0),
    targets: () => (cap && cap.data.targets ? 1 : 0),
  };

  return (
    <SectionCounter drawers={drawers}>
      {({complete, total}) => (
        <span>
          {complete === total ? "Hai" : "Non hai"} completato la sezione Demands
          and Needs
          <br />
          Avanzamento: <FormatNumber>{(complete / total) * 100}</FormatNumber>%
          ({complete} moduli su {total})
        </span>
      )}
    </SectionCounter>
  );
};

export default DenCounter;
