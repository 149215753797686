export const floatFormatter = (value: string) => {
  return value ? value.replace(".", ",") : "";
};

export const birthdayFormatter = (value: string) => {
  if (!value) {
    return "";
  }

  const onlyDigits = value.replace(/[^\d]/g, "");
  if (value.length <= 2) {
    return onlyDigits;
  } else if (value.length <= 5) {
    return `${onlyDigits.slice(0, 2)}/${onlyDigits.slice(2)}`;
  }
  return `${onlyDigits.slice(0, 2)}/${onlyDigits.slice(
    2,
    4
  )}/${onlyDigits.slice(4, 8)}`;
};

export const cityFormatter = (name: string) => {
  if (!name) {
    return "";
  }
  return name.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
