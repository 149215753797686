import React from "react";
import {Card, CardBody, CardHeader, Col, Label, Row} from "reactstrap";

import {IconCheckmark} from "../../Icons";
import {IIddSnapshot} from "../types";
import {domandeIdd} from "./";

interface IOwnProps {
  idd: IIddSnapshot;
}

const IddView: React.FC<IOwnProps> = (props) => {
  return (
    <div className="auto-margin-3">
      <Card>
        <CardHeader className="bg-primary text-white">
          Dati anagrafici
        </CardHeader>
        <CardBody>
          <Row>
            <Col tag="dt" sm={3}>
              Nome
            </Col>
            <Col tag="dd" sm={9}>
              {props.idd.values.name}
            </Col>
            <Col tag="dt" sm={3}>
              Cognome
            </Col>
            <Col tag="dd" sm={9}>
              {props.idd.values.surname}
            </Col>
            <Col tag="dt" sm={3}>
              Luogo di nascita
            </Col>
            <Col tag="dd" sm={9}>
              {props.idd.values.birthPlace}
            </Col>
            <Col tag="dt" sm={3}>
              Data di nascita
            </Col>
            <Col tag="dd" sm={9}>
              {props.idd.values.birthday}
            </Col>
            <Col tag="dt" sm={3}>
              Codice fiscale
            </Col>
            <Col tag="dd" sm={9}>
              {props.idd.values.fiscalCode}
            </Col>
            <Col tag="dt" sm={3}>
              Indirizzo
            </Col>
            <Col tag="dd" sm={9}>
              {props.idd.values.address}
            </Col>
          </Row>
        </CardBody>
      </Card>
      {domandeIdd.sections.map((section) => (
        <Card key={section.title}>
          <CardHeader className="bg-primary text-white">
            {section.title}
          </CardHeader>
          <CardBody className="auto-margin-3">
            {section.questions.map((question) => (
              <div key={question.name}>
                <Label>
                  <strong>{question.label}</strong>
                </Label>
                <ul className="list-unstyled">
                  {question.answers.map((answer) => {
                    const active = question.multiple
                      ? props.idd.values[question.name] &&
                        props.idd.values[question.name]?.[
                          parseInt(answer.value, 10)
                        ] === true
                      : answer.value === props.idd.values[question.name];
                    return (
                      <li
                        className={active ? "text-primary" : ""}
                        key={answer.value}
                      >
                        {active ? (
                          <strong>
                            <IconCheckmark /> {answer.text}
                          </strong>
                        ) : (
                          <span style={{paddingLeft: "1.25em"}}>
                            {answer.text}
                          </span>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </div>
            ))}
          </CardBody>
        </Card>
      ))}
    </div>
  );
};

export default IddView;
