import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Button} from "reactstrap";
import ContainerMax from "../App/ContainerMax";
import isIdValid from "../helpers/isIdValid";
import {IconFillIn} from "../Icons";
import {IRootState} from "../redux/reducers";
import {actions} from "./actions";
import DeliveryDetails from "./Delivery/DeliveryDetails";
import DeliveryModal from "./Delivery/DeliveryModal";
import {IOverdraftsSectionFormData} from "./OverdraftSectionForm";
import {getCapsUi, getIsAdvisor} from "./selectors";
import {ICap, NewCapModalTypes} from "./types";

interface IProps {
  cap?: ICap;
}

const Delivery = ({cap}: IProps) => {
  const dispatch = useDispatch();
  const closeModal = () => {
    dispatch(actions.closeNewCapModal(NewCapModalTypes.Delivery));
  };
  const openModal = () => {
    dispatch(actions.openNewCapModal(NewCapModalTypes.Delivery));
  };
  const isAdvisor = useSelector((state: IRootState) =>
    getIsAdvisor(state, cap?.id)
  );
  const isReadPending = useSelector(getCapsUi).isReadPending;

  const hasLifeNotIbips =
    (cap?.data.overdrafts?.tenoreVita as IOverdraftsSectionFormData)
      ?.consultantRating !== undefined;
  const hasIbips = !!cap?.dateIdd;
  const hasLoss =
    (cap?.data.overdrafts?.beniCasaAnimali as IOverdraftsSectionFormData)
      ?.consultantRating !== undefined ||
    (cap?.data.overdrafts
      ?.infortuniMalattiaPrevenzione as IOverdraftsSectionFormData)
      ?.consultantRating !== undefined;
  const hasOverdrafts = !!cap?.dateScoperture;

  return (
    <ContainerMax
      isLocked={!isIdValid(cap?.onboarding?.identification)}
      isLoading={isReadPending}
    >
      <h3 className="d-flex justify-content-between align-items-center">
        Dichiarazione di avvenuta consegna della documentazione contrattuale e
        precontrattuale ex art. 56 del regolamento IVASS n.40/2018
        {isAdvisor && !cap?.dateSendValidation && (
          <Button
            color="primary"
            outline
            onClick={openModal}
            className="text-nowrap"
          >
            <IconFillIn /> {cap?.delivery ? "Modifica" : "Compila"}
          </Button>
        )}
      </h3>
      {cap?.delivery ? (
        <DeliveryDetails
          delivery={cap.delivery}
          hasLifeNotIbips={hasLifeNotIbips}
          hasIbips={hasIbips}
          hasLoss={hasLoss}
        />
      ) : (
        !isAdvisor && (
          <Alert color="warning">Da compilare da parte dell'Advisor</Alert>
        )
      )}
      {cap && (
        <DeliveryModal
          capId={cap.id}
          hasLifeNotIbips={hasLifeNotIbips}
          hasIbips={hasIbips}
          hasLoss={hasLoss}
          hasOverdrafts={hasOverdrafts}
          place={cap.data.home?.city}
          toggle={closeModal}
        />
      )}
    </ContainerMax>
  );
};

export default Delivery;
