import last from "lodash/last";
import React from "react";
import {useSelector} from "react-redux";
import {Button, Modal, ModalBody, ModalHeader} from "reactstrap";
import {IconPerson, IconSpinner, IconTimes} from "../../Icons";
import {IRootState} from "../../redux/reducers";
import PrivacySubscriptionForm from "../../User/PrivacySubscriptionForm";
import {getPrivacySubscription, getUser} from "../../User/selectors";
import {IPrivacySubscriptionHistory, IUserDB} from "../../Users";
import {getCapsUi} from "../selectors";
import {NewCapModalTypes} from "../types";

interface IProps {
  contractorId: string;
  toggle: () => void;
}

const PrivacySubscriptionModal = ({contractorId, toggle}: IProps) => {
  const isOpen = useSelector(getCapsUi).isModalOpen[
    NewCapModalTypes.PrivacySubscription
  ];
  const privacySubscription = useSelector((state: IRootState) =>
    getPrivacySubscription(state)
  );

  const user = useSelector((state: IRootState) => getUser(state)!);
  const userPrivacySubscription = ((user as unknown) as IUserDB)
    ?.jsonPrivacySubscription;
  const privacySubscriptionHistory = userPrivacySubscription
    ? (JSON.parse(userPrivacySubscription) as IPrivacySubscriptionHistory)
    : undefined;
  const lastPrivacySubscriptionHistory =
    privacySubscriptionHistory && last(privacySubscriptionHistory);
  const privacySubscriptionInitialValues =
    lastPrivacySubscriptionHistory?.values;

  const isUpdating = !!(
    privacySubscription &&
    lastPrivacySubscriptionHistory &&
    parseInt(privacySubscription.version, 10) >
      parseInt(lastPrivacySubscriptionHistory.version, 10)
  );

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
      keyboard={false}
      className="modal-form"
    >
      <ModalHeader
        toggle={toggle}
        close={
          <Button size="sm" className="close-modal" onClick={toggle}>
            <IconTimes
              size={1.25}
              className="icon-border-2 rounded-circle p-1"
            />
          </Button>
        }
      >
        <IconPerson size={3} className="icon-border-2 rounded-circle p-2" />
        Privacy sottoscrizione
      </ModalHeader>
      <ModalBody>
        {privacySubscription ? (
          <PrivacySubscriptionForm
            form="privacySubscriptionModalForm"
            initialValues={privacySubscriptionInitialValues}
            isUpdating={isUpdating}
            privacy={privacySubscription}
          />
        ) : (
          <div className="text-center">
            <IconSpinner className="icon-spin mr-2 my-4" />
            Caricamento privacy sottoscrizione in corso...
          </div>
        )}
      </ModalBody>
    </Modal>
  );
};

export default PrivacySubscriptionModal;
