import React from "react";
import {Alert} from "reactstrap";
import {
  IconCheckCircle,
  IconDanger,
  IconInfoCircleSolid,
  IconWarning,
} from "../../Icons";
import {ProposalStatus} from "../types";

interface IProps {
  isBackOffice: boolean;
  status: ProposalStatus;
}

const StatusAlert = ({isBackOffice, status}: IProps) => {
  if (status === ProposalStatus.Complete) {
    return (
      <Alert color="success">
        <IconCheckCircle /> La proposta è stata validata e risulta congrua con i
        risultati del questionario IBIPs associato
      </Alert>
    );
  }
  if (status === ProposalStatus.Rejected) {
    return (
      <Alert color="info">
        <IconDanger /> Questa proposta è archiviata, non è più possibile alcuna
        modifica.
      </Alert>
    );
  }
  if (status === ProposalStatus.WaitReview) {
    return (
      <Alert color="warning">
        <IconWarning />{" "}
        {isBackOffice
          ? "In attesa di revisione"
          : "La proposta è stata esaminata e non risulta congrua ai requisiti"}
      </Alert>
    );
  }
  if (status === ProposalStatus.WaitingAdvisorsESigns) {
    return (
      <Alert color="danger">
        <IconDanger /> I documenti non sono ancora stati firmati dall'Advisor
      </Alert>
    );
  }
  if (status === ProposalStatus.SignedByAdvisor) {
    return (
      <Alert color="danger">
        <IconDanger /> Firmato dall'Advisor. Non Inviato al cliente
      </Alert>
    );
  }
  if (status === ProposalStatus.WaitingContractorsESign) {
    return (
      <Alert color="danger">
        <IconDanger /> I documenti non sono ancora stati firmati dal cliente
      </Alert>
    );
  }
  if (status === ProposalStatus.ToBeValidated) {
    return (
      <Alert color="primary">
        <IconInfoCircleSolid />{" "}
        {isBackOffice
          ? "La proposta da validare"
          : "La proposta è stata correttamente inviata al back office per l'approvazione"}
      </Alert>
    );
  }
  if (status === ProposalStatus.WaitingFiles) {
    return (
      <Alert color="danger">
        <IconDanger /> I documenti non sono ancora stati caricati
      </Alert>
    );
  }
  if (status === ProposalStatus.Deleted) {
    return (
      <Alert color="danger">
        <IconDanger /> La proposta è stata cancellata
      </Alert>
    );
  }

  return <Alert color="danger">Stato non valido</Alert>;
};

export default StatusAlert;
