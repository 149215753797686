import animateScrollTo from "animated-scroll-to";
import classNames from "classnames";
import last from "lodash/last";
import moment from "moment";
import React from "react";
import {connect} from "react-redux";
import {Redirect, RouteComponentProps} from "react-router-dom";
import {
  Alert,
  Button,
  CardSubtitle,
  CardText,
  CardTitle,
  Container,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import {Dispatch} from "redux";
import {Layout} from "../App";
import ContainerMax from "../App/ContainerMax";
import {actions as eSignActions} from "../ESign/actions";
import {PDFTypes} from "../ESign/types";
import domandeObiettivi from "../helpers/domandeObiettivi";
import checkHasAllContractorDetails from "../helpers/hasAllContractorDetails";
import {isCapComplianceApr21} from "../helpers/isComplianceApr21";
import overdraftsSections from "../helpers/overdraftsSections";
import {yn} from "../helpers/yn";
import {
  IconCalculate,
  IconCheckmark,
  IconDoctor,
  IconEmployment,
  IconEvaluate,
  IconEye,
  IconFillIn,
  IconHouse,
  IconIncomes,
  IconOverdraft,
  IconPerson,
  IconPlus,
  IconRetirement,
  IconSavings,
  IconSpinner,
  IconTargets,
} from "../Icons";
import {actions as recommendationsActions} from "../Recommendation/";
import {IRootState} from "../redux/reducers";
import IApiError from "../types/IApiError";
import {IAppThunkActionStates} from "../types/thunk";
import {actions as userActions} from "../User";
import {getHasPermission} from "../User/selectors";
import {
  actions as usersActions,
  IStateUi as IUsersStateUi,
  IUser,
  IUserEntity,
} from "../Users/";
import {getThunkActionStates} from "../Users/selectors";
import {
  Actions,
  actions,
  ContractorTable,
  dehydrateCap,
  Drawer,
  EmploymentsTable,
  HomeDetails,
  ICap,
  ICapEntity,
  IDataSnapshot,
  IInvestmentOption,
  InailTable,
  IncomesDetails,
  InpsTable,
  IStateNewCap,
  IStateUi,
  NewCapModalTypes,
  OverdraftSectionForm,
  RetirementDetails,
  SavingTable,
  TargetsDetails,
  targetSerie,
} from "./";
import {actions as optionsActions} from "./actions/options";
import CloseCap from "./CloseCap";
import {ICompleteContractorFormData} from "./CompleteContractorForm";
import CompleteContractorModal from "./CompleteContractorModal";
import CopyData from "./CopyData";
import CreateContractorDetails from "./CreateContractorDetails";
import {ICreateContractorFormData} from "./CreateContractorForm";
import CreateContractorModal from "./CreateContractorModal";
import Delivery from "./Delivery";
import DenCounter from "./DenCounter";
import {IEmploymentFormData} from "./EmploymentForm";
import EmploymentsModal from "./EmploymentsModal";
import HasIBIPsDrawer from "./hasIBIPs/HasIBIPsDrawer";
import {IHasIBIPsFormData} from "./hasIBIPs/HasIBIPsForm";
import {IHomeFormData} from "./HomeForm";
import HomeModal from "./HomeModal";
import {
  ContractorProductTable,
  IddModal,
  IddResultSnippet,
  IddViewModal,
  IIddFormData,
} from "./idd";
import IdentificationDetails from "./IdentificationDetails";
import {IIncomesFormData} from "./IncomesForm";
import IncomesModal from "./IncomesModal";
import "./NewPage.scss";
import OnboardingCounter from "./OnboardingCounter";
import {IOverdraftsSectionFormData} from "./OverdraftSectionForm";
import PrivacyDetails from "./PrivacyDetails";
import PrivacySubscription from "./PrivacySubscription";
import Progress from "./Progress";
import {ISavingFormData} from "./SavingForm";
import SavingModal from "./SavingModal";
import {
  getCap,
  getCapsUi,
  getErrorExistingUserId,
  getIsAdvisor,
  getIsContractor,
  getIsUploadIdFail,
  getOptions,
  getUploadIdError,
} from "./selectors";
import {showAttachments3And4} from "../helpers/showAttachments3And4";
import {ITargetFormData} from "./TargetsForm";
import TargetsModal from "./TargetsModal";
import {IUpdateContractorFormData} from "./UpdateContractorForm";
import UpdateContractorModal from "./UpdateContractorModal";
import {IUploadIdFormData} from "./UploadIdForm";
import UploadIdModal from "./UploadIdModal";

interface IMatchParams {
  capId?: string;
}

interface IState {
  openModal?: NewCapModalTypes;
  activeTab: string;
  check1: boolean;
  check2: boolean;
  check3: boolean;
}

type IOwnProps = RouteComponentProps<IMatchParams>;

interface IStateProps {
  canManageCap: boolean;
  chosenCap?: string;
  dispatch?: Dispatch;
  errorExistingUserId?: string;
  isAdvisor: boolean;
  isContractor: boolean;
  isUploadIdFail?: boolean;
  item?: ICap;
  newCap: IStateNewCap;
  options?: IInvestmentOption[];
  privacySubscriptionNotificationStates: IAppThunkActionStates;
  ui: IStateUi;
  uploadIdError?: string;
  user: IUser;
  usersUi: IUsersStateUi;
}

interface IDispatchProps {
  closeModal: (modalType: NewCapModalTypes) => Actions;
  openModal: (modalType: NewCapModalTypes) => Actions;
  completeContractorSubmit: (
    values: ICompleteContractorFormData,
    user: IUserEntity
  ) => Promise<void>;
  createContractorSubmit: (values: ICreateContractorFormData) => Promise<void>;
  updateContractorSubmit: (
    values: IUpdateContractorFormData,
    user: IUserEntity
  ) => Promise<void>;
  useExistingContractorSubmit: (
    event: React.MouseEvent<HTMLButtonElement>
  ) => Promise<void>;
  resetCreateUseExistingContractor: () => void;
  resetLastCreatedId: () => void;
  resetReadRedirect: () => void;
  resetPasswordContractor: (contractorId: string) => Promise<void>;
  employmentSubmit: (
    values: IEmploymentFormData,
    cap: ICapEntity
  ) => Promise<void>;
  homeSubmit: (values: IHomeFormData, cap: ICapEntity) => Promise<void>;
  iddSubmit: (values: IIddFormData, cap: ICapEntity) => Promise<void>;
  uploadIdFail: (error: IApiError) => void;
  uploadIdSubmit: (
    userId: string,
    cap: ICapEntity,
    values: IUploadIdFormData
  ) => Promise<void>;
  confirmIdentificationSubmit: (
    cap: ICapEntity,
    user: IUserEntity
  ) => Promise<void>;
  incomesSubmit: (values: IIncomesFormData, cap: ICapEntity) => Promise<void>;
  injurySubmit: (cap: ICapEntity) => void;
  read: (id: string) => Promise<void>;
  morteSubmit: (cap: ICapEntity) => void;
  overdraftSubmit: (
    values: any,
    cap: ICapEntity,
    empty?: boolean
  ) => Promise<void>;
  hasIBIPsSubmit: (
    values: IHasIBIPsFormData | undefined,
    cap: ICapEntity
  ) => Promise<void>;
  retirementSubmit: (cap: ICapEntity) => void;
  privacyOpenModal: () => void;
  savingSubmit: (values: ISavingFormData, cap: ICapEntity) => Promise<void>;
  targetSubmit: (values: ITargetFormData, cap: ICapEntity) => Promise<void>;
  sendPrivacySubscriptionNotification: (cap: ICap) => Promise<void>;
  tutelaSubmit: (cap: ICapEntity) => void;
  toggleCloseModal: () => Actions;
  openRequestOTPPrivacyModal: (userId: string) => void;
  openRequestOTPIdentificationModal: (capId: string) => void;
  openRequestOTPCapModal: (capId: string) => void;
  closeValidation: (capId: string, responseValidation: 0 | 1) => Promise<void>;
  closeDen: (cap: ICapEntity, email: string) => Promise<void>;
  closeOverdrafts: (cap: ICapEntity, email: string) => Promise<void>;
  requestValidation: (capId: string, email: string) => Promise<void>;
  chooseCap: (capId: string) => recommendationsActions;
  chooseCapFromCap: () => recommendationsActions;
  iddSend: (cap: ICapEntity) => Promise<void>;
  resetIsIddSendSuccess: () => Actions;
  toggleIddSendModal: (contractorId: string) => Actions;
  optionsList: () => Promise<void>;
  createContractorReset: () => void;
  loadCopy: (id: string) => Promise<void>;
  loadCopyReset: () => Actions;
  copyDataInCap: (
    copyData: Partial<IDataSnapshot>,
    cap: ICapEntity
  ) => Promise<void>;
}

type IProps = IOwnProps & IStateProps & IDispatchProps;

const mapStateToProps = (
  state: IRootState,
  ownProps: IOwnProps
): IStateProps => ({
  canManageCap: getHasPermission(state, "cap.manage"),
  chosenCap: state.recommendations.new.chosenCap,
  errorExistingUserId: getErrorExistingUserId(state),
  isAdvisor: getIsAdvisor(state, ownProps.match.params.capId),
  isContractor: getIsContractor(state, ownProps.match.params.capId),
  uploadIdError: getUploadIdError(state),
  isUploadIdFail: getIsUploadIdFail(state),
  item: getCap(state, ownProps.match.params.capId),
  newCap: state.caps.newCap,
  options: getOptions(state),
  ui: getCapsUi(state),
  privacySubscriptionNotificationStates: getThunkActionStates(
    state,
    "privacySubscriptionNotification"
  ),
  user: state.user.user as IUser,
  usersUi: state.users.ui,
});

const mapDispatchToProps = {
  chooseCap: (capId: string) => recommendationsActions.chooseCap(capId),
  chooseCapFromCap: () => recommendationsActions.chooseCapFromCap(true),
  closeDen: (cap: ICapEntity, email?: string) => {
    return actions.closeDen(cap, email);
  },
  closeModal: (modalType: NewCapModalTypes) => {
    return actions.closeNewCapModal(modalType);
  },
  closeOverdrafts: (cap: ICapEntity, email?: string) => {
    return actions.closeOverdrafts(cap, email);
  },
  closeValidation: (capId: string, responseValidation: 0 | 1) => {
    return actions.closeValidation(capId, responseValidation);
  },
  completeContractorSubmit: (
    values: ICompleteContractorFormData,
    user: IUserEntity
  ) => {
    return actions.completeContractor(values, user);
  },
  confirmIdentificationSubmit(cap: ICapEntity, user: IUserEntity) {
    return actions.confirmIdentification(cap, user);
  },
  copyDataInCap(copyData: Partial<IDataSnapshot>, cap: ICapEntity) {
    return actions.copyDataInCap(copyData, cap);
  },
  createContractorReset: () => {
    return actions.createContractorReset();
  },
  createContractorSubmit: (values: ICreateContractorFormData) => {
    return actions.createContractor(values);
  },
  employmentSubmit: (values: IEmploymentFormData, cap: ICapEntity) => {
    return actions.employment(values, cap);
  },
  hasIBIPsSubmit: (values: IHasIBIPsFormData | undefined, cap: ICapEntity) => {
    return actions.hasIBIPs(values, cap);
  },
  homeSubmit: (values: IHomeFormData, cap: ICapEntity) => {
    return actions.home(values, cap);
  },
  iddSend: (cap: ICapEntity) => {
    return actions.iddSend(cap);
  },
  iddSubmit: (values: IIddFormData, cap: ICapEntity) => {
    return actions.idd(values, cap);
  },
  incomesSubmit: (values: IIncomesFormData, cap: ICapEntity) => {
    return actions.incomes(values, cap);
  },
  injurySubmit: (cap: ICapEntity) => {
    return actions.injury({evaluated: true}, cap);
  },
  loadCopy: (id: string) => {
    return actions.loadCopy(id);
  },
  loadCopyReset: () => {
    return actions.loadCopyReset();
  },
  morteSubmit: (cap: ICapEntity) => {
    return actions.morte({evaluated: true}, cap);
  },
  openModal: (modalType: NewCapModalTypes) => {
    return actions.openNewCapModal(modalType);
  },
  openRequestOTPCapModal: (capId: string) => {
    return eSignActions.openModal(PDFTypes.cap, capId);
  },
  openRequestOTPIdentificationModal: (capId: string) => {
    return eSignActions.openModal(PDFTypes.identification, capId);
  },
  openRequestOTPPrivacyModal: (userId: string) => {
    return eSignActions.openModal(PDFTypes.privacy, userId);
  },
  optionsList: () => {
    return optionsActions.loadOptions();
  },
  overdraftSubmit: (values: any, cap: ICapEntity, empty?: boolean) => {
    return actions.overdraft(values, cap, empty);
  },
  privacyOpenModal: () => {
    return userActions.privacyOpenModal();
  },
  read: (id: string) => {
    return actions.read(id);
  },
  requestValidation: (capId: string, email?: string) => {
    return actions.requestValidation(capId, email);
  },
  resetCreateUseExistingContractor: () => {
    return actions.resetCreateUseExistingContractor();
  },
  resetIsIddSendSuccess: () => actions.resetIsIddSendSuccess(),
  resetLastCreatedId: () => {
    return actions.resetLastCreatedId();
  },
  resetPasswordContractor: (contractorId: string) => {
    return usersActions.resetPasswordContractor(contractorId);
  },
  resetReadRedirect: () => {
    return actions.resetReadRedirect();
  },
  retirementSubmit: (cap: ICapEntity) => {
    return actions.retirement({evaluated: true}, cap);
  },
  savingSubmit: (values: ISavingFormData, cap: ICapEntity) => {
    return actions.saving(values, cap);
  },
  sendPrivacySubscriptionNotification: (cap: ICap) => {
    return usersActions.privacySubscriptionNotification(
      cap!.id,
      !!cap!.contractor.privacySubscription
    );
  },
  targetSubmit: (values: ITargetFormData, cap: ICapEntity) => {
    return actions.target(values, domandeObiettivi, cap);
  },
  toggleIddSendModal: (contractorId: string) =>
    actions.toggleIddSendModal(contractorId),
  tutelaSubmit: (cap: ICapEntity) => {
    return actions.tutela({evaluated: true}, cap);
  },
  updateContractorSubmit: (
    values: IUpdateContractorFormData,
    user: IUserEntity
  ) => {
    return actions.updateContractor(values, user);
  },
  uploadIdFail: (error: IApiError) => {
    return actions.uploadIdFail(error);
  },
  uploadIdSubmit: (
    userId: string,
    cap: ICapEntity,
    values: IUploadIdFormData
  ) => {
    return actions.uploadId(userId, cap, values);
  },
  useExistingContractorSubmit: (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    return actions.useExistingContractor();
  },
};

class NewPage extends React.Component<IProps, IState> {
  public static async getDerivedStateFromProps(props: IProps) {
    if (
      !!props.match.params.capId &&
      !props.newCap.copyData &&
      !(
        props.newCap.thunkActionsStates.loadCopy.isPending ||
        props.newCap.thunkActionsStates.loadCopy.isFail
      )
    ) {
      await props.loadCopy(props.match.params.capId);
    }
  }

  private static scrollToSendCredentials() {
    const targetDiv = document.getElementById("send-credentials");
    const top = targetDiv!.offsetTop - 16;
    animateScrollTo(top);
  }

  public readonly state: Readonly<IState> = {
    activeTab: "marketing",
    check1: false,
    check2: false,
    check3: false,
  };

  constructor(props: IProps) {
    super(props);

    this.comprensioneChecked = this.comprensioneChecked.bind(this);
    this.esattezzaChecked = this.esattezzaChecked.bind(this);
    this.fornireChecked = this.fornireChecked.bind(this);
    this.scrollToIdd = this.scrollToIdd.bind(this);
    this.scrollToOverdraft = this.scrollToOverdraft.bind(this);
    this.toggle = this.toggle.bind(this);
    this.chooseCap = this.chooseCap.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  public esattezzaChecked() {
    this.setState({check1: !this.state.check1});
  }

  public comprensioneChecked() {
    this.setState({check2: !this.state.check2});
  }

  public fornireChecked() {
    this.setState({check3: !this.state.check3});
  }

  public async componentDidMount() {
    if (!!this.props.match.params.capId && !this.props.item) {
      await this.props.read(this.props.match.params.capId);
    }
  }

  public componentWillUnmount() {
    // Resetto lastCreatedId altrimenti all'aperture della pagina ci porta nell'analisi fatta in precedenza.
    this.props.resetLastCreatedId();
    this.props.resetReadRedirect();
    this.props.loadCopyReset();
  }

  public render() {
    // TODO: tutta questa logica va spostata in selectors nella mapStateToProps
    // Controlli sul completamento delle varie fasi
    const hasSuitableOption =
      !!this.props.item &&
      !!this.props.item.idd &&
      this.props.item.idd.suitableOptions.length > 0;
    /*
     * Controllo se il contractor ha tutti i dettagli del cassetto Anagrafica Cliente
     * Serve perché quando si autocompletano i dati usando un user già esistente,
     * può succedere che l'user non sia completo e l'app potrebbe crashare più avanti
     */
    const hasAllContractorDetails = checkHasAllContractorDetails(
      this.props.item
    );
    // const hasHome = this.props.item && this.props.item.data.home;
    // const hasEmployment = this.props.item && this.props.item.data.employments;
    // const hasSaving =this.props.item && this.props.item.data.saving
    // const hasTargets =this.props.item && this.props.item.data.targets
    // Devo mostrare la pensione?
    let showRetirement = false;
    if (this.props.item && this.props.item.data.employments) {
      const emp = this.props.item.data.employments.currentEmployment;
      if (
        emp &&
        emp.tipologia !== "pensione" &&
        emp.tipologia !== "disoccupato"
      ) {
        showRetirement = true;
      }
    } else {
      showRetirement = true;
    }
    // Stampiamo la pensione cmq solo nel caso sia nel file di conf
    showRetirement =
      showRetirement && (yn(process.env.REACT_APP_ENABLE_RETIREMENT) || false);

    // Controllo se le varie sezioni sono bloccate
    const isContractor = this.props.item && this.props.item.contractor;
    const isContractorPrivacy =
      this.props.item &&
      this.props.item.contractor &&
      this.props.item.contractor.lastPrivacyEsignId;
    const isOnboardingBlocked =
      !!this.props.item &&
      !!this.props.item.contractor.identification &&
      !!this.props.item.contractor.lastPrivacyEsignId;
    const isDenBlocked =
      !!this.props.item &&
      (!!this.props.item.dateIdd || !!this.props.item.dateScoperture);
    const isOverdraftsBlocked =
      !!this.props.item &&
      (!!this.props.item.dateScoperture ||
        !!this.props.item.dateResponseValidation);
    const isIddBlocked =
      !!this.props.item &&
      (!!this.props.item.dateIdd || !!this.props.item.dateResponseValidation);
    // const isValidationBlocked =
    //   !!this.props.item &&
    //   !!this.props.item.dateResponseValidation;

    // calcolo le barre per i targets. TODO: salvare nel json direttamente i valori delle barre
    const targetsData =
      this.props.item &&
      targetSerie(
        this.props.item.data.targets,
        this.props.item.data.targetsWeights
      );

    return (
      <Layout isProtected>
        {!this.props.isAdvisor &&
          this.props.match.params.capId &&
          this.props.item && (
            <Redirect
              to={`/analisi/view/${this.props.match.params.capId}`}
              push
            />
          )}
        {this.props.ui.isCreateContractorSuccess &&
          this.props.newCap.lastCreatedId && (
            <Redirect
              to={`/analisi/new/${this.props.newCap.lastCreatedId}`}
              push
            />
          )}
        {this.props.ui.readRedirect ? <Redirect to="/analisi" push /> : null}
        {this.props.chosenCap && <Redirect to="/raccomandazioni/new" push />}
        <Container>
          <h2>Analisi personalizzata</h2>
        </Container>
        <ContainerMax isLoading={this.props.ui.isReadPending}>
          <h3>Onboarding del Cliente</h3>
          <Drawer
            icon={IconPerson}
            isButtonDisabled={isOnboardingBlocked}
            isOpen={!!(this.props.item && this.props.item.contractor)}
            openDrawerButton={
              <>
                <IconPlus /> Attiva
              </>
            }
            modal={
              isContractor ? (
                <UpdateContractorModal
                  closeUpdateContractorModal={
                    this.props.resetCreateUseExistingContractor
                  }
                  useExistingContractorSubmit={
                    this.props.useExistingContractorSubmit
                  }
                  updateContractorSubmit={this.props.updateContractorSubmit}
                  ui={this.props.ui}
                  contractor={this.props.item!.contractor}
                  errorExistingUserId={this.props.errorExistingUserId}
                  loggedUser={this.props.user}
                />
              ) : (
                <CreateContractorModal
                  closeCreateContractorModal={
                    this.props.resetCreateUseExistingContractor
                  }
                  createContractorSubmit={this.props.createContractorSubmit}
                  useExistingContractorSubmit={
                    this.props.useExistingContractorSubmit
                  }
                  ui={this.props.ui}
                  createContractorReset={this.props.createContractorReset}
                  errorExistingUserId={this.props.errorExistingUserId}
                  loggedUser={this.props.user}
                />
              )
            }
            openModal={
              isOnboardingBlocked
                ? undefined
                : isContractorPrivacy
                ? undefined
                : isContractor
                ? this.openModal(NewCapModalTypes.UpdateContractor)
                : this.openModal(NewCapModalTypes.CreateContractor)
            }
            title="Area personale"
          >
            {this.props.item && this.props.item.contractor && (
              <CreateContractorDetails
                details={this.props.item.contractor}
                resetPasswordContractor={this.resetPasswordContractor}
                isResetPasswordContractorPending={
                  this.props.usersUi.isResetPasswordContractorPending
                }
                isResetPasswordContractorSuccess={
                  this.props.usersUi.isResetPasswordContractorSuccess
                }
                resetPasswordContractorError={
                  this.props.usersUi.resetPasswordContractorError
                }
              />
            )}
          </Drawer>
          <Drawer
            icon={IconPerson}
            isOpen={!!(this.props.item && this.props.item.contractor)}
            title="Accettazione del consenso"
          >
            {this.props.item && (
              <PrivacyDetails
                capId={this.props.item.id}
                contractorId={this.props.item.contractor.id}
                details={
                  Array.isArray(this.props.item.contractor.privacy)
                    ? last(this.props.item.contractor.privacy)
                    : this.props.item.contractor.privacy
                }
                eSignId={this.props.item.contractor.lastPrivacyEsignId}
                isCapComplianceApr21={isCapComplianceApr21(this.props.item)}
                isContractor={this.props.isContractor}
                openRequestOTPPrivacyModal={
                  isContractor && this.props.openRequestOTPPrivacyModal
                }
                privacyOpenModal={this.props.privacyOpenModal}
                showAttachments3And4={showAttachments3And4(this.props.item)}
              />
            )}
          </Drawer>
          <OnboardingCounter cap={this.props.item} />
        </ContainerMax>
        <ContainerMax
          isLocked={
            !this.props.item || !this.props.item.contractor.lastPrivacyEsignId
          }
          isLoading={this.props.ui.isReadPending}
        >
          <h3>
            Demands and Needs Test
            <br />
            <small>Rilevazione delle esigenze assicurative del cliente</small>
          </h3>
          <p>
            La consulenza inizia con la raccolta di tutte le informazioni
            necessarie per la corretta interpretazione delle risposte al
            questionario IBIPs e l'analisi delle scoperture.
          </p>
          {this.props.newCap.isFail && (
            <Alert color="danger">{this.props.newCap.error}</Alert>
          )}
          <CopyData
            copyDataInCapStates={
              this.props.newCap.thunkActionsStates.copyDataInCap
            }
            loadCopyStates={this.props.newCap.thunkActionsStates.loadCopy}
            copyFunction={this.copyDenDataInCap}
            isOpen={
              !!this.props.newCap.copyData?.den?.data &&
              !this.props.item?.data.home &&
              !this.props.item?.esignId
            }
          >
            È presente un'analisi precedente per questo cliente, effettuata in
            data{" "}
            {moment(this.props.newCap.copyData?.den?.date).format(
              "DD MMMM YYYY[ alle ]HH:mm"
            )}
            , copiare i dati del Demand and Needs Test in quest'analisi? (sarà
            possibile la successiva modifica)
          </CopyData>
          <Drawer
            icon={IconPerson}
            isButtonDisabled={
              !this.props.item?.isLast &&
              (isDenBlocked || this.props.newCap.isPending)
            }
            isOpen={this.props.item && hasAllContractorDetails}
            modal={
              this.props.item && (
                <CompleteContractorModal
                  contractor={this.props.item.contractor}
                  closeCompleteContractor={this.closeModal(
                    NewCapModalTypes.CompleteContractor
                  )}
                  ui={this.props.ui}
                  completeContractorSubmit={this.props.completeContractorSubmit}
                />
              )
            }
            openDrawerButton={
              <>
                {this.props.newCap.isPending ? (
                  <IconSpinner className="icon-spin" />
                ) : (
                  <IconFillIn />
                )}{" "}
                Inserisci
              </>
            }
            openModal={
              !this.props.item?.isLast && isDenBlocked
                ? undefined
                : this.openModal(NewCapModalTypes.CompleteContractor)
            }
            title="Anagrafica"
          >
            {this.props.item ? (
              <ContractorTable contractor={this.props.item.contractor} />
            ) : null}
          </Drawer>
          <Drawer
            icon={IconHouse}
            isButtonDisabled={
              !this.props.item?.isLast &&
              (isDenBlocked || !this.props.item || !this.props.item.contractor)
            }
            isOpen={!!(this.props.item && this.props.item.data.home)}
            modal={
              <HomeModal
                cap={this.props.item!}
                closeHome={this.closeModal(NewCapModalTypes.Home)}
                homeSubmit={this.props.homeSubmit}
                ui={this.props.ui}
              />
            }
            openModal={
              !this.props.item?.isLast && isDenBlocked
                ? undefined
                : this.openModal(NewCapModalTypes.Home)
            }
            title="Residenza"
          >
            {this.props.item && this.props.item.data.home && (
              <HomeDetails details={this.props.item.data.home} />
            )}
          </Drawer>
          <Drawer
            icon={IconTargets}
            isButtonDisabled={
              isDenBlocked || !this.props.item || !this.props.item.contractor
            }
            isOpen={!!(this.props.item && this.props.item.data.targets)}
            modal={
              this.props.item && (
                <TargetsModal
                  closeTarget={this.closeModal(NewCapModalTypes.Target)}
                  cap={this.props.item}
                  ui={this.props.ui}
                  targetSubmit={this.props.targetSubmit}
                />
              )
            }
            openModal={
              isDenBlocked ? undefined : this.openModal(NewCapModalTypes.Target)
            }
            title="Obiettivi"
          >
            {this.props.item &&
              this.props.item.data.targets &&
              this.props.item.data.targetsWeights && (
                <TargetsDetails
                  details={this.props.item.data.targets}
                  weights={this.props.item.data.targetsWeights}
                />
              )}
          </Drawer>
          <Drawer
            icon={IconEmployment}
            isButtonDisabled={
              isDenBlocked || !this.props.item || !this.props.item.contractor
            }
            isOpen={!!(this.props.item && this.props.item.data.employments)}
            modal={
              <EmploymentsModal
                closeEmployment={this.closeModal(NewCapModalTypes.Employment)}
                employmentSubmit={this.props.employmentSubmit}
                cap={this.props.item!}
                ui={this.props.ui}
              />
            }
            openModal={
              isDenBlocked
                ? undefined
                : this.openModal(NewCapModalTypes.Employment)
            }
            title="Situazione professionale"
          >
            {this.props.item && this.props.item.data.employments && (
              <EmploymentsTable
                employments={this.props.item.data.employments}
              />
            )}
          </Drawer>
          <Drawer
            icon={IconIncomes}
            isButtonDisabled={
              isDenBlocked || !this.props.item || !this.props.item.contractor
            }
            isOpen={!!(this.props.item && this.props.item.data.incomes)}
            modal={
              <IncomesModal
                closeIncomes={this.closeModal(NewCapModalTypes.Incomes)}
                incomesSubmit={this.props.incomesSubmit}
                cap={this.props.item!}
                ui={this.props.ui}
              />
            }
            openModal={
              isDenBlocked
                ? undefined
                : this.openModal(NewCapModalTypes.Incomes)
            }
            title="Altri redditi"
          >
            {this.props.item && this.props.item.data.incomes && (
              <IncomesDetails incomes={this.props.item.data.incomes} />
            )}
          </Drawer>
          <h3 className="drawer-spacer">Analisi generale</h3>
          <Drawer
            closeDrawerButton={
              <>
                <IconCheckmark color="success" /> Calcolata
              </>
            }
            icon={IconDoctor}
            isButtonDisabled={
              !this.props.item ||
              !this.props.item.data.employments ||
              !!this.props.item.data.injury
            }
            isOpen={!!(this.props.item && this.props.item.data.injury)}
            openDrawerButton={
              <>
                <IconCalculate /> Calcola
              </>
            }
            openModal={isOverdraftsBlocked ? undefined : this.injurySubmit}
            title="INAIL e INPS"
          >
            {this.props.item &&
              this.props.item.data.injury &&
              this.props.item.data.employments && (
                <div>
                  <h3 className="mb-3 p-3 mt-3 subtitle">
                    INAIL: tutela da infortuni e malattia sul lavoro
                  </h3>
                  <p>
                    L’INAIL (Istituto nazionale Assicurazione Infortuni sul
                    Lavoro) tutela esclusivamente i dipendenti; gli artigiani e
                    i commercianti sono protetti solo nel caso abbiano deciso di
                    aderire all’istituto.
                  </p>
                  <InailTable
                    employments={this.props.item.data.employments}
                    contractor={this.props.item.contractor}
                  />
                  <h3 className="mb-3 p-3 subtitle">
                    INPS: Tutela da infortuni e malattie fuori dal luogo di
                    lavoro
                  </h3>
                  <p>
                    La tutela da infortuni e malattia che non si verificano in
                    contesto lavorativo è garantita dall’INPS. Per accedere alla
                    protezione è richiesta una anzianità contributiva di almeno
                    5 anni, di cui 3 maturati in maniera continuativa.
                  </p>
                  <InpsTable
                    employments={this.props.item.data.employments}
                    contractor={this.props.item.contractor}
                  />
                </div>
              )}
          </Drawer>
          <Drawer
            icon={IconSavings}
            isButtonDisabled={
              isDenBlocked || !this.props.item || !this.props.item.contractor
            }
            isOpen={!!(this.props.item && this.props.item.data.saving)}
            modal={
              <SavingModal
                closeSaving={this.closeModal(NewCapModalTypes.Saving)}
                cap={this.props.item!}
                savingSubmit={this.props.savingSubmit}
                ui={this.props.ui}
              />
            }
            openModal={
              isDenBlocked ? undefined : this.openModal(NewCapModalTypes.Saving)
            }
            title="Capacità di risparmio"
          >
            {this.props.item &&
              this.props.item.data &&
              this.props.item.data.saving && (
                <SavingTable saving={this.props.item.data.saving} />
              )}
          </Drawer>
          <DenCounter cap={this.props.item} />
        </ContainerMax>
        <ContainerMax
          isLocked={
            !this.props.item ||
            !this.props.item.dateDen ||
            !hasAllContractorDetails
          }
          isLoading={this.props.ui.isReadPending}
          id="send-credentials"
        >
          <CardTitle tag="h4">Invia una notifica al tuo cliente</CardTitle>
          <CardText className="mb-4">
            Il tuo cliente riceverà una mail con la conferma del completamento
            del Demand and Needs test.
          </CardText>
          <div className="text-center">
            <Button color="primary" outline onClick={this.closeDen}>
              {this.props.ui.isCloseDenPending && (
                <IconSpinner className="icon-spin mr-2" />
              )}
              Invia notifica al cliente
            </Button>
          </div>
        </ContainerMax>
        <ContainerMax
          isLocked={
            !this.props.item ||
            !this.props.item.dateDen ||
            !hasAllContractorDetails
          }
          isLoading={this.props.ui.isReadPending}
        >
          <Nav justified className="nav-max mt-n5">
            <NavItem>
              <NavLink
                href="#"
                className={classNames({
                  active: this.state.activeTab === "marketing",
                })}
                onClick={this.toggle.bind({}, "marketing")}
              >
                Analisi delle scoperture
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                className={classNames({
                  active: this.state.activeTab === "idd",
                })}
                onClick={this.toggle.bind({}, "idd")}
              >
                Questionario IBIPs
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={this.state.activeTab}>
            <TabPane tabId="marketing" className="auto-margin-4">
              {showRetirement && (
                <Drawer
                  closeDrawerButton={
                    <>
                      <IconCheckmark color="success" /> Calcolata
                    </>
                  }
                  icon={IconRetirement}
                  isButtonDisabled={
                    isOverdraftsBlocked ||
                    !!(this.props.item && this.props.item.data.retirement)
                  }
                  isOpen={
                    !!(this.props.item && this.props.item.data.retirement)
                  }
                  openDrawerButton={
                    <>
                      <IconEvaluate /> Stima
                    </>
                  }
                  openModal={
                    isOverdraftsBlocked ? undefined : this.retirementSubmit
                  }
                  title="Stima della pensione"
                >
                  {this.props.item &&
                  this.props.item.data.retirement &&
                  this.props.item.data.employments ? (
                    <div>
                      <h3 className="m-0">STIMA DELLA PENSIONE</h3>
                      <RetirementDetails
                        employments={this.props.item.data.employments}
                        contractor={this.props.item.contractor}
                      />
                      <p className="smaller">
                        L'importo della pensione pubblica, l'età di
                        pensionamento e il gap previdenziale vengono{" "}
                        <strong>stimati</strong> con il metodo contributivo in
                        base alle informazioni da lei fornite (età, genere,
                        tipologia di lavoro, anzianità contributiva e reddito),
                        in base alla normativa in vigore e una rivalutazione del
                        reddito del 1,9% annuo.
                      </p>
                    </div>
                  ) : null}
                </Drawer>
              )}
              <h3>Analisi delle scoperture</h3>
              <CopyData
                copyDataInCapStates={
                  this.props.newCap.thunkActionsStates.copyDataInCap
                }
                loadCopyStates={this.props.newCap.thunkActionsStates.loadCopy}
                copyFunction={this.copyOverdraftsDataInCap}
                isOpen={
                  !!this.props.newCap.copyData?.overdrafts?.data &&
                  !this.props.item?.data.overdrafts?.oneComplete &&
                  !this.props.item?.esignId
                }
              >
                È presente un'analisi precedente per questo cliente, effettuata
                in data{" "}
                {moment(this.props.newCap.copyData?.overdrafts?.date).format(
                  "DD MMMM YYYY[ alle ]HH:mm"
                )}
                , copiare i dati delle scoperture in quest'analisi? (sarà
                possibile la successiva modifica)
              </CopyData>
              <Drawer
                icon={IconOverdraft}
                id="overdraft-drawer"
                isButtonDisabled={
                  isOverdraftsBlocked ||
                  !!(
                    this.props.item &&
                    this.props.item.data.overdrafts &&
                    this.props.item.data.overdrafts.beniCasaAnimali
                  )
                }
                isOpen={
                  !!(
                    this.props.item &&
                    this.props.item.data.overdrafts &&
                    this.props.item.data.overdrafts.beniCasaAnimali
                  )
                }
                openDrawerButton={
                  <>
                    {this.props.ui.isOverdraftPending ? (
                      <IconSpinner className="icon-spin" />
                    ) : (
                      <IconFillIn />
                    )}{" "}
                    Apri
                  </>
                }
                openModal={
                  isOverdraftsBlocked
                    ? undefined
                    : this.openOverdraftDrawer("beniCasaAnimali")
                }
                alwaysVisible={
                  Number.isInteger(
                    (this.props.item?.data.overdrafts
                      ?.beniCasaAnimali as IOverdraftsSectionFormData)
                      ?.consultantRating
                  ) ? undefined : (
                    <Alert color="info">
                      Se non si completa questa sezione, non sarà possibile
                      proporre al cliente le relative scoperture.
                    </Alert>
                  )
                }
                title="Tutelare beni, casa, animali"
              >
                {this.props.item &&
                  ["beniCasaAnimali"].map((name) => {
                    const overdraft = overdraftsSections[name];
                    return (
                      <OverdraftSectionForm
                        closeOverdraftDrawer={this.closeOverdraftDrawer(
                          "beniCasaAnimali"
                        )}
                        cap={this.props.item!}
                        hideButtons={isOverdraftsBlocked}
                        disabled={isOverdraftsBlocked}
                        form={`overdraft-${name}`}
                        target={(targetsData && targetsData[0]) || 0}
                        key={name}
                        name={name}
                        overdraftSubmit={this.props.overdraftSubmit}
                        questions={overdraft.questions}
                        title={overdraft.title}
                        autonomo={
                          !!this.props.item &&
                          this.props.item.data.employments &&
                          this.props.item.data.employments.currentEmployment
                            .tipologia === "autonomo"
                        }
                      />
                    );
                  })}
              </Drawer>
              <Drawer
                icon={IconOverdraft}
                id="overdraft-drawer"
                isButtonDisabled={
                  isOverdraftsBlocked ||
                  !!(
                    this.props.item &&
                    this.props.item.data.overdrafts &&
                    this.props.item.data.overdrafts.tenoreVita
                  )
                }
                isOpen={
                  !!(
                    this.props.item &&
                    this.props.item.data.overdrafts &&
                    this.props.item.data.overdrafts.tenoreVita
                  )
                }
                openDrawerButton={
                  <>
                    {this.props.ui.isOverdraftPending ? (
                      <IconSpinner className="icon-spin" />
                    ) : (
                      <IconFillIn />
                    )}{" "}
                    Apri
                  </>
                }
                openModal={
                  isOverdraftsBlocked
                    ? undefined
                    : this.openOverdraftDrawer("tenoreVita")
                }
                alwaysVisible={
                  Number.isInteger(
                    (this.props.item?.data.overdrafts
                      ?.overdraftSubmit as IOverdraftsSectionFormData)
                      ?.consultantRating
                  ) ? undefined : (
                    <Alert color="info">
                      Se non si completa questa sezione, non sarà possibile
                      proporre al cliente le relative scoperture.
                    </Alert>
                  )
                }
                title="Tutelare il tenore di vita personale e familiare"
              >
                {this.props.item &&
                  ["tenoreVita"].map((name) => {
                    const overdraft = overdraftsSections[name];
                    return (
                      <OverdraftSectionForm
                        closeOverdraftDrawer={this.closeOverdraftDrawer(
                          "tenoreVita"
                        )}
                        cap={this.props.item!}
                        hideButtons={isOverdraftsBlocked}
                        disabled={isOverdraftsBlocked}
                        form={`overdraft-${name}`}
                        target={(targetsData && targetsData[1]) || 0}
                        key={name}
                        name={name}
                        overdraftSubmit={this.props.overdraftSubmit}
                        questions={overdraft.questions}
                        title={overdraft.title}
                        autonomo={
                          !!this.props.item &&
                          this.props.item.data.employments &&
                          this.props.item.data.employments.currentEmployment
                            .tipologia === "autonomo"
                        }
                      />
                    );
                  })}
              </Drawer>
              <Drawer
                icon={IconOverdraft}
                id="overdraft-drawer"
                isButtonDisabled={
                  isOverdraftsBlocked ||
                  !!(
                    this.props.item &&
                    this.props.item.data.overdrafts &&
                    this.props.item.data.overdrafts.infortuniMalattiaPrevenzione
                  )
                }
                isOpen={
                  !!(
                    this.props.item &&
                    this.props.item.data.overdrafts &&
                    this.props.item.data.overdrafts.infortuniMalattiaPrevenzione
                  )
                }
                openDrawerButton={
                  <>
                    {this.props.ui.isOverdraftPending ? (
                      <IconSpinner className="icon-spin" />
                    ) : (
                      <IconFillIn />
                    )}{" "}
                    Apri
                  </>
                }
                openModal={
                  isOverdraftsBlocked
                    ? undefined
                    : this.openOverdraftDrawer("infortuniMalattiaPrevenzione")
                }
                alwaysVisible={
                  Number.isInteger(
                    (this.props.item?.data.overdrafts
                      ?.infortuniMalattiaPrevenzione as IOverdraftsSectionFormData)
                      ?.consultantRating
                  ) ? undefined : (
                    <Alert color="info">
                      Se non si completa questa sezione, non sarà possibile
                      proporre al cliente le relative scoperture.
                    </Alert>
                  )
                }
                title="Tutelarsi da infortuni, malattia & prevenzione"
              >
                {this.props.item &&
                  ["infortuniMalattiaPrevenzione"].map((name) => {
                    const overdraft = overdraftsSections[name];
                    return (
                      <OverdraftSectionForm
                        closeOverdraftDrawer={this.closeOverdraftDrawer(
                          "infortuniMalattiaPrevenzione"
                        )}
                        cap={this.props.item!}
                        hideButtons={isOverdraftsBlocked}
                        disabled={isOverdraftsBlocked}
                        form={`overdraft-${name}`}
                        target={(targetsData && targetsData[2]) || 0}
                        key={name}
                        name={name}
                        overdraftSubmit={this.props.overdraftSubmit}
                        questions={overdraft.questions}
                        title={overdraft.title}
                        autonomo={
                          !!this.props.item &&
                          this.props.item.data.employments &&
                          this.props.item.data.employments.currentEmployment
                            .tipologia === "autonomo"
                        }
                      />
                    );
                  })}
              </Drawer>
              <CardTitle tag="h4">
                Salva e rendi visibile al cliente l'Analisi delle scoperture
              </CardTitle>
              {this.props.item && (
                <>
                  {!this.props.item ||
                  !this.props.item.data.overdrafts ||
                  !this.props.item.data.overdrafts.oneComplete ? (
                    <Alert color="info">
                      Per poter concludere questa sezione, è necessario salvare
                      almeno uno dei tre moduli dell'analisi delle scoperture
                      <ul className=" mb-0">
                        <li>Tutelare beni, casa, animali</li>
                        <li>
                          Tutelare il tenore di vita personale e familiare
                        </li>
                        <li>Tutelarsi da infortuni, malattia & prevenzione</li>
                      </ul>
                    </Alert>
                  ) : this.props.item.password ? (
                    isOverdraftsBlocked ? (
                      this.props.item.dateScoperture ? (
                        <>
                          <Alert color="success">
                            Sezione completata correttamente
                          </Alert>
                          <div className="text-center">
                            <Button
                              color="primary"
                              outline
                              onClick={this.closeOverdrafts}
                              disabled={
                                this.props.ui.isCloseOverdraftsPending ||
                                !this.props.item ||
                                !this.props.item.data.overdrafts ||
                                !this.props.item.data.overdrafts.oneComplete
                              }
                            >
                              {this.props.ui.isCloseOverdraftsPending && (
                                <IconSpinner className="icon-spin" />
                              )}
                              Invia nuovamente una notifica al cliente
                            </Button>
                          </div>
                        </>
                      ) : (
                        <Alert color="info">
                          Analisi delle scoperture non completata
                        </Alert>
                      )
                    ) : (
                      <>
                        <Alert color="warning">
                          Attenzione! Completando questa sezione, non sarà più
                          possibile modificarla e aggiornare i dati della
                          sezione Demands and Needs Test.
                        </Alert>
                        <div className="text-center">
                          <Button
                            color="primary"
                            outline
                            onClick={this.closeOverdrafts}
                            disabled={
                              this.props.ui.isCloseOverdraftsPending ||
                              !this.props.item ||
                              !this.props.item.data.overdrafts ||
                              !this.props.item.data.overdrafts.oneComplete
                            }
                          >
                            {this.props.ui.isCloseOverdraftsPending && (
                              <IconSpinner className="icon-spin" />
                            )}{" "}
                            Salva tutto e invia notifica al cliente
                          </Button>
                        </div>
                      </>
                    )
                  ) : (
                    <Alert
                      color="warning"
                      className="d-flex align-items-center"
                    >
                      Attenzione! Per poter concludere questa sezione devi prima
                      inviare al cliente le credenziali di accesso
                      <Button
                        color="dark"
                        className="ml-auto"
                        outline
                        onClick={NewPage.scrollToSendCredentials}
                      >
                        Invia credenziali
                      </Button>
                    </Alert>
                  )}
                </>
              )}
            </TabPane>
            <TabPane tabId="idd" className="auto-margin-4">
              {this.props.item && (
                <HasIBIPsDrawer
                  cap={this.props.item!}
                  disabled={isIddBlocked}
                  hasIBIPsSubmit={this.props.hasIBIPsSubmit}
                  options={this.props.options}
                  optionsList={this.props.optionsList}
                  ui={this.props.ui}
                />
              )}
              <Drawer
                icon={IconRetirement}
                isOpen
                title="Costruire un capitale per il futuro"
              >
                {this.props.item &&
                  ["risparmioFuturo"].map((name) => {
                    const overdraft = overdraftsSections[name];
                    return (
                      <OverdraftSectionForm
                        cap={this.props.item!}
                        hideButtons={isIddBlocked}
                        form={`overdraft-${name}`}
                        disabled={isIddBlocked}
                        target={(targetsData && targetsData[3]) || 0}
                        key={name}
                        name={name}
                        bars={false}
                        overdraftSubmit={this.props.overdraftSubmit}
                        questions={overdraft.questions}
                        title={overdraft.title}
                        subtitle={overdraft.subtitle}
                        hasIbips={
                          this.props.item!.data.hasIBIPs &&
                          this.props.item!.data.hasIBIPs.IBIPs.length > 0
                        }
                      />
                    );
                  })}
              </Drawer>
              <Drawer
                icon={IconDoctor}
                isOpen
                closeDrawerButton={
                  <>
                    <IconEye /> Vedi risposte
                  </>
                }
                openModal={
                  this.props.item &&
                  this.props.item.idd &&
                  this.openModal(NewCapModalTypes.ViewIdd)
                }
                modal={
                  this.props.item &&
                  (this.props.item.idd ? (
                    <IddViewModal
                      closeViewIdd={this.closeModal(NewCapModalTypes.ViewIdd)}
                      isOpen={
                        !!this.props.ui.isModalOpen[NewCapModalTypes.ViewIdd]
                      }
                      capItem={this.props.item}
                      ui={this.props.ui}
                    />
                  ) : (
                    <IddModal
                      closeIdd={this.closeModal(NewCapModalTypes.Idd)}
                      iddSubmit={this.props.iddSubmit}
                      cap={this.props.item}
                      ui={this.props.ui}
                    />
                  ))
                }
                title="Valutazione di adeguatezza IBIPs"
              >
                {!this.props.item ||
                !this.props.item.data ||
                !this.props.item.data.overdrafts ||
                !this.props.item.data.overdrafts.risparmioFuturo ||
                !this.props.item.data.hasIBIPs ? (
                  <Alert color="warning">
                    Prima di poter completare questo modulo, devi aver concluso
                    e salvato i moduli soprastanti "Possiedi attualmente dei
                    prodotti per costruire il capitale per il tuo futuro?" e
                    "Costruire un capitale per il futuro"
                  </Alert>
                ) : this.props.item && this.props.item.idd ? (
                  <>
                    <div>
                      <h6>Profilo cliente</h6>
                      <IddResultSnippet idd={this.props.item.idd.idd} />
                    </div>
                    <ContractorProductTable idd={this.props.item.idd} />
                  </>
                ) : (
                  <>
                    <CardText id="idd-drawer">
                      La compilazione del questionario di valutazione di
                      adeguatezza IBIPs permette di verificare quali fondi del
                      vostro paniere sono appropriati ed adeguati
                      all'esperienza, alla situazione finanziaria, agli
                      obiettivi di investimento, alla propensione al rischio e
                      all'orizzonte temporale del vostro Cliente.
                    </CardText>
                    <CardSubtitle tag="h5">Cosa succederà?</CardSubtitle>
                    <CardText>
                      Compilando il questionario si otterranno i seguenti
                      risultati:
                    </CardText>
                    <ul>
                      <li>
                        verranno mostrati i punteggi ottenuti dal Cliente per
                        ogni criterio di valutazione;
                      </li>
                      <li>
                        verrà mostrato l'elenco dei vostri fondi appropriati ed
                        adeguati al Cliente;
                      </li>
                      <li>
                        i dati della sezione "demands & needs" non saranno più
                        modificabili;
                      </li>
                    </ul>
                    <CardSubtitle tag="h5">Prima di iniziare</CardSubtitle>
                    <FormGroup check>
                      <Label check>
                        <Input
                          id="check1"
                          name="check1"
                          type="checkbox"
                          onChange={this.esattezzaChecked}
                          disabled={
                            !this.props.item ||
                            !this.props.item.data ||
                            !this.props.item.data.overdrafts ||
                            !this.props.item.data.overdrafts.risparmioFuturo
                          }
                        />{" "}
                        Dichiaro di aver ricontrollato l'esattezza delle
                        informazioni fornite in "Demands & Needs Test"
                      </Label>
                      <Label check>
                        <Input
                          id="check2"
                          name="check2"
                          type="checkbox"
                          disabled={
                            !this.props.item ||
                            !this.props.item.data ||
                            !this.props.item.data.overdrafts ||
                            !this.props.item.data.overdrafts.risparmioFuturo
                          }
                          onChange={this.comprensioneChecked}
                        />{" "}
                        Dichiaro di aver compreso che i dati inseriti nella
                        sezione "demands & needs" non si potranno più modificare
                      </Label>
                      {this.props.item &&
                      this.props.item.contractor &&
                      !this.props.item.contractor.email ? (
                        <Label check>
                          <Input
                            id="check3"
                            name="check3"
                            type="checkbox"
                            onChange={this.fornireChecked}
                          />{" "}
                          Mi impegno a fornire al cliente copia dei risultati in
                          maniera alternativa alla comunicazione email
                        </Label>
                      ) : null}
                    </FormGroup>
                    <div className="text-center">
                      <Button
                        color="primary"
                        outline
                        disabled={
                          !this.props.item ||
                          isIddBlocked ||
                          !this.state.check1 ||
                          !this.state.check2 ||
                          (!this.state.check3 &&
                            (!this.props.item.contractor ||
                              !this.props.item.contractor.email))
                        }
                        onClick={this.openModal(NewCapModalTypes.Idd)}
                      >
                        Inizia la valutazione
                      </Button>
                    </div>
                  </>
                )}
              </Drawer>
              <CardTitle tag="h4">
                Salva e rendi visibile al cliente il Questionario IBIPs
              </CardTitle>
              {this.props.item && (
                <>
                  {!this.props.item || !this.props.item.idd ? (
                    <Alert color="info">
                      Per poter concludere questa sezione, è necessario salvare
                      i moduli del questionario IBIPs nel seguente ordine:
                      <ul className=" mb-0">
                        <li>
                          Possiedi attualmente dei prodotti IBIPs per costruire
                          il capitale per il tuo futuro?
                        </li>
                        <li>Costruire un capitale per il futuro</li>
                        <li>Valutazione di adeguatezza IBIPs</li>
                      </ul>
                    </Alert>
                  ) : this.props.item.password ? (
                    isIddBlocked ? (
                      <>
                        <Alert color="success">
                          Sezione completata correttamente
                        </Alert>
                        <div className="text-center">
                          <Button
                            color="primary"
                            outline
                            onClick={this.iddSend}
                            disabled={!this.props.item || !this.props.item.idd}
                          >
                            {this.props.ui.isIddSendPending && (
                              <IconSpinner className="icon-spin mr-2" />
                            )}
                            Invia nuovamente una notifica al cliente
                          </Button>
                        </div>
                      </>
                    ) : null
                  ) : (
                    <Alert
                      color="warning"
                      className="d-flex align-items-center"
                    >
                      Attenzione! Per poter concludere questa sezione devi prima
                      inviare al cliente le credenziali di accesso
                      <Button
                        color="dark"
                        className="ml-auto"
                        outline
                        onClick={NewPage.scrollToSendCredentials}
                      >
                        Invia credenziali
                      </Button>
                    </Alert>
                  )}
                </>
              )}
            </TabPane>
          </TabContent>
        </ContainerMax>
        <ContainerMax
          isLocked={
            !this.props.item?.dateIdd && !this.props.item?.dateScoperture
          }
          isLoading={this.props.ui.isReadPending}
          id="identification"
        >
          <h3 className="d-flex justify-content-between">
            Identificazione del cliente
          </h3>
          <Drawer
            icon={IconPerson}
            isButtonDisabled={
              !this.props.item || !this.props.item.contractor.lastPrivacyEsignId
            }
            isOpen={!!this.props.item}
            modal={
              this.props.item && (
                <UploadIdModal
                  closeIdentification={this.closeModal(
                    NewCapModalTypes.UploadId
                  )}
                  isUploadIdFail={this.props.isUploadIdFail}
                  uploadIdError={this.props.uploadIdError}
                  uploadIdFail={this.props.uploadIdFail}
                  uploadIdSubmit={this.uploadIdSubmit}
                  ui={this.props.ui}
                />
              )
            }
            openModal={
              // Se abbiamo l'identificazione, ed è firmata da entrambi, oppure è valida vecchia
              this.props.item?.onboarding?.identification &&
              ((this.props.item.onboarding.identification.esignId &&
                this.props.item.onboarding.identification.contractorEsignId) ||
                (this.props.item.onboarding.identification.esignId &&
                  !this.props.item.onboarding.identification.fileIdFaceName &&
                  !this.props.item.onboarding.identification.fileIdFCName &&
                  !!this.props.item.dateResponseValidation))
                ? undefined
                : this.openModal(NewCapModalTypes.UploadId)
            }
            closeDrawerButton={
              <>
                <IconFillIn /> Carica un documento del cliente
              </>
            }
            title="Identificazione"
          >
            {this.props.item && (
              <IdentificationDetails
                cap={this.props.item}
                contractor={this.props.item.contractor}
                details={
                  this.props.item.onboarding &&
                  this.props.item.onboarding.identification
                }
                isAdvisor={this.props.isAdvisor}
                isContractor={this.props.isContractor}
                isIdentificationPending={this.props.ui.isIdentificationPending}
                identificationError={this.props.ui.identificationError}
                openRequestOTPIdentificationModal={
                  this.props.openRequestOTPIdentificationModal
                }
                identificationSubmit={this.confirmIdentificationSubmit}
              />
            )}
          </Drawer>
        </ContainerMax>
        {(!this.props.item?.dateResponseValidation ||
          this.props.item?.dateResponseValidation?.isAfter(
            moment(process.env.REACT_APP_DATE_START_DELIVERY)
          )) && (
          <>
            <Delivery cap={this.props.item} />
            <CloseCap cap={this.props.item} id="close-cap" />
            <PrivacySubscription
              as={ContainerMax}
              cap={this.props.item}
              isLocked={!this.props.item?.esignId}
              isLoading={this.props.ui.isReadPending}
              id="subscription-privacy"
            />
          </>
        )}
        {this.props.item && (
          <Progress
            capId={this.props.item.id}
            capReady={!this.props.ui.isReadPending}
            denHandleClick={this.closeDen}
            denState={!!this.props.item.dateDen}
            denDisabled={!this.props.item.dateDen}
            iddDisabled={!this.props.item.dateDen || !!this.props.item.dateIdd}
            iddHandleClick={this.iddSend}
            iddState={!!this.props.item.dateIdd}
            isLast={!!this.props.item.isLast}
            hasSuitableOption={hasSuitableOption}
            overdraftHandleClick={this.closeOverdrafts}
            overdraftDisabled={!this.props.item.dateDen}
            isContractor={this.props.isContractor}
            isOverdraftPending={!!this.props.ui.isCloseOverdraftsPending}
            isSigned={!!this.props.item.esignId}
            hasSubscription={
              !!this.props.item.contractor.lastPrivacySubscriptionEsignId
            }
            privacySubscriptionNotificationStates={
              this.props.privacySubscriptionNotificationStates
            }
            sendPrivacySubscriptionNotification={
              this.sendPrivacySubscriptionNotification
            }
            closeValidation={this.props.closeValidation}
            openRequestOTPCapModal={this.props.openRequestOTPCapModal}
            overdraftOneComplete={
              !!this.props.item.data.overdrafts &&
              !!this.props.item.data.overdrafts.oneComplete
            }
            overdraftState={!!this.props.item.dateScoperture}
            isValidated={
              !!this.props.item.dateResponseValidation &&
              this.props.item.responseValidation?.toString() === "1"
            }
            isRejected={
              !!this.props.item.dateResponseValidation &&
              this.props.item.responseValidation?.toString() === "0"
            }
            isRequestValidationPending={
              !!this.props.ui.isRequestValidationPending
            }
            isValidationRequested={!!this.props.item.dateSendValidation}
            isDelivered={!!this.props.item.dateDelivery}
            scrollToSendCredentials={NewPage.scrollToSendCredentials}
            scrollToOverdraft={this.scrollToOverdraft}
            scrollToIdd={this.scrollToIdd}
            requestValidation={this.requestValidation}
            chooseCap={this.chooseCap}
            isIddSendPending={!!this.props.ui.isIddSendPending}
            isCloseDenPending={!!this.props.ui.isCloseDenPending}
          />
        )}
      </Layout>
    );
  }

  private sendPrivacySubscriptionNotification = () => {
    if (this.props.item) {
      this.props.sendPrivacySubscriptionNotification(this.props.item).then();
    }
  };

  private getContractorEntity = () => {
    if (!this.props.item) {
      return this.props.item;
    }

    const {
      result,
      entities: {caps, users},
    } = dehydrateCap(this.props.item);
    const itemEntity = caps[result];
    return users[itemEntity.contractor];
  };

  private getItemEntity = () => {
    if (!this.props.item) {
      return this.props.item;
    }

    const {
      result,
      entities: {caps},
    } = dehydrateCap(this.props.item);
    return caps[result];
  };

  private injurySubmit = () => {
    this.props.injurySubmit(this.getItemEntity()!);
  };
  private retirementSubmit = () => {
    this.props.retirementSubmit(this.getItemEntity()!);
  };
  private iddSend = async () => {
    await this.props.iddSend(this.getItemEntity()!);
  };

  private uploadIdSubmit = async (values: IUploadIdFormData) => {
    await this.props.uploadIdSubmit(
      this.getContractorEntity()!.id,
      this.getItemEntity()!,
      values
    );
  };

  private confirmIdentificationSubmit = async () => {
    const itemEntity = this.getItemEntity()!;
    await this.props.confirmIdentificationSubmit(
      itemEntity,
      this.getContractorEntity()!
    );
    this.props.openRequestOTPIdentificationModal(itemEntity.id);
  };

  private chooseCap() {
    this.props.chooseCap(this.getItemEntity()!.id);
    this.props.chooseCapFromCap();
  }

  private openOverdraftDrawer = (sectionName: string) => async () => {
    await this.props.overdraftSubmit({sectionName}, this.getItemEntity()!);
  };
  private closeOverdraftDrawer = (sectionName: string) => async () => {
    await this.props.overdraftSubmit(
      {sectionName},
      this.getItemEntity()!,
      true
    );
  };
  private closeDen = async () => {
    await this.props.closeDen(
      this.getItemEntity()!,
      this.getContractorEntity()!.email
    );
  };
  private closeOverdrafts = async () => {
    await this.props.closeOverdrafts(
      this.getItemEntity()!,
      this.getContractorEntity()!.email
    );
  };
  private requestValidation = async () => {
    await this.props.requestValidation(
      this.getItemEntity()!.id,
      this.getContractorEntity()!.email
    );
  };

  private resetPasswordContractor = async () => {
    await this.props.resetPasswordContractor(this.getContractorEntity()!.id);
  };

  private copyDenDataInCap = async () => {
    if (this.props.newCap.copyData?.den?.data) {
      await this.props.copyDataInCap(
        this.props.newCap.copyData.den.data,
        this.getItemEntity()!
      );
    }
  };
  private copyOverdraftsDataInCap = async () => {
    if (this.props.newCap.copyData?.overdrafts?.data) {
      await this.props.copyDataInCap(
        this.props.newCap.copyData.overdrafts.data,
        this.getItemEntity()!
      );
    }
  };

  private openModal(modalType: NewCapModalTypes) {
    return () => this.props.openModal(modalType);
  }

  private closeModal(modalType: NewCapModalTypes) {
    return () => this.props.closeModal(modalType);
  }

  private toggle(tab: string) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  private scrollToIdd() {
    this.setState(
      {
        activeTab: "idd",
      },
      () => {
        const targetDiv = document.getElementById("idd-drawer");
        animateScrollTo(targetDiv!, {verticalOffset: -16});
      }
    );
  }

  private scrollToOverdraft() {
    this.setState(
      {
        activeTab: "marketing",
      },
      () => {
        const targetDiv = document.getElementById("overdraft-drawer");
        animateScrollTo(targetDiv!, {verticalOffset: -16});
      }
    );
  }
}

const ConnectedPage = connect(mapStateToProps, mapDispatchToProps)(NewPage);
export default ConnectedPage;
