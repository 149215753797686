import {ICap} from "../Caps";

type ICheckHasAllContractorDetails = (cap?: ICap) => boolean;

const checkHasAllContractorDetails: ICheckHasAllContractorDetails = cap =>
  !!(
    cap &&
    cap.contractor &&
    cap.contractor.birthday &&
    cap.contractor.birthplace &&
    cap.contractor.gender &&
    cap.contractor.education &&
    cap.contractor.maritalStatus &&
    cap.contractor.personalPhone
  );

export default checkHasAllContractorDetails;
