import React from "react";
import {Card, CardText, CardTitle, Col, Row} from "reactstrap";
import FormatNumber from "../helpers/FormatNumber";
import {ICap} from "../Caps";
import {IconSavings} from "../Icons";

interface IProps {
  cap: ICap;
}

const SavingDetails: React.FC<IProps> = ({cap}) => {
  const saving = parseFloat((cap.data.saving && cap.data.saving.saving) || "0");
  const gruzzoletto = parseFloat(
    (cap.data.saving && cap.data.saving.gruzzoletto) || "0"
  );
  return (
    <Card body color="drawer" className="auto-margin-3">
      <h3>
        <IconSavings title="Capacità di risparmio del cliente" /> Capacità di
        risparmio
      </h3>
      <Row>
        <Col md="4" className="mb-3 mb-md-0">
          <Card body className="text-white bg-primary text-center">
            <CardTitle>Capacità di risparmio trimestrale</CardTitle>
            <CardText className="h2">
              € <FormatNumber>{saving * 3}</FormatNumber>
            </CardText>
          </Card>
        </Col>
        <Col md="4" className="mb-3 mb-md-0">
          <Card body className="text-white bg-primary text-center">
            <CardTitle>Capacità di risparmio semestrale</CardTitle>
            <CardText className="h2">
              € <FormatNumber>{saving * 6}</FormatNumber>
            </CardText>
          </Card>
        </Col>
        <Col md="4">
          <Card body className="text-white bg-primary text-center">
            <CardTitle>Capacità di risparmio annua</CardTitle>
            <CardText className="h2">
              € <FormatNumber>{saving * 12}</FormatNumber>
            </CardText>
          </Card>
        </Col>
      </Row>
      <Card body className="text-white bg-primary text-center mt-3">
        <CardTitle>Liquidità disponibile</CardTitle>
        <CardText className="h2">
          € <FormatNumber>{gruzzoletto}</FormatNumber>
        </CardText>
      </Card>
    </Card>
  );
};

export default SavingDetails;
