import React from "react";
import {connect} from "react-redux";
import {
  Alert,
  Button,
  Form,
  FormGroup,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {Field, InjectedFormProps, reduxForm} from "redux-form";
import {emailNormalizer} from "../../helpers/normalizers";
import RenderField from "../../helpers/RenderField";
import {email} from "../../helpers/validators";
import {IconSpinner} from "../../Icons";
import {IRootState} from "../../redux/reducers";
import {ICap} from "../types";

export interface IIddSendFormData {
  email: string;
}

interface IIddSendFormErrors {
  email?: string;
}

interface IOwnProps {
  cap: ICap;
  isIddSendFail?: boolean;
  iddSendError?: string;
  toggleIddSendModal: () => any;
}

const validate = (values: IIddSendFormData) => {
  const errors: IIddSendFormErrors = {};

  if (!values.email || !email(values.email)) {
    errors.email = "Inserisci un indirizzo email valido";
  }

  return errors;
};

const IddSendForm: React.FC<
  IOwnProps & InjectedFormProps<IIddSendFormData, IOwnProps>
> = (props) => (
  <Form noValidate onSubmit={props.handleSubmit}>
    <ModalHeader toggle={props.toggleIddSendModal}>
      Re-invia questionario
    </ModalHeader>
    <ModalBody>
      <p>
        I risultati del questionario di Valutazione Adeguatezza IBIPs verranno
        inviati all'indirizzo email specificato del cliente. Riceverai
        automaticamente una copia di questa mail al tuo indirizzo.
      </p>
      <FormGroup>
        <Label for="email">Email</Label>
        <Field
          component={RenderField}
          readOnly
          id="email"
          name="email"
          type="email"
          normalize={emailNormalizer}
        />
      </FormGroup>
      {props.isIddSendFail ? (
        <Alert color="danger">{props.iddSendError}</Alert>
      ) : null}
    </ModalBody>
    <ModalFooter>
      <Button type="submit" color="primary" block disabled={props.submitting}>
        {props.submitting ? <IconSpinner className="icon-spin" /> : ""} Invia
        questionario
      </Button>{" "}
      <Button
        color="secondary"
        type="button"
        onClick={props.toggleIddSendModal}
      >
        Annulla
      </Button>
    </ModalFooter>
  </Form>
);

const IddSendFormConnected = reduxForm<IIddSendFormData, IOwnProps>({
  form: "iddSendForm",
  validate,
})(IddSendForm);

export default connect((state: IRootState, ownProps: IOwnProps) => {
  const initialValues = {email: ownProps.cap!.contractor.email};

  return {
    initialValues,
  };
})(IddSendFormConnected);
