import {connect} from "react-redux";
import {RouteComponentProps} from "react-router";
import {
  actions,
  IRecommendationEntity,
  IRevision,
  RecommendationStatus,
} from "../";
import {ICap} from "../../Caps";
import {actions as optionsActions} from "../../Caps/actions/options";
import {getOptions} from "../../Caps/selectors";
import {actions as eSignActions} from "../../ESign";
import {getThunkActionStates} from "../../ESign/selectors";
import {PDFTypes} from "../../ESign/types";
import {IRootState} from "../../redux/reducers";
import {getUser} from "../../User/selectors";
import {IChooseOptionsFormData} from "../New/ChooseOptionsForm";
import {
  getCanApprove,
  getCanSeeRecommendationCap,
  getIsAdvisor,
  getIsContractor,
  getIsCreateSuccess,
  getIsReadPending,
  getLastCreatedId,
  getUpdateError,
  getUpdateErrorPerType,
  getUpdatePendingPerType,
  getViewRecommendation,
} from "../selectors";
import Page from "./Page";

interface IMatchParams {
  recommendationId: string;
}
interface IOwnProps extends RouteComponentProps<IMatchParams> {}

const mapStateToProps = (state: IRootState, ownProps: IOwnProps) => {
  return {
    acceptError: getUpdateErrorPerType(state, "Accept"),
    availableOptions: getOptions(state),
    canSeeCap: getCanSeeRecommendationCap(
      state,
      ownProps.match.params.recommendationId
    ),
    isAcceptPending: getUpdatePendingPerType(state, "Accept"),
    isAdvisor: getIsAdvisor(state, ownProps.match.params.recommendationId),
    isBackOffice: getCanApprove(state),
    isContractor: getIsContractor(
      state,
      ownProps.match.params.recommendationId
    ),
    isCreateSuccess: getIsCreateSuccess(state),
    isReadPending: getIsReadPending(state),
    isRejectPending: getUpdatePendingPerType(state, "Reject"),
    lastCreatedId: getLastCreatedId(state),
    rejectError: getUpdateErrorPerType(state, "Reject"),
    signFEADocStates: getThunkActionStates(state, "signFEADoc"),
    submitError: getUpdateError(state),
    user: getUser(state),
    viewRecommendation: getViewRecommendation(
      state,
      ownProps.match.params.recommendationId
    ),
  };
};

const mapDispatchToProps = {
  archiveRecommendation: (recommendationId: string) => {
    return actions.update(recommendationId, {
      status: RecommendationStatus.STATUS_REJECTED,
    });
  },
  openRequestOTPModal: (recommendationId: string) => {
    return eSignActions.openModal(PDFTypes.recommendation, recommendationId);
  },
  optionsList: () => {
    return optionsActions.loadOptions();
  },
  reSendRecommendation: (
    recommendationId: string,
    values: IChooseOptionsFormData,
    revisions: IRevision[],
    cap: ICap
  ) => {
    const ids = Object.keys(values.options).filter(
      (key) => values.options[key].value
    );

    const options = ids.map((id) => ({
      option: cap.idd!.suitableOptions.find(
        (option) => option.id.toString() === id
      ),
      percent: parseFloat(values.options[id].percent),
    }));

    return actions.create({
      cap: cap.id!,
      code: values.code,
      comment: values.comment,
      jsonOptions: JSON.stringify(options),
      jsonRevisions: JSON.stringify(revisions),
      status: RecommendationStatus.STATUS_TO_BE_VALIDATED,
      user: values.userId,
    });
  },
  read: (recommendationId: string) => actions.read(recommendationId),
  resetNewRecommendation: () => actions.createReset(),
  validate: (id: string, values: Partial<IRecommendationEntity>) =>
    actions.update(
      id,
      values,
      values.status === RecommendationStatus.STATUS_CORRESPONDING
        ? "Accept"
        : "Reject"
    ),
};

export default connect(mapStateToProps, mapDispatchToProps)(Page);
