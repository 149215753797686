import isEmpty from "lodash/isEmpty";
import React, {VFC} from "react";
import {FieldErrors, useFormContext} from "react-hook-form";
import {Col, FormGroup, Label, Row} from "reactstrap";
import {
  emailNormalizer,
  naturalNormalizer,
} from "../../../../helpers/normalizers";
import {RhfInput} from "../../../../helpers/RHFInput";
import {email} from "../../../../helpers/validators";
import {RHFAutocompleteCitiesController} from "../../../../shared/RHFAutocompleteCitiesController";
import {INewBeneProposalFormData} from "../../NewBeneProposalForm";

interface ICapitalProps {
  disabled?: boolean;
}

export interface ICapitalFormData {
  address: string;
  cap: string;
  city: string;
  companyName: string;
  companyFiscalCode?: string;
  number: string;
  pec: string;
  region: string;
  vatNumber: string;
}

export const validation = (values: ICapitalFormData) => {
  const errors: FieldErrors<ICapitalFormData> = {};

  // Dati societari
  if (!values.companyName) {
    errors.companyName = {
      type: "required",
      message: "Inserisci la ragione sociale",
    };
  }
  if (!values.vatNumber) {
    errors.vatNumber = {
      type: "required",
      message: "Inserisci la partita iva",
    };
  } else if (values.vatNumber.length !== 11) {
    errors.vatNumber = {
      type: "pattern",
      message: "La partita iva non è nel formato corretto",
    };
  }
  if (!values.companyFiscalCode) {
    errors.companyFiscalCode = {
      type: "required",
      message: "Inserisci il codice fiscale",
    };
  } else if (values.companyFiscalCode.length !== 11) {
    errors.companyFiscalCode = {
      type: "pattern",
      message: "Il codice fiscale aziendale non è nel formato corretto",
    };
  }
  if (!values.pec) {
    errors.pec = {
      type: "required",
      message: "Inserisci la PEC",
    };
  } else if (!email(values.pec)) {
    errors.pec = {
      type: "pattern",
      message: "La PEC non è nel formato corretto",
    };
  }

  // Sede legale
  if (!values.address) {
    errors.address = {
      type: "required",
      message: "Inserisci l'indirizzo della sede legale",
    };
  }
  if (!values.number) {
    errors.number = {
      type: "required",
      message: "Inserisci il numero dell'indirizzo della sede legale",
    };
  }
  if (!values.cap) {
    errors.cap = {
      type: "required",
      message: "Inserisci il cap della sede legale",
    };
  }
  if (!values.city) {
    errors.city = {
      type: "required",
      message: "Inserisci la città della sede legale",
    };
  }
  if (!values.region) {
    errors.region = {
      type: "required",
      message: "Inserisci la città della sede legale",
    };
  }

  return {values: isEmpty(errors) ? values : {}, errors};
};

export const Capital: VFC<ICapitalProps> = ({disabled}) => {
  const {
    control,
    errors,
    formState,
    register,
    setValue,
  } = useFormContext<INewBeneProposalFormData>();

  return (
    <>
      <h5>Dati societari</h5>
      <RhfInput
        errors={errors}
        formState={formState}
        name="rc.companyName"
        register={register}
        label="Ragione sociale"
      />
      <RhfInput
        errors={errors}
        formState={formState}
        label="Partita IVA"
        name="rc.vatNumber"
        onChange={(e) => {
          setValue("rc.vatNumber", naturalNormalizer(e.target.value));
        }}
        register={register}
      />
      <RhfInput
        errors={errors}
        formState={formState}
        label="Codice fiscale aziendale"
        name="rc.companyFiscalCode"
        onChange={(e) => {
          setValue("rc.companyFiscalCode", naturalNormalizer(e.target.value));
        }}
        register={register}
      />
      <RhfInput
        errors={errors}
        formState={formState}
        label="PEC"
        name="rc.pec"
        onChange={(e) => {
          setValue("rc.pec", emailNormalizer(e.target.value));
        }}
        register={register}
      />
      <hr className="border-primary" />
      <h5>Sede legale</h5>
      <FormGroup className="mb-0">
        <Row>
          <Col>
            <RhfInput
              errors={errors}
              formState={formState}
              name="rc.address"
              register={register}
              label="Indirizzo"
            />
          </Col>
          <Col xs="auto" style={{width: "150px"}}>
            <RhfInput
              errors={errors}
              formState={formState}
              name="rc.number"
              register={register}
              label="Numero"
            />
          </Col>
        </Row>
      </FormGroup>
      <RhfInput
        errors={errors}
        formState={formState}
        name="rc.cap"
        register={register}
        label="CAP"
      />
      <FormGroup className="mb-0">
        <Label for="city">Città</Label>
        <Row>
          <Col>
            <RHFAutocompleteCitiesController
              control={control}
              errors={errors}
              formState={formState}
              id="city"
              name="rc.city"
              placeholder="Città"
              regionName="rc.region"
              setValue={setValue}
              strict
            />
          </Col>
          <Col xs="auto" style={{width: "90px"}}>
            <RhfInput name="rc.region" register={register} readOnly />
          </Col>
        </Row>
      </FormGroup>
    </>
  );
};
