import React from "react";
import {Modal, ModalBody, ModalHeader} from "reactstrap";
import AcceptForm, {IAcceptFormData} from "./AcceptForm";

interface IProps {
  isOpen: boolean;
  toggle: () => void;
  isAcceptPending?: boolean;
  acceptError?: string;
  accept: (values: IAcceptFormData) => void;
}

const AcceptModal: React.FC<IProps> = ({
  isOpen,
  isAcceptPending,
  toggle,
  acceptError,
  accept,
}) => (
  <Modal centered isOpen={isOpen} toggle={toggle}>
    <ModalHeader toggle={toggle}>Approvazione definitiva</ModalHeader>
    <ModalBody>
      <p>
        L'approvazione della raccomandazione è definitiva e irreversibile. Non
        sarà più possibile richiedere revisioni né modificarne i dati.
      </p>
      <p>
        Per confermare l'approvazione scrivere "APPROVO" nel campo sottostante e
        cliccare sul bottone "Conferma definitiva".
      </p>
      <AcceptForm
        onSubmit={accept}
        isAcceptPending={isAcceptPending}
        acceptError={acceptError}
      />
    </ModalBody>
  </Modal>
);

export default AcceptModal;
