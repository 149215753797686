import moment from "moment";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {Alert, Button, Card, Modal, ModalBody, ModalHeader} from "reactstrap";
import {actions as eSignActions} from "../../../../ESign";
import {PDFTypes} from "../../../../ESign/types";
import {
  IconCheckmark,
  IconESign,
  IconESignedDocument,
  IconEye,
  IconTimes,
} from "../../../../Icons";
import {actions} from "../../../actions";
import {
  getIsESignsManagementModalOpen,
  getOpenFileName,
} from "../../../selectors";
import {IProposal, IProposalESign} from "../../../types";

interface IProps {
  fileName: string;
  isAdvisor: boolean;
  isContractor: boolean;
  proposal: IProposal;
}

const ESignsManagementModal = ({
  fileName,
  isAdvisor,
  isContractor,
  proposal,
}: IProps) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(getIsESignsManagementModalOpen);
  const openFileName = useSelector(getOpenFileName);

  const document = proposal.eSign?.files.find(
    (document) => document.fileName === fileName
  );

  const close = () => {
    dispatch(actions.closeESignsManagementModal());
  };
  const openRequestOTPCapModal = (esignIndex: string) => {
    dispatch(
      eSignActions.openModal(PDFTypes.proposal, proposal.id, {
        fileName,
        esignIndex,
      })
    );
  };

  const filterDocumentsByPermission = (eSign: IProposalESign) => {
    return (
      (isAdvisor && eSign.whoEsign === "advisor") ||
      (isContractor && eSign.whoEsign === "contractor")
    );
  };

  return (
    <Modal
      isOpen={isOpen && fileName === openFileName}
      toggle={close}
      backdrop="static"
      keyboard={false}
      className="modal-form"
    >
      <ModalHeader
        toggle={close}
        close={
          <Button size="sm" className="close-modal" onClick={close}>
            <IconTimes
              size={1.25}
              className="icon-border-2 rounded-circle p-1"
            />
          </Button>
        }
      >
        <IconESignedDocument
          size={3}
          className="icon-border-2 rounded-circle p-2"
        />
        Proposta di polizza
      </ModalHeader>
      <ModalBody>
        <div className="auto-margin-3">
          <Button
            color="primary"
            outline
            download
            href={`${process.env.REACT_APP_API_BASE_URL}proposals/${proposal.id}/download-file?fileName=${fileName}`}
          >
            <IconEye /> Visualizza il documento caricato
          </Button>
          <h3>Firme richieste</h3>
          {document?.esigns.map((eSign, index) =>
            filterDocumentsByPermission(eSign) ? (
              <Card body key={index} className="auto-margin-3">
                <div dangerouslySetInnerHTML={{__html: eSign.description}} />
                {eSign.esignId ? (
                  <Alert color="success">
                    <IconCheckmark /> Firmato da {eSign.esignUser?.name}{" "}
                    {eSign.esignUser?.surname} in data{" "}
                    {moment(eSign.esignDate).format("LL[ alle ]LT")}
                  </Alert>
                ) : (
                  <Button
                    color="primary"
                    outline
                    onClick={() => openRequestOTPCapModal(index.toString())}
                  >
                    <IconESign /> Firma
                  </Button>
                )}
              </Card>
            ) : null
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ESignsManagementModal;

/*
  OTP: string;
  transactionId: string;
  pdfType: PDFTypes; -> templateProposta
  referenceId: string; -> idProposta

*/
