import classNames from "classnames";
import moment from "moment";
import React from "react";
import {connect} from "react-redux";
import {
  Alert,
  Button,
  Card,
  Form,
  FormGroup,
  Label,
  Progress,
  Table,
  UncontrolledTooltip,
} from "reactstrap";
import {Dispatch} from "redux";
import {
  change,
  Field,
  FieldArray,
  formValueSelector,
  initialize,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import {focusFirstInvalid} from "../helpers/focusFirstInvalid";
import {birthdayFormatter} from "../helpers/formatters";
import {
  IOverdraftsLeaf,
  IOverdraftsNode,
  IOverdraftsQuestion,
} from "../helpers/overdraftsSections";
import RenderCustomCheckboxField from "../helpers/RenderCustomCheckboxField";
import RenderField from "../helpers/RenderField";
import {dateIt} from "../helpers/validators";
import {
  IconCheckmark,
  IconInfoCircle,
  IconMinus,
  IconPlus,
  IconSlide,
  IconSpinner,
} from "../Icons";
import {IRootState} from "../redux/reducers";
import "./OverdraftSectionForm.scss";
import {dehydrateCap, ICap, ICapEntity} from "./types";

export interface IOverdraftQuestion {
  checked?: boolean;
  intermediary?: string;
  policy?: string;
  company?: string;
  expiration?: string;
}
export interface IOverdraftsSectionFormData {
  comment: string;
  consultantRating: number;
  overdraftsCovered: number;
  sectionName: string;
  [name: string]: IOverdraftQuestion | number | string;
}
interface IOverdraftsSectionFormErrors {
  comment?: string;
  [name: string]: string | undefined;
}
interface IOwnProps {
  autonomo?: boolean;
  bars?: boolean;
  cap: ICap;
  disabled?: boolean;
  form: string;
  hasIbips?: boolean;
  hideButtons?: boolean;
  name: string;
  overdraftSubmit?: (
    values: IOverdraftsSectionFormData,
    cap: ICapEntity
  ) => Promise<void>;
  closeOverdraftDrawer?: () => void;
  questions: IOverdraftsQuestion[];
  target: number;
  title: string;
  subtitle?: string;
}
interface IStateProps {
  dispatch: Dispatch;
  inputs: IOverdraftsSectionFormData | undefined;
  empty: boolean;
}
type IProps = InjectedFormProps<IOverdraftsSectionFormData, IOwnProps> &
  IStateProps &
  IOwnProps;
interface IState {
  isConsultantBarTouched: boolean;
  isDragging: boolean;
  left: number;
  width: number;
}

// Usiamo la validazione a livello di input per semplicità visto l'annidamento complicato di questo form
const expirationValidate = (expiration: string) => {
  if (!expiration) {
    return undefined;
  }

  if (
    !dateIt(expiration as any) ||
    !moment(expiration, "DD-MM-YYYY").isValid()
  ) {
    return "La data di scadenza non è nel formato corretto gg/mm/aaaa";
  } else if (moment(expiration, "DD-MM-YYYY").isBefore(moment())) {
    return (
      "La data di scadenza inserita è precedente alla data minima " +
      moment().format("DD-MM-YYYY")
    );
  }

  return undefined;
};

const validate = (values: IOverdraftsSectionFormData) => {
  const errors: IOverdraftsSectionFormErrors = {};

  if (values.consultantRating !== values.overdraftsCovered && !values.comment) {
    errors.comment = "Motiva la tua valutazione sulle scoperture";
  }

  return errors;
};

const FurtherCoverageFieldArray: React.FC<any> = ({
  checked,
  bars,
  checkHandler,
  disabled,
  fields,
  forceCheckboxDisabled,
  name,
  question,
  parentQuestion,
  totalWeights,
  subQuestion,
}) => {
  return (
    <>
      <tr>
        <td style={subQuestion ? {paddingLeft: "30px"} : {}}>
          <Field
            id={`${name}-${question.name}-checked`}
            name={`${question.name}.checked`}
            component={RenderCustomCheckboxField}
            type="checkbox"
            className="custom-switch"
            label={question.question}
            onChange={
              parentQuestion
                ? checkHandler.bind({}, question, parentQuestion, fields)
                : checkHandler.bind({}, question, fields)
            }
            disabled={disabled || forceCheckboxDisabled}
            noValidate
          />
        </td>
        <td>
          {question.weight && bars ? (
            <>
              <span id={`${question.name}`}>
                <IconInfoCircle
                  size={2}
                  color={checked && !disabled ? "primary" : "secondary"}
                  style={{
                    cursor: "help",
                    marginTop: "9px",
                  }}
                  title=" "
                />
              </span>
              <UncontrolledTooltip
                autohide={false}
                boundariesElement="window"
                className="tooltip-md"
                placement="top"
                target={question.name}
              >
                <div className="text-left">
                  {question.description ? <p>{question.description}</p> : ""}
                  <p className="mb-0">
                    Importanza:{" "}
                    {((question.weight / totalWeights) * 100).toFixed(2)} %
                  </p>
                </div>
              </UncontrolledTooltip>
            </>
          ) : (
            " "
          )}
        </td>
        <td>
          <Field
            name={`${question.name}.company`}
            component={RenderField}
            type="text"
            disabled={!checked || disabled}
            noValidate
          />
        </td>
        <td>
          <Field
            name={`${question.name}.policy`}
            component={RenderField}
            type="text"
            disabled={!checked || disabled}
            noValidate
          />
        </td>
        <td>
          <Field
            name={`${question.name}.intermediary`}
            component={RenderField}
            type="text"
            disabled={!checked || disabled}
            noValidate
          />
        </td>
        <td>
          <Field
            component={RenderField}
            disabled={!checked || disabled}
            format={birthdayFormatter}
            name={`${question.name}.expiration`}
            placeholder="gg/mm/aaaa"
            type="text"
            validate={expirationValidate}
          />
        </td>
        <td>
          <IconPlus
            size={1.25}
            color={checked && !disabled ? "primary" : "secondary"}
            className="icon-border-2 rounded-circle"
            style={{
              cursor: checked && !disabled ? "pointer" : "default",
              marginTop: "9px",
              padding: "3px",
            }}
            title="Aggiungi copertura"
            onClick={checked && !disabled ? fields.push.bind({}, {}) : null}
          />
        </td>
      </tr>
      {fields.map((field: string, index: number) => (
        <tr key={`${field}-${index}`}>
          <td />
          <td />
          <td>
            <Field
              name={`${field}.company`}
              component={RenderField}
              type="text"
              disabled={disabled}
              noValidate
            />
          </td>
          <td>
            <Field
              name={`${field}.policy`}
              component={RenderField}
              type="text"
              disabled={disabled}
              noValidate
            />
          </td>
          <td>
            <Field
              name={`${field}.intermediary`}
              component={RenderField}
              type="text"
              disabled={disabled}
              noValidate
            />
          </td>
          <td>
            <Field
              component={RenderField}
              disabled={disabled}
              format={birthdayFormatter}
              name={`${field}.expiration`}
              placeholder="gg/mm/aaaa"
              type="text"
              validate={expirationValidate}
            />
          </td>
          <td>
            <IconMinus
              size={1.25}
              color={disabled ? "secondary" : "danger"}
              className="icon-border-2 rounded-circle"
              style={{
                cursor: disabled ? "default" : "pointer",
                marginTop: "9px",
                padding: "3px",
              }}
              onClick={disabled ? null : fields.remove.bind({}, index)}
            />
          </td>
        </tr>
      ))}
    </>
  );
};

const mapStateToProps = (
  state: IRootState,
  {cap, form, name, questions}: IOwnProps
) => {
  const selector = formValueSelector(form);
  const inputs: IOverdraftsSectionFormData = {} as IOverdraftsSectionFormData;
  let empty;
  questions.forEach((question) => {
    inputs[question.name] = selector(state, question.name);
    if (question.type === "node") {
      question.questions.forEach((subQuestion) => {
        inputs[subQuestion.name] = selector(state, subQuestion.name);
      });
    }
  });
  inputs.consultantRating = selector(state, "consultantRating");
  inputs.overdraftsCovered = selector(state, "overdraftsCovered");

  let initialValues: Partial<IOverdraftsSectionFormData> = {};
  if (
    cap &&
    cap.data.overdrafts &&
    cap.data.overdrafts[name] &&
    (cap.data.overdrafts[name] as IOverdraftsSectionFormData)
      .overdraftsCovered !== undefined
  ) {
    initialValues = cap.data.overdrafts[name] as IOverdraftsSectionFormData;
    empty = false;
  } else {
    initialValues = {
      consultantRating: 0,
      overdraftsCovered: 0,
      sectionName: name,
    };
    questions.forEach((question) => {
      // Calcoliamo se il contractor ha un IBIPs
      const hasIbips = cap.data.hasIBIPs && cap.data.hasIBIPs.IBIPs.length > 0;
      const checked = hasIbips && question.name === "prodotti-assicurativi";
      initialValues[question.name] = {checked: !!checked};
    });
    empty = true;
  }

  return {inputs, initialValues, empty};
};

class OverdraftsSectionForm extends React.Component<IProps, IState> {
  public readonly state: Readonly<IState> = {
    isConsultantBarTouched: false,
    isDragging: false,
    left: 0,
    width: 1,
  };

  constructor(props: IProps) {
    super(props);

    this.handleMouseDownConsultantRating = this.handleMouseDownConsultantRating.bind(
      this
    );
    this.handleMouseUpConsultantRating = this.handleMouseUpConsultantRating.bind(
      this
    );
    this.dragConsultantRating = this.dragConsultantRating.bind(this);
    this.checkHandler = this.checkHandler.bind(this);
    this.subCheckHandler = this.subCheckHandler.bind(this);
  }

  public render() {
    const {
      autonomo = false,
      bars = true,
      closeOverdraftDrawer,
      disabled = false,
      empty,
      form,
      handleSubmit,
      hasIbips,
      hideButtons = false,
      inputs,
      name,
      pristine,
      questions,
      submitting,
      subtitle,
      title,
    } = this.props;

    return (
      <Form onSubmit={handleSubmit(this.submitWithCap)} name={form}>
        <Card color="drawer" body className="border-primary auto-margin-3">
          <h5>{title}</h5>
          {subtitle && <h6>{subtitle}</h6>}
          <Table size="sm" responsive>
            <thead>
              <tr>
                <th>Elenco coperture</th>
                <th />
                <th>Compagnia</th>
                <th>Prodotto</th>
                <th>Intermediario</th>
                <th>Data di scadenza</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {questions.map((question) => {
                let forceCheckboxDisabled = false;
                const checked =
                  inputs &&
                  inputs[question.name] &&
                  (inputs[question.name] as IOverdraftQuestion).checked;

                // Controllo se ho ibips attuali e se la domanda è prodotti-assicurativi la devo selezionare di default
                if (hasIbips && question.name === "prodotti-assicurativi") {
                  forceCheckboxDisabled = true;
                }

                if (question.onlyAutonomo && !autonomo) {
                  return null;
                }

                if (question.type === "question") {
                  return (
                    <FieldArray
                      checked={checked}
                      checkHandler={this.checkHandler}
                      component={FurtherCoverageFieldArray}
                      disabled={disabled}
                      forceCheckboxDisabled={forceCheckboxDisabled}
                      key={question.name}
                      name={`${question.name}.furtherCoverages`}
                      question={question}
                      totalWeights={this.totalWeights()}
                      bars={bars}
                    />
                  );
                } else {
                  return (
                    <React.Fragment key={question.name}>
                      <FieldArray
                        checked={checked}
                        checkHandler={this.checkHandler}
                        component={FurtherCoverageFieldArray}
                        disabled={disabled}
                        name={`${question.name}.furtherCoverages`}
                        question={question}
                        totalWeights={this.totalWeights()}
                        bars={bars}
                      />
                      {question.questions.map((subQuestion) => {
                        const subChecked =
                          inputs &&
                          inputs[subQuestion.name] &&
                          (inputs[subQuestion.name] as IOverdraftQuestion)
                            .checked;

                        if (question.onlyAutonomo && !autonomo) {
                          return null;
                        }

                        return (
                          <FieldArray
                            checked={subChecked}
                            checkHandler={this.subCheckHandler}
                            component={FurtherCoverageFieldArray}
                            disabled={disabled}
                            key={subQuestion.name}
                            name={`${subQuestion.name}.furtherCoverages`}
                            question={subQuestion}
                            totalWeights={this.totalWeights()}
                            bars={bars}
                            parentQuestion={question}
                            subQuestion
                          />
                        );
                      })}
                    </React.Fragment>
                  );
                }
              })}
            </tbody>
          </Table>
          {bars && (
            <>
              <Progress multi className="overdraft-bar">
                <Progress
                  bar
                  value={this.targetValue()}
                  className={classNames({
                    "has-text-left": this.targetValue() < 25,
                    "has-text-right": this.targetValue() >= 25,
                  })}
                >
                  <span>Obiettivo di copertura</span>
                </Progress>
                <Progress
                  bar
                  color="primary"
                  striped
                  value={100 - this.targetValue()}
                />
              </Progress>
              <Progress multi className="overdraft-bar">
                <Progress
                  bar
                  color={
                    inputs && inputs.overdraftsCovered > this.targetValue()
                      ? "success"
                      : "danger"
                  }
                  value={inputs && inputs.overdraftsCovered}
                  className={classNames({
                    "has-text-left":
                      !inputs ||
                      !inputs.overdraftsCovered ||
                      inputs.overdraftsCovered < 25,
                    "has-text-right":
                      inputs &&
                      inputs.overdraftsCovered &&
                      inputs.overdraftsCovered >= 25,
                  })}
                >
                  <span>Coperture attive</span>
                </Progress>
                <Progress
                  bar
                  color="danger"
                  striped
                  value={Math.max(
                    0,
                    this.targetValue() - (inputs?.overdraftsCovered ?? 0)
                  )}
                />
                <Progress
                  bar
                  color="success"
                  striped
                  value={
                    100 -
                    Math.max(
                      0,
                      this.targetValue() - (inputs?.overdraftsCovered ?? 0)
                    ) -
                    (inputs?.overdraftsCovered ?? 0)
                  }
                />
              </Progress>
              <Field name="overdraftsCovered" type="hidden" component="input" />
              <Progress
                multi
                className={classNames("overdraft-bar", {
                  "progress-bar-draggable": !disabled,
                  touched: this.state.isConsultantBarTouched,
                })}
                onMouseDown={this.handleMouseDownConsultantRating}
              >
                <Progress
                  bar
                  color={
                    inputs && inputs.consultantRating > this.targetValue()
                      ? "success"
                      : "danger"
                  }
                  value={inputs && inputs.consultantRating}
                  className={classNames({
                    "has-text-left":
                      !inputs ||
                      !inputs.consultantRating ||
                      inputs.consultantRating < 25,
                    "has-text-right":
                      inputs &&
                      inputs.consultantRating &&
                      inputs.consultantRating >= 25,
                  })}
                >
                  <span>Valutazione del consulente</span>
                  {!disabled && (
                    <IconSlide className="progress-bar-slide-icon" />
                  )}
                </Progress>
                <Progress
                  bar
                  color="danger"
                  striped
                  value={
                    inputs &&
                    Math.max(0, this.targetValue() - inputs.consultantRating)
                  }
                />
                <Progress
                  bar
                  color="success"
                  striped
                  value={
                    inputs &&
                    100 -
                      Math.max(
                        0,
                        this.targetValue() - inputs.consultantRating
                      ) -
                      inputs.consultantRating
                  }
                />
              </Progress>
              <Field name="consultantRating" type="hidden" component="input" />
            </>
          )}
          <FormGroup>
            <Label for={`${name}-comment`}>Commento</Label>
            <Field
              id={`${name}-comment`}
              name={`comment`}
              component={RenderField}
              type="textarea"
              disabled={disabled}
            />
            <Field name="sectionName" type="hidden" component="input" />
          </FormGroup>
          {bars &&
            !hideButtons &&
            inputs &&
            inputs.overdraftsCovered > this.targetValue() && // Le coperture devono essere maggiori del valore target
            (!pristine || empty) && // Il form non deve essere salvato
            inputs.consultantRating === inputs.overdraftsCovered && ( // Non deve esserci una valutazione qualitativa
              <Alert color="warning">
                Il cliente risulta sufficientemente tutelato in quest'area.
                Vuole inserire una valutazione qualitativa?
              </Alert>
            )}
          {!hideButtons && (
            <div className="text-center">
              <Button
                type="submit"
                color="primary"
                outline
                disabled={disabled || submitting}
              >
                {submitting ? (
                  <>
                    <IconSpinner className="icon-spin" /> Salvataggio in
                    corso...
                  </>
                ) : pristine && !empty ? (
                  <>
                    <IconCheckmark color="success" /> Salvato
                  </>
                ) : (
                  <>Salva</>
                )}
              </Button>{" "}
              <Button color="secondary" outline onClick={closeOverdraftDrawer}>
                Annulla
              </Button>
            </div>
          )}
        </Card>
      </Form>
    );
  }

  private submitWithCap = async (values: IOverdraftsSectionFormData) => {
    if (!this.props.hideButtons && this.props.overdraftSubmit) {
      const {
        result,
        entities: {caps},
      } = dehydrateCap(this.props.cap);
      await this.props.overdraftSubmit(values, caps[result]);
    }
  };

  private targetValue() {
    return Math.round(this.props.target * 10);
  }

  // Calcoliamo il valore totale delle risposte in questa sezione tenendo conto delle domande annidate e delle domande
  //  nascoste nel caso la persona non sia autonoma
  private totalWeights() {
    return this.props.questions.reduce((total, question) => {
      if (question.onlyAutonomo && !this.props.autonomo) {
        return total;
      } else {
        if (question.type === "question") {
          return total + question.weight;
        } else {
          return (
            total +
            question.questions.reduce(
              (subTotal, subQuestion) => subTotal + subQuestion.weight,
              0
            )
          );
        }
      }
    }, 0);
  }

  private checkHandler(
    question: IOverdraftsQuestion,
    fields: any,
    e: React.ChangeEvent<HTMLInputElement>,
    value: boolean
  ) {
    const questionName = question.name;
    if (!value) {
      this.props.dispatch(
        change(this.props.form, `${questionName}.intermediary`, "")
      );
      this.props.dispatch(
        change(this.props.form, `${questionName}.policy`, "")
      );
      this.props.dispatch(
        change(this.props.form, `${questionName}.company`, "")
      );
      this.props.dispatch(
        change(this.props.form, `${questionName}.expiration`, "")
      );
      fields.removeAll();
    }

    if (question && question.type === "question") {
      const weightPercent =
        question && (question.weight / this.totalWeights()) * 100;

      if (this.props.inputs && weightPercent) {
        const newValue = Math.max(
          0,
          Math.min(
            100,
            this.props.inputs.overdraftsCovered +
              (value ? weightPercent : -weightPercent)
          )
        );

        this.props.dispatch(
          change(this.props.form, "overdraftsCovered", newValue)
        );
        if (!this.state.isConsultantBarTouched) {
          this.props.dispatch(
            change(this.props.form, "consultantRating", newValue)
          );
        }
      }
    } else if (
      question &&
      question.type === "node" &&
      !e.target.classList.contains("manual")
    ) {
      question.questions.forEach((quest) => {
        const input: HTMLInputElement = document.getElementsByName(
          `${quest.name}.checked`
        )[0] as HTMLInputElement;
        if (input && input.checked !== value) {
          setTimeout(() => {
            input.classList.add("manual");
            input.click();
          }, 0);
        }
      });
      e.target.classList.remove("intermediate");
    }
    e.target.classList.remove("manual");
  }

  private subCheckHandler(
    question: IOverdraftsLeaf,
    parentQuestion: IOverdraftsNode,
    fields: any,
    e: React.ChangeEvent<HTMLInputElement>,
    value: boolean
  ) {
    const questionName = question.name;
    if (!value) {
      this.props.dispatch(
        change(this.props.form, `${questionName}.intermediary`, "")
      );
      this.props.dispatch(
        change(this.props.form, `${questionName}.policy`, "")
      );
      this.props.dispatch(
        change(this.props.form, `${questionName}.company`, "")
      );
      this.props.dispatch(
        change(this.props.form, `${questionName}.expiration`, "")
      );
      fields.removeAll();
    }

    const weightPercent =
      question && (question.weight / this.totalWeights()) * 100;

    if (this.props.inputs && weightPercent) {
      const newValue = Math.max(
        0,
        Math.min(
          100,
          this.props.inputs.overdraftsCovered +
            (value ? weightPercent : -weightPercent)
        )
      );

      this.props.dispatch(
        change(this.props.form, "overdraftsCovered", newValue)
      );
      if (!this.state.isConsultantBarTouched) {
        this.props.dispatch(
          change(this.props.form, "consultantRating", newValue)
        );
      }
    }

    if (!e.target.classList.contains("manual")) {
      const parentInput = document.getElementsByName(
        `${parentQuestion.name}.checked`
      )[0] as HTMLInputElement;
      const allSiblingsChecked = parentQuestion.questions.every((quest) => {
        const sibling = document.getElementsByName(
          `${quest.name}.checked`
        )[0] as HTMLInputElement;
        return sibling.checked;
      });
      const atLeastOneSiblingChecked = parentQuestion.questions.some(
        (quest) => {
          const sibling = document.getElementsByName(
            `${quest.name}.checked`
          )[0] as HTMLInputElement;
          return sibling.checked;
        }
      );
      const parentChecked = parentInput.checked;

      if (atLeastOneSiblingChecked && !parentChecked) {
        // Se almeno una vera e il parent no la segno come intermediate e l'attivo
        parentInput.classList.add("intermediate");
        parentInput.classList.add("manual");
        parentInput.click();
      } else if (!atLeastOneSiblingChecked && parentChecked) {
        // Se nessuna è vera e il parent è attivo lo disattivo
        parentInput.classList.add("manual");
        parentInput.click();
      } else if (
        atLeastOneSiblingChecked &&
        !allSiblingsChecked &&
        parentChecked
      ) {
        // Se almeno una vera e il parent già attivo gli metto intermediate
        parentInput.classList.add("intermediate");
      } else if (allSiblingsChecked) {
        // Se son tutti attivi tolgo intermediate al parent
        parentInput.classList.remove("intermediate");
      }
    }
    e.target.classList.remove("manual");
  }

  private handleMouseDownConsultantRating(
    event: React.MouseEvent<HTMLElement>
  ) {
    if (this.props.disabled) {
      return;
    }
    const nativeEvent = event.nativeEvent;
    document.addEventListener("mouseup", this.handleMouseUpConsultantRating);
    document.addEventListener("mousemove", this.dragConsultantRating);
    this.setState(
      {
        isConsultantBarTouched: true,
        isDragging: true,
        left: event.currentTarget.getBoundingClientRect().left,
        width: event.currentTarget.getBoundingClientRect().width,
      },
      () => {
        this.dragConsultantRating(nativeEvent);
      }
    );
  }

  private handleMouseUpConsultantRating() {
    if (this.props.disabled) {
      return;
    }
    document.removeEventListener("mouseup", this.handleMouseUpConsultantRating);
    document.removeEventListener("mousemove", this.dragConsultantRating);
    this.setState({
      isDragging: false,
    });
  }

  private dragConsultantRating(e: MouseEvent) {
    if (this.props.disabled) {
      return;
    }
    if (this.state.isDragging) {
      e.preventDefault();
      const x = e.clientX;
      const value = Math.max(
        0,
        Math.min(100, ((x - this.state.left) / this.state.width) * 100)
      );
      this.props.dispatch(change(this.props.form, "consultantRating", value));
    }
  }
}

const ConnectedOverdraftsSectionForm = reduxForm<
  IOverdraftsSectionFormData,
  IOwnProps & any,
  string
>({
  onSubmitFail: focusFirstInvalid,
  onSubmitSuccess: (result: any, dispatch: Dispatch<any>, props: any) => {
    dispatch(initialize(props.form, props.values));
  },
  validate,
})(OverdraftsSectionForm);

export default connect(mapStateToProps)(ConnectedOverdraftsSectionForm);
