import React from "react";
import {Card} from "reactstrap";
import {IconAccountability} from "../../Icons";
import AccountabilityForm from "../shared/Accountability/AccountabilityForm";

interface IProps {
  blocked?: boolean;
  isInView?: boolean;
  onAccept?: (value: boolean) => void;
}

const Accountability = ({blocked, isInView, onAccept}: IProps) => {
  return (
    <Card body color="drawer auto-margin-3">
      <h3>
        <IconAccountability title="Assunzione di responsabilità" /> Assunzione
        di responsabilità
      </h3>
      <AccountabilityForm
        blocked={blocked}
        form={isInView ? "accountabilityFormInView" : "accountabilityForm"}
        //        name="eprwler"
        onAccept={onAccept}
        initialValues={
          blocked
            ? {
                accountabilityCheck1: true,
                accountabilityCheck2: true,
                accountabilityCheck3: true,
                accountabilityCheck4: true,
              }
            : {}
        }
      />
    </Card>
  );
};

export default Accountability;
