import {FileRejection} from "react-dropzone";
import {connect} from "react-redux";
import errors, {ErrorCodes} from "../../helpers/errors";
import {IRootState} from "../../redux/reducers";
import {actions} from "../actions";
import DeletePage from "./DeletePage";

const mbExponent = 20;
const mb = 2 ** mbExponent; // 1 MB
const mapStateToProps = (state: IRootState) => ({
  filePath: state.users.filePath,
  report: state.users.report,
  ui: state.users.ui,
});

const mapDispatchToProps = {
  acceptBulkFile: (filePath: string) =>
    actions.acceptBulkFile(filePath, "delete"),
  onDrop: (accepted: File[], fileRejections: FileRejection[]) => {
    if (accepted.length === 0 && fileRejections.length > 0) {
      return actions.uploadBulkFileFail(errors[ErrorCodes.FILE_MULTIPLE]);
    } else if (accepted[0].size > 8 * mb) {
      // 8MB
      return actions.uploadBulkFileFail(errors[ErrorCodes.FILE_TOO_BIG]);
    }
    return actions.uploadBulkFile(accepted[0], "delete");
  },
  resetFileState: () => actions.resetFileState(),
};

export default connect(mapStateToProps, mapDispatchToProps)(DeletePage);
