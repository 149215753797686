import {faCircle as farCircle} from "@fortawesome/pro-regular-svg-icons";
import {faCircle} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {useFormContext} from "react-hook-form";
import {Alert, Card, Col, Row} from "reactstrap";
import "./ChoseProductType.scss";
import {INewBeneProposalFormData} from "./NewBeneProposalForm";

interface IProps {
  houseDisabled?: boolean;
  injuriesDisabled?: boolean;
  rcDisabled?: boolean;
  isView?: boolean;
}

const ChoseProductType = ({
  houseDisabled,
  injuriesDisabled,
  rcDisabled,
  isView,
}: IProps) => {
  const {register, setValue, watch} = useFormContext();
  const type = watch("type");

  const levels: {
    [key in INewBeneProposalFormData["type"]]: {
      label: string;
      disabled: boolean;
      alert: string;
    };
  } = {
    house: {
      label: "Bene Casa",
      disabled: !!houseDisabled,
      alert: `Non è possibile selezionare "Bene Casa" in quanto non è stata
          compilata la sezione "Tutelare beni, casa, animali" nel blocco delle
          scoperture`,
    },
    injuries: {
      label: "Bene Infortuni",
      disabled: !!injuriesDisabled,
      alert: `Non è possibile selezionare "Bene Infortuni" in quanto non è stata
          compilata la sezione "Tutelarsi da infortuni, malattia & prevenzione"
          nel blocco delle scoperture`,
    },
    rc: {
      label: "Bene Professional",
      disabled: !!rcDisabled,
      alert: `Non è possibile selezionare "Bene Professional" in quanto non è stata
          compilata la sezione "<Sezione>" nel blocco delle scoperture`,
    },
  };

  const handleChange = () => {
    setValue("level", "");
  };

  return (
    <>
      {Object.entries(levels).map(
        ([key, level]) =>
          level.disabled && (
            <Alert color="warning" key={key}>
              {level.alert}
            </Alert>
          )
      )}
      <Row>
        {Object.entries(levels).map(([key, level], index) => (
          <Col md key={key} className={index > 0 ? "mt-2 mt-md-0" : undefined}>
            <label className="radio-card">
              <input
                type="radio"
                name="type"
                value={key}
                ref={register}
                onChange={handleChange}
                disabled={isView || level.disabled}
              />
              <Card className="py-2 px-3">
                <div className="text-nowrap">
                  {type === key ? (
                    <FontAwesomeIcon icon={faCircle} />
                  ) : (
                    <FontAwesomeIcon icon={farCircle} />
                  )}{" "}
                  {level.label}
                </div>
              </Card>
            </label>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default ChoseProductType;
