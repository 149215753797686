import {AnyAction, createSlice, Draft} from "@reduxjs/toolkit";
import {createSelector} from "reselect";
import {DeepReadonly} from "utility-types/dist/mapped-types";
import IApiError from "../types/IApiError";
import {IAppThunkActionStates} from "../types/thunk";
import {actionTypes as userActionTypes} from "../User";

type IState = DeepReadonly<{
  [key: string]: {[key: string]: IAppThunkActionStates};
}>;

interface SerializedError {
  name?: string;
  message?: string;
  code?: string;
  stack?: string;
}

interface GenericThunkArg {
  thunkId?: string;
  [key: string]: any;
}

interface PendingAction {
  type: string;
  payload: undefined;
  meta: {
    requestId: string;
    arg: GenericThunkArg | any;
  };
}

interface FulfilledAction {
  type: string;
  payload: any;
  meta: {
    requestId: string;
    arg: GenericThunkArg | any;
  };
}

interface RejectedAction {
  type: string;
  payload: undefined | {error: IApiError};
  error: SerializedError | any;
  meta: {
    requestId: string;
    arg: GenericThunkArg | any;
    aborted: boolean;
    condition: boolean;
  };
}

interface ResetAction {
  type: string;
  payload: undefined;
  meta: {
    arg: GenericThunkArg;
  };
}

const emptyThunkObject = {};

interface ICreateThunkStatesSliceOptions {
  nameSpace: string;
  selectState: (state: any) => IState;
}

const isPendingAction = (nameSpace: string) => (
  action: AnyAction
): action is PendingAction => {
  return action.type.startsWith(nameSpace) && action.type.endsWith("/pending");
};
const isFulfilledAction = (nameSpace: string) => (
  action: AnyAction
): action is FulfilledAction => {
  return (
    action.type.startsWith(nameSpace) && action.type.endsWith("/fulfilled")
  );
};
const isRejectedAction = (nameSpace: string) => (
  action: AnyAction
): action is RejectedAction => {
  return action.type.startsWith(nameSpace) && action.type.endsWith("/rejected");
};
const isResetAction = (nameSpace: string) => (
  action: AnyAction
): action is ResetAction => {
  return action.type.startsWith(nameSpace) && action.type.endsWith("/reset");
};

export const createThunkStatesSlice = ({
  nameSpace,
  selectState,
}: ICreateThunkStatesSliceOptions) => {
  const thunkStatesInitialState: IState = {};

  const initializeState = (state: Draft<IState>, name: string, key: string) => {
    if (!state[name]) {
      state[name] = {};
    }
    if (!state[name][key]) {
      state[name][key] = {};
    }
  };

  const thunkStates = createSlice({
    name: "thunkStates",
    initialState: thunkStatesInitialState as IState,
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(userActionTypes.LOGOUT_SUCCESS, () => {
          return thunkStatesInitialState;
        })
        .addMatcher(isPendingAction(nameSpace), (state, action) => {
          const name = action.type.substring(0, action.type.length - 8);
          const key = action.meta.arg?.thunkId ?? "DEFAULT";
          initializeState(state, name, key);

          state[name][key].isPending = true;
          state[name][key].isSuccess = false;
          state[name][key].isFail = false;
          state[name][key].error = undefined;
        })
        .addMatcher(isFulfilledAction(nameSpace), (state, action) => {
          const name = action.type.substring(0, action.type.length - 10);
          const key = action.meta.arg?.thunkId ?? "DEFAULT";
          initializeState(state, name, key);

          state[name][key].isPending = false;
          state[name][key].isSuccess = true;
          state[name][key].isFail = false;
          state[name][key].error = undefined;
        })
        .addMatcher(isRejectedAction(nameSpace), (state, action) => {
          const name = action.type.substring(0, action.type.length - 9);
          const key = action.meta.arg?.thunkId ?? "DEFAULT";
          initializeState(state, name, key);

          // Nel caso ti thunk rejectWithValue l'errore è sul payload
          // Se abbiamo l'error sul payload ha la precedenza
          const actualError = action.payload?.error ?? action.error;

          state[name][key].isPending = false;
          state[name][key].isSuccess = false;
          state[name][key].isFail = true;
          state[name][key].error =
            actualError.message ?? "Errore sconosciuto, riprova più tardi";
        })
        .addMatcher(isResetAction(nameSpace), (state, action) => {
          const name = action.type.substring(0, action.type.length - 6);
          const key = action.meta.arg?.thunkId ?? "DEFAULT";
          initializeState(state, name, key);

          state[name][key].isPending = false;
          state[name][key].isSuccess = false;
          state[name][key].isFail = false;
          state[name][key].error = undefined;
        });
    },
  });

  const selectThunkActionStates = createSelector(
    [
      selectState,
      (state: any, name: string) => name,
      (state: any, _: any, key: string = "DEFAULT") => key,
    ],
    (thunkActionsStates, thunkActionName, key): IAppThunkActionStates => {
      if (key === "ALL") {
        return thunkActionsStates[thunkActionName] ?? emptyThunkObject;
      }
      return thunkActionsStates[thunkActionName]?.[key] ?? emptyThunkObject;
    }
  );

  return {
    actions: thunkStates.actions,
    reducer: thunkStates.reducer,
    selectors: {selectThunkActionStates},
  };
};
