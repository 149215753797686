import IRoutes from "../types/IRoutes";
import ActivatePage from "./ActivatePage";
import ActivateWithTokenPage from "./ActivateWithTokenPage";
import DocumentsPage from "./DocumentsPage";
import IdentificationManageContainer from "./IdentificationManage/Container";
import LoginPage from "./LoginPage";
import LostPasswordPage from "./LostPasswordPage";
import LostPasswordWithTokenPage from "./LostPasswordWithTokenPage";
import PrivacyPage from "./PrivacyPage";
import PrivacySubscriptionPage from "./PrivacySubscriptionPage";
import ProfilePage from "./ProfilePage";
import SignsContainer from "./SignsContainer";

const routes: IRoutes = [
  {
    component: LoginPage,
    name: "login",
    path: "/login",
  },
  {
    component: ProfilePage,
    name: "user-profile",
    path: "/user/profile",
    permission: "profile.manage",
  },
  {
    component: PrivacyPage,
    name: "user-privacy",
    path: "/user/privacy",
    permission: "profile.manage",
  },
  {
    component: PrivacySubscriptionPage,
    name: "user-privacy-subscription",
    path: "/user/privacy-subscription",
    permission: "profile.manage",
  },
  {
    component: DocumentsPage,
    name: "user-download-documents",
    path: "/user/download-documents",
  },
  {
    component: PrivacyPage,
    name: "user-privacy",
    path: "/user/privacy",
  },
  {
    component: DocumentsPage,
    name: "user-download-documents",
    path: "/user/download-documents",
  },
  {
    component: ActivatePage,
    name: "activate",
    path: "/activate",
  },
  {
    component: LostPasswordPage,
    name: "lost-password",
    path: "/lost-password",
  },
  {
    component: ActivateWithTokenPage,
    name: "set-password-activate",
    path: "/set-password-activate/:token?",
  },
  {
    component: LostPasswordWithTokenPage,
    name: "set-password",
    path: "/set-password/:token?",
  },
  {
    component: SignsContainer,
    name: "signs",
    path: "/user/signs",
    permission: "profile.manage",
  },
  {
    component: IdentificationManageContainer,
    name: "signs",
    path: "/user/ids",
    permission: "profile.manage",
  },
];

export default routes;
