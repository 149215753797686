import animateScrollTo from "animated-scroll-to";
import moment from "moment";
import React from "react";
import {RouteComponentProps} from "react-router";
import {Redirect} from "react-router-dom";
import {Alert, Card, Container} from "reactstrap";
import {
  actions,
  IRecommendation,
  IRecommendationEntity,
  IRevision,
  RecommendationStatus,
} from "../";
import {Layout, LoaderOverlay, PageTitle} from "../../App";
import {ICap, IInvestmentOption} from "../../Caps";
import CloseCap from "../../Caps/CloseCap";
import Delivery from "../../Caps/Delivery";
import PrivacySubscription from "../../Caps/PrivacySubscription";
import {IconCheckCircle} from "../../Icons";
import {IAppThunkActionStates} from "../../types/thunk";
import {IUser} from "../../Users";
import CapDetails from "../CapDetails";
import CapIdentification from "../CapIdentification";
import ContractorDetails from "../ContractorDetails";
import {IChooseOptionsFormData} from "../New/ChooseOptionsForm";
import SavingDetails from "../SavingDetails";
import ESignBar from "./ESignBar";
import OptionsDetails from "./OptionsDetails";
import StatusAlert from "./StatusAlert";
import UserDetails from "./UserDetails";

interface IMatchParams {
  recommendationId: string;
}
interface IStateProps {
  acceptError?: string;
  availableOptions?: IInvestmentOption[];
  canSeeCap: boolean;
  isAcceptPending?: boolean;
  isAdvisor: boolean;
  isBackOffice: boolean;
  isContractor: boolean;
  isCreateSuccess?: boolean;
  isReadPending?: boolean;
  isRejectPending?: boolean;
  lastCreatedId?: string;
  openRequestOTPModal: (recommendationId: string) => void;
  rejectError?: string;
  submitError?: string | undefined;
  user: IUser | null;
  viewRecommendation?: IRecommendation;
  signFEADocStates: IAppThunkActionStates;
}
interface IDispatchToProps {
  archiveRecommendation: (recommendationId: string) => Promise<void>;
  optionsList: () => Promise<void>;
  reSendRecommendation: (
    recommendationId: string,
    values: IChooseOptionsFormData,
    revisions: IRevision[],
    cap: ICap
  ) => Promise<void>;
  read: (recommendationId: string) => Promise<void>;
  resetNewRecommendation: () => actions;
  validate: (
    id: string,
    values: Partial<IRecommendationEntity>
  ) => Promise<void>;
}
interface IOwnProps extends RouteComponentProps<IMatchParams> {}
type IProps = IStateProps & IDispatchToProps & IOwnProps;

class Page extends React.PureComponent<IProps> {
  public constructor(props: IProps) {
    super(props);

    this.handleChooseOptionSubmit = this.handleChooseOptionSubmit.bind(this);
    this.handleValidate = this.handleValidate.bind(this);
    this.openRequestOTPModalWithId = this.openRequestOTPModalWithId.bind(this);
  }

  public async componentDidMount() {
    if (!this.props.viewRecommendation) {
      // Non ho i dati, carico la raccomandazione
      await this.props.read(this.props.match.params.recommendationId);
      // Ora ho la raccomandazione, assegno il tipo giusto per poterla usare
      const nowLoadedRecommendation = (this.props
        .viewRecommendation as unknown) as IRecommendation;
      if (
        nowLoadedRecommendation.status ===
          RecommendationStatus.STATUS_REQUEST_ESIGN_ADVISOR &&
        this.props.isAdvisor
      ) {
        this.props.openRequestOTPModal(nowLoadedRecommendation.id);
      }
    } else {
      // Raccomandazione già in memoria
      if (
        this.props.viewRecommendation.status ===
          RecommendationStatus.STATUS_REQUEST_ESIGN_ADVISOR &&
        this.props.isAdvisor
      ) {
        this.props.openRequestOTPModal(this.props.viewRecommendation.id);
      }
    }
  }

  public componentWillUnmount() {
    this.props.resetNewRecommendation();
  }

  public render() {
    const {
      acceptError,
      availableOptions,
      optionsList,
      canSeeCap,
      isAcceptPending,
      isAdvisor,
      isBackOffice,
      isContractor,
      isCreateSuccess,
      isReadPending,
      isRejectPending,
      lastCreatedId,
      rejectError,
      signFEADocStates,
      submitError,
      user,
      viewRecommendation,
    } = this.props;
    return (
      <Layout isProtected>
        {isCreateSuccess && (
          <Redirect to={`/raccomandazioni/${lastCreatedId}`} />
        )}
        <PageTitle>
          Raccomandazione:
          {viewRecommendation && (
            <span>
              &nbsp; {viewRecommendation.code},&nbsp;inserita il{" "}
              {viewRecommendation.date.format("LL")}
            </span>
          )}
        </PageTitle>
        <Container className="container-max p-md-5 auto-margin-3">
          {signFEADocStates.isSuccess && (
            <Alert color="success">
              <IconCheckCircle /> La raccomandazione personalizzata è stata
              salvata correttamente ed è ora visibile al back office. Il
              download sarà disponibile non appena il cliente l'avrà firmata.
            </Alert>
          )}
          {viewRecommendation && (
            <>
              {!isCreateSuccess && (
                <StatusAlert
                  isBackOffice={isBackOffice}
                  recommendationStatus={viewRecommendation.status}
                />
              )}
              {!isAdvisor && <UserDetails user={viewRecommendation.user} />}
              <ContractorDetails contractor={viewRecommendation.contractor} />
              <CapIdentification
                cap={viewRecommendation.cap}
                disableUpload={
                  !!viewRecommendation.userEsignId &&
                  viewRecommendation.status !==
                    RecommendationStatus.STATUS_WAIT_REVIEW
                }
                isAdvisor={isAdvisor}
                isContractor={isContractor}
              />
              <PrivacySubscription
                as={Card}
                body
                cap={viewRecommendation?.cap}
                className="auto-margin-3 d-block "
                color="drawer"
                icon
              />
              <CapDetails
                cap={viewRecommendation.cap}
                canSeeCap={canSeeCap}
                isAdvisor={isAdvisor}
              />
              <SavingDetails cap={viewRecommendation.cap} />
              {viewRecommendation.status ===
                RecommendationStatus.STATUS_WAIT_REVIEW && isAdvisor ? (
                <OptionsDetails
                  availableOptions={availableOptions!}
                  optionsList={optionsList}
                  code={viewRecommendation.code}
                  viewRecommendationId={viewRecommendation.id}
                  comment={viewRecommendation.comment}
                  editable
                  esignId={viewRecommendation.esignId}
                  userEsignId={viewRecommendation.userEsignId}
                  date={viewRecommendation.date}
                  handleValidate={this.handleValidate}
                  isAdvisor={isAdvisor}
                  isBackOffice={isBackOffice}
                  isAcceptPending={isAcceptPending}
                  isRejectPending={isRejectPending}
                  onSubmit={this.handleChooseOptionSubmit}
                  options={viewRecommendation.options}
                  submitError={submitError}
                  revisions={viewRecommendation.revisions}
                  status={viewRecommendation.status}
                  suitableOptions={
                    viewRecommendation.cap.idd
                      ? viewRecommendation.cap.idd.suitableOptions
                      : []
                  }
                  userId={user!.id}
                  cap={viewRecommendation.cap}
                  acceptError={acceptError}
                  rejectError={rejectError}
                />
              ) : (
                <OptionsDetails
                  code={viewRecommendation.code}
                  comment={viewRecommendation.comment}
                  handleValidate={this.handleValidate}
                  esignId={viewRecommendation.esignId}
                  userEsignId={viewRecommendation.userEsignId}
                  date={viewRecommendation.date}
                  isAdvisor={isAdvisor}
                  isBackOffice={isBackOffice}
                  isContractor={isContractor}
                  isRejectPending={isRejectPending}
                  isAcceptPending={isAcceptPending}
                  options={viewRecommendation.options}
                  revisions={viewRecommendation.revisions}
                  status={viewRecommendation.status}
                  acceptError={acceptError}
                  rejectError={rejectError}
                  cap={viewRecommendation.cap}
                  viewRecommendationId={viewRecommendation.id}
                />
              )}
            </>
          )}
          <LoaderOverlay visible={isReadPending}>
            Caricamento raccomandazione in corso...
          </LoaderOverlay>
        </Container>
        {(!viewRecommendation?.date ||
          viewRecommendation?.date?.isAfter(
            moment(process.env.REACT_APP_DATE_START_DELIVERY)
          ) ||
          !viewRecommendation?.esignId ||
          !viewRecommendation?.userEsignId) && (
          <>
            {isBackOffice && (
              <>
                <Delivery cap={viewRecommendation?.cap} />
                <CloseCap cap={viewRecommendation?.cap} id="close-cap" />
              </>
            )}
          </>
        )}
        {viewRecommendation && (
          <ESignBar
            isAdvisor={isAdvisor}
            isContractor={isContractor}
            recommendation={viewRecommendation}
            openRequestOTPModalWithId={this.openRequestOTPModalWithId}
          />
        )}
      </Layout>
    );
  }

  private openRequestOTPModalWithId() {
    if (this.props.viewRecommendation) {
      this.props.openRequestOTPModal(this.props.viewRecommendation.id);
    }
  }

  private async handleChooseOptionSubmit(values: IChooseOptionsFormData) {
    await this.props.archiveRecommendation(
      this.props.viewRecommendation!.id.toString()
    );
    return this.props
      .reSendRecommendation(
        this.props.viewRecommendation!.id.toString(),
        values,
        this.props.viewRecommendation!.revisions,
        this.props.viewRecommendation!.cap
      )
      .then(() => {
        animateScrollTo(0);
      });
  }

  private handleValidate(values: {
    status:
      | RecommendationStatus.STATUS_CORRESPONDING
      | RecommendationStatus.STATUS_WAIT_REVIEW;
    revision?: string;
  }) {
    const data: Partial<IRecommendationEntity> = {};
    const revisions = this.props.viewRecommendation!.revisions;
    if (values.revision) {
      this.props.viewRecommendation!.revisions.push({
        date: moment(),
        text: values.revision,
      });
    }
    data.jsonRevisions = JSON.stringify(revisions);
    data.status = values.status;
    return this.props
      .validate(this.props.viewRecommendation!.id.toString(), data)
      .then(() => {
        animateScrollTo(0);
      });
  }
}

export default Page;
